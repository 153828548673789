import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import axios from "axios";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import moment from "moment";
import Paginacion from "./../../components/Table/Pagination.js";
import ModalTelefonos from "./../../components/Gestion/TelefonosHistorial.js";
import ModalModificarUsuario from "./../../components/Gestion/ModificarDatosUsuario.js";
import ModalModificarRequerimiento from "./../../components/Gestion/ModificarRequerimiento.js";
import Motivo from "./../../components/Gestion/Motivo.js";
import ObservacionesTeleconsulta from "./../../components/Gestion/ObservacionesTeleconsulta";
import { confirmAlert } from "react-confirm-alert";
import FormControl from "@material-ui/core/FormControl";
import copiar from "../../assets/img/usuarios/copiar.svg";
import CasosRegistrados from "./../../components/Gestion/CasosRegistrados";
import obs_teleconsulta from "../../assets/img/gestion/obs_teleconsulta.svg";
import ver_mas from "../../assets/img/gestion/ver_mas.svg";
import editar from "../../assets/img/gestion/editar.svg";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import { NotificationManager } from "react-notifications";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
// CSS
import "./../../assets/css/gestion.css";

export default class GestionGeneralDerivado extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alerta: false,
      ubicacion: 5,
      tipo: 4,
      pagina: 0,
      filas: 5,
      secId: 0,
      total: 0,
      demanda: [],
      preguntas: false,
      especialidad: null,
      comentariosDemanda: false,
      gegId: null,
      ggoId: null,
      ggoObservacion: "",
      cesfam: props.cesfam,
      usp: props.uspId,
      modalTelefonos: false,
      modalModificarUsuario: false,
      modalMotivo: false,
      modalObservacionesTeleconsulta: false,
      modalCasosRegistrados: false,
      requerimientos: [],

      usuId: null,
      requerimiento: "",
      uspResignar: null,

      textoMotivo: null,
      audioMotivo: null,
      audioMotivoInicio: null,
      audioMotivoTermino: null,

      rut: null,
      direccion: null,
      cesfam_nombre: null,
      nombre_usuario: null,

      texto_filtro: props.rutFiltro || "",
      requerimiento_filtro: "",
      tomado_por_filtro: "",
      riesgo_filtro: "",
      fecha_ingreso_filtro: "",
      gestion_filtro: null,
      usuarios_panel_filtro: [],
    };

    this.mostrarModalTelefonos = this.mostrarModalTelefonos.bind(this);
    this.ocultarModalTelefonos = this.ocultarModalTelefonos.bind(this);
  }

  componentDidMount() {
    this.fetchGestion(this.state.pagina, this.state.filas);
    this.traeRequerimientos();
  }

  copiarRut = (rut) => {
    navigator.clipboard.writeText(rut);
    NotificationManager.info("Rut copiado");
  };

  traeRequerimientos = () => {
    axios
      .get(global.config.modulos.gestion + "requerimientos/", {
        headers: authHeader(),
        params: {
          tipo: this.state.tipo,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ requerimientos: res.data.datos });
        }
      });
  };

  mostrarModalObservacionesTeleconsulta = (id, usuario) => {
    this.setState({
      modalObservacionesTeleconsulta: true,
      gegId: id,
      usuId: usuario,
    });
  };

  ocultarModalObservacionesTeleconsulta = () => {
    this.setState({
      modalObservacionesTeleconsulta: false,
    });
  };

  mostrarModalTelefonos = (usuario, rut, direccion, cesfam, nombre_usuario, gegId) => {
    this.setState({
      modalTelefonos: true,
      usuId: usuario,
      rut: rut,
      direccion: direccion,
      cesfam_nombre: cesfam,
      nombre_usuario: nombre_usuario,
      gegId: gegId,
    });
  };

  ocultarModalTelefonos = () => {
    this.setState({
      modalTelefonos: false,
    });
  };

  mostrarCasosRegistrados = (usuario, gestion) => {
    this.setState({
      modalCasosRegistrados: true,
      usuId: usuario,
      gegId: gestion,
    });
  };

  ocultarCasosRegistrados = () => {
    this.setState({
      modalCasosRegistrados: false,
    });
  };

  mostrarModalModificarUsuario = (usuario) => {
    this.setState({
      modalModificarUsuario: true,
      usuId: usuario,
    });
  };

  ocultarModalModificarUsuario = () => {
    this.setState({
      modalModificarUsuario: false,
    });
    this.fetchGestion(this.state.pagina, this.state.filas);
  };

  mostrarModalModificarRequerimiento = (id, requerimiento) => {
    this.setState({
      modalModificarRequerimiento: true,
      gegId: id,
      requerimiento: requerimiento,
    });
  };

  ocultarModalModificarRequerimiento = () => {
    this.fetchGestion(this.state.pagina, this.state.filas);
    this.setState({
      modalModificarRequerimiento: false,
    });
  };

  mostrarModalMotivo = (gegId, audioMotivo, audioMotivoInicio, audioMotivoTermino, textoMotivo) => {
    this.setState({
      modalMotivo: true,
      gegId: gegId,
      audioMotivo: audioMotivo,
      audioMotivoInicio: audioMotivoInicio,
      audioMotivoTermino: audioMotivoTermino,
      textoMotivo: textoMotivo,
    });
  };

  ocultarModalMotivo = () => {
    this.setState({
      modalMotivo: false,
    });
  };

  mostrarModalAgregarObservacion = (id) => {
    this.setState({
      modalAgregarObservacion: true,
      gegId: id,
    });
  };

  ocultarModalAgregarObservacion = () => {
    this.setState({
      modalAgregarObservacion: false,
    });
    this.fetchGestion(this.state.pagina, this.state.filas);
  };

  fetchGestion = async (pagina, filas) => {
    let fechaIngreso = null;

    if (this.state.fecha_ingreso_filtro) {
      fechaIngreso = moment(this.state.fecha_ingreso_filtro).format("YYYY-MM-DD");
    }
    axios
      .get(global.config.modulos.gestion + "tabla/", {
        headers: authHeader(),
        params: {
          tipo: this.state.tipo,
          ubicacion: this.state.ubicacion,
          pagina: pagina,
          filas: filas,
          cesfam: this.state.cesfam,
          usp: this.state.usp,
          texto_filtro: this.state.texto_filtro,
          requerimiento_filtro: this.state.requerimiento_filtro,
          riesgo_filtro: this.state.riesgo_filtro,
          fecha_ingreso_filtro: fechaIngreso,
          gestion_filtro: this.state.gestion_filtro,
        },
      })
      .then((res) => {
        this.setState({
          demanda: res.data.datos,
          total: res.data.total,
          pagina: res.data.pagina,
        });
      });
  };

  handlePagination = (pagina) => {
    this.setState({
      pagina: pagina,
    });
    this.fetchGestion(pagina, this.state.filas);
  };

  handleFilasPagination = (filas) => {
    this.setState({
      filas: filas,
    });
    this.fetchGestion(this.state.pagina, filas);
  };

  renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span style={{ color: "red" }}>Tiempo Terminado</span>;
    } else {
      return (
        <span>
          {days + " días, "}
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  pausarCaso = (gegid) => {
    confirmAlert({
      title: "",
      message: "Seguro que desea pausar este caso?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          className: "btnAceptar",
          onClick: () => {
            axios
              .patch(
                global.config.modulos.gestion,
                {
                  geg_id: gegid,
                  geg_estado: 2,
                },
                { headers: authHeader() }
              )
              .then((res) => {
                NotificationManager.success("Caso pausado con éxito");
                this.generarHistorial("Se pausa el caso", gegid, this.state.usp);
                this.fetchGestion(this.state.pagina, this.state.filas);
              });
          },
        },
      ],
    });
  };

  reanudarCaso = (gegid) => {
    confirmAlert({
      title: "",
      message: "Seguro que desea reanudar este caso?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          className: "btnAceptar",
          onClick: () => {
            axios
              .patch(
                global.config.modulos.gestion,
                {
                  geg_id: gegid,
                  geg_estado: 1,
                },
                { headers: authHeader() }
              )
              .then((res) => {
                NotificationManager.success("Caso reactivado con éxito");
                this.generarHistorial("Se reanuda el caso", gegid, this.state.usp);
                this.fetchGestion(this.state.pagina, this.state.filas);
              });
          },
        },
      ],
    });
  };

  generarHistorial = (accion, id, usp) => {
    axios
      .post(
        global.config.modulos.gestion + "historial/",
        {
          accion: accion,
          gestion: id,
          usuario_panel: usp,
        },
        { headers: authHeader() }
      )
      .then((res) => {});
  };

  aplicarFiltros = () => {
    this.fetchGestion(this.state.pagina, this.state.filas);
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  actualizarMensajesLeidos = (chat, participante) => {
    axios.post(
      global.config.modulos.chat + "actualizar_chat_leido/",
      {
        chat: chat,
        participante: participante,
      },
      { headers: authHeader() }
    );
  };

  render() {
    const { demanda } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div>
            <div style={{ float: "left", marginBottom: 10 }}>
              <p style={{ float: "left", marginRight: 20, height: 80 }}>
                <FormControl variant="outlined">
                  <p>Eje de acción</p>
                  <select
                    style={{
                      marginTop: 5,
                      minWidth: 250,
                      padding: 6,
                      border: "1px solid black",
                      borderRadius: 5,
                    }}
                    id="requerimiento_filtro"
                    name="requerimiento_filtro"
                    value={this.state.requerimiento_filtro}
                    onChange={this.cambiarValores}
                  >
                    <option value={""}>Todos</option>
                    {this.state.requerimientos.map((res, index) => (
                      <option key={index} value={res.ggr_id}>
                        {res.ggr_requerimiento}
                      </option>
                    ))}
                  </select>
                </FormControl>
              </p>
              <p style={{ float: "left", marginRight: 20, height: 80 }}>
                <p>Fecha de ingreso</p>

                <input
                  type="date"
                  name="fecha_ingreso_filtro"
                  value={this.state.fecha_ingreso_filtro}
                  onChange={this.cambiarValores}
                  placeholder="Fecha de ingreso"
                  className={"inputText"}
                  style={{
                    marginTop: 5,
                    padding: 4,
                    border: "1px solid black",
                    borderRadius: 5,
                    float: "left",
                  }}
                />
              </p>

              <p style={{ float: "left", marginRight: 20, height: 80 }}>
                <p>Nombre o rut</p>
                <input
                  type="text"
                  name="texto_filtro"
                  value={this.state.texto_filtro}
                  onChange={this.cambiarValores}
                  placeholder="Nombre o Rut"
                  className={"inputText"}
                  style={{
                    padding: 6,
                    marginTop: 5,
                    border: "1px solid black",
                    borderRadius: 5,
                    float: "left",
                  }}
                />
                <a
                  onClick={this.aplicarFiltros}
                  color="primary"
                  className="btnAgregarCaso fondoNaranjo"
                  style={{
                    marginTop: 3,
                    padding: "7px 13px 7px 13px",
                    fontSize: 14,
                    float: "left",
                    marginLeft: 30,
                  }}
                >
                  Buscar
                </a>
              </p>
            </div>
            <div style={{ marginTop: 30, marginBottom: 100 }}>
              <TableContainer>
                <Table aria-label="simple table" className="tablaGeneralGestion bordeTablaCovid">
                  <TableHead>
                    <TableRow>
                      <TableCell className="cellInicio" align="center">
                        Fecha de ingreso
                      </TableCell>
                      <TableCell align="center">Nº de registros</TableCell>
                      <TableCell align="center">Nombre</TableCell>
                      <TableCell align="center">Edad</TableCell>
                      <TableCell align="center">Eje de acción</TableCell>
                      <TableCell align="center">Observaciones</TableCell>
                      <TableCell align="center">Ver más</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {demanda.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          <p> {row.geg_fecha_ingreso.replace("T", " ")}</p>
                        </TableCell>

                        <TableCell align="center">
                          {row.registros > 1 ? (
                            <a style={{ cursor: "pointer" }} onClick={() => this.mostrarCasosRegistrados(row.geg_usuario, row.geg_id)}>
                              {row.registros}
                            </a>
                          ) : (
                            row.registros
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <PopupState variant="popover" popupId="demo-popup-popover">
                            {(popupState) => (
                              <div>
                                <a variant="contained" color="primary" style={{ cursor: "pointer" }} {...bindTrigger(popupState)}>
                                  {row.nombre_usuario}
                                </a>
                                <Popover
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                >
                                  <Box p={2}>
                                    <Typography>
                                      <p>
                                        Rut: {row.rut}
                                        <Tooltip title="Copiar" aria-label="Copiar">
                                          <img className="smallIcon" src={copiar} onClick={() => this.copiarRut(row.rut)} />
                                        </Tooltip>
                                      </p>
                                    </Typography>
                                  </Box>
                                </Popover>
                              </div>
                            )}
                          </PopupState>
                        </TableCell>
                        <TableCell align="center">
                          <p>
                            {row.edad ? row.edad : "0"} años{" "}
                            <Tooltip title="Editar" aria-label="Editar">
                              <img className="smallIcon" src={editar} onClick={() => this.mostrarModalModificarUsuario(row.geg_usuario)} />
                            </Tooltip>
                          </p>
                        </TableCell>
                        <TableCell align="center">{row.requerimiento}</TableCell>
                        <TableCell align="center">
                          {row.observaciones ? (
                            <React.Fragment>
                              {row.observaciones.map((res, index) => {
                                return (
                                  <PopupState key={index} variant="popover" popupId="demo-popup-popover">
                                    {(popupState) => (
                                      <React.Fragment>
                                        <a variant="contained" color="primary" style={{ cursor: "pointer" }} {...bindTrigger(popupState)}>
                                          <div className="observaciones">{res.ggo_observacion}</div>
                                        </a>
                                        <Popover
                                          {...bindPopover(popupState)}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "center",
                                          }}
                                        >
                                          <Box p={2}>
                                            <Typography>
                                              <p>
                                                <label style={{ fontWeight: "bold" }}>Funcionario: </label>
                                                {res.ggo_usuario_panel}
                                              </p>
                                              <p>
                                                <label style={{ fontWeight: "bold" }}>Fecha: </label>
                                                {res.ggo_fecha_registro.replace("T", " ")}
                                              </p>
                                            </Typography>
                                          </Box>
                                        </Popover>
                                      </React.Fragment>
                                    )}
                                  </PopupState>
                                );
                              })}
                            </React.Fragment>
                          ) : (
                            <p>No se han registrado observaciones</p>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="Ver el registros de canales y detalle de acciones" aria-label="Ver el registros de canales y detalle de acciones">
                            <img className="mediumIcon" src={ver_mas} onClick={() => this.mostrarModalTelefonos(row.geg_usuario, row.rut, row.direccion, row.cesfam_usuario, row.nombre_usuario, row.geg_id)} />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Paginacion total={this.state.total} paginacion={this.handlePagination} filas_paginacion={this.handleFilasPagination} />
            </div>
          </div>
          {this.state.modalTelefonos ? <ModalTelefonos ocultar={this.ocultarModalTelefonos} usuId={this.state.usuId} rut={this.state.rut} cesfam={this.state.cesfam_nombre} gegId={this.state.gegId} tipo={this.state.tipo}></ModalTelefonos> : null}
          {this.state.modalModificarUsuario ? <ModalModificarUsuario ocultar={this.ocultarModalModificarUsuario} usuId={this.state.usuId}></ModalModificarUsuario> : null}
          {this.state.modalModificarRequerimiento ? <ModalModificarRequerimiento ocultar={this.ocultarModalModificarRequerimiento} gegId={this.state.gegId} requerimiento={this.state.requerimiento} tipo={this.state.tipo}></ModalModificarRequerimiento> : null}
          {this.state.modalMotivo ? <Motivo ocultar={this.ocultarModalMotivo} gegId={this.state.gegId} audioMotivo={this.state.audioMotivo} audioMotivoInicio={this.state.audioMotivoInicio} audioMotivoTermino={this.state.audioMotivoTermino} textoMotivo={this.state.textoMotivo}></Motivo> : null}

          {this.state.modalObservacionesTeleconsulta ? <ObservacionesTeleconsulta ocultar={this.ocultarModalObservacionesTeleconsulta} gegId={this.state.gegId} usuario={this.state.usuId}></ObservacionesTeleconsulta> : null}
          {this.state.modalCasosRegistrados ? <CasosRegistrados ocultar={this.ocultarCasosRegistrados} gestion={this.state.gegId} usuario={this.state.usuId} tipo={this.state.tipo}></CasosRegistrados> : null}
        </GridItem>
      </GridContainer>
    );
  }
}
