import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import CiclosInscribir from "./CiclosInscribir.js";
import CiclosDesinscribir from "./CiclosDesinscribir.js";
import CiclosModificar from "./CiclosModificar.js";
import CiclosAsignarProfesional from "./CiclosAsignarProfesional.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { store } from "./../../store/index";

// estilos
//import "../../assets/css/agregar.css";

export default class Ciclos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ciclos: [],
      ciclosUsuario: [],
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      usuId: props.usuId,
      modalCiclosInscribir: false,
      modalCiclosDesinscribir: false,
      modalCiclosAsignarProfesional: false,
      modalCiclosModificar: false,
      formErrors: {},
      open: true,
      nombreCiclo: "",
      idCiclo: "",
      herramientas: store.getState().herramientas,
    };
  }

  componentDidMount() {
    this.traeCiclosUsuario();
    this.traeCiclos();
  }

  traeCiclos = () => {
    axios
      .get(global.config.modulos.ciclos + "ciclos_no_inscritos/", {
        headers: authHeader(),
        params: { cesfam: this.state.cesfam, usuario: this.state.usuId },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ ciclos: res.data.datos });
        }
      });
  };

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  mostrarCiclosInscribir = () => {
    this.setState({ modalCiclosInscribir: true });
  };

  ocultarCiclosInscribir = () => {
    this.setState({ modalCiclosInscribir: false });
    this.traeCiclosUsuario();
  };

  mostrarCiclosDesinscribir = (nombreCiclo, ciclo) => {
    this.setState({
      modalCiclosDesinscribir: true,
      nombreCiclo: nombreCiclo,
      idCiclo: ciclo,
    });
  };

  ocultarCiclosDesinscribir = () => {
    this.setState({ modalCiclosDesinscribir: false });
    this.traeCiclosUsuario();
  };

  mostrarCiclosAsignarProfesional = () => {
    this.setState({ modalCiclosAsignarProfesional: true });
  };

  ocultarCiclosAsignarProfesional = () => {
    this.setState({ modalCiclosAsignarProfesional: false });
    this.traeCiclosUsuario();
  };

  mostrarCiclosModificar = (nombreCiclo, idCiclo) => {
    this.setState({
      modalCiclosModificar: true,
      nombreCiclo: nombreCiclo,
      idCiclo: idCiclo,
    });
  };

  ocultarCiclosModificar = () => {
    this.setState({ modalCiclosModificar: false });
    this.traeCiclosUsuario();
  };

  traeCiclosUsuario = () => {
    axios
      .get(global.config.modulos.ciclos + "por_usuario/", {
        headers: authHeader(),
        params: { usuario: this.state.usuId, cesfam: this.state.cesfam },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ ciclosUsuario: res.data.datos });
        } else {
          this.setState({ ciclosUsuario: [] });
        }
      });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { open, ciclos, ciclosUsuario, herramientas } = this.state;
    return (
      <Dialog open={open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="cicloUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
              <img className="marca-agua"></img>
              <h2 style={{ marginBottom: 20 }}>Ciclos</h2>
              <GridContainer className="agendar-container">
                {ciclos.length > 0 || ciclosUsuario.length > 0 ? (
                  <React.Fragment>
                    <GridItem xs={12}>
                      <label>Los niveles mostrados muestran la siguiente atención que le corrresponde al usuario</label>
                    </GridItem>
                    {herramientas.includes("H16") ? (
                      <GridItem xs={12} style={{ marginTop: 20 }}>
                        <Button id="" className=" fondoVerde botonCiclos" onClick={this.mostrarCiclosInscribir}>
                          Inscribir
                        </Button>
                        <Button id="" className=" fondoRojo botonCiclos" onClick={this.mostrarCiclosAsignarProfesional} style={{ marginLeft: 20 }}>
                          Asignar profesional
                        </Button>
                      </GridItem>
                    ) : null}
                    <GridItem xs={12} style={{ marginTop: 30 }}>
                      <Table aria-label="simple table" className="bordeTabla">
                        <TableHead>
                          <TableRow>
                            <TableCell className="cellInicio" align="center">
                              Ciclo
                            </TableCell>
                            <TableCell align="center">Nivel</TableCell>
                            <TableCell align="center">Especialidad</TableCell>
                            <TableCell align="center">Profesional</TableCell>
                            <TableCell align="center">Rango reserva</TableCell>
                            {this.state.herramientas.includes("H16") ? (
                              <TableCell className="cellTermino" align="center">
                                Acciones
                              </TableCell>
                            ) : null}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ciclosUsuario.map((cic, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">{cic.ciclo}</TableCell>
                              <TableCell align="center">{cic.nivelSiguiente}</TableCell>
                              <TableCell align="center">{cic.especialidad}</TableCell>
                              <TableCell align="center">{cic.profesional}</TableCell>
                              <TableCell align="center">
                                <p>Inicio:</p>
                                <p> {cic.inicio}</p>
                                <p style={{ marginTop: 10 }}>Término:</p>
                                <p> {cic.termino}</p>
                              </TableCell>
                              {herramientas.includes("H16") ? (
                                <TableCell align="center">
                                  <Button id="" className=" fondoRojo" onClick={() => this.mostrarCiclosModificar(cic.ciclo, cic.ciu_ciclo)} style={{ color: "#fff" }}>
                                    Agregar atención
                                  </Button>
                                  <Button id="" className=" fondoRojo desinscribirCiclo" onClick={() => this.mostrarCiclosDesinscribir(cic.ciclo, cic.ciu_ciclo)}>
                                    Desinscribir
                                  </Button>
                                </TableCell>
                              ) : null}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </GridItem>
                  </React.Fragment>
                ) : (
                  <GridItem xs={12}>
                    <p>Cesfam sin ciclos activos</p>
                  </GridItem>
                )}
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={this.salir} style={{ marginLeft: 20 }}>
                    Cerrar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
        {this.state.modalCiclosInscribir ? <CiclosInscribir ocultar={this.ocultarCiclosInscribir} usuario={this.state.usuId}></CiclosInscribir> : null}
        {this.state.modalCiclosAsignarProfesional ? <CiclosAsignarProfesional ocultar={this.ocultarCiclosAsignarProfesional} usuario={this.state.usuId} ciclosUsuario={this.state.ciclosUsuario}></CiclosAsignarProfesional> : null}
        {this.state.modalCiclosModificar ? <CiclosModificar ocultar={this.ocultarCiclosModificar} usuario={this.state.usuId} nombreCiclo={this.state.nombreCiclo} ciclo={this.state.idCiclo}></CiclosModificar> : null}
        {this.state.modalCiclosDesinscribir ? <CiclosDesinscribir ocultar={this.ocultarCiclosDesinscribir} usuario={this.state.usuId} nombreCiclo={this.state.nombreCiclo} ciclo={this.state.idCiclo}></CiclosDesinscribir> : null}
      </Dialog>
    );
  }
}
