import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";
import moment from "moment";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MaterialTable from "material-table";

import { store } from "../../store/index";
import { NotificationManager } from "react-notifications";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class ListaPacientesAtendidos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tickets: [],
      uspId: store.getState().usp,
      fechaMin: moment().add(1, "day").format("YYYY-MM-DD"),
      fechaFiltro: moment().format("YYYY-MM-DD"),
      open: true,
    };

    var hoy = moment().format("YYYY-MM-DD");
    this.listaPacientesAtendidos(hoy);
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  listaPacientesAtendidos = (fecha) => {
    var apiUrl = global.config.modulos.totem + "historial_atenciones/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          fecha: fecha,
          uspId: this.state.uspId,
        },
      })
      .then((res) => {
        var data = res.data;

        this.setState({ tickets: data.tickets });
      });
  };

  render() {
    const cambiarDia = (event) => {
      this.setState({
        fechaFiltro: event.target.value,
      });
      this.listaPacientesAtendidos(event.target.value);
    };

    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
          <DialogContent>
            <h1>Historial de atenciones</h1>
            <div>
              <div className="divBordeTablaHistorialAtenciones">
                <input
                  type="date"
                  id="fechaFiltro"
                  name="fechaFiltro"
                  className="fechaFiltro"
                  value={this.state.fechaFiltro}
                  onChange={cambiarDia}
                  inputProps={{
                    min: this.state.fechaMin,
                  }}
                ></input>
                <MaterialTable
                  title=""
                  columns={[
                    {
                      title: "Hora",
                      field: "hora_tick",
                    },
                    { title: "Rut", field: "rut_usuario" },
                    { title: "Nombre", field: "nombre_usuario" },
                    { title: "Tipo", field: "tit_nombre_opcion" },
                    { title: "Número de atención", field: "tit_codigo" },
                  ]}
                  data={this.state.tickets}
                  options={{
                    actionsColumnIndex: -1,
                    search: true,
                  }}
                  localization={{
                    header: {
                      actions: "Acciones",
                    },
                    pagination: {
                      labelDisplayedRows: "{from}-{to} de {count}",
                      labelRowsSelect: "filas",
                      firstTooltip: "primera",
                      previousTooltip: "anterior",
                      nextTooltip: "siguiente",
                      lastTooltip: "última",
                    },
                    toolbar: {
                      nRowsSelected: "{0} fila(s) seleccionadas",
                      searchTooltip: "Buscar",
                      searchPlaceholder: "Buscar",
                    },
                    body: {
                      emptyDataSourceMessage: "No tiene historial de atenciones",
                      filterRow: {
                        filterTooltip: "Buscar",
                      },
                    },
                  }}
                />
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
