import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import GridItem from "../../components/grid/GridItem.js";
import reportes from "./../../assets/css/reportes.css";
import GridContainer from "../../components/grid/GridContainer.js";
import { Button, Dialog, DialogContent } from "@material-ui/core/";
import "react-datepicker/dist/react-datepicker.css";
import { store } from "../../store/index";
import axios from "axios";
export default class ModalGraficoOferta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      dia_semana: props.dia_semana,
      titulo: props.titulo,
      tipo: props.tipo,
      semana: props.semana,
      detalles_oferta: [],
      cesfam: store.getState().cesfam,
      nombre: props.nombre,
      tipo_nombre: "",
    };
    console.log(props.tipo);
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar(false);
  };

  componentDidMount() {
    this.detalleOferta();
    this.cambiar_nomre();
  }
  cambiar_nomre = () => {
    if (this.state.tipo === "entregadas") {
      this.setState({
        tipo_nombre: "reservadas",
      });
    }
    if (this.state.tipo === "programadas") {
      this.setState({
        tipo_nombre: "Habilitadas",
      });
    }
    if (this.state.tipo === "disponibles") {
      this.setState({
        tipo_nombre: "disponibles",
      });
    }
  };

  detalleOferta = () => {
    axios
      .get(global.config.modulos.reportes + "detalleOferta/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
          semana: this.state.semana,
          familia: this.state.titulo,
          cesfam: this.state.cesfam,
          tipo: this.state.tipo,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ detalles_oferta: res.data.datos });
        }
      });
    //console.log('ofertas', this.state.detalles_oferta)
  };

  render() {
    const dia_semana = this.state.dia_semana;
    //console.log(dia_semana)
    const especialidad = this.state.detalles_oferta
      .filter(function (ofer) {
        return ofer.dia == dia_semana;
      })
      .map((esp) => esp.dia_semana);
    const especialidad_unique = [...new Set(especialidad)];

    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="beneficioUsuario">
          <DialogContent className="form-modal">
            <GridContainer className="agendar-container">
              <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
                <img alt="" class="marca-agua"></img>
                <GridContainer className="agendar-container">
                  <GridItem xs={12} style={{ marginTop: 10 }}>
                    <div className="modal-ver-mas-titulo">
                      <h2>
                        Oferta {this.state.nombre} día {especialidad_unique} por especialidad y por horas {this.state.tipo_nombre}
                      </h2>
                    </div>
                  </GridItem>
                  <div>
                    {this.state.detalles_oferta.length > 0 ? (
                      <div className="flex-container">
                        {this.state.detalles_oferta
                          .filter(function (ofer) {
                            return ofer.dia == dia_semana;
                          })
                          .map((ofer, index) => (
                            <div key={index}>
                              <div className="modal-ver-mas-contador" key={ofer.esp_id}>
                                {ofer.veces}
                              </div>
                              <div className="modal-ver-mas-nombre">{ofer.especialidad}</div>
                            </div>
                          ))}
                      </div>
                    ) : (
                      <div>No hay informacion</div>
                    )}
                  </div>
                  <GridItem xs={12} style={{ marginTop: 30 }}>
                    <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={this.salir} style={{ marginLeft: 20 }}>
                      Cerrar
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
