import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components

import moment from "moment";
import "moment/locale/es";
import axios from "axios";

import GridItem from "components/grid/GridItem";
import TextField from "@material-ui/core/TextField";

import { store } from "../../../store/index";
import { Grid, Button, emphasize } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";

import Autocomplete from "@material-ui/lab/Autocomplete";

import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import GraficoBarraFuga from "./GraficoBarraFuga";
//CSS
import crearTotem from "assets/css/crearTotem.module.css";

export default class GraficoFuga extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cesfams: [],
      cesfamNom: "",
      cesOpcion: null,
      comunaNom: "",
      comOpcion: null,
      data: [],
      uspId: store.getState().usp,
      timeSelected: null,
      startDate: null,
      endDate: null,
      showDownload: false,
      areaSelected: null,
      areaName: "",
      subSelected: null,
      displayText: "",
      year: false,
      mes: false,
      sem: false,
      dia: false,
      dateSelect: null,
      prefSel: { label: "Normal", val: 0 },
    };
  }

  unsetGrafs() {
    this.setState({
      year: false,
      mes: false,
      sem: false,
      dia: false,
      timeSelected: null,
      dateSelect: null,
    });
  }

  setComuna = (event, value) => {
    this.setState(
      {
        data: [],
        comunaNom: value.label,
        comunaSelected: value.id,
        comOpcion: value,
        cesOpcion: null,
        cesfamNom: "",
        cesfamSelected: 0,
        barraData: false,
      },
      () => {
        this.cargarCesfams(value.id);
      },
      () => {
        this.unsetGrafs();
      }
    );
  };

  setCesfam = (event, value) => {
    this.setState({
      cesfamNom: value.label,
      cesfamSelected: value.id,
      cesOpcion: value,
      areaSelected: null,
      barraData: false,
    });
  };

  setTime = (event, value) => {
    if (value !== null) {
      switch (value.val) {
        case 1:
          this.setState({
            year: false,
            mes: false,
            sem: true,
            dia: false,
            timeSelected: value,
            dateSelect: null,
          });
          break;
        case 2:
          this.setState({
            year: false,
            mes: true,
            sem: false,
            dia: false,
            timeSelected: value,
            dateSelect: null,
            barraData: false,
          });
          break;
        case 3:
          this.setState(
            {
              year: true,
              mes: false,
              sem: false,
              dia: false,
              timeSelected: value,
              dateSelect: null,
              barraData: false,
            },
            () => {
              this.fillData();
            }
          );
          break;
        case 0:
          this.setState({
            year: false,
            mes: false,
            sem: false,
            dia: true,
            timeSelected: value,
            dateSelect: null,
            barraData: false,
          });
          break;
        default:
          this.setState({
            year: false,
            mes: false,
            sem: false,
            dia: false,
            timeSelected: value,
            dateSelect: null,
            barraData: false,
          });
          break;
      }
    } else {
      this.setState({
        year: false,
        mes: false,
        sem: false,
        dia: false,
        timeSelected: value,
        dateSelect: null,
        barraData: false,
      });
    }
  };

  setArea = (event, value) => {
    this.unsetGrafs();
    if (value === null) {
      this.setState({
        areaSelected: null,
        subSelected: null,
        modulSelected: null,
        barraData: false,
      });
    } else {
      this.setState(
        {
          areaSelected: value,
          subSelected: null,
          areaName: value.label,
          modulSelected: null,
          barraData: false,
        },
        () => {
          this.props.cargarSubOpciones(value.id);
          this.props.cargarModulos(value.id, null);
        }
      );
    }
  };

  setArOp = (event, value) => {
    this.unsetGrafs();
    this.setState({ subSelected: value, subName: value.label }, () => {
      this.props.cargarModulos(this.state.areaSelected.id, value.id);
    });
  };

  setModule = (event, value) => {
    this.setState({ modulSelected: value, modulName: value.label, barraData: false }, () => {
      this.fillData();
    });
  };

  cargarCesfams = async (comuna) => {
    const apiUrl = global.config.modulos.admin_totem + "lista_cesfams/?comuna=" + comuna;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.cesfams.length > 0) {
        this.setState({ cesfams: [] });
        res.data.cesfams.map((res) => {
          this.setState({
            cesfams: [...this.state.cesfams, { label: res.ces_nombre, val: res.ces_id, id: res.ces_id }],
          });
        });
      }
    });
  };

  setStartFecha = (e) => {
    this.setState({ startDate: e });
  };

  setEndFecha = (e) => {
    this.setState({ endDate: e });
  };

  setFecha = (e) => {
    this.setState({ dateSelect: e, barraData: false }, () => {
      this.fillData();
    });
  };

  setPref = (event, value) => {
    if (value !== null) {
      switch (value.val) {
        case 0:
          this.setState({ prefSel: value, barraData: false }, () => {
            this.fillData();
          });
          break;
        case 1:
          this.setState({ prefSel: value, barraData: false }, () => {
            this.fillData();
          });
          break;
        case 2:
          this.setState({ prefSel: value, barraData: false }, () => {
            this.fillData();
          });
      }
    } else {
      this.setState(
        {
          prefSel: value,
          barraData: false,
        },
        () => {
          this.fillData();
        }
      );
    }
  };

  componentDidMount() {}

  formatFecha(sem, year) {
    var fechaInicioSemana = moment().years(year).isoWeek(sem).startOf("isoweek");

    return moment(fechaInicioSemana).format("YYYY-MM-DD");
  }

  fillData = async () => {
    var text = "";
    if (this.state.year) {
      var prefSel = "Normal";
      var pref = 0;
      if (this.state.prefSel != null) {
        prefSel = this.state.prefSel.label;
        pref = this.state.prefSel.val;
      }

      text = "Fuga de pacientes " + prefSel + "es entre año y año en el cesfam " + this.state.cesfamNom + " en área de atención ";

      var apiUrl = global.config.modulos.admin_totem + "tickets_fuga/?sub_opcion=3&cesfam=" + this.state.cesOpcion.id + "&pref=" + pref;

      if (this.state.subSelected !== null) {
        apiUrl = apiUrl + "&area=" + this.state.subSelected.id + "&area_parent=" + this.state.areaSelected.id;
        text = text + " " + this.state.areaSelected.label + " " + this.state.subSelected.label;
      } else if (this.state.areaSelected !== null) {
        apiUrl = apiUrl + "&area=" + this.state.areaSelected.id;
        text = text + " " + this.state.areaSelected.label;
      }
      if (this.state.modulSelected) {
        apiUrl = apiUrl + "&modulo=" + this.state.modulSelected.id;
        text = text + ", módulo " + this.state.modulSelected.label;
      }

      this.setState({ data: [] });
      axios.get(apiUrl, { headers: authHeader() }).then((res) => {
        this.setState({
          data: res.data.tickets,
          displayText: text,
          barraData: true,
        });
      });
    }

    if (this.state.mes && this.state.dateSelect !== null) {
      var prefSel = "Normal";
      var pref = 0;
      if (this.state.prefSel != null) {
        prefSel = this.state.prefSel.label;
        pref = this.state.prefSel.val;
      }

      text = "Fuga de pacientes " + prefSel + "es entre mes y mes, año " + moment(this.state.dateSelect).format("YYYY") + " en el cesfam " + this.state.cesfamNom + " en área de atención ";

      var apiUrl = global.config.modulos.admin_totem + "tickets_fuga/?sub_opcion=2&cesfam=" + this.state.cesOpcion.id + "&year=" + moment(this.state.dateSelect).format("YYYY") + "&pref=" + pref;

      if (this.state.subSelected !== null) {
        apiUrl = apiUrl + "&area=" + this.state.subSelected.id + "&area_parent=" + this.state.areaSelected.id;
        text = text + " " + this.state.areaSelected.label + " " + this.state.subSelected.label;
      } else if (this.state.areaSelected !== null) {
        apiUrl = apiUrl + "&area=" + this.state.areaSelected.id;
        text = text + " " + this.state.areaSelected.label;
      }
      if (this.state.modulSelected) {
        apiUrl = apiUrl + "&modulo=" + this.state.modulSelected.id;
        text = text + ", módulo " + this.state.modulSelected.label;
      }

      this.setState({ data: [] });
      axios.get(apiUrl, { headers: authHeader() }).then((res) => {
        this.setState({
          data: res.data.tickets,
          displayText: text,
          barraData: true,
        });
      });
    }

    if (this.state.sem && this.state.dateSelect !== null) {
      var prefSel = "Normal";
      var pref = 0;
      if (this.state.prefSel != null) {
        prefSel = this.state.prefSel.label;
        pref = this.state.prefSel.val;
      }

      text = "Fuga de pacientes " + prefSel + "es entre semana y semana, " + moment(this.state.dateSelect).format("MMMM") + " " + moment(this.state.dateSelect).format("YYYY") + " en el cesfam " + this.state.cesfamNom + " en área de atención ";

      var apiUrl = global.config.modulos.admin_totem + "tickets_fuga/?sub_opcion=1&cesfam=" + this.state.cesOpcion.id + "&year=" + moment(this.state.dateSelect).format("YYYY") + "&mes=" + moment(this.state.dateSelect).format("MM") + "&pref=" + pref;

      if (this.state.subSelected !== null) {
        apiUrl = apiUrl + "&area=" + this.state.subSelected.id + "&area_parent=" + this.state.areaSelected.id;
        text = text + " " + this.state.areaSelected.label + " " + this.state.subSelected.label;
      } else if (this.state.areaSelected !== null) {
        apiUrl = apiUrl + "&area=" + this.state.areaSelected.id;
        text = text + " " + this.state.areaSelected.label;
      }
      if (this.state.modulSelected) {
        apiUrl = apiUrl + "&modulo=" + this.state.modulSelected.id;
        text = text + ", módulo " + this.state.modulSelected.label;
      }

      this.setState({ data: [] });
      axios.get(apiUrl, { headers: authHeader() }).then((res) => {
        this.setState({
          data: res.data.tickets,
          displayText: text,
          barraData: true,
        });
      });
    }

    if (this.state.dia && this.state.dateSelect !== null) {
      var prefSel = "Normal";
      var pref = 0;
      if (this.state.prefSel != null) {
        prefSel = this.state.prefSel.label;
        pref = this.state.prefSel.val;
      }

      text = "Fuga de pacientes " + prefSel + "es entre dia y dia, semana " + moment(this.state.dateSelect).format("L") + " en el cesfam " + this.state.cesfamNom + " en área de atención ";

      var apiUrl = global.config.modulos.admin_totem + "tickets_fuga/?sub_opcion=0&cesfam=" + this.state.cesOpcion.id + "&year=" + moment(this.state.dateSelect).format("YYYY") + "&sem=" + moment(this.state.dateSelect).format("W") + "&pref=" + pref;

      if (this.state.subSelected !== null) {
        apiUrl = apiUrl + "&area=" + this.state.subSelected.id + "&area_parent=" + this.state.areaSelected.id;
        text = text + " " + this.state.areaSelected.label + " " + this.state.subSelected.label;
      } else if (this.state.areaSelected !== null) {
        apiUrl = apiUrl + "&area=" + this.state.areaSelected.id;
        text = text + " " + this.state.areaSelected.label;
      }
      if (this.state.modulSelected) {
        apiUrl = apiUrl + "&modulo=" + this.state.modulSelected.id;
        text = text + ", módulo " + this.state.modulSelected.label;
      }

      this.setState({ data: [] });
      axios.get(apiUrl, { headers: authHeader() }).then((res) => {
        this.setState({
          data: res.data.tickets,
          displayText: text,
          barraData: true,
        });
      });
    }
  };

  handleBarCLick = (datos) => {
    const { year } = this.state;
    const { mes } = this.state;
    const { sem } = this.state;
    if (year) {
      let anno = moment(datos.date, "YYYY");
      this.setState(
        {
          year: false,
          mes: true,
          timeSelected: { label: "Mensual", val: 2 },
          dateSelect: anno,
          barraData: false,
        },
        () => {
          this.fillData();
        }
      );
    } else if (mes) {
      let mess = datos.date;
      let yr = moment(this.state.dateSelect).format("YYYY");

      let fecha2 = mess + "-" + yr;
      fecha2 = moment(fecha2, "MMM-YYYY");

      this.setState(
        {
          mes: false,
          sem: true,
          timeSelected: { label: "Semanal", val: 1 },
          dateSelect: fecha2,
          barraData: false,
        },
        () => {
          this.fillData();
        }
      );
    } else if (sem) {
      let semm = datos.weeknum;
      let yr = moment(this.state.dateSelect).format("YYYY");
      let fecha2 = moment().year(yr).week(semm);

      this.setState(
        {
          sem: false,
          dia: true,
          timeSelected: { label: "Diario", val: 0 },
          dateSelect: fecha2,
          barraData: false,
        },
        () => {
          this.fillData();
        }
      );
    }
  };

  render() {
    const { data } = this.state;
    return (
      <>
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: "2%" }}>
          <Grid container spacing={3}>
            <Grid item xs={1} sm={1} md={1}>
              <InputLabel className={crearTotem.textosTitulos}>Comuna </InputLabel>
              <Autocomplete
                disablePortal
                disableClearable
                value={this.state.comOpcion}
                id="combo-box-comuna"
                options={this.props.comunas}
                className={crearTotem.selectores}
                style={{ padding: "0px 0px 0px 10px" }}
                getOptionLabel={(option) => option.label}
                defaultValue={this.state.comOpcion}
                onChange={this.setComuna.bind(this)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
              ></Autocomplete>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <InputLabel className={crearTotem.textosTitulos}>Cesfam </InputLabel>
              <Autocomplete
                disablePortal
                disableClearable
                value={this.state.cesOpcion}
                id="combo-box-cesfam"
                className={crearTotem.selectores}
                style={{ padding: "0px 0px 0px 10px" }}
                defaultValue={this.state.cesOpcion}
                options={this.state.cesfams}
                noOptionsText={"Seleccione una comuna primero"}
                getOptionLabel={(option) => option.label}
                onChange={this.setCesfam.bind(this)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
              ></Autocomplete>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <InputLabel className={crearTotem.textosTitulos}>Tipo de atención </InputLabel>
              <Autocomplete
                disablePortal
                id="combo-box-atencion"
                className={crearTotem.selectores}
                style={{ padding: "0px 0px 0px 10px" }}
                options={this.props.atenciones}
                getOptionLabel={(option) => option.label}
                onChange={this.setArea.bind(this)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
              ></Autocomplete>
            </Grid>
            {this.props.opAteVisible ? (
              <Grid item xs={1} sm={1} md={1}>
                <Autocomplete disablePortal disableClearable id="combo-box-subatencion" options={this.props.opAte} value={this.state.subSelected} defaultValue={this.state.subSelected} noOptionsText={this.state.areaSelected ? "Esta área no posee opciones" : "Seleccione una área primero"} getOptionLabel={(option) => option.label} onChange={this.setArOp.bind(this)} renderInput={(params) => <TextField {...params} label="Opcion" />}></Autocomplete>
              </Grid>
            ) : null}
            <Grid item xs={1} sm={1} md={1}>
              <InputLabel className={crearTotem.textosTitulos}>Módulo </InputLabel>
              <Autocomplete
                disablePortal
                disableClearable
                id="combo-box-modulo"
                options={this.props.modulos}
                className={crearTotem.selectores}
                style={{ padding: "0px 0px 0px 10px" }}
                noOptionsText={"Seleccione una atención primero"}
                getOptionLabel={(option) => option.label}
                value={this.state.modulSelected}
                defaultValue={this.state.modulSelected}
                onChange={this.setModule.bind(this)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
              ></Autocomplete>
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <InputLabel className={crearTotem.textosTitulos}>Tiempo </InputLabel>
              <Autocomplete
                disablePortal
                id="combo-box-tiempo"
                options={this.props.tiempos}
                getOptionLabel={(option) => option.label}
                value={this.state.timeSelected}
                defaultValue={this.state.timeSelected}
                className={crearTotem.selectores}
                style={{ padding: "0px 0px 0px 10px" }}
                onChange={this.setTime.bind(this)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
              ></Autocomplete>
            </Grid>
            {this.state.mes ? (
              <Grid item xs={1} sm={1} md={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <KeyboardDatePicker
                    disableToolbar
                    invalidDateMessage="¡Fecha mal ingresada, por favor inténtalo nuevamente!"
                    disableFuture="true"
                    openTo="year"
                    views={["year"]}
                    value={this.state.dateSelect}
                    initialFocusedDate={this.dateSelect}
                    format="yyyy"
                    id="date-picker-inline"
                    label="Año"
                    name="yearpick"
                    onChange={(e) => this.setFecha(e)}
                    KeyboardButtonProps={{
                      "aria-label": "cambiar fecha",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            ) : null}

            {this.state.sem ? (
              <Grid item xs={1} sm={1} md={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <KeyboardDatePicker
                    disableToolbar
                    invalidDateMessage="¡Fecha mal ingresada, por favor inténtalo nuevamente!"
                    disableFuture="true"
                    openTo="month"
                    views={["year", "month"]}
                    value={this.state.dateSelect}
                    initialFocusedDate={this.dateSelect}
                    format="yyyy-MM"
                    id="date-picker-inline"
                    label="Año y mes"
                    name="yearmonthpick"
                    onChange={(e) => this.setFecha(e)}
                    KeyboardButtonProps={{
                      "aria-label": "cambiar fecha",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            ) : null}

            {this.state.dia ? (
              <Grid item xs={1} sm={1} md={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <KeyboardDatePicker
                    disableToolbar
                    invalidDateMessage="¡Fecha mal ingresada, por favor inténtalo nuevamente!"
                    disableFuture="true"
                    openTo="date"
                    views={["year", "month", "date"]}
                    value={this.state.dateSelect}
                    initialFocusedDate={this.dateSelect}
                    format="yyyy-MM-dd"
                    id="date-picker-inline"
                    label="Fecha de semana"
                    name="yearmonthweekpick"
                    onChange={(e) => this.setFecha(e)}
                    KeyboardButtonProps={{
                      "aria-label": "cambiar fecha",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            ) : null}

            <Grid item xs={1} sm={1} md={2}>
              <InputLabel className={crearTotem.textosTitulos}>Preferencia </InputLabel>
              <Autocomplete
                disablePortal
                id="combo-box-pref"
                options={this.props.opPref}
                getOptionLabel={(option) => option.label}
                value={this.state.prefSel}
                className={crearTotem.selectores}
                style={{ padding: "0px 0px 0px 10px" }}
                defaultValue={this.state.prefSel}
                onChange={this.setPref.bind(this)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
              ></Autocomplete>
            </Grid>
          </Grid>
        </GridItem>
        <br></br>
        <h3 className="textColor">{this.state.displayText}</h3>
        <div className="coverVac chartContainer chartGrid">
          <div class="chart">{this.state.barraData ? <GraficoBarraFuga data={data} filename={this.state.displayText} handleBarCLick={this.handleBarCLick} /> : null}</div>
        </div>
      </>
    );
  }
}
