import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import InputLabel from "@material-ui/core/InputLabel";

export default class ModificarClaveApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      rut_usuario: props.rut_usuario,
      clave: "",
      confirmarClave: "",
      formErrors: {},
      open: true,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  validaciones() {
    const { clave, confirmarClave } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (!clave) {
      formIsValid = false;
      formErrors["passErr"] = "Escribe la contraseña";
    }

    if (clave && clave.length < 6) {
      formIsValid = false;
      formErrors["passErr"] = "La contraseña debe ser de 6 dígitos";
    }

    if (!confirmarClave) {
      formIsValid = false;
      formErrors["passConfErr"] = "Escribe la contraseña";
    }

    if (clave != confirmarClave) {
      formIsValid = false;
      formErrors["passConfErr"] = "Las contraseñas deben ser iguales";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  enviarFormulario = (e) => {
    if (this.validaciones()) {
      var data = {
        rut: this.state.rut_usuario,
        clave: this.state.clave,
        tipo_usuario: 1,
      };
      let apiUrl = global.config.modulos.aplicaciones + "cambiar_clave/";
      const options = {
        method: "PATCH",
        data: data,
        headers: authHeader(),
      };
      axios(apiUrl, options).then((res) => {
        NotificationManager.success("Contraseña modificada con éxito");
        this.salir();
      });
    }
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { passErr, passConfErr } = this.state.formErrors;

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
              <img className="marca-agua"></img>
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Modificar contraseña de app móvil y portal web</h2>
                </GridItem>
                <GridItem xs={8} style={{ marginTop: 10 }}>
                  <div className={"modalModificarUsuarioDatos"} style={{ float: "left", marginRight: "10%" }}>
                    <InputLabel>Contraseña numérica 6 dígitos</InputLabel>
                    <input
                      type="number"
                      name="clave"
                      value={this.state.clave}
                      onChange={this.cambiarValores}
                      placeholder=""
                      className={"inputText"}
                      onInput={(e) => {
                        e.target.value = e.target.value.length <= 6 ? e.target.value : e.target.value.slice(0, -1);
                      }}
                      min="0"
                    />
                    {passErr && <div style={{ color: "red", paddingBottom: 10 }}>{passErr}</div>}
                  </div>
                  <div className={"modalModificarUsuarioDatos"} style={{ float: "left", marginRight: "10%" }}>
                    <InputLabel>Repetir Contraseña numérica 6 dígitos</InputLabel>
                    <input
                      type="number"
                      name="confirmarClave"
                      value={this.state.confirmarClave}
                      onChange={this.cambiarValores}
                      placeholder=""
                      className={"inputText"}
                      onInput={(e) => {
                        e.target.value = e.target.value.length <= 6 ? e.target.value : e.target.value.slice(0, -1);
                      }}
                      min="0"
                    />
                    {passConfErr && <div style={{ color: "red", paddingBottom: 10 }}>{passConfErr}</div>}
                  </div>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={this.salir} style={{ marginLeft: 20 }}>
                    Cancelar
                  </Button>
                  <Button className="botonDialog fondoVerde" onClick={this.enviarFormulario}>
                    Modificar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
