import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import moment from "moment";
//import "moment/locale/es";
import { connect } from "react-redux";
import chileanRut from "chilean-rut";

// core components
import ver_mas from "../../assets/img/iconos correccion/ver mas.svg";
import errores_carga from "../../assets/img/lista_espera/descargar error de carga.svg";
import eliminar from "../../assets/img/iconos correccion/icono_eliminar.svg";
import modi_icon from "../../assets/img/usuarios/icono_modificar.svg";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Titulo from "../../components/titulo/Titulo.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import MaterialTable from "material-table";
import Paginacion from "./../../components/Table/Pagination.js";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Tooltip from "@material-ui/core/Tooltip";
import Buttons from "react-multi-date-picker/components/button";
import { FormControl } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "react-multi-date-picker/components/button";
import { Input } from "@material-ui/core";
import TableHeader from "../../components/Table/TableHeader";
import AgregarUsuarioPercapitado from "../../components/NoPercapitados/AgregarUsuarioPercapitado";
import EliminarPercapitado from "../../components/NoPercapitados/EliminarPercapitado";
import { DialogContentText } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { NotificationManager } from "react-notifications";
import { CSVReader } from "react-papaparse";
import { LinearProgress } from "@material-ui/core";

export default class CargarTratamientos extends Component {
  constructor() {
    super();

    this.state = {
      mostrarModalNuevoPeriodo: false,
      datos: [],
      Periodos: [],
      periodo: null,
      validos: null,
      tipocarga: null,
      usp_id: store.getState().usp,
      cesfam_admin: store.getState().cesfam,
      estado_gestion: 0,
      tipo_lista: 0,
    };
    this.buttonRef = React.createRef();
  }
  handleOnDrop = (data) => {
    this.setState({ datos: data });
    console.log(data.rut);
  };
  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOnRemoveFile = (data) => {
    this.setState({
      datos: [],
    });
  };
  componentDidMount() {
    this.obtenerCesfamGestion();
    this.obtenerCesfams_evaluacion();
  }
  obtenerCesfams_evaluacion = () => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_cesfams_evaluacion/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam_admin,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            cesfam_admin: res.data.datos[0]["tc_cesfam_transformado"],
            tipo_lista: res.data.datos[0]["tc_tipo_lista"],
          });
        }
      });
  };
  obtenerCesfamGestion = () => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_cesfam_gestion/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam_admin,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            estado_gestion: 1,
          });
        } else {
          this.setState({
            estado_gestion: 0,
          });
        }
      });
  };
  cargaMasiva = () => {
    document.getElementById("cargando").style.display = "none";
    if (this.state.tipocarga === null) {
      NotificationManager.error("Debe seleccionar un tipo de carga");
    }
    if (this.state.datos.length == 0) {
      NotificationManager.error("No se ha cargado ningun archivo");
      return;
    }

    if (this.state.datos.length > 100000) {
      NotificationManager.error("Sobrepasa los usuarios por carga (100 mil).");
    } else {
      if (this.state.tipocarga == 1) {
        document.getElementById("cargando").style.display = "block";
        axios
          .post(
            global.config.modulos.listaEspera + "carga_tratamiento_oirs/",
            {
              datos: this.state.datos,
              periodo: this.state.periodo,
              usp_id: this.state.usp_id,
              cesfam_admin: this.state.cesfam_admin,
              gestion: this.state.estado_gestion,
            },
            { headers: authHeader() }
          )
          .then((res) => {
            if (res.data.estado) {
              document.getElementById("cargando").style.display = "none";
              NotificationManager.success("Carga en proceso este puede demorar dependiendo de la cantidad de datos a subir.");
              this.setState({
                datos: [],
              });
              this.buttonRef.current.removeFile();
            } else {
              NotificationManager.error(res.data.mensaje_error, "ATENCIÓN", 20000);
            }
          });
      }
      if (this.state.tipocarga == 2) {
        document.getElementById("cargando").style.display = "block";
        axios
          .post(
            global.config.modulos.listaEspera + "cargar_en_tratamiento/",
            {
              datos: this.state.datos,
              periodo: this.state.periodo,
              usp_id: this.state.usp_id,
              cesfam_admin: this.state.cesfam_admin,
            },
            { headers: authHeader() }
          )
          .then((res) => {
            if (res.data.estado) {
              document.getElementById("cargando").style.display = "none";
              NotificationManager.success("Carga en proceso este puede demorar dependiendo de la cantidad de datos a subir.");
              this.setState({
                datos: [],
              });
              this.buttonRef.current.removeFile();
            } else {
              NotificationManager.error(res.data.mensaje_error, "ATENCIÓN", 20000);
            }
          });
      }
      if (this.state.tipocarga == 3) {
        document.getElementById("cargando").style.display = "block";
        axios
          .post(
            global.config.modulos.listaEspera + "carga_altas/",
            {
              datos: this.state.datos,
              periodo: this.state.periodo,
              usp_id: this.state.usp_id,
              cesfam_admin: this.state.cesfam_admin,
            },
            { headers: authHeader() }
          )
          .then((res) => {
            if (res.data.estado) {
              document.getElementById("cargando").style.display = "none";
              NotificationManager.success("Carga en proceso este puede demorar dependiendo de la cantidad de datos a subir .");
              this.setState({
                datos: [],
              });
              this.buttonRef.current.removeFile();
            } else {
              NotificationManager.error(res.data.mensaje_error, "ATENCIÓN", 20000);
            }
          });
      }
      if (this.state.tipocarga == 4) {
        document.getElementById("cargando").style.display = "block";
        axios
          .post(
            global.config.modulos.listaEspera + "carga_usuarios_espera_nsp/",
            {
              datos: this.state.datos,
              periodo: this.state.periodo,
              usp_id: this.state.usp_id,
              cesfam_admin: this.state.cesfam_admin,
            },
            { headers: authHeader() }
          )
          .then((res) => {
            if (res.data.estado) {
              document.getElementById("cargando").style.display = "none";
              NotificationManager.success("Carga en proceso este puede demorar dependiendo de la cantidad de datos a subir .");
              this.setState({
                datos: [],
              });
              this.buttonRef.current.removeFile();
            } else {
              NotificationManager.error(res.data.mensaje_error, "ATENCIÓN", 20000);
            }
          });
      }
    }
  };
  descargarExcel = async () => {
    //this.setState({ loading: true });
    NotificationManager.warning("Se inicio proceso de descarga de Excel esto puede tardar unos segundos espere por favor", "Descargando....", 3000);
    await axios.get((window.location.href = global.config.modulos.listaEspera + "excel_errores_cargas/?tipo=" + this.state.tipocarga + "&cesfam=" + this.state.cesfam_admin), { headers: authHeader() }).then((res) => {
      this.setState({ loading: false });
    });
  };
  /* mostrarCrearPeriodo = () => {
    this.setState({ mostrarModalNuevoPeriodo: true, });
  };

  ocultarCrearPeriodo = () => {
    this.setState({ mostrarModalNuevoPeriodo: false });
    this.traerPeriodos()
  }; */
  /* 
  traerPeriodos() {
    axios
      .get(global.config.modulos.listaOirs+"ver_periodo_oirs/",{headers: authHeader(),})
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            Periodos: res.data.datos,
          });
        }
        
      })
      .catch((err) => {
        console.log(err);
      });

  }; */
  /* 
  seleccionarperiodo = (e) => {
    const periodo = e.target.value;
    this.setState({ periodo: periodo });
    //console.log(cesfam);
  }; */
  seleccionartipocarga = (e) => {
    const tipocarga = e.target.value;
    this.setState({ tipocarga: tipocarga });
    //console.log(cesfam);
  };

  render() {
    return (
      <div className="middle-content">
        <div style={{ width: "100%", marginBottom: "5px" }}>
          {this.state.tipo_lista == 2 ? (
            <FormControl style={{ width: "50%", marginBottom: "5px" }}>
              <InputLabel id="demo-simple-select-label">Seleccione tipo carga</InputLabel>
              <Select onChange={this.seleccionartipocarga} value={this.state.tipocarga} name="tipocarga">
                <MenuItem value={null}>Seleccione tipo carga</MenuItem>
                <MenuItem value={3}>Carga de altas</MenuItem>
                <MenuItem value={4}>Carga de nsp</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <FormControl style={{ width: "50%", marginBottom: "5px" }}>
              <InputLabel id="demo-simple-select-label">Seleccione tipo carga</InputLabel>
              <Select onChange={this.seleccionartipocarga} value={this.state.tipocarga} name="tipocarga">
                <MenuItem value={null}>Seleccione tipo carga</MenuItem>
                <MenuItem value={1}>Carga lista de espera</MenuItem>
                <MenuItem value={2}>Carga en tratamiento</MenuItem>
                <MenuItem value={3}>Carga de altas</MenuItem>
                <MenuItem value={4}>Carga de nsp</MenuItem>
              </Select>
            </FormControl>
          )}
        </div>
        <div>
          {this.state.tipocarga == 1 ? (
            this.state.estado_gestion == 1 ? (
              <div
                style={{
                  marginBottom: "20px",
                  marginTop: "20px",
                  display: "flex",
                }}
              >
                <p style={{ fontSize: "13px" }}>CODIGO // RUT // NOMBRE // SEXO // EDAD // CESFAM // TELEFONO1 // TELEFONO2 // FECHA // TIPO ATENCION // OBSERVACION // OBSERVACION2 // (sin espacios ni acentos)</p>
                <Tooltip title="Descargar excel con errores de carga" aria-label="Descargar excel con errores de carga">
                  <img className="imagenBotonAccionMenu" src={errores_carga} onClick={() => this.descargarExcel()} style={{ width: "50px", height: "50px", marginLeft: "50%" }} />
                </Tooltip>
              </div>
            ) : (
              <div
                style={{
                  marginBottom: "20px",
                  marginTop: "20px",
                  display: "flex",
                }}
              >
                <p style={{ fontSize: "13px" }}>CODIGO // RUT // NOMBRE // SEXO // EDAD // CESFAM // TELEFONO1 // TELEFONO2 // FECHA // OBSERVACION // OBSERVACION2 // (sin espacios ni acentos)</p>
                <Tooltip title="Descargar excel con errores de carga" aria-label="Descargar excel con errores de carga">
                  <img className="imagenBotonAccionMenu" src={errores_carga} onClick={() => this.descargarExcel()} style={{ width: "50px", height: "50px", marginLeft: "50%" }} />
                </Tooltip>
              </div>
            )
          ) : null}
          {this.state.tipocarga == 2 ? (
            <div
              style={{
                marginBottom: "20px",
                marginTop: "20px",
                display: "flex",
              }}
            >
              <p style={{ fontSize: "13px" }}>RUT // TIPO DE ATENCION // FECHA CITA // PACIENTE // (sin espacios ni acentos)</p>
              <Tooltip title="Descargar excel con errores de carga" aria-label="Descargar excel con errores de carga">
                <img className="imagenBotonAccionMenu" src={errores_carga} onClick={() => this.descargarExcel()} style={{ width: "50px", height: "50px", marginLeft: "75%" }} />
              </Tooltip>
            </div>
          ) : null}
          {this.state.tipocarga == 3 ? (
            <div
              style={{
                marginBottom: "20px",
                marginTop: "20px",
                display: "flex",
              }}
            >
              <p style={{ fontSize: "13px" }}>RUT // TIPO DE ALTA // FECHA_DE_ALTA // PACIENTE //TIPO DE ATENCION // (sin espacios ni acentos)</p>
              <Tooltip title="Descargar excel con errores de carga" aria-label="Descargar excel con errores de carga">
                <img className="imagenBotonAccionMenu" src={errores_carga} onClick={() => this.descargarExcel()} style={{ width: "50px", height: "50px", marginLeft: "75%" }} />
              </Tooltip>
            </div>
          ) : null}
          {this.state.tipocarga == 4 ? (
            <div
              style={{
                marginBottom: "20px",
                marginTop: "20px",
                display: "flex",
              }}
            >
              <p style={{ fontSize: "13px" }}>RUT // FECHA HORA CITA // PACIENTE (sin espacios ni acentos)</p>
              <Tooltip title="Descargar excel con errores de carga" aria-label="Descargar excel con errores de carga">
                <img className="imagenBotonAccionMenu" src={errores_carga} onClick={() => this.descargarExcel()} style={{ width: "50px", height: "50px", marginLeft: "80%" }} />
              </Tooltip>
            </div>
          ) : null}
          <CSVReader
            ref={this.buttonRef}
            onDrop={this.handleOnDrop}
            onError={this.handleOnError}
            addRemoveButton
            removeButtonColor="#659cef"
            onRemoveFile={this.handleOnRemoveFile}
            config={{
              header: true,
              dynamicTyping: true,
              skipEmptyLines: true,
              transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
            }}
          >
            <span>Arrastra el archivo CSV o presiona para cargar.</span>
          </CSVReader>
          <div style={{ position: "right", width: "100%", marginBottom: "200px" }}>
            <Buttons
              onClick={this.cargaMasiva}
              className={"btnOpcion fondoNaranjo"}
              style={{
                display: "block",
                paddingLeft: "2%",
                paddingRight: "2%",
                cursor: "pointer",
                marginTop: "10px",
                position: "right",
                fontSize: "16px",
                float: "left",
                width: "250px",
                border: "0",
                textAlign: "center",
              }}
            >
              Subir tratamientos Oirs
            </Buttons>
            <LinearProgress id="cargando" style={{ display: "none" }} />
          </div>
        </div>
      </div>
    );
  }
}
