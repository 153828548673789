import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment, { relativeTimeThreshold } from "moment";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert"; // Import
import TimePicker from "rc-time-picker";

// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class FinalizarCaso extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: parseInt(store.getState().usp),
      open: true,
      solucion: "",
      especialidad: "",
      activarTeleconsulta: false,
      activarPresencial: false,
      gegId: props.gegId,
      chat: props.chat,
      usuId: props.usuId,
      usuCesfam: null,
      existeProfesional: false,
      profesional: "",
      existeContactoTelefono: false,
      existeContactoTelefonoOtro: false,
      profesionales: [],
      fecha: "",
      hora: "",
      telefonos: [],
      telefono: "",
      contacto: 1,
      canal: 5,
      estadoAgenda: props.estadoAgenda,
      horaReservada: false,
      canalesTeleconsulta: [],
    };
  }

  componentDidMount() {
    this.traerUsuario();
    this.traerTelefonos();
    this.canalesTeleconsulta();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  traerTelefonos = () => {
    let apiUrl = global.config.modulos.telefonos + "telefonos_por_usuario/" + this.state.usuId;

    axios(apiUrl, {
      headers: authHeader(),
    }).then((res) => {
      this.setState({
        telefonos: res.data.datos,
      });
    });
  };

  traerProfesionales = (espId) => {
    let especialidad = espId;
    let cesfams = [this.state.usuCesfam];

    axios
      .get(global.config.modulos.profesionales + "profesionales_por_sigla_especialidad/", {
        headers: authHeader(),
        params: { sigla: especialidad, cesfam: cesfams },
      })
      .then((res) => {
        this.setState({
          existeProfesional: false,
          existeHorario: false,
          existeContactoTelefono: false,
          existeContactoTelefonoOtro: false,
          profesionales: [],
          horas: [],
          profesional: "",
          hora: "",
          telefono: "",
          contacto: 1,
        });
        if (res.data.datos.length > 0) {
          this.setState({
            profesionales: res.data.datos,
            existeProfesional: true,
          });
        } else {
          NotificationManager.info("No hay profesionales con este tipo de atención");
        }
      });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    if (value === "Se agenda teleconsulta" && this.state.estadoAgenda.length == 0) {
      return this.setState({
        activarTeleconsulta: true,
        activarPresencial: false,
        especialidad: "",
      });
    } else if (value === "Se agenda atención presencial" && this.state.estadoAgenda.length == 0) {
      return this.setState({
        activarTeleconsulta: false,
        activarPresencial: true,
        especialidad: "",
      });
    }

    this.setState({
      activarTeleconsulta: false,
      activarPresencial: false,
      existeProfesional: false,
      especialidad: "",
    });
  };

  cambiarValor = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    if (name == "especialidad") {
      axios
        .get(global.config.modulos.reservas + "hora_pendiente/", {
          headers: authHeader(),
          params: {
            usuario: this.state.usuId,
            especialidad: value,
            cesfam: this.state.usuCesfam,
          },
        })
        .then((res) => {
          if (res.data.estado) {
            confirmAlert({
              title: "",
              message: res.data.datos.mensaje,
              buttons: [
                {
                  label: "Aceptar",
                  className: "btnCancelar",
                },
              ],
            });
            this.setState({
              especialidad: value,
              estadoAgenda: res.data.datos.agenda,
              horaReservada: true,
              existeProfesional: false,
            });
            NotificationManager.info(res.data.datos.mensaje);
          } else {
            this.setState({
              estadoAgenda: 0,
              horaReservada: false,
            });
            this.traerProfesionales(value);
          }
        });
    }

    if (name == "contacto") {
      if (value == 3) {
        this.setState({ existeContactoTelefono: true });
      } else {
        this.setState({ existeContactoTelefono: false });
      }
    }

    if (name == "telefono") {
      if (value == "otro") {
        this.setState({ existeContactoTelefonoOtro: true });
      } else {
        this.setState({ existeContactoTelefonoOtro: false });
      }
    }
  };

  traerUsuario() {
    let apiUrl = global.config.modulos.usuarios + "por_id/" + this.state.usuId;
    axios(apiUrl, {
      headers: authHeader(),
    }).then((res) => {
      this.setState(
        {
          usuCesfam: res.data.datos.usu_cesfam,
        },
        () => {
          this.canalesTeleconsulta();
        }
      );
    });
  }

  finalizarCaso = () => {
    if (this.state.horaReservada) {
      this.horaYaReservada();
    } else if (this.state.activarTeleconsulta || this.state.activarPresencial) {
      this.reservarHora();
    } else {
      let solucion = this.state.solucion;

      if (solucion === "Fallecimiento") {
        confirmAlert({
          title: "",
          message: "Seguro que desea descartar este caso con motivo 'FALLECIMIENTO'? el paciente será eliminado de HoraSalud",
          buttons: [
            {
              label: "Cancelar",
              className: "btnCancelar",
            },
            {
              label: "Aceptar",
              className: "btnAceptar",
              onClick: () => {
                axios
                  .patch(
                    global.config.modulos.usuarios + "por_id/" + this.state.usuId,
                    {
                      usu_id: this.state.usuId,
                      usu_estado: 2,
                      usu_motivo_eliminacion: 5,
                    },
                    {
                      headers: authHeader(),
                    }
                  )
                  .then((res) => {
                    this.modificarGestion();
                  });
              },
            },
          ],
        });
      } else {
        this.modificarGestion();
      }
    }
  };

  modificarGestion = () => {
    let especialidad = this.state.especialidad;

    axios
      .patch(
        global.config.modulos.gestion,
        {
          geg_id: this.state.gegId,
          geg_solucion: this.state.solucion,
          geg_especialidad: especialidad,
          geg_ubicacion: 6,
          geg_estado: 3,
          geg_fecha_finalizacion: moment().format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (this.state.chat > 0) {
          axios
            .patch(
              global.config.modulos.chat + "modificar_chat/",
              {
                id: this.state.chat,
                cht_estado: 0,
              },
              {
                headers: authHeader(),
              }
            )
            .then(() => {});
        }
        NotificationManager.success("Caso finalizado con éxito");
        this.generarHistorial("Se finaliza el caso", this.state.gegId, this.state.uspId);
        this.salir();
      });
  };

  generarHistorial = (accion, id, usp) => {
    axios
      .post(
        global.config.modulos.gestion + "historial/",
        {
          accion: accion,
          gestion: id,
          usuario_panel: usp,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        this.salir();
      });
  };

  reservarHora = () => {
    if (!this.validaciones()) {
      return;
    }

    confirmAlert({
      title: "",
      message: "Segur@ que desea reservar esta hora?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
          onClick: () => null,
        },
        {
          label: "Aceptar",
          className: "btnAceptar",
          onClick: () => {
            let hora_atencion = this.state.hora.format("HH:mm");
            axios
              .post(
                global.config.modulos.reservas + "crear_hora/",
                {
                  fecha: this.state.fecha,
                  hora: hora_atencion,
                  usuario: this.state.usuId,
                  profesional: this.state.profesional,
                  especialidad: this.state.especialidad,
                  usuario_panel: this.state.uspId,
                },
                {
                  headers: authHeader(),
                }
              )
              .then((res) => {
                if (res.data.estado) {
                  this.reservarHorarioCreado(res.data.horario);
                } else {
                  NotificationManager.error(res.data.mensaje);
                }
              });
          },
        },
      ],
    });
  };

  horaYaReservada = () => {
    axios
      .patch(
        global.config.modulos.gestion,
        {
          geg_id: this.state.gegId,
          geg_agenda: this.state.estadoAgenda,
          geg_tipo_contacto: 1,
        },
        {
          headers: authHeader(),
        }
      )
      .then(() => {
        this.modificarGestion();
      });
  };

  reservarHorarioCreado = (horario) => {
    let telefono = this.state.telefono;
    if (telefono == "otro") {
      this.agregarTelefono(this.state.otroTelefono, this.state.usuId, this.state.uspId);
      telefono = this.state.otroTelefono;
    }

    axios
      .post(
        global.config.modulos.agendamientos + "agendar_horario/",
        {
          horario: horario,
          cesfam: this.state.usuCesfam,
          usp_id: this.state.uspId,
          canal: this.state.canal,
          usu_id: this.state.usuId,
          tipo_contacto: this.state.contacto,
          telefono_contacto: telefono,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        axios
          .patch(
            global.config.modulos.gestion,
            {
              geg_id: this.state.gegId,
              geg_agenda: res.data.agenda,
              geg_especialidad: this.state.especialidad,
              geg_tipo_contacto: this.state.contacto,
              geg_telefono_contacto: telefono,
            },
            {
              headers: authHeader(),
            }
          )
          .then((res2) => {
            if (res.data.estado) {
              this.modificarGestion();
              NotificationManager.success(res.data.mensaje);
              this.salir();
            } else {
              NotificationManager.error(res.data.mensaje);
              this.salir();
            }

            this.generarHistorial("Se reserva hora", this.state.gegId, this.state.uspId);
          });
      });
  };

  agregarTelefono = (telefono, usuId, uspId) => {
    axios
      .post(
        global.config.modulos.telefonos + "agregar_telefono/",
        {
          telefono: telefono,
          usu_id: usuId,
          tipo: 2,
          canal: 5,
          usp_id: uspId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {});
  };

  cambiarValorHora = (valor) => {
    if (valor != null) {
      this.setState({ hora: valor });
    }
  };

  generarHistorial = (accion, id, usp) => {
    axios
      .post(
        global.config.modulos.gestion + "historial/",
        {
          accion: accion,
          gestion: id,
          usuario_panel: usp,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {});
  };

  validaciones = () => {
    const { especialidad, contacto, profesional, hora, telefono, otroTelefono, activarTeleconsulta, activarPresencial } = this.state;

    if (activarTeleconsulta || activarPresencial) {
      if (!especialidad) {
        NotificationManager.warning("Por favor completa todos los campos");
        return false;
      }
    }

    if (!profesional || !hora) {
      NotificationManager.warning("Por favor completa todos los campos");
      return false;
    } else if (contacto == 3 && !telefono) {
      NotificationManager.warning("Por favor completa todos los campos");
      return false;
    } else if (telefono == "otro" && !otroTelefono) {
      NotificationManager.warning("Por favor completa todos los campos");
      return false;
    }

    return true;
  };

  canalesTeleconsulta = async () => {
    axios
      .get(global.config.modulos.videollamada + "canales_teleconsulta/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.usuCesfam,
        },
      })
      .then((res) => {
        var data = res.data.datos;
        this.setState({
          canalesTeleconsulta: data,
        });
      })
      .catch((err) => {});
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Finalizar caso</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">Indique la solución entregada</InputLabel>
                    <Select style={{ marginTop: 8, minWidth: 400 }} labelId="demo-simple-select-outlined-label" id="solucion" name="solucion" value={this.state.solucion} onChange={this.cambiarValores} label="Solución">
                      <MenuItem value={"Agendar visita domiciliaria"}>Agendar visita domiciliaria</MenuItem>
                      <MenuItem value={"Confección de receta"}>Confección de receta</MenuItem>
                      <MenuItem value={"Entrega a domicilio"}>Entrega a domicilio</MenuItem>
                      <MenuItem value={"Entrega presencial"}>Entrega presencial</MenuItem>
                      <MenuItem value={"Fallecimiento"}>Fallecimiento</MenuItem>
                      <MenuItem value={"No responde"}>No responde</MenuItem>
                      <MenuItem value={"Paciente rechazó atención"}>Paciente rechazó atención</MenuItem>
                      <MenuItem value={"Se agenda atención presencial"}>Se agenda atención presencial</MenuItem>

                      <MenuItem value={"Se agenda teleconsulta"}>Se agenda teleconsulta</MenuItem>
                      <MenuItem value={"Se entrega hora por SOME"}>Se entrega hora por SOME</MenuItem>

                      <MenuItem value={"Usuario informado"}>Usuario informado</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                {this.state.activarTeleconsulta ? (
                  <GridItem xs={12} style={{ marginTop: 20 }}>
                    <FormControl variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label">Seleccione el tipo de atención que se reservó</InputLabel>
                      <Select style={{ marginTop: 8, minWidth: 400 }} labelId="demo-simple-select-outlined-label" id="especialidad" name="especialidad" value={this.state.especialidad} onChange={this.cambiarValor} label="especialidad">
                        <MenuItem value={"TES"}>Teleconsulta Asistenmte Social</MenuItem>
                        <MenuItem value={"TEE"}>Teleconsulta Enfermero</MenuItem>
                        <MenuItem value={"TEK"}>Teleconsulta kinesiólogo</MenuItem>
                        <MenuItem value={"TMA"}>Teleconsulta Matrona</MenuItem>
                        <MenuItem value={"TEM"}>Teleconsulta Médico</MenuItem>
                        <MenuItem value={"TEN"}>Teleconsulta Nutrición</MenuItem>
                        <MenuItem value={"TEO"}>Teleconsulta Odontólogo</MenuItem>
                        <MenuItem value={"TEP"}>Teleconsulta Psicólogo</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                ) : null}
                {this.state.activarPresencial ? (
                  <GridItem xs={12} style={{ marginTop: 20 }}>
                    <FormControl variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label">Seleccione el tipo de atención que se reservó</InputLabel>
                      <Select style={{ marginTop: 8, minWidth: 400 }} labelId="demo-simple-select-outlined-label" id="especialidad" name="especialidad" value={this.state.especialidad} onChange={this.cambiarValor} label="especialidad">
                        <MenuItem value={"P_ASO"}>Asistente Social Presencial</MenuItem>
                        <MenuItem value={"P_DEN"}>Dental Presencial</MenuItem>
                        <MenuItem value={"P_ENF"}>Enfermero Presencial</MenuItem>
                        <MenuItem value={"P_KIN"}>Kinesiólogo Presencial</MenuItem>
                        <MenuItem value={"P_MAT"}>Matrona Presencial</MenuItem>
                        <MenuItem value={"P_MED"}>Médico Presencial</MenuItem>
                        <MenuItem value={"P_NUT"}>Nutrición Presencial</MenuItem>
                        <MenuItem value={"P_PSI"}>Psicólogo Presencial</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                ) : null}
                {this.state.existeProfesional ? (
                  <React.Fragment>
                    <GridItem xs={12} style={{ marginTop: 20 }}>
                      <InputLabel>Profesional</InputLabel>
                      <select className={"camposAgendaGestion"} id="profesional" name="profesional" value={this.state.profesional} onChange={this.cambiarValor}>
                        <option disabled value="">
                          Seleccione
                        </option>
                        {this.state.profesionales.map((res, index) => (
                          <option key={index} value={res.pro_id}>
                            {res.pro_nombre + " " + res.pro_apellido_p}
                          </option>
                        ))}
                      </select>
                    </GridItem>

                    <GridItem xs={6} style={{ marginTop: 20 }}>
                      <InputLabel>Horario</InputLabel>
                      <TextField id="standard-basic" label="" value={this.state.fecha} type="date" name="fecha" onChange={this.cambiarValor} />
                    </GridItem>
                    <GridItem xs={6} style={{ marginTop: 20 }}>
                      <InputLabel>Hora</InputLabel>

                      <TimePicker
                        minuteStep={5}
                        showSecond={false}
                        name="hora"
                        id="hora"
                        value={this.state.hora}
                        onChange={(e) => this.cambiarValorHora(e)}
                        style={{
                          width: 100,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6} style={{ marginTop: 20 }}>
                      <InputLabel>Contacto</InputLabel>
                      <select id="contacto" name="contacto" value={this.state.contacto} onChange={this.cambiarValor} className={"camposAgendaGestion"}>
                        {this.state.canalesTeleconsulta.map((res, index) => {
                          return (
                            <option value={res.cta_id} key={index}>
                              {res.cta_canal}
                            </option>
                          );
                        })}
                      </select>
                    </GridItem>
                    {this.state.existeContactoTelefono ? (
                      <React.Fragment>
                        <GridItem xs={6} style={{ marginTop: 20 }}>
                          <InputLabel>Teléfono</InputLabel>
                          <select id="telefono" name="telefono" value={this.state.telefono} onChange={this.cambiarValor} className={"camposAgendaGestion"}>
                            <option selected disabled value="">
                              Seleccione
                            </option>
                            {this.state.telefonos.map((res, index) => {
                              return (
                                <option key={index} value={res.tel_telefono}>
                                  {res.tel_telefono}
                                </option>
                              );
                            })}
                            <option value="otro">Otro</option>
                          </select>
                        </GridItem>
                        {this.state.existeContactoTelefonoOtro ? (
                          <GridItem xs={6} style={{ marginTop: 20 }}>
                            <InputLabel>Otro</InputLabel>
                            <TextField
                              InputProps={{
                                startAdornment: <InputAdornment position="start">+56</InputAdornment>,
                              }}
                              id="standard-basic"
                              label=""
                              value={this.state.otroTelefono}
                              style={{ with: 300 }}
                              type="number"
                              name="otroTelefono"
                              onChange={this.cambiarValor}
                              onInput={(e) => {
                                e.target.value = e.target.value.length <= 9 ? e.target.value : e.target.value.slice(0, -1);
                              }}
                            />
                          </GridItem>
                        ) : null}
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                ) : null}
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                    Cancelar
                  </Button>
                  <Button onClick={this.finalizarCaso} style={{ marginLeft: 20 }} color="primary" className="fondoVerde">
                    Aceptar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
