import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Grid } from "@material-ui/core";
import { store } from "./../../store/index";
import axios from "axios";

// estilos
//import "../../assets/css/agregar.css";

export default class UltimaSolicitud extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cesfam: store.getState().cesfam,
      uspid: store.getState().usp,
      usuario: props.usuario,

      open: true,
      fecha: null,
      especialidad: "",
      canal: "",
      estado: "",
      texto: "",
    };
  }

  componentDidMount() {
    this.ultimaSolicitud();
  }

  ultimaSolicitud = () => {
    axios
      .get(global.config.modulos.reservas + "ultima_atencion/", {
        headers: authHeader(),
        params: { usuario: this.state.usuario, cesfam: this.state.cesfam },
      })
      .then((res) => {
        if (res.data.estado) {
          let datos_ultima_solicitud = res.data.datos;
          this.setState({
            fecha: datos_ultima_solicitud.fecha,
            canal: datos_ultima_solicitud.canal_nombre,
            especialidad: datos_ultima_solicitud.especialidad,
            estado: datos_ultima_solicitud.estado_hora,
            texto: datos_ultima_solicitud.texto,
          });
        }
      });
  };

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  render() {
    const { open, fecha, especialidad, canal, estado, texto } = this.state;
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="ultimaSolicitud">
          <DialogContent>
            <Grid container className="agendar-container">
              <Grid item xs={12} md={12}>
                <h2>Última solicitud</h2>
              </Grid>
              {fecha ? (
                <React.Fragment>
                  <Grid item id="modalComentarioComentario" xs={12} style={{ marginTop: 30 }}>
                    <p>
                      <label style={{ fontWeight: "bold" }}>{texto}</label>
                    </p>
                  </Grid>
                  <Grid item id="modalComentarioComentario" xs={6} lg={3} style={{ marginTop: 30 }}>
                    <p>
                      <label style={{ fontWeight: "bold" }}>Fecha:</label>
                    </p>
                    <p style={{ marginTop: 10 }}>{fecha.replace("T", " ")}</p>
                  </Grid>
                  <Grid item id="modalComentarioComentario" xs={6} lg={3} style={{ marginTop: 30 }}>
                    <p>
                      <label style={{ fontWeight: "bold" }}>Especialidad:</label>
                    </p>
                    <p style={{ marginTop: 10 }}>{especialidad}</p>
                  </Grid>
                  <Grid item id="modalComentarioComentario" xs={6} lg={3} style={{ marginTop: 30 }}>
                    <p>
                      <label style={{ fontWeight: "bold" }}>Canal:</label>
                    </p>
                    <p style={{ marginTop: 10 }}>{canal}</p>
                  </Grid>
                  <Grid item id="modalComentarioComentario" xs={6} lg={3} style={{ marginTop: 30 }}>
                    <p>
                      <label style={{ fontWeight: "bold" }}>Estado:</label>
                    </p>
                    <p style={{ marginTop: 10 }}>{estado}</p>
                  </Grid>
                </React.Fragment>
              ) : (
                <Grid item id="modalComentarioComentario" xs={12} style={{ marginTop: 30 }}>
                  <p>
                    <label>Rut sin solicitudes previas</label>
                  </p>
                </Grid>
              )}

              <Grid item xs={12} style={{ marginTop: 30, marginBottom: 30 }}>
                <Button className="botonDialog fondoRojo" onClick={this.salir}>
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
