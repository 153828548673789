import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import { Button, Dialog, DialogContent } from "@material-ui/core/";
import "react-datepicker/dist/react-datepicker.css";
import MaterialTable from "material-table";
import axios from "axios";
import chileanRut from "chilean-rut";
import { store } from "../../store/index";

class ModalPoliconsultanteDetalles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      especialidad_id: props.especialidad_id,
      usuario_id: props.usuario_id,
      detallePolis: [],
      cesfam: store.getState().cesfam,
    };
  }

  componentDidMount() {
    this.detallePolis();
  }
  detallePolis = () => {
    axios
      .get(global.config.modulos.reportes + "listado_policonsultante/detallepoliconsulta/", {
        headers: authHeader(),
        params: {
          usuario_id: this.state.usuario_id,
          especialidad_id: this.state.especialidad_id,
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            detallePolis: res.data.datos,
          });
        }
      });
  };

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="beneficioUsuario">
          <DialogContent>
            <GridContainer id="tablaBloqueos">
              <img alt="" class="marca-agua"></img>

              <GridItem xs={12} sm={12} md={12} id="tablaBloqueos" style={{ width: "100%" }}>
                <MaterialTable
                  id="tablaBloqueos"
                  title="Historial de policonsultantes"
                  columns={[
                    { title: "Canal solicitud", field: "canal_solicitud" },
                    {
                      title: "Rut",
                      render: (rowData) => (
                        <React.Fragment>
                          <div>{chileanRut.format(rowData.rut_usu, true)}</div>
                        </React.Fragment>
                      ),
                    },
                    { title: "Nombre", field: "nombre_usuario" },
                    { title: "Fecha agendamiento", field: "fecha_reservacion" },
                    { title: "Hora pedida", field: "hora_atencion" },
                    { title: "Estado", field: "estado_hora" },
                  ]}
                  data={this.state.detallePolis}
                  options={{
                    sorting: true,
                    search: false,
                  }}
                  localization={{
                    pagination: {
                      labelDisplayedRows: "{from}-{to} de {count}",
                      labelRowsSelect: "filas",
                      firstTooltip: "primera",
                      previousTooltip: "anterior",
                      nextTooltip: "siguiente",
                      lastTooltip: "última",
                    },
                  }}
                ></MaterialTable>
              </GridItem>
              <GridItem xs={12} style={{ marginTop: 30 }}>
                <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={this.salir} style={{ marginLeft: 20 }}>
                  Cerrar
                </Button>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default ModalPoliconsultanteDetalles;
