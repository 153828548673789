import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import "moment/locale/es";
import moment from "moment";

// core components
import { Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MaterialTable from "material-table";
//JS
//IMAGENES
//CSS

class HistorialComunaDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillUnmount() {}

  modalSalir = (estado) => {
    this.props.cerrarModal(estado);
  };

  render() {
    return (
      <>
        <Dialog open={true} onClose={() => this.modalSalir(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modalSolicitudTrabajo">
          <DialogContent>
            <h1 style={{ color: "#666666" }}>Historial Solicitudes</h1>
            <br />
            <br />
            <Grid item xs={12} sm={12} className="divBordeTablaHistorialHoras">
              <MaterialTable
                columns={[
                  {
                    title: "Fecha de creacion",
                    render: (rowData) => (
                      <React.Fragment>
                        <p>{moment(rowData.sol_fec_crear_soli).format("YYYY-MM-DD HH:mm")}</p>
                      </React.Fragment>
                    ),
                    field: "sol_fec_crear_soli",
                  },
                  {
                    title: "Fecha entrega",
                    field: "fecha_entrega",
                  },
                  {
                    title: "Cesfam",
                    field: "nombre_cesfam",
                  },
                  {
                    title: "Quien confirma Cesfam",
                    field: "sol_confirmacion",
                  },
                  {
                    title: "Quien creó",
                    field: "sol_funcionario",
                  },
                  {
                    title: "código",
                    field: "sol_codigo",
                  },
                  {
                    title: "Estado",
                    render: (rowData) => (
                      <React.Fragment>
                        {rowData.sol_estado == 0 && <p>En espera de confirmación</p>}
                        {rowData.sol_estado == 1 && <p>Rechazado por superior</p>}
                        {rowData.sol_estado == 2 && <p>Recibido</p>}
                        {rowData.sol_estado == 3 && <p>Aprobada</p>}
                        {rowData.sol_estado == 4 && <p>Rechazada</p>}
                        {rowData.sol_estado == 5 && <p>Asignación de Ingeniero</p>}
                        {rowData.sol_estado == 6 && <p>Revisión en calidad</p>}
                        {rowData.sol_estado == 7 && <p>Entregado</p>}
                        {rowData.sol_estado == 8 && <p>En pausa, por motivo: {rowData.sol_motivo_pausa}</p>}
                      </React.Fragment>
                    ),
                  },
                ]}
                data={this.props.data}
                options={{
                  actionsColumnIndex: -1,
                  search: true,
                  showTitle: false,
                  toolbar: true,
                }}
                localization={{
                  header: {
                    actions: "Acciones",
                  },
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    labelRowsSelect: "filas",
                    firstTooltip: "primera",
                    previousTooltip: "anterior",
                    nextTooltip: "siguiente",
                    lastTooltip: "última",
                  },
                  toolbar: {
                    nRowsSelected: "{0} fila(s) seleccionadas",
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                  },
                  body: {
                    emptyDataSourceMessage: "No tiene solicitudes realizadas",
                    filterRow: {
                      filterTooltip: "Buscar",
                    },
                  },
                }}
              />
            </Grid>

            <br></br>
            <br></br>

            <a
              className={"btnAcciones btnAgregarAcceso"}
              style={{ width: "150px" }}
              onClick={() => {
                this.modalSalir();
              }}
            >
              {"Cerrar"}
            </a>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default HistorialComunaDialog;
