import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import chileanRut from "chilean-rut";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { DialogContentText } from "@material-ui/core";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Buttons from "react-multi-date-picker/components/button";
import { Input } from "@material-ui/core";
import "../../assets/css/usuarios.css";

export default class UsuarioPrioridad extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usp_id: store.getState().usp,
      cesfamus: store.getState().cesfam,

      open: true,

      id_usuario: props.id_usuario,
      id_prioridad: props.id_prioridad,
      prioridades: [],
      fecha_inicio: null,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  obtenerPrioridades = () => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_prioridades/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfamus,
          prioridad: this.state.id_prioridad,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            prioridades: res.data.datos,
          });
        }
      });
  };

  componentDidMount() {
    this.obtenerPrioridades();
  }

  fecha_inicio = (e) => {
    const fecha_inicio = e.target.value;
    this.setState({ fecha_inicio: fecha_inicio });
  };

  prioridad = (e) => {
    const id_prioridad = e.target.value;
    this.setState({ id_prioridad: id_prioridad });
  };

  agregarPrioridad = () => {
    if (this.state.id_prioridad == null) {
      NotificationManager.error("Debe seleccionar una Prioridad");
      return;
    }

    axios
      .patch(
        global.config.modulos.listaEspera + "agregar_prioridad_usuario/",
        {
          id_usuario: this.state.id_usuario,
          id_prioridad: this.state.id_prioridad,
          usp_id: this.state.usp_id,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Se ha agregado la Prioridad al usuario");
          this.salir();
          this.setState({
            fecha_inicio: null,
            tratamiento: null,
          });
        } else {
          NotificationManager.error("No se ha podido agregar la Prioridad al usuario");
        }
      });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario" style={{ maxWidth: "100%", maxHeight: "100%" }}>
          <DialogContent
            style={{
              width: "500px",
              maxWidth: "100%",
              height: "100%",
              maxHeight: "100%",
            }}
          >
            <div style={{ marginBottom: "5px" }}>
              <h2>Prioridad</h2>
            </div>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Seleccione Prioridad</InputLabel>
                  <Select name="tratamiento" value={this.state.id_prioridad} onChange={this.prioridad}>
                    <MenuItem value={null}>Selecciona Prioridad</MenuItem>
                    {this.state.prioridades.map((pri, i) => {
                      return (
                        <MenuItem key={i} value={pri.lp_id}>
                          {pri.lp_nombre}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "25px",
                  }}
                >
                  <Button style={{ marginRight: 10, backgroundColor: "#00ac77", color: "#fff" }} onClick={() => this.agregarPrioridad()}>
                    Guardar
                  </Button>
                  <Button onClick={this.salir} style={{ color: "#fff" }} className="fondoNaranjo">
                    Cancelar
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
