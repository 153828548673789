import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridContainer from "./../../components/grid/GridContainer.js";
import GridItem from "./../../components/grid/GridItem";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { store } from "./../../store/index";
import { Grid } from "@material-ui/core";
import StackedProgressBar from "../../components/ProgressBar/StackedProgressBar.js";
import { ReactComponent as SalusinFeliz } from "../../assets/img/salusin/salusinfeliz.svg";

import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import "moment/locale/es";
import axios from "axios";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import IconoAlerta from "../../assets/img/metas/alerta.svg";

// CSS
import "./../../assets/css/general.css";
import "./../../assets/css/graficos.css";
import "./../../assets/css/usuarios.css";

export default class GraficoMetas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      comuna: store.getState().comuna,

      periodoOptions: [
        { label: "Mensual", val: 0, id: 1 },
        { label: "Trimestral", val: 1, id: 2 },
        { label: "Semestral", val: 2, id: 3 },
        { label: "Anual", val: 3, id: 4 },
      ],
      periodoSelect: null,
      year: false,

      metaOptions: [],
      metaSelect: null,

      metaComp: null,

      dateSelect: null,
      dateSelectComp: null,

      detail: false,
      moreDetail: false,
      detailComp: false,
      moreDetailComp: false,

      detailSelect: null,
      detailSelectComp: null,

      currentMetaInfo: [],
      comparemetaInfo: [],

      showDate: false,
      comparativa: false,
      showCompare: false,
      showCompareButton: false,
      showGraf: false,

      err: false,
      errcomp: false,
      header: "",
      headercomp: "",
    };

    this.verDesgl = this.verDesgl.bind(this);
    this.verSubDegl = this.verSubDegl.bind(this);
    this.verComp = this.verComp.bind(this);
    this.verDesglComp = this.verDesglComp.bind(this);
    this.verSubDeglComp = this.verSubDeglComp.bind(this);
  }

  componentDidMount() {}

  setPeriodo = (event, value) => {
    this.setState(
      {
        metaOptions: [],
        metaSelect: null,
        dateSelect: null,
        dateSelectComp: null,
        detail: false,
        moreDetail: false,
        comparativa: false,
        showDate: false,
        currentMetaInfo: [],
        compareMetaInfo: [],
        showCompare: false,
        detailSelect: null,
        detailSelectComp: null,
        dateSelectComp: null,
        showGraf: false,
        periodoSelect: value,
        header: "",
        headercomp: "",
        showCompareButton: false,
        year: value.label == "Anual" ? true : false,
      },
      () => {
        this.cargarMetas(value.id);
        console.log("wheee");
      }
    );
  };

  setMeta = (event, value) => {
    this.setState(
      {
        dateSelect: null,
        dateSelectComp: null,
        detail: false,
        moreDetail: false,
        showCompare: false,
        showGraf: false,
        metaSelect: value,
        dateSelectComp: null,
        dateSelect: null,
        comparativa: false,
        currentMetaInfo: [],
        comparemetaInfo: [],
        detailSelect: null,
        detailSelectComp: null,
        dateSelectComp: null,
        showDate: true,
        header: "",
        headercomp: "",
        showCompareButton: false,
      },
      () => {
        console.log("wheee");
      }
    );
  };

  setHeader() {
    var date;
    var mes;
    switch (this.state.periodoSelect.id) {
      case 1:
        date = moment(this.state.dateSelect).format("MMMM") + " de " + moment(this.state.dateSelect).format("YYYY");
        this.setState({ header: date });
        break;
      case 2:
        mes = moment(this.state.dateSelect).format("MM");
        let trim = mes < 4 ? 1 : mes < 7 ? 2 : mes < 10 ? 3 : 4;
        date = "Trimestre " + trim + " de " + moment(this.state.dateSelect).format("YYYY");
        this.setState({ header: date });
        break;
      case 3:
        mes = moment(this.state.dateSelect).format("MM");
        let sem = mes < 7 ? 1 : 2;
        date = "Semestre " + sem + " de " + moment(this.state.dateSelect).format("YYYY");
        this.setState({ header: date });
        break;
      case 4:
        date = "Año " + moment(this.state.dateSelect).format("YYYY");
        this.setState({ header: date });
        break;
    }
  }

  setHeaderComp() {
    var date;
    var mes;
    switch (this.state.periodoSelect.id) {
      case 1:
        date = moment(this.state.dateSelectComp).format("MMMM") + " de " + moment(this.state.dateSelectComp).format("YYYY");
        this.setState({ headercomp: date });
        break;
      case 2:
        mes = moment(this.state.dateSelectComp).format("MM");
        let trim = mes < 4 ? 1 : mes < 7 ? 2 : mes < 10 ? 3 : 4;
        date = "Trimestre " + trim + " de " + moment(this.state.dateSelectComp).format("YYYY");
        this.setState({ headercomp: date });
        break;
      case 3:
        mes = moment(this.state.dateSelectComp).format("MM");
        let sem = mes < 7 ? 1 : 2;
        date = "Semestre " + sem + " de " + moment(this.state.dateSelectComp).format("YYYY");
        this.setState({ headercomp: date });
        break;
      case 4:
        date = "Año " + moment(this.state.dateSelectComp).format("YYYY");
        this.setState({ headercomp: date });
        break;
    }
  }

  getMainName() {
    let str = "Meta general " + this.state.currentMetaInfo.nombre;

    switch (this.state.currentMetaInfo.area) {
      case 1:
        str = str + " comuna:";
        break;
      case 2:
        str = str + " Cesfam " + this.state.currentMetaInfo.nombre_ces + ":";
        break;
      case 3:
        str = str + " Sector " + this.state.currentMetaInfo.nombre_sect + ":";
        break;
    }

    return str;
  }

  getSubName(name) {
    let str = "Meta " + this.state.currentMetaInfo.nombre;

    switch (this.state.currentMetaInfo.area) {
      case 1:
        str = str + " de Cesfam " + name + ":";
        break;
      case 2:
        str = str + " de Sector " + name + ":";
        break;
      case 3:
        str = "";
        break;
    }

    return str;
  }

  getMoreSubName(name) {
    let str = "Meta " + this.state.currentMetaInfo.nombre;

    switch (this.state.currentMetaInfo.area) {
      case 1:
        str = str + " de Sector " + name + ":";
        break;
      case 2:
        str = "";
        break;
      case 3:
        str = "";
        break;
    }

    return str;
  }

  cargarMetas = async (periodo) => {
    const apiUrl = global.config.modulos.metas + "metas_por_periodo/?cesfam=" + this.state.cesfam + "&periodo=" + periodo;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.meta.length > 0) {
        var ind = 0;
        this.setState({ metaOptions: [] });
        res.data.meta.map((res) => {
          this.setState({ metaOptions: [...this.state.metaOptions, { label: res.met_nombre, val: ind, id: res.met_id }] });
          ind = ind + 1;
        });
      }
    });
  };

  cargarProgresoMeta() {
    var fecha = moment(this.state.dateSelect).format("DD-MM-YYYY");
    const apiUrl = global.config.modulos.metas + "progreso_meta_por_id/" + this.state.metaSelect.id + "?fecha=" + fecha;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.estado) {
        let ces = [];
        let sec = [];
        if (res.data.meta.met_area == 1) {
          res.data.desglose.cesfams.map((res) => {
            ces.push(res);
          });
        } else if (res.data.meta.met_area == 2) {
          res.data.desglose.sectores.map((res) => {
            sec.push(res);
          });
        }
        let met = {
          nombre: res.data.meta.met_nombre,
          meta: res.data.meta.met_meta,
          prog_real: res.data.metapr.mec_progreso,
          prog_est: res.data.metapr.mec_estimada,
          nombre_ces: res.data.ces,
          nombre_sect: res.data.sect,
          area: res.data.meta.met_area,
          detalle: res.data.meta.met_area == 1 || res.data.meta.met_area == 2 ? (res.data.meta.met_area == 1 ? ces : sec) : null,
        };
        this.setHeader();
        this.setState({ currentMetaInfo: met, err: false, showGraf: true, showCompareButton: true });
      } else {
        this.setState({ showGraf: true, err: true });
      }
    });
  }

  cargarProgresoMetaComp() {
    var fecha = moment(this.state.dateSelectComp).format("DD-MM-YYYY");
    const apiUrl = global.config.modulos.metas + "progreso_meta_por_id/" + this.state.metaSelect.id + "?fecha=" + fecha;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.estado) {
        let ces = [];
        let sec = [];
        if (res.data.meta.met_area == 1) {
          res.data.desglose.cesfams.map((res) => {
            ces.push(res);
          });
        } else if (res.data.meta.met_area == 2) {
          res.data.desglose.sectores.map((res) => {
            sec.push(res);
          });
        }
        let met = {
          nombre: res.data.meta.met_nombre,
          meta: res.data.meta.met_meta,
          prog_real: res.data.metapr.mec_progreso,
          prog_est: res.data.metapr.mec_estimada,
          nombre_ces: res.data.ces,
          nombre_sect: res.data.sect,
          area: res.data.meta.met_area,
          detalle: res.data.meta.met_area == 1 || res.data.meta.met_area == 2 ? (res.data.meta.met_area == 1 ? ces : sec) : null,
        };
        this.setHeaderComp();
        this.setState({ comparemetaInfo: met, errcomp: false, comparativa: true });
      } else {
        this.setState({ comparativa: true, errcomp: true });
      }
    });
  }

  setFecha = (e) => {
    this.setState({ dateSelect: e }, () => {
      this.cargarProgresoMeta();
    });
  };

  setFechaComp = (e) => {
    this.setState({ dateSelectComp: e }, () => {
      this.cargarProgresoMetaComp();
    });
  };

  calcFill(pr, meta) {
    if (pr === 0 && meta === 0) {
      return 0;
    } else {
      var perc = pr / meta;
      var res = perc * 100;
      return res > 100 ? 100 : res;
    }
  }

  verDesgl() {
    if (this.state.detail && !this.state.moreDetail) {
      this.setState({ detail: false });
    } else if (!this.state.detail && this.state.moreDetail) {
      this.setState({ detail: true, detailSelect: null, moreDetail: false });
    } else if (!this.state.detail) {
      this.setState({ detail: true });
    }
  }

  verSubDegl(det) {
    this.setState({ detail: false, detailSelect: det, moreDetail: true });
  }

  verDesglComp() {
    if (this.state.detailComp && !this.state.detailComp) {
      this.setState({ detailComp: false });
    } else if (!this.state.detailComp && this.state.moreDetailComp) {
      this.setState({ detailComp: true, detailSelectComp: null, moreDetailComp: false });
    } else if (!this.state.detailComp) {
      this.setState({ detailComp: true });
    }
  }

  verSubDeglComp(det) {
    this.setState({ detailComp: false, detailSelectComp: det, moreDetailComp: true });
  }

  verComp() {
    this.setState({ showCompare: !this.state.showCompare, comparativa: false, dateSelectComp: null });
  }

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Grid container spacing={3}>
              <Grid item xs={2} sm={2} md={2}>
                <Autocomplete disablePortal disableClearable value={this.state.periodoSelect} id="combo-box-periodo" options={this.state.periodoOptions} getOptionLabel={(option) => option.label} defaultValue={this.state.periodoSelect} onChange={this.setPeriodo.bind(this)} renderInput={(params) => <TextField {...params} label="Periodo" />}></Autocomplete>
              </Grid>
              <Grid item xs={3} sm={3} md={3}>
                <Autocomplete disablePortal disableClearable value={this.state.metaSelect} id="combo-box-meta" defaultValue={this.state.metaSelect} options={this.state.metaOptions} noOptionsText={"Seleccione un periodo primero"} getOptionLabel={(option) => option.label} onChange={this.setMeta.bind(this)} renderInput={(params) => <TextField {...params} label="Meta" />}></Autocomplete>
              </Grid>
              {this.state.showDate ? (
                <Grid item xs={2} sm={2} md={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <KeyboardDatePicker
                      disableToolbar
                      invalidDateMessage="¡Fecha mal ingresada, por favor inténtalo nuevamente!"
                      disableFuture="true"
                      openTo="year"
                      views={!this.state.year ? ["year", "month"] : ["year"]}
                      value={this.state.dateSelect}
                      initialFocusedDate={this.dateSelect}
                      format="yyyy-MM-dd"
                      id="date-picker-inline"
                      label="Fecha"
                      name="yearmonthweekpick"
                      onChange={(e) => this.setFecha(e)}
                      KeyboardButtonProps={{
                        "aria-label": "cambiar fecha",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              ) : null}
              {this.state.showCompareButton ? (
                <Grid item xs={1} sm={1} md={1} style={{ marginLeft: 25 }}>
                  <Button className={this.state.showCompare ? "btnOpcion buttonSelected botonDialog downloadButton" : "btnOpcion fondoNaranjo botonDialog downloadButton"} onClick={this.verComp}>
                    Comparativa
                  </Button>
                </Grid>
              ) : null}
              {this.state.showCompare ? (
                <Grid item xs={2} sm={2} md={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <KeyboardDatePicker
                      disableToolbar
                      invalidDateMessage="¡Fecha mal ingresada, por favor inténtalo nuevamente!"
                      disableFuture="true"
                      openTo="year"
                      views={!this.state.year ? ["year", "month"] : ["year"]}
                      value={this.state.dateSelectComp}
                      initialFocusedDate={this.dateSelectComp}
                      format="yyyy-MM-dd"
                      id="date-picker-comp"
                      label="Fecha"
                      name="yearmonthweekpick"
                      onChange={(e) => this.setFechaComp(e)}
                      KeyboardButtonProps={{
                        "aria-label": "cambiar fecha",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              ) : null}
            </Grid>
          </GridItem>
        </GridContainer>

        <div class="middle-content ocultarScroll">
          <div class="chartContainer metaBarras">
            <div class="barras">
              {this.state.showGraf ? (
                this.state.err ? (
                  <h2 className="normaltext">No se encuentran datos para esta meta en esta fecha</h2>
                ) : (
                  <>
                    <GridContainer>
                      <GridItem xs={12}>
                        <Grid item xs={12} sm={12} md={12} style={{ marginBottom: 15 }}>
                          <h1 className="normaltext"> {this.state.header} </h1>
                        </Grid>
                      </GridItem>
                    </GridContainer>
                    <br></br>
                    <GridItem xs={12}>
                      <GridContainer className="">
                        <Grid container spacing={8} className="scroll-container ocultarScroll">
                          <Grid item xs={8} sm={8} md={8}>
                            <StackedProgressBar id="meta_main" bgcolor="#6a1b9a" completed={this.calcFill(this.state.currentMetaInfo.prog_real, this.state.currentMetaInfo.meta)} estimated={this.calcFill(this.state.currentMetaInfo.prog_est, this.state.currentMetaInfo.meta)} amount={this.state.currentMetaInfo.prog_real} amount_est={this.state.currentMetaInfo.prog_est} tooltipColor="#77429c" tooltipColor2="#bd9cd1" main={true} alerta={IconoAlerta} title={this.getMainName()} onClick={this.state.currentMetaInfo.area == 3 ? null : this.verDesgl} />
                          </Grid>
                          <Grid item xs={3} sm={3} md={3} style={{ marginLeft: 25 }}>
                            <div class="metaBox" style={{ backgroundColor: "#77429c", borderColor: "#77429c", width: "70%" }}>
                              <p class="metaNum">
                                <b class="vacNumberSm">{this.state.currentMetaInfo.meta}</b>
                              </p>
                            </div>
                          </Grid>

                          {this.state.detail
                            ? this.state.currentMetaInfo.detalle.map((dt, index) => {
                                return (
                                  <>
                                    <br></br>
                                    <Grid item xs={7} sm={7} md={7} style={{ marginTop: 15 }}>
                                      <StackedProgressBar id={this.state.currentMetaInfo.area == 1 ? `meta_sub_${dt.cesfam_id}` : this.state.currentMetaInfo.area == 2 ? `meta_sub_${dt.sector_id}` : null} bgcolor="#6a1b9a" completed={this.calcFill(dt.real, dt.metadiv)} estimated={this.calcFill(dt.estimado, dt.metadiv)} amount={dt.real} amount_est={dt.estimado} alerta={IconoAlerta} tooltipColor="#77429c" tooltipColor2="#bd9cd1" title={this.state.currentMetaInfo.area == 1 ? this.getSubName(dt.cesfam) : this.state.currentMetaInfo.area == 2 ? this.getSubName(dt.sector) : null} onClick={this.state.currentMetaInfo.area == 1 ? this.verSubDegl.bind(this, dt) : null} small={true} />
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={2} style={{ marginTop: 15 }}>
                                      <div class="metaBox" style={{ backgroundColor: "#77429c", borderColor: "#77429c", width: "140%" }}>
                                        <p class="metaNum">
                                          <b>{dt.metadiv}</b>
                                        </p>
                                      </div>
                                    </Grid>
                                  </>
                                );
                              })
                            : null}
                          {this.state.detailSelect ? (
                            <>
                              <Grid item xs={7} sm={7} md={7} style={{ marginTop: 15 }}>
                                <StackedProgressBar id={this.state.currentMetaInfo.area == 1 ? `meta_sub_${this.state.detailSelect.cesfam_id}` : this.state.currentMetaInfo.area == 2 ? `meta_sub_${this.state.detailSelect.sector_id}` : null} bgcolor="#6a1b9a" completed={this.calcFill(this.state.detailSelect.real, this.state.detailSelect.metadiv)} estimated={this.calcFill(this.state.detailSelect.estimado, this.state.detailSelect.metadiv)} amount={this.state.detailSelect.real} amount_est={this.state.detailSelect.estimado} alerta={IconoAlerta} tooltipColor="#77429c" tooltipColor2="#bd9cd1" title={this.state.currentMetaInfo.area == 1 ? this.getSubName(this.state.detailSelect.cesfam) : this.state.currentMetaInfo.area == 2 ? this.getSubName(this.state.detailSelect.sector) : null} onClick={this.verDesgl} small={true} />
                              </Grid>
                              <Grid item xs={2} sm={2} md={2} style={{ marginTop: 15 }}>
                                <div class="metaBox" style={{ backgroundColor: "#77429c", borderColor: "#77429c", width: "140%" }}>
                                  <p class="metaNum">
                                    <b>{this.state.detailSelect.metadiv}</b>
                                  </p>
                                </div>
                              </Grid>
                            </>
                          ) : null}
                          {this.state.moreDetail
                            ? this.state.detailSelect.detalle.map((dt, index) => {
                                return (
                                  <>
                                    <Grid item xs={6} sm={6} md={6} style={{ marginTop: 15 }}>
                                      <StackedProgressBar id={`meta_sub_${dt.sector_id}`} bgcolor="#6a1b9a" completed={this.calcFill(dt.real, dt.metadiv)} estimated={this.calcFill(dt.estimado, dt.metadiv)} amount={dt.real} alerta={IconoAlerta} amount_est={dt.estimado} tooltipColor="#77429c" tooltipColor2="#bd9cd1" title={this.getMoreSubName(dt.sector)} small={true} />
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={2} style={{ marginLeft: 40, marginTop: 15 }}>
                                      <div class="metaBox" style={{ backgroundColor: "#77429c", borderColor: "#77429c", width: "140%" }}>
                                        <p class="metaNum">
                                          <b>{dt.metadiv}</b>
                                        </p>
                                      </div>
                                    </Grid>
                                  </>
                                );
                              })
                            : null}
                        </Grid>
                      </GridContainer>
                    </GridItem>
                    <br></br>
                  </>
                )
              ) : null}
            </div>
            <div class="comparativa">
              {this.state.comparativa ? (
                this.state.errcomp ? (
                  <h2 className="normaltext">No se encuentran datos para esta meta en esta fecha</h2>
                ) : (
                  <>
                    <GridContainer>
                      <GridItem xs={12}>
                        <Grid item xs={12} sm={12} md={12} style={{ marginBottom: 15 }}>
                          <h1 className="normaltext"> {this.state.headercomp} </h1>
                        </Grid>
                      </GridItem>
                    </GridContainer>
                    <br></br>
                    <GridItem xs={12}>
                      <GridContainer className="">
                        <Grid container spacing={8} className="scroll-container ocultarScroll">
                          <Grid item xs={8} sm={8} md={8}>
                            <StackedProgressBar id="meta_main_comp" bgcolor="#6a1b9a" completed={this.calcFill(this.state.comparemetaInfo.prog_real, this.state.currentMetaInfo.meta)} estimated={this.calcFill(this.state.comparemetaInfo.prog_est, this.state.currentMetaInfo.meta)} amount={this.state.comparemetaInfo.prog_real} amount_est={this.state.comparemetaInfo.prog_est} tooltipColor="#77429c" tooltipColor2="#bd9cd1" main={true} alerta={IconoAlerta} title={this.getMainName()} onClick={this.state.currentMetaInfo.area == 3 ? null : this.verDesglComp} />
                          </Grid>
                          <Grid item xs={3} sm={3} md={3} style={{ marginLeft: 25 }}>
                            <div class="metaBox" style={{ backgroundColor: "#77429c", borderColor: "#77429c", width: "75%" }}>
                              <p class="metaNum">
                                <b class="vacNumberSm">{this.state.comparemetaInfo.meta}</b>
                              </p>
                            </div>
                          </Grid>

                          {this.state.detailComp
                            ? this.state.comparemetaInfo.detalle.map((dt, index) => {
                                return (
                                  <>
                                    <br></br>
                                    <Grid item xs={7} sm={7} md={7} style={{ marginTop: 15 }}>
                                      <StackedProgressBar id={this.state.comparemetaInfo.area == 1 ? `meta_sub_${dt.cesfam_id}` : this.state.comparemetaInfo.area == 2 ? `meta_sub_${dt.sector_id}` : null} bgcolor="#6a1b9a" completed={this.calcFill(dt.real, dt.metadiv)} estimated={this.calcFill(dt.estimado, dt.metadiv)} amount={dt.real} amount_est={dt.estimado} alerta={IconoAlerta} tooltipColor="#77429c" tooltipColor2="#bd9cd1" title={this.state.comparemetaInfo.area == 1 ? this.getSubName(dt.cesfam) : this.state.comparemetaInfo.area == 2 ? this.getSubName(dt.sector) : null} onClick={this.state.comparemetaInfo.area == 1 ? this.verSubDeglComp.bind(this, dt) : null} small={true} />
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={2} style={{ marginTop: 15 }}>
                                      <div class="metaBox" style={{ backgroundColor: "#77429c", borderColor: "#77429c", width: "175%" }}>
                                        <p class="metaNum">
                                          <b>{dt.metadiv}</b>
                                        </p>
                                      </div>
                                    </Grid>
                                  </>
                                );
                              })
                            : null}
                          {this.state.detailSelectComp ? (
                            <>
                              <Grid item xs={7} sm={7} md={7} style={{ marginTop: 15 }}>
                                <StackedProgressBar id={this.state.comparemetaInfo.area == 1 ? `meta_sub_${this.state.detailSelectComp.cesfam_id}` : this.state.currentMetaInfo.area == 2 ? `meta_sub_${this.state.detailSelectComp.sector_id}` : null} bgcolor="#6a1b9a" completed={this.calcFill(this.state.detailSelectComp.real, this.state.detailSelectComp.metadiv)} estimated={this.calcFill(this.state.detailSelectComp.estimado, this.state.detailSelectComp.metadiv)} amount={this.state.detailSelectComp.real} amount_est={this.state.detailSelectComp.estimado} tooltipColor="#77429c" tooltipColor2="#bd9cd1" alerta={IconoAlerta} title={this.state.comparemetaInfo.area == 1 ? this.getSubName(this.state.detailSelectComp.cesfam) : this.state.detailSelectComp.area == 2 ? this.getSubName(this.state.detailSelectComp.sector) : null} onClick={this.verDesglComp} small={true} />
                              </Grid>
                              <Grid item xs={2} sm={2} md={2} style={{ marginTop: 15 }}>
                                <div class="metaBox" style={{ backgroundColor: "#77429c", borderColor: "#77429c", width: "175%" }}>
                                  <p class="metaNum">
                                    <b>{this.state.detailSelectComp.metadiv}</b>
                                  </p>
                                </div>
                              </Grid>
                            </>
                          ) : null}
                          {this.state.moreDetailComp
                            ? this.state.detailSelectComp.detalle.map((dt, index) => {
                                return (
                                  <>
                                    <Grid item xs={6} sm={6} md={6} style={{ marginTop: 15 }}>
                                      <StackedProgressBar id={`meta_sub_${dt.sector_id}`} bgcolor="#6a1b9a" completed={this.calcFill(dt.real, dt.metadiv)} estimated={this.calcFill(dt.estimado, dt.metadiv)} amount={dt.real} amount_est={dt.estimado} alerta={IconoAlerta} tooltipColor="#77429c" tooltipColor2="#bd9cd1" title={this.getMoreSubName(dt.sector)} small={true} />
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={2} style={{ marginLeft: 40, marginTop: 15 }}>
                                      <div class="metaBox" style={{ backgroundColor: "#77429c", borderColor: "#77429c", width: "175%" }}>
                                        <p class="metaNum">
                                          <b>{dt.metadiv}</b>
                                        </p>
                                      </div>
                                    </Grid>
                                  </>
                                );
                              })
                            : null}
                        </Grid>
                      </GridContainer>
                    </GridItem>
                    <br></br>
                  </>
                )
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  }
}
