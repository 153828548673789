const ProgressBar = (props) => {
    const { bgcolor, completed, amount, tooltipColor, small } = props;
  
    const containerStyles = {
      height: 30,
      width: '100%',
      backgroundColor: "#d1d1d1",
      borderRadius: 50,
      margin: 10
    }
  
    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: bgcolor,
        borderRadius: 'inherit',
        textAlign: 'right',
        transition: 'width 1s ease-in-out',
    }

    const tooltip = {
      backgroundColor: tooltipColor,
      borderColor: tooltipColor+' transparent'
    }
  
    return (
      <div style={containerStyles}>
        <div className={`${small ? "barGradientSmall" : "barGradient"}`} style={fillerStyles}>
          <span className="bubble" style={tooltip}>{`${amount}`}</span>
        </div>
      </div>
    );
  };
  
  export default ProgressBar;