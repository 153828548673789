import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert";

// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class FinalizarCasoPlanContigo extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: parseInt(store.getState().usp),
      open: true,
      solucion: "",
      teleconsulta: "",
      presencial: "",
      gegId: props.gegId,
      chat: props.chat,
      usuId: props.usuId,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  cambiarValor = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  finalizarCaso = () => {
    let solucion = this.state.solucion;

    if (solucion === "Fallecido") {
      confirmAlert({
        title: "",
        message: "Seguro que desea descartar este caso con motivo 'FALLECIDO'? el paciente será eliminado de HoraSalud",
        buttons: [
          {
            label: "Cancelar",
            className: "btnCancelar",
          },
          {
            label: "Aceptar",
            className: "btnAceptar",
            onClick: () => {
              axios
                .patch(
                  global.config.modulos.usuarios + "por_id/" + this.state.usuId,
                  {
                    usu_id: this.state.usuId,
                    usu_estado: 2,
                    usu_motivo_eliminacion: 5,
                  },
                  {
                    headers: authHeader(),
                  }
                )
                .then((res) => {
                  this.guardarSolucion();
                });
            },
          },
        ],
      });
    } else {
      this.guardarSolucion();
    }
  };

  guardarSolucion = () => {
    let especialidad = null;
    axios
      .patch(
        global.config.modulos.gestion,
        {
          geg_id: this.state.gegId,
          geg_solucion: this.state.solucion,
          geg_especialidad: especialidad,
          geg_ubicacion: 6,
          geg_estado: 3,
          geg_fecha_finalizacion: moment().format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (this.state.chat > 0) {
          axios
            .patch(
              global.config.modulos.chat + "modificar_chat/",
              {
                id: this.state.chat,
                cht_estado: 0,
              },
              {
                headers: authHeader(),
              }
            )
            .then(() => {});
        }
        NotificationManager.success("Caso descartado con éxito");
        this.generarHistorial("Se descarta el caso", this.state.gegId, this.state.uspId);
        this.salir();
      });
  };

  generarHistorial = (accion, id, usp) => {
    axios
      .post(
        global.config.modulos.gestion + "historial/",
        {
          accion: accion,
          gestion: id,
          usuario_panel: usp,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        this.salir();
      });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Descartar caso</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">Indique la solución entregada</InputLabel>
                    <Select style={{ marginTop: 8, minWidth: 400 }} labelId="demo-simple-select-outlined-label" id="solucion" name="solucion" value={this.state.solucion} onChange={this.cambiarValores} label="Solución">
                      <MenuItem value={"No corresponde"}>No corresponde</MenuItem>
                      <MenuItem value={"Duplicado"}>Duplicado</MenuItem>
                      <MenuItem value={"Fallecido"}>Fallecido</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>

                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                    Cancelar
                  </Button>
                  <Button onClick={this.finalizarCaso} style={{ marginLeft: 20 }} color="primary" className="fondoVerde">
                    Aceptar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
