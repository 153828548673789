import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Grid from "@material-ui/core/Grid";
import { NotificationManager } from "react-notifications";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export default class FinalizarCaso extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      gestion: props.gdo_id,

      motivoFin: "",
      open: true,
      opciones: [],
    };
  }

  componentWillMount() {
    this.traerMotivos();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  finalizarCaso = () => {
    if (this.state.motivoFin == "") {
      return NotificationManager.error("El motivo no puede estar vacio");
    }

    this.registrarCasoFinalizado();
  };

  registrarCasoFinalizado = () => {
    let opcion = this.state.motivoFin;
    axios
      .patch(
        global.config.modulos.gestion + "finalizar_caso/",
        {
          gestion: this.state.gestion,
          motivo_fin: opcion,
          uspId: this.state.uspId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        NotificationManager.success("Caso finalizado con éxito!");
        this.salir();
      });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  traerMotivos = () => {
    console.log(this.state.cesfam);
    axios
      .get(global.config.modulos.gestion + "traer_motivos_finalizacion/", {
        headers: authHeader(),
        params: { cesfam: this.state.cesfam },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ opciones: res.data.datos });
        }
      });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" id="finalizarDemanda">
        <DialogContent>
          <div>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <h2>Finalizar solicitud</h2>
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
                <FormControl fullWidth>
                  <InputLabel id="label-sector">Motivo</InputLabel>
                  <Select labelId="label-sector" id="motivoFin" name="motivoFin" value={this.state.motivoFin} fullWidth onChange={this.handleChange}>
                    {this.state.opciones.map((e, index) => (
                      <MenuItem key={index} value={e.gmf_id}>
                        {e.gmf_motivo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
                <Button style={{ float: "right", marginLeft: 10 }} onClick={this.salir} className="botonSimple fondoRojo">
                  Cerrar
                </Button>
                <Button onClick={this.finalizarCaso} className="botonSimple fondoVerde" style={{ float: "right" }}>
                  Finalizar
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
