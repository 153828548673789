import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components

import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Titulo from "./../../components/titulo/Titulo.js";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Grid, List, ListItem } from "@material-ui/core";

// CSS
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

// custom components
import { store } from "./../../store/index";

import en_proc from "./../../assets/img/cambiohoras/en_proceso.svg";
import en_proc_select from "./../../assets/img/cambiohoras/en_proceso_select.svg";
import tomados from "./../../assets/img/cambiohoras/tomados.svg";
import tomados_select from "./../../assets/img/cambiohoras/tomados_select.svg";
import infor from "./../../assets/img/cambiohoras/informado.svg";
import infor_select from "./../../assets/img/cambiohoras/informado_select.svg";
import reagen from "./../../assets/img/cambiohoras/reagendado.svg";
import reagen_select from "./../../assets/img/cambiohoras/reagendamiento_select.svg";
import no_cont from "./../../assets/img/cambiohoras/no_contesto.svg";
import no_cont_select from "./../../assets/img/cambiohoras/no_contesto_select.svg";
import historial from "./../../assets/img/cambiohoras/historial.svg";
import historial_select from "./../../assets/img/cambiohoras/historial_select.svg";

import TablaHoras from "./TablaHoras.js";
import Tooltip from "@material-ui/core/Tooltip";
import signo from "./../../assets/img/signo.svg";

export default class MenuCambioHoras extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usu_id: props.usu_id,

      cesfam: store.getState().cesfam,
      uspId: store.getState().usp,
      herramientas: store.getState().herramientas,
    };
  }

  componentDidMount() {
    this.cambioOpciones(2);
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Cambios de hora <span title=''><img src=" + signo + "></img></span>";
  };

  cambioOpciones = (tipo) => {
    this.setState({ vista: false }, () => {
      this.setState({
        vista: true,
        opcion: tipo,
      });
    });
  };

  render() {
    return (
      <div>
        <div className="middle-area" style={{ width: "85%" }}>
          <div>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
                  <div style={{ marginBottom: 20 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <React.Fragment>
                          <Tooltip title="En Proceso" aria-label="En Proceso">
                            <div className="divImageUser">
                              <img className={this.state.opcion == 2 ? "imagenUsuario  centerImage menuSeleccionado" : "imagenUsuario  centerImage"} src={this.state.opcion == 2 ? en_proc_select : en_proc} onClick={() => this.cambioOpciones(2)} />
                            </div>
                          </Tooltip>

                          <Tooltip title="Casos tomados" aria-label="Casos tomados">
                            <div className="divImageUser">
                              <img className={this.state.opcion == 3 ? "imagenUsuario  centerImage menuSeleccionado" : "imagenUsuario  centerImage"} src={this.state.opcion == 3 ? tomados_select : tomados} onClick={() => this.cambioOpciones(3)} />
                            </div>
                          </Tooltip>

                          <Tooltip title="Reagendado" aria-label="Reagendado">
                            <div className="divImageUser">
                              <img className={this.state.opcion == 4 ? "imagenUsuario  centerImage menuSeleccionado" : "imagenUsuario  centerImage"} src={this.state.opcion == 4 ? reagen_select : reagen} onClick={() => this.cambioOpciones(4)} />
                            </div>
                          </Tooltip>

                          <Tooltip title="Informado" aria-label="Informado">
                            <div className="divImageUser">
                              <img className={this.state.opcion == 5 ? "imagenUsuario  centerImage menuSeleccionado" : "imagenUsuario  centerImage"} src={this.state.opcion == 5 ? infor_select : infor} onClick={() => this.cambioOpciones(5)} />
                            </div>
                          </Tooltip>

                          <Tooltip title="No contestó" aria-label="No contestó">
                            <div className="divImageUser">
                              <img className={this.state.opcion == 6 ? "imagenUsuario  centerImage menuSeleccionado" : "imagenUsuario  centerImage"} src={this.state.opcion == 6 ? no_cont_select : no_cont} onClick={() => this.cambioOpciones(6)} />
                            </div>
                          </Tooltip>

                          <Tooltip title="Historial" aria-label="Histórico">
                            <div className="divImageUser">
                              <img className={this.state.opcion == 7 ? "imagenUsuario  centerImage menuSeleccionado" : "imagenUsuario  centerImage"} src={this.state.opcion == 7 ? historial_select : historial} onClick={() => this.cambioOpciones(7)} />
                            </div>
                          </Tooltip>
                        </React.Fragment>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </React.Fragment>
          </div>
          <div className="middle-content">{this.state.vista ? <TablaHoras opcion={this.state.opcion} /> : null}</div>
        </div>
      </div>
    );
  }
}
