import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import { store } from "./../../store/index";
// CSS
import "./../../assets/css/inicio.css";

import GesIngreso from "./GestionIngreso";
import GesAnulado from "./GestionAnulado";
import GesConfirmado from "./GestionConfirmado";
import GesHistorico from "./GestionHistorico.js";
import GesNoContestado from "./GestionNoContestado.js";
import GesNoInscrito from "./GestionNoInscrito.js";
import GesSospechosos from "./GestionSospechoso";
import BuscarUsuarioEnGestion from "./../../components/GestionReservaExterna/BuscarUsuarioGestion";
import SuspenderRecordatorios from "components/GestionReservaExterna/SuspenderRecordatorios.js";
import GesNoExiste from "./GestionNoExiste";

import signo from "./../../assets/img/signo.svg";

export default class GestionReservaExterna extends Component {
  constructor(props) {
    super(props);

    let redireccion_ubicacion = 1;
    let rutFiltro = null;
    if (this.props.history.location.state != undefined) {
      redireccion_ubicacion = this.props.history.location.state.ubicacion;
    }

    this.state = {
      alerta: false,
      ubicacion: redireccion_ubicacion,
      demanda: [],
      modalGestionIngreso: true,
      modalGestionAnulado: false,
      modalGestionConfirmado: false,
      modalGestionHistorico: false,
      modalGestionNoContestado: false,
      modalGestionNoInscrito: false,
      modalGestionSuspendidos: false,
      modalGestionNoExiste: false,
      modalBuscarUsuarioEnGestion: false,
      modalSuspenderRecordatorios: false,
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      nombre_cesfam: store.getState().nombre_cesfam,
      nombre_ubicacion: "Ingresados",
      rutFiltro: null,
    };

    this.cambiarVentana = this.cambiarVentana.bind(this);
  }

  componentDidMount() {
    this.handleChange(this.state.ubicacion);
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Recordatorios externos <span title='Permite gestionar horas creadas de forma externa a HoraSalud'><img src=" + signo + "></img></span>";
  };

  volver = () => {
    this.props.history.push("/menuReservaExterna");
  };

  activarVista = (vistaActivada) => {
    this.setState(
      {
        modalGestionIngreso: false,
        modalGestionAnulado: false,
        modalGestionConfirmado: false,
        modalGestionHistorico: false,
        modalGestionNoContestado: false,
        modalGestionNoInscrito: false,
        modalGestionSuspendidos: false,
        modalGestionNoExiste: false,
      },
      () => {
        this.setState({ vistaActual: vistaActivada, [vistaActivada]: true }, () => {
          this.setState({ rutFiltro: null, uspFiltro: true });
        });
      }
    );
  };

  handleChange = (vista) => {
    if (vista == 1) {
      this.setState({ nombre_ubicacion: "Ingresados" }, () => {
        this.activarVista("modalGestionIngreso");
        this.titulo();
      });
    }
    if (vista == 2) {
      this.setState({ nombre_ubicacion: "Confirmados" }, () => {
        this.activarVista("modalGestionConfirmado");
        this.titulo();
      });
    }
    if (vista == 3) {
      this.setState({ nombre_ubicacion: "Anulados" }, () => {
        this.activarVista("modalGestionAnulado");
        this.titulo();
      });
    }
    if (vista == 4) {
      this.setState({ nombre_ubicacion: "No inscritos en HS" }, () => {
        this.activarVista("modalGestionNoInscrito");
        this.titulo();
      });
    }
    if (vista == 5) {
      this.setState({ nombre_ubicacion: "No contestados" }, () => {
        this.activarVista("modalGestionNoContestado");
        this.titulo();
      });
    }
    if (vista == 6) {
      this.setState({ nombre_ubicacion: "Histórico" }, () => {
        this.activarVista("modalGestionHistorico");
        this.titulo();
      });
    }
    if (vista == 7) {
      this.setState({ nombre_ubicacion: "Suspendidos" }, () => {
        this.activarVista("modalGestionSuspendidos");
        this.titulo();
      });
    }
    if (vista == 8) {
      this.setState({ nombre_ubicacion: "No existe" }, () => {
        this.activarVista("modalGestionNoExiste");
        this.titulo();
      });
    }
  };

  mostrarBuscarUsuarioEnGestion = () => {
    this.setState({ modalBuscarUsuarioEnGestion: true });
  };

  ocultarBuscarUsuarioEnGestion = () => {
    this.setState({ modalBuscarUsuarioEnGestion: false });
  };

  mostrarSuspenderRecordatorios = () => {
    this.setState({ modalSuspenderRecordatorios: true });
  };

  ocultarSuspenderRecordatorios = () => {
    this.setState({ modalSuspenderRecordatorios: false });
  };

  cambiarVentana = (vista, rut = null) => {
    console.log(vista);
    console.log(rut);
    this.setState({ rutFiltro: rut }, () => {
      this.handleChange(vista);
    });
  };

  render() {
    let activado = {
      marginLeft: 20,
      width: 70,
      marginRight: 0,
      marginTop: "-5px",
    };
    let noActivado = { marginLeft: 20, width: 40, marginRight: 0 };
    return (
      <div className="middle-area">
        <div className="middle-content">
          <GridContainer>
            <GridItem xs={12} style={{ margin: 0 }}>
              <a onClick={this.volver} className={"btnAgregarCaso fondoNaranjo"} style={{ cursor: "pointer", marginRight: 10, float: "left" }}>
                Volver al Menu
              </a>
              <a onClick={this.mostrarSuspenderRecordatorios} className={"btnAgregarCaso"} style={{ cursor: "pointer", marginRight: 10, backgroundColor: "#d04a4a" }}>
                Suspender horas
              </a>
              <a onClick={this.mostrarBuscarUsuarioEnGestion} className={"btnAgregarCaso fondoNaranjo"} style={{ cursor: "pointer", marginRight: 10 }}>
                Buscar Usuario
              </a>
            </GridItem>
            {this.state.modalGestionIngreso ? <GesIngreso usp={this.state.uspId} cesfam={this.state.cesfam} nombre_cesfam={this.state.nombre_cesfam} cambiarVentana={this.cambiarVentana} rutFiltro={this.state.rutFiltro}></GesIngreso> : null}
            {this.state.modalGestionAnulado ? <GesAnulado usp={this.state.uspId} cesfam={this.state.cesfam} nombre_cesfam={this.state.nombre_cesfam} cambiarVentana={this.cambiarVentana} rutFiltro={this.state.rutFiltro}></GesAnulado> : null}
            {this.state.modalGestionConfirmado ? <GesConfirmado usp={this.state.uspId} cesfam={this.state.cesfam} nombre_cesfam={this.state.nombre_cesfam} cambiarVentana={this.cambiarVentana} rutFiltro={this.state.rutFiltro}></GesConfirmado> : null}
            {this.state.modalGestionHistorico ? <GesHistorico usp={this.state.uspId} cesfam={this.state.cesfam} nombre_cesfam={this.state.nombre_cesfam} cambiarVentana={this.cambiarVentana} rutFiltro={this.state.rutFiltro}></GesHistorico> : null}
            {this.state.modalGestionNoContestado ? <GesNoContestado usp={this.state.uspId} cesfam={this.state.cesfam} nombre_cesfam={this.state.nombre_cesfam} cambiarVentana={this.cambiarVentana} rutFiltro={this.state.rutFiltro}></GesNoContestado> : null}
            {this.state.modalGestionNoInscrito ? <GesNoInscrito usp={this.state.uspId} cesfam={this.state.cesfam} nombre_cesfam={this.state.nombre_cesfam} cambiarVentana={this.cambiarVentana} rutFiltro={this.state.rutFiltro}></GesNoInscrito> : null}
            {this.state.modalGestionSuspendidos ? <GesSospechosos usp={this.state.uspId} cesfam={this.state.cesfam} nombre_cesfam={this.state.nombre_cesfam} cambiarVentana={this.cambiarVentana} rutFiltro={this.state.rutFiltro}></GesSospechosos> : null}
            {this.state.modalGestionNoExiste ? <GesNoExiste usp={this.state.uspId} cesfam={this.state.cesfam} nombre_cesfam={this.state.nombre_cesfam} cambiarVentana={this.cambiarVentana} rutFiltro={this.state.rutFiltro}></GesNoExiste> : null}
            {this.state.modalBuscarUsuarioEnGestion ? <BuscarUsuarioEnGestion ocultar={this.ocultarBuscarUsuarioEnGestion} cambiarVentana={this.cambiarVentana}></BuscarUsuarioEnGestion> : null}
            {this.state.modalSuspenderRecordatorios ? <SuspenderRecordatorios ocultar={this.ocultarSuspenderRecordatorios}></SuspenderRecordatorios> : null}
          </GridContainer>
        </div>
      </div>
    );
  }
}
