import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import axios from "axios";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Paginacion from "./../../components/Table/Pagination.js";
import MenuTab from "./../../components/GestionReservaExterna/MenuTab.js";
import Observaciones from "./../../components/GestionReservaExterna/Observaciones";
import Solucion from "./../../components/GestionReservaExterna/Resolucion";
import Contador from "./../../components/GestionReservaExterna/Contador";
import { NotificationManager } from "react-notifications";
import { store } from "./../../store/index";
import { confirmAlert } from "react-confirm-alert";
import Tooltip from "@material-ui/core/Tooltip";
import comentario from "../../assets/img/gestion/comentario.svg";
import ver_mas from "../../assets/img/gestion/ver_mas.svg";
import moment from "moment";
import editar from "../../assets/img/gestion/editar.svg";

// CSS
import "./../../assets/css/gestion.css";

export default class GestionNoInscrito extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: props.usp,
      cesfam: props.cesfam,
      ubicacion: 4,
      pagina: 0,
      filas: 10,
      total: 0,
      idRegistro: null,
      modalObservaciones: false,
      modalSolucion: false,
      uspTomado: null,
      demanda: [],
      texto_filtro: props.rutFiltro,
    };
  }

  copiarRut = (rut) => {
    navigator.clipboard.writeText(rut);
    NotificationManager.info("Rut copiado");
  };

  componentDidMount() {
    this.fetchGestion(this.state.pagina, this.state.filas);
  }

  fetchGestion = async (pagina, filas) => {
    axios
      .get(global.config.modulos.reservas_externas + "registros/", {
        headers: authHeader(),
        params: {
          ubicacion: this.state.ubicacion,
          pagina: pagina,
          filas: filas,
          usp: this.state.usp,
          cesfam: this.state.cesfam,
          texto_filtro: this.state.texto_filtro,
        },
      })
      .then((res) => {
        this.setState({
          demanda: res.data.datos,
          total: res.data.total,
          pagina: res.data.pagina,
        });
      });
  };

  handlePagination = (pagina) => {
    this.setState({
      pagina: pagina,
    });
    this.fetchGestion(pagina, this.state.filas);
  };

  handleFilasPagination = (filas) => {
    this.setState({
      filas: filas,
    });
    this.fetchGestion(this.state.pagina, filas);
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  aplicarFiltros = () => {
    this.fetchGestion(this.state.pagina, this.state.filas);
  };

  mostrarObservaciones = (id, usp) => {
    this.setState({
      idRegistro: id,
      modalObservaciones: true,
      uspTomado: usp,
    });
  };

  ocultarObservaciones = () => {
    this.setState({
      modalObservaciones: false,
    });
  };

  mostrarSolucion = (id) => {
    this.setState({
      idRegistro: id,
      modalSolucion: true,
    });
  };

  ocultarSolucion = () => {
    this.setState({
      modalSolucion: false,
    });
    this.fetchGestion(this.state.pagina, this.state.filas);
  };

  tomarCaso = (id) => {
    confirmAlert({
      title: "",
      message: "¿Esta segur@ que desea tomar este caso?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => {
            this.accionTomarCaso(id);
          },
          className: "btnAceptar",
        },
      ],
    });
  };

  accionTomarCaso = (id) => {
    axios
      .patch(
        global.config.modulos.reservas_externas + "tomar_caso/",
        {
          gestion: id,
          usp: this.state.uspId,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Caso tomado con éxito");
          this.fetchGestion(this.state.pagina, this.state.filas);
        } else {
          NotificationManager.success(res.data.mensaje);
          this.fetchGestion(this.state.pagina, this.state.filas);
        }
      });
  };

  devolverCaso = (id) => {
    confirmAlert({
      title: "",
      message: "¿Esta segur@ que desea devolver este caso a gestión?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => {
            this.accionDevolverCaso(id);
          },
          className: "btnAceptar",
        },
      ],
    });
  };

  accionDevolverCaso = (id) => {
    axios
      .put(
        global.config.modulos.reservas_externas + "actualizar/",
        {
          gre_id: id,
          gre_tomado: null,
          gre_fecha_toma_caso: null,
          gre_contestado: 0,
          gre_intentos: 0,
          gre_ubicacion: 1,
          gre_respuestas: null,
          gre_fecha_proximo_envio: moment().format("YYYY-MM-DD HH:mm:ss"),
        },
        { headers: authHeader() }
      )
      .then((res) => {
        NotificationManager.success("Caso devuelto a gestión");
        this.fetchGestion(this.state.pagina, this.state.filas);
      });
  };

  inscribirUsuario = (usuario) => {
    confirmAlert({
      title: "",
      message: "¿Esta segur@ que desea inscribir a este paciente?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => {
            this.accionInscribirUsuario(usuario);
          },
          className: "btnAceptar",
        },
      ],
    });
  };

  accionInscribirUsuario(usuario) {
    let data = {
      usu_estado: 1,
      usu_fecha_inscripcion: moment().format("YYYY-MM-DD HH:mm:ss"),
      usu_canal_inscripcion: 5,
      usp_id: this.state.uspId,
    };
    let apiUrl = global.config.modulos.usuarios + "por_id/" + usuario;
    const options = {
      method: "PATCH",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      NotificationManager.success("Paciente inscrito con éxito");
      this.fetchGestion(this.state.pagina, this.state.filas);
    });
  }

  render() {
    const { demanda } = this.state;
    const colorUrgencia = {
      Alto: "rgb(193 39 45)",
      Medio: "rgb(255 255 0)",
      Bajo: "rgb(57 181 74)",
      AltoColor: "#fff",
      MedioColor: "#000",
      BajoColor: "#fff",
    };

    return (
      <GridContainer style={{ width: "100%" }}>
        <GridItem xs={12} sm={12} md={12}>
          <div>
            <div style={{ float: "left" }}>
              <p style={{ float: "left", marginLeft: 20, height: 80 }}>
                <input
                  type="text"
                  name="texto_filtro"
                  value={this.state.texto_filtro}
                  onChange={this.cambiarValores}
                  placeholder="Nombre o Rut"
                  className={"inputText"}
                  style={{
                    padding: 6,
                    border: "1px solid black",
                    borderRadius: 5,
                    float: "left",
                  }}
                />
                <a
                  onClick={this.aplicarFiltros}
                  color="primary"
                  className="btnAgregarCaso fondoNaranjo"
                  style={{
                    padding: "7px 13px 7px 13px",
                    fontSize: 14,
                    float: "left",
                    marginLeft: 30,
                  }}
                >
                  Buscar
                </a>
              </p>
            </div>
            <MenuTab modal={4} cambiarVentana={this.props.cambiarVentana} />
            <div style={{ marginTop: 30, marginBottom: 100 }}>
              <TableContainer>
                <Table aria-label="simple table" className="tablaGeneralGestion bordeTabla">
                  <TableHead>
                    <TableRow>
                      <TableCell className="cellInicio" align="center">
                        Fecha de ingreso
                      </TableCell>
                      <TableCell align="center">Rut</TableCell>
                      <TableCell align="center">Nombre</TableCell>
                      <TableCell align="center">Edad</TableCell>
                      <TableCell align="center">Cesfam</TableCell>
                      <TableCell align="center">Tipo de atención</TableCell>
                      <TableCell align="center">Lugar de atención</TableCell>
                      <TableCell align="center">Fecha de atención</TableCell>
                      <TableCell align="center">Tomado por</TableCell>
                      <TableCell align="center">Observaciones</TableCell>
                      <TableCell align="center">Solución</TableCell>
                      <TableCell align="center">Acciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {demanda.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{row.gre_fecha_ingreso ? row.gre_fecha_ingreso.replace("T", " ") : "--"}</TableCell>
                        <TableCell align="center">{row.rut}</TableCell>
                        <TableCell align="center">{row.nombre_usuario}</TableCell>
                        <TableCell align="center">{row.edad}</TableCell>
                        <TableCell align="center">{row.cesfam_usuario}</TableCell>
                        <TableCell align="center">{row.gre_tipo_atencion}</TableCell>
                        <TableCell align="center">{row.lugar_atencion}</TableCell>
                        <TableCell align="center">{row.gre_fecha_atencion ? row.gre_fecha_atencion.replace("T", " ") : "--"}</TableCell>
                        <TableCell align="center">{row.usuario_panel ? row.usuario_panel : "--"}</TableCell>
                        <TableCell align="center">
                          <Tooltip title="Observaciones" aria-label="Observaciones">
                            <img className="mediumIcon" src={comentario} onClick={() => this.mostrarObservaciones(row.gre_id, row.gre_tomado)} />
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          {row.gre_solucion ? (
                            <React.Fragment>
                              <p>{row.gre_solucion}</p>
                              <p>{row.gre_fecha_termino.replace("T", " ")}</p>
                            </React.Fragment>
                          ) : null}
                          {row.gre_tomado == this.state.uspId ? (
                            <Tooltip title="Cambiar" aria-label="Cambiar">
                              <img className={row.gre_solucion ? "smallIcon" : "mediumIcon"} src={editar} onClick={() => this.mostrarSolucion(row.gre_id)} />
                            </Tooltip>
                          ) : (
                            <img
                              className={row.gre_solucion ? "smallIcon" : "mediumIcon"}
                              style={{
                                filter: "grayscale(100%)",
                                cursor: "default",
                              }}
                              src={editar}
                            />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {row.gre_tomado == this.state.uspId && (
                            <React.Fragment>
                              {row.inscrito == 0 && (
                                <button className="reservas_externas_boton" onClick={() => this.inscribirUsuario(row.gre_usuario)}>
                                  inscribir
                                </button>
                              )}
                              {row.inscrito == 1 && (
                                <button className="reservas_externas_boton" onClick={() => this.devolverCaso(row.gre_id)}>
                                  Devolver a gestión
                                </button>
                              )}
                            </React.Fragment>
                          )}
                          {!row.gre_tomado && (
                            <button className="reservas_externas_boton" onClick={() => this.tomarCaso(row.gre_id)}>
                              Tomar caso
                            </button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Paginacion total={this.state.total} paginacion={this.handlePagination} filas_paginacion={this.handleFilasPagination} />
            </div>
          </div>
          {this.state.modalObservaciones && <Observaciones idRegistro={this.state.idRegistro} uspTomado={this.state.uspTomado} ocultar={this.ocultarObservaciones} />}
          {this.state.modalSolucion && <Solucion idRegistro={this.state.idRegistro} ocultar={this.ocultarSolucion} />}
        </GridItem>
      </GridContainer>
    );
  }
}
