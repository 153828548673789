import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "./../../store/index";
import { confirmAlert } from "react-confirm-alert"; // Import
import { NotificationManager } from "react-notifications";
import axios from "axios";

// core components
import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";

import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";

import ComentariosDemanda from "components/Demanda/AgregarObservacion";
import FinalizarCaso from "components/Demanda/FinalizarCaso";
import Requerimiento from "components/Demanda/Requerimiento";
import PausarCaso from "components/Demanda/PausarCaso";
import CambiarAtencion from "components/Demanda/CambiarAtencion";

import Countdown from "react-countdown";
import Paginacion from "components/Table/Pagination.js";

import finalizar from "assets/img/gestion/finalizar.svg";
import derivar from "assets/img/gestion/derivar.svg";
import ver_mas from "assets/img/gestion/ver_mas.svg";
import pausar from "assets/img/gestion/pausar.svg";
import derivar_urgente from "assets/img/gestion/derivar_urgente.svg";
import respuestas from "assets/img/gestion/respuestas.svg";
import play from "assets/img/usuarios/play.svg";
import telefono from "assets/img/usuarios/telefono.svg";

import Motivo from "components/Demanda/Motivo.js";
import VistaUsuarios from "components/GestionPrioritarios/VistaUsuarios";
import CasosRegistrados from "components/Demanda/CasosRegistrados";
import DerivarCaso from "components/Demanda/DerivarCaso";
import HistorialCaso from "components/Demanda/HistorialCaso";
import alerta from "assets/img/gestion/alerta.svg";

import editar from "assets/img/gestion/editar.svg";

// CSS
import "assets/css/demanda.css";

export default class GestionDemandaAsignados extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alerta: false,
      ubicacion: 2,
      pagina: 0,
      filas: 5,
      secId: 0,
      total: 0,

      demanda: [],
      preguntas: false,
      especialidad: null,

      comentariosDemanda: false,
      modalFinalizarCaso: false,
      modalCasosRegistrados: false,
      modalRequerimiento: false,
      modalPausarCaso: false,
      modalCambiarAtencion: false,
      modalDerivarCaso: false,
      mostrarMotivo: false,
      modalUsuario: false,
      modalHistorialCaso: false,

      usuId: null,
      cuestionario: null,
      codigoCuestionario: null,

      idDemanda: null,

      estadoCaso: null,
      requerimiento: "",
      idRequerimiento: null,
      fechaDerivacion: null,
      observacion_libre: "",
      usuarios_panel_filtro: [],
      demandaTomado: null,

      texto_filtro: props.rutFiltro || "",

      cesfam: store.getState().cesfam,
      tomado_por_filtro: store.getState().usp,
      uspId: store.getState().usp,

      herramientas: store.getState().herramientas,
    };
  }

  mostrarModalUsuario = (usuId) => {
    this.setState({
      modalUsuario: true,
      usuId: usuId,
    });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  ocultarModalUsuario = () => {
    this.setState({
      modalUsuario: false,
    });
  };

  mostrarModalDerivarCaso = (id, derivacion) => {
    this.setState({
      modalDerivarCaso: true,
      idDemanda: id,
      derivacionUrgencia: derivacion,
    });
  };

  ocultarModalDerivarCaso = () => {
    this.setState({
      modalDerivarCaso: false,
    });
    this.fetchDemanda(this.state.pagina, this.state.filas);
  };

  mostrarModalHistorialCaso = (id) => {
    this.setState({
      modalHistorialCaso: true,
      idDemanda: id,
    });
  };

  ocultarModalHistorialCaso = () => {
    this.setState({
      modalHistorialCaso: false,
    });
  };

  mostrarModalCambiarAtencion = (id, especialidad) => {
    this.setState({
      modalCambiarAtencion: true,
      idDemanda: id,
      especialidad: especialidad,
    });
  };

  ocultarModalCambiarAtencion = (reiniciar = false) => {
    this.setState({
      modalCambiarAtencion: false,
    });
    if (reiniciar) {
      this.fetchDemanda(this.state.pagina, this.state.filas);
    }
  };

  mostrarMotivo = (motivo_audio, motivo_audio_inicio, motivo_audio_termino, motivo_texto, usuId, cuestionario, codigoCuestionario) => {
    this.setState(
      {
        audioMotivo: motivo_audio,
        audioMotivoInicio: motivo_audio_inicio,
        audioMotivoTermino: motivo_audio_termino,
        textoMotivo: motivo_texto,
        usuId: usuId,
        cuestionario: cuestionario,
        codigoCuestionario: codigoCuestionario,
      },
      () => {
        this.setState({ mostrarMotivo: true });
      }
    );
  };

  ocultarMotivo = () => {
    this.setState(
      {
        mostrarMotivo: false,
      },
      () => {
        this.fetchDemanda(this.state.pagina, this.state.filas);
      }
    );
  };

  mostrarModalRequerimiento = (id, telefonos, requerimiento, idRequerimiento, observacion_libre, fechaDerivacion) => {
    this.setState(
      {
        idDemanda: id,
        telefonos: telefonos,
        requerimiento: requerimiento,
        idRequerimiento: idRequerimiento,
        observacion_libre: observacion_libre,
        fechaDerivacion: fechaDerivacion,
      },
      () => {
        this.setState({ modalRequerimiento: true });
      }
    );
  };

  ocultarModalRequerimiento = () => {
    this.setState(
      {
        modalRequerimiento: false,
      },
      () => {
        this.fetchDemanda(this.state.pagina, this.state.filas);
      }
    );
  };

  mostrarModalPausarCaso = () => {
    this.setState({
      modalPausarCaso: true,
    });
  };

  ocultarModalPausarCaso = () => {
    this.setState(
      {
        modalPausarCaso: false,
      },
      () => {
        this.fetchDemanda(this.state.pagina, this.state.filas);
      }
    );
  };

  mostrarModalFinalizarCaso = (id, usuario) => {
    this.setState({
      modalFinalizarCaso: true,
      idDemanda: id,
      usuId: usuario,
    });
  };

  ocultarModalFinalizarCaso = () => {
    this.setState({
      modalFinalizarCaso: false,
    });
    this.fetchDemanda(this.state.pagina, this.state.filas);
  };

  mostrarModalCasosRegistrados = (id, usuario) => {
    this.setState(
      {
        idDemanda: id,
        usuId: usuario,
      },
      () => {
        this.setState({ modalCasosRegistrados: true });
      }
    );
  };

  ocultarModalCasosRegistrados = () => {
    this.setState({
      modalCasosRegistrados: false,
    });
    this.fetchDemanda(this.state.pagina, this.state.filas);
  };

  ocultarComentariosDemanda = () => {
    this.setState({ comentariosDemanda: false });
  };

  componentDidMount() {
    this.fetchDemanda(this.state.pagina, this.state.filas);
    this.listarUsp();
    this.props.desactivarFiltros();
  }

  fetchDemanda = async (pagina, filas) => {
    axios
      .get(global.config.modulos.demanda + "tabla_cliente/", {
        headers: authHeader(),
        params: {
          ubicacion: 2,
          pagina: pagina,
          filas: filas,
          texto_filtro: this.state.texto_filtro,
          cesfam: this.state.cesfam,
          usp: this.state.uspId,
          tomado: this.state.tomado_por_filtro,
        },
      })
      .then((res) => {
        this.setState({
          demanda: res.data.datos,
          total: res.data.total,
          pagina: res.data.pagina,
        });
      });
  };

  handlePagination = (pagina) => {
    this.setState({
      pagina: pagina,
    });
    this.fetchDemanda(pagina, this.state.filas);
  };

  handleFilasPagination = (filas) => {
    this.setState({
      filas: filas,
    });
    this.fetchDemanda(this.state.pagina, filas);
  };

  abrirComentariosDemanda = (usuario, estado, usp) => {
    this.setState({
      comentariosDemanda: true,
      usuId: usuario,
      estadoCaso: estado,
      demandaTomado: usp,
    });
  };

  renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <span style={{ color: "red" }}>
          - {days + " días, "} {hours}:{minutes}:{seconds}
        </span>
      );
    } else {
      return (
        <span>
          {days + " días, "}
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  aplicarFiltros = () => {
    this.fetchDemanda(this.state.pagina, this.state.filas);
  };

  pausarCaso = (id) => {
    //estado = 1, se reactiva, estado = 2, se pausa
    confirmAlert({
      title: "",
      message: "Seguro que desea pausar este caso?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          className: "btnAceptar",
          onClick: () => {
            axios
              .patch(
                global.config.modulos.demanda + "pausar_caso/",
                {
                  id: id,
                  estado: 2,
                  usuario_panel: this.state.uspId,
                },
                { headers: authHeader() }
              )
              .then((res) => {
                NotificationManager.success("Caso pausado con éxito");
                this.fetchDemanda(this.state.pagina, this.state.filas);
              });
          },
        },
      ],
    });
  };

  reanudarCaso = (id) => {
    //estado = 1, se reactiva, estado = 2, se pausa
    confirmAlert({
      title: "",
      message: "Seguro que desea reanudar este caso?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          className: "btnAceptar",
          onClick: () => {
            axios
              .patch(
                global.config.modulos.demanda + "pausar_caso/",
                {
                  id: id,
                  estado: 1,
                  usuario_panel: this.state.uspId,
                },
                { headers: authHeader() }
              )
              .then((res) => {
                NotificationManager.success("Caso reactivado con éxito");
                this.fetchDemanda(this.state.pagina, this.state.filas);
              });
          },
        },
      ],
    });
  };

  devolverCasoDerivado = (id, urgencia) => {
    let mensaje = urgencia == 1 ? "Seguro que desea devolver este caso?" : "Seguro que desea devolver este caso con urgencia?";
    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          className: "btnAceptar",
          onClick: () => {
            axios
              .patch(
                global.config.modulos.demanda + "devolver_caso_derivado/",
                {
                  id: id,
                  urgencia: urgencia,
                },
                { headers: authHeader() }
              )
              .then((res) => {
                this.fetchDemanda(this.state.pagina, this.state.filas);
              });
          },
        },
      ],
    });
  };

  listarUsp = () => {
    axios
      .get(global.config.modulos.demanda + "lista_usuarios_panel/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam_vista,
          tomado: this.state.uspId,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ usuarios_panel_filtro: res.data.datos });
        }
      });
  };

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div>
            <div style={{ float: "left" }}>
              <p style={{ float: "left", marginLeft: 20, height: 80 }}>
                <FormControl variant="outlined">
                  <select
                    style={{
                      marginTop: 8,
                      minWidth: 250,
                      padding: 6,
                      border: "1px solid black",
                      borderRadius: 5,
                    }}
                    id="tomado_por_filtro"
                    name="tomado_por_filtro"
                    value={this.state.tomado_por_filtro}
                    onChange={this.cambiarValores}
                  >
                    <option value={null} disabled selected>
                      Tomado por
                    </option>
                    <option value={""}>Todos</option>
                    <option value={this.state.uspId}>Mis registros</option>
                    {this.state.usuarios_panel_filtro.map((usp, index) => {
                      return (
                        <option key={index} value={usp.usp_id}>
                          {usp.usp_nombre + " " + usp.usp_apellido_p}
                        </option>
                      );
                    })}
                  </select>
                </FormControl>
              </p>
              <p style={{ float: "left", marginLeft: 20, height: 80 }}>
                <a
                  onClick={this.aplicarFiltros}
                  color="primary"
                  className="btnAgregarCaso fondoNaranjo"
                  style={{
                    padding: "7px 13px 7px 13px",
                    fontSize: 14,
                    float: "left",
                    marginLeft: 30,
                    marginTop: 5,
                  }}
                >
                  Buscar
                </a>
              </p>
            </div>
            <div style={{ marginBottom: 100 }}>
              <TableContainer>
                <Table aria-label="simple table" className="bordeTabla">
                  <TableHead>
                    <TableRow>
                      <TableCell className="cellInicio" align="center">
                        Fecha de ingreso
                      </TableCell>
                      <TableCell align="center">Tiempo de respuesta</TableCell>
                      <TableCell align="center">Nombre</TableCell>
                      <TableCell align="center">Cesfam</TableCell>
                      <TableCell align="center">Atención</TableCell>
                      <TableCell align="center">Motivo</TableCell>
                      <TableCell align="center">Registros previos</TableCell>
                      <TableCell align="center">Intentos</TableCell>
                      <TableCell align="center">Funcionario</TableCell>
                      <TableCell align="center">Observaciones</TableCell>
                      <TableCell align="center">Historial</TableCell>
                      {this.state.cesfam != 49 && <TableCell align="center">Requerimiento</TableCell>}
                      <TableCell className="cellTermino" align="center">
                        Acciones
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.demanda.length > 0 ? (
                      this.state.demanda.map((row) => (
                        <TableRow key={row.ged_id}>
                          <TableCell align="center">
                            {row.ged_fecha_registro.replace("T", " ")}
                            {row.ged_urgencia == 1 ? <p style={{ marginTop: 10, fontWeight: "bold" }}>Derivado</p> : null}
                            {row.ged_urgencia == 2 ? (
                              <p
                                style={{
                                  marginTop: 10,
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                Derivado con urgencia
                              </p>
                            ) : null}
                          </TableCell>
                          <TableCell align="center">
                            <Countdown date={row.fecha_limite.replace("T", " ")} renderer={this.renderer} overtime={true} />
                          </TableCell>
                          <TableCell align="center">
                            <a variant="contained" color="primary" style={{ cursor: "pointer" }} onClick={() => this.mostrarModalUsuario(row.usu_id)}>
                              {row.usu_nombre}
                            </a>
                            {row.fecha_nacimiento == null && (
                              <Tooltip title={<span style={{ fontSize: 15 }}>No cuenta con fecha de nacimiento</span>} aria-label="No cuenta con fecha de nacimiento">
                                <img className="mediumIcon" src={alerta} style={{ width: 15, marginTop: 10 }} />
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <a variant="contained" color="primary">
                              {row.cesfam_usuario}
                            </a>
                          </TableCell>
                          <TableCell align="center">
                            {row.especialidad}
                            {row.ged_tomado == this.state.uspId && this.state.cesfam != 49 && (
                              <Tooltip title="Cambiar atención" aria-label="Cambiar atención">
                                <img className="mediumIcon" src={editar} style={{ width: 25 }} onClick={() => this.mostrarModalCambiarAtencion(row.ged_id, row.ged_especialidad)} />
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title="Ver el motivo del caso" aria-label="Ver el motivo del caso">
                              <img className="mediumIcon" src={ver_mas} onClick={() => this.mostrarMotivo(row.motivo_audio, row.motivo_audio_inicio, row.motivo_audio_termino, row.motivo_texto, row.usu_id, row.cuestionario, row.codigo_cuestionario)} />
                            </Tooltip>
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title="Ver registros previos" aria-label="Ver registros previos">
                              <img className="mediumIcon" src={ver_mas} onClick={() => this.mostrarModalCasosRegistrados(row.ged_id, row.ged_usuario)} />
                            </Tooltip>
                          </TableCell>
                          <TableCell align="center">{row.total_rechazos ? row.total_rechazos : "0"}</TableCell>

                          <TableCell align="center">{row.usuario_panel}</TableCell>
                          <TableCell align="center">
                            <Tooltip title="Ver observaciones" aria-label="Ver observaciones">
                              <img className="smallMedIcon" style={{ marginTop: 0 }} src={respuestas} onClick={() => this.abrirComentariosDemanda(row.ged_usuario, row.ged_estado, row.ged_tomado)} />
                            </Tooltip>
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title="Ver historial" aria-label="Ver historial">
                              <img className="mediumIcon" src={ver_mas} onClick={() => this.mostrarModalHistorialCaso(row.ged_id)} />
                            </Tooltip>
                          </TableCell>

                          {this.state.cesfam != 49 && (
                            <TableCell align="center">
                              {row.ged_requerimiento && row.ged_requerimiento}
                              {row.ged_tomado == this.state.uspId && (
                                <Tooltip title="Requerimiento" aria-label="Requerimiento">
                                  <img className="mediumIcon" src={telefono} onClick={() => this.mostrarModalRequerimiento(row.ged_id, row.telefonos, row.ged_requerimiento, row.ged_requerimiento_id, row.ged_motivo_fin_libre, row.ged_fecha_derivacion)} />
                                </Tooltip>
                              )}
                            </TableCell>
                          )}

                          <TableCell align="center">
                            {row.ged_tomado == this.state.uspId ? (
                              <React.Fragment>
                                {row.ged_estado == 1 ? (
                                  <React.Fragment>
                                    <Tooltip title="Finalizar caso" aria-label="Finalizar caso">
                                      <img className="mediumIcon" src={finalizar} onClick={() => this.mostrarModalFinalizarCaso(row.ged_id, row.ged_usuario)} />
                                    </Tooltip>
                                    <Tooltip title="Pausar caso" aria-label="Pausar caso" onClick={() => this.pausarCaso(row.ged_id)}>
                                      <img className="mediumIcon" src={pausar} />
                                    </Tooltip>
                                    {row.requerimiento && row.requerimiento.gdr_puede_derivar ? (
                                      <>
                                        {this.state.cesfam != 49 && (
                                          <>
                                            {row.ged_funcionario_deriva ? (
                                              <React.Fragment>
                                                <Tooltip title="Devolver caso" aria-label="Devolver caso" onClick={() => this.devolverCasoDerivado(row.ged_id, 1)}>
                                                  <img className="mediumIcon" src={derivar} />
                                                </Tooltip>
                                                <Tooltip title="Devolver caso con urgencia" aria-label="Devolver caso con urgencia" onClick={() => this.devolverCasoDerivado(row.ged_id, 2)}>
                                                  <img className="mediumIcon" src={derivar_urgente} />
                                                </Tooltip>
                                              </React.Fragment>
                                            ) : (
                                              <React.Fragment>
                                                <Tooltip title="Derivar caso" aria-label="Derivar caso" onClick={() => this.mostrarModalDerivarCaso(row.ged_id, 1)}>
                                                  <img className="mediumIcon" src={derivar} />
                                                </Tooltip>
                                                {this.state.herramientas.includes("H46") && (
                                                  <Tooltip title="Derivar caso con urgencia" aria-label="Derivar caso con urgencia" onClick={() => this.mostrarModalDerivarCaso(row.ged_id, 2)}>
                                                    <img className="mediumIcon" src={derivar_urgente} />
                                                  </Tooltip>
                                                )}
                                              </React.Fragment>
                                            )}
                                          </>
                                        )}
                                      </>
                                    ) : null}
                                  </React.Fragment>
                                ) : (
                                  <Tooltip title="Reanudar el caso" aria-label="Reanudar el caso" onClick={() => this.reanudarCaso(row.ged_id)}>
                                    <img className="mediumIcon" src={play} />
                                  </Tooltip>
                                )}
                              </React.Fragment>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={13}>
                          No hay registros para mostrar
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Paginacion total={this.state.total} paginacion={this.handlePagination} filas_paginacion={this.handleFilasPagination} />
            </div>
          </div>

          {this.state.comentariosDemanda ? <ComentariosDemanda ocultar={this.ocultarComentariosDemanda} usuario={this.state.usuId} estadoCaso={this.state.estadoCaso} demandaTomado={this.state.demandaTomado}></ComentariosDemanda> : null}
          {this.state.modalCasosRegistrados ? <CasosRegistrados ocultar={this.ocultarModalCasosRegistrados} demanda={this.state.idDemanda} usuario={this.state.usuId}></CasosRegistrados> : null}
          {this.state.modalFinalizarCaso ? <FinalizarCaso ocultar={this.ocultarModalFinalizarCaso} idDemanda={this.state.idDemanda} activarVista={this.props.activarVista} usuId={this.state.usuId}></FinalizarCaso> : null}
          {this.state.mostrarMotivo ? <Motivo ocultar={this.ocultarMotivo} usuId={this.state.usuId} cuestionario={this.state.cuestionario} codigoCuestionario={this.state.codigoCuestionario} textoMotivo={this.state.textoMotivo} audioMotivoInicio={this.state.audioMotivoInicio} audioMotivoTermino={this.state.audioMotivoTermino} audioMotivo={this.state.audioMotivo}></Motivo> : null}
          {this.state.modalRequerimiento ? <Requerimiento ocultar={this.ocultarModalRequerimiento} idDemanda={this.state.idDemanda} telefonos={this.state.telefonos} activarVista={this.props.activarVista} requerimiento={this.state.requerimiento} idRequerimiento={this.state.idRequerimiento} observacion_libre={this.state.observacion_libre} fechaDerivacion={this.state.fechaDerivacion}></Requerimiento> : null}
          {this.state.modalPausarCaso ? <PausarCaso ocultar={this.ocultarModalPausarCaso}></PausarCaso> : null}
          {this.state.modalUsuario ? <VistaUsuarios ocultar={this.ocultarModalUsuario} usuario={this.state.usuId}></VistaUsuarios> : null}
          {this.state.modalDerivarCaso ? <DerivarCaso ocultar={this.ocultarModalDerivarCaso} idDemanda={this.state.idDemanda} urgencia={this.state.derivacionUrgencia}></DerivarCaso> : null}
          {this.state.modalHistorialCaso ? <HistorialCaso ocultar={this.ocultarModalHistorialCaso} idDemanda={this.state.idDemanda}></HistorialCaso> : null}
          {this.state.modalCambiarAtencion ? <CambiarAtencion ocultar={this.ocultarModalCambiarAtencion} idDemanda={this.state.idDemanda} especialidad={this.state.especialidad}></CambiarAtencion> : null}
        </GridItem>
      </GridContainer>
    );
  }
}
