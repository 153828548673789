import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";

import { Grid, List, ListItem, MenuItem, Select } from "@material-ui/core";

import { store } from "store/index";
import { confirmAlert } from "react-confirm-alert";

import InputLabel from "@material-ui/core/InputLabel";
import GridContainer from "components/grid/GridContainer.js";
import GridItem from "components/grid/GridItem.js";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import NotificationManager from "react-notifications/lib/NotificationManager.js";

import HelpIcon from "@material-ui/icons/Help";
import crearTotem from "assets/css/crearTotem.module.css";
import ticket_corto from "assets/img/admin_totem/ticket_corto.png";
import ticket_largo from "assets/img/admin_totem/ticket_largo.png";

// CSS
import "react-confirm-alert/src/react-confirm-alert.css";

export default class Ticket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totem: store.getState().totem,
      tickets: 0,
      prev_ticket: false,
    };
  }

  componentDidMount() {
    this.configuracion_ticket();
  }

  mostrarAlerta = (mensaje) => {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  };

  configuracion_ticket = () => {
    const apiUrl = global.config.modulos.admin_totem + "configuraciones/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          totem: this.state.totem,
          tipo: 2,
        },
      })
      .then((res) => {
        var data = res.data;
        document.getElementById("tamanoNumero").value = data["cnt_tamano_ticket"];
        document.getElementById("tamanoMensaje").value = data["cnt_tamano_mensaje"];
        document.getElementById("mensajeTicket").value = data["cnt_mensaje_ticket"];

        this.setState({ tickets: data["cnt_tipo"] });
      });
  };

  modificar_configuracion = (tipo) => {
    var data = {
      totem: this.state.totem,
      tipo: tipo,
      cnt_tipo: this.state.tickets,
    };

    var tamanoNumero = document.getElementById("tamanoNumero").value;
    var tamanoMensaje = document.getElementById("tamanoMensaje").value;
    var mensajeTicket = document.getElementById("mensajeTicket").value;

    if (tamanoNumero > 7 || tamanoNumero < 0) {
      NotificationManager.error("El tamaño del numero del ticket debe ser entre 0 o 7", "Atencion", 3000);
      return;
    }

    data["cnt_tamano_ticket"] = tamanoNumero;
    data["cnt_tamano_mensaje"] = tamanoMensaje;
    data["cnt_mensaje_ticket"] = mensajeTicket;

    axios.patch(global.config.modulos.admin_totem + "configuraciones_por_id/", data, { headers: authHeader() }).then((res) => {
      this.mostrarAlerta("Guardado con éxito");
    });
  };

  abrirTicket = () => {
    this.setState({
      prev_ticket: true,
    });
  };

  cerrarTicket = () => {
    this.setState({
      prev_ticket: false,
    });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    console.log(value);
    this.setState({ [name]: value });
  };

  render() {
    return (
      <GridContainer style={{ width: "100%", marginTop: "10px" }}>
        <GridItem xs={9} sm={9} md={9}>
          <Grid container spacing={3}>
            <Grid item xs={2} sm={2} className={crearTotem.MargenGrid}>
              <InputLabel className={crearTotem.textosTitulos}>Tamaño número</InputLabel>
              <input type="number" id="tamanoNumero" className={crearTotem.input} defaultValue={"7"} min="0" max="7" />
            </Grid>

            {/* <Grid item xs={2} sm={2} className={crearTotem.MargenGrid}>
              <InputLabel className={crearTotem.textosTitulos} style={{ display: "flex", alignItems: "center" }}>
                Seleccionar Ticket
                <HelpIcon onClick={this.abrirTicket} className={crearTotem.helpIcon} />
              </InputLabel>
              <Select labelId="demo-simple-select-label" id="tickets" disableUnderline={true} name="tickets" className={crearTotem.selectores} value={this.state.tickets} onChange={this.cambiarValores}>
                <MenuItem value={1}>Ticket Largo</MenuItem>
                <MenuItem value={2}>Ticket de agendamiento</MenuItem>
                <MenuItem value={4}>Ticket Corto</MenuItem>
              </Select>
            </Grid> */}

            <Grid item xs={2} sm={2} style={{ display: "None" }}>
              <InputLabel className={crearTotem.textosTitulos}>Tamaño letra mensaje</InputLabel>
              <input type="number" id="tamanoMensaje" className={crearTotem.input} defaultValue={"0"} min="0" max="7" />
            </Grid>

            <Grid item xs={12} sm={12}></Grid>

            <Grid item xs={8} sm={8}>
              <InputLabel className={crearTotem.textosTitulos}>Mensaje ticket</InputLabel>
              <textArea type="text" id="mensajeTicket" className={crearTotem.inputTextArea} />
            </Grid>
          </Grid>
          <a
            className={crearTotem.btnAgregarAcceso}
            style={{
              width: "6vw",
              height: "40px",
              marginTop: "5%",
            }}
            onClick={() => {
              this.modificar_configuracion(2);
            }}
          >
            {"Guardar"}
          </a>
        </GridItem>

        <Dialog open={this.state.prev_ticket} onClose={this.cerrarTicket} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
          <DialogContent>
            <h1>Seleccion de ticket </h1>
            <br></br>
            <br></br>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3} style={{ justifyContent: "center" }}>
                {this.state.tickets == 0 ? <InputLabel className={"textosTitulos"}>Al seleccionar una opción podrá ver una imagen de ejemplo de como será el ticket seleccionado </InputLabel> : <></>}
                {this.state.tickets == 1 ? <img src={ticket_largo} style={{ width: "50%" }} /> : <></>}
                {this.state.tickets == 2 ? <img src={ticket_corto} style={{ width: "50%" }} /> : <></>}
                {this.state.tickets == 4 ? <img src={ticket_corto} style={{ width: "50%" }} /> : <></>}
              </Grid>
            </Grid>
            <br></br>
            <br></br>
          </DialogContent>
        </Dialog>
      </GridContainer>
    );
  }
}
