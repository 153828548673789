import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { NotificationManager } from "react-notifications";
// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class ModificarRequerimiento extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      requerimiento: props.requerimiento,
      uspId: parseInt(store.getState().usp),
      open: true,
      gegId: props.gegId,
      requerimientos: [],
      tipo: props.tipo,
    };
  }

  componentDidMount() {
    this.traeRequerimientos();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  traeRequerimientos = () => {
    axios
      .get(global.config.modulos.gestion + "requerimientos/", {
        headers: authHeader(),
        params: {
          tipo: this.state.tipo,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ requerimientos: res.data.datos });
        }
      });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  enviarFormulario = (e) => {
    var data = {
      geg_id: this.state.gegId,
      geg_requerimiento: this.state.requerimiento,
    };

    let apiUrl = global.config.modulos.gestion;

    const options = {
      method: "PATCH",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      NotificationManager.success("Requerimiento modificado con éxito");
      this.salir();
    });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Modificar requerimiento</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">Requerimiento</InputLabel>
                    <Select style={{ marginTop: 8, minWidth: 300 }} labelId="demo-simple-select-outlined-label" id="requerimiento" name="requerimiento" value={this.state.requerimiento} onChange={this.cambiarValores} label="Requerimiento">
                      {this.state.requerimientos.map((res, index) => (
                        <MenuItem key={index} value={res.ggr_id}>
                          {res.ggr_requerimiento}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                    Cancelar
                  </Button>
                  <Button onClick={this.enviarFormulario} color="primary" className="fondoVerde">
                    Modificar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
