import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import chileanRut from "chilean-rut";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { DialogContentText } from "@material-ui/core";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Buttons from "react-multi-date-picker/components/button";
import { Input } from "@material-ui/core";
import "../../assets/css/usuarios.css";

export default class EditarRutListaOirs extends Component {
  constructor(props) {
    super(props);
    var today = new Date(),
      date = moment(today).format("YYYY-MM-DD");

    this.state = {
      //usp_id: store.getState().usp,
      cesfamus: store.getState().cesfam,
      //herramientas: store.getState().herramientas,
      //cliente: store.getState().cliente,
      open: true,
      //canal: 5,
      //rutExtranjero: false,
      //agregarndoUsuario: false,
      //ordenarPrioridad: null,
      pagina: 0,
      filas: 10,
      total: 0,
      id_usuario: props.id_usuario,
      tratamientos: [],
      rutM: props.rut,

      //fecha_inicio: moment(props.fecha_cita0).format('YYYY-MM-DD'),
      //tratamiento: props.programa_cita,
      cesfam_admin: store.getState().cesfam,
      usp_id: store.getState().usp,
    };
    console.log("usuario", this.state.id_usuario);
    console.log("Tratamiento", this.state.tratamiento);
  }
  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };
  obtenerTratamientos = () => {
    axios
      .get(global.config.modulos.listaEspera + "ver_tratamientos/", {
        headers: authHeader(),
        params: {
          cesfam_admin: this.state.cesfam_admin,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            tratamientos: res.data.datos,
          });
        }
      });
  };
  componentDidMount() {
    this.obtenerTratamientos();
  }

  modirut = (e) => {
    const rutM = e.target.value;
    this.setState({ rutM: rutM });
    //console.log(this.state.tratamiento);
  };

  agregarTratamiento = () => {
    if (this.state.rutM === "" || this.state.rutM === null) {
      NotificationManager.error("Debe ingresar un rut", "Atencion", 3000);
      return;
    } else if (chileanRut.validate(this.state.rutM) === false) {
      NotificationManager.error("Rut invalido", "Atencion", 3000);
      return;
    }
    axios
      .get(global.config.modulos.listaEspera + "obtener_rut_usuario/", {
        headers: authHeader(),
        params: {
          rut: this.state.rutM,
          cesfam: this.state.cesfam_admin,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          axios
            .patch(
              global.config.modulos.listaEspera + "editar_rut_usuario/",
              {
                rut: this.state.rutM,
                id_usuario: this.state.id_usuario,
                usp_id: this.state.usp_id,
              },
              {
                headers: authHeader(),
              }
            )
            .then((res) => {
              if (res.data.estado) {
                NotificationManager.success("Rut modificado correctamente", "Atencion", 3000);
                this.setState({
                  rutM: null,
                });
                this.salir();
              } else {
                NotificationManager.error(res.data.mensaje, "Atencion", 3000);
                return;
              }
            });
        } else {
          NotificationManager.error(res.data.mensaje, "Atencion", 3000);
          return;
        }
      });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario" style={{ maxWidth: "100%", maxHeight: "100%" }}>
          <DialogContent
            style={{
              width: "700px",
              maxWidth: "100%",
              height: "100%",
              maxHeight: "100%",
            }}
          >
            <div style={{ marginBottom: "20px" }}>
              <h2>Modificar rut</h2>
            </div>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div style={{ marginBottom: "3%" }}>
                  <div style={{ marginBottom: "5px" }}>
                    <FormControl style={{ width: "90%", marginRight: "4%" }}>
                      <InputLabel id="demo-simple-select-label">Modifique el rut mal ingresado</InputLabel>
                      <Input type="text" name="Rut" value={this.state.rutM} onChange={this.modirut} />
                    </FormControl>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginBottom: "3%",
                    marginLeft: "65%",
                    marginTop: "30px",
                  }}
                >
                  <Buttons
                    onClick={() => this.agregarTratamiento()}
                    //className={"btnOpcion fondoNaranjo"}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2%",
                      marginTop: "2%",
                      fontSize: "16px",
                      float: "left",
                      border: "0",
                      textAlign: "center",
                      backgroundColor: "#00ac77",
                      height: "40px",
                      width: "150px",
                    }}
                  >
                    Modificar
                  </Buttons>
                  <Buttons
                    onClick={this.salir}
                    className={"btnOpcion fondoNaranjo"}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2%",
                      marginTop: "2%",
                      fontSize: "16px",
                      float: "left",
                      border: "0",
                      textAlign: "center",
                      height: "40px",
                      width: "150px",
                    }}
                  >
                    Cancelar
                  </Buttons>
                </div>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
