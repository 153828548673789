import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Grid } from "@material-ui/core";
import graf from "../../../assets/css/graf.module.css";
import am5locales_es_ES from "@amcharts/amcharts5/locales/es_ES";
import { NotificationManager } from "react-notifications";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

export default class Grafico extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      comuna: props.comuna,
      tipoFiltro: props.tipoFiltro,
      fecha_semana: props.fecha_semana,
      fecha_mes: props.fecha_mes,
      fecha_año: props.fecha_año,
    };
  }

  componentDidMount() {
    this.MostrarGrafico();
  }

  MostrarGrafico() {
    this.setState({ datas: [] });

    axios
      .get(global.config.modulos.grafico_demanda + "grafico_entrada_empresa/", {
        headers: authHeader(),
        params: {
          comuna: this.state.comuna,
          tipoFiltro: this.state.tipoFiltro,
          semana: this.state.fecha_semana,
          mes: this.state.fecha_mes,
          año: this.state.fecha_año,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No hay datos");
          return;
        } else {
          this.setState({ datas: res.data.datos }, () => {
            if (!this.chart) {
              this.root = am5.Root.new("chartdiv1");
              this.root._logo.dispose();

              this.root.setThemes([am5themes_Animated.new(this.root)]);

              let chart = this.root.container.children.push(
                am5xy.XYChart.new(this.root, {
                  paddingLeft: 0,
                  paddingRight: 1,
                })
              );

              chart.topAxesContainer.children.push(
                am5.Label.new(this.root, {
                  text: "Minutos de llamadas por compañía teléfonica",
                  fontSize: 30,
                  fontWeight: "500",
                  x: am5.p0,
                  marginBottom: 20,
                })
              );

              let cursor = chart.set("cursor", am5xy.XYCursor.new(this.root, {}));
              cursor.lineY.set("visible", false);

              let xRenderer = am5xy.AxisRendererX.new(this.root, {
                minGridDistance: 30,
                minorGridEnabled: true,
              });

              xRenderer.labels.template.setAll({
                rotation: 0,
                centerY: am5.p100,
                centerX: am5.p50,
              });

              let xAxis = chart.xAxes.push(
                am5xy.CategoryAxis.new(this.root, {
                  maxDeviation: 0.3,
                  categoryField: "compañia",
                  renderer: xRenderer,
                  tooltip: am5.Tooltip.new(this.root, {}),
                })
              );

              let yRenderer = am5xy.AxisRendererY.new(this.root, {
                strokeOpacity: 0.1,
              });

              let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(this.root, {
                  maxDeviation: 0.3,
                  renderer: yRenderer,
                })
              );

              let series = chart.series.push(
                am5xy.ColumnSeries.new(this.root, {
                  name: "Series 1",
                  xAxis: xAxis,
                  yAxis: yAxis,
                  valueYField: "tiempo_legible",
                  sequencedInterpolation: true,
                  categoryXField: "compañia",
                  tooltip: am5.Tooltip.new(this.root, {
                    labelText: "[bold]{categoryX}[/]\n{valueY} minutos",
                    fill: am5.color(0xff5566),
                    autoTextColor: false,
                    keepTargetHover: true,
                  }),
                })
              );

              series.columns.template.setAll({
                cornerRadiusTL: 5,
                cornerRadiusTR: 5,
                strokeOpacity: 0,
              });

              series.columns.template.adapters.add("fill", (fill, target) => {
                const dataContext = target.dataItem.dataContext;
                return dataContext.color ? am5.color(dataContext.color) : fill;
              });

              series.columns.template.adapters.add("stroke", (stroke, target) => {
                const dataContext = target.dataItem.dataContext;
                return dataContext.color ? am5.color(dataContext.color) : stroke;
              });

              this.chart = chart;
            }

            let data = this.state.datas;
            this.chart.xAxes.getIndex(0).data.setAll(data);
            this.chart.series.getIndex(0).data.setAll(data);
          });
        }
      })
      .catch((error) => {
        NotificationManager.error("Error al cargar los datos: " + error.message);
      });
  }

  render() {
    return <Grid id="chartdiv1" className={graf.graficoCall2}></Grid>;
  }
}
