import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import Button from "@material-ui/core/Button";
import Titulo from "components/titulo/Titulo.js";
import signo from "assets/img/signo.svg";
import "assets/css/general.css";
import "assets/css/usuarios.css";
import "assets/css/general.css";
import DatePicker from "react-multi-date-picker";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { store } from "store/index";
import graf from "assets/css/graf.module.css";
import img from "../../../assets/img/icono calendario.svg";
export default class GraficoSectores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_ces: store.getState().cesfam,
      idCliente: store.getState().cliente,
      herramientas: store.getState().herramientas,
      usp: store.getState().usp,
      cargas: [],
      familias: [],
      listaFamilia: false,
      familia: "Especialidad",
      fam: "",
      atenciones: [],
      listaAtencion: false,
      atencion: "Tipo de atencion",
      aten: "",
      filtros: false,
      sectores: [],
      listaSector: false,
      sector: "Sector",
      sec: "",
      fecha_inicio: "",
      fec_ini: "",
      fecha_termino: "",
      fec_ter: "",
      semana: "",
      primeraCargaSemana: "",
      primeraCargaFecha: "",
      searchTerm: "",
      tablacondatos: false,
      tablasindatos: true,
      mensajeSinDatos: "Seleccione el tipo de atencion para buscar datos",
      filtrosFechas: false,
      orden: "",
      direccion: "asc",
      filtroDia: true,
      filtroSemana: true,
    };
  }

  traerCargas = () => {
    axios
      .get(global.config.modulos.grafico_demanda + "carga_hora_info/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.id_ces,
          cliente: this.state.idCliente,
          famiia: this.state.fam,
          atencion: this.state.aten,
          sector: this.state.sec,
          fecha_inicio: this.state.fec_ini,
          fecha_termino: this.state.fec_ter,
          semana: this.state.sem,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          this.setState(
            {
              cargas: [],
              tablasindatos: true,
              tablacondatos: false,
              mensajeSinDatos: "No se encontraron datos",
            },
            () => {
              if (this.state.fec_ini && this.state.fec_ter == "") {
                NotificationManager.info("falta la fecha de termino");
                return;
              }
            }
          );
        } else {
          if (this.state.aten == "") {
            this.setState({
              mensajeSinDatos: "Debe Ingresar el tipo de atencion",
            });
          } else {
            this.setState(
              {
                cargas: res.data.datos,
                tablacondatos: true,
                tablasindatos: false,
              },
              () => {
                console.log(this.state.cargas);
              }
            );
          }
        }
      });
  };

  traerSectores = () => {
    axios
      .get(global.config.modulos.grafico_demanda + "carga_horarios_sectores/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.id_ces,
          cliente: this.state.idCliente,
          especialidad: this.state.aten,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No se encontraron datos");
          return;
        } else {
          this.setState(
            {
              sectores: res.data.datos,
            },
            () => {
              console.log(this.state.sectores);
            }
          );
        }
      });
  };

  traerFamiliaEsp = () => {
    axios
      .get(global.config.modulos.grafico_demanda + "filtro_grafico_sectores2/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.id_ces,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No se encontraron datos");
          return;
        } else {
          this.setState(
            {
              familias: res.data.datos,
            },
            () => {
              console.log(this.state.familias);
            }
          );
        }
      });
  };

  traerAtenciones = () => {
    axios
      .get(global.config.modulos.grafico_demanda + "filtro_especialidades_familia/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.id_ces,
          familia: this.state.fam,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No se encontraron datos");
          return;
        } else {
          this.setState(
            {
              atenciones: res.data.datos,
            },
            () => {
              console.log(this.state.atenciones);
            }
          );
        }
      });
  };

  traerPrimeraCarga = () => {
    axios
      .get(global.config.modulos.grafico_demanda + "primer_horario/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.id_ces,
          cliente: this.state.idCliente,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("");
          return;
        } else {
          this.setState(
            {
              primeraCargaFecha: res.data.datos[0],
              primeraCargaSemana: res.data.datos[1],
            },
            () => {
              console.log(this.state.primeraCargaSemana);
              console.log(this.state.primeraCargaFecha);
            }
          );
        }
      });
  };

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Historial cargas horarios <span title=''><img src=" + signo + "></img></span>";
  };

  actualizarDatos = (nombre, valor) => {
    this.setState({ [nombre]: valor }, () => {
      if (nombre === "fam") {
        this.setState(
          {
            filtros: true,
            atencion: "Tipo de atencion",
            aten: "",
            sector: "Sector",
            sec: "",
            cargas: [],
            filtrosFechas: true,
            orden: "",
            direccion: "asc",
          },
          () => {
            this.traerAtenciones();
            this.traerSectores();
          }
        );
      } else if (nombre === "atencion") {
        this.setState(
          {
            sector: "Sector",
            sec: "",
            orden: "",
            direccion: "asc",
          },
          () => {
            this.traerSectores();
            this.traerCargas();
          }
        );
      } else if (nombre === "sector") {
        this.setState(
          {
            orden: "",
            direccion: "asc",
          },
          () => {
            this.traerCargas();
          }
        );
      }
    });
  };

  actualizarFecha = (nombre, valor) => {
    if (nombre === "semana" || nombre === "sem") {
      this.setState(
        {
          [nombre]: valor.target.value,
          fec_ini: "",
          fec_ter: "",
          filtroDia: false,
        },
        () => {
          this.setState(
            {
              filtroDia: true,
            },
            () => {
              this.traerCargas();
            }
          );
        }
      );
    } else {
      this.setState(
        {
          [nombre]: valor.day + "-" + valor.month.number + "-" + valor.year,
          sem: "",
          filtroSemana: false,
        },
        () => {
          this.setState(
            {
              filtroSemana: true,
            },
            () => {
              this.traerCargas();
            }
          );
        }
      );
    }
  };

  listaFamilia = () => {
    if (this.state.listaFamilia === false) {
      this.setState({
        listaFamilia: true,
      });
    } else {
      this.setState({
        listaFamilia: false,
      });
    }
  };

  listaAtencion = () => {
    if (this.state.listaAtencion === false) {
      this.setState({
        listaAtencion: true,
      });
    } else {
      this.setState({
        listaAtencion: false,
      });
    }
  };

  listaSector = () => {
    if (this.state.listaSector === false) {
      this.setState({
        listaSector: true,
      });
    } else {
      this.setState({
        listaSector: false,
      });
    }
  };

  Limpiar = () => {
    this.setState(
      {
        familia: "Especialidad",
        fam: "",
        atencion: "Tipo de atencion",
        aten: "",
        sector: "Sector",
        sec: "",
        fecha_inicio: "",
        fec_ini: "",
        fecha_termino: "",
        fec_ter: "",
        semana: "",
        cargas: [],
        atenciones: [],
        sectores: [],
        filtrosFechas: false,
      },
      () => {
        this.setState({
          filtrosFechas: true,
          mensajeSinDatos: "Seleccione el tipo de atencion para buscar datos",
        });
      }
    );
  };

  ordenarPor = (campo) => {
    if (this.state.orden === campo) {
      this.setState({ direccion: this.state.direccion === "asc" ? "desc" : "asc" }, () => {
        this.ordenarCargas();
      });
    } else {
      this.setState({ orden: campo, direccion: "asc" }, () => {
        this.ordenarCargas();
      });
    }
  };

  ordenarCargas = () => {
    const { cargas, orden, direccion } = this.state;
    const copiaCargas = [...cargas];

    copiaCargas.sort((a, b) => {
      if (orden === "total") {
        const valorA = parseFloat(a[orden]);
        const valorB = parseFloat(b[orden]);

        if (!isNaN(valorA) && !isNaN(valorB)) {
          const factor = direccion === "asc" ? 1 : -1;
          return (valorA - valorB) * factor;
        }
      } else {
        const factor = direccion === "asc" ? 1 : -1;
        return a[orden].localeCompare(b[orden]) * factor;
      }

      return 0;
    });

    this.setState({ cargas: copiaCargas });
  };

  renderIcon = (campo) => {
    if (campo === this.state.orden) {
      return this.state.direccion === "asc" ? "↓" : "↑";
    }
    return null;
  };

  handleSearch = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  componentDidMount() {
    this.titulo();
    this.traerFamiliaEsp();
    this.traerPrimeraCarga();
  }

  render() {
    const { cargas, searchTerm } = this.state;

    const filteredCargas = cargas.filter((item) => item.profesional.toLowerCase().includes(searchTerm.toLowerCase()));
    return (
      <div className="middle-area" style={{ zIndex: 45 }}>
        <div style={{ width: "98%", height: "20%" }}>
          <div className={graf.divUpperTabla}>
            <div className={graf.divBotones}>
              <button className={graf.buttonFamilia} onClick={() => this.listaFamilia()}>
                <span className="button-text">{this.state.familia}</span>
                <span className="arrow" style={{ fontSize: "80%" }}>
                  &#9660;
                </span>
              </button>

              {this.state.filtros && (
                <button className={graf.buttonAtencion} onClick={() => this.listaAtencion()}>
                  <span className="button-text">{this.state.atencion}</span>
                  <span className="arrow" style={{ fontSize: "80%" }}>
                    &#9660;
                  </span>
                </button>
              )}

              {this.state.filtros && (
                <button className={graf.buttonSector} onClick={() => this.listaSector()}>
                  <span className="button-text">{this.state.sector}</span>
                  <span className="arrow" style={{ fontSize: "80%" }}>
                    &#9660;
                  </span>
                </button>
              )}
            </div>
            {this.state.filtrosFechas && (
              <div className={graf.divFechas}>
                {this.state.filtroDia && (
                  <div
                    style={{
                      display: "flex",
                      textAlign: "left",
                      background: "transparent",
                      color: "rgb(128, 128, 128)",
                      width: "10rem",
                      marginLeft: "12px",
                      borderRadius: "2px",
                      border: "2px solid orange",
                      fontSize: "1vw",
                      position: "relative",
                      alignItems: "center",
                    }}
                  >
                    <DatePicker
                      onChange={(e) => {
                        this.actualizarFecha("fec_ini", e);
                      }}
                      weekDays={["D", "L", "M", "M", "J", "V", "S"]}
                      months={["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]}
                      minDate={this.state.primeraCargaFecha}
                      format="DD/MM/YYYY"
                      placeholder="Fecha de inicio"
                      style={{
                        background: "transparent",
                        border: "none",
                        width: "100%",
                      }}
                    ></DatePicker>
                    <img
                      style={{
                        width: "17px",
                        position: "absolute",
                        right: "4%",
                        top: "19%",
                        zIndex: "-5",
                      }}
                      src={img}
                    ></img>
                  </div>
                )}
                {this.state.filtroDia && (
                  <div
                    style={{
                      display: "flex",
                      textAlign: "left",
                      background: "transparent",
                      color: "rgb(128, 128, 128)",
                      width: "10rem",
                      marginLeft: "12px",
                      borderRadius: "2px",
                      border: "2px solid orange",
                      fontSize: "1vw",
                      position: "relative",
                      alignItems: "center",
                    }}
                  >
                    <DatePicker
                      onChange={(e) => {
                        this.actualizarFecha("fec_ter", e);
                      }}
                      weekDays={["D", "L", "M", "M", "J", "V", "S"]}
                      months={["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]}
                      minDate={this.state.primeraCargaFecha}
                      format="DD/MM/YYYY"
                      placeholder="Fecha de termino"
                      style={{
                        background: "transparent",
                        border: "none",
                        width: "100%",
                      }}
                    ></DatePicker>
                    <img
                      style={{
                        width: "17px",
                        position: "absolute",
                        right: "4%",
                        top: "19%",
                        zIndex: "-5",
                      }}
                      src={img}
                    ></img>
                  </div>
                )}
                {this.state.filtroSemana && (
                  <div className={graf.divSemana}>
                    <input
                      onChange={(e) => {
                        this.actualizarFecha("sem", e);
                      }}
                      type="week"
                      id="weekInput"
                      name="weekInput"
                      min={this.state.primeraCargaSemana}
                      style={{
                        display: "flex",
                        textAlign: "left",
                        background: "transparent",
                        color: "rgb(128, 128, 128)",
                        width: "10rem",
                        marginLeft: "12px",
                        borderRadius: "2px",
                        border: "2px solid orange",
                        fontSize: "0.8vw",
                        position: "relative",
                        alignItems: "center",
                        height: "2rem",
                      }}
                    />
                  </div>
                )}
                <div className={graf.divBtnLimpiar}>
                  {this.state.filtros && (
                    <Button onClick={this.Limpiar} className={graf.btnLimpiar}>
                      <span style={{ color: "#FFFFFF" }}>Limpiar</span>
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
          <div>
            {this.state.listaFamilia && (
              <ul onMouseLeave={() => this.listaFamilia()} className={graf.listaFamilia}>
                {this.state.familias.map((item) => (
                  <Button
                    onClick={() => {
                      this.actualizarDatos("familia", item.esp_especialidad);
                      this.actualizarDatos("fam", item.esp_sigla);
                    }}
                    style={{
                      borderBottom: "1px solid white",
                      color: "white",
                      fontSize: "12px",
                    }}
                    value={item.esp_sigla}
                  >
                    {item.esp_especialidad}
                  </Button>
                ))}
              </ul>
            )}

            {this.state.listaAtencion && (
              <ul onMouseLeave={() => this.listaAtencion()} className={graf.listaAtencion}>
                {this.state.atenciones.map((item) => (
                  <Button
                    onClick={() => {
                      this.actualizarDatos("atencion", item.esp_especialidad);
                      this.actualizarDatos("aten", item.esp_especialidad);
                    }}
                    style={{
                      borderBottom: "1px solid white",
                      color: "white",
                      fontSize: "12px",
                    }}
                    value={item.esp_sigla}
                  >
                    {item.esp_especialidad}
                  </Button>
                ))}
              </ul>
            )}

            {this.state.listaSector && (
              <ul onMouseLeave={() => this.listaSector()} className={graf.listaSector}>
                {this.state.sectores.map((item) => (
                  <Button
                    onClick={() => {
                      this.actualizarDatos("sector", item.sector);
                      this.actualizarDatos("sec", item.sector);
                    }}
                    style={{
                      borderBottom: "1px solid white",
                      color: "white",
                      fontSize: "12px",
                    }}
                    value={item.sector}
                  >
                    {item.sector}
                  </Button>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div style={{ width: "98%", height: "70%" }}>
          <div className={graf.divTitulo}>
            <h3
              style={{
                color: "rgb(102, 102, 102)",
                fontSize: "1.5rem",
                position: "relative",
                paddingTop: "1%",
                paddingBottom: "2%",
              }}
            >
              Tabla de cargas de horas
            </h3>
            <div style={{ marginRight: "1%" }}>
              <input className={graf.inputTable} placeholder="Buscar Profesional" type="text" onChange={this.handleSearch} />
            </div>
          </div>
          {this.state.tablacondatos && (
            <div className={graf.divTabla2}>
              <table>
                <thead>
                  <tr>
                    <th onClick={() => this.ordenarPor("profesional")}>Profesional {this.renderIcon("profesional")}</th>
                    <th onClick={() => this.ordenarPor("especialidad")}>Tipo de atención {this.renderIcon("especialidad")}</th>
                    <th onClick={() => this.ordenarPor("sector")}>Sector {this.renderIcon("sector")}</th>
                    <th onClick={() => this.ordenarPor("carga")}>Fecha de la carga {this.renderIcon("carga")}</th>
                    <th onClick={() => this.ordenarPor("atencion")}>Fecha de atención {this.renderIcon("atencion")}</th>
                    <th onClick={() => this.ordenarPor("total")}>Horas cargadas {this.renderIcon("total")}</th>
                    <th onClick={() => this.ordenarPor("usuariopanel")}>Quien cargo las horas {this.renderIcon("usuariopanel")}</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCargas.map((item, index) => (
                    <tr key={index}>
                      <td>{item.profesional}</td>
                      <td>{item.especialidad}</td>
                      <td>{item.sector}</td>
                      <td>{item.carga}</td>
                      <td>{item.atencion}</td>
                      <td>{item.total}</td>
                      <td>{item.usuariopanel}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {this.state.tablasindatos && (
            <div className={graf.divTabla2}>
              <table>
                <thead>
                  <tr>
                    <th>Profesional</th>
                    <th>Tipo de atención</th>
                    <th>Sector</th>
                    <th>Fecha de la carga</th>
                    <th>Fecha de atención</th>
                    <th>Horas cargadas</th>
                    <th>Quien cargo las horas</th>
                  </tr>
                </thead>
                <div className={graf.sinDatos}>
                  <label>{this.state.mensajeSinDatos}</label>
                </div>
              </table>
            </div>
          )}
        </div>
      </div>
    );
  }
}
