import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import axios from "axios";
import { CSVReader } from "react-papaparse";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { NotificationManager } from "react-notifications";
import csvFallecidos from "../../assets/img/imgCargaMasiva/fallecidos.png";
import { store } from "./../../store/index";
import carga_ciclos from "../../assets/img/imgCargaMasiva/ciclos.svg";
import carga_beneficios from "../../assets/img/imgCargaMasiva/beneficios.svg";
import carga_prenaturo from "../../assets/img/imgCargaMasiva/prematuros.svg";
import carga_reservaExterba from "../../assets/img/imgCargaMasiva/reservas externas.svg";
import carga_fallecidos from "../../assets/img/imgCargaMasiva/fallecidos.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { FaQuestionCircle } from "react-icons/fa";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import signo from "./../../assets/img/signo.svg";

// CSS
import "./../../assets/css/usuarios.css";
import moduleCM from "../../assets/css/cargamasiva.module.css";

export default class CargarFallecidos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      motivos: [],
      motivo: "",
      datos: [],
      herramientas: store.getState().herramientas,
      ultimaCarga: null,
      idCarga: null,
    };

    this.cargaMasiva = this.cargaMasiva.bind(this);
  }

  componentDidMount() {
    this.traerMotivosEliminacion();
    this.titulo();
  }

  ultimaCarga() {
    axios
      .get(global.config.modulos.cargas_masivas + "usuarios/ultima_carga/", {
        headers: authHeader(),
        params: { id_usp: this.state.uspId, motivo: this.state.motivo, cesfam: this.state.cesfam },
      })
      .then((res) => {
        if (res.data.estado) {
          let fecha = res.data.datos.cam_fecha_registro.split("T");
          dia = String(fecha[0]).split("-");
          let dia = dia[0] + "-" + dia[1] + "-" + dia[2];
          this.setState({ ultimaCarga: "Última carga: " + dia + " a las " + fecha[1], idCarga: res.data.datos.cam_id });
        } else {
          this.setState({ ultimaCarga: null });
        }
      });
  }

  descargarExcel = (e) => {
    const url = global.config.modulos.cargas_masivas + "usuarios/excel_errores/?id_carga=" + this.state.idCarga;
    window.open(url, "_blank").focus();
  };

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Carga masiva <span title='Permite ingresar o actualizar a través de de un archivo .csv información sobre los ciclos de los pacientes'><img style='width: 1vw' src=" + signo + "></img></span>";
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name == "motivo") {
        this.ultimaCarga();
      }
    });
  };

  handleOnDrop = (data) => {
    let registros = [];
    data.forEach((reg) => {
      registros.push(reg.data);
    });
    if (registros.length == 0) {
      NotificationManager.error("Revise el formato del csv o si contiene datos", "", 5 * 60 * 1000);
      return false;
    }
    this.setState({ datos: registros });
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOnRemoveFile = (data) => {
    console.log(data);
    this.setState({ datos: [] });
  };

  cargaMasiva() {
    document.getElementById("cargando").style.display = "block";

    axios
      .post(
        global.config.modulos.cargas_masivas + "usuarios/carga_masiva_pasivados/",
        {
          pasivados: this.state.datos,
          uspId: this.state.uspId,
          motivo: this.state.motivo,
          cesfam: this.state.cesfam,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        document.getElementById("cargando").style.display = "none";
        if (res.data.estado) {
          NotificationManager.success("los registros se están ingresando, se le notificará cuando termine", "", 5 * 60 * 1000);
        } else {
          NotificationManager.error(res.data.mensaje, "", 5 * 60 * 1000);
          if (res.data.errores.length > 0) {
            let mensaje = "";

            res.data.errores.forEach((reg) => {
              mensaje += reg + "\n";
            });

            const element = document.createElement("a");
            const file = new Blob([mensaje], {
              type: "text/plain;charset=utf-8",
            });
            element.href = URL.createObjectURL(file);
            element.download = "excepciones.txt";
            document.body.appendChild(element);
            element.click();
          }
        }
      })
      .catch((error) => {
        document.getElementById("cargando").style.display = "none";
        NotificationManager.error("Error al ingresar los registros", "", 5 * 60 * 1000);
      });
  }

  cargarCiclos = () => {
    this.props.history.push("/cargarCiclos");
  };

  cargarFallecidos = () => {
    this.props.history.push("/cargarFallecidos");
  };

  cargarCondiciones = () => {
    this.props.history.push("/cargarCondiciones");
  };

  cargarBeneficios = () => {
    this.props.history.push("/cargarBeneficios");
  };

  cargarReservasExternas = () => {
    this.props.history.push("/cargarReservasExternas");
  };

  traerMotivosEliminacion() {
    const apiUrl = global.config.modulos.cargas_masivas + "usuarios/motivos_eliminacion_usuario/";
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      this.setState({
        motivosEliminacion: res.data.motivos,
      });
    });
  }

  render() {
    const { ciclo, datos, ultimaCarga } = this.state;

    return (
      <div className="middle-area">
        <div className="middle-content">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <div className={moduleCM.divIcon}>
                    <img className={moduleCM.imgCMciclos1} src={carga_ciclos} onClick={this.cargarCiclos} />

                    <img className={moduleCM.imgCMbeneficio} src={carga_beneficios} onClick={this.cargarBeneficios} />

                    <img className={moduleCM.imgCMfallecido1} src={carga_fallecidos} onClick={this.cargarFallecidos} />

                    {this.state.herramientas.includes("H44") ? <img className={moduleCM.imgCMprematuro} src={carga_prenaturo} onClick={this.cargarCondiciones} /> : null}

                    {this.state.herramientas.includes("H47") ? <img className={moduleCM.imgCMreservaExterna} src={carga_reservaExterba} onClick={this.cargarReservasExternas} /> : null}
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Grid item xs={12} sm={12}>
                    {ultimaCarga && (
                      <Grid item xs={12} sm={12}>
                        <Button
                          onClick={this.descargarExcel}
                          style={{
                            backgroundColor: "#bf3c50",
                            color: "white",
                            marginTop: 20,
                          }}
                        >
                          Descargar errores - {ultimaCarga}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <br></br>
                  <Grid item xs={12} sm={12}>
                    <InputLabel className={moduleCM.labelCiclos} shrink htmlFor="age-native-label-placeholder" required>
                      ¿Porqué esta eliminando a los pacientes?
                    </InputLabel>
                    <Select className={moduleCM.selectCiclos} style={{ width: "20vw" }} id="motivo" name="motivo" value={this.state.motivo} onChange={this.cambiarValores} displayEmpty>
                      <MenuItem value="" disabled>
                        <em></em>
                      </MenuItem>
                      {this.state.motivosEliminacion &&
                        this.state.motivosEliminacion.map((mb, index) => (
                          <MenuItem value={mb.meu_id} key={index}>
                            {mb.meu_motivo}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                  <br></br>
                  <Grid item xs={12} sm={12}>
                    <span>Cargar CSV</span>
                    <div className={moduleCM.csvCargaMasiva}>
                      <CSVReader
                        onDrop={this.handleOnDrop}
                        onError={this.handleOnError}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={this.handleOnRemoveFile}
                        config={{
                          header: true,
                          dynamicTyping: true,
                          skipEmptyLines: true,
                          transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
                        }}
                      >
                        <div className={moduleCM.divSpanArchivo}>
                          <span className={moduleCM.spanArchivo}>Seleccione archivo</span>
                          <span style={{ fontSize: "0.9vw" }}> O arrastre archivo .CSV </span>
                        </div>
                      </CSVReader>
                      <LinearProgress id="cargando" style={{ display: "none" }} />
                    </div>
                  </Grid>
                </Grid>
                {datos.length > 0 && (
                  <Grid item xs={12}>
                    <Button
                      onClick={this.cargaMasiva}
                      style={{
                        backgroundColor: "#3cbf71",
                        color: "white",
                        marginTop: 20,
                      }}
                    >
                      Cargar registros
                    </Button>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={6} sm={6} md={6}>
                <img className={moduleCM.imgEjemploCiclo} src={csvFallecidos} alt="csv" />
              </Grid>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
