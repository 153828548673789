import React, { useLayoutEffect } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5locales_es_ES from "@amcharts/amcharts5/locales/es_ES";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import "./../../../assets/css/graficos.css";

export default function GraficoCoberturaInfluenza(props) {
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv");

    var responsive = am5themes_Responsive.newEmpty(root);

    responsive.addRule({
      name: "AxisRendererY",
      relevant: am5themes_Responsive.widthL,
      settings: {
        inside: true,
      },
    });

    root.setThemes([am5themes_Animated.new(root), responsive]);

    root.locale = am5locales_es_ES;

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );

    /* let exporting = am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {}),
            dataSource: props.data,
            numericFields: ["value_total", "value_enviados", "value_recibidos"],
            dateFields: ["date"],
            dataFields: {
              date: "Hora",
              value_total: "Total archivos",
              value_enviados: "Archivos enviados",
              value_recibidos: "Archivos recibidos",
            },
            dataFieldsOrder: ["Hora", "Archivos enviados", "Archivos recibidos", "Total archivos"],
            pngOptions: {
              disabled: true
            },
            jpgOptions: {
              disabled: true
            },
            pdfOptions: {
              addURL: false
            },
            jsonOptions: {
                disabled: true
            },
            csvOptions: {
                disabled: true
            },
            pdfdataOptions: {
                disabled: true
            },
            htmlOptions: {  
                disabled: true
            },
            printOptions : {
                disabled: true
              },
            title: 'Archivos Enviados y Recibidos',
            filePrefix: props.filename
          });

          exporting.events.on("pdfdocready", function(event) {

            event.doc.content.unshift({
              text: props.filename,
              margin: [0, 30],
              style: {
                fontSize: 25,
                bold: true,
              }
            });
          
          }); */

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "date",
        renderer: am5xy.AxisRendererX.new(root, {
          cellStartLocation: 0.3,
          cellEndLocation: 0.7,
        }),
        tooltip: am5.Tooltip.new(root, {
          themeTags: ["axis"],
          animationDuration: 200,
        }),
      })
    );

    xAxis.data.setAll(props.data);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    chart.get("colors").set("colors", [am5.color("#7d44b3"), am5.color("#88c8f2"), am5.color("#822362")]);

    let series0 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Vacunados",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "vacunados",
        categoryXField: "date",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{categoryX}\nArchivos Enviados : {valueY}",
        }),
      })
    );

    series0.columns.template.setAll({
      width: am5.percent(140),
      tooltipY: 0,
    });

    var modal = am5.Modal.new(root, {
      content: "No se encontraron datos para estos filtros",
    });

    series0.events.on("datavalidated", function (ev) {
      var series = ev.target;
      if (ev.target.data.length < 1) {
        modal.open();
      } else {
        modal.close();
      }
    });

    series0.data.setAll(props.data);

    let series1 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Metas",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "meta",
        categoryXField: "date",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "{categoryX}\nMeta de vacunas : {valueY}",
        }),
      })
    );

    series1.strokes.template.setAll({
      strokeWidth: 3,
    });

    series1.data.setAll(props.data);

    series1.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          strokeWidth: 3,
          stroke: series1.get("stroke"),
          radius: 5,
          fill: root.interfaceColors.get("background"),
        }),
      });
    });

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));

    let legend = chart.rightAxesContainer.children.push(
      am5.Legend.new(root, {
        width: 200,
        paddingLeft: 30,
        height: am5.percent(100),
      })
    );

    legend.data.setAll(chart.series.values);

    chart.appear(1000, 100);
    series0.appear();
    series1.appear();

    return () => {
      root.dispose();
    };
  }, []);
  return <div id="chartdiv" class="armchResponsiveBarVac"></div>;
}
