import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import moment from "moment";

import { connect } from "react-redux";
import chileanRut from "chilean-rut";
import Countdown from "react-countdown";

// core components
import ver_mas from "assets/img/iconos correccion/ver mas.svg";
import modi_icon from "assets/img/usuarios/icono_modificar.svg";

import Paginacion from "components/Table/Pagination.js";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import TableHeader from "components/Table/TableHeader";

import VistaUsuarios from "components/GestionPrioritarios/VistaUsuarios";
import EditarDatos from "components/GestionDespacho/EditarDatos";
import RegistrosPrevios from "components/GestionDespacho/RegistrosPrevios";

import { confirmAlert } from "react-confirm-alert"; // Import

class GestionGeneralIngreso extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datos: [],
      pagina: 0,
      total: 0,
      filas: 10,
      nombreTitulo: [
        { nombre: "Fecha de solicitud", filtro: "loi_fecharegistro" },
        { nombre: "Tiempo de espera", filtro: "loi_rut" },
        { nombre: "Rut paciente", filtro: "loi_nombre" },
        { nombre: "Dirección", filtro: "loi_fecha_nacimiento" },
        { nombre: "Registros previos", filtro: "loi_sexo" },
        { nombre: "Acciones", filtro: "loi_cesfam" },
      ],
      texto_filtro: props.texto_filtro,

      cesfam: store.getState().cesfam,
      uspId: store.getState().usp,

      registrosPrevios: false,
    };
  }

  componentDidMount() {
    this.traeTabla();
  }

  traeTabla = () => {
    axios
      .get(global.config.modulos.gestion + "tabla_gestion_despacho/", {
        headers: authHeader(),
        params: {
          texto_filtro: this.state.texto_filtro,
          cesfam: this.state.cesfam,
          ubicacion: 1,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ datos: res.data.datos });
        }
      });
  };

  mostrarModalUsuario = (usuId) => {
    this.setState({
      modalUsuario: true,
      usuId: usuId,
    });
  };

  ocultarModalUsuario = () => {
    this.setState({
      modalUsuario: false,
    });
  };

  tomarCaso = (gdoId) => {
    confirmAlert({
      title: "",
      message: "Seguro que desea tomar este caso?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          className: "btnAceptar",
          onClick: () => {
            axios
              .patch(
                global.config.modulos.gestion + "tomar_caso/",
                {
                  gdoId: gdoId,
                  uspId: this.state.uspId,
                },
                { headers: authHeader() }
              )
              .then((res) => {
                this.traeTabla();
              });
          },
        },
      ],
    });
  };

  mostrarRegistrosPrevios = (usu_id, gdoId) => {
    this.setState({
      registrosPrevios: true,
      usu_id: usu_id,
      gdoId: gdoId,
    });
  };

  ocultarRegistrosPrevios = () => {
    this.setState({
      registrosPrevios: false,
    });
  };

  mostrarModalEditar = (usu_id, direccion) => {
    this.setState({
      mostrarModalEditar: true,
      usu_id: usu_id,
      direccion: direccion,
    });
  };

  cerrarModalEditar = () => {
    this.setState({
      mostrarModalEditar: false,
    });
    this.traeTabla();
  };

  renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    return (
      <span>
        {days + " días, "}
        {hours}:{minutes}:{seconds}
      </span>
    );
  };

  render() {
    return (
      <div className="middle-content">
        <TableContainer style={{ marginBottom: "120px" }}>
          <Table className="tablaGeneralGestion bordeTabla" aria-label="simple table">
            <TableHeader nombreTitulo={this.state.nombreTitulo} datocampo={this.handleFiltradorCampo} />
            <TableBody>
              {this.state.datos.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">
                    <p style={{ fontSize: "14px" }}>{moment(row.gdo_fecha_registro).format("DD-MM-YYYY HH:mm")} hrs</p>
                  </TableCell>

                  <TableCell align="center">
                    <Countdown date={row.gdo_fecha_registro.replace("T", " ")} renderer={this.renderer} overtime={true} />
                  </TableCell>

                  <TableCell>{<a onClick={() => this.mostrarModalUsuario(row.usuario)}>{chileanRut.format(row.rut, true)}</a>}</TableCell>

                  <TableCell align="center">
                    <React.Fragment>
                      <p style={{ fontSize: "14px" }}>{row.direccion}</p>
                    </React.Fragment>
                    <React.Fragment>
                      <Tooltip title={<h4>Modificar dirección</h4>}>
                        <div>
                          <img class="mediumIcon" src={modi_icon} onClick={() => this.mostrarModalEditar(row.usuario, row.direccion)}></img>
                        </div>
                      </Tooltip>
                    </React.Fragment>
                  </TableCell>

                  <TableCell align="center">
                    <div>
                      <img class="mediumIcon" src={ver_mas} onClick={() => this.mostrarRegistrosPrevios(row.usuario, row.gdo_id)}></img>
                    </div>
                  </TableCell>

                  <TableCell align="center">
                    <Button className="botonesAlineados fondoNaranjo" variant="contained" color="primary" onClick={() => this.tomarCaso(row.gdo_id)}>
                      Tomar caso
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Paginacion total={this.state.total} paginacion={this.handlePagination} filas_paginacion={this.handleFilasPagination} />
        </TableContainer>
        {this.state.modalUsuario ? <VistaUsuarios ocultar={this.ocultarModalUsuario} usuario={this.state.usuId}></VistaUsuarios> : null}
        {this.state.mostrarModalEditar ? <EditarDatos ocultar={this.cerrarModalEditar} usu_id={this.state.usu_id} direccion={this.state.direccion}></EditarDatos> : null}
        {this.state.registrosPrevios ? <RegistrosPrevios ocultar={this.ocultarRegistrosPrevios} usu_id={this.state.usu_id} gestion={this.state.gdoId}></RegistrosPrevios> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(GestionGeneralIngreso);
