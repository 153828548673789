import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";

import { Grid, List, ListItem } from "@material-ui/core";
import { store } from "store/index";

import InputLabel from "@material-ui/core/InputLabel";
import GridContainer from "components/grid/GridContainer.js";
import GridItem from "components/grid/GridItem.js";

import Radio from "@material-ui/core/Radio";
import NativeSelect from "@material-ui/core/NativeSelect";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import { confirmAlert } from "react-confirm-alert";
import { socketTotem } from "service/socket";

import opc1 from "assets/img/admin/opc1.png";
import opc2 from "assets/img/admin/opc2.png";
import opc3 from "assets/img/admin/opc3.png";
import opc4 from "assets/img/admin/opc4.png";

// CSS
import "react-confirm-alert/src/react-confirm-alert.css";

export default class AccionesFuncionario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opciones: [],
      opciones: [],
      opcionesSub: [],
      opcionesSubMenu: [],
      opcSubmenu: 0,
      tituloOpcSubmenu: "",
      totem: store.getState().totem,
    };
  }

  componentDidMount() {
    this.opcionesCreadasTot();
  }

  mostrarAlerta = (mensaje) => {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  };

  opcionesCreadasTot = () => {
    const apiUrl = global.config.modulos.admin_totem + "opciones_creadas/?totem=" + this.state.totem;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var data = res.data;

      var datos = data["opciones"];
      if (datos.length > 0) {
        var rows = [],
          i = 0,
          len = datos.length;
        while (++i <= len) rows.push(i);
        this.setState({ opciones: rows, opcSeleccionadas: datos.length });

        for (var i = 0; i < datos.length; i++) {
          document.getElementById("nombre" + i).value = datos[i]["opc_nombre"];
          document.getElementById("sigla" + i).value = datos[i]["opc_abreviatura"];

          var opc_sub = datos[i]["opc_sub"];
          if (opc_sub.length > 0) {
            document.getElementById("select" + i).value = 1;
            document.getElementById("contenedorConfig" + i).style.display = "block";
            document.getElementById("contTit" + i).style.display = "none";
            document.getElementById("contPref" + i).style.display = "none";

            this.state.opcionesSubMenu[i] = opc_sub;
          } else {
            document.getElementById("contenedorConfig" + i).style.display = "none";
            document.getElementById("contTit" + i).style.display = "block";
            document.getElementById("contPref" + i).style.display = "block";

            document.getElementById("cantidad" + i).value = datos[i]["opc_cantidad_ticket"];
            document.getElementById("selectPreferencial" + i).value = datos[i]["opc_preferencial"];
          }
        }
      } else {
        var rows = [],
          i = 0,
          len = 4;
        while (++i <= len) rows.push(i);
        this.setState({ opciones: rows, opcSeleccionadas: 4 });
      }
    });
  };

  agregarOpciones = () => {
    var datos = [];
    for (var i = 0; i < this.state.opciones.length; i++) {
      var nombreOpc = document.getElementById("nombre" + i).value;
      var siglaOpc = document.getElementById("sigla" + i).value;
      var selectOpc = document.getElementById("select" + i);
      var selectOpc = selectOpc.options[selectOpc.selectedIndex].value;

      if (nombreOpc == "") {
        this.mostrarAlerta("Ingresa el nombre del modulo " + (i + 1));
        return;
      }
      if (siglaOpc == "") {
        this.mostrarAlerta("Ingresa la sigla del modulo " + (i + 1));
        return;
      }

      var cantidadOpc = 0;
      var selectPrefeOpc = 0;
      var subMenus = [];
      if (selectOpc == 0) {
        var cantidadOpc = document.getElementById("cantidad" + i).value;
        var selectPrefeOpc = document.getElementById("selectPreferencial" + i);
        var selectPrefeOpc = selectPrefeOpc.options[selectPrefeOpc.selectedIndex].value;
      } else {
        subMenus = this.state.opcionesSubMenu[i];
        if (!subMenus) {
          this.mostrarAlerta("Ingresa el subMenu de la opción " + nombreOpc);
          return;
        }
      }

      var data = {
        opc_nombre: nombreOpc.toUpperCase(),
        opc_sigla: siglaOpc.toUpperCase(),
        opc_submenu: selectOpc,
        opc_cantidad_ticket: cantidadOpc,
        opc_preferencial: selectPrefeOpc,
        subMenus: subMenus,
      };
      datos.push(data);
    }

    axios
      .post(
        global.config.modulos.admin_totem + "crear_opciones/",
        {
          opciones: datos,
          totem: 1,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        socketTotem.emit("reiniciar_pantalla", {
          totem: 1,
        });
        this.mostrarAlerta("Guardado con éxito");
      });
  };

  cambioSubMenu = (event) => {
    var idCont = event.target.id.substr(-1);
    var valor = event.target.value;
    if (valor == 1) {
      document.getElementById("contenedorConfig" + idCont).style.display = "block";
      document.getElementById("contTit" + idCont).style.display = "none";
      document.getElementById("contPref" + idCont).style.display = "none";

      document.getElementById("cantidad" + idCont).value = 1;
      document.getElementById("selectPreferencial" + idCont).value = 0;
    } else {
      document.getElementById("contenedorConfig" + idCont).style.display = "none";
      document.getElementById("contTit" + idCont).style.display = "block";
      document.getElementById("contPref" + idCont).style.display = "block";
    }
  };

  configuracionSubMenu = (event, id) => {
    var posicion = id.substr(-1);
    var nombreOpc = document.getElementById("nombre" + posicion).value;

    this.setState({
      tituloOpcSubmenu: nombreOpc,
      opcSubmenu: posicion,
      abrirSubMenu: true,
    });

    setTimeout(() => {
      this.datosOpcionesSubMenu();
    }, 500);
  };

  datosOpcionesSubMenu = () => {
    var datos = this.state.opcionesSubMenu[this.state.opcSubmenu];
    if (datos) {
      var rows = [],
        i = 0,
        len = datos.length;
      while (++i <= len) rows.push(i);
      this.setState({ opcionesSub: rows, opcSubSeleccionadas: datos.length });

      for (var i = 0; i < datos.length; i++) {
        document.getElementById("nombreSub" + i).value = datos[i]["opc_nombre"];
        document.getElementById("siglaSub" + i).value = datos[i]["opc_sigla"];
        document.getElementById("cantidadSub" + i).value = datos[i]["opc_cantidad_ticket"];
        document.getElementById("selectPreferencialSub" + i).value = datos[i]["opc_preferencial"];
      }
    } else {
      var rows = [],
        i = 0,
        len = 4;
      while (++i <= len) rows.push(i);
      this.setState({ opcionesSub: rows, opcSubSeleccionadas: 4 });
    }
  };

  salirSubMenu = () => {
    this.setState({
      abrirSubMenu: false,
    });
  };

  agregarOpcionesSubMenu = () => {
    var subMenus = [];
    for (var i = 0; i < this.state.opcionesSub.length; i++) {
      var nombreOpc = document.getElementById("nombreSub" + i).value;
      var siglaOpc = document.getElementById("siglaSub" + i).value;

      var cantidadOpc = document.getElementById("cantidadSub" + i).value;
      var selectPrefeOpc = document.getElementById("selectPreferencialSub" + i);
      var selectPrefeOpc = selectPrefeOpc.options[selectPrefeOpc.selectedIndex].value;

      if (nombreOpc == "") {
        this.mostrarAlerta("Ingresa el nombre del modulo " + (i + 1));
        return;
      }

      var data = {
        opc_nombre: nombreOpc.toUpperCase(),
        opc_sigla: siglaOpc.toUpperCase(),
        opc_cantidad_ticket: cantidadOpc,
        opc_preferencial: selectPrefeOpc,
      };
      subMenus.push(data);
    }

    this.state.opcionesSubMenu[this.state.opcSubmenu] = subMenus;

    this.setState({
      abrirSubMenu: false,
    });
    this.mostrarAlerta("Guardado con éxito");
  };

  cambiarOpc = (event) => {
    var rows = [],
      i = 0,
      len = event.target.value;
    while (++i <= len) rows.push(i);

    this.setState({ opciones: rows, opcSeleccionadas: event.target.value });
  };

  cambiarOpcSubMenu = (event) => {
    var rows = [],
      i = 0,
      len = event.target.value;
    while (++i <= len) rows.push(i);

    this.setState({
      opcionesSub: rows,
      opcSubSeleccionadas: event.target.value,
    });
  };

  render() {
    return (
      <GridContainer style={{ width: "100%", marginTop: "10px" }}>
        <GridItem xs={12} sm={12} md={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div className="botonesOpc">
                <Grid container spacing={3}>
                  <Grid item xs={4} sm={1}>
                    <img src={opc4} />
                    <Radio value="4" onChange={this.cambiarOpc} checked={this.state.opcSeleccionadas == "4"} />
                  </Grid>
                  <Grid item xs={4} sm={1}>
                    <img src={opc3} />
                    <Radio value="3" onChange={this.cambiarOpc} checked={this.state.opcSeleccionadas == "3"} />
                  </Grid>
                  <Grid item xs={4} sm={1}>
                    <img src={opc2} />
                    <Radio value="2" onChange={this.cambiarOpc} checked={this.state.opcSeleccionadas == "2"} />
                  </Grid>
                  <Grid item xs={4} sm={1}>
                    <img src={opc1} />
                    <Radio value="1" onChange={this.cambiarOpc} checked={this.state.opcSeleccionadas == "1"} />
                  </Grid>
                </Grid>
              </div>
            </Grid>

            <Grid item xs={12} sm={12}>
              <a
                className={"btnAcciones btnAgregarAcceso"}
                onClick={() => {
                  this.agregarOpciones();
                }}
              >
                {"Guardar"}
              </a>
              {this.state.opciones.map((opc, index) => (
                <Grid item xs={12} sm={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={4} sm={2}>
                      <InputLabel className={"textosTitulos"}>Nombre modulo {index + 1}</InputLabel>
                      <input type="text" id={"nombre" + index} className={"inputText"} />
                    </Grid>

                    <Grid item xs={4} sm={2}>
                      <InputLabel className={"textosTitulos"}>Sigla modulo {index + 1}</InputLabel>
                      <input type="text" id={"sigla" + index} className={"inputText"} />
                    </Grid>

                    <Grid item xs={4} sm={2}>
                      <InputLabel className={"textosTitulos"}>Agregar submenús</InputLabel>
                      <NativeSelect className={"selectT"} id={"select" + index} onChange={this.cambioSubMenu}>
                        <option value="0">No</option>
                        <option value="1">Sí</option>
                      </NativeSelect>
                    </Grid>

                    <Grid item xs={4} sm={2} id={"contTit" + index}>
                      <InputLabel className={"textosTitulos"}>Cantidad tickets</InputLabel>
                      <input type="number" id={"cantidad" + index} className={"inputText"} defaultValue={"1"} min="1" />
                    </Grid>

                    <Grid item xs={4} sm={2} id={"contPref" + index}>
                      <InputLabel className={"textosTitulos"}>Opción preferencial</InputLabel>
                      <NativeSelect className={"selectT"} id={"selectPreferencial" + index}>
                        <option value="0">No</option>
                        <option value="1">Sí</option>
                      </NativeSelect>
                    </Grid>

                    <div id={"contenedorConfig" + index} style={{ display: "none" }}>
                      <Grid item xs={4} sm={2}>
                        <a
                          className={"btnsNormal"}
                          onClick={() => {
                            this.configuracionSubMenu(this, "subMenu" + index);
                          }}
                        >
                          {"Configuración submenú"}
                        </a>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </GridItem>

        {this.state.abrirSubMenu ? (
          <div>
            <Dialog open={this.state.abrirSubMenu} onClose={this.salirSubMenu} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
              <DialogContent>
                <h1>Configuración submenú {this.state.tituloOpcSubmenu}</h1>
                <br></br>
                <br></br>

                <GridItem xs={12} sm={12} md={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <div className="botonesOpc">
                        <Grid container spacing={3}>
                          <Grid item xs={4} sm={1}>
                            <img src={opc4} />
                            <Radio value="4" onChange={this.cambiarOpcSubMenu} checked={this.state.opcSubSeleccionadas == "4"} />
                          </Grid>
                          <Grid item xs={4} sm={1}>
                            <img src={opc3} />
                            <Radio value="3" onChange={this.cambiarOpcSubMenu} checked={this.state.opcSubSeleccionadas == "3"} />
                          </Grid>
                          <Grid item xs={4} sm={1}>
                            <img src={opc2} />
                            <Radio value="2" onChange={this.cambiarOpcSubMenu} checked={this.state.opcSubSeleccionadas == "2"} />
                          </Grid>
                          <Grid item xs={4} sm={1}>
                            <img src={opc1} />
                            <Radio value="1" onChange={this.cambiarOpcSubMenu} checked={this.state.opcSubSeleccionadas == "1"} />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <a
                        className={"btnAcciones btnAgregarAcceso"}
                        onClick={() => {
                          this.agregarOpcionesSubMenu();
                        }}
                      >
                        {"Guardar"}
                      </a>
                      {this.state.opcionesSub.map((opc, index) => (
                        <Grid item xs={12} sm={12}>
                          <Grid container spacing={3}>
                            <Grid item xs={4} sm={3}>
                              <InputLabel className={"textosTitulos"}>Nombre modulo {index + 1}</InputLabel>
                              <input type="text" id={"nombreSub" + index} className={"inputText"} />
                            </Grid>

                            <Grid item xs={4} sm={3}>
                              <InputLabel className={"textosTitulos"}>Sigla modulo {index + 1}</InputLabel>
                              <input type="text" id={"siglaSub" + index} className={"inputText"} />
                            </Grid>

                            <Grid item xs={4} sm={3}>
                              <InputLabel className={"textosTitulos"}>Cantidad tickets</InputLabel>
                              <input type="number" id={"cantidadSub" + index} className={"inputText"} defaultValue={"1"} min="1" />
                            </Grid>

                            <Grid item xs={4} sm={3}>
                              <InputLabel className={"textosTitulos"}>Opción preferencial</InputLabel>
                              <NativeSelect className={"selectT"} id={"selectPreferencialSub" + index}>
                                <option value="0">No</option>
                                <option value="1">Sí</option>
                              </NativeSelect>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </GridItem>
              </DialogContent>
            </Dialog>
          </div>
        ) : null}
      </GridContainer>
    );
  }
}
