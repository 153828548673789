import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";

// core components
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Titulo from "../../components/titulo/Titulo.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import MaterialTable from "material-table";

import VistaUsuarios from "components/GestionPrioritarios/VistaUsuarios";
import chileanRut from "chilean-rut";

import signo from "./../../assets/img/signo.svg";

class Beneficios extends Component {
  constructor() {
    super();

    this.state = {
      datosListarBeneficios: [],
      cesfam: store.getState().cesfam,
      rut: [],
      id_usuario: 0,
    };
  }

  componentDidMount() {
    this.titulo();
    this.beneficios();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Beneficios Finalizados <span title='Permite ver los beneficios finalizados de los pacientes'><img src=" + signo + "></img></span>";
  };

  beneficios = () => {
    let apiUrl = global.config.modulos.beneficios + "verbeneficiosfin/";
    axios.get(apiUrl, { headers: authHeader(), params: { cesfam: this.state.cesfam } }).then((res) => {
      this.setState({ datosListarBeneficios: res.data.datos });
    });
  };

  mostrarModalUsuario = (idusuario) => {
    if (idusuario) {
      this.setState({
        modalUsuario: true,
        id_usuario: idusuario,
      });
    }
  };

  ocultarModalUsuario = () => {
    this.setState({
      modalUsuario: false,
    });
  };

  render() {
    return (
      <div className="middle-area">
        <div className="middle-content">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} id="tablaBloqueos">
              <MaterialTable
                title=""
                columns={[
                  {
                    title: "Rut del paciente",
                    field: "rut_paciente",
                    render: (rowData) => (
                      <React.Fragment>
                        <strong>
                          <a style={{ cursor: "pointer" }} onClick={this.mostrarModalUsuario.bind(this, rowData.usu_id)}>
                            {chileanRut.format(rowData.rut_paciente, true)}
                          </a>
                        </strong>
                      </React.Fragment>
                    ),
                  },
                  { title: "Nombre del paciente", field: "nombre_usuario" },
                  { title: "Nombre del beneficio", field: "nombre_beneficio" },
                  { title: "Fecha de inicio", field: "fecha_inicio" },
                  { title: "Fecha de término", field: "fecha_termino" },
                  { title: "Motivo de término", field: "motivo_termino" },
                  {
                    title: "Fecha de desinscripción",
                    field: "fecha_desinscripcion",
                  },
                  {
                    title: "Funcionario que desinscribió",
                    field: "usuario_panel_desinscripcion",
                  },
                ]}
                data={this.state.datosListarBeneficios}
                localization={{
                  header: {
                    actions: "Acciones",
                  },
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    labelRowsSelect: "filas",
                    firstTooltip: "primera",
                    previousTooltip: "anterior",
                    nextTooltip: "siguiente",
                    lastTooltip: "última",
                  },
                  toolbar: {
                    nRowsSelected: "{0} fila(s) seleccionadas",
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                  },
                  body: {
                    emptyDataSourceMessage: "No hay Beneficios Finalizados",
                    filterRow: {
                      filterTooltip: "Buscar",
                    },
                  },
                }}
              />
            </GridItem>
            {this.state.modalUsuario ? <VistaUsuarios ocultar={this.ocultarModalUsuario} usuario={this.state.id_usuario}></VistaUsuarios> : null}
          </GridContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Beneficios);
