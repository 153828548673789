import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import moment from "moment";
import "moment/locale/es";

import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

export default class MostrarAudios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      audios: props.audios,
      ocultar: props.ocultar,
      open: true,
      modalEscucharAudio: false,
      modalRespuesta: false,
      grabacion: "",
      fecha: "",
      respuesta: "",
    };
  }

  render() {
    return (
      <React.Fragment>
        <Dialog open={this.state.open} onClose={this.state.ocultar} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="beneficioUsuario">
          <DialogContent>
            <div style={{ marginBottom: "3%" }}>
              <h2>Registros de Ofrecimientos</h2>
            </div>
            <TableContainer>
              <Table aria-label="simple table" className="tablaGeneralGestion bordeTabla">
                <TableHead>
                  <TableRow>
                    <TableCell className="cellInicio" align="center">
                      <a style={{ cursor: "pointer", fontWeight: "bold" }}>Fecha contacto</a>
                    </TableCell>
                    <TableCell className="cellInicio" align="center">
                      <a style={{ cursor: "pointer", fontWeight: "bold" }}>Teléfono</a>
                    </TableCell>
                    <TableCell className="cellInicio" align="center">
                      <a style={{ cursor: "pointer", fontWeight: "bold" }}>Intentos</a>
                    </TableCell>
                    <TableCell align="center">
                      <a style={{ cursor: "pointer", fontWeight: "bold" }}>Canal</a>
                    </TableCell>
                    <TableCell align="center">
                      <a style={{ cursor: "pointer", fontWeight: "bold" }}>Detalle</a>
                    </TableCell>
                    <TableCell align="center">
                      <a style={{ cursor: "pointer", fontWeight: "bold" }}>Estado</a>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.audios.map((audio, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        {moment(audio.fecha).format("DD-MM-YYYY")} {moment(audio.fecha).format("HH:mm:ss")}
                      </TableCell>
                      <TableCell align="center">{audio.telefono}</TableCell>
                      <TableCell align="center">{audio.intentos}</TableCell>
                      <TableCell align="center">{audio.canal}</TableCell>

                      {audio.respuesta === 1 ? (
                        <TableCell align="center">
                          <div
                            style={{
                              backgroundColor: "rgb(77, 170, 115)",
                              color: "rgb(255, 255, 255)",
                              borderRadius: "5px",
                            }}
                          >
                            {" "}
                            Reserva{" "}
                          </div>
                        </TableCell>
                      ) : audio.respuesta === 2 ? (
                        <TableCell align="center">
                          <div
                            style={{
                              backgroundColor: "rgb(209, 2, 2)",
                              color: "rgb(255, 255, 255)",
                              borderRadius: "5px",
                            }}
                          >
                            No reserva
                          </div>
                        </TableCell>
                      ) : audio.intentos === 3 && audio.respuesta === 0 ? (
                        <TableCell align="center">
                          <div
                            style={{
                              backgroundColor: "rgb(209, 2, 2)",
                              color: "rgb(255, 255, 255)",
                              borderRadius: "5px",
                            }}
                          >
                            No contesta
                          </div>
                        </TableCell>
                      ) : audio.contesta === 1 && audio.respuesta === 0 ? (
                        <TableCell align="center">
                          <div
                            style={{
                              backgroundColor: "rgb(41, 132, 168)",
                              color: "rgb(255, 255, 255)",
                              borderRadius: "5px",
                            }}
                          >
                            No presionó ninguna opción
                          </div>
                        </TableCell>
                      ) : (
                        <TableCell align="center">
                          <div
                            style={{
                              backgroundColor: "rgb(209, 2, 2)",
                              color: "rgb(255, 255, 255)",
                              borderRadius: "5px",
                            }}
                          >
                            Aún no contesta
                          </div>
                        </TableCell>
                      )}

                      {audio.contesta === 0 ? (
                        <TableCell align="center">
                          <div
                            style={{
                              backgroundColor: "rgb(209, 2, 2)",
                              color: "rgb(255, 255, 255)",
                              borderRadius: "5px",
                            }}
                          >
                            No contesta
                          </div>
                        </TableCell>
                      ) : audio.contesta === 1 && audio.respuesta === 0 ? (
                        <TableCell align="center">
                          <div
                            style={{
                              backgroundColor: "rgb(41, 132, 168)",
                              color: "rgb(255, 255, 255)",
                              borderRadius: "5px",
                            }}
                          >
                            Recive la informacion
                          </div>
                        </TableCell>
                      ) : (
                        <TableCell align="center">
                          <div
                            style={{
                              backgroundColor: "rgb(77, 170, 115)",
                              color: "rgb(255, 255, 255)",
                              borderRadius: "5px",
                            }}
                          >
                            Contesta
                          </div>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "3%",
              }}
            >
              <Button onClick={this.state.ocultar} style={{ backgroundColor: "#eaa028", color: "white" }}>
                Cerrar
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}
