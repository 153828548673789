import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";

import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";
import { FormControl } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { Select } from "@material-ui/core";
// estilos
//import "../../assets/css/agregar.css";

export default class BeneficioInscribirSalaIra extends Component {
  constructor(props) {
    super(props);

    this.state = {
      beneficios: [],
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      usuId: props.usuario,
      beneficio: props.beneficio,
      cesfamAsociados: [],
      benes: [],
      bene: [],
      cesfamSeleccionado: "",
      open: true,
      nombreBeneficio: props.nombreBeneficio,
      mostrarFiltroBene: false,
      mostrarSelecProfesional: false,
      mostrarFiltroCesfam: false,
      tituloOriginal: true,
      profesionalSeleccionado: "",
      especialidades: [],
      profesionales: [],
      atencionExterna: null,
      cesfam_bene: props.cesfam_bene,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  traerProfesionales = () => {
    let arrEspecialidades = this.state.especialidades.split(",");
    axios
      .get(global.config.modulos.profesionales + "por_especialidad/", {
        headers: authHeader(),
        params: {
          especialidad: parseInt(arrEspecialidades[0]),
        },
      })
      .then((res) => {
        this.setState({ profesionales: res.data.datos, mostrarSelecProfesional: false }, () => {
          this.setState({ mostrarSelecProfesional: true });
        });
      });
  };

  cesfamAsociados() {
    axios
      .get(global.config.modulos.beneficios + "cesfam_asociados_beneficio/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
          cesfam_bene: this.state.cesfam_bene,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          return NotificationManager.info("No tiene Cesfams asociados");
        }
        if (res.data.datos.length > 1) {
          this.setState({ cesfamAsociados: res.data.datos }, () => {
            this.setState({ mostrarFiltroCesfam: false }, () => {
              this.setState({ mostrarFiltroCesfam: true });
            });
          });
        } else {
          if (this.state.cesfam == this.state.cesfam_bene) {
            this.setState({ cesfamAsociados: res.data.datos }, () => {
              this.setState({ mostrarFiltroCesfam: false }, () => {
                this.setState({ mostrarFiltroCesfam: true });
              });
            });
          } else {
            this.setState({ cesfamSeleccionado: res.data.datos[0].cesfam }, () => {
              this.BeneficiosAsociados();
            });
          }
        }
      });
  }

  BeneficiosAsociados() {
    axios
      .get(global.config.modulos.beneficios + "beneficios_x_nombre/", {
        headers: authHeader(),
        params: {
          ces: this.state.cesfamSeleccionado,
          ben_nombre: this.state.nombreBeneficio,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          return NotificationManager.info("No tiene beneficios asociados");
        }
        if (res.data.datos.length > 1) {
          this.setState({ benes: res.data.datos, mostrarSelecProfesional: false }, () => {
            this.setState({ mostrarFiltroBene: false }, () => {
              this.setState({ mostrarFiltroBene: true });
            });
          });
        } else {
          this.setState(
            {
              bene: res.data.datos[0],
              mostrarFiltroBene: false,
              mostrarSelecProfesional: false,
            },
            () => {
              this.setState(
                {
                  especialidades: this.state.bene["ben_especialidades"],
                },
                () => {
                  this.traerProfesionales();
                }
              );
            }
          );
        }
      });
  }

  inscribir = () => {
    if (this.state.cesfamSeleccionado == null || this.state.cesfamSeleccionado == "") {
      return NotificationManager.warning("Por favor Seleccione el cesfam");
    }
    if (this.state.bene == null || this.state.bene == []) {
      return NotificationManager.warning("Por favor seleccione el beneficio");
    }
    if (this.state.profesionalSeleccionado == null || this.state.profesionalSeleccionado == "") {
      return NotificationManager.warning("Por favor seleccione un profesional");
    }
    console.log(this.state.especialidades);

    axios
      .post(
        global.config.modulos.beneficios + "inscribir/",
        {
          fechaInicio: null,
          fechaExtra: null,
          fechaTermino: null,
          usuario: this.state.usuId,
          beneficio: this.state.beneficio,
          usuarioPanel: this.state.uspId,
          profesional: this.state.profesionalSeleccionado,
          atencionExterna: this.state.atencionExterna,
          especialidad: parseInt(this.state.especialidades),
          cesfam_redireccion: this.state.bene["ben_especialidades"],
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Inscrito con éxito");
        } else {
          NotificationManager.error("Ha ocurrido un error al inscribir");
        }
        this.salir();
      });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name === "cesfamSeleccionado") {
        this.BeneficiosAsociados();
      }
      if (name === "bene") {
        this.setState(
          {
            especialidades: this.state.bene["ben_especialidades"],
          },
          () => {
            this.traerProfesionales();
          }
        );
      }
    });
  };

  componentDidMount() {
    this.cesfamAsociados();
  }

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="beneficioUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
              <img alt="" class="marca-agua"></img>
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Inscribir beneficio {this.state.nombreBeneficio}</h2>
                </GridItem>

                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <label>Seleccione los datos asociados al beneficio</label>
                </GridItem>

                {this.state.mostrarFiltroCesfam ? (
                  <GridItem xs={3} style={{ marginTop: 20 }}>
                    <FormControl
                      style={{
                        width: "65%",
                        marginLeft: "1%",
                      }}
                    >
                      <InputLabel id="Filtro-label">Seleccione el Cesfam</InputLabel>
                      <Select labelId="Filtro-label" id="Filtro-select" name="cesfamSeleccionado" onChange={this.cambiarValores}>
                        <MenuItem>Seleccione el Cesfam</MenuItem>
                        {this.state.cesfamAsociados.map((ces) => (
                          <MenuItem key={ces.cesfam} value={ces.cesfam}>
                            {ces.ces_nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                ) : null}
                {this.state.mostrarFiltroBene ? (
                  <GridItem xs={3} style={{ marginTop: 20 }}>
                    <FormControl
                      style={{
                        width: "65%",
                        marginLeft: "1%",
                      }}
                    >
                      <InputLabel id="Filtro-label-2">Seleccione el beneficio</InputLabel>
                      <Select labelId="Filtro-label-2" id="Filtro-select-2" name="bene" onChange={this.cambiarValores}>
                        {this.state.benes.map((ben) => (
                          <MenuItem key={ben.ben_id} value={ben}>
                            {ben.ben_nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                ) : null}
                {this.state.mostrarSelecProfesional ? (
                  <GridItem xs={3} style={{ marginTop: 20 }}>
                    <FormControl
                      style={{
                        width: "70%",
                        marginLeft: "1%",
                      }}
                    >
                      <InputLabel id="Filtro-label-3">Seleccione el Profesional</InputLabel>
                      <Select labelId="Filtro-label-3" id="Filtro-select-3" name="profesionalSeleccionado" onChange={this.cambiarValores}>
                        <MenuItem>Seleccione el Profesional</MenuItem>
                        {this.state.profesionales.map((pro) => (
                          <MenuItem key={pro.pro_id} value={pro.pro_id}>
                            {pro.nombreProfesional}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                ) : null}
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={this.salir} style={{ marginLeft: 20 }}>
                    Cerrar
                  </Button>
                  <Button id="modalEliminarBtnAceptar" className="botonDialog fondoVerde" onClick={this.inscribir}>
                    Inscribir
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
