import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Grid from "@material-ui/core/Grid";
import { confirmAlert } from "react-confirm-alert"; // Import

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import chileanRut from "chilean-rut";
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";

import MultiSelect from "react-multi-select-component";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { NotificationManager } from "react-notifications";

export default class ModificarProfesional extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nombre: "",
      apellidoP: "",
      apellidoM: "",
      rut: "",
      existe: 0,
      correo: "",
      correoAnt: "",
      ausencia: "0",
      sexo: "M",
      especialidadesSeleccionados: [],
      especialidades: [],
      familiaEspecialidad: "",
      atenciones: [],
      sectoresSeleccionados: [],
      sectores: [],
      formErrors: {},
      sectores: [],

      profesiones: [],
      profesion: 1,

      tiempoRestante: false,
      tiempoTranscurrido: false,
      decirNombre: false,

      proId: this.props.profesional,
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,

      herramientas: store.getState().herramientas,

      open: true,
    };

    this.revisaExisteRutCesfam = this.revisaExisteRutCesfam.bind(this);
    this.salir = this.salir.bind(this);
  }

  componentDidMount() {
    this.traerTodosLosSectores();
    this.traerTodasLasEspecialidades();
    this.traerProfesiones();
    this.traerDatosProfesional(this.props.profesional);
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  mostrarAlertaAgregado() {
    NotificationManager.success("Profesional agregado con éxito");
  }

  traerProfesiones() {
    const apiUrl = global.config.modulos.profesionales + "profesiones/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        var data = res.data;
        this.setState({ profesiones: data.profesiones });
      });
  }

  traerDatosProfesional(proId) {
    let apiUrl = global.config.modulos.profesionales + "por_profesional_cesfam/" + proId + "/" + this.state.cesfam;
    axios(apiUrl, {
      headers: authHeader(),
    }).then((res) => {
      if (!res.data["pro_apellido_p"]) {
        res.data["pro_apellido_p"] = "";
      }

      if (!res.data["pro_apellido_m"]) {
        res.data["pro_apellido_m"] = "";
      }

      if (!res.data["pro_correo"]) {
        res.data["pro_correo"] = "";
      }

      let arrAtenciones = [];
      if (res.data["especialidades"]["atenciones"]) {
        res.data["especialidades"]["atenciones"].map((res) => {
          arrAtenciones.push(res.especialidad.toString() + "_" + res.nombre_especialidad);
        });
      }

      let arrSectores = [];
      if (res.data["sectores"]) {
        res.data["sectores"].map((res) => {
          arrSectores.push(res.prs_sector_id.toString());
        });
      }

      if (res.data["pro_tiempo_restante"] == 1) {
        res.data["pro_tiempo_restante"] = true;
      }

      if (res.data["pro_tiempo_transcurrido"] == 1) {
        res.data["pro_tiempo_transcurrido"] = true;
      }

      if (res.data["pro_decir_nombre"] == 1) {
        res.data["pro_decir_nombre"] = true;
      }

      this.setState({
        nombre: res.data["pro_nombre"],
        apellidoP: res.data["pro_apellido_p"],
        apellidoM: res.data["pro_apellido_m"],
        rut: res.data["pro_rut"],
        correo: res.data["pro_correo"],
        correoAnt: res.data["pro_correo"],
        ausencia: res.data["pro_ausente"],
        sexo: res.data["pro_sexo"],
        profesion: res.data["pro_perfil"],
        familiaEspecialidad: res.data["especialidades"]["especialidad"],
        tiempoRestante: res.data["pro_tiempo_restante"],
        tiempoTranscurrido: res.data["pro_tiempo_transcurrido"],
        decirNombre: res.data["pro_decir_nombre"],
        especialidadesSeleccionados: arrAtenciones,
        sectoresSeleccionados: arrSectores,
      });

      this.traerEspecialidades(proId);
      this.traerSectores(proId);
      this.traerTodasLasAtenciones(res.data["especialidades"]["especialidad"]);
    });
  }

  traerEspecialidades(proId) {
    const apiUrl = global.config.modulos.profesionales + "especialidades_por_profesional_cesfam/" + proId + "/" + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.estado) {
        var espResultSelect = [];
        res.data.datos.map((esp) => {
          espResultSelect.push(esp.esp_id + "_" + esp.esp_nombre);
          this.setState({ especialidadesSeleccionados: espResultSelect });
        });
      }
    });
  }

  traerSectores(proId) {
    const apiUrl = global.config.modulos.profesionales + "sectores_por_profesional_cesfam/" + proId + "/" + this.state.cesfam;
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.data.estado) {
          var secResultSelect = [];
          var sectoresProfesional = res.data.datos;
          var sectoresCesfam = this.state.sectores;
          for (var i = 0; i < sectoresCesfam.length; i++) {
            for (var x = 0; x < sectoresProfesional.length; x++) {
              if (sectoresCesfam[i]["value"] == sectoresProfesional[x]["sec_id"]) {
                let sec = {
                  label: sectoresCesfam[i]["label"],
                  value: sectoresCesfam[i]["value"],
                };
                secResultSelect.push(sec);
              }
            }
          }
        }
      });
  }

  traerTodosLosSectores() {
    const apiUrl = global.config.modulos.sectores + "por_cesfam/" + this.state.cesfam;
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        this.setState({
          sectores: res.data.datos,
        });
      });
  }

  traerTodasLasEspecialidades() {
    const apiUrl = global.config.modulos.especialidades + "?cesfam=" + this.state.cesfam + "&tipo=1";
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        this.setState({
          especialidades: res.data.datos,
        });
      });
  }

  traerTodasLasAtenciones(familia) {
    if (familia) {
      const apiUrl = global.config.modulos.especialidades + "?cesfam=" + this.state.cesfam + "&tipo=2&familia=" + familia + "&mostrar_menu=1";
      axios
        .get(apiUrl, {
          headers: authHeader(),
        })
        .then((res) => {
          this.setState({
            atenciones: res.data.datos,
          });
        });
    } else {
      this.setState({
        atenciones: [],
      });
    }
  }

  cambiarValores = (e) => {
    let { name, value } = e.target;

    const quitarNumeros = ["nombre", "apellidoP", "apellidoM"];
    if (quitarNumeros.includes(name)) {
      value = value.replace(/[0-9]/g, "");
    }

    this.setState({ [name]: value });
  };

  revisaExisteRutCesfam() {
    var rut = document.getElementById("rut").value;

    this.setState({ rut: rut });

    var data = {
      rut: rut,
      cesfam: this.state.cesfam,
    };

    let apiUrl = global.config.modulos.profesionales + "por_rut_cesfam/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      if (!res.data.estado) {
        this.setState({
          existe: 0,
        });
      } else {
        this.setState({
          existe: 1,
        });
      }
    });
  }

  validaciones() {
    const { nombre, apellidoP, rut, existe, correo, especialidadesSeleccionados, sectoresSeleccionados } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (!nombre) {
      formIsValid = false;
      formErrors["nombreErr"] = "Escribe el nombre.";
    }

    if (!apellidoP) {
      formIsValid = false;
      formErrors["apellidoErr"] = "Escribe el apellido.";
    }

    if (!rut) {
      formIsValid = false;
      formErrors["rutErr"] = "Escribe el rut.";
    } else if (!chileanRut.validate(rut)) {
      formIsValid = false;
      formErrors["rutErr"] = "Escribe el rut valido.";
    }

    if (existe == 1) {
      formIsValid = false;
      formErrors["rutErr"] = "El profesional ya éxiste en el cesfam.";
    }

    if (correo) {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(correo)) {
        formIsValid = false;
        formErrors["correoErr"] = "Verifica el formato del correo.";
      }
    }

    if (especialidadesSeleccionados.length === 0) {
      formIsValid = false;
      formErrors["especialidadesErr"] = "Selecciona la especialidad del profesional.";
    }
    if (especialidadesSeleccionados.length === 0) {
      formIsValid = false;
      formErrors["atencionesErr"] = "Selecciona la o las atenciones del profesional.";
    }

    if (sectoresSeleccionados.length === 0) {
      formIsValid = false;
      formErrors["sectoresErr"] = "Selecciona el o los sectores del profesional.";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  enviarFormulario = (e) => {
    if (this.validaciones()) {
      var arrayEspecialidades = [];
      this.state.especialidadesSeleccionados.map((especialidad) => {
        arrayEspecialidades.push(parseInt(especialidad.split("_")[0]));
      });

      var especialidades = arrayEspecialidades;
      var sectores = this.state.sectoresSeleccionados;

      var tiempoRestante = 0;
      if (this.state.tiempoRestante) {
        tiempoRestante = 1;
      }

      var tiempoTranscurrido = 0;
      if (this.state.tiempoTranscurrido) {
        tiempoTranscurrido = 1;
      }

      if (!this.state.especialidadesSeleccionados.some((item) => item.includes("Teleconsulta"))) {
        tiempoTranscurrido = 0;
        tiempoRestante = 0;
      }

      var decirNombre = 0;
      if (this.state.decirNombre) {
        decirNombre = 1;
      }

      var data = {
        pro_nombre: this.state.nombre,
        pro_apellido_p: this.state.apellidoP,
        pro_apellido_m: this.state.apellidoM,
        pro_correo: this.state.correo,
        pro_ausente: this.state.ausencia,
        pro_sexo: this.state.sexo,
        pro_rut: this.state.rut,
        pro_tiempo_transcurrido: tiempoTranscurrido,
        pro_tiempo_restante: tiempoRestante,
        pro_decir_nombre: decirNombre,
        pro_perfil: this.state.profesion,
        especialidades: especialidades,
        sectores: sectores,
        cesfam: this.state.cesfam,
        uspId: this.state.uspId,
      };

      let apiUrl = global.config.modulos.profesionales + "por_profesional_cesfam/" + this.state.proId + "/" + this.state.cesfam;

      const options = {
        method: "PATCH",
        data: data,
        headers: authHeader(),
      };

      axios(apiUrl, options).then((res) => {
        if (this.state.correo == "" || this.state.correo == this.state.correoAnt) {
          NotificationManager.success("Modificado con éxito");
        } else {
          axios
            .post(
              global.config.modulos.aplicaciones + "enviar_correo_verificacion_link/",
              {
                rut: this.state.rut,
                correo: this.state.correo,
                tipo_usuario: 2,
              },
              {
                headers: authHeader(),
              }
            )
            .then((res) => {
              NotificationManager.success("Modificado con éxito, recuerde que le llegará un correo con un link de confirmación");
            })
            .catch((error) => {
              NotificationManager.success("Modificado con éxito");
            });
        }
        this.salir();
      });
    }
  };

  cambioEspecialidad = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
    this.traerTodasLasAtenciones(value);
  };

  cambioAtenciones = (e) => {
    let { name, value } = e.target;
    let atenciones_registradas = this.state.especialidadesSeleccionados;

    const index = atenciones_registradas.indexOf(value);
    if (index > -1) {
      atenciones_registradas.splice(index, 1);
    } else {
      atenciones_registradas.push(value);
    }

    this.setState({ especialidadesSeleccionados: atenciones_registradas });
  };

  cambioSector = (e) => {
    let { name, value } = e.target;
    let sectores_registrados = this.state.sectoresSeleccionados;

    const index = sectores_registrados.indexOf(value);
    if (index > -1) {
      sectores_registrados.splice(index, 1);
    } else {
      sectores_registrados.push(value);
    }

    this.setState({ sectoresSeleccionados: sectores_registrados });
  };

  cambiarValoresCheckBox = (nombre, valor) => {
    this.setState({
      [nombre]: !valor,
    });
  };

  render() {
    const { nombreErr, apellidoErr, rutErr, especialidadesErr, atencionesErr, sectoresErr } = this.state.formErrors;

    const { nombre, apellidoP, apellidoM, rut, correo, ausencia, sexo, especialidadesSeleccionados, especialidades, sectoresSeleccionados, stringMultiEspecialidades, sectores, profesion, profesiones, atenciones } = this.state;

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modalAgregarProfesional">
        <DialogContent>
          <div spacing={3} className="modificarProfesional">
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Modificar profesional</h2>
                </GridItem>
              </GridContainer>
            </GridItem>
            <Grid container spacing={3} style={{ marginTop: 30 }}>
              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>Nombre*</InputLabel>
                <input type="text" name="nombre" value={nombre} onChange={this.cambiarValores} placeholder="" className={nombreErr ? "inputText showError" : "inputText"} />
                {nombreErr && <div style={{ color: "red", paddingBottom: 10 }}>{nombreErr}</div>}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>Apellido Paterno*</InputLabel>
                <input type="text" name="apellidoP" value={apellidoP} onChange={this.cambiarValores} placeholder="" className={apellidoErr ? "inputText showError" : "inputText"} />
                {apellidoErr && <div style={{ color: "red", paddingBottom: 10 }}>{apellidoErr}</div>}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>Apellido Materno</InputLabel>
                <input type="text" name="apellidoM" value={apellidoM} onChange={this.cambiarValores} placeholder="" className={"inputText"} />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>Rut*</InputLabel>
                <input type="text" id="rut" name="rut" value={rut} onChange={this.revisaExisteRutCesfam} placeholder="Ejemplo 12345678-9" className={rutErr ? "inputText showError" : "inputText"} maxLength={12} />
                {rutErr && <div style={{ color: "red", paddingBottom: 10 }}>{rutErr}</div>}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>Correo</InputLabel>
                <input type="text" name="correo" value={correo} onChange={this.cambiarValores} placeholder="" className={"inputText"} />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>Sexo</InputLabel>
                <Select id="sexo" name="sexo" value={sexo} onChange={this.cambiarValores} displayEmpty style={{ width: "100%" }}>
                  <MenuItem value="M" key={"1"}>
                    Masculino
                  </MenuItem>
                  <MenuItem value="F" key={"2"}>
                    Femenino
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>Profesión</InputLabel>
                <Select id="profesion" name="profesion" value={profesion} onChange={this.cambiarValores} displayEmpty style={{ width: "100%" }}>
                  {profesiones.map((res, index) => (
                    <MenuItem value={res.ppn_id} key={index}>
                      {res.ppn_nombre}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>Especialidad</InputLabel>
                <select name="familiaEspecialidad" id="familiaEspecialidad" onChange={this.cambioEspecialidad} value={this.state.familiaEspecialidad} style={{ padding: 5, borderRadius: 5, marginTop: 5 }}>
                  <option value={""}>Seleccione especialidad</option>
                  {especialidades.map((res, index) => (
                    <option key={index} value={res.esp_sigla}>
                      {res.esp_nombre}
                    </option>
                  ))}
                </select>

                {especialidadesErr && <div style={{ color: "red", paddingBottom: 10 }}>{especialidadesErr}</div>}
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>Tipo de atención</InputLabel>
                <FormGroup>
                  {atenciones.map((res, index) => {
                    var checked = false;
                    if (especialidadesSeleccionados.includes(res.esp_id + "_" + res.esp_nombre)) {
                      checked = true;
                    }
                    return <FormControlLabel key={index} control={<Checkbox checked={checked} value={res.esp_id + "_" + res.esp_nombre} onChange={this.cambioAtenciones} />} label={res.esp_nombre} />;
                  })}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>Atenciones seleccionadas*</InputLabel>
                {especialidadesSeleccionados.map((res, index) => (
                  <p key={index}>{res.split("_")[1]}</p>
                ))}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>Sectores*</InputLabel>
                <select name="sectoresSeleccionados" id="sectoresSeleccionados" onChange={this.cambioSector} value={sectoresSeleccionados} multiple style={{ minWidth: "70%", borderRadius: 5, marginTop: 5 }}>
                  {sectores.map((res, index) => (
                    <option key={index} value={res.sec_id}>
                      {res.sec_nombre}
                    </option>
                  ))}
                </select>

                {sectoresErr && <div style={{ color: "red", paddingBottom: 10 }}>{sectoresErr}</div>}
              </Grid>

              {this.state.herramientas.includes("H71") && (
                <Grid item xs={12} sm={6} md={6}>
                  <InputLabel>Ausencia Profesional</InputLabel>
                  <Select id="ausencia" name="ausencia" value={ausencia} onChange={this.cambiarValores} displayEmpty className={"inputText"}>
                    <MenuItem value="0">
                      <em>Sin Ausencia</em>
                    </MenuItem>
                    <MenuItem value="1">
                      <em>Vacaciones</em>
                    </MenuItem>
                    <MenuItem value="2">
                      <em>Licencia</em>
                    </MenuItem>
                  </Select>
                </Grid>
              )}

              {especialidadesSeleccionados.some((item) => item.includes("Teleconsulta")) ? (
                <Grid item xs={12} sm={6} md={6}>
                  <InputLabel>Teleconsulta:</InputLabel>
                  <br></br>
                  <Grid container spacing={1}>
                    <FormGroup>
                      <FormControlLabel key={""} control={<Checkbox checked={this.state.tiempoRestante} onChange={() => this.cambiarValoresCheckBox("tiempoRestante", this.state.tiempoRestante)} name="tiempoRestante" value="1" />} label="Mostrar tiempo restante" />
                      <FormControlLabel key={""} control={<Checkbox checked={this.state.tiempoTranscurrido} onChange={() => this.cambiarValoresCheckBox("tiempoTranscurrido", this.state.tiempoTranscurrido)} name="tiempoTranscurrido" value="1" />} label="Mostrar tiempo transcurrido" />
                    </FormGroup>
                  </Grid>
                </Grid>
              ) : null}

              <Grid item xs={12} sm={6} md={6}>
                <InputLabel>Configuración canales</InputLabel>
                <br></br>
                <Grid container spacing={1}>
                  <FormGroup>
                    <FormControlLabel key={""} control={<Checkbox checked={this.state.decirNombre} onChange={() => this.cambiarValoresCheckBox("decirNombre", this.state.decirNombre)} name="decirNombre" value="1" />} label="Indicar nombre en reserva" />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>

            <div style={{ width: "100%", float: "left", marginTop: "25px" }}>
              <Button style={{ marginTop: 10 }} onClick={this.salir} color="primary" className="fondoRojo">
                Cancelar
              </Button>
              <Button style={{ marginTop: 10 }} onClick={this.enviarFormulario} color="primary" className="fondoVerde">
                Modificar
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
