import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import moment from "moment";
import chileanRut from "chilean-rut";

import { connect } from "react-redux";
import { store } from "./../../store/index";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";

import Titulo from "../../components/titulo/Titulo.js";
import TimePicker from "rc-time-picker";

import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";

import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert";
import { FaTrash } from "react-icons/fa";

// estilos
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#fff",
  },
}))(Tooltip);

class Horarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabla: "",
      tiempoInicio: "",
      tiempoTermino: "",

      disabled: true,
      rendimientos: [],
      rendimiento: 15,
      especialidades: [],
      especialidad: "0",
      fecha: new Date(),

      cesfam: store.getState().cesfam,
    };
  }

  componentDidMount() {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Horario de Profesionales<span title='asdasd'><span>";

    this.especialidades();
    this.traerHorarioCesfam();
    this.traerRendimientos();
  }

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
        },
      ],
    });
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  especialidades = () => {
    axios
      .get(global.config.modulos.reportes + "listado_especialidades/", {
        headers: authHeader(),
        params: { cesfam: this.state.cesfam },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            especialidades: res.data.datos,
          });
        }
      });
  };

  traerRendimientos() {
    this.setState({
      rendimientos: [
        {
          esr_rendimiento: 15,
        },
        {
          esr_rendimiento: 20,
        },
        {
          esr_rendimiento: 30,
        },
        {
          esr_rendimiento: 40,
        },
        {
          esr_rendimiento: 45,
        },
        {
          esr_rendimiento: 60,
        },
      ],
    });
  }

  traerHorarioCesfam() {
    const apiUrl = global.config.modulos.cesfams + "cesfam_horario/?cesfam=" + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var horarioFuncionamiento = res.data.horarioFuncionamiento;
      var fechaActual = moment().format("YYYY-MM-DD");
      this.setState({
        tiempoInicio: moment(fechaActual + " " + horarioFuncionamiento.hora_inicio),
        tiempoTermino: moment(fechaActual + " " + horarioFuncionamiento.hora_termino),
      });
    });
  }

  horarios = (fechaHorario, especialidad, tiempoInicio, tiempoTermino) => {
    fechaHorario = moment(fechaHorario).format("YYYY-MM-DD");

    var horarioInicio = tiempoInicio.format("HH:mm");
    var horarioTermino = tiempoTermino.format("HH:mm");

    var rendimiento = this.state.rendimiento;

    var data = {
      fecha: fechaHorario,
      cesfam: this.state.cesfam,
      especialidad: especialidad,
    };
    let apiUrl = global.config.modulos.agendamientos + "horario/";
    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      var fechaInicio = fechaHorario + " " + horarioInicio + ":00";
      var fechaTermino = fechaHorario + " " + horarioTermino + ":00";

      var fecha = fechaInicio;

      var minutosAsumar = parseFloat(rendimiento);

      var arregloRendimiento = [];
      var profesionales = [];
      arregloRendimiento.push(fecha);
      do {
        fecha = moment(fecha).add(minutosAsumar, "minutes").format("YYYY-MM-DD HH:mm:ss");
        arregloRendimiento.push(fecha);
      } while (fecha < fechaTermino);

      var estado = res.data.estado;
      if (!estado) {
        var tipo = res.data.tipo;

        var tabla = [];

        var rowsHead = [];
        rowsHead.push(<TableCell className={"tablaRow cellInicio"} key={0}></TableCell>);

        rowsHead.push(
          <TableCell className={"tablaRow cellTermino"}>
            <p style={{ textAlign: "center" }}>Horario </p>
          </TableCell>
        );

        tabla.push(
          <TableHead>
            <TableRow>{rowsHead}</TableRow>
          </TableHead>
        );

        var rows = [];
        for (var j = 0; j < arregloRendimiento.length; j++) {
          var cell = [];

          var fecha = arregloRendimiento[j].split(" ");
          var horasF = fecha[1];
          var horaR = horasF.substring(5, 0);

          cell.push(<TableCell className={"tablaRow"}>{horaR}</TableCell>);

          var motivo = "Sin agenda";
          if (tipo == 1) {
            motivo = "Día feriado";
          }

          cell.push(
            <TableCell className={"tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
              <div>
                <span>
                  <span className={"contenedorDatos"}>
                    <button
                      title="HORARIO BLOQUEADO"
                      className={"botonesCargaMasiva porSeleccionar"}
                      style={{
                        backgroundColor: "#6a6c6a",
                      }}
                    >
                      <label className={"textoBotonesCarga"} style={{ color: "#444444" }}>
                        {motivo}
                      </label>
                    </button>
                  </span>
                </span>
              </div>
            </TableCell>
          );
          rows.push(<TableRow className={"tablaRow"}>{cell}</TableRow>);
        }
      } else {
        var horarioMedico = res.data.horarios;

        var tabla = [];

        var rowsHead = [];
        rowsHead.push(<TableCell className={"tablaRow cellInicio"} key={0}></TableCell>);

        for (var i = 0; i < horarioMedico.length; i++) {
          profesionales.push(horarioMedico[i]["pro_id"]);

          rowsHead.push(
            <TableCell className={i == horarioMedico.length - 1 ? "tablaRow cellTermino" : "tablaRow"}>
              <p style={{ textAlign: "center" }}>{horarioMedico[i]["profesional"]} </p>
            </TableCell>
          );
        }

        tabla.push(
          <TableHead>
            <TableRow>{rowsHead}</TableRow>
          </TableHead>
        );

        var rows = [];
        for (var j = 0; j < arregloRendimiento.length; j++) {
          var cell = [];

          var fecha = arregloRendimiento[j].split(" ");
          var horasF = fecha[1];
          var horaR = horasF.substring(5, 0);

          cell.push(
            <TableCell className={"tablaRow horasHorario"} id={horaR}>
              {horaR}
            </TableCell>
          );

          for (var f = 0; f < horarioMedico.length; f++) {
            var horasMed = horarioMedico[f]["horas"];
            var reuniones = horarioMedico[f]["reuniones"];

            var proId = horarioMedico[f]["pro_id"];
            var Hora = horaR;
            var fechaHora = fechaHorario + " " + Hora;

            var horarioRendimiento = 0;
            var bloqueoPorReunion = 0;
            var horarioUsado = 0;
            var espe = "";
            var espeColor = "";

            var horAtencion = "";
            var horMotivo = "";
            var horRendimiento = "";
            var horRendimiento2 = "";
            var tipoReunion = "";
            var horEstado = "";
            var horReservada = "";
            var colorEstado = "";
            var ageEstado = "";
            var ageEstadoNombre = "";
            var nombreUsuario = "";
            var hora_ate = "";
            var direccion = "";

            for (var m = 0; m < reuniones.length; m++) {
              var fecha_reunion = reuniones[m]["fecha_reunion"];
              var inicio_reunion = reuniones[m]["hora_inicio"];
              var termino_reunion = reuniones[m]["hora_termino"];

              if (Hora >= inicio_reunion && Hora < termino_reunion && fecha_reunion == fechaHorario) {
                bloqueoPorReunion = 1;
                tipoReunion = reuniones[m]["reunion"];
              }
            }

            for (var ex = 0; ex < horasMed.length; ex++) {
              var hora_atencion = horasMed[ex]["hora_atencion"];
              horAtencion = moment(horasMed[ex]["hor_atencion"]);
              horRendimiento = horasMed[ex]["hor_rendimiento"];

              var horaAtencion = horAtencion.format("HH:mm:ss");
              horAtencion = horAtencion.format("YYYY-MM-DD HH:mm:ss");

              var minutosAsumar = parseFloat(horRendimiento) - 1;
              var fechaFinal = moment(horAtencion).add(minutosAsumar, "minutes").format("YYYY-MM-DD HH:mm:ss");

              if (Hora == hora_atencion) {
                horarioUsado = 1;

                espe = horasMed[ex]["esp_nombre"];
                espeColor = horasMed[ex]["esp_color"];

                horMotivo = horasMed[ex]["hor_motivo"];
                horEstado = horasMed[ex]["hor_estado"];
                horReservada = horasMed[ex]["hor_reservada"];
                hora_ate = moment(horasMed[ex]["hor_atencion"]);
                hora_ate = hora_ate.format("HH:mm");
                horRendimiento2 = horasMed[ex]["hor_rendimiento"];
                direccion = horasMed[ex]["direccion"];

                nombreUsuario = "";

                if (horEstado == 3) {
                  colorEstado = "#A42828";
                  ageEstadoNombre = "Pausada";
                } else if (horEstado == 0) {
                  colorEstado = "#898483";
                  ageEstadoNombre = "Bloqueada por";
                  nombreUsuario = horMotivo;
                  espeColor = "#898483";
                } else {
                  if (horReservada == 0) {
                    //Disponible
                    colorEstado = "#0D7D34";
                    ageEstadoNombre = "Disponible";
                  } else if (horReservada == 2) {
                    ageEstado = horasMed[ex]["age_estado"];
                    ageEstadoNombre = horasMed[ex]["age_estado_nombre"];
                    nombreUsuario = horasMed[ex]["nombreUsuarioReserva"] + ", " + chileanRut.format(horasMed[ex]["rutUsuarioReserva"], true);

                    if (ageEstado == 4) {
                      colorEstado = "#D4D400";
                    } else if (ageEstado == 5) {
                      colorEstado = "#F6A30F";
                    } else if (ageEstado == 7) {
                      colorEstado = "#2A4899";
                    } else if (ageEstado == 9) {
                      colorEstado = "#E51D20";
                    }
                    //Disponible
                  } else if (horReservada == 1) {
                    //En proceso de reserva
                    ageEstadoNombre = "En proceso";
                    colorEstado = "#F7BCC8";
                  }
                }
              } else if (horaAtencion < Hora && horAtencion < fechaFinal && fechaFinal > fechaHora) {
                horarioRendimiento = 1;

                espe = horasMed[ex]["esp_nombre"];
                espeColor = horasMed[ex]["esp_color"];

                horMotivo = horasMed[ex]["hor_motivo"];
                horEstado = horasMed[ex]["hor_estado"];
                horReservada = horasMed[ex]["hor_reservada"];
                hora_ate = moment(horasMed[ex]["hor_atencion"]);
                hora_ate = hora_ate.format("HH:mm");
                horRendimiento2 = horasMed[ex]["hor_rendimiento"];
                direccion = horasMed[ex]["direccion"];

                nombreUsuario = "";

                if (horEstado == 3) {
                  colorEstado = "#A42828";
                  ageEstadoNombre = "Pausada";
                } else if (horEstado == 0) {
                  colorEstado = "#898483";
                  ageEstadoNombre = "Bloqueada por";
                  nombreUsuario = horMotivo;
                  espeColor = "#898483";
                } else {
                  if (horReservada == 0) {
                    //Disponible
                    colorEstado = "#0D7D34";
                    ageEstadoNombre = "Disponible";
                  } else if (horReservada == 2) {
                    ageEstado = horasMed[ex]["age_estado"];
                    ageEstadoNombre = horasMed[ex]["age_estado_nombre"];
                    nombreUsuario = horasMed[ex]["nombreUsuarioReserva"] + ", " + chileanRut.format(horasMed[ex]["rutUsuarioReserva"], true);

                    if (ageEstado == 4) {
                      colorEstado = "#D4D400";
                    } else if (ageEstado == 5) {
                      colorEstado = "#F6A30F";
                    } else if (ageEstado == 7) {
                      colorEstado = "#2A4899";
                    } else if (ageEstado == 9) {
                      colorEstado = "#E51D20";
                    }
                    //Disponible
                  } else if (horReservada == 1) {
                    //En proceso de reserva
                    ageEstadoNombre = "En proceso";
                    colorEstado = "#F7BCC8";
                  }
                }
              }
            }

            if (bloqueoPorReunion == 1) {
              cell.push(
                <TableCell className={"tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
                  <div>
                    <HtmlTooltip
                      title={
                        <div id="tablahorarioProfesional" className="cargarHorario">
                          <Table className="bordeTabla">
                            <TableContainer>
                              <TableHead>
                                <TableRow>
                                  <TableCell className={"MuiTableCell-head tablaRow cellInicio"} key={0}>
                                    Bloqueado por
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>{tipoReunion}</TableCell>
                                </TableRow>
                              </TableBody>
                            </TableContainer>
                          </Table>
                        </div>
                      }
                    >
                      <button
                        title={"REUNIÓN " + tipoReunion}
                        className={"botonesCargaMasiva porSeleccionar"}
                        style={{
                          backgroundColor: "#6a6c6a",
                        }}
                      >
                        <label className={"textoBotonesCarga"} style={{ color: "#adadad" }}>
                          Bloqueado por<br></br>
                          {tipoReunion}
                        </label>
                      </button>
                    </HtmlTooltip>
                  </div>
                </TableCell>
              );
            } else if (horarioUsado == 1) {
              cell.push(
                <TableCell className={"tablaRow horarioCreado"} style={{ textAlign: "center" }}>
                  <div>
                    <HtmlTooltip
                      title={
                        <div id="tablahorarioProfesional" className="cargarHorario">
                          <Table className="bordeTabla">
                            <TableContainer>
                              <TableHead>
                                <TableRow>
                                  <TableCell className={"MuiTableCell-head tablaRow cellInicio"} key={0}>
                                    Hora
                                  </TableCell>
                                  <TableCell className={"MuiTableCell-head tablaRow cellTermino"} key={1}>
                                    Estado
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>{hora_ate}</TableCell>
                                  <TableCell
                                    style={{
                                      backgroundColor: colorEstado,
                                      color: "#fff",
                                    }}
                                  >
                                    {ageEstadoNombre}
                                    <br></br>
                                    {nombreUsuario}
                                    <br></br>
                                    {direccion}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </TableContainer>
                          </Table>
                        </div>
                      }
                    >
                      <button
                        title="HORARIO CARGADO"
                        className={"botonesCargaMasiva porSeleccionar"}
                        style={{
                          backgroundColor: espeColor,
                        }}
                        id={Hora + "cont" + proId}
                        onClick={this.activar_horas.bind(this, Hora, hora_ate, arregloRendimiento, proId, profesionales)}
                      >
                        <label
                          className="estadoHora"
                          style={{
                            backgroundColor: colorEstado,
                          }}
                        ></label>
                        <label
                          style={{
                            color: "#fff",
                          }}
                        >
                          {espe}
                          <br></br>
                          {hora_ate}
                          <br></br>
                          {horRendimiento2}
                        </label>
                      </button>
                    </HtmlTooltip>
                  </div>
                </TableCell>
              );
            } else if (horarioRendimiento == 1) {
              cell.push(
                <TableCell
                  className={"tablaRow horarioBloqueado"}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <span className={"contenedorDatos"}>
                    <button
                      className={"botonesCargaMasiva porSeleccionar"}
                      style={{
                        backgroundColor: espeColor,
                      }}
                      id={Hora + "cont" + proId}
                      onClick={this.activar_horas.bind(this, Hora, hora_ate, arregloRendimiento, proId, profesionales)}
                    >
                      <label
                        style={{
                          color: "#fff",
                        }}
                      >
                        {espe}
                        <br></br>
                        {hora_ate}
                        <br></br>({horRendimiento2})<br></br>
                        Rendimiento hora anterior
                      </label>
                    </button>
                  </span>
                </TableCell>
              );
            } else {
              cell.push(
                <TableCell className={"tablaRow horarioASeleccionar"} style={{ textAlign: "center" }}>
                  <div>
                    <span>
                      <span className={"contenedorDatos"}></span>
                    </span>
                  </div>
                </TableCell>
              );
            }
          }
          rows.push(<TableRow className={"tablaRow"}>{cell}</TableRow>);
        }
      }

      tabla.push(<TableBody>{rows}</TableBody>);

      this.setState({
        tabla: (
          <Table stickyHeader className="bordeTabla" size="small" aria-label="a dense table">
            {tabla}
          </Table>
        ),
      });
    });
  };

  activar_horas = (horaFinal, hora_ate, arregloRendimiento, proId, profesionales) => {
    console.log(proId);
    console.log(profesionales);
    for (var j = 0; j < arregloRendimiento.length; j++) {
      var fechaP = arregloRendimiento[j].split(" ");
      var horasFP = fechaP[1];
      var horaRP = horasFP.substring(5, 0);

      var activarColor = 0;
      var activarColorPro = 0;

      for (var x = 0; x < arregloRendimiento.length; x++) {
        if (horaRP == hora_ate) {
          activarColor = 1;
        } else if (hora_ate < horaRP && hora_ate < horaFinal && horaFinal >= horaRP) {
          activarColor = 1;
        }
      }

      if (activarColor == 1) {
        document.getElementById(horaRP).style.backgroundColor = "#4AFFFF";
        for (var p = 0; p < profesionales.length; p++) {
          if (proId == profesionales[p]) {
            document.getElementById(horaRP + "cont" + proId).style.boxShadow = "0 0 17px 3px #4AFFFF";
          } else {
            var prof = document.getElementById(horaRP + "cont" + profesionales[p]);
            if (prof != undefined) {
              prof.style.boxShadow = "none";
            }
          }
        }
      } else {
        document.getElementById(horaRP).style.backgroundColor = "";
        for (var p = 0; p < profesionales.length; p++) {
          var prof = document.getElementById(horaRP + "cont" + profesionales[p]);
          if (prof != undefined) {
            prof.style.boxShadow = "none";
          }
        }
      }
    }
  };

  cambiarValoresFecha = (e) => {
    if (this.state.especialidad != "0") {
      this.horarios(e, this.state.especialidad, this.state.tiempoInicio, this.state.tiempoTermino);
    }

    this.setState({ tabla: "", fecha: e });
  };

  cambiarValoresEspecialidad = (e) => {
    this.setState({ tabla: "", especialidad: e.target.value, disabled: false });
    this.horarios(this.state.fecha, e.target.value, this.state.tiempoInicio, this.state.tiempoTermino);
  };

  cambiarValoresHora = (valor, hora) => {
    if (this.state.especialidad == "0") {
      NotificationManager.warning("Selecciona la especialidad");
      return;
    }

    if (valor != null) {
      if (hora == "inicio") {
        if (valor > this.state.tiempoTermino) {
          NotificationManager.warning("La hora de inicio no puede ser mayor a la de término");
        } else {
          this.setState({
            tiempoInicio: valor,
            tabla: "",
          });

          this.horarios(this.state.fecha, this.state.especialidad, valor, this.state.tiempoTermino);
        }
      }
      if (hora == "termino") {
        if (valor < this.state.tiempoInicio) {
          NotificationManager.warning("La hora de término no puede ser menor a la de inicio");
        } else {
          this.setState({
            tiempoTermino: valor,
            tabla: "",
          });

          this.horarios(this.state.fecha, this.state.especialidad, this.state.tiempoInicio, valor);
        }
      }
    }
  };

  cambioRendimiento = (event) => {
    this.setState(
      {
        rendimiento: event.target.value,
        tabla: "",
      },
      () => {
        this.horarios(this.state.fecha, this.state.especialidad, this.state.tiempoInicio, this.state.tiempoTermino);
      }
    );
  };

  render() {
    const { tiempoInicio, tiempoTermino } = this.state;

    return (
      <div className="middle-area" style={{ width: "85%" }}>
        <div className="middle-content">
          <React.Fragment>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={5} md={4} lg={3} xl={2}>
                  <div style={{ width: "48%", float: "left", marginTop: "16px" }}>
                    <p>
                      <label
                        style={{
                          color: "rgba(0, 0, 0, 0.54)",
                          marginLeft: 12,
                        }}
                      >
                        Hora inicio
                      </label>
                    </p>
                    <TimePicker minuteStep={5} showSecond={false} name="tiempoInicio" id="tiempoInicio" value={tiempoInicio} onChange={(e) => this.cambiarValoresHora(e, "inicio")} />
                  </div>
                  <div style={{ width: "48%", float: "left", marginTop: "16px" }}>
                    <p>
                      <label
                        style={{
                          color: "rgba(0, 0, 0, 0.54)",
                          marginLeft: 12,
                        }}
                      >
                        Hora Termino
                      </label>
                    </p>
                    <TimePicker minuteStep={5} showSecond={false} name="tiempoTermino" id="tiempoTermino" value={tiempoTermino} onChange={(e) => this.cambiarValoresHora(e, "termino")} />
                  </div>
                </Grid>

                <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <DatePicker
                      id="date-picker-inline"
                      disableToolbar
                      invalidDateMessage="¡Fecha mal ingresada, por favor inténtalo nuevamente!"
                      variant="inline"
                      format="dd 'de' MMMM 'de' yyyy"
                      margin="normal"
                      label="Fecha"
                      value={this.state.fecha}
                      name="fecha"
                      onChange={(e) => this.cambiarValoresFecha(e)}
                      KeyboardButtonProps={{
                        "aria-label": "cambiar fecha",
                      }}
                      className="dtp_main"
                      renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                        day = moment(day).format("YYYY-MM-DD");

                        return <div style={{ position: "relative" }}>{dayComponent}</div>;
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} sm={4} md={4} lg={3} xl={3} style={{ marginTop: "16px" }}>
                  <InputLabel>Especialidad</InputLabel>
                  <Select name="especialidad" value={this.state.especialidad} onChange={this.cambiarValoresEspecialidad}>
                    <MenuItem value="0" disabled>
                      Seleccione una especialidad
                    </MenuItem>

                    {this.state.especialidades.map((esp, index) => (
                      <MenuItem value={esp.id} key={index}>
                        {esp.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={2} md={2} style={{ marginTop: "16px" }}>
                  <InputLabel>Rendimiento</InputLabel>
                  <Select name="rendimiento" disabled={this.state.disabled} className={"selectSimple"} value={this.state.rendimiento} style={{ width: "100%" }} onChange={this.cambioRendimiento} displayEmpty>
                    {this.state.rendimientos &&
                      this.state.rendimientos.map((ren, index) => (
                        <MenuItem value={ren.esr_rendimiento} key={index}>
                          {ren.esr_rendimiento}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <div id="tablahorarioProfesional" className="cargarHorario">
                <TableContainer style={{ maxHeight: 600 }}>{this.state.tabla}</TableContainer>
              </div>
            </Grid>
          </React.Fragment>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Horarios);
