import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";

// estilos
//import "../../assets/css/agregar.css";

export default class BeneficioDesinscribirPodologo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      beneficios: [],
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      usuId: props.usuario,
      beneficio: props.beneficio,
      motivoTermino: 1,
      formErrors: {},
      open: true,
      nombreBeneficio: props.nombreBeneficio,
      idPrioridad: props.idPrioridad,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  desinscribir = () => {
    axios
      .patch(
        global.config.modulos.beneficios + "desinscribir/",
        {
          beneficio: this.state.beneficio,
          usuario: this.state.usuId,
          usuarioPanel: this.state.uspId,
          motivoTermino: this.state.motivoTermino,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Desinscrito con éxito");
        } else {
          NotificationManager.error("Ha ocurrido un error al desinscribir");
        }
        this.salir();
      });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="beneficioUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
              <img alt="" class="marca-agua" />
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Desinscribir beneficio {this.state.nombreBeneficio}</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <label>Seleccione la razón por la que se desinscribe del beneficio</label>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <select className="selectTerminoBeneficio" name="motivoTermino" value={this.state.motivoTermino} onChange={this.cambiarValores}>
                    <option value="1">Alta por término de tratamiento</option>
                    <option value="2">Alta por inasistencia</option>
                    <option value="3">Error de digitación</option>
                  </select>
                </GridItem>

                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={this.salir} style={{ marginLeft: 20 }}>
                    Cerrar
                  </Button>
                  <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo fondoRojoBeneficio" onClick={this.desinscribir}>
                    Desinscribir
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
