import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import GridItem from "../grid/GridItem.js";
import BeneficioDesinscribir from "./BeneficioDesinscribirSalaEra.js";
import BeneficioInscribir from "./BeneficioInscribirSalaIra.js";
import { store } from "../../store/index";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import moment from "moment";

// estilos
//import "../../assets/css/agregar.css";

export default class BeneficioSalaEra extends Component {
  constructor(props) {
    super(props);

    this.state = {
      beneficios: [],
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      usuId: props.usuId,
      datosBeneficio: props.beneficio,
      formErrors: {},
      modalBeneficioInscribir: false,
      modalBeneficioDesinscribir: false,
      beneficio: null,
      nombreBeneficio: null,
      open: true,
      herramientas: store.getState().herramientas,
      especialidades: "",
      espRedireccion: props.cesfamAsociado,
      cesRedireccion: "",
      cesNombre: "",
      espNombre: "",
      nombreProfesional: "",
      idPrioridad: "",
      cesfam_bene: "",
    };
  }

  traeBeneficios = () => {
    this.props.traeBeneficios();
  };

  traeProfesionalPrioritario = () => {
    axios
      .get(global.config.modulos.profesionales + "obtener_profesional_preferente/", {
        headers: authHeader(),
        params: {
          usuario: this.state.usuId,
          especialidad: this.state.espRedireccion,
        },
      })
      .then((res) => {
        this.setState({
          nombreProfesional: res.data.datos.nombre + " " + res.data.datos.apellido,
          idPrioridad: res.data.datos.id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  traerCesfamRedireccion = () => {
    axios
      .get(global.config.modulos.especialidades, {
        headers: authHeader(),
        params: {
          esp_id: this.state.espRedireccion,
        },
      })
      .then((res) => {
        this.setState(
          {
            espNombre: res.data.datos.esp_nombre,
            cesRedireccion: res.data.datos.esp_cesfam_id,
          },
          () => {
            axios
              .get(global.config.modulos.cesfams, {
                headers: authHeader(),
                params: {
                  cesid: this.state.cesRedireccion,
                },
              })
              .then((res) => {
                if (res.data.estado) {
                  this.setState({ cesNombre: res.data.ces_nombre });
                }
              });
          }
        );
      });
  };

  mostrarBeneficioInscribir = (beneficio, nombre, especialidad, cesfam_bene) => {
    this.setState({
      modalBeneficioInscribir: true,
      beneficio: beneficio,
      nombreBeneficio: nombre,
      especialidades: especialidad,
      cesfam_bene: cesfam_bene,
    });
  };

  ocultarBeneficioInscribir = () => {
    this.setState({ modalBeneficioInscribir: false });
    this.traeBeneficios();
  };

  mostrarBeneficioDesinscribir = (beneficio, nombre) => {
    this.setState({
      modalBeneficioDesinscribir: true,
      beneficio: beneficio,
      nombreBeneficio: nombre,
    });
  };

  ocultarBeneficioDesinscribir = () => {
    this.setState({ modalBeneficioDesinscribir: false });
    this.traeBeneficios();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  inscribir = () => {
    axios
      .post(
        global.config.modulos.beneficios + "inscribir/",
        {
          fechaInicio: null,
          fechaExtra: null,
          fechaTermino: null,
          usuario: this.state.usuId,
          beneficio: this.state.datosBeneficio.ben_id,
          usuarioPanel: this.state.uspId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Inscrito con éxito");
        } else {
          NotificationManager.error("Ha ocurrido un error al inscribir");
        }
        this.traeBeneficios();
      });
  };

  componentDidMount() {
    this.traerCesfamRedireccion();
    this.traeProfesionalPrioritario();
  }

  render() {
    const beneficio = this.state.datosBeneficio;
    return (
      <React.Fragment>
        {beneficio.estadoUsuario ? (
          <GridItem xs={6} style={{ marginTop: 30 }}>
            <div className={"bordeTabla cardBeneficio"}>
              <h3 className="alignCenter">{beneficio.ben_nombre}</h3>
              <div className="cardDivBeneficio">
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Estado: </label> Activo
                </p>
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Fecha de ingreso: </label> {beneficio.usuario.usb_fecha_inicio}
                </p>
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Cesfam Asignado: </label> {this.state.cesNombre}
                </p>
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Profesional Asignado: </label> {this.state.nombreProfesional}
                </p>
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Especialidad del Beneficio:</label> {this.state.espNombre}
                </p>

                <p className="alignCenter" style={{ marginTop: 20 }}>
                  {this.state.herramientas.includes("H15") ? (
                    <a onClick={() => this.mostrarBeneficioDesinscribir(beneficio.ben_id, beneficio.ben_nombre)} className="botonDesinscribirBeneficio">
                      Desinscribir
                    </a>
                  ) : null}
                </p>
              </div>
            </div>
          </GridItem>
        ) : (
          <GridItem xs={6} style={{ marginTop: 30 }}>
            <div className={"bordeTabla cardBeneficio"}>
              <h3 className="alignCenter">{beneficio.ben_nombre}</h3>
              <div className="cardDivBeneficio">
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Estado: </label> Inactivo
                </p>
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Fecha de ingreso: </label> --
                </p>
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Cesfam Asignado: </label> --
                </p>
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Profesional Asignado: </label> --
                </p>
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Especialidad del Beneficio:</label> --
                </p>

                <br />
                <p className="alignCenter" style={{ marginTop: 20 }}>
                  {this.state.herramientas.includes("H15") ? (
                    <a onClick={() => this.mostrarBeneficioInscribir(beneficio.ben_id, beneficio.ben_nombre, beneficio.ben_especialidades, beneficio.ben_cesfam)} className="botonInscribirBeneficio">
                      Inscribir
                    </a>
                  ) : null}
                </p>
              </div>
            </div>
          </GridItem>
        )}
        {this.state.modalBeneficioInscribir ? <BeneficioInscribir ocultar={this.ocultarBeneficioInscribir} usuario={this.state.usuId} beneficio={this.state.beneficio} especialidades={this.state.especialidades} nombreBeneficio={this.state.datosBeneficio.ben_nombre} cesfam_bene={this.state.cesfam_bene}></BeneficioInscribir> : null}
        {this.state.modalBeneficioDesinscribir ? <BeneficioDesinscribir ocultar={this.ocultarBeneficioDesinscribir} usuario={this.state.usuId} beneficio={this.state.beneficio} idPrioridad={this.state.idPrioridad} nombreBeneficio={this.state.datosBeneficio.ben_nombre}></BeneficioDesinscribir> : null}
      </React.Fragment>
    );
  }
}
