import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import { connect } from "react-redux";

// core components
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Titulo from "../../components/titulo/Titulo.js";
import MaterialTable from "material-table";

import signo from "./../../assets/img/signo.svg";

import "./../../assets/css/bloqueos.css";

class ListaTeleconsulta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cesfam: store.getState().cesfam,
      profesionales: [],
    };
  }

  componentDidMount() {
    this.fetchProfesionales();
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Lista profesionales <span title='Permite revisar los profesionales que tienen activa la teleconsulta'><img src=" + signo + "></img></span>";
  };

  fetchProfesionales = async () => {
    const apiUrl = global.config.modulos.profesionales + "teleconsulta/?cesfam=" + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var profesionales = res.data["datos"];
      this.setState({
        profesionales: profesionales,
      });
    });
  };

  render() {
    return (
      <div className="middle-area">
        <div className="middle-content">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} id="tablaBloqueos">
              <MaterialTable
                title=""
                columns={[
                  { title: "Rut", field: "pro_rut" },
                  { title: "Nombre", field: "pro_nombre" },
                  { title: "Teleconsulta", field: "teleconsulta" },
                ]}
                data={this.state.profesionales}
                actions={[]}
                options={{
                  actionsColumnIndex: -1,
                  search: true,
                }}
                localization={{
                  header: {
                    actions: "Acciones",
                  },
                }}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ListaTeleconsulta);
