import React, { Component } from "react";

// core components
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import GridItem from "./../../components/grid/GridItem.js";
import BeneficioDesinscribir from "./BeneficioDesinscribirControlSano";
import BeneficioInscribir from "./BeneficioInscribirControlSano";
import { store } from "./../../store/index";

export default class BeneficioControlSano extends Component {
  constructor(props) {
    super(props);

    this.state = {
      beneficios: [],
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      usuId: props.usuId,
      datosBeneficio: props.beneficio,
      formErrors: {},
      modalBeneficioInscribir: false,
      modalBeneficioDesinscribir: false,
      beneficio: null,
      nombreBeneficio: null,
      open: true,
      herramientas: store.getState().herramientas,
      nombreProfesional: "",
      idPrioridad: null,
    };
  }

  componentDidMount() {}

  traeBeneficios = () => {
    this.props.traeBeneficios();
  };

  mostrarBeneficioInscribir = (beneficio, nombre) => {
    this.setState({
      modalBeneficioInscribir: true,
      beneficio: beneficio,
      nombreBeneficio: nombre,
    });
  };

  ocultarBeneficioInscribir = () => {
    this.setState({ modalBeneficioInscribir: false });
    this.traeBeneficios();
  };

  mostrarBeneficioDesinscribir = (beneficio, nombre) => {
    this.setState({
      modalBeneficioDesinscribir: true,
      beneficio: beneficio,
      nombreBeneficio: nombre,
    });
  };

  ocultarBeneficioDesinscribir = () => {
    this.setState({ modalBeneficioDesinscribir: false });
    this.traeBeneficios();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const beneficio = this.state.datosBeneficio;

    return (
      <React.Fragment>
        {beneficio.estadoUsuario ? (
          <GridItem xs={6} style={{ marginTop: 30 }}>
            <div className={"bordeTabla cardBeneficio"}>
              <h3 className="alignCenter">{beneficio.ben_nombre}</h3>
              <div className="cardDivBeneficio">
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Estado: </label> Activo
                </p>
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Fecha de ingreso: </label> {beneficio.usuario.usb_fecha_inicio}
                </p>

                <p className="alignCenter" style={{ marginTop: 20 }}>
                  {this.state.herramientas.includes("H15") ? (
                    <a onClick={() => this.mostrarBeneficioDesinscribir(beneficio.ben_id, beneficio.ben_nombre)} className="botonDesinscribirBeneficio">
                      Desinscribir
                    </a>
                  ) : null}
                </p>
              </div>
            </div>
          </GridItem>
        ) : (
          <GridItem xs={6} style={{ marginTop: 30 }}>
            <div className={"bordeTabla cardBeneficio"}>
              <h3 className="alignCenter">{beneficio.ben_nombre}</h3>
              <div className="cardDivBeneficio">
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Estado: </label> Inactivo
                </p>
                <p className="parrafoBeneficio">
                  <label className="labelBeneficio">Fecha de ingreso: </label>
                  {" --"}
                </p>
                <br />
                <p className="alignCenter" style={{ marginTop: 20 }}>
                  {this.state.herramientas.includes("H15") ? (
                    <a onClick={() => this.mostrarBeneficioInscribir(beneficio.ben_id, beneficio.ben_nombre)} className="botonInscribirBeneficio">
                      Inscribir
                    </a>
                  ) : null}
                </p>
              </div>
            </div>
          </GridItem>
        )}
        {this.state.modalBeneficioInscribir ? <BeneficioInscribir ocultar={this.ocultarBeneficioInscribir} usuario={this.state.usuId} beneficio={this.state.beneficio} nombreBeneficio={this.state.datosBeneficio.ben_nombre}></BeneficioInscribir> : null}
        {this.state.modalBeneficioDesinscribir ? <BeneficioDesinscribir ocultar={this.ocultarBeneficioDesinscribir} usuario={this.state.usuId} beneficio={this.state.beneficio} idPrioridad={this.state.idPrioridad} nombreBeneficio={this.state.datosBeneficio.ben_nombre}></BeneficioDesinscribir> : null}
      </React.Fragment>
    );
  }
}
