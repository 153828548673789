import React from 'react';
import Button from "@material-ui/core/Button";
import GridItem from '../../components/grid/GridItem';
import Dialog from "@material-ui/core/Dialog";
import GridContainer from '../../components/grid/GridContainer';
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export const ModalTelefono = ({showModal, noPhones, handleConfirm, handleCancel}) => {

    return(
        <Dialog open={showModal} onClose={handleCancel} className="modificarUsuario">
            <GridContainer  className="agendar-container">
                <GridItem xs={12} sm={12} md={12} className="" style={{ marginTop: 30, marginLeft: 30, marginRight: 30, marginBottom: 30 }}>
                    <GridContainer className="">
                        <GridItem xs={12} style={{ marginTop: 10 }}>
                        <h1>El paciente no posee un número de teléfono<br/> válido registrado. </h1>
                        </GridItem>
                        <GridItem xs={12} style={{ marginTop: 30, display: "flex" , justifyContent: 'flex-end' }}>
                            <Button style={{ marginRight: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} className="fondoVerde" onClick={handleConfirm}>
                                Agregar Teléfono
                            </Button>
                            <Button style={{ marginLeft: 5, marginRight: 20, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={handleCancel}>
                                Atrás
                            </Button>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </Dialog>
        )
}