import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { withStyles, makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import axios from "axios";
import { store } from "./../../store/index";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import { NotificationManager } from "react-notifications";
import Tooltip from "@material-ui/core/Tooltip";
import TimePicker from "rc-time-picker";

import { FaTrash, FaUnlock, FaClone } from "react-icons/fa";

import Diario from "./../../components/CopiarHorarios/Diario";
import Semanal from "./../../components/CopiarHorarios/Semanal";
import Clonar from "./../../components/CopiarHorarios/Clonar";

import CambiarRendimiento from "./ModalCambioRendimiento";
import DuplicarHorario from "./ModalDuplicarHorario";
import AccionesCarga from "./ModalAccionesCarga";

// CSS
import "rc-time-picker/assets/index.css";
import "./../../assets/css/horarios.css";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#fff",
  },
}))(Tooltip);

export default class CargarHorario extends Component {
  constructor(props) {
    super(props);

    var weeknumber = moment().isoWeek();
    weeknumber = weeknumber < 10 ? "0" + weeknumber : weeknumber;
    var yearnumber = moment().year();
    var semanaActual = yearnumber + "-W" + weeknumber;

    var fechaActual = moment().format("YYYY-MM-DD");
    this.state = {
      proId: props.profesional,
      nombreprofesional: "",

      motivosBloqueo: [],
      motivoBloqueo: "Activa",
      motivoBloqueoTxt: "",
      tiposatenciones: [],
      tipoatencion: "",

      direcciones: [],
      direccion: null,

      sectores: [],
      sector: "",
      rendimientos: [],
      rendimiento: "",
      tabla: "",
      tiempoInicio: moment(fechaActual + " 08:00"),
      tiempoTermino: moment(fechaActual + " 17:00"),
      semana: semanaActual,

      tipoRol: store.getState().tipo,
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,

      fechaHorario: "",

      arregloHorario: [],
      arregloDias: [],
      arregloTemp: [],
      arregloSum: [],
      arregloInicio: [],
      arregloTermino: [],
      mostrarMotivoTxt: false,
      disabled: true,
      btnCargarHorarioDisabled: false,
      btnCrearHorarioDisabled: true,
      cargahorario: false,
      copiarDia: false,
      copiarSemana: false,
      tipoCarga: 1,

      accionesCarga: false,
      cambiarRendimiento: false,
      cambiarHoras: false,
      repetirSemana: false,
      duplicarHorario: false,

      herramientas: store.getState().herramientas,
    };
  }

  componentDidMount() {
    this.traerHorarioCesfam();
    this.traerMotivosBloqueo();
    this.mostrarFiltros();
  }

  mensajeCopiarUltimaCarga = (tipo) => {
    if (tipo == 1) {
      confirmAlert({
        title: "",
        message: "¿Desea copiar toda la semana o solo la última carga?",
        buttons: [
          {
            label: "Toda",
            onClick: () => this.copiarHorarioSemana(1),
            className: "btnAceptar",
          },
          {
            label: "Última carga",
            onClick: () => this.copiarHorarioSemana(2),
            className: "btnCancelar",
          },
        ],
      });
    } else {
      confirmAlert({
        title: "",
        message: "¿Desea repetir toda la semana o solo la última carga?",
        buttons: [
          {
            label: "Toda",
            onClick: () => this.repetirSemana(1),
            className: "btnAceptar",
          },
          {
            label: "Última carga",
            onClick: () => this.repetirSemana(2),
            className: "btnCancelar",
          },
        ],
      });
    }
  };

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
        },
      ],
    });
  }

  mostrarAlertaErrorAlCargarHorario() {
    NotificationManager.error("El Horario ha cargar tuvo un problema y no se ha cargado la información");
  }

  desbloquearHorarioConfirmar(horId) {
    confirmAlert({
      title: "",
      message: "¿Seguro(a) que deseas desbloquear el horario?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => this.desbloquearHorario(horId),
          className: "btnAceptar",
        },
      ],
    });
  }

  eliminarHorarioConfirmar(horId) {
    confirmAlert({
      title: "",
      message: "¿Seguro(a) que deseas eliminar el horario?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => this.eliminarHorario(horId),
          className: "btnAceptar",
        },
      ],
    });
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  cambiarValoresHora = (valor, hora) => {
    if (valor != null) {
      if (hora == "inicio") {
        if (valor > this.state.tiempoTermino) {
          NotificationManager.warning("La hora de inicio no puede ser mayor a la de término");
        } else {
          this.setState({
            tiempoInicio: valor,
            arregloHorario: [],
            arregloDias: [],
            arregloTemp: [],
            arregloSum: [],
            arregloInicio: [],
            arregloTermino: [],
            tabla: "",
          });

          this.horarios(this.state.rendimiento, this.state.semana, valor, this.state.tiempoTermino);
        }
      }
      if (hora == "termino") {
        if (valor < this.state.tiempoInicio) {
          NotificationManager.warning("La hora de término no puede ser menor a la de inicio");
        } else {
          this.setState({
            tiempoTermino: valor,
            arregloHorario: [],
            arregloDias: [],
            arregloTemp: [],
            arregloSum: [],
            arregloInicio: [],
            arregloTermino: [],
            tabla: "",
          });

          this.horarios(this.state.rendimiento, this.state.semana, this.state.tiempoInicio, valor);
        }
      }
    }
  };

  validaciones() {
    const tiempoInicio = this.state.tiempoInicio.format("HH:mm");
    const tiempoTermino = this.state.tiempoTermino.format("HH:mm");
    let formErrors = {};
    let formIsValid = true;

    var prof = this.state.proId;

    if (tiempoInicio == "00:00") {
      formIsValid = false;
      NotificationManager.warning("Ingrese el rango del horario a crear");
    } else if (tiempoTermino == "00:00") {
      formIsValid = false;
      NotificationManager.warning("Ingrese el rango del horario a crear");
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  copiarHorarioSemana = (tipoCarga) => {
    this.setState({
      copiarSemana: true,
      tipoCarga: tipoCarga,
    });
  };

  ocultarCopiaSemana = () => {
    this.setState({ copiarSemana: false });
  };

  ocultarCopiaSemanaActualizado = () => {
    this.setState({ copiarSemana: false });
    this.horarios(this.state.rendimiento, this.state.semana, this.state.tiempoInicio, this.state.tiempoTermino);
  };

  copiarHorarioDiario = (fechaHorario) => {
    this.setState({
      fechaHorario: fechaHorario,
      copiarDia: true,
    });
  };

  ocultarCopiaDia = () => {
    this.setState({ copiarDia: false });
  };

  ocultarCopiaDiaActualizado = () => {
    this.setState({ copiarDia: false });
    this.horarios(this.state.rendimiento, this.state.semana, this.state.tiempoInicio, this.state.tiempoTermino);
  };

  repetirSemana = (tipoCarga) => {
    this.setState({
      repetirSemana: true,
      tipoCarga: tipoCarga,
    });
  };

  ocultarRepetirSemana = () => {
    this.setState({ repetirSemana: false });
  };

  ocultarRepetirSemanaActualizado = () => {
    this.setState({ repetirSemana: false });
    this.horarios(this.state.rendimiento, this.state.semana, this.state.tiempoInicio, this.state.tiempoTermino);
  };

  mostrarFiltros = (e) => {
    this.setState({
      disabled: false,
      btnCrearHorarioDisabled: false,
    });

    this.traerDirecciones();
    this.traerSectores();
    this.traerEspecialidades();
  };

  crearHorarios = (e) => {
    if (this.validaciones()) {
      this.setState({
        cargahorario: true,
      });
      this.horarios(this.state.rendimiento, this.state.semana, this.state.tiempoInicio, this.state.tiempoTermino);
    }
  };

  cancelarCrearHorarios = (e) => {
    confirmAlert({
      title: "¿Seguro que desea continuar?",
      message: "Se borrarán las horas que no hayan sido cargadas.",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Continuar",
          onClick: () => {
            if (this.validaciones()) {
              this.setState({
                cargahorario: false,
                btnCrearHorarioDisabled: true,
                cambiarHoras: false,
              });
            }
          },
          className: "btnAceptar",
        },
      ],
    });
  };

  traerHorarioCesfam() {
    const apiUrl = global.config.modulos.cesfams + "cesfam_horario/?cesfam=" + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader(), params: { proId: this.state.proId, cesfam: this.state.cesfam } }).then((res) => {
      var horarioFuncionamiento = res.data.horarioFuncionamiento;
      var fechaActual = moment().format("YYYY-MM-DD");
      this.setState({
        tiempoInicio: moment(fechaActual + " " + horarioFuncionamiento.hora_inicio),
        tiempoTermino: moment(fechaActual + " " + horarioFuncionamiento.hora_termino),
      });
    });
  }

  traerMotivosBloqueo() {
    const apiUrl = global.config.modulos.agendamientos + "motivos_bloqueo_horario/?tipo=1&cesfam=" + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var motivosBloqueo = res.data;
      this.setState({
        motivosBloqueo: motivosBloqueo,
      });
    });
  }

  traerSectores() {
    const apiUrl = global.config.modulos.profesionales + "sectores_por_profesional_cesfam/" + this.state.proId + "/" + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.estado && res.data["datos"].length > 0) {
        this.setState({
          sectores: res.data["datos"],
          sector: res.data["datos"][0]["sec_id"],
        });
      } else {
        this.setState({
          sectores: [],
          sector: "",
        });
      }
    });
  }

  traerDirecciones() {
    const apiUrl = global.config.modulos.agendamientos + "horarios_direcciones/?cesfam=" + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var direcciones = res.data["direcciones"];
      if (direcciones.length > 0) {
        this.setState({
          direcciones: res.data["direcciones"],
          direccion: res.data["direcciones"][0]["hds_id"],
        });
      }
    });
  }

  traerEspecialidades() {
    const apiUrl = global.config.modulos.agendamientos + "especialidades_carga_horario/" + this.state.cesfam + "/" + this.state.proId;

    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.length > 0) {
        this.setState({
          tiposatenciones: res.data,
          tipoatencion: res.data[0]["esp_id"],
        });
        this.traerRendimientos(res.data[0]["esp_id"], 0);
      } else {
        this.setState({
          tiposatenciones: [],
          tipoatencion: "",
        });
        this.traerRendimientos(0, 0);
      }
    });
  }

  traerRendimientos(espid, tipo) {
    const apiUrl = global.config.modulos.agendamientos + "especialidades_rendimiento/" + espid;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.length > 0) {
        this.setState({
          rendimientos: res.data,
          rendimiento: res.data[0]["esr_rendimiento"],
        });

        if (tipo == 1) {
          this.horarios(res.data[0]["esr_rendimiento"], this.state.semana, this.state.tiempoInicio, this.state.tiempoTermino);
        }
      } else {
        this.setState({
          rendimientos: [
            {
              esr_rendimiento: 15,
            },
            {
              esr_rendimiento: 20,
            },
            {
              esr_rendimiento: 30,
            },
            {
              esr_rendimiento: 40,
            },
            {
              esr_rendimiento: 45,
            },
            {
              esr_rendimiento: 60,
            },
          ],
          rendimiento: 15,
        });

        if (tipo == 1) {
          this.horarios(15, this.state.semana, this.state.tiempoInicio, this.state.tiempoTermino);
        }
      }
    });
  }

  horarios = (rendimiento, semana, tiempoInicio, tiempoTermino) => {
    var horarioInicio = tiempoInicio.format("HH:mm");
    var horarioTermino = tiempoTermino.format("HH:mm");

    var sem = semana;

    var arrSemana = sem.split("-W");
    var nSemana = arrSemana[1];
    var añoPorNSemana = arrSemana[0];

    var fechaInicioSemana = moment().isoWeekYear(añoPorNSemana).isoWeek(nSemana).startOf("isoweek").format("YYYY-MM-DD");
    var fechaTerminoSemana = moment().isoWeekYear(añoPorNSemana).isoWeek(nSemana).endOf("isoweek").format("YYYY-MM-DD");

    var data = {
      fechaInicioSemana: fechaInicioSemana,
      fechaTerminoSemana: fechaTerminoSemana,
      cesfam: this.state.cesfam,
      profesional: this.state.proId,
    };

    let apiUrl = global.config.modulos.agendamientos + "horario_cargado_semanal/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      var horarioMedico = res.data.datos;
      var feriados = res.data.feriados;
      var horarioFuncionamiento = res.data.horarioFuncionamiento;
      var reuniones = res.data.reuniones;
      var excepciones = res.data.excepciones;

      var fechaInicio = fechaInicioSemana + " " + horarioInicio;
      var fechaTermino = fechaInicioSemana + " " + horarioTermino;

      var fecha = fechaInicio;

      var minutosAsumar = parseFloat(rendimiento);

      var arregloRendimiento = [];
      arregloRendimiento.push(fecha);
      do {
        fecha = moment(fecha).add(minutosAsumar, "minutes").format("YYYY-MM-DD HH:mm:ss");
        arregloRendimiento.push(fecha);
      } while (fecha < fechaTermino);

      var arrfechaBloqueo = [];
      for (var e = 0; e < horarioMedico.length; e++) {
        arrfechaBloqueo.push(horarioMedico[e]["hor_atencion"].replace("T", " "));
      }

      var tabla = [];

      var rowsHead = [];
      rowsHead.push(<TableCell className={"tablaRow cellInicio"} key={0}></TableCell>);

      var fecha = moment(fechaInicioSemana);
      for (var i = 0; i <= 6; i++) {
        rowsHead.push(
          <TableCell className={i == 6 ? "tablaRow cellTermino" : "tablaRow"}>
            <p style={{ textAlign: "center" }}>{fecha.format("dddd")} </p>
            <p style={{ textAlign: "center" }}>{fecha.format("DD-MM-YYYY")}</p>

            <FaClone onClick={this.copiarHorarioDiario.bind(this, fecha.format("YYYY-MM-DD"))} style={{ color: "#baaaaa", cursor: "pointer" }} />
          </TableCell>
        );
        fecha = moment(fecha).add(1, "day");
      }

      tabla.push(
        <TableHead>
          <TableRow>{rowsHead}</TableRow>
        </TableHead>
      );

      var rows = [];
      for (var j = 0; j < arregloRendimiento.length; j++) {
        var cell = [];

        var fecha = arregloRendimiento[j].split(" ");
        var horasF = fecha[1];
        var horaR = horasF.substring(5, 0);

        cell.push(<TableCell className={"tablaRow"}>{horaR}</TableCell>);

        var fechaInicioS = moment(fechaInicioSemana).format("YYYY-MM-DD");

        for (var x = 0; x <= 6; x++) {
          var fechaHorario = moment(fechaInicioS).format("YYYY-MM-DD");

          var Hora = horaR + ":00";
          var fechaHora = fechaHorario + " " + Hora;

          var bloqueoPorReunion = 0;
          var diaFeriado = 0;
          var fueraDeHorario = 0;
          var horarioCopado = 0;
          var coompFecha = 0;
          var rendimiento2 = "";
          var restriccionEdad = "";

          var horId = "";
          var horAtencion = "";
          var horEspecialidad = "";
          var horEdadMenor = "";
          var horEdadMayor = "";
          var horSector = "";
          var horEspecialidadId = "";
          var horMotivoBloqueo = "";
          var horTipo = "";
          var horRendimiento = "";
          var horEstado = "";

          var tipoReunion = "";
          var direccion = null;

          var funcionario = "";
          var fechaCreado = "";

          var arrfechaFuncionamiento = [];
          for (var l = 0; l < horarioFuncionamiento.length; l++) {
            var dia_semana = horarioFuncionamiento[l]["dia_semana"];
            var inicio_funcionamiento = horarioFuncionamiento[l]["hora_inicio"];
            var termino_funcionamiento = horarioFuncionamiento[l]["hora_termino"];

            arrfechaFuncionamiento.push(dia_semana);

            if ((Hora < inicio_funcionamiento && dia_semana == x) || (Hora > termino_funcionamiento && dia_semana == x)) {
              fueraDeHorario = 1;
            }
          }

          for (var m = 0; m < reuniones.length; m++) {
            var fecha_reunion = reuniones[m]["fecha_reunion"];
            var inicio_reunion = reuniones[m]["hora_inicio"];
            var termino_reunion = reuniones[m]["hora_termino"];

            if (Hora >= inicio_reunion && Hora < termino_reunion && fecha_reunion == fechaHorario) {
              bloqueoPorReunion = 1;
              tipoReunion = reuniones[m]["reunion"];
            }
          }

          for (var ex = 0; ex < excepciones.length; ex++) {
            var fecha_reunion = excepciones[ex]["fecha_reunion"];
            var inicio_reunion = excepciones[ex]["hora_inicio"];
            var termino_reunion = excepciones[ex]["hora_termino"];

            if (Hora >= inicio_reunion && Hora < termino_reunion && fecha_reunion == fechaHorario) {
              bloqueoPorReunion = 0;
            }
          }

          if (feriados.indexOf(fechaHorario) > 0) {
            diaFeriado = 1;
          } else if (arrfechaFuncionamiento.indexOf(x) < 0) {
            fueraDeHorario = 1;
          }

          for (var d = 0; d < horarioMedico.length; d++) {
            horAtencion = moment(horarioMedico[d]["hor_atencion"]);
            horRendimiento = horarioMedico[d]["hor_rendimiento"];

            var fechaAtencion = horAtencion.format("YYYY-MM-DD");
            var horaAtencion = horAtencion.format("HH:mm:ss");
            horAtencion = horAtencion.format("YYYY-MM-DD HH:mm:ss");

            var minutosAsumar = parseFloat(horRendimiento);

            var fechaFinal = moment(horAtencion).add(minutosAsumar, "minutes").format("YYYY-MM-DD HH:mm:ss");

            if (arrfechaBloqueo.indexOf(fechaHora) < 0 && horaAtencion < Hora && horAtencion < fechaFinal && fechaFinal > fechaHora && fechaAtencion == fechaHorario && horRendimiento != rendimiento) {
              horarioCopado = 1;
            } else if (arrfechaBloqueo.indexOf(fechaHora) < 0 && horaAtencion < Hora && horAtencion < fechaFinal && fechaFinal > fechaHora && fechaAtencion == fechaHorario && horRendimiento == rendimiento) {
              horarioCopado = 1;
            } else if (horAtencion == fechaHora && fechaAtencion == fechaHorario && diaFeriado == 0 && fueraDeHorario == 0 && bloqueoPorReunion == 0) {
              horId = horarioMedico[d]["hor_id"];
              horEspecialidad = horarioMedico[d]["hor_especialidad__esp_nombre"];
              horEdadMenor = horarioMedico[d]["hor_edad_menor"];
              horEdadMayor = horarioMedico[d]["hor_edad_mayor"];
              horSector = horarioMedico[d]["hor_sector__sec_nombre"];
              horEspecialidadId = horarioMedico[d]["hor_especialidad"];
              horMotivoBloqueo = horarioMedico[d]["hor_motivo"];
              horTipo = horarioMedico[d]["hor_tipo"];
              horEstado = horarioMedico[d]["hor_estado"];
              direccion = horarioMedico[d]["direccion"];
              funcionario = horarioMedico[d]["funcionario"];
              fechaCreado = horarioMedico[d]["hor_fecha_registro"];

              horarioCopado = 0;
              coompFecha += 1;
              fueraDeHorario = 0;
              diaFeriado = 0;
              bloqueoPorReunion = 0;

              rendimiento2 = horRendimiento + " minutos ";

              if ((horEdadMenor == null || horEdadMenor == 0) && (horEdadMayor == null || horEdadMayor == 0)) {
                restriccionEdad = " Sin restricción";
              } else {
                restriccionEdad = " de " + horEdadMenor + " a " + horEdadMayor + " años";
              }
            }
          }

          if (fueraDeHorario == 1) {
            cell.push(
              <TableCell className={"tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
                <div>
                  <span>
                    <span className={"contenedorDatos"}>
                      <button
                        title="FUERA DE HORARIO DEL CENTRO"
                        className={"botonesCargaMasiva porSeleccionar"}
                        style={{
                          backgroundColor: "#6a6c6a",
                        }}
                      >
                        <label className={"textoBotonesCarga"} style={{ color: "#444444" }}>
                          Fuera de horario
                        </label>
                      </button>
                    </span>
                  </span>
                </div>
              </TableCell>
            );
          } else if (bloqueoPorReunion == 1) {
            cell.push(
              <TableCell className={"tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
                <div>
                  <span>
                    <span className={"contenedorDatos"}>
                      <button
                        title={"REUNIÓN " + tipoReunion}
                        className={"botonesCargaMasiva porSeleccionar"}
                        style={{
                          backgroundColor: "#6a6c6a",
                        }}
                      >
                        <label className={"textoBotonesCarga"} style={{ color: "#444444" }}>
                          Bloqueado por<br></br>
                          {tipoReunion}
                        </label>
                      </button>
                    </span>
                  </span>
                </div>
              </TableCell>
            );
          } else if (diaFeriado == 1) {
            cell.push(
              <TableCell className={"tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
                <div>
                  <span>
                    <span className={"contenedorDatos"}>
                      <button
                        title="HORARIO BLOQUEADO"
                        className={"botonesCargaMasiva porSeleccionar"}
                        style={{
                          backgroundColor: "#6a6c6a",
                        }}
                      >
                        <label className={"textoBotonesCarga"} style={{ color: "#444444" }}>
                          Día feriado
                        </label>
                      </button>
                    </span>
                  </span>
                </div>
              </TableCell>
            );
          } else if (horarioCopado == 1) {
            cell.push(
              <TableCell className={"tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
                <div>
                  <span>
                    <span className={"contenedorDatos"}>
                      <button
                        title="HORARIO BLOQUEADO"
                        className={"botonesCargaMasiva porSeleccionar"}
                        style={{
                          backgroundColor: "#6a6c6a",
                        }}
                      >
                        <label className={"textoBotonesCarga"} style={{ color: "#444444" }}>
                          Horario Bloqueado
                        </label>
                      </button>
                    </span>
                  </span>
                </div>
              </TableCell>
            );
          } else {
            if (coompFecha != 0) {
              if (horTipo == "2") {
                cell.push(
                  <TableCell className={"tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
                    <div>
                      <HtmlTooltip
                        title={
                          <div>
                            <Table class="table table-striped table-hover tablaPopover">
                              <TableBody>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Especialidad&nbsp;&nbsp;&nbsp;</TableCell>
                                  <TableCell>{horEspecialidad}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Rendimiento&nbsp;&nbsp;&nbsp;</TableCell>
                                  <TableCell>{rendimiento2}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Sector&nbsp;</TableCell>
                                  <TableCell>{horSector}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Restricción de edad&nbsp;</TableCell>
                                  <TableCell>{restriccionEdad}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Motivo bloqueo&nbsp;</TableCell>
                                  <TableCell>{horMotivoBloqueo}</TableCell>
                                </TableRow>
                                {direccion != null && (
                                  <TableRow>
                                    <TableCell style={{ fontWeight: "900" }}>Dirección&nbsp;</TableCell>
                                    <TableCell>{direccion}</TableCell>
                                  </TableRow>
                                )}
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Creado por&nbsp;</TableCell>
                                  <TableCell>
                                    {funcionario}
                                    <br></br>
                                    {moment(fechaCreado).format("DD-MM-YYYY HH:mm:ss")}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </div>
                        }
                      >
                        <button
                          title="HORARIO BLOQUEADO"
                          className={"botonesCargaMasiva porSeleccionar"}
                          style={{
                            backgroundColor: "#6a6c6a",
                          }}
                        >
                          <label
                            className={"textoBotonesCarga"}
                            style={{
                              color: "#444444",
                            }}
                          >
                            {horEspecialidad}
                          </label>
                        </button>
                      </HtmlTooltip>
                      <br></br>
                      <FaTrash
                        onClick={this.eliminarHorarioConfirmar.bind(this, horId)}
                        style={{
                          color: "#baaaaa",
                          cursor: "pointer",
                          marginTop: 7,
                        }}
                      />
                    </div>
                  </TableCell>
                );
              } else if (horEstado == "0") {
                cell.push(
                  <TableCell className={"tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
                    <div>
                      <HtmlTooltip
                        title={
                          <div>
                            <Table class="table table-striped table-hover tablaPopover">
                              <TableBody>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Especialidad&nbsp;&nbsp;&nbsp;</TableCell>
                                  <TableCell>{horEspecialidad}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Rendimiento&nbsp;&nbsp;&nbsp;</TableCell>
                                  <TableCell>{rendimiento2}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Sector&nbsp;</TableCell>
                                  <TableCell>{horSector}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Restricción de edad&nbsp;</TableCell>
                                  <TableCell>{restriccionEdad}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Motivo bloqueo&nbsp;</TableCell>
                                  <TableCell>{horMotivoBloqueo}</TableCell>
                                </TableRow>
                                {direccion != null && (
                                  <TableRow>
                                    <TableCell style={{ fontWeight: "900" }}>Dirección&nbsp;</TableCell>
                                    <TableCell>{direccion}</TableCell>
                                  </TableRow>
                                )}
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Creado por&nbsp;</TableCell>
                                  <TableCell>
                                    {funcionario}
                                    <br></br>
                                    {moment(fechaCreado).format("DD-MM-YYYY HH:mm:ss")}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </div>
                        }
                      >
                        <button
                          title="HORARIO BLOQUEADO"
                          className={"botonesCargaMasiva porSeleccionar"}
                          style={{
                            backgroundColor: "#6a6c6a",
                          }}
                        >
                          <label
                            className={"textoBotonesCarga"}
                            style={{
                              color: "#444444",
                            }}
                          >
                            {horEspecialidad}
                          </label>
                        </button>
                      </HtmlTooltip>
                      <br></br>
                      <FaUnlock
                        onClick={this.desbloquearHorarioConfirmar.bind(this, horId)}
                        style={{
                          color: "#baaaaa",
                          cursor: "pointer",
                          marginTop: 7,
                        }}
                      />
                    </div>
                  </TableCell>
                );
              } else {
                cell.push(
                  <TableCell className={"tablaRow horarioCreado"} style={{ textAlign: "center" }}>
                    <div>
                      <HtmlTooltip
                        title={
                          <div>
                            <Table class="table table-striped table-hover tablaPopover">
                              <TableBody>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Especialidad&nbsp;&nbsp;&nbsp;</TableCell>
                                  <TableCell>{horEspecialidad}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Rendimiento&nbsp;&nbsp;&nbsp;</TableCell>
                                  <TableCell>{rendimiento2}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Sector&nbsp;</TableCell>
                                  <TableCell>{horSector}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Restricción de edad&nbsp;</TableCell>
                                  <TableCell>{restriccionEdad}</TableCell>
                                </TableRow>
                                {direccion != null && (
                                  <TableRow>
                                    <TableCell style={{ fontWeight: "900" }}>Dirección&nbsp;</TableCell>
                                    <TableCell>{direccion}</TableCell>
                                  </TableRow>
                                )}
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Creado por&nbsp;</TableCell>
                                  <TableCell>
                                    {funcionario}
                                    <br></br>
                                    {moment(fechaCreado).format("DD-MM-YYYY HH:mm:ss")}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </div>
                        }
                      >
                        {this.state.herramientas.includes("H42") || this.state.herramientas.includes("H43") ? (
                          <button
                            className={"botonesCargaMasiva porSeleccionar"}
                            style={{
                              backgroundColor: "rgb(38, 165, 48)",
                              borderColor: "rgb(38, 165, 48)",
                              color: "#fff",
                            }}
                            onClick={this.accionesCarga.bind(this, horId, horEspecialidadId)}
                          >
                            <label className={"textoBotonesCarga"}>{horEspecialidad}</label>
                          </button>
                        ) : (
                          <button
                            className={"botonesCargaMasiva porSeleccionar"}
                            style={{
                              backgroundColor: "rgb(38, 165, 48)",
                              borderColor: "rgb(38, 165, 48)",
                              color: "#fff",
                            }}
                          >
                            <label className={"textoBotonesCarga"}>{horEspecialidad}</label>
                          </button>
                        )}
                      </HtmlTooltip>
                      <br></br>
                      <FaTrash
                        onClick={this.eliminarHorarioConfirmar.bind(this, horId)}
                        style={{
                          color: "#baaaaa",
                          cursor: "pointer",
                          marginTop: 7,
                        }}
                      />
                    </div>
                  </TableCell>
                );
              }
            } else {
              cell.push(
                <TableCell className={"tablaRow horarioASeleccionar"} style={{ textAlign: "center" }}>
                  <div>
                    <span>
                      <span className={"contenedorDatos"}>
                        <button id={"seleccionar" + fechaHora} className={"botonesCargaMasiva porSeleccionar"} onClick={this.seleccionar.bind(this, "seleccionar" + fechaHora, fechaHora)}>
                          <label className={"textoBotonesCarga"}>Seleccionar</label>
                        </button>
                      </span>
                    </span>
                  </div>
                </TableCell>
              );
            }
          }

          fechaInicioS = moment(fechaInicioS).add(1, "day").format("YYYY-MM-DD");
        }
        rows.push(<TableRow className={"tablaRow"}>{cell}</TableRow>);
      }
      tabla.push(<TableBody>{rows}</TableBody>);

      this.setState({
        tabla: (
          <Table stickyHeader className="bordeTabla" size="small" aria-label="a dense table">
            {tabla}
          </Table>
        ),
      });
    });
  };

  seleccionar(idDiv, fecha) {
    const { arregloDias, arregloTemp, arregloSum, arregloInicio, arregloTermino } = this.state;

    var link = document.getElementById(idDiv);

    if (arregloDias.includes(fecha)) {
      if (arregloTemp.length == 1) {
        this.setState({ arregloTemp: [], arregloSum: [] });

        arregloTermino.push(fecha);
        link.innerHTML = '<i class="fa fa-check-circle-o" style="color: #ffffff" aria-hidden="true"></i> <label style="color:#ffffff;cursor: pointer;">Horario 1 cupo</label>';
        link.style.backgroundColor = "rgb(38, 165, 48)";
        link.style.borderColor = "rgb(38, 165, 48)";
      } else {
        link.innerHTML = '<label class="textoBotonesCarga">Seleccionar</label>';
        link.style.backgroundColor = "#fff0";
        link.style.borderColor = "#000";
        var index = arregloDias.indexOf(fecha);
        arregloDias.splice(index, 1);

        var indexSum = arregloSum.indexOf(fecha);
        arregloSum.splice(indexSum, 1);

        var indexIni = arregloInicio.indexOf(fecha);
        arregloInicio.splice(indexIni, 1);

        var indexTer = arregloTermino.indexOf(fecha);
        arregloTermino.splice(indexTer, 1);
      }
    } else {
      arregloDias.push(fecha);
      arregloSum.push(fecha);

      if (arregloSum.length > 0 && arregloSum.indexOf(fecha) == 0) {
        arregloTemp.push(fecha);
        if (arregloTemp.length > 2) {
          arregloTemp = [];
        }
        arregloInicio.push(fecha);
        link.innerHTML = '<i class="fa fa-check-circle-o" style="color: #ffffff"></i> <label style="color:#ffffff;cursor: pointer;">Inicio</label>';
        link.style.backgroundColor = "#2653f9";
        link.style.borderColor = "#2653f9";
      } else {
        arregloTermino.push(fecha);
        link.innerHTML = '<i class="fa fa-check-circle-o" style="color: #ffffff"></i> <label style="color:#ffffff;cursor: pointer;">Termino</label>';
        link.style.backgroundColor = "#bd2525";
        link.style.borderColor = "#bd2525";

        this.setState({ arregloSum: [], arregloTemp: [] });
      }
    }
  }

  horariosParaCargar = () => {
    this.setState({
      btnCargarHorarioDisabled: true,
    });

    const { arregloHorario, arregloInicio, arregloTermino, rendimiento } = this.state;

    if (arregloInicio.length != 0) {
      var fechaInicio = arregloInicio[0];
    }

    for (var x = 0; x < arregloInicio.length; x++) {
      var fechaInicio = arregloInicio[x];
      var fechaTermino = arregloTermino[x];

      if (fechaInicio == fechaTermino) {
        arregloHorario.push(fechaInicio);
      } else {
        var fecha = fechaInicio;

        var minutosAsumar = parseFloat(rendimiento);

        arregloHorario.push(fecha);
        do {
          fecha = moment(fecha).add(minutosAsumar, "minutes").format("YYYY-MM-DD HH:mm:ss");
          arregloHorario.push(fecha);
        } while (fecha < fechaTermino);
      }
    }

    if (arregloInicio.length != arregloTermino.length) {
      this.mostrarAlerta("Cada inicio debe tener su término, revise los dias a cargar");

      this.setState({
        arregloHorario: [],
        btnCargarHorarioDisabled: false,
      });
    } else {
      if (this.state.direcciones.length > 0) {
        this.validar_direcciones();
      } else {
        this.enviarFormulario();
      }
    }
  };

  validar_direcciones = () => {
    var data = {
      horarios: this.state.arregloHorario,
      profesional: this.state.proId,
      cesfam: this.state.cesfam,
      direccion: this.state.direccion,
    };

    let apiUrl = global.config.modulos.agendamientos + "revisar_direcciones/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      var data = res.data;
      var estado = data.estado;
      if (estado) {
        this.enviarFormulario();
      } else {
        confirmAlert({
          onClickOutside: () => {
            this.setState({
              arregloHorario: [],
              btnCargarHorarioDisabled: false,
            });
          },
          title: "",
          message: data.mensaje,
          buttons: [
            {
              label: "No",
              className: "btnCancelar",
              onClick: () =>
                this.setState({
                  arregloHorario: [],
                  btnCargarHorarioDisabled: false,
                }),
            },
            {
              label: "Sí",
              onClick: () => this.enviarFormulario(),
              className: "btnAceptar",
            },
          ],
        });
      }
    });
  };

  enviarFormulario = (e) => {
    var horarios = this.state.arregloHorario;
    if (horarios.length == 0) {
      this.mostrarAlerta("No tienes horarios para cargar");
      this.setState({
        arregloHorario: [],
        btnCargarHorarioDisabled: false,
      });
    } else {
      var tipo = 1;
      var estado = 3;
      var pausada = 1;

      var prioridad = 6;
      var motivoBloqueo = "";
      if (this.state.motivoBloqueo == "Activa") {
        motivoBloqueo = null;
      } else if (this.state.motivoBloqueo == "otro") {
        estado = 0;
        pausada = 0;
        motivoBloqueo = this.state.motivoBloqueoTxt;
      } else {
        estado = 0;
        pausada = 0;
        motivoBloqueo = this.state.motivoBloqueo;
      }

      if (this.state.motivoBloqueo == "Cambios de hora") {
        prioridad = 12;
        estado = 1;
        pausada = 0;
        motivoBloqueo = null;
      }

      var sem = this.state.semana;
      var arrSemana = sem.split("-W");

      var nSemana = arrSemana[1];
      var añoPorNSemana = arrSemana[0];

      var fechaInicio = moment().years(añoPorNSemana).isoWeek(nSemana).startOf("isoweek").format("YYYY-MM-DD");

      var fechaTermino = moment().years(añoPorNSemana).isoWeek(nSemana).endOf("isoweek").format("YYYY-MM-DD");

      var data = {
        fechaInicio: fechaInicio,
        fechaTermino: fechaTermino,
        horarios: this.state.arregloHorario,
        especialidad: this.state.tipoatencion,
        tipo: tipo,
        estado: estado,
        pausada: pausada,
        prioridad: prioridad,
        profesional: this.state.proId,
        motivoBloqueo: motivoBloqueo,
        sector: this.state.sector,
        cesfam: this.state.cesfam,
        direccion: this.state.direccion,
        rendimiento: parseInt(this.state.rendimiento),
        usuariopanel: this.state.uspId,
        fecharegistro: moment().format("YYYY-MM-DD HH:mm:ss"),
      };

      let apiUrl = global.config.modulos.agendamientos + "cargar_horario_semanal/";

      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };

      axios(apiUrl, options).then((res) => {
        var data = res.data;
        var estado = data.estado;
        if (estado) {
          this.mostrarAlerta(data.mensaje);
          this.horarios(this.state.rendimiento, this.state.semana, this.state.tiempoInicio, this.state.tiempoTermino);

          this.setState({
            motivoBloqueo: "Activa",
            mostrarMotivoTxt: false,
            arregloHorario: [],
            arregloDias: [],
            arregloTemp: [],
            arregloSum: [],
            arregloInicio: [],
            arregloTermino: [],
            tabla: "",
            btnCargarHorarioDisabled: false,
          });
        } else {
          this.mostrarAlerta(data.mensaje);

          this.setState({
            arregloHorario: [],
            btnCargarHorarioDisabled: false,
          });
        }
      });
    }
  };

  desbloquearHorario(horId) {
    var data = {
      hor_estado: 1,
    };

    let apiUrl = global.config.modulos.agendamientos + "horario_por_id/" + horId;

    const options = {
      method: "PATCH",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      this.setState({
        motivoBloqueo: "Activa",
        mostrarMotivoTxt: false,
        arregloHorario: [],
        arregloDias: [],
        arregloTemp: [],
        arregloSum: [],
        arregloInicio: [],
        arregloTermino: [],
        tabla: "",
      });

      this.horarios(this.state.rendimiento, this.state.semana, this.state.tiempoInicio, this.state.tiempoTermino);
    });
  }

  eliminarHorario(horId) {
    var arregloHorEl = [];
    arregloHorEl.push(horId);

    var data = {
      horarios: arregloHorEl,
      usp_id: this.state.uspId,
    };

    let apiUrl = global.config.modulos.agendamientos + "eliminar_horarios/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      if (res.data.mensaje == "eliminadoExito") {
        this.setState({
          motivoBloqueo: "Activa",
          mostrarMotivoTxt: false,
          arregloHorario: [],
          arregloDias: [],
          arregloTemp: [],
          arregloSum: [],
          arregloInicio: [],
          arregloTermino: [],
          tabla: "",
        });

        this.horarios(this.state.rendimiento, this.state.semana, this.state.tiempoInicio, this.state.tiempoTermino);
        NotificationManager.success("Se han eliminado existosamente la horas");
      } else if (res.data.mensaje == "horarioAgendado") {
        NotificationManager.warning("No se pueden eliminar horas reservadas");
      }
    });
  }

  descargarHorario = () => {
    var sem = this.state.semana;
    var arrSemana = sem.split("-W");

    var nSemana = arrSemana[1];
    var añoPorNSemana = arrSemana[0];

    var fechaInicio = moment().years(añoPorNSemana).isoWeek(nSemana).startOf("isoweek").format("YYYY-MM-DD");

    var fechaTermino = moment().years(añoPorNSemana).isoWeek(nSemana).endOf("isoweek").format("YYYY-MM-DD");

    var data = {
      fechaInicio: fechaInicio,
      fechaTermino: fechaTermino,
      cesfam: this.state.cesfam,
      profesional: this.state.proId,
    };

    let apiUrl = global.config.modulos.agendamientos + "excel_carga_masiva_semana/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      var data = res.data;

      var estado = data.estado;
      if (estado) {
        window.open(res.data.ruta, "_blank").focus();
      } else {
        this.mostrarAlerta(data.mensaje);
      }
    });
  };

  ocultarCambiarRendimiento = () => {
    this.setState({
      cambiarRendimiento: false,
    });
  };

  ocultarExitoCambiarRendimiento = () => {
    this.setState({
      cambiarRendimiento: false,
    });

    this.horarios(this.state.rendimiento, this.state.semana, this.state.tiempoInicio, this.state.tiempoTermino);
  };

  ocultarDuplicarHorario = () => {
    this.setState({
      duplicarHorario: false,
    });
  };

  ocultarDuplicarHorarioActualizado = () => {
    this.setState({ duplicarHorario: false });
    this.horarios(this.state.rendimiento, this.state.semana, this.state.tiempoInicio, this.state.tiempoTermino);
  };

  accionesCarga = (horario, especialidad) => {
    const apiUrl = global.config.modulos.agendamientos + "configuracion_duplicar_horario/?cesfam=" + this.state.cesfam + "&especialidad=" + especialidad;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var data = res.data;

      if (this.state.herramientas.includes("H43") && this.state.herramientas.includes("H42") && data.estado) {
        this.setState({
          accionesCarga: true,
          horarioCam: horario,
          especialidadCam: especialidad,
        });
      } else if (this.state.herramientas.includes("H42") && data.estado) {
        this.setState({
          duplicarHorario: true,
          horarioCam: horario,
        });
      } else if (this.state.herramientas.includes("H43")) {
        this.setState({
          cambiarRendimiento: true,
          horarioCam: horario,
          especialidadCam: especialidad,
        });
      }
    });
  };

  ocultarAccionesCarga = () => {
    this.setState({
      accionesCarga: false,
    });

    this.horarios(this.state.rendimiento, this.state.semana, this.state.tiempoInicio, this.state.tiempoTermino);
  };

  render() {
    const { direcciones, direccion, motivosBloqueo, motivoBloqueo, motivoBloqueoTxt, tiposatenciones, tipoatencion, sectores, sector, rendimientos, rendimiento, tiempoInicio, tiempoTermino, semana, disabled, btnCargarHorarioDisabled } = this.state;

    const cambioTipoAtencion = (event) => {
      this.setState({
        tipoatencion: event.target.value,
      });
      this.traerRendimientos(event.target.value, 1);
    };

    const cambioSector = (event) => {
      this.setState({
        sector: event.target.value,
      });
    };

    const cambioRendimiento = (event) => {
      this.setState({
        rendimiento: event.target.value,
        arregloHorario: [],
        arregloDias: [],
        arregloTemp: [],
        arregloSum: [],
        arregloInicio: [],
        arregloTermino: [],
        tabla: "",
      });

      this.horarios(event.target.value, this.state.semana, this.state.tiempoInicio, this.state.tiempoTermino);
    };

    const cambioMotivoBloqueo = (event) => {
      if (event.target.value == "otro") {
        this.setState({
          motivoBloqueo: event.target.value,
          mostrarMotivoTxt: true,
        });
      } else {
        this.setState({
          motivoBloqueo: event.target.value,
          mostrarMotivoTxt: false,
        });
      }
    };

    const cambiarSemana = (event) => {
      var semana = event.target.value;
      if (semana != "") {
        this.setState({
          semana: semana,
          arregloHorario: [],
          arregloDias: [],
          arregloTemp: [],
          arregloSum: [],
          arregloInicio: [],
          arregloTermino: [],
          tabla: "",
        });

        this.horarios(this.state.rendimiento, semana, this.state.tiempoInicio, this.state.tiempoTermino);
      }
    };

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div className="cargarHorario" style={{ marginBottom: 200, marginTop: "2vw" }}>
              <div>
                <React.Fragment>
                  <Grid container spacing={6}>
                    <Grid item xs={12} sm={5} md={4} lg={3} xl={2}>
                      <div style={{ width: "48%", float: "left" }}>
                        <p>
                          <label
                            style={{
                              color: "rgba(0, 0, 0, 0.54)",
                              marginLeft: 12,
                            }}
                          >
                            Hora inicio
                          </label>
                        </p>
                        <TimePicker minuteStep={5} showSecond={false} name="tiempoInicio" id="tiempoInicio" disabled={this.state.cambiarHoras} value={tiempoInicio} onChange={(e) => this.cambiarValoresHora(e, "inicio")} />
                      </div>
                      <div>
                        <p>
                          <label
                            style={{
                              color: "rgba(0, 0, 0, 0.54)",
                              marginLeft: 12,
                            }}
                          >
                            Hora Termino
                          </label>
                        </p>
                        <TimePicker minuteStep={5} showSecond={false} name="tiempoTermino" id="tiempoTermino" disabled={this.state.cambiarHoras} value={tiempoTermino} onChange={(e) => this.cambiarValoresHora(e, "termino")} />
                      </div>
                    </Grid>

                    <React.Fragment>
                      <Grid item xs={12} sm={5} md={4} lg={2} style={{ float: "left" }}>
                        <InputLabel>Tipo de Atención</InputLabel>
                        <Select disabled={disabled} name="tipoatencion" className={"selectSimple"} value={tipoatencion} style={{ width: "100%" }} onChange={cambioTipoAtencion} displayEmpty>
                          {tiposatenciones &&
                            tiposatenciones.map((ta, index) => (
                              <MenuItem value={ta["esp_id"]} key={index}>
                                {ta["esp_nombre"]}
                              </MenuItem>
                            ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={2} style={{ float: "left" }}>
                        <InputLabel>Sectores</InputLabel>
                        <Select disabled={disabled} name="sector" className={"selectSimple"} value={sector} style={{ width: "100%" }} onChange={cambioSector} displayEmpty>
                          {sectores &&
                            sectores.map((sec, index) => (
                              <MenuItem value={sec.sec_id} key={index}>
                                {sec.sec_nombre}
                              </MenuItem>
                            ))}
                        </Select>
                      </Grid>

                      {direcciones.length > 0 && (
                        <Grid item xs={12} sm={4} md={4} lg={2} style={{ float: "left" }}>
                          <InputLabel>Dirección</InputLabel>
                          <Select disabled={disabled} name="direccion" className={"selectSimple"} value={direccion} style={{ width: "100%" }} onChange={this.cambiarValores} displayEmpty>
                            {direcciones.map((dir, index) => (
                              <MenuItem value={dir.hds_id} key={index}>
                                {dir.hds_direccion}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      )}

                      <Grid item xs={12} sm={4} md={4} lg={2} style={{ float: "left" }}>
                        <InputLabel>Motivo Bloqueo</InputLabel>
                        <Select disabled={disabled} name="motivoBloqueo" className={"selectSimple"} value={motivoBloqueo} onChange={cambioMotivoBloqueo} style={{ width: "100%" }} displayEmpty>
                          {motivosBloqueo &&
                            motivosBloqueo.map((mb, index) => (
                              <MenuItem value={mb.mb_descripcion} key={index}>
                                {mb.mb_nombre}
                              </MenuItem>
                            ))}
                        </Select>
                      </Grid>
                      {this.state.mostrarMotivoTxt ? (
                        <Grid item xs={12} sm={4} md={4} lg={2} style={{ float: "left" }}>
                          <TextareaAutosize name="motivoBloqueoTxt" aria-label="minimum height" className={"selectSimple"} rowsMin={3} style={{ width: "100%" }} placeholder="Escribe el motivo..." value={motivoBloqueoTxt} onChange={this.cambiarValores} />
                        </Grid>
                      ) : null}
                      <Grid item xs={12} sm={4} md={4} lg={2} style={{ float: "left" }}>
                        <InputLabel>Rendimiento</InputLabel>
                        <Select disabled={disabled} name="rendimiento" className={"selectSimple"} value={rendimiento} style={{ width: "100%" }} onChange={cambioRendimiento} displayEmpty>
                          {rendimientos &&
                            rendimientos.map((ren, index) => (
                              <MenuItem value={ren.esr_rendimiento} key={index}>
                                {ren.esr_rendimiento}
                              </MenuItem>
                            ))}
                        </Select>
                      </Grid>

                      <Grid item xs={12} sm={4} md={4} lg={2} style={{ float: "left" }}>
                        <InputLabel>Semana del horario</InputLabel>
                        <TextField id="semana" name="semana" type="week" disabled={disabled} value={semana} onChange={cambiarSemana} />
                      </Grid>
                      <br></br>

                      {!this.state.cargahorario ? (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          style={{
                            float: "right",
                            marginTop: 0,
                            paddingTop: 0,
                          }}
                        >
                          <Button style={{ marginLeft: 20, float: "right" }} variant="contained" onClick={this.cancelarCrearHorarios} color="primary" className="fondoNaranjo">
                            Limpiar
                          </Button>
                          <Button variant="contained" color="primary" onClick={this.crearHorarios} disabled={btnCargarHorarioDisabled} className="fondoVerde" style={{ float: "right" }}>
                            Crear Horario
                          </Button>
                        </Grid>
                      ) : null}

                      {this.state.cargahorario ? (
                        <Grid item xs={12} sm={12} md={12} className="cargaHorarioOpciones">
                          <br></br>
                          <br></br>
                          <Grid item xs={12} sm={12} md={12} style={{ float: "right", marginBottom: 20 }}>
                            <Button variant="contained" color="primary" onClick={this.mensajeCopiarUltimaCarga.bind(this, 2)} className="fondoNaranjo">
                              Repetir semana
                            </Button>

                            <Button style={{ marginLeft: 20 }} variant="contained" color="primary" onClick={this.mensajeCopiarUltimaCarga.bind(this, 1)} className="fondoNaranjo">
                              Copiar semana
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <div
                              id="tablahorarioProfesional"
                              style={{
                                marginTop: "20px",
                              }}
                            >
                              <TableContainer>{this.state.tabla}</TableContainer>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} style={{ float: "right", marginTop: 20 }}>
                            <Button style={{ marginRight: 20 }} variant="contained" color="primary" onClick={this.descargarHorario} disabled={btnCargarHorarioDisabled} className="fondoVerde">
                              Descargar horario
                            </Button>

                            <Button variant="contained" color="primary" onClick={this.horariosParaCargar} disabled={btnCargarHorarioDisabled} className="fondoVerde">
                              Cargar horario
                            </Button>

                            <Button style={{ marginLeft: 20 }} variant="contained" onClick={this.cancelarCrearHorarios} color="primary" className="fondoNaranjo">
                              Limpiar
                            </Button>
                          </Grid>
                        </Grid>
                      ) : null}
                    </React.Fragment>
                  </Grid>
                </React.Fragment>
              </div>
              {this.state.copiarDia ? <Diario profesional={this.state.proId} fechaHorario={this.state.fechaHorario} ocultar={this.ocultarCopiaDia} ocultarCopiaDiaActualizado={this.ocultarCopiaDiaActualizado}></Diario> : null}
              {this.state.copiarSemana ? <Semanal profesional={this.state.proId} nsemana={this.state.semana} tipoCarga={this.state.tipoCarga} ocultar={this.ocultarCopiaSemana} ocultarCopiaSemanaActualizado={this.ocultarCopiaSemanaActualizado}></Semanal> : null}
              {this.state.repetirSemana ? <Clonar profesional={this.state.proId} semana={this.state.semana} tipoCarga={this.state.tipoCarga} ocultar={this.ocultarRepetirSemana} ocultarRepetirSemanaActualizado={this.ocultarRepetirSemanaActualizado}></Clonar> : null}
              {this.state.cambiarRendimiento ? <CambiarRendimiento horario={this.state.horarioCam} especialidad={this.state.especialidadCam} ocultar={this.ocultarCambiarRendimiento} ocultarExito={this.ocultarExitoCambiarRendimiento}></CambiarRendimiento> : null}
              {this.state.duplicarHorario ? <DuplicarHorario horario={this.state.horarioCam} ocultar={this.ocultarDuplicarHorario} ocultarActualizado={this.ocultarDuplicarHorarioActualizado}></DuplicarHorario> : null}
              {this.state.accionesCarga ? <AccionesCarga horario={this.state.horarioCam} especialidad={this.state.especialidadCam} ocultar={this.ocultarAccionesCarga}></AccionesCarga> : null}
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
