import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";

// core components
import GridItem from "../grid/GridItem.js";
import GridContainer from "../grid/GridContainer.js";
import chileanRut from "chilean-rut";
import Titulo from "../titulo/Titulo.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import MaterialTable from "material-table";
import Paginacion from "../Table/Pagination.js";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Buttons from "react-multi-date-picker/components/button";
import { Dialog } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { DialogContentText } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import InformacionUsuario from "../Usuarios/InformacionUsuario";

export default class VistaUsuarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      lapid: props.lap_id,
      prioDetalle: [],
      usuario: props.usuario,
      mostrarInfo: true,
    };
    console.log(this.state.usuario);
    //console.log(this.state.especialidad);
  }

  salir = () => {
    this.setState({
      open: false,
      mostrarInfo: false,
    });
    this.props.ocultar();
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="buscarUsuarioGestion" fullWidth maxWidth="8000px">
          <DialogContent>
            <DialogContentText style={{ fontSize: "30px", fontWeight: "bold" }}>Paciente</DialogContentText>
            {this.state.mostrarInfo ? <InformacionUsuario onClose={this.salir} usu_id={this.state.usuario} modificarRutNombre={null} /> : null}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
