import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { NotificationManager } from "react-notifications";
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import graf from "assets/css/graf.module.css";
import Button from "@material-ui/core/Button";

export default class GraficosProfesionalesComuna extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fecha_actual: props.fecha_actual,
      fecha_inicio: props.fecha_inicio,
      fecha_termino: props.fecha_termino,
      comuna: props.cliente,
      especialidad: props.especialidad,
      largoGraf: "110rem",
      datos: [],
      grafModal: false,
      datas: [],
      cesfam: props.cesfams,
      tipo: props.tipo,
      cesdatos: props.cesdatos,
      acumulado: props.acumulado,
      cesfamsito: "",
      profesionalito: "",
    };
  }
  closeModal = (e) => {
    if (e.target === e.currentTarget) {
      this.setState({
        grafModal: false,
      });
    }
  };

  grafico_profesionales() {
    this.setState({ datas: [] });
    axios
      .get(
        global.config.modulos.grafico_demanda + "grafica_profesionales_comuna/",
        {
          headers: authHeader(),
          params: {
            cliente: this.state.comuna,
            fecha_actual: this.state.fecha_actual,
            fecha_inicio: this.state.fecha_inicio,
            fecha_termino: this.state.fecha_termino,
            especialidad: this.state.especialidad,
            ces: this.state.cesfam,
            tipo: this.state.tipo,
            cesdatos: this.state.cesdatos,
            acumulado: this.state.acumulado,
          },
        }
      )
      .then((res) => {
        if (!res.data.estado) {
          if (
            this.state.fecha_inicio > this.state.fecha_termino &&
            this.state.fecha_actual == "" &&
            this.state.acumulado == false
          ) {
            NotificationManager.info(
              "La fecha Final de la busqueda es menor a la inicial"
            );
            return;
          }
          return;
        }
        this.setState({ datas: res.data.datos }, () => {
          try {
            this.state.root.dispose();
          } catch (e) {}

          let root = am5.Root.new("chartdiv1");
          root.setThemes([am5themes_Animated.new(root)]);

          root._logo.dispose();

          let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
              layout: root.verticalLayout,
            })
          );

          let legend = chart.children.push(
            am5.Legend.new(root, {
              centerX: am5.p50,
              x: am5.p50,
            })
          );

          let data = this.state.datas;

          if (this.state.datas.length < 3) {
            this.setState({ largoGraf: "1.5rem" });
          }
          if (this.state.datas.length < 6) {
            this.setState({ largoGraf: "20rem" });
          }
          if (this.state.datas.length > 5 && this.state.datas.length < 11) {
            this.setState({ largoGraf: "35rem" });
          }
          if (this.state.datas.length > 10 && this.state.datas.length < 20) {
            this.setState({ largoGraf: "50rem" });
          }
          if (this.state.datas.length > 19 && this.state.datas.length < 26) {
            this.setState({ largoGraf: "64rem" });
          }
          if (this.state.datas.length > 25 && this.state.datas.length < 33) {
            this.setState({ largoGraf: "75rem" });
          }
          if (this.state.datas.length > 33 && this.state.datas.length < 40) {
            this.setState({ largoGraf: "87rem" });
          }
          if (this.state.datas.length > 40 && this.state.datas.length < 47) {
            this.setState({ largoGraf: "93rem" });
          }
          if (this.state.datas.length > 47 && this.state.datas.length < 57) {
            this.setState({ largoGraf: "120rem" });
          }
          if (this.state.datas.length > 57 && this.state.datas.length < 200) {
            this.setState({ largoGraf: "170rem" });
          }

          let yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
              categoryField: "Nombre",
              renderer: am5xy.AxisRendererY.new(root, {
                inversed: true,
                cellStartLocation: 0.1,
                cellEndLocation: 0.9,
                minGridDistance: 19,
                minorGridEnabled: true,
              }),
            })
          );

          yAxis.data.setAll(data);

          let xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
              renderer: am5xy.AxisRendererX.new(root, {
                strokeOpacity: 0.1,
                minGridDistance: 50,
              }),
              min: 0,
            })
          );

          var cursor = chart.set(
            "cursor",
            am5xy.XYCursor.new(root, {
              yAxis: yAxis,
            })
          );
          cursor.lineX.set("visible", false);

          let thiss = this;

          function createSeries(field, name) {
            let series = chart.series.push(
              am5xy.ColumnSeries.new(root, {
                name: name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: field,
                categoryYField: "Nombre",
                sequencedInterpolation: true,
                tooltip: am5.Tooltip.new(root, {
                  pointerOrientation: "horizontal",
                  labelText:
                    "[bold]{name}[/]\nProfesional: {NombreP}\nProfesion: {Profesion}\nPacientes: {valueX}\nCesfam: {Cesfam}",
                  autoTextColor: false,
                }),
              })
            );

            series.columns.template.setAll({
              height: am5.p100,
              strokeOpacity: 0,
            });

            series.bullets.push(function () {
              return am5.Bullet.new(root, {
                locationX: 0.99,
                locationY: 0.5,
                sprite: am5.Label.new(root, {
                  centerY: am5.p50,
                  text: "{valueX}",
                  populateText: true,
                  fontSize: 13,
                }),
              });
            });

            series.bullets.push(function () {
              return am5.Bullet.new(root, {
                locationX: 1,
                locationY: 0.5,
                sprite: am5.Label.new(root, {
                  centerX: am5.p100,
                  centerY: am5.p50,
                  text: "{name}",
                  fill: am5.color(0xffffff),
                  populateText: true,
                }),
              });
            });

            series.columns.template.adapters.add(
              "fill",
              function (fill, target) {
                return chart
                  .get("colors")
                  .getIndex(series.columns.indexOf(target));
              }
            );

            series.columns.template.adapters.add(
              "stroke",
              function (stroke, target) {
                return chart
                  .get("colors")
                  .getIndex(series.columns.indexOf(target));
              }
            );

            series.columns.template.events.on("click", function (ev) {
              console.log(ev.target.dataItem.dataContext);
              console.log(ev.target.dataItem.dataContext.id_pro);
              console.log(ev.target.dataItem.dataContext.id_profesion);
              console.log(ev.target.dataItem.dataContext.ces_id);
              thiss.setState(
                {
                  datos: [],
                  grafModal: true,
                  cesfamsito: ev.target.dataItem.dataContext.ces_id,
                  profesionalito: ev.target.dataItem.dataContext.id_pro,
                },
                () => {
                  axios
                    .get(
                      global.config.modulos.grafico_demanda +
                        "grafico_circular_profesionales/",
                      {
                        headers: authHeader(),
                        params: {
                          profesional: ev.target.dataItem.dataContext.id_pro,
                          cesdatos: thiss.state.cesdatos,
                          esp: ev.target.dataItem.dataContext.id_profesion,
                          fecha_actual: thiss.state.fecha_actual,
                          fecha_inicio: thiss.state.fecha_inicio,
                          fecha_termino: thiss.state.fecha_termino,
                          cesfam: ev.target.dataItem.dataContext.ces_id,
                          acumulado: thiss.state.acumulado,
                        },
                      }
                    )
                    .then((resp) => {
                      try {
                        this.state.root1.dispose();
                      } catch (e) {}

                      if (!resp.data.estado) {
                        NotificationManager.info(
                          "No hay datos para el dia actual por el momento"
                        );
                      } else {
                        thiss.setState(
                          {
                            datos: resp.data.datos1,
                          },
                          () => {
                            let root1 = am5.Root.new("chartdiv");

                            root1._logo.dispose();

                            root1.setThemes([am5themes_Animated.new(root1)]);

                            let chart1 = root1.container.children.push(
                              am5percent.PieChart.new(root1, {
                                endAngle: 270,
                                layout: root1.horizontalLayout,
                                radius: am5.percent(70),
                              })
                            );

                            let series1 = chart1.series.push(
                              am5percent.PieSeries.new(root1, {
                                valueField: "Total",
                                categoryField: "Categoria",
                                endAngle: 270,
                                tooltip: am5.Tooltip.new(root1, {
                                  autoTextColor: false,
                                  labelText:
                                    "{category}: [bold]{value} Horas[/]",
                                }),
                              })
                            );
                            var legend1 = chart1.children.push(
                              am5.Legend.new(root1, {
                                centerY: am5.percent(50),
                                y: am5.percent(50),
                                layout: root1.verticalLayout,
                              })
                            );

                            series1
                              .get("colors")
                              .set("colors", [
                                am5.color("#FF9327"),
                                am5.color("#ef4597"),
                                am5.color("#4fc9ba"),
                                am5.color("#225093"),
                                am5.color("#23bae8"),
                                am5.color("#8156c1"),
                                am5.color("#71d3a4"),
                                am5.color("#f45175"),
                                am5.color("#e8c42a"),
                                am5.color("#caa2f4"),
                              ]);

                            series1.slices.template.states.create("active", {
                              shiftRadius: 1,
                              strokeWidth: 2,
                            });

                            series1.labels.template.setAll({
                              fontSize: 14,
                              fill: am5.color("#808080"),
                              text: " [bold]{category}[/]\n {valuePercentTotal.formatNumber('0.00')}%\n {value}",
                              textAlign: "left",
                              maxWidth: 150,
                            });

                            series1.slices.template.setAll({
                              stroke: am5.color(0xffffff),
                              strokeWidth: 4,
                            });

                            series1.labels.template.adapters.add(
                              "y",
                              function (y, target) {
                                let dataItem = target.dataItem;
                                if (dataItem) {
                                  var tick = dataItem.get("tick");
                                  if (tick) {
                                    if (y < -chart.height() / 2) {
                                      tick.set("forceHidden", true);
                                    } else {
                                      tick.set("forceHidden", false);
                                    }
                                  }
                                  return y;
                                }
                              }
                            );

                            series1.slices.template.events.on(
                              "click",
                              function (ev) {
                                series1.slices.each(function (slice) {
                                  if (
                                    slice != ev.target &&
                                    slice.get("active")
                                  ) {
                                    slice.set("active", false);
                                  }
                                });
                              }
                            );

                            series1.data.setAll(thiss.state.datos);
                            legend1.data.setAll(series1.dataItems);

                            let exporting1 = am5plugins_exporting.Exporting.new(
                              root1,
                              {
                                menu: am5plugins_exporting.ExportingMenu.new(
                                  root1,
                                  {}
                                ),
                                pngOptions: {
                                  quality: 0.8,
                                  maintainPixelRatio: true,
                                },
                                htmlOptions: {
                                  disabled: true,
                                },
                                pdfdataOptions: {
                                  disabled: true,
                                },
                                jsonOptions: {
                                  disabled: true,
                                },
                                printOptions: {
                                  disabled: true,
                                },
                                pdfOptions: {
                                  addURL: false,
                                  includeData: true,
                                  quality: 1,
                                  addColumnNames: true,
                                },

                                dataSource: thiss.state.datos,
                                filePrefix: "DatosGraficoComuna",
                              }
                            );
                            series1.appear(1000, 100);
                          }
                        );
                      }
                    });
                }
              );
            });

            chart.get("colors").set("colors", [am5.color("#47BAA4")]);

            series.data.setAll(data);
            series.appear();

            return series;
          }

          createSeries("Total_Pacientes", "Inicio tratamiento");

          legend.data.setAll(chart.series.values);

          let exporting = am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {}),
            pngOptions: {
              quality: 0.8,
              maintainPixelRatio: true,
            },
            htmlOptions: {
              disabled: true,
            },
            pdfdataOptions: {
              disabled: true,
            },
            jsonOptions: {
              disabled: true,
            },
            printOptions: {
              disabled: true,
            },
            pdfOptions: {
              addURL: false,
              includeData: true,
              quality: 1,
              addColumnNames: true,
            },

            dataSource: res.data.datos1,
            filePrefix: "DatosGraficoComuna",
          });

          chart.appear(1000, 100);
        });
      });
  }
  componentDidMount() {
    this.grafico_profesionales();
  }
  render() {
    return (
      <div style={{ zIndex: 45, width: "88%" }}>
        <div
          style={{
            overflowY: "scroll",
            overflowX: "scroll",
            width: "80%",
            height: "70%",
            position: "fixed",
            textAlign: "center",
            left: "7%",
          }}
        >
          <div
            id="chartdiv1"
            style={{
              width: "90%",
              height: this.state.largoGraf,
              marginTop: "50px",
            }}
          ></div>
        </div>
        {this.state.grafModal && (
          <div className={graf.modal} onClick={(e) => this.closeModal(e)}>
            <div
              className={graf.modalcontent}
              onClick={(e) => e.stopPropagation()}
            >
              <h3
                style={{
                  color: "rgb(102, 102, 102)",
                  fontSize: "1.5rem",
                  position: "relative",
                  left: "5%",
                }}
              >
                Totales de altas e ingresos de pacientes
              </h3>
              <div
                id="chartdiv"
                style={{
                  position: "relative",
                  left: "2%",
                  width: "90%",
                  height: "60%",
                }}
              ></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
