import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";
import moment from "moment";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import { store } from "../../store/index";
import { NotificationManager } from "react-notifications";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class PacientesAtendidos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fechaAyerF: moment().subtract(1, "day").format("DD-MM-YYYY"),
      uspId: store.getState().usp,
      tickets: props.tickets,
      open: true,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
          <DialogContent>
            <h1>¡Atención!</h1>
            <br></br>
            <br></br>

            <div className="contenedorCantPac">
              <label className={"cantidadPacientes"}>{this.state.tickets}</label>
              <label className={"textCantPac"}>pacientes fueron atendidos ayer {this.state.fechaAyerF}</label>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
