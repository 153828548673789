import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { confirmAlert } from "react-confirm-alert"; // Import
import { store } from "./../../store/index";

import axios from "axios";

// core components
import { Grid } from "@material-ui/core";
import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";
import AgregarUsuario from "components/Usuarios/AgregarUsuario";

import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import chileanRut from "chilean-rut";
import ModalExisteCaso from "./ModalExisteCaso";
import crearTotem from "assets/css/crearTotem.module.css";

import { NotificationManager } from "react-notifications";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";

// estilos
import "assets/css/general.css";
import "assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class AgregarCaso extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canal: 5,
      uspId: store.getState().usp,
      cliente: store.getState().cliente,
      cesfam: store.getState().cesfam,

      gestion: props.gegId,
      tipo: props.tipo,

      open: true,
      rut: null,

      agregarUsuario: false,

      motivo: "",
      especialidades: [],
      especialidad: null,

      modalExisteCaso: false,
      mensajeAlerta: [],

      posicion: null,
      formErrors: {},
    };
  }

  componentDidMount = () => {
    this.traerEspecialidades();
  };

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar(this.state.posicion);
  };

  ocultarExisteCaso = () => {
    this.setState({ modalExisteCaso: false });
    this.salir();
  };

  cambiarValores = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  ocultarAgregarUsuario = () => {
    this.setState({ agregarUsuario: false });
    this.crearCaso();
  };

  crearCaso = () => {
    axios
      .post(
        global.config.modulos.demanda + "crear_demanda_manual/",
        {
          cesfam: this.state.cesfam,
          rut: this.state.rut,
          usuario_panel: this.state.uspId,
          motivo: this.state.motivo,
          especialidad: this.state.especialidad,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Caso agregado con éxito");
          this.setState({ posicion: 1 }, () => {
            this.salir();
          });
        }
      });
  };

  validaciones() {
    const { rut, motivo, especialidad } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (!rut) {
      formIsValid = false;
      formErrors["rutErr"] = "Escribe el rut.";
    } else {
      if (!chileanRut.validate(rut)) {
        formIsValid = false;
        formErrors["rutErr"] = "Escribe el rut valido.";
      }
    }

    if (motivo == null || motivo == "") {
      formIsValid = false;
      formErrors["motivoErr"] = "Escribe el motivo.";
    }

    if (especialidad == null || especialidad == "") {
      formIsValid = false;
      formErrors["especialidadErr"] = "Selecciona la especialidad.";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  enviarFormulario = (crearDirecto) => {
    if (crearDirecto) {
      return this.crearCaso();
    }

    if (this.validaciones()) {
      axios
        .get(global.config.modulos.demanda + "gestion_usuario/", {
          headers: authHeader(),
          params: {
            rut: this.state.rut,
            cesfam: this.state.cesfam,
          },
        })
        .then((res) => {
          if (!res.data.estado) {
            this.setState({
              mensajeAlerta: res.data.mensaje,
              modalExisteCaso: true,
            });
            return;
          }

          if (res.data.cesfam) {
            confirmAlert({
              title: "",
              message: res.data.mensaje + ", desea continuar?",
              buttons: [
                {
                  label: "Cancelar",
                  className: "btnCancelar",
                },
                {
                  label: "Aceptar",
                  onClick: () => this.crearCaso(),
                  className: "btnAceptar",
                },
              ],
            });
          } else {
            confirmAlert({
              title: "",
              message: res.data.mensaje + ", desea continuar?",
              buttons: [
                {
                  label: "Cancelar",
                  className: "btnCancelar",
                },
                {
                  label: "Aceptar",
                  onClick: () => this.setState({ agregarUsuario: true }),
                  className: "btnAceptar",
                },
              ],
            });
          }
        });
    }
  };

  traerEspecialidades() {
    axios.get(global.config.modulos.especialidades + "especialidades_entregan_horas/", { headers: authHeader(), params: { cesfam: this.state.cesfam } }).then((res) => {
      if (res.data.estado) {
        this.setState({
          especialidades: res.data.datos,
        });
      }
    });
  }

  render() {
    const { rutErr, motivoErr, especialidadErr } = this.state.formErrors;
    const traerDatos = (res) => {
      this.setState({
        especialidad: res.esp_id,
      });
    };
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario agregarCaso">
        <DialogContent>
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
            <Grid container spacing={3}>
              <GridItem xs={12}>
                <h2>Agregar Caso</h2>
              </GridItem>
              <Grid item xs={6} style={{ marginTop: 10 }}>
                <InputLabel className={crearTotem.textosTitulos}>Rut</InputLabel>
                <TextField id="rut" style={{ width: "100%" }} name="rut" value={this.state.rut} onChange={this.cambiarValores} />
                {rutErr && <div style={{ color: "red", paddingTop: 10 }}>{rutErr}</div>}
              </Grid>
              <Grid item xs={6} style={{ marginTop: 10 }}>
                <InputLabel className={crearTotem.textosTitulos}>Motivo ingreso</InputLabel>
                <TextField id="motivo" style={{ width: "100%" }} name="motivo" value={this.state.motivo} onChange={this.cambiarValores} />
                {motivoErr && <div style={{ color: "red", paddingTop: 10 }}>{motivoErr}</div>}
              </Grid>
              <Grid item xs={6} style={{ marginTop: 10 }}>
                <InputLabel className={crearTotem.textosTitulos}>Atención asociada</InputLabel>
                <Autocomplete
                  disableClearable
                  id="combo-box-comuna"
                  options={Object.values(this.state.especialidades)}
                  getOptionLabel={(option) => option.esp_nombre}
                  onChange={function (event, res) {
                    if (res != null) {
                      traerDatos(res);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Seleccionar"
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                ></Autocomplete>
                {especialidadErr && <div style={{ color: "red", paddingTop: 10 }}>{especialidadErr}</div>}
              </Grid>

              <Grid xs={12} style={{ marginTop: 20 }}>
                <Button onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                  Cancelar
                </Button>
                <Button onClick={() => this.enviarFormulario(false)} color="primary" className="fondoVerde">
                  Agregar
                </Button>
              </Grid>
            </Grid>
          </GridItem>
        </DialogContent>

        {this.state.modalExisteCaso ? <ModalExisteCaso ocultar={this.ocultarExisteCaso} mensaje={this.state.mensajeAlerta} /> : null}
        {this.state.agregarUsuario ? <AgregarUsuario ocultar={this.ocultarAgregarUsuario} cliente={this.state.cliente}></AgregarUsuario> : null}
      </Dialog>
    );
  }
}
