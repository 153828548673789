import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";

import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert"; // Import

import Imagenologia from "assets/css/imagenologia.module.css";

// estilos
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class ExamenNoRealizado extends Component {
  constructor(props) {
    super(props);

    this.state = {
      motivos: [],
      motivo: "",
      motivoTxt: "",
      mostrarMotivoTxt: false,

      uspId: store.getState().usp,
      imaId: props.imaId,
      canal: 5,

      formErrors: {},
      open: true,
    };
  }

  componentDidMount() {
    this.traerMotivos();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  validaciones() {
    const { motivo, motivoTxt } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (motivo == "" || motivo == 0) {
      formIsValid = false;
      formErrors["motivoErr"] = "Selecciona el motivo.";
    } else if (motivo == 5 && motivoTxt == "") {
      formIsValid = false;
      formErrors["motivoTxtErr"] = "Escribe el motivo.";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  enviarFormulario = (e) => {
    if (this.validaciones()) {
      let apiUrl = global.config.modulos.agendamientos + "cambio_estado_imagenologia/";

      const options = {
        method: "POST",
        data: {
          imaId: this.state.imaId,
          accion: 2,
          uspId: this.state.uspId,
          motivoRechazo: this.state.motivo,
          motivoRechazoTexto: this.state.motivoTxt,
        },
        headers: authHeader(),
      };
      axios(apiUrl, options).then((res) => {
        if (res.data.estado) {
          NotificationManager.info(res.data.mensaje);
          this.salir();
        }
      });
    }
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    if (value == 5 && name == "motivo") {
      this.setState({
        mostrarMotivoTxt: true,
      });
    } else if (value != 5 && name == "motivo") {
      this.setState({
        mostrarMotivoTxt: false,
        motivoTxt: "",
      });
    }

    this.setState({ [name]: value });
  };

  traerMotivos() {
    const apiUrl = global.config.modulos.agendamientos + "motivos_examen_no_realizado/";
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      this.setState({
        motivos: res.data.motivos,
      });
    });
  }

  render() {
    const { motivoErr, motivoTxtErr } = this.state.formErrors;

    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogContent className={Imagenologia.popup}>
            <GridContainer className="agendar-container">
              <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
                <img className="marca-agua"></img>
                <GridContainer className="agendar-container">
                  <GridItem xs={12}>
                    <h2>¿Por qué no pudo realizar el examen?</h2>
                  </GridItem>
                  <GridItem xs={6} style={{ marginTop: 30 }}>
                    <Select id="motivo" name="motivo" style={{ width: "100%" }} value={this.state.motivo} onChange={this.cambiarValores} displayEmpty>
                      <MenuItem value="" disabled>
                        <em>Seleccionar</em>
                      </MenuItem>

                      {this.state.motivos &&
                        this.state.motivos.map((mb, index) => (
                          <MenuItem value={mb.meo_id} key={index}>
                            {mb.meo_motivo}
                          </MenuItem>
                        ))}
                    </Select>
                    {motivoErr && <div style={{ color: "red", paddingBottom: 10 }}>{motivoErr}</div>}
                  </GridItem>
                  <GridItem xs={12} style={{ marginTop: 30 }}>
                    {this.state.mostrarMotivoTxt ? <TextareaAutosize name="motivoTxt" aria-label="minimum height" className={"selectSimple "} rowsMin={3} placeholder="Escribe el motivo..." value={this.state.motivoTxt} onChange={this.cambiarValores} style={{ width: "45%", padding: "2px" }} /> : null}
                    {motivoTxtErr && <div style={{ color: "red", paddingBottom: 10 }}>{motivoTxtErr}</div>}
                  </GridItem>

                  <GridItem xs={12} style={{ marginTop: 30 }}>
                    <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={this.salir} style={{ marginLeft: 20 }}>
                      Cancelar
                    </Button>
                    <Button className="botonDialog fondoVerde" onClick={this.enviarFormulario}>
                      Guardar
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
