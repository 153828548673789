import React, { useLayoutEffect, useRef } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

const COLORS = ["#5286d1", "#9ae2fc"];

export default function GraficoBarraFuga(props) {
  console.log(props);
  const series1Ref = useRef(null);
  const series2Ref = useRef(null);
  const xAxisRef = useRef(null);

  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv");

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
      })
    );

    chart.get("colors").set("colors", [am5.color(COLORS[0]), am5.color(COLORS[1])]);

    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: props.data,
      dataFields: {
        date: "Fecha",
        cant_at: "Pacientes atendidos",
        cant_no_at: "Pacientes no atendidos",
      },
      dataFieldsOrder: ["Fecha", "Pacientes atendidos", "Pacientes no atendidos"],
      pngOptions: {
        disabled: true,
      },
      jpgOptions: {
        disabled: true,
      },
      pdfOptions: {
        addURL: false,
        includeData: true,
      },
      jsonOptions: {
        disabled: true,
      },
      csvOptions: {
        disabled: true,
      },
      pdfdataOptions: {
        disabled: true,
      },
      htmlOptions: {
        disabled: true,
      },
      printOptions: {
        disabled: true,
      },
      title: "Pacientes",
      filePrefix: props.filename,
    });

    exporting.events.on("pdfdocready", function (event) {
      event.doc.content.unshift({
        text: props.filename,
        margin: [0, 30],
        style: {
          fontSize: 25,
          bold: true,
        },
      });
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: "date",
      })
    );

    let toolt1 = am5.Tooltip.new(root, {});

    toolt1.events.on("click", function (ev) {
      var datos = ev.target._dataItem.dataContext;
      props.handleBarCLick(datos);
    });

    let series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Pacientes atendidos ",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "cant_at",
        categoryXField: "date",
        tooltip: toolt1,
      })
    );

    series1.columns.template.events.on("click", function (ev) {
      var datos = ev.target._dataItem.dataContext;
      props.handleBarCLick(datos);
    });

    series1.get("tooltip").label.set("text", "[bold]{name}\n[bold]Total: {valueY}");

    let toolt2 = am5.Tooltip.new(root, {});

    toolt2.events.on("click", function (ev) {
      var datos = ev.target._dataItem.dataContext;
      props.handleBarCLick(datos);
    });

    let series2 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Pacientes no atendidos ",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "cant_no_at",
        categoryXField: "date",
        tooltip: toolt2,
      })
    );

    series2.columns.template.events.on("click", function (ev) {
      var datos = ev.target._dataItem.dataContext;
      props.handleBarCLick(datos);
    });

    series2.get("tooltip").label.set("text", "[bold]{name}\n[bold]Total: {valueY}");

    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    let legend = chart.rightAxesContainer.children.push(am5.Legend.new(root, {}));
    legend.itemContainers.template.set("width", am5.p100);
    legend.valueLabels.template.setAll({
      width: am5.p100,
      textAlign: "right",
    });

    legend.data.setAll(chart.series.values);

    xAxisRef.current = xAxis;
    series1Ref.current = series1;
    series2Ref.current = series2;

    var modal = am5.Modal.new(root, {
      content: "No se encontraron datos para estos filtros",
    });

    if (props.data.length < 1) {
      modal.open();
    } else {
      modal.close();
    }

    return () => {
      root.dispose();
    };
  }, []);

  // This code will only run when props.data changes
  useLayoutEffect(() => {
    xAxisRef.current.data.setAll(props.data);
    series1Ref.current.data.setAll(props.data);
    series2Ref.current.data.setAll(props.data);
  }, [props.data]);

  return <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>;
}
