import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import

// CSS
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import InformacionUsuarioCSS from "./../../assets/css/usuarios.module.css";
//JS
import ModificarTelefono from ".//ModificarTelefono";

import { store } from "./../../store/index";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import { NotificationManager } from "react-notifications";
import { Grid } from "@material-ui/core";

//IMG
import icono_modificar from "../../assets/img/usuarios/icono_modificar.svg";
import icono_eliminar from "../../assets/img/usuarios/icono_eliminar.svg";
import icono_call from "../../assets/img/usuarios/iconos_colores/call_center.svg";
import icono_web from "../../assets/img/usuarios/icono_web.svg";
import icono_app from "../../assets/img/usuarios/icono_app.svg";
import icono_some from "../../assets/img/usuarios/icono_some.svg";
import transitions from "@material-ui/core/styles/transitions";

// estilos
//import "../../assets/css/agregar.css";

export default class Telefonos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      telefonosSome: [],
      telefonosWeb: [],
      telefonosApp: [],
      telefonosCall: [],
      formErrors: {},
      uspId: store.getState().usp,
      usuId: props.usuId,
      modalModificarTelefono: false,
      telId: null,
      telefono: "",
      ancho: props.ancho | 6,

      letraTamanio: props.tamanio,
    };
  }

  componentDidMount() {
    this.traerTelefonos();
    //console.log('esto se llama solo');
  }

  componentDidUpdate() {
    if (this.props.tamanio != this.state.tamanio) {
      this.setState(
        {
          tamanio: this.props.tamanio,
        },
        () => {
          this.cambiarTamanio(this.state.tamanio, 2);
        }
      );
    }
  }
  cambiarTamanio = (tipo, transition) => {
    this.removerClasses();
    const allElements = document.querySelectorAll("[name='labelTam']");

    allElements.forEach((element) => {
      if (tipo == "1") {
        element.classList.add(InformacionUsuarioCSS.label_11);
      } else if (tipo == "2") {
        element.classList.add(InformacionUsuarioCSS.label_13);
      } else if (tipo == "3") {
        element.classList.add(InformacionUsuarioCSS.label_16);
      } else if (tipo == "4") {
        element.classList.add(InformacionUsuarioCSS.label_20);
      }

      if (transition == 1) {
        element.style.transition = "all 300ms linear";
      }
    });

    axios
      .patch(
        global.config.modulos.usuariospanel + "cambio_tamanio_letra/",
        {
          usuarioPanel: store.getState().usp,
          tipo_letra: tipo,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        this.setState({ letraTamanio: tipo });
      });
  };

  removerClasses = () => {
    const allElements = document.querySelectorAll("[name='labelTam']");

    allElements.forEach((element) => {
      element.classList.remove(InformacionUsuarioCSS.label_11, InformacionUsuarioCSS.label_13, InformacionUsuarioCSS.label_16, InformacionUsuarioCSS.label_20);
    });
  };

  mostrarAlertaEliminarTelefono = (tel) => {
    confirmAlert({
      title: "",
      message: "Deseas eliminar este télefono del registro del paciente",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => this.eliminarTelefono(tel),
          className: "btnAceptar",
        },
      ],
    });
  };

  traerTelefonos() {
    let apiUrl = global.config.modulos.telefonos + "telefonos_por_usuario_por_ficha/" + this.state.usuId;
    axios(apiUrl, {
      headers: authHeader(),
    }).then((res) => {
      var telefonos = res.data.datos;
      var telCall = [];
      var telWeb = [];
      var telSome = [];
      var telApp = [];

      telefonos.map((res) => {
        res["tel_fecha_registro"] = res["tel_fecha_registro"].replace("T", " ");

        if (res["tel_canal"] == 2) {
          telCall.push(res);
        }
        if (res["tel_canal"] == 5) {
          telSome.push(res);
        }
        if (res["tel_canal"] == 11) {
          telWeb.push(res);
        }
        if (res["tel_canal"] == 3) {
          telApp.push(res);
        }
      });
      this.setState({
        telefonosSome: telSome,
        telefonosWeb: telWeb,
        telefonosApp: telApp,
        telefonosCall: telCall,
      });
      this.cambiarTamanio(this.state.tamanio, 2);
    });
  }

  eliminarTelefono = (tel) => {
    var data = {
      telefono: tel,
      usuario: this.state.usuId,
      usp_id: this.state.uspId,
    };
    let apiUrl = global.config.modulos.telefonos + "telefono_por_numero/";
    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      try {
        NotificationManager.success("Télefono eliminado con éxito");
        this.traerTelefonos();
        this.props.validar();
      } catch (e) {
        console.log(e);
        this.props.onClose();
      }
    });
  };

  mostrarModalModificarTelefono = (id, telefono) => {
    this.setState({
      modalModificarTelefono: true,
      telId: id,
      telefono: telefono,
    });
  };

  ocultarModalModificarTelefono = () => {
    this.setState({ modalModificarTelefono: false });
    this.traerTelefonos();
  };

  render() {
    const { ancho } = this.state;
    return (
      <div className="formDiv">
        <div className="User" style={{ overflowY: "scroll", maxHeight: "50vh", paddingTop: "1vh" }}>
          <Grid container spacing={2} className="fichaUsuario scroll-container">
            <Grid item xs={12} sm={12} md={this.state.tamanio <= 2 ? 6 : 12}>
              <div>
                <img className={this.state.tamanio > 2 ? InformacionUsuarioCSS.imagenDatos_GrupoFamiliar : InformacionUsuarioCSS.imagenDatos_telefono_pequenio} src={icono_some} />
                <div className={this.state.tamanio > 2 ? InformacionUsuarioCSS.tablasTelefonosG : InformacionUsuarioCSS.tablasTelefonosP}>
                  {this.state.telefonosSome.length > 0 ? (
                    <React.Fragment>
                      <Grid container className="contenidoDatosUsuario">
                        <Grid className="datosIndividuales" item xs={12}>
                          <Table aria-label="simple table" className={InformacionUsuarioCSS.bordeTablaTelefono}>
                            <TableHead>
                              <TableRow>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Fecha de registro
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Número de teléfono
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Frecuencia
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Acciones
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.telefonosSome.map((tel, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell align="center" name="labelTam">
                                      {tel.tel_fecha_registro}
                                    </TableCell>
                                    <TableCell align="center">
                                      {tel.tel_confirmado == 1 ? (
                                        <React.Fragment>
                                          <Tooltip title="Este teléfono ha sido ingresado por paciente" aria-label="Este teléfono ha sido ingresado por paciente">
                                            <label style={{ fontWeight: "bold" }} name="labelTam">
                                              {tel.tel_telefono}
                                            </label>
                                          </Tooltip>
                                          {tel.grupo_familiar == 1 ? <p name="labelTam">{"(GF)"}</p> : null}
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          <label name="labelTam">{tel.tel_telefono}</label>
                                          {tel.grupo_familiar == 1 ? <p name="labelTam">{"(GF)"}</p> : null}
                                        </React.Fragment>
                                      )}
                                    </TableCell>
                                    <TableCell align="center" name="labelTam">
                                      {tel.tel_cantidad}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        border: "none",
                                      }}
                                    >
                                      {tel.grupo_familiar != 1 && (
                                        <React.Fragment>
                                          <Tooltip title="Eliminar teléfono" aria-label="Eliminar teléfono">
                                            <img style={this.state.letraTamanio > 2 ? { width: "2.2vw" } : { width: "1.8vw" }} className="imagenBotonAccion" src={icono_eliminar} onClick={() => this.mostrarAlertaEliminarTelefono(tel.tel_telefono)} />
                                          </Tooltip>
                                          <Tooltip title="Editar teléfono" aria-label="Editar teléfono">
                                            <img style={this.state.letraTamanio > 2 ? { width: "2.2vw" } : { width: "1.8vw" }} className="imagenBotonAccion" src={icono_modificar} onClick={() => this.mostrarModalModificarTelefono(tel.tel_id, tel.tel_telefono)} />
                                          </Tooltip>
                                        </React.Fragment>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid container spacing={2} className="contenidoDatosUsuario">
                        <Grid className="datosIndividuales" item xs={12}>
                          <Table aria-label="simple table" className={InformacionUsuarioCSS.bordeTablaTelefono}>
                            <TableHead>
                              <TableRow>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Fecha de registro
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Número de teléfono
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Frecuencia
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Acciones
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell align="center" colSpan="4" name="labelTam">
                                  Sin registros
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={this.state.tamanio <= 2 ? 6 : 12}>
              <div>
                <img className={this.state.tamanio > 2 ? InformacionUsuarioCSS.imagenDatos_GrupoFamiliar : InformacionUsuarioCSS.imagenDatos_telefono_pequenio} src={icono_call} />
                <div className={this.state.tamanio > 2 ? InformacionUsuarioCSS.tablasTelefonosG : InformacionUsuarioCSS.tablasTelefonosP}>
                  {this.state.telefonosCall.length > 0 ? (
                    <React.Fragment>
                      <Grid container spacing={2} className="contenidoDatosUsuario">
                        <Grid className="datosIndividuales" item xs={12}>
                          <Table aria-label="simple table" className={InformacionUsuarioCSS.bordeTablaTelefono}>
                            <TableHead>
                              <TableRow>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Fecha de registro
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Número de teléfono
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Frecuencia
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Acciones
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.telefonosCall.map((tel, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell align="center" name="labelTam">
                                      {tel.tel_fecha_registro}
                                    </TableCell>
                                    <TableCell align="center">
                                      {tel.tel_confirmado == 1 ? (
                                        <React.Fragment>
                                          <Tooltip title="Este teléfono ha sido ingresado por paciente" aria-label="Este teléfono ha sido ingresado por paciente">
                                            <label name="labelTam" style={{ fontWeight: "bold" }}>
                                              {tel.tel_telefono}
                                            </label>
                                          </Tooltip>
                                          {tel.grupo_familiar == 1 ? <p name="labelTam">{"(GF)"}</p> : null}
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          <label name="labelTam">{tel.tel_telefono}</label>
                                          {tel.grupo_familiar == 1 ? <p name="labelTam">{"(GF)"}</p> : null}
                                        </React.Fragment>
                                      )}
                                    </TableCell>
                                    <TableCell align="center" name="labelTam">
                                      {tel.tel_cantidad}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        border: "none",
                                      }}
                                    >
                                      {tel.grupo_familiar != 1 && (
                                        <React.Fragment>
                                          <Tooltip title="Eliminar teléfono" aria-label="Eliminar teléfono">
                                            <img style={this.state.letraTamanio > 2 ? { width: "2.2vw" } : { width: "1.8vw" }} className="imagenBotonAccion" src={icono_eliminar} onClick={() => this.mostrarAlertaEliminarTelefono(tel.tel_telefono)} />
                                          </Tooltip>
                                          <Tooltip title="Editar teléfono" aria-label="Editar teléfono">
                                            <img style={this.state.letraTamanio > 2 ? { width: "2.2vw" } : { width: "1.8vw" }} className="imagenBotonAccion" src={icono_modificar} onClick={() => this.mostrarModalModificarTelefono(tel.tel_id, tel.tel_telefono)} />
                                          </Tooltip>
                                        </React.Fragment>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid container spacing={2} className="contenidoDatosUsuario">
                        <Grid className="datosIndividuales" item xs={12}>
                          <Table aria-label="simple table" className={InformacionUsuarioCSS.bordeTablaTelefono}>
                            <TableHead>
                              <TableRow>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Fecha de registro
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Número de teléfono
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Frecuencia
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Acciones
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell align="center" colSpan="4" name="labelTam">
                                  Sin registros
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={this.state.tamanio <= 2 ? 6 : 12}>
              <div>
                <img className={this.state.tamanio > 2 ? InformacionUsuarioCSS.imagenDatos_GrupoFamiliar : InformacionUsuarioCSS.imagenDatos_telefono_pequenio} src={icono_app} />
                <div className={this.state.tamanio > 2 ? InformacionUsuarioCSS.tablasTelefonosG : InformacionUsuarioCSS.tablasTelefonosP}>
                  {this.state.telefonosApp.length > 0 ? (
                    <React.Fragment>
                      <Grid container spacing={2} className="contenidoDatosUsuario">
                        <Grid className="datosIndividuales" item xs={12}>
                          <Table aria-label="simple table" className={InformacionUsuarioCSS.bordeTablaTelefono}>
                            <TableHead>
                              <TableRow>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Fecha de registro
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Número de teléfono
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Frecuencia
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Acciones
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.telefonosApp.map((tel, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell align="center" name="labelTam">
                                      {tel.tel_fecha_registro}
                                    </TableCell>
                                    <TableCell align="center">
                                      {tel.tel_confirmado == 1 ? (
                                        <React.Fragment>
                                          <Tooltip title="Este teléfono ha sido ingresado por paciente" aria-label="Este teléfono ha sido ingresado por paciente">
                                            <label name="labelTam" style={{ fontWeight: "bold" }}>
                                              {tel.tel_telefono}
                                            </label>
                                          </Tooltip>
                                          {tel.grupo_familiar == 1 ? <p name="labelTam">{"(GF)"}</p> : null}
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          <label name="labelTam">{tel.tel_telefono}</label>
                                          {tel.grupo_familiar == 1 ? <p name="labelTam">{"(GF)"}</p> : null}
                                        </React.Fragment>
                                      )}
                                    </TableCell>
                                    <TableCell align="center" name="labelTam">
                                      {tel.tel_cantidad}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        border: "none",
                                      }}
                                    >
                                      {tel.grupo_familiar != 1 && (
                                        <React.Fragment>
                                          <Tooltip title="Eliminar teléfono" aria-label="Eliminar teléfono">
                                            <img style={this.state.letraTamanio > 2 ? { width: "2.2vw" } : { width: "1.8vw" }} className="imagenBotonAccion" src={icono_eliminar} onClick={() => this.mostrarAlertaEliminarTelefono(tel.tel_telefono)} />
                                          </Tooltip>
                                          <Tooltip title="Editar teléfono" aria-label="Editar teléfono">
                                            <img style={this.state.letraTamanio > 2 ? { width: "2.2vw" } : { width: "1.8vw" }} className="imagenBotonAccion" src={icono_modificar} onClick={() => this.mostrarModalModificarTelefono(tel.tel_id, tel.tel_telefono)} />
                                          </Tooltip>
                                        </React.Fragment>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid container spacing={2} className="contenidoDatosUsuario">
                        <Grid className="datosIndividuales" item xs={12}>
                          <Table aria-label="simple table" className={InformacionUsuarioCSS.bordeTablaTelefono}>
                            <TableHead>
                              <TableRow>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Fecha de registro
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Número de teléfono
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Frecuencia
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Acciones
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell align="center" colSpan="4" name="labelTam">
                                  Sin registros
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={this.state.tamanio <= 2 ? 6 : 12}>
              <div>
                <img className={this.state.tamanio > 2 ? InformacionUsuarioCSS.imagenDatos_GrupoFamiliar : InformacionUsuarioCSS.imagenDatos_telefono_pequenio} src={icono_web} />
                <div className={this.state.tamanio > 2 ? InformacionUsuarioCSS.tablasTelefonosG : InformacionUsuarioCSS.tablasTelefonosP}>
                  {this.state.telefonosWeb.length > 0 ? (
                    <React.Fragment>
                      <Grid container spacing={2} className="contenidoDatosUsuario">
                        <Grid className="datosIndividuales" item xs={12}>
                          <Table aria-label="simple table" className={InformacionUsuarioCSS.bordeTablaTelefono}>
                            <TableHead>
                              <TableRow>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Fecha de registro
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Número de teléfono
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Frecuencia
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Acciones
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.telefonosWeb.map((tel, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell align="center">{tel.tel_fecha_registro}</TableCell>
                                    <TableCell align="center">
                                      {tel.tel_confirmado == 1 ? (
                                        <React.Fragment>
                                          <Tooltip title="Este teléfono ha sido ingresado por paciente" aria-label="Este teléfono ha sido ingresado por paciente">
                                            <label name="labelTam" style={{ fontWeight: "bold" }}>
                                              {tel.tel_telefono}
                                            </label>
                                          </Tooltip>
                                          {tel.grupo_familiar == 1 ? <p name="labelTam">{"(GF)"}</p> : null}
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          <label name="labelTam">{tel.tel_telefono}</label>
                                          {tel.grupo_familiar == 1 ? <p name="labelTam">{"(GF)"}</p> : null}
                                        </React.Fragment>
                                      )}
                                    </TableCell>
                                    <TableCell align="center" name="labelTam">
                                      {tel.tel_cantidad}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        border: "none",
                                      }}
                                    >
                                      {tel.grupo_familiar != 1 && (
                                        <React.Fragment>
                                          <Tooltip title="Eliminar teléfono" aria-label="Eliminar teléfono">
                                            <img style={{ width: 20 }} className="imagenBotonAccion" src={icono_eliminar} onClick={() => this.mostrarAlertaEliminarTelefono(tel.tel_telefono)} />
                                          </Tooltip>
                                          <Tooltip title="Editar teléfono" aria-label="Editar teléfono">
                                            <img style={{ width: 20 }} className="imagenBotonAccion" src={icono_modificar} onClick={() => this.mostrarModalModificarTelefono(tel.tel_id, tel.tel_telefono)} />
                                          </Tooltip>
                                        </React.Fragment>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid container spacing={2} className="contenidoDatosUsuario">
                        <Grid className="datosIndividuales" item xs={12}>
                          <Table aria-label="simple table" className={InformacionUsuarioCSS.bordeTablaTelefono}>
                            <TableHead>
                              <TableRow>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Fecha de registro
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Número de teléfono
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Frecuencia
                                </TableCell>
                                <TableCell className="cellHead" align="center" name="labelTam">
                                  Acciones
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell align="center" colSpan="4" name="labelTam">
                                  Sin registros
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          {this.state.modalModificarTelefono ? <ModificarTelefono ocultar={this.ocultarModalModificarTelefono} telId={this.state.telId} telefono={this.state.telefono}></ModificarTelefono> : null}

          <br></br>
          <br></br>
        </div>
      </div>
    );
  }
}
