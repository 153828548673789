import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { connect } from "react-redux";

import axios from "axios";
import chileanRut from "chilean-rut";

import moment from "moment";
import "moment/locale/es";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MaterialTable from "material-table";

import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";

import { confirmAlert } from "react-confirm-alert"; // Import

import icon_ver_l from "assets/img/video_llamada/lunes/ver.svg";
import icon_archivos_l from "assets/img/video_llamada/lunes/archivos.svg";
import icon_observaciones_menu_l from "assets/img/video_llamada/lunes/observaciones_menu.svg";
import icon_reservar_l from "assets/img/video_llamada/lunes/reservar.svg";
import icon_video_llamada_l from "assets/img/video_llamada/lunes/video_llamada.svg";

import icon_ver_m from "assets/img/video_llamada/martes/ver.svg";
import icon_archivos_m from "assets/img/video_llamada/martes/archivos.svg";
import icon_observaciones_menu_m from "assets/img/video_llamada/martes/observaciones_menu.svg";
import icon_reservar_m from "assets/img/video_llamada/martes/reservar.svg";
import icon_video_llamada_m from "assets/img/video_llamada/martes/video_llamada.svg";

import icon_ver_mi from "assets/img/video_llamada/miercoles/ver.svg";
import icon_archivos_mi from "assets/img/video_llamada/miercoles/archivos.svg";
import icon_observaciones_menu_mi from "assets/img/video_llamada/miercoles/observaciones_menu.svg";
import icon_reservar_mi from "assets/img/video_llamada/miercoles/reservar.svg";
import icon_video_llamada_mi from "assets/img/video_llamada/miercoles/video_llamada.svg";

import icon_ver_j from "assets/img/video_llamada/jueves/ver.svg";
import icon_archivos_j from "assets/img/video_llamada/jueves/archivos.svg";
import icon_observaciones_menu_j from "assets/img/video_llamada/jueves/observaciones_menu.svg";
import icon_reservar_j from "assets/img/video_llamada/jueves/reservar.svg";
import icon_video_llamada_j from "assets/img/video_llamada/jueves/video_llamada.svg";

import icon_ver_v from "assets/img/video_llamada/viernes/ver.svg";
import icon_archivos_v from "assets/img/video_llamada/viernes/archivos.svg";
import icon_observaciones_menu_v from "assets/img/video_llamada/viernes/observaciones_menu.svg";
import icon_reservar_v from "assets/img/video_llamada/viernes/reservar.svg";
import icon_video_llamada_v from "assets/img/video_llamada/viernes/video_llamada.svg";

import icon_ver_s from "assets/img/video_llamada/sabado/ver.svg";
import icon_archivos_s from "assets/img/video_llamada/sabado/archivos.svg";
import icon_observaciones_menu_s from "assets/img/video_llamada/sabado/observaciones_menu.svg";
import icon_reservar_s from "assets/img/video_llamada/sabado/reservar.svg";
import icon_video_llamada_s from "assets/img/video_llamada/sabado/video_llamada.svg";

import icon_ver_gris from "assets/img/video_llamada/ver_gris.svg";
import icon_ver_rojo from "assets/img/video_llamada/ver_rojo.svg";

import icon_telefono from "assets/img/video_llamada/telefono.svg";
import icon_video_llamada_gris from "assets/img/video_llamada/video_llamada_gris.svg";

import estados_colores from "assets/img/video_llamada/estados_colores.svg";

import icon_descargar_archivo from "assets/img/video_llamada/descargar_archivo.svg";

import AgendarUsuario from "components/Usuarios/ModalAgendarHora";

import { store } from "../../store/index";
import { socketVideollamada } from "../../service/socket";

import signo from "assets/img/signo.svg";

import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";

import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

import "assets/css/videollamada.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import imagenologia from "assets/css/imagenologia.module.css";
import InformacionUsuario from "components/Usuarios/InformacionUsuario";
import VistaUsuarios from "components/GestionPrioritarios/VistaUsuarios";

class Pacientes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fechaPacientes: [],

      fechaSeleccionada: "",
      semanaFiltro: moment().format("GGGG-[W]WW"),
      color: "#1ac6c1",
      posicion: 0,

      proId: store.getState().id_medico,
      canal: 5,
      usu_id: "",

      acompanante: "",
      datosAcompanante: "",
      pacientes: [],
      observaciones: [],
      cambiarEstado: [],
      archivos: [],
      tipoArchivo: 2,
      nombreTipoArchivo: "Fecha enviado",

      urlArchivoDescargar: "",
      nombreArchivo: "",

      modalAcomp: false,
      modalObs: false,
      modalArch: false,
      modalReserva: false,
      uspId: store.getState().usp,
    };

    /* var mes = moment().format("YYYY-MM");
    this.traerFechasAgendas(mes); */
  }

  componentDidMount() {
    this.titulo();

    this.traerSemanas(this.state.semanaFiltro);

    socketVideollamada.on("sala_espera", (data) => {
      if (this.state.proId == data.profesional) {
        this.traerSemanas(this.state.semanaFiltro);
      }
    });
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Pacientes del día <span title=''><img src=" + signo + "></img></span>";
  };

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  }

  traerSemanas = (sem) => {
    var semana = sem;

    if (semana == "") {
      return;
    }

    var arrSemana = sem.split("-W");
    var nSemana = arrSemana[1];
    var añoPorNSemana = arrSemana[0];
    var inicioSemana = moment().isoWeekYear(añoPorNSemana).isoWeek(nSemana).startOf("isoweek").format("YYYY-MM-DD");

    var colores = ["#1ac6c1", "#ea1877", "#7b37db", "#d625b4", "#1289db", "#24bde0"];

    this.traerDatos(inicioSemana, colores[0], 0);
  };

  traerDatos = async (fecha, color, posicion) => {
    this.setState({
      fechaSeleccionada: fecha,
      color: color,
      posicion: posicion,
    });
    fecha = moment(fecha).format("YYYY-MM-DD");

    var arrSemana = this.state.semanaFiltro.split("-W");
    var nSemana = arrSemana[1];
    var añoPorNSemana = arrSemana[0];
    var inicioSemana = moment().isoWeekYear(añoPorNSemana).isoWeek(nSemana).startOf("isoweek").format("YYYY-MM-DD");

    var botonesTop = [];

    var colores = ["#1ac6c1", "#ea1877", "#7b37db", "#d625b4", "#1289db", "#24bde0"];
    var inicioSemana = moment(inicioSemana);
    for (var i = 0; i <= 5; i++) {
      if (i == posicion) {
        botonesTop.push(
          <button id={"btn" + i} className={imagenologia.botonL} style={{ background: colores[i], width: "11vw" }} onClick={this.traerDatos.bind(this, inicioSemana, colores[i], i)}>
            {inicioSemana.format("dddd") + " " + inicioSemana.format("DD")}
          </button>
        );
      } else {
        botonesTop.push(
          <button id={"btn" + i} className={imagenologia.botonL} style={{ background: colores[i] }} onClick={this.traerDatos.bind(this, inicioSemana, colores[i], i)}>
            {inicioSemana.format("ddd") + " " + inicioSemana.format("DD")}
          </button>
        );
      }

      inicioSemana = moment(inicioSemana).add(1, "day");
    }

    this.setState({
      botonesTop: botonesTop,
    });

    var datos = {
      fecha: fecha,
      proId: this.state.proId,
    };

    var res = await axios.get(global.config.modulos.videollamada + "agenda_teleconsulta_por_fecha/", {
      headers: authHeader(),
      params: datos,
    });

    var data = res.data;

    var estado = data.estado;
    var agenda = data.agenda;
    var conteoAgenda = agenda.length;

    var tableBody = [];
    if (estado) {
      for (var i = 0; i < conteoAgenda; i++) {
        var icon_ver = icon_ver;
        var icon_archivos = icon_archivos;
        var icon_observaciones_menu = icon_observaciones_menu;
        var icon_reservar = icon_reservar;
        var icon_video_llamada = icon_video_llamada;

        if (posicion == 0) {
          icon_ver = icon_ver_l;
          icon_archivos = icon_archivos_l;
          icon_observaciones_menu = icon_observaciones_menu_l;
          icon_reservar = icon_reservar_l;
          icon_video_llamada = icon_video_llamada_l;
        } else if (posicion == 1) {
          icon_ver = icon_ver_m;
          icon_archivos = icon_archivos_m;
          icon_observaciones_menu = icon_observaciones_menu_m;
          icon_reservar = icon_reservar_m;
          icon_video_llamada = icon_video_llamada_m;
        } else if (posicion == 2) {
          icon_ver = icon_ver_mi;
          icon_archivos = icon_archivos_mi;
          icon_observaciones_menu = icon_observaciones_menu_mi;
          icon_reservar = icon_reservar_mi;
          icon_video_llamada = icon_video_llamada_mi;
        } else if (posicion == 3) {
          icon_ver = icon_ver_j;
          icon_archivos = icon_archivos_j;
          icon_observaciones_menu = icon_observaciones_menu_j;
          icon_reservar = icon_reservar_j;
          icon_video_llamada = icon_video_llamada_j;
        } else if (posicion == 4) {
          icon_ver = icon_ver_v;
          icon_archivos = icon_archivos_v;
          icon_observaciones_menu = icon_observaciones_menu_v;
          icon_reservar = icon_reservar_v;
          icon_video_llamada = icon_video_llamada_v;
        } else if (posicion == 5) {
          icon_ver = icon_ver_s;
          icon_archivos = icon_archivos_s;
          icon_observaciones_menu = icon_observaciones_menu_s;
          icon_reservar = icon_reservar_s;
          icon_video_llamada = icon_video_llamada_s;
        }

        if (agenda[i]["acompanante"] == "Sí") {
          icon_ver = agenda[i]["acompanante_datos"] != 0 ? icon_ver : icon_ver_rojo;
        } else {
          icon_ver = icon_ver_gris;
        }

        if (agenda[i]["sala_espera"] == 0 && agenda[i]["age_tipo_gestion"] != 3) {
          icon_video_llamada = icon_video_llamada_gris;
        } else if (agenda[i]["age_tipo_gestion"] == 3) {
          icon_video_llamada = icon_telefono;
        }

        tableBody.push(
          <React.Fragment>
            <TableRow key={i} style={{ background: agenda[i]["estado_color"] }}>
              <TableCell align="center">{agenda[i]["horario"]}</TableCell>
              <TableCell align="center">
                {agenda[i]["llamada_grupal"] == 0 ? (
                  <strong>
                    <a style={{ cursor: "pointer" }} onClick={this.mostrarModalUsuario.bind(this, agenda[i]["usu_id"])}>
                      {chileanRut.format(agenda[i]["usu_rut"], true)}
                    </a>
                  </strong>
                ) : (
                  "--"
                )}
              </TableCell>
              <TableCell align="center">{agenda[i]["llamada_grupal"] == 0 ? agenda[i]["nombre_usuario"] : "--"}</TableCell>
              <TableCell align="center">
                <p>{agenda[i]["llamada_grupal"] == 0 ? agenda[i]["usu_nombre_social"] : "--"}</p>
              </TableCell>
              <TableCell align="center">{agenda[i]["llamada_grupal"] == 0 ? agenda[i]["edad"] : "--"}</TableCell>
              <TableCell align="center">{agenda[i]["tipo_contacto"]}</TableCell>
              <TableCell align="center">
                {agenda[i]["llamada_grupal"] == 0 ? (
                  <div className={imagenologia.contenedorImgVideoLlamada} style={{ float: "none", justifyContent: "center" }}>
                    {agenda[i]["acompanante"]} <img src={icon_ver} style={agenda[i]["acompanante"] == "Sí" ? { cursor: "pointer", width: "2vw", marginLeft: "5px" } : { cursor: "default", width: "2vw", marginLeft: "5px" }} onClick={agenda[i]["acompanante"] == "Sí" ? this.abrirModalAcomp.bind(this, agenda[i]["age_id"], agenda[i]["nombre_usuario"], agenda[i]["usu_rut"]) : null} />
                  </div>
                ) : (
                  <div className={imagenologia.contenedorImgVideoLlamada} style={{ float: "none", justifyContent: "center" }}>
                    No <img src={icon_ver} style={{ cursor: "default", width: "2vw", marginLeft: "5px" }} onClick={null} />
                  </div>
                )}
              </TableCell>
              <TableCell align="center">
                {agenda[i]["llamada_grupal"] == 0 ? (
                  <div className={imagenologia.contenedorImgVideoLlamada}>
                    <img className={imagenologia.botonesAccionesVideoLlamada} src={icon_video_llamada} style={{ cursor: "pointer" }} onClick={this.opcionesContacto.bind(this, agenda[i]["age_id"], agenda[i]["age_tipo_gestion"], agenda[i]["age_cesfam"], agenda[i]["usu_id"])} />
                    <img className={imagenologia.botonesAccionesVideoLlamada} src={icon_reservar} style={{ cursor: "pointer" }} onClick={this.agendarUsuario.bind(this, agenda[i]["usu_id"])} />
                    <img className={imagenologia.botonesAccionesVideoLlamada} src={icon_archivos} style={{ cursor: "pointer" }} onClick={this.traerArchivos.bind(this, agenda[i]["usu_id"], 2)} />
                    <img className={imagenologia.botonesAccionesVideoLlamada} src={icon_observaciones_menu} style={{ cursor: "pointer" }} onClick={this.abrirModalObservacionesHistorial.bind(this, agenda[i]["usu_id"])} />
                  </div>
                ) : (
                  <div className={imagenologia.contenedorImgVideoLlamada}>
                    <img className={imagenologia.botonesAccionesVideoLlamada} src={icon_video_llamada} style={{ cursor: "pointer" }} onClick={this.confirmarLlamada.bind(this, agenda[i]["age_id"], null, agenda[i]["age_cesfam"], null, 2)} />
                  </div>
                )}
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      }
    } else {
      tableBody.push(
        <TableRow>
          <TableCell align="center" style={{ height: 250 }} colSpan={8}>
            {data["mensaje"]}
          </TableCell>
        </TableRow>
      );
    }

    var border = "3px solid " + color;
    var tabla = [];
    tabla.push(
      <TableContainer style={{ maxHeight: "46vh", border: border, borderRadius: "15px" }}>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                Hora
              </TableCell>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                RUT
              </TableCell>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                Nombre
              </TableCell>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                Nombre Social
              </TableCell>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                Edad
              </TableCell>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                Contacto
              </TableCell>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                ¿Necesita acompañante?
              </TableCell>
              <TableCell align="center" className={posicion == 0 ? imagenologia.borderL : posicion == 1 ? imagenologia.borderM : posicion == 2 ? imagenologia.borderMI : posicion == 3 ? imagenologia.borderJ : posicion == 4 ? imagenologia.borderV : imagenologia.borderS}>
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tableBody}</TableBody>
        </Table>
      </TableContainer>
    );

    this.setState({
      tabla: tabla,
    });
  };

  obtenerAcomp = async (agenda) => {
    var datos = { agenda: agenda };
    var respuesta = {};
    await axios
      .get(global.config.modulos.videollamada + "acomp_por_agenda/", {
        headers: authHeader(),
        params: datos,
      })
      .then(function (res) {
        respuesta = res;
      });
    return respuesta.data;
  };

  archivos = (usuId, nombreUsuario) => {
    axios
      .get(global.config.modulos.usuarios + "por_id/" + usuId, { headers: authHeader() })
      .then(function (res) {
        var data = res.data;
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  opcionesContacto = (ageId, tipoGestion, cesfam, usuario) => {
    if (tipoGestion == 3) {
      this.cambiarEstadoReserva(ageId);
    } else {
      this.confirmarLlamada(ageId, tipoGestion, cesfam, usuario, 1);
    }
  };

  confirmarLlamada = (ageId, tipoGestion, cesfam, usuario, tipo) => {
    confirmAlert({
      title: "¿Está seguro de iniciar una videollamada?",
      message: "",
      buttons: [
        {
          label: "Sí",
          onClick: tipo == 1 ? this.videoLlamada.bind(this, ageId, tipoGestion, cesfam, usuario) : this.videoLlamadaGrupal.bind(this, ageId, cesfam),
        },
        {
          label: "No",
        },
      ],
    });
  };

  videoLlamada = async (ageId, tipoGestion, cesfam, usuario) => {
    const datos = { ageId: ageId, tipo: tipoGestion, dispositivo: 1 };
    var res = await axios.post(global.config.modulos.videollamada + "crear_sala/", datos, { headers: authHeader() });
    var data = res.data;
    if (data.estado) {
      var resToken = await axios.get(global.config.modulos.videollamada + "token_sala/?uspId=" + this.state.uspId + "&sala=" + data.vls_nombre, { headers: authHeader() });
      var dataToken = resToken.data;

      window.localStorage.setItem("ageId", ageId);
      window.localStorage.setItem("cesfam", cesfam);
      window.localStorage.setItem("usuId", usuario);

      window.localStorage.setItem("nombreSala", data.vls_nombre);
      window.localStorage.setItem("idSala", data.vls_id);
      window.localStorage.setItem("tipoGestion", tipoGestion);
      window.localStorage.setItem("token", dataToken.token);

      this.props.history.push("/videoLlamada");
    } else {
      confirmAlert({
        title: data.mensaje,
        message: "",
        buttons: [
          {
            label: "Aceptar",
          },
          {
            label: "Ausente",
            onClick: this.cambiarEstadoAgenda.bind(this, ageId, 9),
          },
        ],
      });
    }
  };

  videoLlamadaGrupal = async (ageId, cesfam) => {
    const datos = { agenda: ageId };
    var res = await axios.post(global.config.modulos.videollamada + "crear_sala_grupal/", datos, { headers: authHeader() });
    var data = res.data;
    if (data.estado) {
      var resToken = await axios.get(global.config.modulos.videollamada + "token_sala/?uspId=" + this.state.uspId + "&sala=" + data.vls_nombre, { headers: authHeader() });
      var dataToken = resToken.data;

      window.localStorage.setItem("ageId", ageId);
      window.localStorage.setItem("cesfam", cesfam);
      window.localStorage.setItem("nombreSala", data.vls_nombre);
      window.localStorage.setItem("idSala", data.vls_id);
      window.localStorage.setItem("token", dataToken.token);

      this.props.history.push("/videoLlamadaGrupal");
    } else {
      confirmAlert({
        title: data.mensaje,
        message: "",
        buttons: [
          {
            label: "Aceptar",
          },
          {
            label: "Ausente",
            onClick: this.cambiarEstadoAgenda.bind(this, ageId, 9),
          },
        ],
      });
    }
  };

  cambiarEstadoReserva = async (ageId) => {
    const datos = { titulo: "cambiar_estado_reserva", canal: this.state.canal };
    var res = await axios.get(global.config.modulos.aplicaciones + "mensajes_por_titulo/", {
      headers: authHeader(),
      params: datos,
    });

    var data = res.data;

    var cont = [];
    cont.push(
      <>
        <GridItem xs={12}>
          <h2 className="titulosMedico">{data.msg_mensaje}</h2>
        </GridItem>
        <GridItem xs={12}>
          <button className="btnModalMedico" onClick={this.cambiarEstadoAgenda.bind(this, ageId, 7)}>
            Realizada
          </button>
        </GridItem>
        <GridItem xs={12}>
          <button className="btnModalMedico" onClick={this.cambiarEstadoAgenda.bind(this, ageId, 9)}>
            Pérdida
          </button>
        </GridItem>
        <GridItem xs={12}>
          <button className="btnModalMedico" onClick={this.cerrarModalReserva.bind(this)}>
            Cancelar
          </button>
        </GridItem>
      </>
    );
    this.setState({ modalReserva: true, cambiarEstado: cont });
  };

  cambiarEstadoAgenda = async (ageId, estadoAgenda) => {
    const datos = { agenda: ageId, estadoHora: estadoAgenda };
    var res = await axios.post(global.config.modulos.agendamientos + "cambio_estado_agenda/", datos, { headers: authHeader() });
    var data = res.data;
    if (data["estado_agenda"] == "Asistida") {
      data["estado_agenda"] = "Realizada";
    }

    var contenedor = "<strong>Estado:</strong> " + data["estado_agenda"];
    document.getElementById("estadoAgenda" + ageId).innerHTML = contenedor;

    this.cerrarModalReserva();
  };

  cerrarModalReserva = () => {
    this.setState({ modalReserva: false });
  };

  hayAcomp = async (agenda) => {
    var acomp = await this.obtenerAcomp(agenda);
    return acomp.estado;
  };

  salaEspera = async (agenda) => {
    var datos = { agenda: agenda, revisar: 1 };
    var respuesta = {};
    await axios
      .get(global.config.modulos.aplicaciones + "datos_sala_espera/", {
        headers: authHeader(),
        params: datos,
      })
      .then(function (res) {
        respuesta = res;
      });
    return respuesta.data.estado;
  };

  abrirModalAcomp = async (agenda, nombre, rut) => {
    var acomp = await this.obtenerAcomp(agenda);

    var f_rut = chileanRut.format(rut, true);

    var datos = [];
    var titulo = [];
    var mensaje = "El paciente " + nombre + ", RUT " + f_rut;
    if (acomp.estado) {
      mensaje = mensaje + " fue acompañado por:";

      var ac_rut = chileanRut.format(acomp.acomp.vlac_rut_acom, true);
      datos.push(
        <>
          <div className="mensajeTxt" style={{ width: "15%", float: "left" }}>
            <label>
              <strong>Rut</strong>
            </label>
            <br />
            <label>{ac_rut}</label>
          </div>
          <div className="mensajeTxt" style={{ width: "15%", float: "left" }}>
            <label>
              <strong>Nombre</strong>
            </label>
            <br />
            <label>{acomp.nombre}</label>
          </div>
          <div className="mensajeTxt" style={{ width: "15%", float: "left" }}>
            <label>
              <strong>Parentesco</strong>
            </label>
            <br />
            <label>{acomp.parent}</label>
          </div>
        </>
      );

      titulo.push(<label className="mensajeTxt">{mensaje}</label>);
    } else {
      titulo.push(
        <label className="mensajeTxt">
          {mensaje}
          <label>
            <strong> Sin acompañante. </strong>
          </label>
        </label>
      );
    }

    this.setState({
      acompanante: titulo,
      datosAcompanante: datos,
      modalAcomp: true,
    });
  };

  cerrarModalAcomp = () => {
    this.setState({ modalAcomp: false });
  };

  abrirModalObservacionesHistorial = async (usuario) => {
    var datos = {
      usuario: usuario,
    };

    var res = await axios.get(global.config.modulos.videollamada + "observaciones/", {
      headers: authHeader(),
      params: datos,
    });

    var data = res.data;
    if (data["observaciones"].length > 1) {
      this.setState({
        observaciones: data["observaciones"],
        modalObs: true,
      });
    } else {
      this.mostrarAlerta("No hay observaciones ingresadas.");
    }
  };

  cerrarModalObs = () => {
    this.setState({ modalObs: false });
  };

  traerArchivos = async (usuario, tipo) => {
    var nombreTipo = "";
    if (tipo == 1) {
      nombreTipo = "Fecha recibido";
    } else {
      nombreTipo = "Fecha enviado";
    }

    var datos = {
      usuId: usuario,
      proId: this.state.proId,
      tipo: tipo,
    };

    var res = await axios.get(global.config.modulos.videollamada + "archivos_por_usuario_profesional/", {
      headers: authHeader(),
      params: datos,
    });

    var data = res.data;
    if (data["archivos"].length >= 1) {
      this.setState({
        archivos: data["archivos"],
        modalArch: true,
        usu_id: usuario,
        tipoArchivo: tipo,
        nombreTipoArchivo: nombreTipo,
      });
    } else {
      this.mostrarAlerta("No se han enviado o recibido archivos.");
    }
  };

  descargarArchivo = async (archivo) => {
    var res = await axios.get(global.config.modulos.videollamada + "archivo/" + archivo, { headers: authHeader() });

    var data = res.data;
    var urlArchivoDescargar = global.config.modulos.REACT_APP_URL_SERVER_ + "/descarga_archivo?archivo=" + data["vla_nombre_archivo"] + "&formato=" + data["vla_formato_archivo"];

    var link = document.createElement("a");
    link.download = data["vla_nombre_archivo"];
    link.href = urlArchivoDescargar;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  cerrarModalArch = () => {
    this.setState({ modalArch: false });
  };

  agendarUsuario = (usu_id) => {
    this.setState({ agendarUsuario: true, usu_id: usu_id });
  };

  ocultarAgendar = () => {
    this.setState({ agendarUsuario: false, usu_id: "" });
  };

  handleMonthChange = (day) => {
    var mes = moment(day).format("YYYY-MM");
    this.traerFechasAgendas(mes);
  };

  traerFechasAgendas = async (fecha) => {
    var datos = {
      fecha: fecha,
      proid: this.state.proId,
    };

    var res = await axios.get(global.config.modulos.profesionales + "agenda_fechas_por_mes_app/", {
      headers: authHeader(),
      params: datos,
    });

    var data = res.data;
    this.setState({
      fechaPacientes: data.fechas,
    });
  };

  mostrarModalUsuario = (idusuario) => {
    if (idusuario) {
      this.setState({
        modalUsuario: true,
        id_usuario: idusuario,
      });
    }
  };

  ocultarModalUsuario = () => {
    this.setState({
      modalUsuario: false,
    });
  };

  render() {
    const cambiarSemana = (event) => {
      var semanaFiltro = event.target.value;
      if (semanaFiltro != "") {
        this.setState(
          {
            semanaFiltro: semanaFiltro,
            tabla: "",
          },
          () => {
            this.traerSemanas(semanaFiltro);
          }
        );
      }
    };

    return (
      <>
        <article className="middle-area">
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <DatePicker
              id="date-picker-inline"
              disableToolbar
              invalidDateMessage="¡Fecha mal ingresada, por favor inténtalo nuevamente!"
              variant="inline"
              format="dd 'de' MMMM 'de' yyyy"
              margin="normal"
              label="Fecha"
              value={this.state.fechaReservas}
              onMonthChange={this.handleMonthChange}
              name="fechaReservas"
              onChange={(e) => this.cambiarValoresFecha(e)}
              KeyboardButtonProps={{
                "aria-label": "cambiar fecha",
              }}
              className="dtp_main"
              renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                day = moment(day).format("YYYY-MM-DD");

                const isSelected = this.state.fechaPacientes.includes(day);
                return (
                  <div style={{ position: "relative" }}>
                    {dayComponent}
                    {isSelected ? <label className="fechaActiva"></label> : undefined}
                  </div>
                );
              }}
            />
          </MuiPickersUtilsProvider> */}
          <GridContainer>
            <GridItem xs={6}>
              <InputLabel>Semana del horario</InputLabel>
              <TextField id="semanaFiltro" name="semanaFiltro" type="week" value={this.state.semanaFiltro} onChange={cambiarSemana} />
            </GridItem>

            <GridItem xs={6} style={{ textAlign: "end" }}>
              <img src={estados_colores} style={{ width: "29vw" }} />
            </GridItem>
          </GridContainer>

          <div
            style={{
              marginTop: "20px",
            }}
          >
            <div
              style={{
                marginLeft: "20px",
              }}
            >
              {this.state.botonesTop}
            </div>

            {this.state.tabla}
          </div>
        </article>

        <Dialog open={this.state.modalAcomp} onClose={this.cerrarModalAcomp} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="">
          <DialogContent>
            <GridContainer className="agendar-container">
              <GridItem xs={12}>
                <h2 className="titulosMedico">Datos Acompañante</h2>
              </GridItem>
              <GridItem xs={12}>{this.state.acompanante}</GridItem>
              <GridItem xs={12}>{this.state.datosAcompanante}</GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.modalObs} onClose={this.cerrarModalObs} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="agendarHora">
          <DialogContent>
            <GridContainer className="agendar-container">
              <GridItem xs={12}>
                <h2 className="titulosMedico">Observaciones</h2>
              </GridItem>
              <GridItem xs={12}>
                <TableContainer style={{ width: "100%", marginTop: "" }}>
                  <Table aria-label="simple table" className="" style={{ textAlign: "center" }}>
                    <MaterialTable
                      title=""
                      columns={[
                        {
                          title: "Fecha",
                          field: "vlo_fecha_registro",
                          render: (rowData) => <p>{moment(rowData.vlo_fecha_registro).format("DD-MM-YYYY HH:mm:ss")}</p>,
                        },
                        {
                          title: "Profesional",
                          field: "nombre_profesional",
                        },
                        {
                          title: "Observación",
                          field: "vlo_observacion",
                        },
                      ]}
                      data={this.state.observaciones}
                      options={{
                        actionsColumnIndex: -1,
                        sorting: false,
                        search: true,
                        toolbar: false,
                        actions: true,
                        exportAllData: true,
                        exportButton: true,
                      }}
                      localization={{
                        header: {
                          actions: "a",
                        },
                        toolbar: {
                          searchPlaceholder: "Buscar",
                          searchTooltip: "Buscar",
                          exportTitle: "Exportar",
                          exportAriaLabel: "Exportar",
                          exportCSVName: "Exportar como CSV",
                          exportPDFName: "Exportar como PDF",
                        },
                        body: {
                          emptyDataSourceMessage: "No se encuentran difusiones para este cesfam",
                        },
                        pagination: {
                          labelRowsSelect: "Filas",
                          labelDisplayedRows: "{from}-{to} de {count}",
                        },
                      }}
                    ></MaterialTable>
                  </Table>
                </TableContainer>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.modalArch} onClose={this.cerrarModalArch} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="agendarHora">
          <DialogContent>
            <GridContainer className="agendar-container">
              <GridItem xs={12}>
                <h2 className="titulosMedico">Ver archivos adjuntos</h2>
              </GridItem>
              <GridItem xs={12} style={{ textAlign: "center" }}>
                <button className={this.state.tipoArchivo == 2 ? "btnAccionesMedico btnSeleccionadoMedico" : "btnAccionesMedico "} onClick={this.traerArchivos.bind(this, this.state.usu_id, 2)} id="btnEnviados">
                  Enviados
                </button>
                <button className={this.state.tipoArchivo == 1 ? "btnAccionesMedico btnSeleccionadoMedico" : "btnAccionesMedico "} onClick={this.traerArchivos.bind(this, this.state.usu_id, 1)} id="btnRecibidos">
                  Recibidos
                </button>
              </GridItem>
              <GridItem xs={12}>
                <TableContainer style={{ width: "100%", marginTop: "" }}>
                  <Table aria-label="simple table" className="" style={{ textAlign: "center" }}>
                    <MaterialTable
                      title=""
                      columns={[
                        {
                          title: "Nombre archivo",
                          field: "vla_nombre_archivo",
                        },
                        {
                          title: `${this.state.nombreTipoArchivo}`,
                          field: "vla_fecha_registro",
                          render: (rowData) => <p>{moment(rowData.vla_fecha_registro).format("DD-MM-YYYY HH:mm:ss")}</p>,
                        },
                        {
                          title: "Descargar",
                          render: (rowData) => <img style={{ width: "3vw", cursor: "pointer" }} src={icon_descargar_archivo} onClick={this.descargarArchivo.bind(this, rowData.vla_id)} />,
                        },
                      ]}
                      data={this.state.archivos}
                      options={{
                        actionsColumnIndex: -1,
                        sorting: false,
                        search: true,
                        toolbar: false,
                        actions: true,
                        exportAllData: true,
                        exportButton: true,
                      }}
                      localization={{
                        header: {
                          actions: "a",
                        },
                        toolbar: {
                          searchPlaceholder: "Buscar",
                          searchTooltip: "Buscar",
                          exportTitle: "Exportar",
                          exportAriaLabel: "Exportar",
                          exportCSVName: "Exportar como CSV",
                          exportPDFName: "Exportar como PDF",
                        },
                        body: {
                          emptyDataSourceMessage: "No se encuentran difusiones para este cesfam",
                        },
                        pagination: {
                          labelRowsSelect: "Filas",
                          labelDisplayedRows: "{from}-{to} de {count}",
                        },
                      }}
                    ></MaterialTable>
                  </Table>
                </TableContainer>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.modalReserva} onClose={this.cerrarModalReserva} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="">
          <DialogContent>
            <GridContainer className="agendar-container" style={{ textAlign: "center" }}>
              {this.state.cambiarEstado}
            </GridContainer>
          </DialogContent>
        </Dialog>

        {this.state.modalUsuario ? <VistaUsuarios ocultar={this.ocultarModalUsuario} usuario={this.state.id_usuario}></VistaUsuarios> : null}
        {this.state.agendarUsuario ? <AgendarUsuario ocultar={this.ocultarAgendar} usuario={this.state.usu_id}></AgendarUsuario> : null}
      </>
    );
  }
}
export default connect()(Pacientes);
