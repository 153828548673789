import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";
/* eslint-disable no-use-before-define */

import { store } from "store/index";
import { Grid, MenuItem, Select } from "@material-ui/core";

import InputLabel from "@material-ui/core/InputLabel";
import GridContainer from "components/grid/GridContainer.js";
import GridItem from "components/grid/GridItem.js";
import MultiSelect from "react-multi-select-component";
import MaterialTable from "material-table";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Slider from "@material-ui/core/Slider";

import eliminar from "assets/img/admin/eliminar.svg";

import tipo_vista_1 from "assets/img/admin_totem/tipo_vista_1.png";
import tipo_vista_2 from "assets/img/admin_totem/tipo_vista_2.png";
import tipo_vista_4 from "assets/img/admin_totem/tipo_vista_4.png";
import tipo_vista_5 from "assets/img/admin_totem/tipo_vista_5.png";
import tipo_totem_1 from "assets/img/admin_totem/tipo_totem_1.png";
import tipo_totem_2 from "assets/img/admin_totem/tipo_totem_2.png";
import tipo_totem_3 from "assets/img/admin_totem/tipo_totem_3.png";
import prioritario_manual from "assets/img/admin_totem/prioritario_manual.png";
import ticket_corto from "assets/img/admin_totem/ticket_corto.png";
import ticket_largo from "assets/img/admin_totem/ticket_largo.png";
import vista_pantalla1 from "assets/img/admin_totem/vista_pantalla1.png";
import vista_pantalla2 from "assets/img/admin_totem/vista_pantalla2.png";
import vista_pantalla3 from "assets/img/admin_totem/vista_pantalla3.png";
import top from "assets/img/admin_totem/top.png";
import mid from "assets/img/admin_totem/mid.png";
import opcion from "assets/img/admin_totem/opcion.png";
import bot from "assets/img/admin_totem/bot.png";

import crearTotem from "assets/css/crearTotem.module.css";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { confirmAlert } from "react-confirm-alert";
import HelpIcon from "@material-ui/icons/Help";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VistaTicket from "./VistaTicket";

// CSS
import "react-confirm-alert/src/react-confirm-alert.css";
import { CheckBox } from "@material-ui/icons";

export default class CrearTotem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cesfams: [],
      tickets: 0,
      tot_template: 1,
      tot_tipo: 1,
      tot_popup_prioritario: 1,
      selectedCesfamId: 0,
      tot_nombre: "",
      tot_eslogan: "",
      totemIp: "0.0.0.0",
      tot_tamano_logo: 20,
      sin_uso: 30,
      tiempo_imagen: 15,
      nombre_logo: "",
      abrirTipo_vista: false,
      abrirPrio: false,
      abrirTipo_totem: false,

      confTotem: true,
      confPantalla: false,
      confTicket: false,

      tam_proxAtendido: 70,
      tam_atendido: 120,
      tam_anteriorAtendido: 70,
      tam_opcion: 50,
      tam_banner: 62,
      velocidad_banner: 194,
      msj_banner: "ESTIMADO VECINO, RECUERDE : SI NO PUEDE ASISTIR POR FAVOR ANULE SU HORA DE ATENCIÓN.",
      volumenVideo: 0,

      tam_cod_ticket: 3,
      msj_ticket: "Recuerde que es obligatorio el uso de mascarilla dentro del CESFAM",

      urlLogo: "",
      prueba: "",
      prev_logo: false,
      prev_ticket: false,
      tam_num_pantalla: false,
      seleccion_pan: 0,
      totem_vista: false,
      pantalla_vista: false,

      totem: true,
      pantalla: true,

      logo_comuna: "",
      logo_hs: "",
    };
  }

  componentDidMount() {
    this.cargar_todos_cesfam();
  }

  mostrarAlerta = (mensaje) => {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  };

  cargar_todos_cesfam = () => {
    axios
      .get(global.config.modulos.admin_totem + "traer_todos_cesfam/", {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            cesfams: res.data.datos,
          });
        } else {
          console.log("error en cargar cesfam");
        }
      });
  };

  crear_totem_online = () => {
    if (this.state.tot_template === 0 || this.state.selectedCesfamId === 0 || this.state.tot_popup_prioritario === 0 || this.state.tot_nombre == "" || this.state.tot_tipo === 0 || this.state.tot_tamano_logo == "" || this.state.sin_uso == "" || this.state.tiempo_imagen == "" || this.state.tot_eslogan == "" || this.state.nombre_logo == "") {
      this.mostrarAlerta("No deben quedar campos vacios");
    } else {
      var nomTotem = this.state.tot_nombre;
      var id_cesfam = this.state.selectedCesfamId;
      var tot_template = this.state.tot_template;
      var tot_tipo = this.state.tot_tipo;
      var tot_tamano_logo = this.state.tot_tamano_logo;
      var sin_uso = this.state.sin_uso;
      var tiempo_imagen = this.state.tiempo_imagen;
      var tot_eslogan = this.state.tot_eslogan;
      var tot_popup_prioritario = this.state.tot_popup_prioritario;
      var nombre_logo = this.state.nombre_logo;

      var tam_proxAtendido = this.state.tam_proxAtendido;
      var tam_atendido = this.state.tam_atendido;
      var tam_anteriorAtendido = this.state.tam_anteriorAtendido;
      var tam_opcion = this.state.tam_opcion;
      var tam_banner = this.state.tam_banner;
      var velocidad_banner = this.state.velocidad_banner;
      var msj_banner = this.state.msj_banner;
      var volumenVideo = this.state.volumenVideo;

      var tam_cod_ticket = this.state.tam_cod_ticket;
      var msj_ticket = this.state.msj_ticket;
      var logo_hs = this.state.logo_hs;
      var logo_comuna = this.state.logo_comuna;

      var tickets = this.state.tickets;

      var data = {
        tot_nombre: nomTotem,
        id_cesfam: id_cesfam,
        tot_template: tot_template,
        tot_tipo: tot_tipo,
        tot_popup_prioritario: tot_popup_prioritario,
        tot_tamano_logo: tot_tamano_logo,
        sin_uso: sin_uso,
        tot_eslogan: tot_eslogan,
        tiempo_imagen: tiempo_imagen,
        nombre_logo: nombre_logo,
      };

      axios
        .post(global.config.modulos.admin_totem + "crear_totem/", data, { headers: authHeader() })
        .then((res) => {
          if (res.data.estado) {
            this.mostrarAlerta("Guardado con éxito");
          } else {
            this.mostrarAlerta(res.data.mensaje_error);
          }
        })
        .then((resp) => {
          var data = {
            tam_proxAtendido: tam_proxAtendido,
            tam_atendido: tam_atendido,
            tam_anteriorAtendido: tam_anteriorAtendido,
            tam_opcion: tam_opcion,
            tam_banner: tam_banner,
            velocidad_banner: velocidad_banner,
            msj_banner: msj_banner,
            volumenVideo: volumenVideo,

            tam_cod_ticket: tam_cod_ticket,
            msj_ticket: msj_ticket,
            tickets: tickets,
            logo_hs: logo_hs,
            logo_comuna: logo_comuna,
          };
          axios.post(global.config.modulos.admin_totem + "crear_complementos_totem/", data, { headers: authHeader() }).then((resp) => {
            if (resp.data.estado) {
              this.setState({
                tot_popup_prioritario: 1,
                tot_tipo: 1,
                tot_template: 1,
                selectedCesfamId: 0,
                tot_tamano_logo: 20,
                sin_uso: 30,
                tiempo_imagen: 15,
                tot_nombre: "_",
                tot_eslogan: "",
                nombre_logo: "",

                tam_proxAtendido: 70,
                tam_atendido: 120,
                tam_anteriorAtendido: 70,
                tam_opcion: 50,
                tam_banner: 62,
                velocidad_banner: 194,
                msj_banner: "ESTIMADO VECINO, RECUERDE : SI NO PUEDE ASISTIR POR FAVOR ANULE SU HORA DE ATENCIÓN.",
                volumenVideo: 0,

                tam_cod_ticket: 3,
                msj_ticket: "Recuerde que es obligatorio el uso de mascarilla dentro del CESFAM",
                tickets: 0,
                confTotem: true,
                confTicket: false,
                confPantalla: false,
              });

              var data = {
                tot_nombre: this.state.tot_nombre,
                id_cesfam: this.state.selectedCesfamId,
                tot_template: this.state.tot_template,
                tot_tipo: this.state.tot_tipo,
                tot_popup_prioritario: this.state.tot_popup_prioritario,
                tot_tamano_logo: this.state.tot_tamano_logo,
                sin_uso: this.state.sin_uso,
                tot_eslogan: this.state.tot_eslogan,
                tiempo_imagen: this.state.tiempo_imagen,
                nombre_logo: this.state.nombre_logo,
                tipo: 1,
              };

              axios.patch(global.config.modulos.admin_totem + "crear_totem/", data, { headers: authHeader() }).then((res) => {
                this.setState({ totem: false, pantalla: false }, () => {
                  this.setState({
                    totem: true,
                    pantalla: true,
                    confTotem: true,
                    confTicket: false,
                    confPantalla: false,
                  });
                });
              });
            }
          });
        });
    }
  };
  abrirModal_tipoVista = () => {
    this.setState({ abrirTipo_vista: true });
  };

  abrirModal_tipoTotem = () => {
    this.setState({ abrirTipo_totem: true, totem_vista: true });
  };

  salirTipo_vista = () => {
    this.setState({ abrirTipo_vista: false });
  };

  salirTipo_totem = () => {
    this.setState({
      abrirTipo_totem: false,
      totem_vista: false,
      pantalla_vista: false,
    });
  };

  cambiarValoresAudio = (event, newValue) => {
    this.setState({ volumenVideo: newValue }, () => {
      this.modificarCodigoIframe(2);
    });
  };

  cambiarValoresCesfam = (event, value) => {
    const selectedCesfam = this.state.cesfams.find((cesfam) => cesfam.ces_nombre === value);
    if (selectedCesfam) {
      this.setState(
        {
          selectedCesfamId: selectedCesfam.ces_id,
          tot_nombre: selectedCesfam.ces_nombre,
          tot_eslogan: `Cesfam "${selectedCesfam.ces_nombre}"`,
        },
        () => {
          console.log(this.state.tot_nombre);
          this.modificarCodigoIframe(1);
        }
      );
    }
  };

  salirTipo_vista = () => {
    this.setState({
      abrirTipo_vista: false,
    });
  };

  cargarArchivo = (event, tipo, ubicacion, nombreInput) => {
    if (this.state.tot_nombre == "") {
      this.mostrarAlerta("Debes seleccionar un centro de salud");
      document.getElementById(nombreInput).value = "";
    } else {
      const data = new FormData();
      data.append("file", event[0]);
      data.append("filename", ubicacion);

      this.subirArchivo(data);

      if (tipo == 1) {
        this.setState(
          {
            nombre_logo: event[0].name,
          },
          () => {
            this.modificarCodigoIframe(1);
          }
        );
      } else if (tipo == 2) {
        var datos = {
          nombre: event[0].name,
          tipo: tipo,
        };
        axios.patch(global.config.modulos.admin_totem + "ruta_logo_comuna/", datos, { headers: authHeader() }).then((res) => {
          this.setState({
            logo_hs: res.data.datos.cnt_logo_hs,
          });
        });
      } else if (tipo == 3) {
        var datos = {
          nombre: event[0].name,
          tipo: tipo,
        };

        axios.patch(global.config.modulos.admin_totem + "ruta_logo_comuna/", datos, { headers: authHeader() }).then((res) => {
          this.setState({
            logo_comuna: res.data.datos.cnt_logo_comuna,
          });
        });
      }
    }
  };

  subirArchivo = async (data) => {
    this.setState({ cargando: true });

    await axios.post(global.config.modulos.REACT_APP_URL_SERVER_ + "/carga_archivos_totem", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    this.setState({ cargando: false });
    return true;
  };

  muteAudio = () => {
    this.setState(
      {
        volumenVideo: 0,
      },
      () => {
        this.modificarCodigoIframe(2);
      }
    );
  };

  abrirModal_prio = () => {
    this.setState({
      abrirPrio: true,
    });
  };
  cerrarModal_prio = () => {
    this.setState({
      abrirPrio: false,
    });
  };

  abrirTicket = () => {
    this.setState({
      prev_ticket: true,
    });
  };

  cerrarTicket = () => {
    this.setState({
      prev_ticket: false,
    });
  };
  vista_totem = () => {
    this.setState({
      totem_vista: true,
      pantalla_vista: false,
    });
  };

  vista_pantalla = () => {
    this.setState({
      totem_vista: false,
      pantalla_vista: true,
    });
  };

  tamano_num_pantalla = (valor) => {
    this.setState({
      tam_num_pantalla: true,
      seleccion_pan: valor,
    });
  };

  cerrar_tamano_num_pantalla = () => {
    this.setState({
      tam_num_pantalla: false,
    });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    console.log(value);
    this.setState({ [name]: value });
  };

  valoresTotem = (e) => {
    const { name, value } = e.target;

    if (this.state.tot_nombre == "") {
      this.setState({ tot_nombre: "Nombre Totem" }, () => {});
    }
    if (this.state.tot_eslogan == "") {
      this.setState({ tot_eslogan: "Titulo totem" }, () => {});
    }

    this.setState({ [name]: value }, () => {
      this.modificarCodigoIframe(1);
      if (name == "tot_tipo") {
        this.modificarCodigoIframe(2);
      }
    });

    if (name == "tot_tipo" && value == 3) {
      document.getElementById("tam_proxAtendido").disabled = true;
      document.getElementById("tam_opcion").disabled = true;
      document.getElementById("tam_atendido").disabled = true;
      document.getElementById("tam_anteriorAtendido").disabled = true;
    } else {
      document.getElementById("tam_proxAtendido").disabled = false;
      document.getElementById("tam_opcion").disabled = false;
      document.getElementById("tam_atendido").disabled = false;
      document.getElementById("tam_anteriorAtendido").disabled = false;
    }
  };

  valoresPantalla = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.modificarCodigoIframe(2);
    });
  };

  modificarCodigoIframe = (tipo) => {
    if (tipo == 1) {
      var data = {
        tot_nombre: this.state.tot_nombre,
        id_cesfam: this.state.selectedCesfamId,
        tot_template: this.state.tot_template,
        tot_tipo: this.state.tot_tipo,
        tot_popup_prioritario: this.state.tot_popup_prioritario,
        tot_tamano_logo: this.state.tot_tamano_logo,
        sin_uso: this.state.sin_uso,
        tot_eslogan: this.state.tot_eslogan,
        tiempo_imagen: this.state.tiempo_imagen,
        nombre_logo: this.state.nombre_logo,
        tipo: tipo,
      };
    } else if (tipo == 2) {
      var data = {
        tam_proxAtendido: this.state.tam_proxAtendido,
        tam_atendido: this.state.tam_atendido,
        tam_anteriorAtendido: this.state.tam_anteriorAtendido,
        tam_opcion: this.state.tam_opcion,
        tam_banner: this.state.tam_banner,
        velocidad_banner: this.state.velocidad_banner,
        msj_banner: this.state.msj_banner,
        volumenVideo: this.state.volumenVideo,
        tipo: tipo,
      };
    }

    axios.patch(global.config.modulos.admin_totem + "crear_totem/", data, { headers: authHeader() }).then((res) => {
      this.setState({ totem: false }, () => {
        this.setState({ totem: true });
      });
    });
  };

  cambiarVista = (vista) => {
    if (vista == 1) {
      this.setState({
        confTotem: true,
        confPantalla: false,
        confTicket: false,
      });
    } else if (vista == 2) {
      this.setState({
        confTotem: false,
        confPantalla: true,
        confTicket: false,
      });
    } else if (vista == 3) {
      this.setState({
        confTotem: false,
        confPantalla: false,
        confTicket: true,
      });
    }
  };

  render() {
    return (
      <>
        <GridContainer style={{ width: "100%", padding: "0px" }}>
          <GridItem xs={12} sm={12} md={12} className={crearTotem.Contenedor_crearTotem}>
            <Grid xs={12} sm={12} md={12} className={crearTotem.btn_crearTotem} style={{ marginTop: "45px" }}>
              <a
                className={this.state.confTotem ? "btnAcciones botonAccionActivo" : "btnAcciones"}
                onClick={() => this.cambiarVista(1)}
                style={{
                  width: "13%",
                  marginRight: "8px",
                  borderRadius: "10px",
                }}
              >
                {"Configuración"} <br /> {"tótem"}
              </a>
              <a
                className={this.state.confPantalla ? "btnAcciones botonAccionActivo" : "btnAcciones"}
                onClick={() => this.cambiarVista(2)}
                style={{
                  width: "13%",
                  marginRight: "8px",
                  borderRadius: "10px",
                }}
              >
                {"Configuración"} <br /> {" pantalla"}
              </a>
              <a
                className={this.state.confTicket ? "btnAcciones botonAccionActivo" : "btnAcciones"}
                onClick={() => this.cambiarVista(3)}
                style={{
                  width: "13%",
                  marginRight: "8px",
                  borderRadius: "10px",
                }}
              >
                {"Configuración"} <br /> {"ticket"}
              </a>
            </Grid>

            <Grid item xs={12} sm={12}>
              {this.state.confTotem && (
                <>
                  <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    <div style={{ width: "55%" }}>
                      <Grid item xs={12} sm={12} className={crearTotem.contenedor_filaCrearTotem}>
                        <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                          <InputLabel className={crearTotem.textoTitulos}>Seleccionar Centro de salud</InputLabel>

                          <Autocomplete
                            id="select_cesfams"
                            options={this.state.cesfams.map((cesfam) => cesfam.ces_nombre)}
                            name="select_cesfams"
                            disableClearable
                            value={this.state.tot_nombre}
                            className={crearTotem.selectores}
                            style={{ padding: "0px 0px 0px 10px" }}
                            onChange={this.cambiarValoresCesfam}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  type: "search",
                                  disableUnderline: true,
                                }}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                          <InputLabel className={crearTotem.textoTitulos}>Nombre Del totem</InputLabel>
                          <input type="text" id="tot_nombre" name="tot_nombre" value={this.state.tot_nombre} onChange={this.cambiarValores} className={crearTotem.input} />
                        </Grid>

                        <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                          <InputLabel className={crearTotem.textoTitulos}>Título tótem</InputLabel>
                          <input type="text" id="tot_eslogan" name="tot_eslogan" value={this.state.tot_eslogan} className={crearTotem.input} onChange={this.valoresTotem} />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12} className={crearTotem.contenedor_filaCrearTotem}>
                        <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                          <InputLabel className={crearTotem.textoTitulos}>
                            Tipo de Vista <HelpIcon onClick={this.abrirModal_tipoVista} className={crearTotem.helpIcon} />{" "}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            disableUnderline={true}
                            id="tot_template"
                            name="tot_template"
                            className={crearTotem.selectores}
                            value={this.state.tot_template}
                            onChange={this.valoresTotem}
                            inputProps={{
                              color: "red",
                            }}
                          >
                            <MenuItem value={0}>Seleccione Tipo Vista</MenuItem>
                            <MenuItem value={1}>Vista comun del totem</MenuItem>
                            <MenuItem value={2}>Vista principal para pantalla pequeña</MenuItem>
                            <MenuItem value={4}>Vista sin imagen y texto de fila de atención</MenuItem>
                            <MenuItem value={5}>Vista principal "Sin Rut"</MenuItem>
                          </Select>
                        </Grid>

                        <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                          <InputLabel className={crearTotem.textoTitulos}>
                            Tipo de tótem{"  "}
                            <HelpIcon onClick={this.abrirModal_tipoTotem} className={crearTotem.helpIcon} />
                          </InputLabel>
                          <Select labelId="demo-simple-select-label" disableUnderline={true} id="tot_tipo" name="tot_tipo" className={crearTotem.selectores} value={this.state.tot_tipo} onChange={this.valoresTotem}>
                            <MenuItem value={0}>Seleccione Tipo de totem</MenuItem>
                            <MenuItem value={1}>Gestor de filas.</MenuItem>
                            <MenuItem value={2}>Gestor de filas con mas de 4 opciones.</MenuItem>
                            <MenuItem value={3}>Agendar horas.</MenuItem>
                          </Select>
                        </Grid>

                        <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                          <InputLabel className={crearTotem.textoTitulos}>
                            Selección Prioritario <HelpIcon onClick={this.abrirModal_prio} className={crearTotem.helpIcon} />
                          </InputLabel>
                          <Select labelId="demo-simple-select-label" disableUnderline={true} id="tot_popup_prioritario" name="tot_popup_prioritario" className={crearTotem.selectores} value={this.state.tot_popup_prioritario} onChange={this.valoresTotem}>
                            <MenuItem value={0}>Seleccione Prioritario</MenuItem>
                            <MenuItem value={1}>Manual</MenuItem>
                            <MenuItem value={2}>Automático</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} className={crearTotem.contenedor_filaCrearTotem}>
                        <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                          <InputLabel className={crearTotem.textoTitulos}>Tamaño Logo </InputLabel>
                          <input type="number" id="tot_tamano_logo" name="tot_tamano_logo" className={crearTotem.input} value={this.state.tot_tamano_logo} min="1" onChange={this.valoresTotem} />
                        </Grid>

                        <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                          <InputLabel className={crearTotem.textoTitulos}>Tiempo sin uso (segundos)</InputLabel>
                          <input type="number" id="sin_uso" name="sin_uso" className={crearTotem.input} value={this.state.sin_uso} onChange={this.cambiarValores} />
                        </Grid>

                        <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                          <InputLabel className={crearTotem.textoTitulos}>Tiempo por imagen (segundos)</InputLabel>
                          <input type="number" min="0" id="tiempo_imagen" name="tiempo_imagen" className={crearTotem.input} value={this.state.tiempo_imagen} onChange={this.cambiarValores} />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12} className={crearTotem.contenedor_filaCrearTotem}>
                        <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                          <InputLabel className={crearTotem.textoTitulos}>Logo de totem</InputLabel>
                          <input style={{ width: "97%" }} className={crearTotem.inputFile} accept=".png" type="file" id="logoTotem" name="logoTotem" onChange={(e) => this.cargarArchivo(e.target.files, 1, "logo_comuna/", "logoTotem")} />
                        </Grid>
                        <Grid xs={4} sm={4}></Grid>
                        <Grid xs={4} sm={4}></Grid>
                      </Grid>
                      <a
                        className={"btnAcciones btnAgregarAcceso"}
                        style={{ width: "20%", marginTop: "3%" }}
                        onClick={() => {
                          this.cambiarVista(2);
                        }}
                      >
                        {"Siguiente"}
                      </a>
                    </div>
                    <div style={{ width: "45%" }}>
                      {this.state.totem ? (
                        <iframe
                          src="https://panelv2hs.cl/totem/inicio/13"
                          sandbox="allow-same-origin allow-scripts"
                          width="768"
                          height="1024"
                          style={{
                            display: "flex",
                            transform: "scale(0.6) translate(0%, -47%)",
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              )}

              {this.state.confPantalla && (
                <>
                  {this.state.tot_tipo === 1 ? (
                    <>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "55%" }}>
                          <Grid item xs={12} sm={12} className={crearTotem.contenedor_filaCrearTotem}>
                            <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                              <InputLabel className={crearTotem.textoTitulos}>
                                Tamaño proxima atención
                                <HelpIcon onClick={() => this.tamano_num_pantalla(1)} className={crearTotem.helpIcon} />
                              </InputLabel>
                              <input type="number" id="tam_proxAtendido" name="tam_proxAtendido" className={crearTotem.input} defaultValue={this.state.tam_proxAtendido} onChange={this.valoresPantalla} />
                            </Grid>
                            <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                              <InputLabel className={crearTotem.textoTitulos}>
                                Tamaño atención actual
                                <HelpIcon onClick={() => this.tamano_num_pantalla(2)} className={crearTotem.helpIcon} />
                              </InputLabel>
                              <input type="number" id="tam_atendido" name="tam_atendido" className={crearTotem.input} defaultValue={this.state.tam_atendido} onChange={this.valoresPantalla} />
                            </Grid>
                            <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                              <InputLabel className={crearTotem.textoTitulos}>
                                Tamaño de modulo
                                <HelpIcon onClick={() => this.tamano_num_pantalla(3)} className={crearTotem.helpIcon} />
                              </InputLabel>
                              <input type="number" id="tam_opcion" name="tam_opcion" className={crearTotem.input} defaultValue={this.state.tam_opcion} onChange={this.valoresPantalla} />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} className={crearTotem.contenedor_filaCrearTotem}>
                            <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                              <InputLabel className={crearTotem.textoTitulos}>
                                Tamaño última atención
                                <HelpIcon className={crearTotem.helpIcon} onClick={() => this.tamano_num_pantalla(4)} />
                              </InputLabel>
                              <input type="number" id="tam_anteriorAtendido" name="tam_anteriorAtendido" className={crearTotem.input} defaultValue={this.state.tam_anteriorAtendido} onChange={this.valoresPantalla} />
                            </Grid>

                            <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                              <InputLabel className={crearTotem.textoTitulos}>Tamaño del banner (pixeles)</InputLabel>
                              <input type="number" id="tam_banner" name="tam_banner" className={crearTotem.input} defaultValue={this.state.tam_banner} onChange={this.valoresPantalla} />
                            </Grid>

                            <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                              <InputLabel className={crearTotem.textoTitulos} style={{ whiteSpace: "nowrap" }}>
                                Velocidad del banner
                              </InputLabel>
                              <input type="number" id="velocidad_banner" name="velocidad_banner" className={crearTotem.input} defaultValue={this.state.velocidad_banner} onChange={this.valoresPantalla} />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} className={crearTotem.contenedor_filaCrearTotem}>
                            <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                              <InputLabel
                                className={crearTotem.textoTitulos}
                                style={{
                                  marginTop: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                Volumen video
                                <VolumeOffIcon onClick={this.muteAudio} className={crearTotem.audioMute} />
                              </InputLabel>
                              <Slider
                                id="volumenVideo"
                                name="volumenVideo"
                                onChange={this.cambiarValoresAudio}
                                min={0}
                                step={0.1}
                                max={1}
                                valueLabelDisplay="auto"
                                value={this.state.volumenVideo}
                                style={{
                                  marginBottom: "10px",
                                  width: "100%",
                                  color: "#eaa028",
                                }}
                              />
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={12} className={crearTotem.MargenGrid}>
                            <InputLabel className={crearTotem.textoTitulos}>Mensaje banner</InputLabel>
                            <textarea type="text" id="msj_banner" name="msj_banner" onChange={this.valoresPantalla} className={crearTotem.inputTextArea} value={this.state.msj_banner} />
                          </Grid>
                          <a
                            className={"btnAcciones btnAgregarAcceso"}
                            style={{ width: "20%", marginTop: "3%" }}
                            onClick={() => {
                              this.cambiarVista(3);
                            }}
                          >
                            {"Siguiente"}
                          </a>
                        </div>
                        <div style={{ width: "45%" }}>
                          {this.state.totem && (
                            <iframe
                              src="https://panelv2hs.cl/pantalla/inicio/13"
                              sandbox="allow-same-origin allow-scripts"
                              width="1920"
                              height="1080"
                              style={{
                                display: "flex",
                                transform: "scale(35%) translate(-91%, -95%)",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </>
                  ) : this.state.tot_tipo === 2 ? (
                    <>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "55%" }}>
                          <Grid item xs={12} sm={12} className={crearTotem.contenedor_filaCrearTotem}>
                            <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                              <InputLabel className={crearTotem.textoTitulos}>
                                Tamaño de modulo
                                <HelpIcon onClick={() => this.tamano_num_pantalla(3)} className={crearTotem.helpIcon} />
                              </InputLabel>
                              <input type="number" id="tam_opcion" name="tam_opcion" className={crearTotem.input} defaultValue={this.state.tam_opcion} onChange={this.valoresPantalla} />
                            </Grid>
                            <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                              <InputLabel className={crearTotem.textoTitulos}>
                                Tamaño última atención
                                <HelpIcon className={crearTotem.helpIcon} onClick={() => this.tamano_num_pantalla(4)} />
                              </InputLabel>
                              <input type="number" id="tam_anteriorAtendido" name="tam_anteriorAtendido" className={crearTotem.input} defaultValue={this.state.tam_anteriorAtendido} onChange={this.valoresPantalla} />
                            </Grid>
                            <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                              <InputLabel
                                className={crearTotem.textoTitulos}
                                style={{
                                  marginTop: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                Volumen video
                                <VolumeOffIcon onClick={this.muteAudio} className={crearTotem.audioMute} />
                              </InputLabel>
                              <Slider
                                id="volumenVideo"
                                name="volumenVideo"
                                onChange={this.cambiarValoresAudio}
                                min={0}
                                step={0.1}
                                max={1}
                                valueLabelDisplay="auto"
                                value={this.state.volumenVideo}
                                style={{
                                  marginBottom: "10px",
                                  width: "100%",
                                  color: "#eaa028",
                                }}
                              />
                            </Grid>
                          </Grid>

                          <a
                            className={"btnAcciones btnAgregarAcceso"}
                            style={{ width: "20%", marginTop: "3%" }}
                            onClick={() => {
                              this.cambiarVista(3);
                            }}
                          >
                            {"Siguiente"}
                          </a>
                        </div>
                        <div style={{ width: "45%" }}>
                          {this.state.totem && (
                            <iframe
                              src="https://panelv2hs.cl/pantalla/inicio/13"
                              sandbox="allow-same-origin allow-scripts"
                              width="1920"
                              height="1080"
                              style={{
                                display: "flex",
                                transform: "scale(35%) translate(-91%, -95%)",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </>
                  ) : this.state.tot_tipo === 3 ? (
                    <>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "55%" }}>
                          <Grid item xs={12} sm={12} className={crearTotem.contenedor_filaCrearTotem}>
                            <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                              <InputLabel className={crearTotem.textoTitulos}>Tamaño del banner (pixeles)</InputLabel>
                              <input type="number" id="tam_banner" name="tam_banner" className={crearTotem.input} defaultValue={this.state.tam_banner} onChange={this.valoresPantalla} />
                            </Grid>

                            <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                              <InputLabel className={crearTotem.textoTitulos} style={{ whiteSpace: "nowrap" }}>
                                Velocidad del banner
                              </InputLabel>
                              <input type="number" id="velocidad_banner" name="velocidad_banner" className={crearTotem.input} defaultValue={this.state.velocidad_banner} onChange={this.valoresPantalla} />
                            </Grid>
                            <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                              <InputLabel
                                className={crearTotem.textoTitulos}
                                style={{
                                  marginTop: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                Volumen video
                                <VolumeOffIcon onClick={this.muteAudio} className={crearTotem.audioMute} />
                              </InputLabel>
                              <Slider
                                id="volumenVideo"
                                name="volumenVideo"
                                onChange={this.cambiarValoresAudio}
                                min={0}
                                step={0.1}
                                max={1}
                                valueLabelDisplay="auto"
                                value={this.state.volumenVideo}
                                style={{
                                  marginBottom: "10px",
                                  width: "100%",
                                  color: "#eaa028",
                                }}
                              />
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={12} className={crearTotem.MargenGrid}>
                            <InputLabel className={crearTotem.textoTitulos}>Mensaje banner</InputLabel>
                            <textarea type="text" id="msj_banner" name="msj_banner" onChange={this.valoresPantalla} className={crearTotem.inputTextArea} value={this.state.msj_banner} />
                          </Grid>
                          <a
                            className={"btnAcciones btnAgregarAcceso"}
                            style={{ width: "20%", marginTop: "3%" }}
                            onClick={() => {
                              this.cambiarVista(3);
                            }}
                          >
                            {"Siguiente"}
                          </a>
                        </div>
                        <div style={{ width: "45%" }}>
                          {this.state.totem && (
                            <iframe
                              src="https://panelv2hs.cl/pantalla/inicio/13"
                              sandbox="allow-same-origin allow-scripts"
                              width="1920"
                              height="1080"
                              style={{
                                display: "flex",
                                transform: "scale(35%) translate(-91%, -95%)",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
              )}

              {this.state.confTicket && (
                <>
                  <div style={{ display: "flex" }}>
                    {this.state.tot_tipo == 3 ? (
                      <div style={{ width: "55%" }}>
                        <div className={crearTotem.contenedor_filaCrearTotem}>
                          <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                            <InputLabel className={crearTotem.textoTitulos}>Logo comuna</InputLabel>
                            <input style={{ width: "97%" }} className={crearTotem.inputFile} accept=".png" type="file" id="logoComuna" name="logoComuna" onChange={(e) => this.cargarArchivo(e.target.files, 3, "publicidad/totem/tickets/", "logoComuna")} />
                          </Grid>
                        </div>
                        <a
                          className={"btnAcciones btnAgregarAcceso"}
                          style={{ width: "20%" }}
                          onClick={() => {
                            this.crear_totem_online();
                          }}
                        >
                          Crear
                        </a>
                      </div>
                    ) : (
                      <>
                        <div style={{ width: "55%" }}>
                          <div className={crearTotem.contenedor_filaCrearTotem}>
                            <Grid item xs={12} sm={12} className={crearTotem.MargenGrid}>
                              <InputLabel className={crearTotem.textoTitulos}>Mensaje ticket</InputLabel>
                              <textarea type="text" id="msj_ticket" name="msj_ticket" onChange={this.cambiarValores} className={crearTotem.inputTextArea} value={this.state.msj_ticket} />
                            </Grid>
                          </div>

                          <div className={crearTotem.contenedor_filaCrearTotem}>
                            <Grid item xs={4} sm={4} className={crearTotem.MargenGrid}>
                              <InputLabel className={crearTotem.textoTitulos}>Tamaño codigo ticket</InputLabel>
                              <input type="number" id="tam_cod_ticket" name="tam_cod_ticket" min="1" max="7" className={crearTotem.input} defaultValue={this.state.tam_cod_ticket} onChange={this.cambiarValores} />
                            </Grid>
                          </div>

                          <a
                            className={"btnAcciones btnAgregarAcceso"}
                            style={{ width: "20%" }}
                            onClick={() => {
                              this.crear_totem_online();
                            }}
                          >
                            Crear
                          </a>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </Grid>
          </GridItem>
        </GridContainer>

        {/* DIALOG ------------------------------ */}
        <Dialog open={this.state.abrirTipo_vista} onClose={this.salirTipo_vista} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
          <DialogContent>
            <h1>Tipo de vista</h1>
            <br></br>
            <br></br>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3}>
                <Grid item xs={8} sm={8}>
                  {this.state.tot_template == 0 ? <InputLabel className={crearTotem.textoTitulos}>Al seleccionar una opción podrá ver una imagen de ejemplo de como se ve este tipo de vista </InputLabel> : <></>}
                  {this.state.tot_template == 1 ? <img src={tipo_vista_1} width="700px" height="900px" /> : <></>}
                  {this.state.tot_template == 2 ? <img src={tipo_vista_2} width="700px" height="900px" /> : <></>}
                  {this.state.tot_template == 4 ? <img src={tipo_vista_4} width="700px" height="900px" /> : <></>}
                  {this.state.tot_template == 5 ? <img src={tipo_vista_5} width="768px" height="1024px" style={{ transform: "scale(0.8)" }} /> : <></>}
                </Grid>
              </Grid>
            </Grid>
            <br></br>
            <br></br>
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.abrirPrio} onClose={this.cerrarModal_prio} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
          <DialogContent>
            <h1>Prioridad</h1>
            <br></br>
            <br></br>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={3}
                style={{
                  justifyContent: "center",
                }}
              >
                <Grid item xs={8} sm={8}>
                  {this.state.tot_popup_prioritario == 0 ? <InputLabel className={crearTotem.textoTitulos}>Al seleccionar una opción podrá ver una imagen de ejemplo de como se ve el tipo de prioridad manual </InputLabel> : <></>}
                  {this.state.tot_popup_prioritario == 1 ? <img src={prioritario_manual} width="100%" /> : <></>}
                  {this.state.tot_popup_prioritario == 2 ? <InputLabel className={crearTotem.textoTitulos}>La opción automatica no se aplica el popap de prioritario </InputLabel> : <></>}
                </Grid>
              </Grid>
            </Grid>
            <br></br>
            <br></br>
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.abrirTipo_totem} onClose={this.salirTipo_totem} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
          <DialogContent>
            <h1>Tipo de totem</h1>
            <br></br>
            <br></br>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={3}
                style={{
                  justifyContent: "center",
                }}
              >
                {this.state.tot_tipo === 0 ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <InputLabel className={crearTotem.textoTitulos}>Al seleccionar una opción podrá ver una imagen de ejemplo de cómo se ve este tipo de tótem</InputLabel>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <a
                        className={this.state.totem_vista ? "btnAcciones botonAccionActivo" : "btnAcciones"}
                        style={{ height: "50px", width: "25%" }}
                        onClick={() => {
                          this.vista_totem();
                        }}
                      >
                        Tótem
                      </a>
                      <a
                        className={this.state.pantalla_vista ? "btnAcciones botonAccionActivo" : "btnAcciones"}
                        style={{ height: "50px", width: "25%" }}
                        onClick={() => {
                          this.vista_pantalla();
                        }}
                      >
                        Pantalla Tv
                      </a>
                    </Grid>
                    <Grid item xs={8} sm={8} style={{ display: "flex", justifyContent: "center" }}>
                      {this.state.totem_vista && (
                        <>
                          {this.state.tot_tipo === 1 && this.state.totem_vista ? <img src={tipo_totem_1} width="480px" height="680px" /> : null}

                          {this.state.tot_tipo === 2 && this.state.totem_vista ? <img src={tipo_totem_2} width="480px" height="680px" /> : null}

                          {this.state.tot_tipo === 3 && this.state.totem_vista ? <img src={tipo_totem_3} width="480px" height="680px" /> : null}
                        </>
                      )}

                      {this.state.pantalla_vista && (
                        <>
                          {this.state.tot_tipo === 1 && this.state.pantalla_vista ? <img src={vista_pantalla1} width="560px" height="300px" /> : null}

                          {this.state.tot_tipo === 2 && this.state.pantalla_vista ? <img src={vista_pantalla2} width="560px" height="300px" /> : null}

                          {this.state.tot_tipo === 3 && this.state.pantalla_vista ? <img src={vista_pantalla3} width="560px" height="300px" /> : null}
                        </>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <br></br>
            <br></br>
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.prev_ticket} onClose={this.cerrarTicket} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="pacientesAtendidos">
          <DialogContent>
            <h1>Seleccion de ticket </h1>
            <br></br>
            <br></br>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3} style={{ justifyContent: "center" }}>
                {this.state.tickets == 1 ? <img src={ticket_largo} style={{ width: "50%" }} /> : <></>}
                {this.state.tickets == 2 ? <img src={tipo_totem_2} style={{ width: "50%" }} /> : <></>}
                {this.state.tickets == 4 ? <img src={ticket_corto} style={{ width: "50%" }} /> : <></>}
              </Grid>
            </Grid>
            <br></br>
            <br></br>
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.tam_num_pantalla} onClose={this.cerrar_tamano_num_pantalla} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogContent className={crearTotem.num_pantallas}>
            <h1>Vista previa</h1>
            {this.state.seleccion_pan == 1 ? <img src={top} width="50%" /> : <></>}
            {this.state.seleccion_pan == 2 ? <img src={mid} width="50%" /> : <></>}
            {this.state.seleccion_pan == 3 ? <img src={opcion} width="50%" /> : <></>}
            {this.state.seleccion_pan == 4 ? <img src={bot} width="50%" /> : <></>}
          </DialogContent>
        </Dialog>
      </>
    );
  }
}
