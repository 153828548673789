import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "store/index";

// core components
import { Grid } from "@material-ui/core";

import GraficoTicketsA from "./GraficosTickets.js";
import GraficoDemanda from "./GraficoDemandaArea.js";
import GraficoDemandaModulos from "./GraficoDemandaModulos.js";
import GraficoFuga from "./GraficoFuga.js";
import GraficoEspera from "./GraficoTiempoEspera.js";
import GraficoAtencion from "./GraficoTiempoAtencion.js";

import "moment/locale/es";

//CSS
import crearTotem from "assets/css/crearTotem.module.css";

export default class MenuTotem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mostrarTicks: true,
      mostrarAten: false,
      mostrarMod: false,
      mostrarFuga: false,
      mostrarEspera: false,
      mostrarTiempoAten: false,
      opciones: [
        { label: "Por Cesfam", val: 0 },
        { label: "Por Comuna", val: 1 },
      ],
      tiempos: [
        { label: "Diario", val: 0 },
        { label: "Semanal", val: 1 },
        { label: "Mensual", val: 2 },
        { label: "Anual", val: 3 },
      ],
      comunas: [],
      atenciones: [],
      opAte: [],
      opAteVisible: false,
      modulos: [],
      opPref: [
        { label: "Normal", val: 0 },
        { label: "Preferencial", val: 1 },
        { label: "Normal y Preferencial", val: 2 },
      ],
      cesfam: store.getState().cesfam,
      totem: store.getState().totem,
    };
  }

  componentDidMount() {
    this.cargarComunas();
    this.cargarOpciones();
  }

  cargarComunas = async () => {
    const apiUrl = global.config.modulos.admin_totem + "lista_comunas/?cesfam=" + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      res.data.comunas.map((res) => {
        this.setState({
          comunas: [...this.state.comunas, { label: res.com_nombre, val: res.cac_id, id: res.cac_id }],
        });
      });
    });
  };

  cargarOpciones = async () => {
    const apiUrl = global.config.modulos.admin_totem + "lista_opciones/?totem=" + this.state.totem;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      res.data.opciones.map((res) => {
        this.setState({
          atenciones: [...this.state.atenciones, { label: res.opc_nombre, val: res.opc_id, id: res.opc_id }],
        });
      });
    });
  };

  cargarSubOpciones = async (main_op) => {
    const apiUrl = global.config.modulos.admin_totem + "lista_opciones_emp/?opcion=" + main_op;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      this.setState({
        opAte: [{ label: "Todas las opciones", val: 0, id: 0 }],
      });
      if (res.data.opciones.length > 0) {
        this.setState({ opAteVisible: true });
        res.data.opciones.map((res) => {
          this.setState({
            opAte: [...this.state.opAte, { label: res.nom_sec, val: res.id_sec, id: res.id_sec }],
          });
        });
      } else {
        this.setState({ opAteVisible: false });
      }
    });
  };

  cargarModulos = async (area, subarea) => {
    var apiUrl = global.config.modulos.admin_totem + "lista_modulos/?area=" + area;
    if (subarea !== null) {
      apiUrl = apiUrl + "&sub_opcion=" + subarea;
    }
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      this.setState({
        modulos: [{ label: "Todos los modulos", val: 0, id: 0 }],
      });
      res.data.modulos.map((res) => {
        this.setState({
          modulos: [...this.state.modulos, { label: res.usp_nombre, val: res.usp_id, id: res.usp_id }],
        });
      });
    });
  };

  mostrarOpcion(opcion) {
    switch (opcion) {
      case 1:
        this.setAllFalse();
        this.setState({ mostrarTicks: true });
        break;
      case 2:
        this.setAllFalse();
        this.setState({ mostrarAten: true });
        break;
      case 3:
        this.setAllFalse();
        this.setState({ mostrarMod: true });
        break;
      case 4:
        this.setAllFalse();
        this.setState({ mostrarFuga: true });
        break;
      case 5:
        this.setAllFalse();
        this.setState({ mostrarEspera: true });
        break;
      case 6:
        this.setAllFalse();
        this.setState({ mostrarTiempoAten: true });
        break;
      default:
        this.setAllFalse();
        break;
    }
  }

  setAllFalse() {
    this.setState({
      mostrarTicks: false,
      mostrarAten: false,
      mostrarMod: false,
      mostrarFuga: false,
      mostrarEspera: false,
      mostrarTiempoAten: false,
      opAteVisible: false,
    });
  }

  render() {
    return (
      <div>
        <Grid item xs={12} sm={12}>
          <a
            className={this.state.mostrarTicks ? "btnOpcion  buttonSelected" : "btnOpcion fondoNaranjo"}
            style={{
              cursor: "pointer",
              marginRight: 10,
              width: "13%",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={this.mostrarOpcion.bind(this, 1)}
          >
            Tickets
          </a>
          <a
            className={this.state.mostrarAten ? "btnOpcion  buttonSelected" : "btnOpcion fondoNaranjo"}
            style={{
              cursor: "pointer",
              marginRight: 10,
              width: "13%",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={this.mostrarOpcion.bind(this, 2)}
          >
            Área de Atención
          </a>
          <a
            className={this.state.mostrarMod ? "btnOpcion  buttonSelected" : "btnOpcion fondoNaranjo"}
            style={{
              cursor: "pointer",
              marginRight: 10,
              width: "13%",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={this.mostrarOpcion.bind(this, 3)}
          >
            Modulos
          </a>
          <a
            className={this.state.mostrarFuga ? "btnOpcion  buttonSelected" : "btnOpcion fondoNaranjo"}
            style={{
              cursor: "pointer",
              marginRight: 10,
              width: "13%",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={this.mostrarOpcion.bind(this, 4)}
          >
            Fuga de Pacientes
          </a>
          <a
            className={this.state.mostrarEspera ? "btnOpcion  buttonSelected" : "btnOpcion fondoNaranjo"}
            style={{
              cursor: "pointer",
              marginRight: 10,
              width: "13%",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={this.mostrarOpcion.bind(this, 5)}
          >
            Tiempos de Espera
          </a>
          <a
            className={this.state.mostrarTiempoAten ? "btnOpcion  buttonSelected" : "btnOpcion fondoNaranjo"}
            style={{
              cursor: "pointer",
              marginRight: 10,
              width: "13%",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={this.mostrarOpcion.bind(this, 6)}
          >
            Tiempo de Atención
          </a>
        </Grid>
        <br></br>
        <div class="middle-content">
          {this.state.mostrarTicks ? <GraficoTicketsA opciones={this.state.opciones} comunas={this.state.comunas} tiempos={this.state.tiempos} atenciones={this.state.atenciones} opAte={this.state.opAte} cargarSubOpciones={this.cargarSubOpciones} modulos={this.state.modulos} cargarModulos={this.cargarModulos} opAteVisible={this.state.opAteVisible} opPref={this.state.opPref} /> : null}
          {this.state.mostrarAten ? <GraficoDemanda opciones={this.state.opciones} comunas={this.state.comunas} tiempos={this.state.tiempos} atenciones={this.state.atenciones} opAte={this.state.opAte} cargarSubOpciones={this.cargarSubOpciones} opAteVisible={this.state.opAteVisible} opPref={this.state.opPref} /> : null}
          {this.state.mostrarMod ? <GraficoDemandaModulos opciones={this.state.opciones} comunas={this.state.comunas} tiempos={this.state.tiempos} atenciones={this.state.atenciones} opAte={this.state.opAte} cargarSubOpciones={this.cargarSubOpciones} modulos={this.state.modulos} cargarModulos={this.cargarModulos} opAteVisible={this.state.opAteVisible} opPref={this.state.opPref} /> : null}

          {this.state.mostrarFuga ? <GraficoFuga opciones={this.state.opciones} comunas={this.state.comunas} tiempos={this.state.tiempos} atenciones={this.state.atenciones} opAte={this.state.opAte} cargarSubOpciones={this.cargarSubOpciones} modulos={this.state.modulos} cargarModulos={this.cargarModulos} opAteVisible={this.state.opAteVisible} opPref={this.state.opPref} /> : null}

          {this.state.mostrarEspera ? <GraficoEspera opciones={this.state.opciones} comunas={this.state.comunas} tiempos={this.state.tiempos} atenciones={this.state.atenciones} opAte={this.state.opAte} cargarSubOpciones={this.cargarSubOpciones} opAteVisible={this.state.opAteVisible} opPref={this.state.opPref} modulos={this.state.modulos} cargarModulos={this.cargarModulos} /> : null}

          {this.state.mostrarTiempoAten ? <GraficoAtencion opciones={this.state.opciones} comunas={this.state.comunas} tiempos={this.state.tiempos} atenciones={this.state.atenciones} opAte={this.state.opAte} cargarSubOpciones={this.cargarSubOpciones} opAteVisible={this.state.opAteVisible} opPref={this.state.opPref} modulos={this.state.modulos} cargarModulos={this.cargarModulos} /> : null}
        </div>
      </div>
    );
  }
}
