import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";
import play from "../../assets/img/usuarios/play.svg";
import pause from "../../assets/img/usuarios/pause.svg";
import audio_salusin from "../../assets/img/usuarios/audio_salusin.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import "./../../assets/css/usuarios.css";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

export default class EscucharAudioRechazo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ocultar: props.ocultar,
      grabacion: props.grabacion,
      texto: props.texto,
      open: props.open,
      mostrarK: true,
      fecha: props.fecha,
      audio: null,
      paused: false,
      porcentajeCarga: 0,
      duracion: 0,
      tiempoActual: 0,
    };

    if (this.state.grabacion === null) {
      if (this.state.texto === null) {
        this.state.texto = "No se ingresó el motivo del rechazo.";
      }
      this.state.mostrarK = false;
    } else {
      this.state.mostrarK = true;
    }
  }

  componentDidMount() {
    if (this.state.mostrarK === true) {
      let id = this.makeid(5);
      this.descargarAudio(this.state.grabacion, id);
    }
  }

  makeid = (length) => {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  descargarAudio = (url, registro) => {
    let urlCortada = url.replace("http://200.111.252.138/grabaciones/", "");
    axios
      .get(global.config.modulos.parametros + "descargar_audio/", {
        headers: authHeader(),
        params: { url: urlCortada, registro: registro },
      })
      .then((res) => {
        this.setState({ audio: new Audio(res.data) });
      });
  };

  reproducirMotivo = () => {
    let audio = this.state.audio;

    if (!audio.paused) {
      audio.pause();
      this.setState({ paused: false });
      return;
    } else if (audio.currentTime) {
      audio.play();
      this.setState({ paused: true });
      return;
    }
    this.setState({ duracion: Math.trunc(audio.duration) });

    audio.play();
    this.setState({ paused: true });

    let x = 0;
    let aumento = 100 / audio.duration;
    let porcent = 0;
    let current = 0;

    let listen = audio.addEventListener("timeupdate", () => {
      current = Math.trunc(audio.currentTime);

      if (current >= x) {
        x++;
        porcent = porcent + aumento;
        this.setState({ porcentajeCarga: porcent, tiempoActual: current });
      }
      if (current >= Math.trunc(audio.duration)) {
        audio.pause();
        this.setState({ paused: false });
        audio.currentTime = 0;
        this.setState({ porcentajeCarga: 0, tiempoActual: 0 });
      }
    });
  };

  handleProgressClick = (event) => {
    let audio = this.state.audio;

    const barraProgreso = event.currentTarget;
    const barraProgresoWidth = barraProgreso.offsetWidth;
    const clickX = event.nativeEvent.offsetX;

    const clickPercentage = clickX / barraProgresoWidth;

    const newTime = clickPercentage * audio.duration;
    audio.currentTime = newTime;

    this.setState({
      tiempoActual: Math.trunc(newTime),
      porcentajeCarga: clickPercentage * 100,
    });

    audio.addEventListener("timeupdate", () => {
      const currentTime = audio.currentTime;
      const duration = audio.duration;
      const porcentajeCarga = (currentTime / duration) * 100;

      this.setState({
        tiempoActual: Math.trunc(currentTime),
        porcentajeCarga: porcentajeCarga,
      });
    });
  };

  salir = () => {
    if (this.state.mostrarK === true) {
      let audio = this.state.audio;

      if (!audio.paused) {
        audio.pause();
        this.setState({ paused: false });
      }
    }
    this.setState({ open: false }, () => {
      this.state.ocultar();
    });
  };
  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="escucharAudio">
          <DialogContent>
            {this.state.mostrarK ? (
              <GridContainer className="agendar-container">
                <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
                  <img className="marca-agua"></img>
                  <GridContainer className="agendar-container">
                    <GridItem xs={12}>
                      <h2>Escuchar audio</h2>
                      <p style={{ marginTop: 30 }}>
                        <label>Fecha de registro: {this.state.fecha}</label>
                      </p>
                      <p className="bordeAudio" style={{ marginTop: 20 }}>
                        <GridContainer className="agendar-container">
                          <GridItem xs={3}>
                            <div className="bordeAudio" style={{ padding: 3 }}>
                              <img src={audio_salusin}></img>
                            </div>
                          </GridItem>
                          <GridItem
                            xs={9}
                            style={{
                              display: "block",
                              alignContent: "center",
                              paddingLef: "0px !important",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <p style={{ textAlign: "center" }}>
                                  <a style={{ cursor: "pointer" }} onClick={() => this.reproducirMotivo()}>
                                    {!this.state.paused ? <img style={{ width: 60, paddingRight: 10 }} src={play}></img> : <img style={{ width: 60, paddingRight: 10 }} src={pause}></img>}
                                  </a>
                                </p>
                              </div>

                              <div
                                style={{
                                  width: "75%",
                                  height: "5px",
                                  backgroundColor: "rgb(167 159 159)",
                                  borderRadius: "5px",
                                  position: "relative",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  this.handleProgressClick(e);
                                }}
                              >
                                <div
                                  style={{
                                    width: this.state.porcentajeCarga + "%",
                                    height: "5px",
                                    backgroundColor: "#3e3c3c",
                                    borderRadius: "5px",
                                  }}
                                ></div>
                                <div>
                                  <label style={{ float: "left" }}>{this.state.tiempoActual}s</label>
                                  <label style={{ float: "right" }}>{this.state.duracion}s</label>
                                </div>
                              </div>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </p>
                    </GridItem>
                    <GridItem xs={12} style={{ marginTop: 15 }}>
                      <Button onClick={this.salir} className="botonSimple floatRigth fondoRojo">
                        Cerrar
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            ) : (
              <div>
                <TableContainer>
                  <Table aria-label="simple table" className="tablaGeneralGestion bordeTabla">
                    <TableHead>
                      <TableRow>
                        <TableCell className="cellInicio" align="center">
                          <a style={{ cursor: "pointer", fontWeight: "bold" }}>Motivo del rechazo</a>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">{this.state.texto}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
