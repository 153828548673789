import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Grid, List, ListItem } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert";
import chileanRut from "chilean-rut";

// CSS
import "assets/css/general.css";
import "assets/css/usuarios.css";

// custom components
import AgregarUsuario from "components/Usuarios/AgregarUsuario";
import InformacionUsuario from "components/Usuarios/InformacionUsuario";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

import signo from "assets/img/signo.svg";
import { store } from "./../../store/index";

//3th components
import axios from "axios";

export default class BuscarUsuario extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usu_id: 0,
      mostrarInfo: false,
      rut: "",
      nombre: "",
      modificarUsuario: false,
      agregarUsuario: false,
      extranjero: false,
      mostrarBotonLimpiar: false,
      usuarios: [],
      busquedaUsuario: true,

      cliente: store.getState().cliente,
      cesfam: store.getState().cesfam,
      uspId: store.getState().usp,
      herramientas: store.getState().herramientas,

      formErrors: {},
    };

    this.agregarUsuario = this.agregarUsuario.bind(this);
    this.modificarUsuario = this.modificarUsuario.bind(this);
  }

  componentDidMount() {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Buscar paciente <span title='Podrás visualizar toda la información relacionada al paciente'><img src=" + signo + "></img></span>";

    this.mostrarAlerta();
  }

  mostrarAlerta = () => {
    axios
      .get(global.config.modulos.usuariospanel + "mensaje_derecho/", {
        headers: authHeader(),
        params: {
          usuario_panel: this.state.uspId,
          cliente: this.state.cliente,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          confirmAlert({
            title: "",
            message: res.data["mensaje"],
            closeOnEscape: false,
            closeOnClickOutside: false,
            buttons: [
              {
                label: "Aceptar",
                className: "btnAceptar",
                onClick: () => {
                  axios
                    .post(
                      global.config.modulos.usuariospanel + "aceptar_mensaje_derecho/",
                      {
                        usuario_panel: this.state.uspId,
                        cliente: this.state.cliente,
                      },
                      { headers: authHeader() }
                    )
                    .then((res) => {});
                },
              },
            ],
          });
        }
      });
  };

  buscarUsuarios = async (nombre) => {
    this.setState({ buscar_usuario: nombre });

    var data = {
      nombre: nombre,
      cesfam: this.state.cesfam,
      uspId: this.state.uspId,
    };

    let apiUrl = global.config.modulos.usuarios + "por_nombre/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    if (nombre.length >= 4) {
      axios(apiUrl, options).then(async (res) => {
        var data = res.data.datos;
        this.setState({
          usuarios: res.data.datos,
        });

        if (nombre.length >= 8 && data.length == 0) {
          var rut = nombre;
          await this.comprobarRut(rut);
        }
      });
    }

    if (nombre == "") {
      this.setState({
        usuarios: [],
      });
    }
  };

  comprobarRut = async (rut) => {
    if (chileanRut.validate(rut)) {
      let apiUrl = global.config.modulos.usuarios + "por_rut/";
      const options = {
        method: "POST",
        data: {
          rut: rut,
          canal: 5,
          cesfam: this.state.cesfam,
          uspId: this.state.uspId,
        },
        headers: authHeader(),
      };
      var res = await axios(apiUrl, options);
      let formErrors = {};
      formErrors["usuarioErr"] = res.data.mensaje;
      this.setState({ formErrors: formErrors });
    }
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  modificarRutNombre = (rut, nombre) => {
    this.setState({ rut: rut, nombre: nombre });
  };

  validaciones() {
    const { buscar_usuario } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (buscar_usuario == "") {
      formIsValid = false;
      formErrors["usuarioErr"] = "Escribe el rut o nombre del paciente a buscar.";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  limpiarDatos = () => {
    this.setState({ mostrarBotonLimpiar: false, mostrarInfo: false });
  };

  buscarUsuario = (e) => {
    if (this.validaciones()) {
      this.setState({
        extranjero: false,
        mostrarBotonLimpiar: true,
        mostrarInfo: true,
      });
    }
  };

  buscarUsuarioExtranjero = () => {
    axios
      .get(global.config.modulos.usuarios + "extranjero_general/", {
        headers: authHeader(),
        params: { cesfam: this.state.cesfam },
      })
      .then((res) => {
        if (res.data["estado"]) {
          const id_extranjero = res.data["datos"]["usu_id"];
          this.setState({
            usu_id: id_extranjero,
            extranjero: true,
            mostrarBotonLimpiar: true,
            mostrarInfo: true,
          });
        }
      });
  };

  modificarUsuario() {
    this.setState({
      modificarUsuario: true,
    });
  }

  agregarUsuario = () => {
    this.setState({
      agregarUsuario: true,
    });
  };

  ocultarAgregar = () => {
    this.setState({ agregarUsuario: false });
  };

  render() {
    const { usuarioErr } = this.state.formErrors;

    const elegirUsuario = (usu_id) => {
      window.localStorage.setItem("usuId", usu_id);
      this.setState({
        usu_id: usu_id,
      });
    };

    const open = Boolean(this.state.mostrarProgramas);
    const id = open ? "simple-popover" : undefined;

    return (
      <div className="middle-area buscarUsuario">
        <div>
          <React.Fragment>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                {this.state.busquedaUsuario ? (
                  <List>
                    <ListItem>
                      {!this.state.mostrarBotonLimpiar ? (
                        <React.Fragment>
                          <Autocomplete
                            id="cmbUsuarios"
                            onInputChange={(event, newInputValue) => {
                              this.buscarUsuarios(newInputValue);
                            }}
                            onChange={function (event, res) {
                              if (res === null) {
                              } else {
                                elegirUsuario(res.usu_id);
                              }
                            }}
                            size="small"
                            options={Object.values(this.state.usuarios)}
                            getOptionLabel={(option) => option.rutPuntuacion + " - " + option.nombre_usuario}
                            filterOptions={createFilterOptions({
                              // matchFrom: 'start',
                              stringify: (option) => {
                                var nombre_split = [""];
                                var apellido_p_split = [""];
                                var apellido_m_split = [""];
                                if (option.usu_nombre) {
                                  nombre_split = option.usu_nombre.split(" ");
                                } else {
                                  nombre_split = [option.usu_nombre];
                                }
                                if (option.usu_apellido_p) {
                                  apellido_p_split = option.usu_apellido_p.split(" ");
                                } else {
                                  apellido_p_split = [option.usu_apellido_p];
                                }

                                if (option.usu_apellido_m) {
                                  apellido_m_split = option.usu_apellido_m.split(" ");
                                } else {
                                  apellido_m_split = [option.usu_apellido_m];
                                }

                                var texto_busqueda_nombre = "";
                                nombre_split.map((nom) => {
                                  apellido_p_split.map((app) => {
                                    apellido_m_split.map((apm) => {
                                      texto_busqueda_nombre += nom + " " + app + " " + apm + " " + nom + " " + apm + " ";
                                    });
                                  });
                                });
                                return option.rutPuntuacion + " " + option.usu_rut + " " + texto_busqueda_nombre + " " + option.rut_con_guion + " " + option.usu_rut_original + " " + option.rutOriginalPuntuacion + " " + option.usu_pasaporte + " " + option.rutOriginal_con_guion + " " + option.nombre_usuario;
                              },
                            })}
                            style={{
                              width: 390,
                              height: 40,
                            }}
                            noOptionsText={"No se encontró paciente"}
                            renderInput={(params) => <TextField {...params} label="Pacientes" variant="outlined" />}
                          ></Autocomplete>

                          <Button variant="contained" onClick={this.buscarUsuario} className={"fondoNaranjo"}>
                            Buscar
                          </Button>
                          {this.state.herramientas.includes("H10") ? (
                            <Button tabIndex="-1" type="button" aria-label="Clear" title="Clear" variant="contained" onClick={this.buscarUsuarioExtranjero} className={"fondoNaranjo"}>
                              Extranjero
                            </Button>
                          ) : null}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <label style={{ fontSize: 20 }}>
                            {this.state.rut} - {this.state.nombre}
                          </label>
                          <Button style={{ marginLeft: 20 }} variant="contained" className={"fondoNaranjo"} onClick={this.limpiarDatos}>
                            Limpiar
                          </Button>
                        </React.Fragment>
                      )}
                    </ListItem>
                    {usuarioErr && <div style={{ color: "red", paddingBottom: 10 }}>{usuarioErr}</div>}
                  </List>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={4} style={{ marginTop: 20 }}>
                {!this.state.mostrarBotonLimpiar && (
                  <>
                    {this.state.herramientas.includes("H60") && (
                      <Button style={{ marginTop: 18 }} variant="contained" className="botonesAlineados botonAgregarUsuario fondoVerde" onClick={this.agregarUsuario}>
                        Agregar paciente
                      </Button>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </React.Fragment>
          {this.state.mostrarInfo ? <InformacionUsuario usu_id={this.state.usu_id} modificarRutNombre={this.modificarRutNombre} limpiarDatos={this.limpiarDatos} extranjero={this.state.extranjero} /> : null}
          {this.state.agregarUsuario ? <AgregarUsuario ocultar={this.ocultarAgregar} cliente={this.state.cliente}></AgregarUsuario> : null}
        </div>
      </div>
    );
  }
}
