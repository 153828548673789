/*

Este componente se usa para ingresar un valor a un input
Se le debe entregar los siguientes props:
- cambiarValor: función que se ejecuta cuando se cambia el valor
- titulo: título del componente
- valorActual: valor de la variable en el componente padre
- nombreVar: nombre de la variable que guarda el valor en el componente padre
- tooltip: texto que se muestra al pasar el mouse por el ícono de información
- tipo: tipo de input (text, number, etc)
- componenteFuncional: si es true, el componente es de función y la cambia la función que ejecuta el cambio de valor

En el componente padre se debe utilizar este método para cambiar el valor del componente (modificar como se necesite):

cambiarValor = (nombreVar,valor) => {
  this.setState({ [nombreVar]: valor });
}

*/
import React from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InfoTooltip from "./InfoTooltip";
import Grid from "@material-ui/core/Grid";
import GridItem from "../grid/GridItem.js";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: 300,
      marginLeft: 8,
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export default function BasicTextField(props) {
  const classes = useStyles();
  const { cambiarValor, titulo, valorActual, nombreVar, tipo, tooltip, filas, componenteFuncional } = props;

  const handleChange = (event) => {
    if (componenteFuncional) {
      cambiarValor(event.target.value);
    } else {
      cambiarValor(nombreVar, event.target.value);
    }
  };

  return (
    <GridItem xs={12} sm={12} md={12}>
      <Grid container>
        <Grid item>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id={nombreVar} label={titulo} value={valorActual} onChange={handleChange} type={tipo} rows={filas > 1 || filas ? filas : 1} multiline={filas > 1 || filas ? true : false} variant={filas > 1 || filas ? "outlined" : "standard"} />
          </form>
        </Grid>
        <Grid item>
          <InfoTooltip texto={tooltip} />
        </Grid>
      </Grid>
    </GridItem>
  );
}
