import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import { NotificationManager } from "react-notifications";

// core components
import GridItem from "../grid/GridItem.js";
import GridContainer from "../grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { confirmAlert } from "react-confirm-alert"; // Import

// estilos
import "../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class ModalCambioRendimiento extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usp_id: store.getState().usp,
      cesfam: store.getState().cesfam,

      horId: props.horario,
      espId: props.especialidad,

      rendimiento: "0",
      rendimientoTxt: "",

      open: true,
      formErrors: [],
    };

    this.traerRendimientos();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  salirExito = () => {
    this.setState({
      open: false,
    });
    this.props.ocultarExito();
  };

  traerRendimientos() {
    const apiUrl = global.config.modulos.agendamientos + "especialidades_rendimiento/" + this.state.espId;
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.data.length > 0) {
          this.setState({
            rendimientos: res.data,
          });
        } else {
          this.setState({
            rendimientos: [
              {
                esr_rendimiento: 15,
              },
              {
                esr_rendimiento: 20,
              },
              {
                esr_rendimiento: 30,
              },
              {
                esr_rendimiento: 40,
              },
              {
                esr_rendimiento: 45,
              },
              {
                esr_rendimiento: 60,
              },
            ],
          });
        }
      });
  }

  validaciones() {
    const { rendimiento, rendimientoTxt } = this.state;

    let formIsValid = true;

    if (rendimiento == "0") {
      NotificationManager.warning("Debe Seleccionar un rendimiento");
      formIsValid = false;
    } else if (rendimiento == "otro" && rendimientoTxt == "") {
      NotificationManager.warning("Debe escribir el rendimiento");
      formIsValid = false;
    }

    return formIsValid;
  }

  confirmarCambiar = () => {
    if (this.validaciones()) {
      confirmAlert({
        title: "",
        message: "¿Esta segur@ de cambiar el rendimiento del horario?",
        buttons: [
          {
            label: "Cancelar",
            className: "btnCancelar",
          },
          {
            label: "Aceptar",
            onClick: () => this.cambiarRendimiento(),
            className: "btnAceptar",
          },
        ],
      });
    }
  };

  cambiarRendimiento = () => {
    const { rendimiento, rendimientoTxt } = this.state;

    var rendimientoC = rendimiento;
    if (rendimiento == "otro") {
      rendimientoC = rendimientoTxt;
    }

    let apiUrl = global.config.modulos.agendamientos + "horario_por_id/" + this.state.horId;

    var data = {
      hor_rendimiento: rendimientoC,
    };

    const options = {
      method: "PATCH",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      var data = res.data;
      this.salirExito();
      NotificationManager.success("Rendimiento reemplazado con éxito");
    });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const cambioRendimiento = (event) => {
      if (event.target.value == "otro") {
        this.setState({
          rendimiento: event.target.value,
          mostrarRendTxt: true,
        });
      } else {
        this.setState({
          rendimiento: event.target.value,
          mostrarRendTxt: false,
        });
      }
    };

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modalAgregarProfesional">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer className="agendar-container">
                <GridItem xs={12} sm={12} md={12}>
                  <h2>Cambiar rendimiento anterior</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <InputLabel style={{ color: "black" }}>Rendimientos</InputLabel>
                  <br></br>
                  <Select name="rendimiento" className={"selectSimple"} value={this.state.rendimiento} onChange={cambioRendimiento} style={{ minWidth: 250 }} displayEmpty>
                    <MenuItem value={"0"} key={0}>
                      {"Selecciona"}
                    </MenuItem>

                    {this.state.rendimientos &&
                      this.state.rendimientos.map((rend, index) => (
                        <MenuItem value={rend.esr_rendimiento} key={index}>
                          {rend.esr_rendimiento}
                        </MenuItem>
                      ))}
                    <MenuItem value={"otro"}>{"Otro...."}</MenuItem>
                  </Select>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} className="selectMotivoBloqueo">
                  {this.state.mostrarRendTxt ? <input name="rendimientoTxt" type="number" aria-label="minimum height" className={"inputTextAgendar"} style={{ float: "left", marginTop: 20, width: "20%" }} placeholder="Digita el rendimiento..." value={this.state.rendimientoTxt} onChange={this.cambiarValores} min={0} /> : null}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Button variant="contained" className="btnCargaHorario fondoRojo" style={{ backgroundColor: "#ea2828" }} onClick={this.salir}>
                    Cancelar
                  </Button>
                  <Button variant="contained" className="btnCargaHorario fondoVerde" onClick={this.confirmarCambiar}>
                    Reemplazar rendimiento
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
