import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { NotificationManager } from "react-notifications";
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import CambioGraf from "./BotonesGraficosEspRes.js";

import stilos from "../../../assets/css/graficosDM.module.css";
import "../../../assets/css/graficosD.css";

export default class GrafResEsp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datos: [],
      fecha_actual: props.fecha_actual,
      fecha_inicio: props.fecha_inicio,
      fecha_termino: props.fecha_termino,
      fecha_semana: props.fecha_semana,
      fecha_mes: props.fecha_mes,
      fecha_año: props.fecha_año,
      cesfam: props.cesfam,
      root: "",
      idCliente: props.idCliente,
      ces: "",
      color: props.color,
      total: "",
      cesNombre: props.cesNombre,
      today: new Date(),
      year: "",
      month: "",
      day: "",
      dateStr: "",
      titulo_archivo: "",
      displayInicial: "block",
      displayBloqueadas: "none",
      displayAnuladas: "none",
      displayFinal: "none",
      heiGra1: "",
      heiGra2: "",
      heiGra3: "",
      heiGra4: "",
      left1: "100vw",
      left2: "100vw",
      left3: "100vw",
      left4: "20vw",
    };
  }

  GrafResEsp() {
    this.setState({ datos: [] });
    axios
      .get(global.config.modulos.grafico_demanda + "agendadasXespecialidadCli/", {
        headers: authHeader(),
        params: {
          fecha_actual: this.state.fecha_actual,
          fecha_inicio: this.state.fecha_inicio,
          fecha_termino: this.state.fecha_termino,
          fecha_semana: this.state.fecha_semana,
          fecha_mes: this.state.fecha_mes,
          fecha_año: this.state.fecha_año,
          cliente: this.state.idCliente,
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No hay datos en el grafico de reservas por tipo de atencion");
          return;
        }
        this.setState({ datos: res.data.datos[0] }, () => {
          try {
            this.state.root.dispose();
          } catch (e) {}

          let root = am5.Root.new("chartdiv27");
          root._logo.dispose();

          root.setThemes([am5themes_Animated.new(root)]);

          let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
              panX: false,
              panY: false,
              wheelX: "none",
              wheelY: "none",
              pinchZoomX: false,
            })
          );

          let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
          cursor.lineX.set("visible", false);

          let yRenderer = am5xy.AxisRendererY.new(root, {
            minGridDistance: 30,
          });
          yRenderer.labels.template.setAll({
            rotation: 0,
            centerY: am5.p50,
            centerX: am5.p0,
            paddingRight: 10,
            fontSize: "0.8rem",
            fontWeight: "bold",
          });

          yRenderer.grid.template.setAll({
            location: 1,
          });

          let yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
              maxDeviation: 0.3,
              categoryField: "especialidad",
              renderer: yRenderer,
            })
          );

          let xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
              maxDeviation: 0.3,
              renderer: am5xy.AxisRendererX.new(root, {
                strokeOpacity: 0.1,
              }),
            })
          );

          yAxis.get("renderer").labels.template.setAll({
            maxWidth: 100,
            textAlign: "left",
          });

          let series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
              name: "Series 1",
              xAxis: xAxis,
              yAxis: yAxis,
              valueXField: "total",
              sequencedInterpolation: true,
              categoryYField: "especialidad",
              tooltip: am5.Tooltip.new(root, {
                labelText: "{especialidad}\n {valueX} ",
                autoTextColor: false,
                fill: am5.color(0xff5566),
              }),
            })
          );

          series.columns.template.setAll({
            cornerRadiusTL: 5,
            cornerRadiusTR: 5,
            strokeOpacity: 0,
          });

          series.bullets.push(function () {
            return am5.Bullet.new(root, {
              locationX: 1,
              locationY: 0.5,
              sprite: am5.Label.new(root, {
                centerY: am5.p50,
                text: "{valueX}",
                populateText: true,
                fontWeight: "bold",
              }),
            });
          });

          series.columns.template.adapters.add("fill", function (fill, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
          });

          series.columns.template.adapters.add("stroke", function (stroke, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
          });

          chart.get("colors").set("colors", [am5.color("#47BAA4"), am5.color("#EAA028"), am5.color("#EF4597"), am5.color("#6E45AF"), am5.color("#8CBCF4"), am5.color("#D82323"), am5.color("#F7D856"), am5.color("#CEACE5"), am5.color("#9BCFB1"), am5.color("#2466A8")]);
          let data = this.state.datos;

          let exporting = am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {}),
            dataSource: this.state.datos,
            title: `RESERVADAS X TIPO ATENCION `,
            pdfOptions: {
              imageFormat: "jpg",

              addColumnNames: true,
            },
            htmlOptions: {
              disabled: true,
            },
            jpgOptions: {
              disabled: true,
            },
            csvOptions: {
              disabled: true,
            },
            jsonOptions: {
              disabled: true,
            },
            printOptions: {
              disabled: true,
            },
            xlsxOptions: {
              imageFormat: "jpg",

              addColumnNames: true,
            },
            pngOptions: {
              disabled: true,
            },
            pdfdataOptions: {
              disabled: true,
            },
            filePrefix: `Reservas por Tipo de Atencion ${this.state.titulo_archivo}`,
            dataFields: {
              especialidad: "Tipo de Atencion",
              total: "Total",
              aa: ``,
              bb: `Total ${this.state.total}`,
            },
          });
          if (this.state.datos.length < 6) {
            this.setState({ heiGra4: "20rem" });
          }
          if (this.state.datos.length > 5 && this.state.datos.length < 11) {
            this.setState({ heiGra4: "35rem" });
          }
          if (this.state.datos.length > 10 && this.state.datos.length < 20) {
            this.setState({ heiGra4: "50rem" });
          }
          if (this.state.datos.length > 19 && this.state.datos.length < 26) {
            this.setState({ heiGra4: "64rem" });
          }
          if (this.state.datos.length > 25 && this.state.datos.length < 33) {
            this.setState({ heiGra4: "75rem" });
          }
          if (this.state.datos.length > 32 && this.state.datos.length < 40) {
            this.setState({ heiGra4: "87rem" });
          }
          if (this.state.datos.length > 39 && this.state.datos.length < 47) {
            this.setState({ heiGra4: "93rem" });
          }
          if (this.state.datos.length > 47) {
            this.setState({ heiGra4: "200rem" });
          }

          yAxis.data.setAll(data);
          series.data.setAll(data);

          series.appear(1000);
          chart.appear(1000, 100);
        });
      });
  }

  GrafResEspAnulada() {
    this.setState({ datos: [] });
    axios
      .get(global.config.modulos.grafico_demanda + "agendadasXespecialidadCliAnulada/", {
        headers: authHeader(),
        params: {
          fecha_actual: this.state.fecha_actual,
          fecha_inicio: this.state.fecha_inicio,
          fecha_termino: this.state.fecha_termino,
          fecha_semana: this.state.fecha_semana,
          fecha_mes: this.state.fecha_mes,
          fecha_año: this.state.fecha_año,
          cliente: this.state.idCliente,
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No hay datos en el grafico de reservas por tipo de atencion");
          return;
        }
        this.setState({ datos: res.data.datos[0] }, () => {
          try {
            this.state.root.dispose();
          } catch (e) {}

          let root = am5.Root.new("chartdiv28");
          root._logo.dispose();

          root.setThemes([am5themes_Animated.new(root)]);

          let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
              panX: false,
              panY: false,
              wheelX: "none",
              wheelY: "none",
              pinchZoomX: false,
            })
          );

          let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
          cursor.lineX.set("visible", false);

          let yRenderer = am5xy.AxisRendererY.new(root, {
            minGridDistance: 30,
          });
          yRenderer.labels.template.setAll({
            rotation: 0,
            centerY: am5.p50,
            centerX: am5.p0,
            paddingRight: 10,
            fontSize: "0.8rem",
            fontWeight: "bold",
          });

          yRenderer.grid.template.setAll({
            location: 1,
          });

          let yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
              maxDeviation: 0.3,
              categoryField: "especialidad",
              renderer: yRenderer,
            })
          );

          let xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
              maxDeviation: 0.3,
              renderer: am5xy.AxisRendererX.new(root, {
                strokeOpacity: 0.1,
              }),
            })
          );

          yAxis.get("renderer").labels.template.setAll({
            maxWidth: 100,
          });

          let series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
              name: "Series 1",
              xAxis: xAxis,
              yAxis: yAxis,
              valueXField: "total",
              sequencedInterpolation: true,
              categoryYField: "especialidad",
              tooltip: am5.Tooltip.new(root, {
                labelText: "{especialidad}\n {valueX} ",
                autoTextColor: false,
                fill: am5.color(0xff5566),
              }),
            })
          );

          series.columns.template.setAll({
            cornerRadiusTL: 5,
            cornerRadiusTR: 5,
            strokeOpacity: 0,
          });

          series.bullets.push(function () {
            return am5.Bullet.new(root, {
              locationX: 1,
              locationY: 0.5,
              sprite: am5.Label.new(root, {
                centerY: am5.p50,
                text: "{valueX}",
                populateText: true,
                fontWeight: "bold",
              }),
            });
          });

          series.columns.template.adapters.add("fill", function (fill, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
          });

          series.columns.template.adapters.add("stroke", function (stroke, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
          });

          chart.get("colors").set("colors", [am5.color("#47BAA4"), am5.color("#EAA028"), am5.color("#EF4597"), am5.color("#6E45AF"), am5.color("#8CBCF4"), am5.color("#D82323"), am5.color("#F7D856"), am5.color("#CEACE5"), am5.color("#9BCFB1"), am5.color("#2466A8")]);
          let data = this.state.datos;

          let exporting = am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {}),
            dataSource: this.state.datos,
            title: `RESERVADAS X TIPO ATENCION `,
            pdfOptions: {
              imageFormat: "jpg",

              addColumnNames: true,
            },
            htmlOptions: {
              disabled: true,
            },
            jpgOptions: {
              disabled: true,
            },
            csvOptions: {
              disabled: true,
            },
            jsonOptions: {
              disabled: true,
            },
            printOptions: {
              disabled: true,
            },
            xlsxOptions: {
              imageFormat: "jpg",

              addColumnNames: true,
            },
            pngOptions: {
              disabled: true,
            },
            pdfdataOptions: {
              disabled: true,
            },
            filePrefix: `Reservas Anuladas por Tipo de Atencion ${this.state.titulo_archivo}`,
            dataFields: {
              especialidad: "Tipo de Atencion",
              total: "Total",
              aa: ``,
              bb: `Total ${this.state.total}`,
            },
          });

          if (this.state.datos.length < 6) {
            this.setState({ heiGra3: "20rem" });
          }
          if (this.state.datos.length > 5 && this.state.datos.length < 11) {
            this.setState({ heiGra3: "35rem" });
          }
          if (this.state.datos.length > 10 && this.state.datos.length < 20) {
            this.setState({ heiGra3: "50rem" });
          }
          if (this.state.datos.length > 19 && this.state.datos.length < 26) {
            this.setState({ heiGra3: "64rem" });
          }
          if (this.state.datos.length > 25 && this.state.datos.length < 33) {
            this.setState({ heiGra3: "75rem" });
          }
          if (this.state.datos.length > 32 && this.state.datos.length < 40) {
            this.setState({ heiGra3: "87rem" });
          }
          if (this.state.datos.length > 39 && this.state.datos.length < 47) {
            this.setState({ heiGra3: "93rem" });
          }
          if (this.state.datos.length > 47) {
            this.setState({ heiGra3: "200rem" });
          }

          yAxis.data.setAll(data);
          series.data.setAll(data);

          series.appear(1000);
          chart.appear(1000, 100);
        });
      });
  }
  GrafResEspTotal() {
    this.setState({ datos: [] });
    axios
      .get(global.config.modulos.grafico_demanda + "agendadasXespecialidadCliTotal/", {
        headers: authHeader(),
        params: {
          fecha_actual: this.state.fecha_actual,
          fecha_inicio: this.state.fecha_inicio,
          fecha_termino: this.state.fecha_termino,
          fecha_semana: this.state.fecha_semana,
          fecha_mes: this.state.fecha_mes,
          fecha_año: this.state.fecha_año,
          cliente: this.state.idCliente,
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No hay datos en el grafico de reservas por tipo de atencion");
          return;
        }
        this.setState({ datos: res.data.datos[0], total: res.data.datos[1] }, () => {
          try {
            this.state.root.dispose();
          } catch (e) {}

          let root = am5.Root.new("chartdiv29");
          root._logo.dispose();

          root.setThemes([am5themes_Animated.new(root)]);

          let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
              panX: false,
              panY: false,
              wheelX: "none",
              wheelY: "none",
              pinchZoomX: false,
            })
          );

          let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
          cursor.lineX.set("visible", false);

          let yRenderer = am5xy.AxisRendererY.new(root, {
            minGridDistance: 30,
          });
          yRenderer.labels.template.setAll({
            rotation: 0,
            centerY: am5.p50,
            centerX: am5.p0,
            paddingRight: 10,
            fontSize: "0.8rem",
            fontWeight: "bold",
          });

          yRenderer.grid.template.setAll({
            location: 1,
          });

          let yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
              maxDeviation: 0.3,
              categoryField: "especialidad",
              renderer: yRenderer,
            })
          );

          let xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
              maxDeviation: 0.3,
              renderer: am5xy.AxisRendererX.new(root, {
                strokeOpacity: 0.1,
              }),
            })
          );

          yAxis.get("renderer").labels.template.setAll({
            maxWidth: 100,
          });

          let series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
              name: "Series 1",
              xAxis: xAxis,
              yAxis: yAxis,
              valueXField: "total",
              sequencedInterpolation: true,
              categoryYField: "especialidad",
              tooltip: am5.Tooltip.new(root, {
                labelText: "{especialidad}\n {valueX} ",
                autoTextColor: false,
                fill: am5.color(0xff5566),
              }),
            })
          );

          series.columns.template.setAll({
            cornerRadiusTL: 5,
            cornerRadiusTR: 5,
            strokeOpacity: 0,
          });

          series.bullets.push(function () {
            return am5.Bullet.new(root, {
              locationX: 1,
              locationY: 0.5,
              sprite: am5.Label.new(root, {
                centerY: am5.p50,
                text: "{valueX}",
                populateText: true,
                fontWeight: "bold",
              }),
            });
          });

          series.columns.template.adapters.add("fill", function (fill, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
          });

          series.columns.template.adapters.add("stroke", function (stroke, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
          });

          chart.get("colors").set("colors", [am5.color("#47BAA4"), am5.color("#EAA028"), am5.color("#EF4597"), am5.color("#6E45AF"), am5.color("#8CBCF4"), am5.color("#D82323"), am5.color("#F7D856"), am5.color("#CEACE5"), am5.color("#9BCFB1"), am5.color("#2466A8")]);
          let data = this.state.datos;

          let exporting = am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {}),
            dataSource: this.state.datos,
            title: `RESERVADAS X TIPO ATENCION `,
            pdfOptions: {
              imageFormat: "jpg",

              addColumnNames: true,
            },
            htmlOptions: {
              disabled: true,
            },
            jpgOptions: {
              disabled: true,
            },
            csvOptions: {
              disabled: true,
            },
            jsonOptions: {
              disabled: true,
            },
            printOptions: {
              disabled: true,
            },
            xlsxOptions: {
              imageFormat: "jpg",

              addColumnNames: true,
            },
            pngOptions: {
              disabled: true,
            },
            pdfdataOptions: {
              disabled: true,
            },
            filePrefix: `Reservas por Tipo de Atencion General ${this.state.titulo_archivo}`,
            dataFields: {
              especialidad: "Tipo de Atencion",
              total: "Total",
              aa: ``,
              bb: `Total ${this.state.total}`,
            },
          });

          console.log(this.state.datos.length);

          if (this.state.datos.length < 6) {
            this.setState({ heiGra2: "20rem" });
          }
          if (this.state.datos.length > 5 && this.state.datos.length < 11) {
            this.setState({ heiGra2: "35rem" });
          }
          if (this.state.datos.length > 10 && this.state.datos.length < 20) {
            this.setState({ heiGra2: "50rem" });
          }
          if (this.state.datos.length > 19 && this.state.datos.length < 26) {
            this.setState({ heiGra2: "64rem" });
          }
          if (this.state.datos.length > 25 && this.state.datos.length < 33) {
            this.setState({ heiGra2: "75rem" });
          }
          if (this.state.datos.length > 32 && this.state.datos.length < 40) {
            this.setState({ heiGra2: "87rem" });
          }
          if (this.state.datos.length > 39 && this.state.datos.length < 47) {
            this.setState({ heiGra2: "93rem" });
          }
          if (this.state.datos.length > 47) {
            this.setState({ heiGra2: "200rem" });
          }

          yAxis.data.setAll(data);
          series.data.setAll(data);

          series.appear(1000);
          chart.appear(1000, 100);
        });
      });
  }
  GrafResEspBloqueos() {
    this.setState({ datos: [] });
    axios
      .get(global.config.modulos.grafico_demanda + "agendadasXespecialidadCliBloqueos/", {
        headers: authHeader(),
        params: {
          fecha_actual: this.state.fecha_actual,
          fecha_inicio: this.state.fecha_inicio,
          fecha_termino: this.state.fecha_termino,
          fecha_semana: this.state.fecha_semana,
          fecha_mes: this.state.fecha_mes,
          fecha_año: this.state.fecha_año,
          cliente: this.state.idCliente,
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No hay datos en el grafico de reservas por tipo de atencion");
          return;
        }
        this.setState({ datos: res.data.datos[0] }, () => {
          try {
            this.state.root.dispose();
          } catch (e) {}

          let root = am5.Root.new("chartdiv30");
          root._logo.dispose();

          root.setThemes([am5themes_Animated.new(root)]);

          let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
              panX: false,
              panY: false,
              wheelX: "none",
              wheelY: "none",
              pinchZoomX: false,
            })
          );

          let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
          cursor.lineX.set("visible", false);

          let yRenderer = am5xy.AxisRendererY.new(root, {
            minGridDistance: 30,
          });
          yRenderer.labels.template.setAll({
            rotation: 0,
            centerY: am5.p50,
            centerX: am5.p0,
            paddingRight: 10,
            fontSize: "0.8rem",
            fontWeight: "bold",
          });

          yRenderer.grid.template.setAll({
            location: 1,
          });

          let yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
              maxDeviation: 0.3,
              categoryField: "especialidad",
              renderer: yRenderer,
            })
          );

          let xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
              maxDeviation: 0.3,
              renderer: am5xy.AxisRendererX.new(root, {
                strokeOpacity: 0.1,
              }),
            })
          );

          yAxis.get("renderer").labels.template.setAll({
            maxWidth: 100,
          });

          let series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
              name: "Series 1",
              xAxis: xAxis,
              yAxis: yAxis,
              valueXField: "total",
              sequencedInterpolation: true,
              categoryYField: "especialidad",
              tooltip: am5.Tooltip.new(root, {
                labelText: "{especialidad}\n{valueX} ",
                autoTextColor: false,
                fill: am5.color(0xff5566),
              }),
            })
          );

          series.bullets.push(function () {
            return am5.Bullet.new(root, {
              locationX: 1,
              locationY: 0.5,
              sprite: am5.Label.new(root, {
                centerY: am5.p50,
                text: "{valueX}",
                populateText: true,
                fontWeight: "bold",
              }),
            });
          });

          series.columns.template.setAll({
            cornerRadiusTL: 5,
            cornerRadiusTR: 5,
            strokeOpacity: 0,
          });

          series.columns.template.adapters.add("fill", function (fill, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
          });

          series.columns.template.adapters.add("stroke", function (stroke, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
          });

          chart.get("colors").set("colors", [am5.color("#47BAA4"), am5.color("#EAA028"), am5.color("#EF4597"), am5.color("#6E45AF"), am5.color("#8CBCF4"), am5.color("#D82323"), am5.color("#F7D856"), am5.color("#CEACE5"), am5.color("#9BCFB1"), am5.color("#2466A8")]);
          let data = this.state.datos;

          let exporting = am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {}),
            dataSource: this.state.datos,
            title: `RESERVADAS X TIPO ATENCION `,
            pdfOptions: {
              imageFormat: "jpg",

              addColumnNames: true,
            },
            htmlOptions: {
              disabled: true,
            },
            jpgOptions: {
              disabled: true,
            },
            csvOptions: {
              disabled: true,
            },
            jsonOptions: {
              disabled: true,
            },
            printOptions: {
              disabled: true,
            },
            xlsxOptions: {
              imageFormat: "jpg",

              addColumnNames: true,
            },
            pngOptions: {
              disabled: true,
            },
            pdfdataOptions: {
              disabled: true,
            },
            filePrefix: `Reservas por Tipo de Atencion General ${this.state.titulo_archivo}`,
            dataFields: {
              especialidad: "Tipo de Atencion",
              total: "Total",
              aa: ``,
              bb: `Total ${this.state.total}`,
            },
          });

          console.log(this.state.datos.length);

          if (this.state.datos.length < 6) {
            this.setState({ heiGra1: "20rem" });
          }
          if (this.state.datos.length > 5 && this.state.datos.length < 11) {
            this.setState({ heiGra1: "35rem" });
          }
          if (this.state.datos.length > 10 && this.state.datos.length < 20) {
            this.setState({ heiGra1: "50rem" });
          }
          if (this.state.datos.length > 19 && this.state.datos.length < 26) {
            this.setState({ heiGra1: "64rem" });
          }
          if (this.state.datos.length > 25 && this.state.datos.length < 33) {
            this.setState({ heiGra1: "75rem" });
          }
          if (this.state.datos.length > 32 && this.state.datos.length < 40) {
            this.setState({ heiGra1: "87rem" });
          }
          if (this.state.datos.length > 39 && this.state.datos.length < 47) {
            this.setState({ heiGra1: "93rem" });
          }
          if (this.state.datos.length > 47) {
            this.setState({ heiGra1: "200rem" });
          }

          yAxis.data.setAll(data);
          series.data.setAll(data);

          series.appear(1000);
          chart.appear(1000, 100);
        });
      });
  }

  clickButton = (i) => {
    console.log("-------", i);
    if (i === 1) {
      this.setState(
        {
          displayInicial: "block",
          displayBloqueadas: "none",
          displayAnuladas: "none",
          displayFinal: "none",
          left4: "100vw",
          left3: "100vw",
          left2: "100vw",
          left1: "100vw",
        },
        () => {
          this.setState({
            left4: "20vw",
          });
        }
      );
    } else if (i === 2) {
      this.setState(
        {
          displayInicial: "none",
          displayBloqueadas: "block",
          displayAnuladas: "none",
          displayFinal: "none",
          left4: "100vw",
          left3: "100vw",
          left2: "100vw",
          left1: "100vw",
        },
        () => {
          this.setState({
            left3: "20vw",
          });
        }
      );
    } else if (i === 3) {
      this.setState(
        {
          displayInicial: "none",
          displayBloqueadas: "none",
          displayAnuladas: "block",
          displayFinal: "none",
          left4: "100vw",
          left3: "100vw",
          left2: "100vw",
          left1: "100vw",
        },
        () => {
          this.setState({
            left2: "20vw",
          });
        }
      );
    } else {
      this.setState(
        {
          displayInicial: "none",
          displayBloqueadas: "none",
          displayAnuladas: "none",
          displayFinal: "block",
          left4: "100vw",
          left3: "100vw",
          left2: "100vw",
          left1: "100vw",
        },
        () => {
          this.setState({
            left1: "20vw",
          });
        }
      );
    }
  };

  componentDidMount() {
    this.setState(
      {
        year: this.state.today.getFullYear().toString().slice(),
        month: (this.state.today.getMonth() + 1).toString().padStart(2, "0"),
        day: this.state.today.getDate().toString().padStart(2, "0"),
        act_date: new Date(this.state.fecha_actual),
        ini_date: new Date(this.state.fecha_inicio),
        ter_date: new Date(this.state.fecha_termino),
      },
      () => {
        if (!this.state.fecha_actual && !this.state.fecha_termino && !this.state.fecha_inicio) {
          this.setState({
            titulo_archivo: ` ${this.state.day}-${this.state.month}-${this.state.year}`,
          });
        } else if ((this.state.fecha_actual !== "") & !this.state.fecha_termino && !this.fecha_inicio) {
          this.setState({
            titulo_archivo: `  ${this.state.act_date.getDate().toString().padStart(2, "0")}-${(this.state.act_date.getMonth() + 1).toString().padStart(2, "0")}-${this.state.act_date.getFullYear().toString().slice()}`,
          });
        } else {
          this.setState({
            act_date: "",
            titulo_archivo: `${this.state.ini_date.getDate().toString().padStart(2, "0")}-${(this.state.ini_date.getMonth() + 1).toString().padStart(2, "0")}-${this.state.ini_date.getFullYear().toString().slice()} ~ ${this.state.ter_date.getDate().toString().padStart(2, "0")}-${(this.state.ter_date.getMonth() + 1).toString().padStart(2, "0")}-${this.state.ter_date.getFullYear().toString().slice()}`,
          });
        }
      }
    );
    this.GrafResEsp();
    this.GrafResEspAnulada();
    this.GrafResEspTotal();
    this.GrafResEspBloqueos();
  }

  render() {
    return (
      <div style={{ margin: "30px 13%" }}>
        <div style={{ position: "absolute", width: "85%" }}>
          <h1
            style={{
              color: "#808080",
              fontSize: "1.6vw",
            }}
          >
            Horas reservadas por Tipo de atención{" "}
          </h1>
          <h3
            style={{
              color: "#808080",
              fontSize: "0.9vw",
            }}
          >
            Horas reservadas {">"} Tipo de atención
          </h3>
          <h3
            style={{
              color: "#808080",
              fontSize: "1vw",
            }}
          >
            Total:{this.state.total}
          </h3>
          <CambioGraf displayInicial={this.state.displayInicial} displayBloqueadas={this.state.displayBloqueadas} displayAnuladas={this.state.displayAnuladas} displayFinal={this.state.displayFinal} clickButton={this.clickButton} colores={this.state.color} />
        </div>
        <div
          id="chartdiv27"
          className={stilos.transicion}
          style={{
            width: "60vw",
            position: "absolute",
            height: this.state.heiGra4,
            zIndex: -1,
            marginTop: "15vh",
            left: this.state.left4,
            display: this.state.displayInicial,
          }}
        >
          <h3
            style={{
              color: "#808080",
              fontSize: "1vw",
              position: "absolute",
              top: "-20px",
            }}
          >
            Reservas Iniciales
          </h3>
        </div>

        <div
          id="chartdiv30"
          className={stilos.transicion}
          style={{
            width: "60vw",
            position: "absolute",
            height: this.state.heiGra1,
            zIndex: -1,
            marginTop: "15vh",
            left: this.state.left3,
            display: this.state.displayBloqueadas,
          }}
        >
          <h3
            style={{
              color: "#808080",
              fontSize: "1vw",
              position: "absolute",
              top: "-20px",
            }}
          >
            Reservas Bloqueadas
          </h3>
        </div>

        <div
          id="chartdiv28"
          className={stilos.transicion}
          style={{
            width: "60vw",
            position: "absolute",
            height: this.state.heiGra3,
            zIndex: -1,
            marginTop: "15vh",
            left: this.state.left2,
            display: this.state.displayAnuladas,
          }}
        >
          <h3
            style={{
              color: "#808080",
              fontSize: "1vw",
              position: "absolute",
              top: "-20px",
            }}
          >
            Reservas Anuladas
          </h3>
        </div>

        <div
          id="chartdiv29"
          className={stilos.transicion}
          style={{
            width: "60vw",
            position: "absolute",
            height: this.state.heiGra2,
            zIndex: -1,
            marginTop: "15vh",
            left: this.state.left1,
            display: this.state.displayFinal,
          }}
        >
          <h3
            style={{
              color: "#808080",
              fontSize: "1vw",
              position: "absolute",
              top: "-20px",
            }}
          >
            Reservas Finales
          </h3>
        </div>
      </div>
    );
  }
}
