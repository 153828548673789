import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import chileanRut from "chilean-rut";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { store } from "./../../store/index";
// core components
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import GridItem from "components/grid/GridItem.js";

import MultiSelect from "react-multi-select-component";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Cargando from "../../components/Helpers/Cargando";

// estilos
import FuncionarioCSS from "assets/css/funcionario.module.css";
import "./../../assets/css/funcionario.css";
import { Tooltip } from "recharts";

export default class ModificarUsuarioPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usp_id: this.props.usuario_panel,
      nombre: "",
      apellido: "",
      correo: "",
      rut: "",
      usuario: "",
      contrasena: "",
      contrasenaRepetir: "",
      telefono: "",
      cesfams: [],
      cesfamsSeleccionados: [],
      cesfamsSeleccionadosUsp: [],
      cesfamsVisibles: "",
      GAcceso: 0,
      seleccionadosCesfam: "",
      rol: "",
      roles: [],
      herramientas: [],
      stringMultiCesfams: {
        selectSomeItems: "Seleccionar cesfams...",
        allItemsAreSelected: "Todos están seleccionados .",
        selectAll: "Seleccionar todo",
        search: "Buscar ",
        clearSearch: "Borrar búsqueda",
      },
      formErrors: {},
      cliente: store.getState().cliente,
      open: true,
      cargando: false,
      herramienta: store.getState().herramientas,
      cesfam: store.getState().cesfam,
      uspId: store.getState().usp,
    };

    this.salir = () => {
      this.setState({
        open: false,
      });
      props.ocultar();
    };

    this.initialState = this.state;
  }

  componentDidMount() {
    this.traerRoles();
    this.editarUsp();
  }

  mostrarAlerta() {
    NotificationManager.success("Acceso modificado con éxito");
  }

  validaciones() {
    const { nombre, apellido, correo, rut, contrasena, contrasenaRepetir, cesfamsSeleccionados } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (!nombre) {
      formIsValid = false;
      formErrors["nombreErr"] = "Escribe el nombre.";
    }

    if (!apellido) {
      formIsValid = false;
      formErrors["apellidoErr"] = "Escribe el apellido.";
    }

    if (correo != "") {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(correo)) {
        formIsValid = false;
        formErrors["correoErr"] = "El correo es invalido.";
      }
    } else if (!correo) {
      formIsValid = false;
      formErrors["correoErr"] = "Escribe un correo.";
    }

    if (!rut) {
      formIsValid = false;
      formErrors["rutErr"] = "Escribe el rut.";
    } else if (!chileanRut.validate(rut)) {
      formIsValid = false;
      formErrors["rutErr"] = "Escribe el rut valido.";
    }
    if (contrasena != contrasenaRepetir) {
      formIsValid = false;
      formErrors["contrasenaRepetirErr"] = "Debe ser la misma contraseña.";
    }

    if (cesfamsSeleccionados.length === 0) {
      formIsValid = false;
      formErrors["cesfamsSeleccionadosErr"] = "Selecciona los cesfam que visualizara.";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  enviarFormulario = (e) => {
    e.preventDefault();
    if (this.validaciones()) {
      this.setState({ cargando: true });
      var cesfamsSelect = "";
      var cesfams = this.state.cesfamsSeleccionados;
      for (var i = 0; i < cesfams.length; i++) {
        cesfamsSelect += cesfams[i]["value"] + ",";
      }
      cesfamsSelect = cesfamsSelect.substring(0, cesfamsSelect.length - 1);

      var data = {
        usp_nombre: this.state.nombre,
        usp_apellido_p: this.state.apellido,
        usp_email: this.state.correo,
        usp_rut: this.state.rut,
        usp_usuario: this.state.usuario,
        usp_comuna: store.getState().cliente,
        usp_cesfam: this.state.cesfamsSeleccionados[0]["value"],
        usp_rol: this.state.rol,
        usp_cesfams_visibles: cesfamsSelect,
        usp_password: this.state.contrasena,
        usp_telefono: this.state.telefono,
        gestorAcceso: this.state.GAcceso,
        usp_id: this.state.usp_id,
        usp_estado: 1,
      };
      let apiUrl = global.config.modulos.usuariospanel + "por_id/" + this.state.usp_id;
      const options = {
        method: "PATCH",
        data: data,
        headers: authHeader(),
      };
      axios(apiUrl, options)
        .then((res) => {
          let modificaciones = JSON.stringify(res.data.diferencias);

          this.setState({ cargando: false });
          this.mostrarAlerta();

          // TIPO 7 : ACCIONES EN GESTOR ACCESOS
          let tipo = 7;
          let mensaje = "El usuariopanel " + this.state.uspId + " Modifico el acceso de " + this.state.usp_id + " Con los siguientes datos: " + JSON.stringify(modificaciones);
          if (modificaciones != "[]") {
            this.agregarAccion(mensaje, this.state.uspId, this.state.usp_id, tipo);
          }
          this.salir();
        })
        .catch((error) => {
          this.setState({ cargando: false });
        });
    }
  };

  traerRoles() {
    const apiUrl = global.config.modulos.roles + "por_comuna/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          comuna: this.state.cliente,
        },
      })
      .then((res) => {
        this.setState({
          roles: res.data["datos"],
        });
      });
  }

  agregarAccion = (accion, uspId, usuario, tipo) => {
    const datos = {
      accion: accion,
      uspId: uspId,
      usuario: usuario,
      tipo: tipo,
    };

    let apiUrl = global.config.modulos.usuariospanel + "agregar_accion/";
    axios
      .post(apiUrl, datos, {
        headers: authHeader(),
      })
      .then((res) => {});
  };

  editarUsp() {
    const apiUrl = global.config.modulos.usuariospanel + "por_id/" + this.state.usp_id;
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        let herr_acceso = res.data.herramienta;
        if (herr_acceso.length > 1) {
          herr_acceso = 2;
        } else if (herr_acceso == 0) {
          herr_acceso = herr_acceso;
        } else {
          herr_acceso = res.data.herramienta[0]["hup_herramienta"];
        }

        this.setState({
          nombre: res.data.usp_nombre,
          apellido: res.data.usp_apellido_p,
          rut: res.data.usp_rut,
          usuario: res.data.usp_usuario,
          contrasenaAnterior: res.data.usp_password,
          correo: res.data.usp_email,
          rol: res.data.usp_rol.toString(),
          rolUsp: res.data.usp_rol.toString(),
          comuna: res.data.usp_comuna,
          cesfamsVisibles: res.data.usp_cesfams_visibles,
          telefono: res.data.usp_telefono,
          GAcceso: herr_acceso,
        });

        const apiUrl2 = global.config.modulos.cesfams + "por_comuna/?id=" + this.state.cliente;
        axios
          .get(apiUrl2, {
            headers: authHeader(),
            params: {
              id: this.state.cliente,
            },
          })
          .then((res) => {
            let cesResult = [];

            for (var i = 0; i < res.data["datos"].length; i++) {
              let ces = {
                value: res.data["datos"][i]["ces_id"],
                label: res.data["datos"][i]["ces_nombre"],
              };
              cesResult.push(ces);
            }

            var cesResultUsp = [];
            var cesVisibles = this.state.cesfamsVisibles.split(",");
            var cesfamsResult = cesResult;
            for (var i = 0; i < cesfamsResult.length; i++) {
              for (var x = 0; x < cesVisibles.length; x++) {
                if (cesfamsResult[i]["value"] == cesVisibles[x]) {
                  let ces = {
                    label: cesfamsResult[i]["label"],
                    value: cesfamsResult[i]["value"],
                  };
                  cesResultUsp.push(ces);
                }
              }
            }

            this.setState({
              cesfams: cesResult,
              cesfamsSeleccionados: cesResultUsp,
              cesfamsSeleccionadosUsp: cesResultUsp,
            });
          });

        this.traerCesfams(res.data.usp_comuna);
      });
  }

  traerCesfams(com) {
    const apiUrl = global.config.modulos.cesfams + "por_comuna/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          id: com,
        },
      })
      .then((res) => {
        let cesResult = [];
        for (var i = 0; i < res.data["datos"].length; i++) {
          let ces = {
            value: res.data["datos"][i]["ces_id"],
            label: res.data["datos"][i]["ces_nombre"],
          };
          cesResult.push(ces);
        }
        this.setState({
          cesfams: cesResult,
        });

        var cesResultSelect = [];
        var cesVisibles = this.state.cesfamsSeleccionadosUsp;
        var cesfamsResult = this.state.cesfams;
        for (var i = 0; i < cesfamsResult.length; i++) {
          for (var x = 0; x < cesVisibles.length; x++) {
            if (cesfamsResult[i]["value"] == cesVisibles[x]["value"]) {
              let ces = {
                label: cesfamsResult[i]["label"],
                value: cesfamsResult[i]["value"],
              };
              cesResultSelect.push(ces);
            }
          }
        }

        this.setState({
          cesfamsSeleccionados: cesResultSelect,
        });
      });
  }

  render() {
    const { nombreErr, apellidoErr, correoErr, rutErr, contrasenaErr, contrasenaRepetirErr, cesfamsSeleccionadosErr } = this.state.formErrors;
    const { cesfams, cesfamsSeleccionados, rol, roles, stringMultiCesfams } = this.state;

    const cambioRol = (event) => {
      this.setState({
        rol: event.target.value,
      });
    };
    const cambioGestorAcceso = (event) => {
      this.setState({
        GAcceso: event.target.value,
      });
    };

    const cambioCesfam = (cesfamsSeleccionados) => {
      this.setState({ cesfamsSeleccionados });
    };

    return (
      <>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" className="DialogFuncionarios" aria-describedby="alert-dialog-description">
          <DialogContent>
            <GridItem xs={12} sm={12} md={12} style={{ width: "900px" }}>
              <h1 style={{ paddingLeft: "30px", color: "#818181" }}>Modificar acceso</h1>
              <br />
              <br />
              <br />
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  marginBottom: "40px",
                }}
                className="prueba12341234512345"
              >
                <GridItem xs={4} sm={4} md={4}>
                  <InputLabel>Nombre</InputLabel>

                  <input type="text" name="nombre" value={this.state.nombre} onChange={this.cambiarValores} placeholder="" className={nombreErr ? FuncionarioCSS.inputTextErr : FuncionarioCSS.inputText} />
                  {nombreErr && <div className={FuncionarioCSS.mensajeErr}>{nombreErr}</div>}
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <InputLabel>Apellido</InputLabel>
                  <input type="text" name="apellido" value={this.state.apellido} onChange={this.cambiarValores} placeholder="" className={apellidoErr ? FuncionarioCSS.inputTextErr : FuncionarioCSS.inputText} />
                  {apellidoErr && <div className={FuncionarioCSS.mensajeErr}>{apellidoErr}</div>}
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <InputLabel>Rut</InputLabel>
                  <input type="text" name="rut" value={this.state.rut} onChange={this.cambiarValores} placeholder="Ejemplo 12345678-9" className={rutErr ? FuncionarioCSS.inputTextErr : FuncionarioCSS.inputText} disabled />
                  {rutErr && <div className={FuncionarioCSS.mensajeErr}>{rutErr}</div>}
                </GridItem>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{
                  padding: "0 !important",
                  display: "flex",
                  marginBottom: "40px",
                }}
              >
                <GridItem xs={4} sm={4} md={4}>
                  <InputLabel>Correo</InputLabel>
                  <input type="text" name="correo" value={this.state.correo} onChange={this.cambiarValores} placeholder="Ejemplo admin@horasalud.cl" className={correoErr ? FuncionarioCSS.inputTextErr : FuncionarioCSS.inputText} />
                  {correoErr && <div className={FuncionarioCSS.mensajeErr}>{correoErr}</div>}
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <InputLabel>Télefono</InputLabel>
                  <TextField
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+56</InputAdornment>,
                    }}
                    id="standard-basic"
                    value={this.state.telefono}
                    type="number"
                    name="telefono"
                    style={{ width: "100%" }}
                    onChange={this.cambiarValores}
                    onInput={(e) => {
                      e.target.value = e.target.value.length <= 9 ? e.target.value : e.target.value.slice(0, -1);
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <InputLabel>Rol</InputLabel>
                  <Select id="rol" name="rol" value={rol} onChange={cambioRol} style={{ width: "100%" }} displayEmpty>
                    <MenuItem value="" disabled>
                      <em>Seleccione rol</em>
                    </MenuItem>

                    {roles &&
                      roles.map((rl, index) => (
                        <MenuItem value={rl.rol_id} key={index}>
                          {rl.rol_nombre}
                        </MenuItem>
                      ))}
                  </Select>
                </GridItem>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{
                  padding: "0 !important",
                  display: "flex",
                  marginBottom: "40px",
                  alignItems: "flex-end",
                }}
              >
                <GridItem xs={4} sm={4} md={4}>
                  <InputLabel>Contraseña</InputLabel>
                  <input type="password" name="contrasena" value={this.state.contrasena} onChange={this.cambiarValores} placeholder="" className={contrasenaErr ? FuncionarioCSS.inputTextErr : FuncionarioCSS.inputText} />
                  {contrasenaErr ? <div className={FuncionarioCSS.mensajeErr}>{contrasenaErr}</div> : <div></div>}
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <InputLabel>Confirmar Contraseña</InputLabel>
                  <input type="password" name="contrasenaRepetir" value={this.state.contrasenaRepetir} onChange={this.cambiarValores} placeholder="" className={contrasenaRepetirErr ? FuncionarioCSS.inputTextErr : FuncionarioCSS.inputText} />
                  {contrasenaRepetirErr && <div className={FuncionarioCSS.mensajeErr}>{contrasenaRepetirErr}</div>}
                </GridItem>

                {this.state.herramienta.includes("H62") ? (
                  <GridItem xs={4} sm={4} md={4}>
                    <InputLabel>Cesfams habilitados</InputLabel>
                    <MultiSelect className="funcionario_multi_selector" options={cesfams} value={cesfamsSeleccionados} onChange={cambioCesfam} labelledBy={"Select"} overrideStrings={stringMultiCesfams} />
                    {cesfamsSeleccionadosErr && <div className={FuncionarioCSS.mensajeErr}>{cesfamsSeleccionadosErr}</div>}
                  </GridItem>
                ) : this.state.herramienta.includes("H63") ? (
                  <GridItem xs={4} sm={4} md={4}>
                    <InputLabel>Cesfams habilitados</InputLabel>
                    <MultiSelect className="funcionario_multi_selector multiselectDisabled" options={cesfams} disabled={true} value={cesfamsSeleccionados} onChange={cambioCesfam} labelledBy={"Select"} overrideStrings={stringMultiCesfams} />
                    {cesfamsSeleccionadosErr && <div className={FuncionarioCSS.mensajeErr}>{cesfamsSeleccionadosErr}</div>}
                  </GridItem>
                ) : null}
              </GridItem>
              {this.state.herramienta.includes("H64") && (
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{
                    padding: "0 !important",
                    display: "flex",
                    marginBottom: "40px",
                    alignItems: "flex-end",
                  }}
                >
                  <GridItem xs={4} sm={4} md={4}>
                    <InputLabel>Habilitar Gestor Acceso</InputLabel>

                    <Select id="rol" name="rol" value={this.state.GAcceso} onChange={cambioGestorAcceso} style={{ width: "100%" }} displayEmpty>
                      <MenuItem value="" disabled>
                        <em>Seleccione permiso</em>
                      </MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                      <MenuItem value={109}>Accesos de CESFAM</MenuItem>
                      <MenuItem value={108}>Accesos de Comuna</MenuItem>
                    </Select>
                  </GridItem>
                </GridItem>
              )}

              <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <button style={{ width: "150px" }} onClick={this.salir} className={FuncionarioCSS.boton}>
                  Cancelar
                </button>
                <button style={{ width: "150px" }} onClick={this.enviarFormulario} className={FuncionarioCSS.boton}>
                  Modificar
                </button>
              </div>
            </GridItem>
          </DialogContent>
        </Dialog>
        {this.state.cargando ? <Cargando color="#fff" type="spinningBubbles" /> : null}
      </>
    );
  }
}
