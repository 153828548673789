import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import Button from "@material-ui/core/Button";
import Titulo from "components/titulo/Titulo.js";
import signo from "assets/img/signo.svg";
import "assets/css/general.css";
import "assets/css/usuarios.css";
import "assets/css/general.css";
import DatePicker from "react-multi-date-picker";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { store } from "store/index";
import graf from "assets/css/graf.module.css";
import GrafPrincipal from "components/Graficos/Sectores/PrincipalSector.js";
import img from "../../../assets/img/icono calendario.svg";
export default class GraficoSectores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaPeriodo: false,
      selecSemana: false,
      selecMensual: false,
      selecAnual: false,
      selectRango: false,
      valueSelecPeriodo: "Seleccione Periodo",
      valueSelecEsp: "Seleccione Especialidad",
      id_ces: store.getState().cesfam,
      especialidades: [],
      listaEspecialidades: false,
      esp: "",
      weekInput: "",
      mes: "",
      año: "",
      añoinicio: "",
      añotermino: "",
      fecha_inicio: "",
      fecha_termino: "",
      mostrarGrafico: false,
      sectores: [],
      mostrarTabla: false,
      displaySector: false,
      formaDatos: false,
      tipo_graft: "",
      tipo_grafico: "Seleccione forma de los datos",
      displayS: false,
      displayM: false,
      displayA: false,
      displayR: false,
      rangoAño: false,
      rangoSemana: false,
      selecSemanaI: "",
      selecSemanaT: "",
      rangoMes: false,
      mesI: "",
      mesT: "",
      primeraCargaFecha: "",
      primeraCargaSemana: "",
      primeraCargaMes: "",
      primeraCargaAño: "",
      orden: "",
      direccion: "asc",
    };
  }

  botonBorrar = () => {
    this.setState({
      valueSelecEsp: "Seleccione Especialidad",
      valueSelecPeriodo: "Seleccione Periodo",
      tipo_grafico: "Seleccione forma de los datos",
      tipo_graft: "",
      esp: "",
      weekInput: "",
      mes: "",
      año: "",
      fecha_inicio: "",
      fecha_termino: "",
      selecSemana: false,
      selecMensual: false,
      selecAnual: false,
      selectRango: false,
      añoinicio: "",
      añotermino: "",
      rangoAño: false,
      rangoSemana: false,
      selecSemanaI: "",
      selecSemanaT: "",
      rangoMes: false,
      mesI: "",
      mesT: "",
    });
  };

  traerPrimeraCarga = () => {
    axios
      .get(global.config.modulos.grafico_demanda + "primer_horario/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.id_ces,
        },
      })
      .then((res) => {
        if (!res.data.datos) {
          NotificationManager.info("No se encontraron especialidades");
          return;
        } else {
          this.setState(
            {
              primeraCargaFecha: res.data.datos[0],
              primeraCargaSemana: res.data.datos[1],
              primeraCargaMes: res.data.datos[2],
              primeraCargaAño: res.data.datos[3],
            },
            () => {
              console.log(this.state.primeraCargaAño);
            }
          );
        }
      });
  };

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Oferta Final por Sector  <span title=''><img src=" + signo + "></img></span>";
  };

  traerEspecialidades = () => {
    axios
      .get(global.config.modulos.grafico_demanda + "filtro_grafico_sectores2/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.id_ces,
        },
      })
      .then((res) => {
        if (!res.data.datos) {
          NotificationManager.info("No se encontraron especialidades");
          return;
        } else {
          this.setState({
            especialidades: res.data.datos,
          });
        }
      });
  };

  traerSectoresSinCarga = () => {
    this.setState({ sectores: [] }, () => {
      axios
        .get(global.config.modulos.grafico_demanda + "cesfam_sin_carga/", {
          headers: authHeader(),
          params: {
            cesfam: this.state.id_ces,
          },
        })
        .then((res) => {
          if (!res.data.estado) {
            this.setState({ displaySector: false });
            NotificationManager.info("Todos los sectores an cargado horas en esta semana");
          } else {
            this.setState({
              sectores: res.data.datos,
              displaySector: true,
            });
          }
        });
    });
  };

  actualizarDatos = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        weekInput: "",
        mes: "",
        año: "",
        fecha_inicio: "",
        fecha_termino: "",
      },
      () => {
        this.setState({ [name]: value }, () => {
          this.setState({ mostrarGrafico: false }, () => {
            this.setState({
              mostrarGrafico: true,
            });
          });
        });
      }
    );
  };

  onClickPeriodo = () => {
    this.setState({
      listaPeriodo: true,
    });
  };

  onOutPeriodo = () => {
    this.setState({
      listaPeriodo: false,
    });
  };

  onClickEsp = () => {
    this.setState({
      listaEspecialidades: true,
    });
  };

  onOutEsp = () => {
    this.setState({
      listaEspecialidades: false,
    });
  };

  onClickFormaDatos = () => {
    this.setState({
      formaDatos: true,
    });
  };

  onOutFormaDatos = () => {
    this.setState({
      formaDatos: false,
    });
  };

  onClickSemana = () => {
    this.setState({
      selecSemana: true,
      selecMensual: false,
      selecAnual: false,
      selectRango: false,
      rangoSemana: false,
      rangoMes: false,
      rangoAño: false,
      valueSelecPeriodo: "Semanal",
    });
  };

  onClickMensual = () => {
    this.setState({
      selecSemana: false,
      selecMensual: true,
      selecAnual: false,
      selectRango: false,
      rangoSemana: false,
      rangoMes: false,
      rangoAño: false,
      valueSelecPeriodo: "Mensual",
    });
  };

  onClickAnual = () => {
    this.setState({
      selecSemana: false,
      selecMensual: false,
      selecAnual: true,
      selectRango: false,
      rangoSemana: false,
      rangoMes: false,
      rangoAño: false,
      valueSelecPeriodo: "Anual",
    });
  };

  onClickRango = () => {
    this.setState(
      {
        selecSemana: false,
        selecMensual: false,
        selecAnual: false,
        rangoAño: false,
        selectRango: false,
        rangoSemana: false,
        rangoMes: false,
        valueSelecPeriodo: "Dar Rango",
      },
      () => {
        if (this.state.tipo_graft === "anual") {
          this.setState({
            rangoAño: true,
          });
        } else if (this.state.tipo_graft === "semanal") {
          this.setState({
            rangoSemana: true,
          });
        } else if (this.state.tipo_graft === "mensual") {
          this.setState({
            rangoMes: true,
          });
        } else {
          this.setState({
            selectRango: true,
          });
        }
      }
    );
  };

  clickEspecialidad = (item) => {
    this.setState({
      esp: item.esp_sigla,
      valueSelecEsp: item.esp_especialidad,
    });
  };

  actualizarAño = (fecha) => {
    this.setState({ weekInput: "", mes: "", año: "", fecha_inicio: "", fecha_termino: "" }, () => {
      this.setState(
        {
          año: fecha["year"],
        },
        () => {
          this.setState({ mostrarGrafico: false }, () => {
            this.setState({
              mostrarGrafico: true,
            });
          });
        }
      );
    });
  };

  actualizarAñoIncio = (fecha) => {
    this.setState(
      {
        weekInput: "",
        mes: "",
        año: "",
        fecha_inicio: "",
        fecha_termino: "",
        añoinicio: "",
      },
      () => {
        this.setState(
          {
            añoinicio: fecha["year"],
          },
          () => {
            this.setState({ mostrarGrafico: false }, () => {
              this.setState({
                mostrarGrafico: true,
              });
            });
          }
        );
      }
    );
  };

  actualizarAñoTermino = (fecha) => {
    this.setState(
      {
        weekInput: "",
        mes: "",
        año: "",
        fecha_inicio: "",
        fecha_termino: "",
        añotermino: "",
      },
      () => {
        this.setState(
          {
            añotermino: fecha["year"],
          },
          () => {
            this.setState({ mostrarGrafico: false }, () => {
              this.setState({
                mostrarGrafico: true,
              });
            });
          }
        );
      }
    );
  };

  componentDidMount() {
    this.traerEspecialidades();
    this.traerSectoresSinCarga();
    this.titulo();
    this.traerPrimeraCarga();
  }

  fechaLimpiar = (e) => {
    if (e === 1) {
      this.setState({
        fecha_inicio: "",
      });
    }
    if (e === 2) {
      this.setState({
        fecha_termino: "",
      });
    }
  };

  onclickTabla = () => {
    this.setState({
      mostrarTabla: true,
    });
  };
  onclosTabla = (e) => {
    if (e.target === e.currentTarget) {
      this.setState({
        mostrarTabla: false,
      });
    }
  };

  actualizarFechaInicial = (fecha) => {
    this.setState({ weekInput: "", mes: "", año: "", fecha_inicio: "" }, () => {
      this.setState(
        {
          fecha_inicio: fecha["year"] + "-" + fecha["month"] + "-" + fecha["day"],
        },
        () => {
          if (this.state.fecha_termino) {
            this.setState({ mostrarGrafico: false }, () => {
              this.setState({
                mostrarGrafico: true,
              });
            });
          }
        }
      );
    });
  };

  actualizarFechaTermino = (fecha) => {
    this.setState({ weekInput: "", mes: "", año: "", fecha_termino: "" }, () => {
      this.setState(
        {
          fecha_termino: fecha["year"] + "-" + fecha["month"] + "-" + fecha["day"],
        },
        () => {
          if (this.state.fecha_inicio) {
            this.setState({ mostrarGrafico: false }, () => {
              this.setState({
                mostrarGrafico: true,
              });
            });
          }
        }
      );
    });
  };

  actualizarGrafico = (tipo, tipo2) => {
    this.setState({ tipo_graft: "", listaPeriodo: false }, () => {
      this.setState(
        {
          tipo_graft: tipo,
          tipo_grafico: tipo2,
        },
        () => {
          if (tipo == "dia") {
            this.setState({
              displayA: "Initial",
              displayM: "Initial",
              displayR: "Initial",
              displayS: "Initial",
              listaPeriodo: true,
            });
          } else if (tipo == "semanal") {
            this.setState({
              displayA: "Initial",
              displayM: "Initial",
              displayR: "Initial",
              displayS: "none",
              listaPeriodo: true,
            });
          } else if (tipo == "mensual") {
            this.setState({
              displayA: "Initial",
              displayM: "none",
              displayR: "Initial",
              displayS: "none",
              listaPeriodo: true,
            });
          } else if (tipo == "anual") {
            this.setState({
              displayA: "none",
              displayM: "none",
              displayR: "Initial",
              displayS: "none",
              listaPeriodo: true,
            });
          }
        }
      );
    });
  };

  ordenarPor = (campo) => {
    if (this.state.orden === campo) {
      this.setState({ direccion: this.state.direccion === "asc" ? "desc" : "asc" }, () => {
        this.ordenarCargas();
      });
    } else {
      this.setState({ orden: campo, direccion: "asc" }, () => {
        this.ordenarCargas();
      });
    }
  };

  ordenarCargas = () => {
    const { sectores, orden, direccion } = this.state;
    const copiaCargas = [...sectores];

    copiaCargas.sort((a, b) => {
      if (orden === "total") {
        const valorA = parseFloat(a[orden]);
        const valorB = parseFloat(b[orden]);

        if (!isNaN(valorA) && !isNaN(valorB)) {
          const factor = direccion === "asc" ? 1 : -1;
          return (valorA - valorB) * factor;
        }
      } else {
        const factor = direccion === "asc" ? 1 : -1;
        return a[orden].localeCompare(b[orden]) * factor;
      }

      return 0;
    });
    this.setState({ sectores: copiaCargas });
  };

  renderIcon = (campo) => {
    if (campo === this.state.orden) {
      return this.state.direccion === "asc" ? "↓" : "↑";
    }
    return null;
  };

  actualizar = () => {
    this.setState(
      {
        mostrarGrafico: false,
      },
      () => {
        this.setState({
          mostrarGrafico: true,
        });
      }
    );
  };

  render() {
    return (
      <div className="middle-area" style={{ zIndex: 45 }}>
        <div
          style={{
            marginTop: "1%",
            display: "flex",
            zIndex: "2",
            justifyContent: "space-between",
            width: "99%",
          }}
        >
          <div style={{ display: "flex", width: "100%" }}>
            <Tooltip title={"Seleccione la especialidad que desea buscar"}>
              <button className="arrow-down-button1" onClick={() => this.onClickEsp()} style={{ cursor: "pointer" }}>
                <span className="button-text">{this.state.valueSelecEsp}</span>
                <span className="arrow">&#9660;</span>
              </button>
            </Tooltip>
            <Tooltip title={"Seleccione como desea ver los datos"}>
              <button className="arrow-down-button3" onClick={() => this.onClickFormaDatos()} style={{ cursor: "pointer" }}>
                <span className="button-text">{this.state.tipo_grafico}</span>
                <span className="arrow">&#9660;</span>
              </button>
            </Tooltip>
            <Tooltip title={"Seleccione periodo"}>
              <button className="arrow-down-button2" onClick={() => this.onClickPeriodo()} style={{ cursor: "pointer" }}>
                <span className="button-text">{this.state.valueSelecPeriodo}</span>
                <span className="arrow">&#9660;</span>
              </button>
            </Tooltip>
            {this.state.selecSemana && (
              <input
                onChange={(e) => this.actualizarDatos(e)}
                style={{
                  display: "flex",
                  textAlign: "left",
                  background: "transparent",
                  color: "rgb(128, 128, 128)",
                  width: "10rem",
                  marginLeft: "12px",
                  borderRadius: "2px",
                  border: "2px solid orange",
                  fontSize: "0.8vw",
                  position: "relative",
                  alignItems: "center",
                }}
                type="week"
                id="weekInput"
                name="weekInput"
                min={this.state.primeraCargaSemana}
              />
            )}
            {this.state.selecMensual && (
              <input
                onChange={(e) => this.actualizarDatos(e)}
                style={{
                  display: "flex",
                  textAlign: "left",
                  background: "transparent",
                  color: "rgb(128, 128, 128)",
                  width: "10rem",
                  marginLeft: "12px",
                  borderRadius: "2px",
                  border: "2px solid orange",
                  fontSize: "0.8vw",
                  position: "relative",
                  alignItems: "center",
                }}
                type="month"
                id="weekInput"
                name="mes"
                min={this.state.primeraCargaMes}
              />
            )}
            {this.state.selecAnual && (
              <div
                style={{
                  display: "flex",
                  textAlign: "left",
                  background: "transparent",
                  color: "rgb(128, 128, 128)",
                  width: "10rem",
                  marginLeft: "12px",
                  borderRadius: "2px",
                  border: "2px solid orange",
                  fontSize: "1vw",
                  position: "relative",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  onChange={this.actualizarAño}
                  placeholder="---- Año"
                  style={{
                    background: "transparent",
                    border: "none",
                    width: "100%",
                  }}
                  onlyYearPicker
                  name="año"
                  minDate={this.state.primeraCargaAño}
                />
                <img
                  style={{
                    width: "17px",
                    position: "absolute",
                    right: "4%",
                    top: "19%",
                    zIndex: "-5",
                  }}
                  src={img}
                ></img>
              </div>
            )}
            {this.state.selectRango && (
              <div
                style={{
                  display: "flex",
                  textAlign: "left",
                  background: "transparent",
                  color: "rgb(128, 128, 128)",
                  width: "10rem",
                  marginLeft: "12px",
                  borderRadius: "2px",
                  border: "2px solid orange",
                  fontSize: "1vw",
                  position: "relative",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  placeholder="Fecha de inicio"
                  weekDays={["D", "L", "M", "M", "J", "V", "S"]}
                  onChange={this.actualizarFechaInicial}
                  months={["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octtubre", "Noviembre", "Diciembre"]}
                  style={{
                    background: "transparent",
                    border: "none",
                    width: "100%",
                  }}
                  format="DD/MM/YYYY"
                />
                <img
                  style={{
                    width: "17px",
                    position: "absolute",
                    right: "4%",
                    top: "19%",
                    zIndex: "-5",
                  }}
                  src={img}
                ></img>
              </div>
            )}
            {this.state.selectRango && (
              <div
                style={{
                  display: "flex",
                  textAlign: "left",
                  background: "transparent",
                  color: "rgb(128, 128, 128)",
                  width: "10rem",
                  marginLeft: "12px",
                  borderRadius: "2px",
                  border: "2px solid orange",
                  fontSize: "1vw",
                  position: "relative",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  placeholder="Fecha de Termino"
                  onChange={this.actualizarFechaTermino}
                  weekDays={["D", "L", "M", "M", "J", "V", "S"]}
                  months={["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octtubre", "Noviembre", "Diciembre"]}
                  style={{
                    background: "transparent",
                    border: "none",
                    width: "100%",
                  }}
                  format="DD/MM/YYYY"
                />
                <img
                  style={{
                    width: "17px",
                    position: "absolute",
                    right: "4%",
                    top: "19%",
                    zIndex: "-5",
                  }}
                  src={img}
                ></img>
              </div>
            )}
            {this.state.rangoAño && (
              <div
                style={{
                  display: "flex",
                  textAlign: "left",
                  background: "transparent",
                  color: "rgb(128, 128, 128)",
                  width: "10rem",
                  marginLeft: "12px",
                  borderRadius: "2px",
                  border: "2px solid orange",
                  fontSize: "1vw",
                  position: "relative",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  onChange={this.actualizarAñoIncio}
                  placeholder="---- Año Desde"
                  style={{
                    background: "transparent",
                    border: "none",
                    width: "100%",
                  }}
                  onlyYearPicker
                  name="añoinicio"
                  minDate={this.state.primeraCargaAño}
                />
                <img
                  style={{
                    width: "17px",
                    position: "absolute",
                    right: "4%",
                    top: "19%",
                    zIndex: "-5",
                  }}
                  src={img}
                ></img>
              </div>
            )}
            {this.state.rangoAño && (
              <div
                style={{
                  display: "flex",
                  textAlign: "left",
                  background: "transparent",
                  color: "rgb(128, 128, 128)",
                  width: "10rem",
                  marginLeft: "12px",
                  borderRadius: "2px",
                  border: "2px solid orange",
                  fontSize: "1vw",
                  position: "relative",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  onChange={this.actualizarAñoTermino}
                  placeholder="---- Año Hasta"
                  style={{
                    background: "transparent",
                    border: "none",
                    width: "100%",
                  }}
                  onlyYearPicker
                  name="añotermino"
                  minDate={this.state.primeraCargaAño}
                />
                <img
                  style={{
                    width: "17px",
                    position: "absolute",
                    right: "4%",
                    top: "19%",
                    zIndex: "-5",
                  }}
                  src={img}
                ></img>
              </div>
            )}

            {this.state.rangoSemana && (
              <input
                onChange={(e) => this.actualizarDatos(e)}
                style={{
                  display: "flex",
                  textAlign: "left",
                  background: "transparent",
                  color: "rgb(128, 128, 128)",
                  width: "10rem",
                  marginLeft: "12px",
                  borderRadius: "2px",
                  border: "2px solid orange",
                  fontSize: "0.8vw",
                  position: "relative",
                  alignItems: "center",
                }}
                type="week"
                id="selecSemanaI"
                name="selecSemanaI"
                min={this.state.primeraCargaSemana}
              />
            )}
            {this.state.rangoSemana && (
              <input
                onChange={(e) => this.actualizarDatos(e)}
                style={{
                  display: "flex",
                  textAlign: "left",
                  background: "transparent",
                  color: "rgb(128, 128, 128)",
                  width: "10rem",
                  marginLeft: "12px",
                  borderRadius: "2px",
                  border: "2px solid orange",
                  fontSize: "0.8vw",
                  position: "relative",
                  alignItems: "center",
                }}
                type="week"
                id="selecSemanaT"
                name="selecSemanaT"
                min={this.state.primeraCargaSemana}
              />
            )}
            {this.state.rangoMes && (
              <input
                onChange={(e) => this.actualizarDatos(e)}
                style={{
                  display: "flex",
                  textAlign: "left",
                  background: "transparent",
                  color: "rgb(128, 128, 128)",
                  width: "10rem",
                  marginLeft: "12px",
                  borderRadius: "2px",
                  border: "2px solid orange",
                  fontSize: "0.8vw",
                  position: "relative",
                  alignItems: "center",
                }}
                type="month"
                id="weekInput"
                name="mesI"
                min={this.state.primeraCargaMes}
              />
            )}
            {this.state.rangoMes && (
              <input
                onChange={(e) => this.actualizarDatos(e)}
                style={{
                  display: "flex",
                  textAlign: "left",
                  background: "transparent",
                  color: "rgb(128, 128, 128)",
                  width: "10rem",
                  marginLeft: "12px",
                  borderRadius: "2px",
                  border: "2px solid orange",
                  fontSize: "0.8vw",
                  position: "relative",
                  alignItems: "center",
                }}
                type="month"
                id="weekInput"
                name="mesT"
                min={this.state.primeraCargaMes}
              />
            )}
            <Tooltip title={"Actualizar grafico con nuevos datos"}>
              <Button
                onClick={this.actualizar}
                style={{
                  fontSize: "80%",
                  marginLeft: "1%",
                  background: "orange",
                  cursor: "pointer",
                }}
              >
                <span style={{ color: "#FFFFFF" }}>Actualizar</span>
              </Button>
            </Tooltip>
            <Tooltip title={"Limpia los filtros"}>
              <Button
                onClick={this.botonBorrar}
                style={{
                  fontSize: "80%",
                  marginLeft: "1%",
                  background: "orange",
                  cursor: "pointer",
                }}
              >
                <span style={{ color: "#FFFFFF" }}>Limpiar</span>
              </Button>
            </Tooltip>
          </div>

          {this.state.displaySector && (
            <div style={{ left: "80%" }}>
              <button className={graf.btnLimpiarSectores} onClick={() => this.onclickTabla()}>
                Sectores sin carga
              </button>
            </div>
          )}

          {this.state.mostrarTabla && (
            <div
              className="modal"
              onClick={(e) => this.onclosTabla(e)}
              style={{
                display: "block",
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 2,
                background: "rgba(0,0,0,0.5)",
              }}
            >
              <div
                className="modal-content"
                onClick={(e) => e.stopPropagation()}
                style={{
                  position: "absolute",
                  top: "56%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  background: "#fff",
                  width: "50%",
                  height: "auto",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  zIndex: 3,
                }}
              >
                <h3
                  style={{
                    color: "rgb(102, 102, 102)",
                    fontSize: "1.5rem",
                    position: "relative",
                    padding: "4%",
                  }}
                >
                  Sectores que no han subido horas
                </h3>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingBottom: "5%",
                  }}
                >
                  <table className={graf.mitabla}>
                    <thead>
                      <tr>
                        <th onClick={() => this.ordenarPor("ssa_sector_nombre")}>Sector{this.renderIcon("ssa_sector_nombre")}</th>
                        <th onClick={() => this.ordenarPor("ssa_ultima_carga")}>Ultima Carga{this.renderIcon("ssa_ultima_carga")}</th>
                        <th onClick={() => this.ordenarPor("ssa_especialidad__esp_especialidad")}>
                          Especialidad
                          {this.renderIcon("ssa_especialidad__esp_especialidad")}
                        </th>
                        <th onClick={() => this.ordenarPor("Nombre")}>Quien Cargo{this.renderIcon("Nombre")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.sectores.map((item) => (
                        <tr>
                          <td>{item.ssa_sector_nombre}</td>
                          <td>{item.ssa_ultima_carga}</td>
                          <td>{item.ssa_especialidad__esp_especialidad}</td>
                          <td>{item.Nombre}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
        <div style={{ width: "99%", display: "flex" }}>
          {this.state.listaEspecialidades && (
            <ul
              onMouseLeave={() => this.onOutEsp()}
              style={{
                position: "absolute",
                background: "linear-gradient(to bottom, #143275, #225093, #3675c1)",
                width: "11.8rem",
                zIndex: 2,
                display: "flex",
                flexDirection: "column",
                borderRadius: "7px",

                top: "3rem",
                marginLeft: "0.5rem",
              }}
            >
              {this.state.especialidades.map((item) => (
                <Button
                  style={{
                    borderBottom: "1px solid white",
                    color: "white",
                  }}
                  onClick={() => this.clickEspecialidad(item)}
                  value={item.esp_sigla}
                >
                  {item.esp_especialidad}
                </Button>
              ))}
            </ul>
          )}

          {this.state.formaDatos && (
            <ul
              onMouseLeave={() => this.onOutFormaDatos()}
              style={{
                position: "absolute",
                background: "linear-gradient(to bottom, #40b56f, #63c189, #79d89f)",
                width: "11.8rem",
                zIndex: 2,
                display: "flex",
                flexDirection: "column",
                borderRadius: "7px",
                left: "13rem",
                top: "3rem",
                height: "9.4rem",
              }}
            >
              <Button style={{ borderBottom: "1px solid white", color: "white" }} onClick={() => this.actualizarGrafico("dia", "Día")} value="dia">
                Por Dia
              </Button>
              <Button style={{ borderBottom: "1px solid white", color: "white" }} onClick={() => this.actualizarGrafico("semanal", "Semana")} value="semana">
                Por Semana
              </Button>
              <Button style={{ borderBottom: "1px solid white", color: "white" }} onClick={() => this.actualizarGrafico("mensual", "Mes")} value="mes">
                Por Mes
              </Button>
              <Button style={{ borderBottom: "1px solid white", color: "white" }} onClick={() => this.actualizarGrafico("anual", "Año")} value="año">
                Por Año
              </Button>
            </ul>
          )}

          {this.state.listaPeriodo && (
            <ul
              onMouseLeave={() => this.onOutPeriodo()}
              style={{
                position: "absolute",
                background: "linear-gradient(to bottom, #09a6d1, #23bae8, #64ddf9)",
                width: "11.8rem",
                zIndex: 2,
                display: "flex",
                flexDirection: "column",
                borderRadius: "7px",
                left: "24.8rem",
                top: "3rem",
                marginLeft: "0.6rem",
                height: "9.4rem",
              }}
            >
              <Button
                value="0"
                onClick={() => this.onClickSemana()}
                style={{
                  display: this.state.displayS,
                  borderBottom: "1px solid white",
                  color: "white",
                }}
              >
                Semanal
              </Button>

              <Button
                value="1"
                onClick={() => this.onClickMensual()}
                style={{
                  display: this.state.displayM,
                  borderBottom: "1px solid white",
                  color: "white",
                }}
              >
                Mensual
              </Button>

              <Button
                value="2"
                onClick={() => this.onClickAnual()}
                style={{
                  display: this.state.displayA,
                  borderBottom: "1px solid white",
                  color: "white",
                }}
              >
                Anual
              </Button>

              <Button
                value="3"
                onClick={() => this.onClickRango()}
                style={{
                  display: this.state.displayR,
                  borderBottom: "1px solid white",
                  color: "white",
                }}
              >
                Dar Rango
              </Button>
            </ul>
          )}
        </div>
        <div>{this.state.mostrarGrafico && <GrafPrincipal cesfam={this.state.id_ces} semana={this.state.weekInput} año={this.state.año} esp={this.state.esp} mes={this.state.mes} fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} tipo_graft={this.state.tipo_graft} añoinicio={this.state.añoinicio} añotermino={this.state.añotermino} semanaInicio={this.state.selecSemanaI} semanaTermino={this.state.selecSemanaT} mesI={this.state.mesI} mesT={this.state.mesT} />}</div>
      </div>
    );
  }
}
