import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";
// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class ModificarDatosUsuario extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      nombre: "",
      apellidoP: "",
      apellidoM: "",
      fechaNacimiento: "",
      uspId: parseInt(store.getState().usp),
      open: true,
      usuId: props.usuId,
    };
  }

  componentDidMount() {
    this.traerUsuario();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
        },
      ],
    });
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  traerUsuario() {
    let apiUrl = global.config.modulos.usuarios + "por_id/" + this.state.usuId;
    axios(apiUrl, {
      headers: authHeader(),
    }).then((res) => {
      this.setState({
        nombre: res.data["datos"]["usu_nombre"],
        apellidoP: res.data["datos"]["usu_apellido_p"],
        apellidoM: res.data["datos"]["usu_apellido_m"],
        fechaNacimiento: res.data["datos"]["usu_fecha_nacimiento"],
      });
    });
  }

  enviarFormulario = (e) => {
    if (this.validaciones()) {
      var data = {
        usu_nombre: this.state.nombre,
        usu_apellido_p: this.state.apellidoP,
        usu_apellido_m: this.state.apellidoM,
        usu_fecha_nacimiento: this.state.fechaNacimiento,
        canal: 5,
        usp_id: this.state.uspId,
      };

      let apiUrl = global.config.modulos.usuarios + "por_id/" + this.state.usuId;

      const options = {
        method: "PATCH",
        data: data,
        headers: authHeader(),
      };

      axios(apiUrl, options).then((res) => {
        NotificationManager.success("Usuario modificado con éxito");
        this.salir();
      });
    }
  };

  validaciones = () => {
    const { nombre, apellidoP, fechaNacimiento } = this.state;

    if (!nombre) {
      this.mostrarAlerta("Escriba un nombre");
      return false;
    }
    if (!apellidoP) {
      this.mostrarAlerta("Escriba un apellido");
      return false;
    }
    if (!fechaNacimiento) {
      this.mostrarAlerta("Registre la fecha de nacimiento");
      return false;
    }
    return true;
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Modificar</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <InputLabel>Nombre</InputLabel>
                  <input type="text" name="nombre" value={this.state.nombre} onChange={this.cambiarValores} placeholder="" className={"inputText"} />
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <InputLabel>Apellido Paterno</InputLabel>
                  <input type="text" name="apellidoP" value={this.state.apellidoP} onChange={this.cambiarValores} placeholder="" className={"inputText"} />
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <InputLabel>Apellido Materno</InputLabel>
                  <input type="text" name="apellidoM" value={this.state.apellidoM} onChange={this.cambiarValores} placeholder="" className={"inputText"} />
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <InputLabel>Fecha de Nacimiento</InputLabel>
                  <TextField
                    id="fechaNacimiento"
                    name="fechaNacimiento"
                    type="date"
                    value={this.state.fechaNacimiento}
                    onChange={this.cambiarValores}
                    inputProps={{
                      max: this.state.hoy,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                    Cancelar
                  </Button>
                  <Button onClick={this.enviarFormulario} color="primary" className="fondoVerde">
                    Modificar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
