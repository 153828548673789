import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import chileanRut from "chilean-rut";
import axios from "axios";
import moment from "moment";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Buttons from "react-multi-date-picker/components/button";
import UsuarioEnEspera from "./UsuarioEnEspera";

import { Input } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import { store } from "./../../store/index";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Grid } from "@material-ui/core";

import "assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class AgregarUsuarioTratamiento extends Component {
  constructor(props) {
    super(props);
    var today = new Date(),
      date = moment(today).format("YYYY-MM-DD");

    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      herramientas: store.getState().herramientas,
      cliente: store.getState().cliente,

      open: true,
      observaciones: [],
      modalUsuarioEnEspera: false,

      usuId: null,
      rut: "",
      nombres: "",
      edad: date,
      fecha_nacimiento: "",
      sexo: "",
      telefono1: "",
      telefono2: "",
      observacion_libre: "",

      buscado: false,
      fecha_registro: date,
      hoy: moment().format("YYYY-MM-DD"),

      estado_gestion: 0,
      tratamientos: [],
      tratamiento: null,
      tipo_lista: 0,
      mostrar_select: 0,
      escalas_trat: [],
      escala: null,
      tratamiento_secundarios: [],
      trat_sec: null,
      trat_libre: 0,
      tratamiento_libre: null,
      info_obs_fija: null,
      mensaje_obs_fija: null,
      fecha_diagnostico: date,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  componentDidMount() {
    this.obtenerObservaciones();
    this.obtenerTratamientos();
    this.obtenerCesfamGestion();
    this.obtenerCesfams_evaluacion();
  }

  obtenerObservaciones = () => {
    axios
      .get(global.config.modulos.listaEspera + "ver_observaciones_fijas/", {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            observaciones: res.data.datos,
          });
        }
      });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  obtenerCesfams_evaluacion = () => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_cesfams_evaluacion/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState(
            {
              cesfam: res.data.datos["tc_cesfam_transformado"],
              tipo_lista: res.data.datos["tc_tipo_lista"],
            },
            () => this.obtenerTratamientos(),
            this.obtenerTratamientoSec()
          );
        }
      });
  };

  obtenerCesfamGestion = () => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_cesfam_gestion/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            estado_gestion: 1,
          });
        } else {
          this.setState({
            estado_gestion: 0,
          });
        }
      });
  };

  obtenerTratamientos = () => {
    axios
      .get(global.config.modulos.listaEspera + "ver_tratamientos/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            tratamientos: res.data.datos,
          });
        }
      });
  };

  obtenerEscalasTratamiento = (tratamiento) => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_escalas_evaluacion/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
          especialidad: tratamiento,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            escalas_trat: res.data.datos,
            mostrar_select: 1,
          });
        } else {
          this.setState({
            mostrar_select: 0,
          });
        }
      });
  };

  obtenerTratamientoSec = () => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_tratamientos_secundarios/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            tratamiento_secundarios: res.data.datos,
          });
        }
      });
  };

  obtenerInfoTTo = (tratamiento) => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_tratamientos_secundarios/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
          tratamiento: tratamiento,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            info_obs_fija: res.data.datos[0]["lre_id_obs_fija"],
            mensaje_obs_fija: res.data.datos[0]["lre_mensaje_alerta"],
          });
        } else {
          this.setState({
            info_obs_fija: null,
          });
        }
      });
  };

  tratamiento = (e) => {
    const tratamiento = e.target.value;
    this.setState({ tratamiento: tratamiento });

    if (tratamiento == "63") {
      this.setState({ trat_libre: 1 });
    } else {
      this.setState({ trat_libre: 0 });
    }
    this.obtenerEscalasTratamiento(tratamiento);
  };

  escala_tt = (e) => {
    const escala = e.target.value;
    this.setState({ escala: escala });
  };

  tratamiento_sec = (e) => {
    const trat_sec = e.target.value;
    this.setState({ trat_sec: trat_sec });
    this.obtenerInfoTTo(trat_sec);
  };

  buscarRayen = async (rut) => {
    this.setState({ buscado: true });
    if (chileanRut.validate(this.state.rut) === false) {
      NotificationManager.error("Rut ingresado es incorrecto", "Atencion", 3000);
      return;
    }
    axios
      .get(global.config.modulos.listaEspera + "buscar_usuario_rayen/", {
        headers: authHeader(),
        params: {
          rut: rut,
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          if (res.data.espera) {
            this.setState({
              usuId: res.data.usuario,
              modalUsuarioEnEspera: true,
            });
          }
          if (res.data.horasalud) {
            var sexo = res.data.datos["usu_sexo"];
            if (sexo == null) {
              sexo = "0";
            }

            var data = {
              usuId: res.data.datos["usu_id"],
              nombres: res.data.datos["nombres"],
              fecha_nacimiento_r: res.data.datos["usu_fecha_nacimiento"],
              fecha_nacimiento: res.data.datos["usu_fecha_nacimiento"],
              sexo_r: res.data.datos["usu_sexo"],
              sexo: sexo,
              telefono1: res.data.datos["usu_telefono1"],
              telefono2: res.data.datos["usu_telefono2"],
              datosPaciente: true,
            };

            this.setState(data);
          } else if (res.data.fallecido) {
            this.mostrarAlerta(res.data.mensaje);
          } else if (res.data.percapitado) {
            this.mostrarAlerta(res.data.mensaje);
          }
        }
      });
  };

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
        },
      ],
    });
  }

  validaciones() {
    const { rut, fecha_nacimiento, sexo, telefono1, telefono2 } = this.state;

    if (!rut) {
      NotificationManager.error("Escribe el rut.");
      return false;
    }

    if (!chileanRut.validate(rut)) {
      NotificationManager.error("Escribe el rut valido.");
      return false;
    }

    if (!fecha_nacimiento) {
      NotificationManager.error("Escribe la Fecha de Nacimiento.");
      return false;
    }

    if (sexo === "" || sexo === null || sexo === "0") {
      NotificationManager.error("Selecciona el sexo del usuario.");
      return false;
    }

    if ((telefono1 == null && telefono2 == null) || (telefono1 == "" && telefono2 == "")) {
      NotificationManager.error("Debes escribir al menos un télefono de contacto", "Atención", 3000);
      return false;
    }

    if (telefono1 != null && telefono1 != "") {
      if (telefono1.length > 0 && telefono1.length < 9) {
        NotificationManager.error("Escribe el número de celular correcto.");
        return false;
      }
    }

    if (telefono2 != null && telefono2 != "") {
      if (telefono2.length > 0 && telefono2.length < 9) {
        NotificationManager.error("Escribe el número de casa correcto.");
        return false;
      }
    }

    if (this.state.tipo_lista == 2) {
      if (this.state.tratamiento === "" || this.state.tratamiento === null) {
        NotificationManager.error("Debe ingresar tratamiento del paciente", "Atención", 3000);
        return false;
      }

      if (this.state.fecha_diagnostico === "" || this.state.fecha_diagnostico === null) {
        NotificationManager.error("Debe ingresar fecha del diagnostico", "Atención", 3000);
        return false;
      }
      if (this.state.trat_sec === "" || this.state.trat_sec === null) {
        NotificationManager.error("Debe ingresar resultado de evaluaciones", "Atención", 3000);
        return false;
      }

      if (this.state.mostrar_select == 1) {
        if (this.state.escala === "" || this.state.escala === null) {
          NotificationManager.error("Debe ingresar escala de evaluación", "Atención", 3000);
          return false;
        }
      }
      if (this.state.trat_libre == 1) {
        if (this.state.tratamiento_libre === "" || this.state.tratamiento_libre === null) {
          NotificationManager.error("Debe ingresar el tipo de atención", "Atención", 3000);
          return false;
        }
      }
      if (this.state.info_obs_fija != null) {
        return this.AlertaFijo();
      }
    }

    return true;
  }

  verificar_edad = () => {
    if (this.validaciones()) {
      const url = "verificar_edad/";
      const params = {
        fecha_nacimiento: this.state.fecha_nacimiento,
        cesfam: this.state.cesfam,
      };

      return axios
        .get(global.config.modulos.listaEspera + url, { headers: authHeader(), params })
        .then((res) => {
          var data = res.data;
          var estado = data.estado;
          if (estado) {
            this.mostrarAlerta(data.mensaje);
          } else {
            this.agregarUsuario();
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  AlertaFijo = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui"
            style={{
              backgroundColor: "white",
              width: "100%",
              padding: "40px",
              borderRadius: "20px",
            }}
          >
            <h1
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              ¡Atención!
            </h1>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <p>{this.state.mensaje_obs_fija}</p>
                <p>Se ingresara los datos ingresados al listado de otros casos</p>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "3%",
                    marginLeft: "70%",
                    marginTop: "2%",
                  }}
                >
                  <Buttons
                    onClick={() => {
                      onClose();
                      this.agregarUsuario();
                    }}
                    className={"btnOpcion fondoNaranjo"}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2%",
                      marginTop: "2%",
                      fontSize: "16px",
                      float: "left",
                      border: "0",
                      textAlign: "center",
                      height: "40px",
                      width: "150px",
                    }}
                  >
                    Aceptar
                  </Buttons>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        );
      },
    });
  };

  agregarUsuario = () => {
    var data = {
      usuId: this.state.usuId,
      cesfam: this.state.cesfam,
      sexo: this.state.sexo,
      fecha_nacimiento: this.state.fecha_nacimiento,

      observacion: this.state.observacion_libre,

      fecha_registro: this.state.fecha_registro,
      tratamiento: this.state.tratamiento,
      escala: this.state.escala,
      tratamiento_sec: this.state.trat_sec,
      tto_libre: this.state.tratamiento_libre,
      observacionFija: this.state.info_obs_fija,
      fecha_diag: this.state.fecha_diagnostico,
      uspId: this.state.uspId,
    };

    if (this.state.telefono1 != "" && this.state.telefono1 != null) {
      data["telefono1"] = this.state.telefono1;
    }

    if (this.state.telefono2 != "" && this.state.telefono2 != null) {
      data["telefono2"] = this.state.telefono2;
    }

    axios
      .post(global.config.modulos.listaEspera + "agregar_oirs/", data, {
        headers: authHeader(),
      })
      .then((res) => {
        this.mostrarAlerta(res.data.mensaje);
        this.salir();
      });
  };

  hideModalUsuarioEnEspera = () => {
    this.setState({
      modalUsuarioEnEspera: false,
    });
    this.salir();
  };

  limpiarRut = () => {
    this.setState({
      datosPaciente: false,
      rut: "",
    });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogContent>
            <h2>Ingresar paciente en lista de espera</h2>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ marginTop: "2vh" }}>
                <Grid container spacing={3}>
                  <Grid item xs={this.state.datosPaciente ? 4 : 11}>
                    <Grid container spacing={3}>
                      <Grid item xs={8}>
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">Rut</InputLabel>
                          <Input type="text" name="rut" value={this.state.rut} onChange={this.cambiarValores} disabled={this.state.datosPaciente ? true : false} />
                        </FormControl>
                      </Grid>

                      <Grid item xs={2}>
                        <Buttons onClick={() => this.buscarRayen(this.state.rut)} className={"btnOpcion fondoVerde"}>
                          Buscar
                        </Buttons>
                      </Grid>

                      {this.state.datosPaciente && (
                        <Grid item xs={2}>
                          <Buttons onClick={() => this.limpiarRut()} className={"btnOpcion fondoNaranjo"}>
                            Limpiar
                          </Buttons>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {this.state.datosPaciente && (
                    <GridItem xs={9} sm={9} md={9}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">Nombre completo</InputLabel>
                            <Input type="text" name="nombres" value={this.state.nombres} onChange={this.cambiarValores} disabled />
                          </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              label="Fecha de nacimiento"
                              id="date"
                              name="fecha_nacimiento"
                              type="date"
                              value={this.state.fecha_nacimiento}
                              onChange={this.cambiarValores}
                              disabled={this.state.fecha_nacimiento_r ? true : false}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                inputProps: {
                                  min: "1900-01-01",
                                  max: this.state.hoy,
                                },
                              }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              label="Sexo"
                              name="sexo"
                              value={this.state.sexo}
                              onChange={this.cambiarValores}
                              disabled={this.state.sexo_r ? true : false}
                              select
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              <MenuItem value="0">Seleccione Sexo</MenuItem>
                              <MenuItem value="M">Masculino</MenuItem>
                              <MenuItem value="F">Femenino</MenuItem>
                              <MenuItem value="I">InterSex</MenuItem>
                            </TextField>
                          </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">Fecha registro</InputLabel>
                            <Input type="date" name="fecha_registro" value={this.state.fecha_registro} onChange={this.cambiarValores} />
                          </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">Teléfono 1</InputLabel>
                            <Input type="number" name="telefono1" value={this.state.telefono1} onChange={this.cambiarValores} />
                          </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">Teléfono 2</InputLabel>
                            <Input type="number" name="telefono2" value={this.state.telefono2} onChange={this.cambiarValores} />
                          </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">Observación libre</InputLabel>
                            <Input type="text" name="observacion_libre" value={this.state.observacion_libre} onChange={this.cambiarValores} />
                          </FormControl>
                        </Grid>

                        {this.state.estado_gestion == 1 ? (
                          <Grid item xs={4}>
                            <FormControl style={{ width: "100%" }}>
                              <InputLabel id="demo-simple-select-label">Seleccione Programa</InputLabel>
                              <Select name="tratamiento" value={this.state.tratamiento} onChange={this.tratamiento}>
                                <MenuItem value={null}>Seleccione Tratamiento</MenuItem>
                                {this.state.tratamientos.map((tratamiento, i) => {
                                  return (
                                    <MenuItem key={i} value={tratamiento.lt_id}>
                                      {tratamiento.lt_nombre}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        ) : null}

                        {this.state.tipo_lista == 2 ? (
                          <Grid item xs={4}>
                            <FormControl style={{ width: "100%" }}>
                              <InputLabel id="demo-simple-select-label">Seleccione Diagnóstico</InputLabel>
                              <Select name="tratamiento" value={this.state.tratamiento} onChange={this.tratamiento}>
                                <MenuItem value={null}>Seleccione Diagnóstico</MenuItem>
                                {this.state.tratamientos.map((tratamiento, i) => {
                                  return (
                                    <MenuItem key={i} value={tratamiento.lt_id}>
                                      {tratamiento.lt_nombre}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        ) : null}

                        {this.state.trat_libre == 1 ? (
                          <Grid item xs={4}>
                            <FormControl style={{ width: "30%", marginLeft: "5%" }}>
                              <InputLabel id="demo-simple-select-label">Indique patología</InputLabel>
                              <Input type="text" name="tratamiento_libre" value={this.state.tratamiento_libre} onChange={this.cambiarValores} />
                            </FormControl>
                          </Grid>
                        ) : null}

                        {this.state.mostrar_select == 1 ? (
                          <Grid item xs={4}>
                            <FormControl style={{ width: "100%" }}>
                              <InputLabel id="demo-simple-select-label">Escala de evaluacion</InputLabel>
                              <Select name="tratamiento" value={this.state.escala} onChange={this.escala_tt}>
                                <MenuItem value={null}>Seleccione escala de evaluacion</MenuItem>
                                {this.state.escalas_trat.map((escala, i) => {
                                  return (
                                    <MenuItem key={i} value={escala.ev_id}>
                                      {escala.ev_nombre}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        ) : null}

                        {this.state.tipo_lista == 2 ? (
                          <Grid item xs={4}>
                            <FormControl style={{ width: "100%" }}>
                              <InputLabel id="demo-simple-select-label">Resultado examen</InputLabel>
                              <Select name="tratamiento" value={this.state.trat_sec} onChange={this.tratamiento_sec}>
                                <MenuItem value={null}>Seleccione resultado de examen</MenuItem>
                                {this.state.tratamiento_secundarios.map((tt_sec, i) => {
                                  return (
                                    <MenuItem key={i} value={tt_sec.lre_id}>
                                      {tt_sec.lre_nombre}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        ) : null}

                        {this.state.tipo_lista == 2 ? (
                          <Grid item xs={4}>
                            <FormControl style={{ width: "100%" }}>
                              <InputLabel id="demo-simple-select-label">Fecha diagnóstico</InputLabel>
                              <Input type="date" name="fecha_diagnostico" value={this.state.fecha_diagnostico} onChange={this.cambiarValores} />
                            </FormControl>
                          </Grid>
                        ) : null}
                      </Grid>
                    </GridItem>
                  )}
                </Grid>
              </GridItem>

              {this.state.datosPaciente && (
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <Button onClick={this.salir} className="botonSimple floatRigth fondoRojo" style={{ marginLeft: 20 }}>
                    Salir
                  </Button>
                  <Button onClick={() => this.verificar_edad()} className="botonSimple fondoVerde" id="btnAgregar" style={{ float: "right" }}>
                    Agregar
                  </Button>
                </GridItem>
              )}
            </GridContainer>
          </DialogContent>
        </Dialog>
        {this.state.modalUsuarioEnEspera ? <UsuarioEnEspera ocultar={this.hideModalUsuarioEnEspera} usuId={this.state.usuId}></UsuarioEnEspera> : null}
      </div>
    );
  }
}
