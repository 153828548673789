import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";

import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Buttons from "react-multi-date-picker/components/button";

import { Input } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert"; // Import

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "../../assets/css/usuarios.css";

export default class AlertaReingreso extends Component {
  constructor(props) {
    super(props);
    var today = new Date(),
      date = moment(today).format("YYYY-MM-DD");

    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      herramientas: store.getState().herramientas,
      cliente: store.getState().cliente,

      open: true,
      observaciones: [],

      usuId: props.usuId,

      observacion_libre: null,

      estado_gestion: 0,
      tratamientos: [],
      tratamiento: null,

      tipo_lista: 0,
      mostrar_select: 0,
      escalas_trat: [],
      escala: null,
      tratamiento_secundarios: [],
      trat_sec: null,
      trat_libre: 0,
      tratamiento_libre: null,
      info_obs_fija: null,
      mensaje_obs_fija: null,
      fecha_diagnostico: date,
    };
  }
  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  componentDidMount() {
    this.obtenerCesfamGestion();
    this.obtenerTratamientos();
    this.obtenerCesfams_evaluacion();
  }

  obtenerCesfams_evaluacion = () => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_cesfams_evaluacion/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState(
            {
              cesfam: res.data.datos["tc_cesfam_transformado"],
              tipo_lista: res.data.datos["tc_tipo_lista"],
            },
            () => this.obtenerTratamientos(),
            this.obtenerTratamientoSec()
          );
        }
      });
  };

  obtenerTratamientos = () => {
    axios
      .get(global.config.modulos.listaEspera + "ver_tratamientos/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            tratamientos: res.data.datos,
          });
        }
      });
  };

  obtenerEscalasTratamiento = (tratamiento) => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_escalas_evaluacion/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
          especialidad: tratamiento,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            escalas_trat: res.data.datos,
            mostrar_select: 1,
          });
        } else {
          this.setState({
            mostrar_select: 0,
          });
        }
      });
  };

  obtenerTratamientoSec = () => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_tratamientos_secundarios/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            tratamiento_secundarios: res.data.datos,
          });
        }
      });
  };

  obtenerInfoTTo = (tratamiento) => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_tratamientos_secundarios/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
          tratamiento: tratamiento,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            info_obs_fija: res.data.datos["lre_id_obs_fija"],
            mensaje_obs_fija: res.data.datos["lre_mensaje_alerta"],
          });
        } else {
          this.setState({
            info_obs_fija: null,
          });
        }
      });
  };

  tratamiento = (e) => {
    const tratamiento = e.target.value;
    this.setState({ tratamiento: tratamiento });

    if (tratamiento == "63") {
      this.setState({ trat_libre: 1 });
    } else {
      this.setState({ trat_libre: 0 });
    }
    this.obtenerEscalasTratamiento(tratamiento);
  };

  escala_tt = (e) => {
    const escala = e.target.value;
    this.setState({ escala: escala });
  };

  tratamiento_sec = (e) => {
    const trat_sec = e.target.value;
    this.setState({ trat_sec: trat_sec });
    this.obtenerInfoTTo(trat_sec);
  };

  obtenerCesfamGestion = () => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_cesfam_gestion/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            estado_gestion: 1,
          });
        } else {
          this.setState({
            estado_gestion: 0,
          });
        }
      });
  };

  validaciones = () => {
    if (this.state.tipo_lista == 2) {
      if (this.state.tratamiento === "" || this.state.tratamiento === null) {
        NotificationManager.error("Debe ingresar tratamiento del paciente", "Atencion", 3000);
        return;
      }

      if (this.state.fecha_diagnostico === "" || this.state.fecha_diagnostico === null) {
        NotificationManager.error("Debe ingresar fecha del diagnostico", "Atencion", 3000);
        return;
      }
      if (this.state.trat_sec === "" || this.state.trat_sec === null) {
        NotificationManager.error("Debe ingresar resultado de evaluaciones", "Atencion", 3000);
        return;
      }

      if (this.state.mostrar_select == 1) {
        if (this.state.escala === "" || this.state.escala === null) {
          NotificationManager.error("Debe ingresar escala de evaluación", "Atencion", 3000);
          return;
        }
      }
      if (this.state.trat_libre == 1) {
        if (this.state.tratamiento_libre === "" || this.state.tratamiento_libre === null) {
          NotificationManager.error("Debe ingresar el tipo de atención", "Atencion", 3000);
          return;
        }
      }
      if (this.state.info_obs_fija != null) {
        return this.AlertaFijo();
      }
    }

    this.agregarUsuario();
  };

  AlertaFijo = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui"
            style={{
              backgroundColor: "white",
              width: "100%",
              padding: "40px",
              borderRadius: "20px",
            }}
          >
            <h1
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              ¡Atención!
            </h1>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <p>{this.state.mensaje_obs_fija}</p>
                <p>Se ingresara los datos ingresados al listado de otros casos</p>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "3%",
                    marginLeft: "70%",
                    marginTop: "2%",
                  }}
                >
                  <Buttons
                    onClick={() => {
                      onClose();
                      this.agregarUsuario();
                    }}
                    className={"btnOpcion fondoNaranjo"}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2%",
                      marginTop: "2%",
                      fontSize: "16px",
                      float: "left",
                      border: "0",
                      textAlign: "center",
                      height: "40px",
                      width: "150px",
                    }}
                  >
                    Aceptar
                  </Buttons>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        );
      },
    });
  };

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
        },
      ],
    });
  }

  agregarUsuario = () => {
    axios
      .post(
        global.config.modulos.listaEspera + "agregar_oirs/",
        {
          usuId: this.state.usuId,

          cesfam: this.state.cesfam,

          observacion: this.state.observacion_libre,
          uspId: this.state.uspId,

          tratamiento: this.state.tratamiento,
          escala: this.state.escala,
          tratamiento_sec: this.state.trat_sec,
          tto_libre: this.state.tratamiento_libre,
          observacionFija: this.state.info_obs_fija,
          fecha_diag: this.state.fecha_diagnostico,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        this.mostrarAlerta(res.data.mensaje);
        this.salir();
      });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogContent
            style={{
              width: "1100px",
              maxWidth: "100%",
              height: "100%",
              maxHeight: "100%",
            }}
          >
            <div>
              <h1
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  marginBottom: "20px",
                }}
              >
                ¡Atención!
              </h1>
              <GridContainer>
                <GridItem xs={10} sm={10} md={10}>
                  <p>¿Deseas ingresar nuevamente a este paciente a la lista de espera?</p>
                  <Grid container spacing={2} style={{ marginTop: "1%" }}>
                    <Grid item xs={5}>
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">Seleccione {this.state.estado_gestion == 1 ? "Programa" : "Diagnóstico"}</InputLabel>
                        <Select name="tratamiento" value={this.state.tratamiento} onChange={this.tratamiento}>
                          <MenuItem value={null}>Seleccione {this.state.estado_gestion == 1 ? "Programa" : "Diagnóstico"}</MenuItem>
                          {this.state.tratamientos.map((tratamiento, i) => {
                            return (
                              <MenuItem key={i} value={tratamiento.lt_id}>
                                {tratamiento.lt_nombre}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    {this.state.trat_libre == 1 ? (
                      <Grid item xs={5}>
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">ingrese tratamiento</InputLabel>
                          <Input type="text" name="tratamiento_libre" value={this.state.tratamiento_libre} onChange={this.cambiarValores} />
                        </FormControl>
                      </Grid>
                    ) : null}

                    {this.state.mostrar_select == 1 ? (
                      <Grid item xs={5}>
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">Escala de evaluacion</InputLabel>
                          <Select name="tratamiento" value={this.state.escala} onChange={this.escala_tt}>
                            <MenuItem value={null}>Seleccione escala de evaluacion</MenuItem>
                            {this.state.escalas_trat.map((escala, i) => {
                              return (
                                <MenuItem key={i} value={escala.ev_id}>
                                  {escala.ev_nombre}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    ) : null}

                    {this.state.tipo_lista == 2 ? (
                      <Grid item xs={5}>
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">Resultado examen</InputLabel>
                          <Select name="tratamiento" value={this.state.trat_sec} onChange={this.tratamiento_sec}>
                            <MenuItem value={null}>Seleccione resultado de examen</MenuItem>
                            {this.state.tratamiento_secundarios.map((tt_sec, i) => {
                              return (
                                <MenuItem key={i} value={tt_sec.lre_id}>
                                  {tt_sec.lre_nombre}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    ) : null}

                    {this.state.tipo_lista == 2 ? (
                      <Grid item xs={5}>
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">Fecha diagnóstico</InputLabel>
                          <Input type="date" name="fecha_diagnostico" value={this.state.fecha_diagnostico} onChange={this.cambiarValores} />
                        </FormControl>
                      </Grid>
                    ) : null}
                  </Grid>

                  <div
                    style={{
                      display: "flex",
                      marginBottom: "3%",
                      marginLeft: "70%",
                      marginTop: "2%",
                    }}
                  >
                    <Buttons
                      onClick={() => {
                        this.validaciones();
                      }}
                      style={{
                        cursor: "pointer",
                        marginLeft: "2%",
                        marginTop: "2%",
                        fontSize: "16px",
                        float: "left",
                        border: "0",
                        textAlign: "center",
                        height: "40px",
                        width: "150px",
                        backgroundColor: "#00ac77",
                      }}
                    >
                      Aceptar
                    </Buttons>
                    <Buttons
                      onClick={this.salir}
                      className={"btnOpcion fondoNaranjo"}
                      style={{
                        cursor: "pointer",
                        marginLeft: "2%",
                        marginTop: "2%",
                        fontSize: "16px",
                        float: "left",
                        border: "0",
                        textAlign: "center",
                        height: "40px",
                        width: "150px",
                      }}
                    >
                      Cancelar
                    </Buttons>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
