import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";

// core components
import { store } from "./../../store/index";

import DemAsignados from "./GestionDemandaAsignados";
import DemIngreso from "./GestionDemandaIngreso";
import DemFinalizados from "./GestionDemandaFinalizados";
import DemDerivados from "./GestionDemandaDerivados";

import vista_ingreso from "assets/img/gestion/vista_ingreso.svg";
import vista_tomados from "assets/img/gestion/vista_tomados.svg";
import vista_derivado from "assets/img/gestion/vista_derivar.svg";
import vista_finalizados from "assets/img/gestion/vista_finalizar.svg";
import vista_ingreso_activa from "assets/img/gestion/vista_ingreso_activa.svg";
import vista_derivado_activa from "assets/img/gestion/vista_derivar_activa.svg";
import vista_tomados_activa from "assets/img/gestion/vista_tomados_activa.svg";
import vista_finalizados_activa from "assets/img/gestion/vista_finalizar_activa.svg";

import Tooltip from "@material-ui/core/Tooltip";

import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";

import BuscarUsuarioGestion from "components/Demanda/BuscarUsuarioGestion";
import AgregarCaso from "components/Demanda/AgregarCaso.js";

import signo from "assets/img/signo.svg";
import "assets/css/inicio.css";

export default class GestionDemanda extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alerta: false,
      secId: 0,
      total: 0,
      demanda: [],
      preguntas: false,
      especialidad: null,
      gedId: null,
      modalAgregarCaso: false,
      demandaIngreso: true,
      demandaAsignados: false,
      demandaDerivados: false,
      demandaFinalizados: false,
      modalBuscarUsuarioGestion: false,
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      rutFiltro: null,
      especialidades: [],
      herramientas: store.getState().herramientas,
    };
  }

  componentDidMount() {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Gestión nivel 3 <span title='Permite gestionar los casos que por prioridad caen en la gestión manual'><img src=" + signo + "></img></span>";

    if (this.props.history.location.state != undefined) {
      this.setState({ rutFiltro: this.props.history.location.state.rut }, () => {
        this.handleChange(2);
      });
    } else {
      this.traerEspecialidades();
    }
  }

  traerEspecialidades() {
    axios.get(global.config.modulos.especialidades + "especialidades_entregan_horas/", { headers: authHeader(), params: { cesfam: this.state.cesfam } }).then((res) => {
      if (res.data.estado) {
        this.setState({
          especialidades: res.data.datos,
        });
      }
    });
  }

  mostrarModalBuscarUsuarioGestion = () => {
    this.setState({
      modalBuscarUsuarioGestion: true,
    });
  };

  ocultarModalBuscarUsuarioGestion = () => {
    this.setState({
      modalBuscarUsuarioGestion: false,
    });
  };

  mostrarModalAgregarCaso = () => {
    this.setState({
      modalAgregarCaso: true,
    });
  };

  ocultarModalAgregarCaso = (posicion = false) => {
    this.setState({
      modalAgregarCaso: false,
    });

    if (posicion) {
      this.handleChange(posicion);
    }
  };

  activarVista = (vistaActivada, rut = null) => {
    console.log(vistaActivada, rut);
    this.setState(
      {
        rutFiltro: rut,
        demandaIngreso: false,
        demandaDerivados: false,
        demandaAsignados: false,
        demandaFinalizados: false,
      },
      () => {
        this.setState({ [vistaActivada]: true });
      }
    );
  };

  desactivarFiltros = () => {
    this.setState({ rutFiltro: null });
  };

  handleChange = (posicion, rutFiltro = null) => {
    console.log(posicion, rutFiltro);
    if (rutFiltro) {
      this.setState({ rutFiltro: rutFiltro });
    }
    if (posicion == 1) {
      this.activarVista("demandaIngreso", rutFiltro);
    }
    if (posicion == 2) {
      this.activarVista("demandaAsignados", rutFiltro);
    }
    if (posicion == 3) {
      this.activarVista("demandaFinalizados", rutFiltro);
    }
    if (posicion == 4) {
      this.activarVista("demandaDerivados", rutFiltro);
    }
  };

  render() {
    let botonDemandaIngreso = this.state.demandaIngreso ? { marginLeft: 20, width: 70, marginRight: 0, marginTop: "-4px" } : { marginLeft: 20, width: 40, marginRight: 0 };
    let botonDemandaAsignados = this.state.demandaAsignados ? { marginLeft: 20, width: 70, marginRight: 0, marginTop: "-5px" } : { marginLeft: 20, width: 40, marginRight: 0 };
    let botonDemandaFinalizados = this.state.demandaFinalizados ? { marginLeft: 20, width: 70, marginRight: 0, marginTop: "-5px" } : { marginLeft: 20, width: 40, marginRight: 0 };
    let botonDemandaDerivados = this.state.demandaDerivados ? { marginLeft: 20, width: 70, marginRight: 0, marginTop: "-5px" } : { marginLeft: 20, width: 40, marginRight: 0 };
    return (
      <div className="middle-area" style={{ width: "85%" }}>
        <GridContainer>
          <GridItem xs={12} style={{ marginTop: 30 }}>
            <Tooltip title="Casos ingresados" aria-label="Casos ingresados">
              <img style={botonDemandaIngreso} className="imagenBotonAccionMenu" src={this.state.demandaIngreso ? vista_ingreso_activa : vista_ingreso} onClick={() => this.handleChange(1)} />
            </Tooltip>
            <Tooltip title="Casos en proceso" aria-label="Casos en proceso">
              <img style={botonDemandaAsignados} className="imagenBotonAccionMenu" src={this.state.demandaAsignados ? vista_tomados_activa : vista_tomados} onClick={() => this.handleChange(2)} />
            </Tooltip>

            {this.state.herramientas.includes("H69") && (
              <Tooltip title="Casos derivados" aria-label="Casos derivados">
                <img style={botonDemandaDerivados} className="imagenBotonAccionMenu" src={this.state.demandaDerivados ? vista_derivado_activa : vista_derivado} onClick={() => this.handleChange(4)} />
              </Tooltip>
            )}

            {this.state.herramientas.includes("H70") && (
              <Tooltip title="Casos finalizados" aria-label="Casos finalizados">
                <img style={botonDemandaFinalizados} className="imagenBotonAccionMenu" src={this.state.demandaFinalizados ? vista_finalizados_activa : vista_finalizados} onClick={() => this.handleChange(3)} />
              </Tooltip>
            )}

            {this.state.especialidades.length > 0 ? (
              <a onClick={this.mostrarModalAgregarCaso} className={"btnAgregarCaso fondoVerde"} style={{ cursor: "pointer" }}>
                Agregar caso
              </a>
            ) : null}

            <a onClick={this.mostrarModalBuscarUsuarioGestion} className={"btnAgregarCaso fondoNaranjo"} style={{ cursor: "pointer", marginRight: 10 }}>
              Buscar Usuario
            </a>
          </GridItem>
        </GridContainer>
        <div className="middle-content">
          <GridContainer>
            <GridItem xs={12} style={{ marginTop: 30, marginLeft: "0px", overflow: "scroll" }}>
              {this.state.demandaIngreso ? <DemIngreso activarVista={this.activarVista} rutFiltro={this.state.rutFiltro} desactivarFiltros={this.desactivarFiltros}></DemIngreso> : null}
              {this.state.demandaAsignados ? <DemAsignados activarVista={this.activarVista} rutFiltro={this.state.rutFiltro} desactivarFiltros={this.desactivarFiltros}></DemAsignados> : null}
              {this.state.demandaDerivados ? <DemDerivados activarVista={this.activarVista} rutFiltro={this.state.rutFiltro} desactivarFiltros={this.desactivarFiltros}></DemDerivados> : null}
              {this.state.demandaFinalizados ? <DemFinalizados activarVista={this.activarVista} rutFiltro={this.state.rutFiltro} desactivarFiltros={this.desactivarFiltros}></DemFinalizados> : null}
              {this.state.modalAgregarCaso ? <AgregarCaso ocultar={this.ocultarModalAgregarCaso} activarVista={this.activarVista}></AgregarCaso> : null}
              {this.state.modalBuscarUsuarioGestion ? <BuscarUsuarioGestion ocultar={this.ocultarModalBuscarUsuarioGestion} activarVista={this.handleChange}></BuscarUsuarioGestion> : null}
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
