import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "components/grid/GridItem.js";
import BeneficioDesinscribir from "./BeneficioDesinscribirPorSesiones";
import BeneficioInscribir from "./BeneficioInscribirPorSesiones";
import { store } from "./../../store/index";

import Button from "@material-ui/core/Button";

// estilos
//import "../../assets/css/agregar.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import beneCss from "assets/css/beneficio.module.css";

export default class BeneficioSesiones extends Component {
  constructor(props) {
    super(props);

    this.state = {
      beneficios: [],
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      usuId: props.usuId,
      datosBeneficio: props.beneficio,
      formErrors: {},
      modalBeneficioInscribir: false,
      modalBeneficioDesinscribir: false,
      beneficio: null,
      nombreBeneficio: null,
      open: true,
      herramientas: store.getState().herramientas,
      especialidades: "",
      nombreProfesional: "",
      idPrioridad: null,
    };
  }

  componentDidMount() {}

  traeBeneficios = () => {
    this.props.traeBeneficios();
  };

  mostrarBeneficioInscribir = (beneficio, nombre, especialidad) => {
    this.setState({
      modalBeneficioInscribir: true,
      beneficio: beneficio,
      nombreBeneficio: nombre,
      especialidades: especialidad,
    });
  };

  ocultarBeneficioInscribir = () => {
    this.setState({ modalBeneficioInscribir: false });
    this.traeBeneficios();
  };

  mostrarBeneficioDesinscribir = (beneficio, nombre) => {
    this.setState({
      modalBeneficioDesinscribir: true,
      beneficio: beneficio,
      nombreBeneficio: nombre,
    });
  };

  ocultarBeneficioDesinscribir = () => {
    this.setState({ modalBeneficioDesinscribir: false });
    this.traeBeneficios();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  agendarHoras = () => {
    window.location.href = "/horarios_esp";
  };

  render() {
    const beneficio = this.state.datosBeneficio;

    return (
      <React.Fragment>
        <GridItem xs={6} style={{ marginTop: 30 }}>
          <div className={"bordeTabla cardBeneficio"}>
            <h3 className="alignCenter">{beneficio.ben_nombre}</h3>
            <div className="cardDivBeneficio">
              <p className="parrafoBeneficio">
                <label className="labelBeneficio">Estado: </label> {beneficio.estadoUsuario ? "Activo" : "Inactivo"}
              </p>
              <p className="parrafoBeneficio">
                <label className="labelBeneficio">Fecha de ingreso: </label> {beneficio.estadoUsuario ? beneficio.usuario.usb_fecha_inicio : "--"}
              </p>
              <p className="parrafoBeneficio">
                <label className="labelBeneficio">Total sesiones: </label> {beneficio.sesiones}
              </p>
              <p className="parrafoBeneficio">
                <label className="labelBeneficio">Frecuencia: </label> {beneficio.frecuencia}
              </p>

              <p className="parrafoBeneficio">
                <label className="labelBeneficio">Asistidas: </label> {beneficio.asistidas}
              </p>

              {beneficio.estadoUsuario && (
                <p className="parrafoBeneficio">
                  <Button onClick={() => this.agendarHoras()} className="fondoRojo" style={{ color: "#fff" }}>
                    Reservar horas
                  </Button>
                </p>
              )}

              {beneficio.estadoUsuario ? (
                <p className="alignCenter" style={{ marginTop: 20 }}>
                  {this.state.herramientas.includes("H15") ? (
                    <a onClick={() => this.mostrarBeneficioDesinscribir(beneficio.ben_id, beneficio.ben_nombre)} className="botonDesinscribirBeneficio">
                      Desinscribir
                    </a>
                  ) : null}
                </p>
              ) : (
                <p className="alignCenter" style={{ marginTop: 20 }}>
                  {this.state.herramientas.includes("H15") ? (
                    <a onClick={() => this.mostrarBeneficioInscribir(beneficio.ben_id, beneficio.ben_nombre, beneficio.ben_especialidades)} className="botonInscribirBeneficio">
                      Inscribir
                    </a>
                  ) : null}
                </p>
              )}
            </div>
          </div>
        </GridItem>
        {this.state.modalBeneficioInscribir ? <BeneficioInscribir ocultar={this.ocultarBeneficioInscribir} usuario={this.state.usuId} beneficio={this.state.beneficio} especialidades={this.state.especialidades} nombreBeneficio={this.state.datosBeneficio.ben_nombre}></BeneficioInscribir> : null}
        {this.state.modalBeneficioDesinscribir ? <BeneficioDesinscribir ocultar={this.ocultarBeneficioDesinscribir} usuario={this.state.usuId} beneficio={this.state.beneficio} idPrioridad={this.state.idPrioridad} nombreBeneficio={this.state.datosBeneficio.ben_nombre}></BeneficioDesinscribir> : null}
      </React.Fragment>
    );
  }
}
