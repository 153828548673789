import React, { useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import axios from "axios";
import TableContainer from "@material-ui/core/TableContainer";
import { useHistory } from "react-router-dom";
import icono_ir from "../../assets/img/gestion/ir.svg";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";

// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

const BuscarUsuarioGestion = (props) => {
  const history = useHistory();
  const [open, setOpen] = useState(true);
  const [usuarios, setUsuarios] = useState([]);

  const redirigirGestion = (vista, rut) => {
    /*
    let url = "/gestionReservaExterna";
    history.push(url, {
      ubicacion: ubicacion,
      rutFiltro: rut,
    });
    */
    console.log("---", vista, rut);
    props.cambiarVentana(vista, rut);

    props.ocultar();
  };

  const buscarUsuarios = (e) => {
    const { name, value } = e.target;
    const nombre = value;

    var data = {
      nombre: nombre,
    };

    let apiUrl = global.config.modulos.reservas_externas + "buscar_usuario/";

    const options = {
      method: "GET",
      params: data,
      headers: authHeader(),
    };

    if (nombre.length >= 4) {
      axios(apiUrl, options).then((res) => {
        let datos = [];
        if (res.data.datos) {
          datos = res.data.datos;
        }
        setUsuarios(datos);
      });
    } else if (nombre == "") {
      setUsuarios([]);
    }
  };

  return (
    <Dialog open={open} onClose={props.ocultar} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="buscarUsuarioGestion">
      <DialogContent>
        <GridContainer className="agendar-container">
          <GridItem xs={12} sm={12} md={12} className="">
            <GridContainer className="agendar-container">
              <GridItem xs={12}>
                <h2>Buscar usuario</h2>
              </GridItem>
              <GridItem xs={12} style={{ marginTop: 30 }}>
                <p>Escriba el rut o el nombre del paciente a buscar</p>
                <p>
                  <input type="text" className="input-buscar-usuario" onChange={(e) => buscarUsuarios(e)} />
                </p>
                <TableContainer style={{ marginTop: 30, height: 500 }}>
                  <Table aria-label="simple table" className="tablaGeneralGestion bordeTabla">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Fecha de ingreso</TableCell>
                        <TableCell align="center">Ubicación</TableCell>
                        <TableCell align="center">Nombre</TableCell>
                        <TableCell align="center">Rut</TableCell>
                        <TableCell align="center">Tomado por</TableCell>
                        <TableCell align="center">Fecha de finalización</TableCell>
                        <TableCell align="center">Ir</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {usuarios.map((res, index) => (
                        <TableRow index={index}>
                          <TableCell align="center">{res.fecha_ingreso ? res.fecha_ingreso.replace("T", " ") : null}</TableCell>
                          <TableCell align="center">{res.ubicacion}</TableCell>
                          <TableCell align="center">{res.nombre_usuario}</TableCell>
                          <TableCell align="center">{res.usu_rut}</TableCell>
                          <TableCell align="center">{res.usuario_panel}</TableCell>
                          <TableCell align="center">{res.fecha_termino ? res.fecha_termino.replace("T", " ") : null}</TableCell>
                          <TableCell align="center">
                            <Tooltip title="Ir al caso" aria-label="Ir al caso">
                              <a style={{ cursor: "pointer" }} onClick={() => redirigirGestion(res.vista, res.usu_rut)}>
                                <img style={{ width: 30 }} src={icono_ir} />
                              </a>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </GridItem>

              <GridItem xs={12} style={{ marginTop: 20 }}>
                <Button id={"modalModificarUsuarioBtnCancelar"} onClick={props.ocultar} style={{ marginLeft: 20, float: "right", color: "#fff" }} color="primary" className="fondoRojo">
                  Cerrar
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
};

export default BuscarUsuarioGestion;
