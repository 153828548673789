import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";
import { store } from "../../store/index";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

// estilos
//import "../../assets/css/agregar.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class DetalleContacto extends Component {
  constructor(props) {
    super(props);

    this.state = {
      motivo: "",
      uspid: store.getState().usp,
      usuario_reparto: props.usuario_reparto,
      intentosContacto: [],
      open: true,
    };
  }

  componentDidMount() {
    this.traerDetalle();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  traerDetalle = () => {
    axios
      .get(global.config.modulos.repartos + "detalle_contacto/", {
        headers: authHeader(),
        params: {
          usuario_reparto: this.state.usuario_reparto,
        },
      })
      .then((res) => {
        var data = res.data;

        var estado = data.estado;
        if (estado) {
          var contactoDetalle = [];

          var contactos = data.datos;
          var conteoContactos = contactos.length;
          for (var j = 0; j < conteoContactos; j++) {
            var fechaEnvio = moment(contactos[j].rud_fecha_envio);
            fechaEnvio = fechaEnvio.format("DD-MM-YYYY HH:mm:ss");

            var canal = contactos[j].canal;

            var telefono = contactos[j].rud_telefono;

            var estado = "";
            var contesta = contactos[j].rud_contesta;
            var confirma = contactos[j].rud_confirma;

            if (contesta == 0 && confirma == 0) {
              estado = "No ha contestado";
            } else if (contesta == 1 && confirma == 0) {
              estado = "Contesta pero no confirma";
            } else if (contesta == 1 && confirma == 1) {
              estado = "Confirma visita";
            } else if (contesta == 1 && confirma == 2) {
              estado = "Indica que no puede recibir la visita";
            }

            var cont = {
              fechaEnvio: fechaEnvio,
              canal: canal,
              telefono: telefono,
              estado: estado,
            };
            contactoDetalle.push(cont);
          }
          this.setState({ intentosContacto: contactoDetalle });
        }
      });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="detalleHorasBloqueadas">
          <DialogContent>
            <h2>Detalle contacto</h2>
            {this.state.intentosContacto.length > 0 ? (
              <Table className="bordeTabla" aria-label="simple table " stickyHeader style={{ marginTop: 20 }}>
                <TableHead>
                  <TableRow>
                    <TableCell className="cellInicio" align="center">
                      Fecha contacto
                    </TableCell>
                    <TableCell align="center">Canal</TableCell>
                    <TableCell align="center">Teléfono</TableCell>
                    <TableCell className="cellTermino" align="center">
                      Acción
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.intentosContacto.map((int, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{int["fechaEnvio"]}</TableCell>
                      <TableCell align="center">{int["canal"]}</TableCell>
                      <TableCell align="center">{int["telefono"] ? int["telefono"] : "--"}</TableCell>

                      <TableCell align="center">{int["estado"]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <React.Fragment>
                <br></br>
                <Grid item xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
                  <label style={{ fontSize: "2vw" }}>{"No se ha contactado al paciente"}</label>
                </Grid>
              </React.Fragment>
            )}
          </DialogContent>
          <DialogActions>
            <Button className="botonDialog fondoRojo" onClick={this.salir}>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
