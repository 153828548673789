import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";
// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class ModificarComentario extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      comid: props.comId,
      comentario: props.comentario,
      usuId: props.usuId,
      comentarios: [],
      formErrors: {},
      uspId: parseInt(store.getState().usp),
      open: true,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  validaciones() {
    const { comentario } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (!comentario) {
      formIsValid = false;
      formErrors["comentarioErr"] = "Escribe el comentario.";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  enviarFormulario = (e) => {
    if (this.validaciones()) {
      var data = {
        com_comentario: this.state.comentario,
        usu_id: this.state.usuId,
        usp_id: this.state.uspId,
      };
      let apiUrl = global.config.modulos.comentarios + "comentario_por_id/" + this.state.comid;
      const options = {
        method: "PATCH",
        data: data,
        headers: authHeader(),
      };
      axios(apiUrl, options).then((res) => {
        NotificationManager.success("Comentario modificado con éxito");
        this.salir();
      });
    }
  };

  render() {
    const { comentarioErr } = this.state.formErrors;
    const { comentarios } = this.state;
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="comentarios">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Comentarios</h2>
                </GridItem>

                <GridItem id="modalComentarioComentario" xs={12} style={{ marginTop: 30 }}>
                  <TextField id="standard-basic" label="Comentario" value={this.state.comentario} style={{ with: "100%" }} type="text" name="comentario" onChange={this.cambiarValores} className={comentarioErr ? "inputText showError" : "inputText"} />
                  {comentarioErr && <div style={{ color: "red", paddingBottom: 10 }}>{comentarioErr}</div>}
                </GridItem>

                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <Button onClick={this.salir} className="botonSimple floatRigth fondoRojo" style={{ marginLeft: 20 }}>
                    Salir
                  </Button>
                  <Button onClick={this.enviarFormulario} className="botonSimple fondoVerde" id="btnAgregar" style={{ float: "right" }}>
                    Modificar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
