import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import GridItem from "../../components/grid/GridItem.js";
import { store } from "../../store/index";
import GridContainer from "../../components/grid/GridContainer.js";
import { Button, Dialog, DialogContent } from "@material-ui/core/";
import "react-datepicker/dist/react-datepicker.css";
import ModalNSPDetalles from "./ModalNSPDetalles.js";
import axios from "axios";

export default class ModalNSP extends Component {
  constructor(props) {
    super(props);
    //estado inicial
    this.state = {
      open: true,
      especialidad: "",
      contador: 0,
      cesfam: store.getState().cesfam,
      showModalNSPDetalles: false,
      especialidad_id: props.especialidad_id,
      especialidad: "",
      datos_detalle: [],
      id_usuario: props.id_usuario,
      startDate: props.startDate,
      endDate: props.endDate,
      especialidad_id: props.especialidad_id,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };
  componentDidMount() {
    this.nspcount();
  }

  nspcount = () => {
    axios
      .get(global.config.modulos.reportes + "listado_NSP/especialidadnsp/", {
        headers: authHeader(),
        params: {
          usuario_id: this.state.id_usuario,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        this.setState({
          datos_detalle: res.data.datos,
        });
      });
  };
  showModalNSPDetalles = (usuario_id, especialidad_id) => {
    this.setState({
      showModalNSPDetalles: true,
      especialidad_id: especialidad_id,
      usuario_id: usuario_id,
    });
  };

  hideModalNSPDetalles = () => {
    this.setState({ showModalNSPDetalles: false });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="beneficioUsuario">
          <DialogContent className="form-modal">
            <GridContainer className="agendar-container">
              <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
                <img alt="" class="marca-agua"></img>
                <GridContainer className="agendar-container">
                  <GridItem xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
                    <div>
                      <h2>Total NSP por especialidad</h2>
                    </div>
                  </GridItem>
                  <div>
                    {this.state.datos_detalle.length > 0 ? (
                      <div className="flex-container">
                        {this.state.datos_detalle.map((esp, index) => (
                          <div key={index}>
                            <div className="modal-ver-mas-contador" key={esp.age_usuario} onClick={() => this.showModalNSPDetalles(esp.age_usuario, esp.age_especialidad)}>
                              {esp.veces}
                            </div>
                            <div className="modal-ver-mas-nombre">{esp.especialidad}</div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>No hay informacion</div>
                    )}
                  </div>
                  <GridItem xs={12} style={{ marginTop: 30 }}>
                    <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={this.salir} style={{ marginLeft: 20 }}>
                      Cerrar
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
        {this.state.showModalNSPDetalles ? <ModalNSPDetalles ocultar={this.hideModalNSPDetalles} especialidad_id={this.state.especialidad_id} usuario_id={this.state.usuario_id} fecha_inicio={this.state.startDate} fecha_termino={this.state.endDate}></ModalNSPDetalles> : null}
      </div>
    );
  }
}
