import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { NotificationManager } from "react-notifications";
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

export default class TablaSexo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dates: [],
      datas: [],
      fecha_actual: props.fecha_actual,
      fecha_inicio: props.fecha_inicio,
      fecha_termino: props.fecha_termino,
      edad_max: props.edad_max,
      edad_min: props.edad_min,
      stc_nombre: props.stc_nombre,
      ces_id: props.ces_id,
      sec: props.sec,
      root: "",
      id_com: "",
    };
  }

  cesXsec() {
    this.setState({ datas: [] });
    console.log("hoal");
    axios
      .get(global.config.modulos.grafico_demanda + "agendadasXcomuna/", {
        headers: authHeader(),
        params: {
          fecha_act: this.state.fecha_actual,
          fecha_ini: this.state.fecha_inicio,
          fecha_ter: this.state.fecha_termino,
          stc_nombre: this.state.stc_nombre,
          edad_max: this.state.edad_max,
          edad_min: this.state.edad_min,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No hay datos");
          return;
        }
        this.setState({ datas: res.data.datos }, () => {
          console.log(this.state.datas);
          this.setState({ sec: this.state.datas[0].sec });
          try {
            this.state.root.dispose();
          } catch (e) {}

          let root = am5.Root.new("chartdiv10");
          let myTheme = am5.Theme.new(root);

          myTheme.rule("Grid", ["base"]).setAll({
            strokeOpacity: 0.1,
          });

          root.setThemes([am5themes_Animated.new(root), myTheme]);

          let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
              panX: false,
              panY: false,
              wheelX: "none",
              wheelY: "none",
            })
          );

          let yRenderer = am5xy.AxisRendererY.new(root, {
            minGridDistance: 30,
          });
          yRenderer.grid.template.set("location", 1);

          let yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
              maxDeviation: 0,
              categoryField: "category",
              renderer: yRenderer,
            })
          );

          let xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
              maxDeviation: 0,
              min: 0,
              renderer: am5xy.AxisRendererX.new(root, {
                visible: true,
                strokeOpacity: 0.1,
              }),
            })
          );

          let series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
              name: "Series 1",
              xAxis: xAxis,
              yAxis: yAxis,
              valueXField: "value",
              sequencedInterpolation: true,
              categoryYField: "category",
            })
          );
          series.bullets.push(function () {
            return am5.Bullet.new(root, {
              locationX: 1,
              sprite: am5.Label.new(root, {
                centerY: am5.p50,
                centerX: am5.p100,
                fill: root.interfaceColors.get("alternativeText"),
                text: "{value}",
                populateText: true,
              }),
            });
          });

          let columnTemplate = series.columns.template;

          columnTemplate.setAll({
            cursorOverStyle: "pointer",
            tooltipText: "{category}: {value}",
            cornerRadiusBR: 10,
            cornerRadiusTR: 10,
            strokeOpacity: 0,
          });
          columnTemplate.adapters.add("fill", (fill, target) => {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
          });

          columnTemplate.adapters.add("stroke", (stroke, target) => {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
          });

          let thiss = this;

          columnTemplate.events.on("click", function (ev) {
            if (thiss.state.stc_nombre == "Norte") {
              thiss.setState({ ces_id: ev.target.dataItem.dataContext.id }, () => {
                thiss.props.mostrarGraficoCanal(thiss.state.ces_id);
              });
            } else {
              thiss.setState({ id_com: ev.target.dataItem.dataContext.id }, () => {
                thiss.props.mostrarS_C(thiss.state.id_com);
              });
            }
          });

          let data = this.state.datas;

          yAxis.data.setAll(data);
          series.data.setAll(data);

          series.appear(1000);
          chart.appear(1000, 100);
        });
      });
  }

  componentDidMount() {
    this.cesXsec();
  }

  render() {
    return (
      <div
        id="tablas"
        style={{
          width: "40%",
          overflowY: "scroll",
          overflowX: "scroll",
          height: "70%",
          zIndex: 1,
          position: "fixed",
          textAlign: "center",
        }}
      >
        <h1 style={{ marginTop: "20px" }}>horas agendadas por {this.state.sec}</h1>

        <div
          id="chartdiv10"
          style={{
            display: "flex",
            width: "100%",
            height: "80%",
            zIndex: 1,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        ></div>
      </div>
    );
  }
}
