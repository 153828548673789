import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";

import { store } from "store/index";
import { Grid, List, ListItem } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert";
import { socketTotem } from "../../../service/socket";

import GridContainer from "components/grid/GridContainer.js";
import GridItem from "components/grid/GridItem.js";

import Cargando from "components/Helpers/Cargando.js";

// CSS
import "react-confirm-alert/src/react-confirm-alert.css";

export default class AccionesFuncionario extends Component {
  constructor(props) {
    super(props);
    this.state = { cargando: false, totem: store.getState().totem };
  }

  componentDidMount() {}

  mostrarAlerta = (mensaje) => {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  };

  refreshTotem = () => {
    this.mostrarAlerta("Tótem recargado con éxito");

    socketTotem.emit("reiniciar_pantalla", {
      totem: this.state.totem,
    });

    /*  socketTotem.emit("repetir_numero", {
      totem: this.state.totem,
    });
 */
    /* socketTotem.emit("reiniciar_totem", {
      totem: this.state.totem,
    }); */
  };

  reestablecerTotem = () => {
    var apiUrl = global.config.modulos.admin_totem + "restablecer/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          totem: this.state.totem,
        },
      })
      .then((res) => {
        this.refreshTotem();
        this.mostrarAlerta("Tótem reestablecido con éxito");
      });
  };

  transferirDatos = () => {
    this.setState({ cargando: true });
    var apiUrl = global.config.modulos.admin_totem + "traspaso_datos/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          totem: this.state.totem,
        },
      })
      .then((res) => {
        this.setState({ cargando: false });
        this.mostrarAlerta("Datos transferidos con éxito");
      });
  };

  render() {
    return (
      <GridContainer style={{ width: "100%", marginTop: "10px" }}>
        <GridItem xs={12} sm={12} md={12}>
          <Grid container spacing={3} style={{ marginTop: "8vw", textAlign: "center" }}>
            <Grid item xs={12} sm={12}>
              <a
                className={"btnsAccionesPanel"}
                onClick={() => {
                  this.refreshTotem();
                }}
              >
                {"Refresh tótem"}
              </a>
            </Grid>
            {this.state.cargando ? <Cargando></Cargando> : <></>}
          </Grid>
        </GridItem>
      </GridContainer>
    );
  }
}
