import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import TextField from "@material-ui/core/TextField";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert"; // Import

// estilos
//import "../../assets/css/agregar.css";

export default class Observaciones extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      idRegistro: props.idRegistro,
      observaciones: [],
      observacion: "",
      uspTomado: props.uspTomado,
      open: true,
    };
  }

  componentDidMount() {
    this.traerObservaciones();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  traerObservaciones = () => {
    axios
      .get(global.config.modulos.reservas_externas + "observaciones/", {
        headers: authHeader(),
        params: {
          gestion: this.state.idRegistro,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ observaciones: res.data.datos });
        }
      });
  };

  agregarObservacion = () => {
    confirmAlert({
      title: "",
      message: "¿Esta segur@ que desea guardar esta observación?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => {
            axios
              .post(
                global.config.modulos.reservas_externas + "agregar_observacion/",
                {
                  gestion: this.state.idRegistro,
                  usp: this.state.uspId,
                  observacion: this.state.observacion,
                },
                {
                  headers: authHeader(),
                }
              )
              .then((res) => {
                if (res.data.estado) {
                  NotificationManager.success("Observación agregada con éxito");
                  this.traerObservaciones();
                }
              });
          },
          className: "btnAceptar",
        },
      ],
    });
  };

  cambiarValor = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { observaciones, uspTomado, uspId } = this.state;
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="recordatoriosExternosObservaciones">
          <DialogContent>
            <h2>Observaciones</h2>
            {uspTomado == uspId ? (
              <GridContainer className="agendar-container">
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <TextField id="observacion" name="observacion" label="Agregar observación" multiline rows={4} value={this.state.observacion} style={{ width: "100%" }} variant="outlined" onChange={this.cambiarValor} />
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: 20, color: "white", float: "right" }} color="primary" className="fondoRojo">
                    Cancelar
                  </Button>
                  <Button onClick={this.agregarObservacion} style={{ color: "white", float: "right" }} color="primary" className="fondoVerde">
                    Agregar
                  </Button>
                </GridItem>
              </GridContainer>
            ) : null}
            <Table className="bordeTabla" aria-label="simple table " stickyHeader style={{ marginTop: 20 }}>
              <TableHead>
                <TableRow>
                  <TableCell className="cellInicio" align="center">
                    Fecha
                  </TableCell>
                  <TableCell align="center">Funcionario</TableCell>
                  <TableCell align="center">Observación</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {observaciones.map((observacion, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{observacion.gro_fecha_registro.replace("T", " ")}</TableCell>
                    <TableCell align="center">{observacion.usuario_panel}</TableCell>
                    <TableCell align="center">{observacion.gro_observacion}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
