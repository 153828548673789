import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "./../../store";
import { confirmAlert } from "react-confirm-alert";
import { NotificationManager } from "react-notifications";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class FinalizarCaso extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,

      usuId: props.usuId,
      idDemanda: props.idDemanda,

      motivoFin: "",
      open: true,
      opciones: [],
      formErrors: {},
    };
  }

  componentWillMount() {
    this.traerMotivos();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  validaciones() {
    const { motivoFin } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (motivoFin == "" || motivoFin == null) {
      formIsValid = false;
      formErrors["motivoFinErr"] = "Selecciona el motivo";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  finalizarCaso = () => {
    if (!this.validaciones()) {
      return;
    }

    let opcion = this.state.motivoFin.split(";");
    if (opcion[0] == 2) {
      confirmAlert({
        title: "",
        message: "Seguro que desea descartar este caso con motivo 'FALLECIMIENTO'? el paciente será eliminado de HoraSalud",
        buttons: [
          {
            label: "Cancelar",
            className: "btnCancelar",
          },
          {
            label: "Aceptar",
            className: "btnAceptar",
            onClick: () => {
              var data = {
                usu_estado: 2,
                usu_motivo_eliminacion: 5,
                usp_id: this.state.uspId,
              };

              let apiUrl = global.config.modulos.usuarios + "por_id/" + this.state.usuId;

              const options = {
                method: "DELETE",
                data: data,
                headers: authHeader(),
              };

              axios(apiUrl, options).then((res) => {
                this.registrarCasoFinalizado();
              });
            },
          },
        ],
      });
    } else {
      this.registrarCasoFinalizado();
    }
  };

  registrarCasoFinalizado = () => {
    let opcion = this.state.motivoFin.split(";");
    axios
      .patch(
        global.config.modulos.demanda + "finalizar_caso/",
        {
          id: this.state.idDemanda,
          gestion_funcionario: 3,
          motivo_fin: opcion[1],
          usp: this.state.uspId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        NotificationManager.success("Caso finalizado con éxito!");
        this.props.activarVista("demandaFinalizados");
        this.salir();
      });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  traerMotivos = () => {
    console.log(this.state.cesfam);
    axios
      .get(global.config.modulos.demanda + "traer_motivos_finalizacion/", {
        headers: authHeader(),
        params: { cesfam: this.state.cesfam },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ opciones: res.data.datos });
        }
      });
  };

  render() {
    const { motivoFinErr } = this.state.formErrors;

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" id="finalizarDemanda">
        <DialogContent>
          <div>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <h2>Finalizar caso</h2>
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
                <FormControl fullWidth>
                  <InputLabel id="label-sector">Motivo</InputLabel>
                  <Select labelId="label-sector" id="motivoFin" name="motivoFin" value={this.state.motivoFin || ""} fullWidth onChange={this.handleChange}>
                    {this.state.opciones.map((e, index) => (
                      <MenuItem key={index} value={e.dmf_tipo + ";" + e.dmf_motivo}>
                        {e.dmf_motivo}
                      </MenuItem>
                    ))}
                  </Select>
                  {motivoFinErr && <div style={{ color: "red", paddingBottom: 10 }}>{motivoFinErr}</div>}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
                <Button style={{ float: "right", marginLeft: 10 }} onClick={this.salir} className="botonSimple fondoRojo">
                  Cerrar
                </Button>
                <Button onClick={this.finalizarCaso} className="botonSimple fondoVerde" style={{ float: "right" }}>
                  Finalizar
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
