import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "./../../store/index";

import chileanRut from "chilean-rut";
import axios from "axios";

// core components

import { confirmAlert } from "react-confirm-alert"; // Import
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import { Input } from "@material-ui/core";

import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";
import Buttons from "react-multi-date-picker/components/button";
import MaterialTable from "material-table";

import AlertaReingreso from "./AlertaReingreso";

import "assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class UsuarioEnEspera extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      herramientas: store.getState().herramientas,
      cliente: store.getState().cliente,

      open: true,

      observaciones: [],
      cesfams: [],
      usuId: props.usuId,
      rut: "",

      ingreso: null,
      codigo: null,
      fecha_registro: null,

      nombres: null,
      edad: null,
      sexo: null,
      fecha_nacimiento: null,
      nombre_cesfam: null,
      telefono1: null,
      telefono2: null,
      loi_id: null,

      estado_tratamiento: false,
      estado_alta: false,
      observacion_libre: null,
      observacionesLibres: [],
      estado_observado: false,
      nombre_observacion_fija: null,

      estado_boton: false,
      estado_citado: false,
      tratamiento: null,
    };
  }
  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  componentDidMount() {
    this.obtenerUsuario();
  }

  obtenerUsuario = async () => {
    await axios
      .get(global.config.modulos.listaEspera + "usuarioEnEspera/", {
        headers: authHeader(),
        params: {
          usuId: this.state.usuId,
          cesfam: store.getState().cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            ingreso: res.data.datos["ingreso"],
            codigo: res.data.datos["loi_fila_b1"],
            fecha_registro: res.data.datos["loi_fecharegistro"],
            rut: res.data.datos["loi_rut"],
            nombres: res.data.datos["loi_nombre"],
            edad: res.data.datos["edad"],
            sexo: res.data.datos["loi_sexo"],
            fecha_nacimiento: res.data.datos["loi_fecha_nacimiento"],
            nombre_cesfam: res.data.datos["cesfam_nombre"],
            telefono1: res.data.datos["loi_telefono1"],
            telefono2: res.data.datos["loi_telefono2"],
            estado_tratamiento: res.data.datos["estado_tratamiento"],
            estado_citado: res.data.datos["estado_citado"],
            estado_alta: res.data.datos["estado_alta"],
            estado_observado: res.data.datos["estado_observado"],
            fecha_tratamiento: res.data.datos["loi_fecha_tratamiento"],
            fecha_alta: res.data.datos["loi_fecha_alta"],
            nombre_tratamiendo: res.data.datos["nombre_tratamiento"],
            nombre_alta: res.data.datos["nombre_alta"],
            loi_id: res.data.datos["loi_id"],
            nombre_observacion_fija: res.data.datos["nombre_observacion_fija"],
            estado_boton: res.data.datos["estado_boton"],
          });
          this.obtenerObservacionesLibres();
        }
      });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  agregarobservacion = () => {
    if (this.state.observacion_libre == null || this.state.observacion_libre == "") {
      NotificationManager.error("Debe ingresar una observacion");
      return;
    }

    axios
      .post(
        global.config.modulos.listaEspera + "crear_observacion_usuario/",
        {
          loi_id: this.state.loi_id,
          uspId: this.state.uspId,
          observacion: this.state.observacion_libre,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Se ha agregado una observacion al paciente");
          this.setState({
            observacion_libre: "",
          });

          this.obtenerObservacionesLibres();
        } else {
          NotificationManager.error("No se ha podido agregar el tratamiento al paciente");
        }
      });
  };

  observacion_libre = (e) => {
    const observacion_libre = e.target.value;
    this.setState({ observacion_libre: observacion_libre });
  };

  obtenerObservacionesLibres = () => {
    axios
      .get(global.config.modulos.listaEspera + "ver_observaciones_usuario/", {
        headers: authHeader(),
        params: { loi_id: this.state.loi_id },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            observacionesLibres: res.data.datos,
          });
        }
      });
  };

  mostrarAlertaReingreso = () => {
    this.setState({
      modalReingreso: true,
    });
  };

  ocultarAlertaReingreso = () => {
    this.setState({
      modalReingreso: false,
    });
    this.salir();
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogContent
            style={{
              width: "1100px",
              maxWidth: "100%",
              height: "100%",
              maxHeight: "100%",
            }}
          >
            <div style={{ marginBottom: "15px" }}>
              <h3>¡Atención!</h3>
              <h3>
                Paciente ya esta inscrito, se encuentra en {this.state.ingreso} {this.state.estado_alta ? "por" : null} {this.state.estado_alta ? this.state.nombre_alta : null}{" "}
              </h3>
            </div>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ display: "flex", fontSize: "14px" }}>
                <div style={{ marginBottom: "2%", marginRight: "5%" }}>
                  <div
                    style={{
                      width: "100%",
                      marginBottom: "5px",
                      fontSize: "14px",
                    }}
                  >
                    <p> Código</p>
                  </div>
                  <div style={{ width: "100%", fontSize: "14px" }}>
                    <p>{this.state.codigo}</p>
                  </div>
                </div>
                <div style={{ marginBottom: "2%", marginRight: "5%" }}>
                  <div style={{ width: "100%", marginBottom: "5px" }}>
                    <p>Fecha de ingreso</p>
                  </div>
                  <div style={{ width: "100%" }}>
                    <p>{this.state.fecha_registro}</p>
                  </div>
                </div>
                <div style={{ marginBottom: "2%", marginRight: "5%" }}>
                  <div style={{ width: "100%", marginBottom: "5px" }}>
                    <p>Rut</p>
                  </div>
                  <div style={{ width: "100%" }}>
                    <p>{chileanRut.format(this.state.rut, true)}</p>
                  </div>
                </div>
                <div style={{ marginBottom: "2%", marginRight: "5%" }}>
                  <div style={{ width: "100%", marginBottom: "5px" }}>
                    <p>Nombre</p>
                  </div>
                  <div style={{ width: "100%" }}>
                    <p>{this.state.nombres}</p>
                  </div>
                </div>
                <div style={{ marginBottom: "2%", marginRight: "3%" }}>
                  <div style={{ width: "100%", marginBottom: "5px" }}>
                    <p>Edad</p>
                  </div>
                  <div style={{ width: "100%" }}>
                    <p>{this.state.edad} años</p>
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} style={{ display: "flex", fontSize: "14px" }}>
                <div style={{ marginBottom: "2%", marginRight: "5%" }}>
                  <div style={{ width: "100%", marginBottom: "5px" }}>
                    <p>Sexo</p>
                  </div>
                  <div style={{ width: "100%" }}>
                    <p>{this.state.sexo}</p>
                  </div>
                </div>
                <div style={{ marginBottom: "2%", marginRight: "5%" }}>
                  <div style={{ width: "100%", marginBottom: "5px" }}>
                    <p>Cesfam</p>
                  </div>
                  <div style={{ width: "100%" }}>
                    <p>{this.state.nombre_cesfam}</p>
                  </div>
                </div>
                <div style={{ marginBottom: "2%", marginRight: "5%" }}>
                  <div style={{ width: "100%", marginBottom: "5px" }}>
                    <p>Teléfono 1 </p>
                  </div>
                  <div style={{ width: "100%" }}>
                    <p>{this.state.telefono1}</p>
                  </div>
                </div>
                <div style={{ marginBottom: "2%", marginRight: "5%" }}>
                  <div style={{ width: "100%", marginBottom: "5px" }}>
                    <p>Teléfono 2</p>
                  </div>
                  <div style={{ width: "100%" }}>
                    <p>{this.state.telefono2}</p>
                  </div>
                </div>
              </GridItem>
              {this.state.estado_tratamiento ? (
                <GridItem xs={12} sm={12} md={12} style={{ display: "flex", fontSize: "14px" }}>
                  <div style={{ marginBottom: "2%", marginRight: "5%" }}>
                    <div style={{ width: "100%", marginBottom: "5px" }}>
                      <p>Fecha de Tratamiento</p>
                    </div>
                    <div style={{ width: "100%" }}>
                      <p>{this.state.fecha_tratamiento}</p>
                    </div>
                  </div>
                  <div style={{ marginBottom: "2%", marginRight: "5%" }}>
                    <div style={{ width: "100%", marginBottom: "5px" }}>
                      <p>Tratamiento</p>
                    </div>
                    <div style={{ width: "100%" }}>
                      <p>{this.state.nombre_tratamiendo}</p>
                    </div>
                  </div>
                </GridItem>
              ) : null}
              {this.state.estado_citado ? (
                <GridItem xs={12} sm={12} md={12} style={{ display: "flex", fontSize: "14px" }}>
                  <div style={{ marginBottom: "2%", marginRight: "5%" }}>
                    <div style={{ width: "100%", marginBottom: "5px" }}>
                      <p>Fecha de Citacion a Tratamiento</p>
                    </div>
                    <div style={{ width: "100%" }}>
                      <p>{this.state.fecha_tratamiento}</p>
                    </div>
                  </div>
                  <div style={{ marginBottom: "2%", marginRight: "5%" }}>
                    <div style={{ width: "100%", marginBottom: "5px" }}>
                      <p>Tratamiento</p>
                    </div>
                    <div style={{ width: "100%" }}>
                      <p>{this.state.nombre_tratamiendo}</p>
                    </div>
                  </div>
                </GridItem>
              ) : null}
              {this.state.estado_alta ? (
                <GridItem xs={12} sm={12} md={12} style={{ display: "flex", fontSize: "14px" }}>
                  <div style={{ marginBottom: "2%", marginRight: "5%" }}>
                    <div style={{ width: "100%", marginBottom: "5px" }}>
                      <p>Fecha de alta</p>
                    </div>
                    <div style={{ width: "100%" }}>
                      <p>{this.state.fecha_alta}</p>
                    </div>
                  </div>
                  <div style={{ marginBottom: "2%", marginRight: "5%" }}>
                    <div style={{ width: "100%", marginBottom: "5px" }}>
                      <p>Tipo Alta</p>
                    </div>
                    <div style={{ width: "100%" }}>
                      <p>{this.state.nombre_alta}</p>
                    </div>
                  </div>
                </GridItem>
              ) : null}
              {this.state.estado_observado ? (
                <GridItem xs={12} sm={12} md={12} style={{ display: "flex", fontSize: "14px" }}>
                  <div style={{ marginBottom: "2%", marginRight: "5%" }}>
                    <div
                      style={{
                        width: "100%",
                        marginBottom: "5px",
                        fontSize: "14px",
                      }}
                    >
                      <p>Razón Observación</p>
                    </div>
                    <div style={{ width: "100%" }}>
                      <p>{this.state.nombre_observacion_fija}</p>
                    </div>
                  </div>
                </GridItem>
              ) : null}

              <GridItem xs={12} sm={12} md={12} style={{ display: "flex", fontSize: "14px" }}>
                <div style={{ marginBottom: "1px", marginRight: "5%" }}>
                  <div style={{ width: "100%", marginBottom: "5px" }}>
                    <p>Observación</p>
                  </div>
                  <div style={{ display: "flex", width: "100%" }}>
                    <FormControl>
                      <TextField
                        id="observacion_libre"
                        name="observacion_libre"
                        multiline
                        rows={2}
                        value={this.state.observacion_libre}
                        onChange={this.observacion_libre}
                        style={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          padding: "5px",
                          width: "500px",
                        }}
                      />
                    </FormControl>
                    <Buttons
                      onClick={this.agregarobservacion}
                      className={"btnOpcion fondoNaranjo"}
                      style={{
                        cursor: "pointer",
                        marginLeft: "50px",
                        marginTop: "2%",
                        fontSize: "14px",
                        float: "left",
                        border: "0",
                        textAlign: "center",
                        height: "45px",
                        width: "100px",
                        padding: "5px",
                      }}
                    >
                      Agregar observación
                    </Buttons>
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} id="tablaBloqueos" style={{ display: "flex" }}>
                <MaterialTable
                  style={{ width: "100% ", fontSize: "14px" }}
                  title=""
                  columns={[
                    {
                      title: "Fecha ingreso observación",
                      field: "uo_fecha_observacion",
                    },
                    { title: "Funcionario ", field: "funcionario" },
                    { title: "Observación", field: "uo_observacion" },
                  ]}
                  data={this.state.observacionesLibres}
                  options={{
                    actionsColumnIndex: -1,
                    search: false,
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage: "Usuario aun no tiene observaciones",
                    },
                  }}
                />
              </GridItem>

              {this.state.estado_boton ? (
                <GridItem xs={12} sm={12} md={12}>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "1%",
                      marginLeft: "65%",
                      marginTop: "30px",
                    }}
                  >
                    <Buttons
                      onClick={() => this.mostrarAlertaReingreso()}
                      style={{
                        cursor: "pointer",
                        marginLeft: "2%",
                        marginTop: "2%",
                        fontSize: "16px",
                        float: "left",
                        border: "0",
                        textAlign: "center",
                        backgroundColor: "#00ac77",
                        height: "40px",
                        width: "150px",
                      }}
                    >
                      Confirmar
                    </Buttons>
                    <Buttons
                      onClick={this.salir}
                      className={"btnOpcion fondoNaranjo"}
                      style={{
                        cursor: "pointer",
                        marginLeft: "2%",
                        marginTop: "2%",
                        fontSize: "16px",
                        float: "left",
                        border: "0",
                        textAlign: "center",
                        height: "40px",
                        width: "150px",
                      }}
                    >
                      Cancelar
                    </Buttons>
                  </div>
                </GridItem>
              ) : (
                <GridItem xs={12} sm={12} md={12}>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "1%",
                      marginLeft: "90%",
                      marginTop: "5px",
                    }}
                  >
                    <Buttons
                      onClick={this.salir}
                      className={"btnOpcion fondoNaranjo"}
                      style={{
                        cursor: "pointer",
                        marginLeft: "2%",
                        marginTop: "2%",
                        fontSize: "16px",
                        float: "left",
                        border: "0",
                        textAlign: "center",
                        height: "40px",
                        width: "150px",
                      }}
                    >
                      Cancelar
                    </Buttons>
                  </div>
                </GridItem>
              )}
            </GridContainer>
          </DialogContent>
        </Dialog>
        {this.state.modalReingreso ? <AlertaReingreso ocultar={this.ocultarAlertaReingreso} usuId={this.state.usuId}></AlertaReingreso> : null}
      </div>
    );
  }
}
