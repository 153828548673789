import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import axios from "axios";
import TableContainer from "@material-ui/core/TableContainer";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import editar from "../../assets/img/gestion/editar.svg";
import AgregarAyudaMunicipal from "./AyudaMunicipalAgregar";
import ModificarAyudaMunicipal from "./AyudaMunicipalModificar";
import icono_eliminar from "../../assets/img/usuarios/icono_eliminar.svg";
import { confirmAlert } from "react-confirm-alert";

import Tooltip from "@material-ui/core/Tooltip";
import { NotificationManager } from "react-notifications";

// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class AyudaMunicipal extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: parseInt(store.getState().usp),
      open: true,
      usuario: props.usuario,
      ayudas: [],
      modalAgregarAyudaMunicipal: false,
      modalModificarAyudaMunicipal: false,
      idAyuda: null,
    };
  }

  componentDidMount() {
    this.traerAyudaMunicipal();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  mostrarAgregarAyudaMunicipal = () => {
    this.setState({ modalAgregarAyudaMunicipal: true });
  };

  ocultarAgregarAyudaMunicipal = () => {
    this.setState({ modalAgregarAyudaMunicipal: false });
    this.traerAyudaMunicipal();
  };

  mostrarModificarAyudaMunicipal = (idAyuda) => {
    this.setState({ modalModificarAyudaMunicipal: true, idAyuda: idAyuda });
  };

  ocultarModificarAyudaMunicipal = () => {
    this.setState({ modalModificarAyudaMunicipal: false });
    this.traerAyudaMunicipal();
  };

  traerAyudaMunicipal = () => {
    axios
      .get(global.config.modulos.gestion + "ayuda_municipal/", {
        headers: authHeader(),
        params: { usuario: this.state.usuario },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ ayudas: res.data.datos });
        }
      });
  };

  validarDatos = () => {
    let respuesta = { estado: true, mensaje: "" };

    if (this.state.fecha == "") {
      respuesta["estado"] = false;
      respuesta["mensaje"] = "Selecciona una fecha";
    } else if (this.state.gestion == "") {
      respuesta["estado"] = false;
      respuesta["mensaje"] = "Selecciona una gestión";
    } else if (this.state.ayuda == "") {
      respuesta["estado"] = false;
      respuesta["mensaje"] = "Selecciona una ayuda";
    }

    return respuesta;
  };

  guardarAyudaMunicipal = () => {
    let datosValidados = this.validarDatos();
    if (!datosValidados["estado"]) {
      return NotificationManager.warning(datosValidados["mensaje"]);
    }

    axios
      .post(
        global.config.modulos.gestion + "ayuda_municipal/",
        {
          fecha: this.state.fecha,
          ayuda: this.state.ayuda,
          gestion: this.state.gestion,
          usuario: this.state.usuario,
          usuario_panel: this.state.uspId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Ayuda guardada con éxito");
          this.traerAyudaMunicipal();
        }
      });
  };

  modificarAyudaMunicipal = (id) => {
    axios
      .patch(
        global.config.modulos.gestion + "ayuda_municipal/",
        {
          id: id,
          fecha: this.state.fecha,
          ayuda: this.state.ayuda,
          gestion: this.state.gestion,
          usuario: this.state.usuario,
          usuario_panel: this.state.uspId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Ayuda modificada con éxito");
        }
      });
  };

  eliminarAyudaMunicipal = (id) => {
    confirmAlert({
      title: "",
      message: "¿Esta segur@ que desea eliminar esta ayuda?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => {
            axios
              .patch(
                global.config.modulos.gestion + "ayuda_municipal/",
                {
                  id: id,
                  gam_estado: 0,
                },
                {
                  headers: authHeader(),
                }
              )
              .then((res) => {
                if (res.data.estado) {
                  this.traerAyudaMunicipal();
                  NotificationManager.success("Ayuda eliminada con éxito");
                }
              });
          },
          className: "btnAceptar",
        },
      ],
    });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Ayuda Municipal</h2>
                  <Button onClick={this.mostrarAgregarAyudaMunicipal} color="primary" className="fondoVerde">
                    Agregar Ayuda
                  </Button>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <TableContainer>
                    <Table aria-label="simple table" className="tablaGeneralGestion bordeTablaCovid">
                      <TableHead>
                        <TableRow>
                          <TableCell className="cellInicio" align="center">
                            Tipo de ayuda
                          </TableCell>
                          <TableCell align="center">Fecha de la ayuda</TableCell>
                          <TableCell align="center">Estado</TableCell>
                          <TableCell align="center">Realizado por</TableCell>
                          <TableCell className="cellTermino" align="center" style={{ minWidth: 115 }}>
                            Acciones
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.ayudas.map((res, index) => (
                          <TableRow key={index}>
                            <TableCell align="center">{res.gam_ayuda}</TableCell>
                            <TableCell align="center">{res.gam_fecha}</TableCell>
                            <TableCell align="center">{res.gam_gestion}</TableCell>
                            <TableCell align="center">{res.usuario_panel}</TableCell>
                            <TableCell align="center">
                              <Tooltip title="Editar la ayuda" aria-label="Editar la ayuda">
                                <img className={"smallIcon"} src={editar} onClick={() => this.mostrarModificarAyudaMunicipal(res.gam_id)} />
                              </Tooltip>
                              <Tooltip title="Eliminar la ayuda" aria-label="Eliminar la ayuda">
                                <img style={{ width: 20 }} className="smallIcon" src={icono_eliminar} onClick={() => this.eliminarAyudaMunicipal(res.gam_id)} />
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </GridItem>

                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <Button onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                    Cerrar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
        {this.state.modalAgregarAyudaMunicipal ? <AgregarAyudaMunicipal ocultar={this.ocultarAgregarAyudaMunicipal} usuario={this.state.usuario}></AgregarAyudaMunicipal> : null}
        {this.state.modalModificarAyudaMunicipal ? <ModificarAyudaMunicipal ocultar={this.ocultarModificarAyudaMunicipal} usuario={this.state.usuario} id={this.state.idAyuda}></ModificarAyudaMunicipal> : null}
      </Dialog>
    );
  }
}
