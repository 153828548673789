import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import Button from "@material-ui/core/Button";
import Titulo from "components/titulo/Titulo.js";
import signo from "assets/img/signo.svg";
import "assets/css/general.css";
import "assets/css/usuarios.css";
import "assets/css/general.css";
import Grafico_Pro_Com from "components/Graficos/Profesionales/RankingProfesionalesCom";
import Grafico_Pro_Ces from "components/Graficos/Profesionales/ProfesionalesCesfam";
import DatePicker from "react-multi-date-picker";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { store } from "store/index";
import img from "../../../assets/img/icono calendario.svg";

export default class GraficoProfesionales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perfiles: [],
      perfil: "",
      fec_act: "",
      fec_ini: "",
      fec_ter: "",
      fecha_actual: "",
      fecha_inicio: "",
      fecha_termino: "",
      idCliente: store.getState().cliente,
      herramientas: store.getState().herramientas,
      id_ces: store.getState().cesfam,
      listaEsp: false,
      listaCes: false,
      especialidad: "",
      mostrarGraficoComunal: false,
      mostrarGraficoCesfam: false,
      btnEstado: "none",
      listaGraf: false,
      usp: store.getState().usp,
      total_cesfam: "",
      esp: "Seleccione Especialidad",
      ces: "Seleccione Cesfam",
      cesfams: [],
      cesfamsVisibles: [],
      total_cesfam: "",
      cesfamdatos: "",
      botonCom: "None",
      leLis: "28.8%",
      tipoGraf: "Seleccione tipo de grafico",
      tipo: "",
      leftesp: "",
      acumulado: "Grafico Acumulado",
      primeraCargaFecha: "",
      primeraCargaSemana: "",
      primeraCargaMes: "",
      primeraCargaAño: "",
      gAcumulado: false,
    };
  }
  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Profesionales - Pacientes <span title=''><img src=" + signo + "></img></span>";
  };

  datosSelecCesfam = () => {
    this.setState({ cesfams: [] });
    axios.get(global.config.modulos.usuariospanel + `por_id/` + this.state.usp, { headers: authHeader() }).then((res) => {
      if (res.data) {
        if (res.data.rol == "administrador") {
          this.setState(
            {
              mostrarGraficoComunal: true,
              botonCom: "flex",
              tipo: "admin",
              leftesp: "14.8%",
            },
            () => {
              this.filtroPerfiles();
            }
          );
        } else {
          this.setState(
            {
              mostrarGraficoComunal: true,
              btnEstado: "initial",
              botonCom: "None",
              leLis: "56.9%",
              tipo: "admin",
              leftesp: "14.8%",
            },
            () => {
              this.filtroPerfiles();
            }
          );
        }
        const cesfamsVisibles = res.data["usp_cesfams_visibles"].split(",").map((numero) => parseInt(numero));
        this.setState({ cesfams: [] });

        axios
          .get(global.config.modulos.grafico_demanda + "cesfamCliente/", {
            headers: authHeader(),
            params: {
              comuna: this.state.idCliente,
            },
          })
          .then((res) => {
            if (!res.data.estado) {
              NotificationManager.info("No hay datos");
              return;
            }

            this.setState({
              comuna: res.data.datos[0]["comuna"],
              cesfams: [],
              cesfamsVisibles: [],
            });

            for (let index = 0; index < res.data.datos.length; index++) {
              if (cesfamsVisibles.includes(res.data.datos[index]["id"])) {
                this.setState((prevState) => ({
                  cesfams: [...prevState.cesfams, res.data.datos[index]],
                  cesfamsVisibles: [...prevState.cesfamsVisibles, res.data.datos[index]["id"]],
                }));
              }

              this.setState({ total_cesfam: this.state.cesfams.length });
            }

            if (this.state.total_cesfam === 0) {
              NotificationManager.info("no tiene cesfams asociados");
            }
          });
      }
    });
  };

  filtroPerfiles = () => {
    this.setState({ perfiles: [] });
    axios
      .get(global.config.modulos.grafico_demanda + "filtro_Profesional/", {
        headers: authHeader(),
        params: {
          cliente: this.state.idCliente,
          fecha_inicio: this.state.fecha_inicio,
          fecha_termino: this.state.fecha_termino,
          fecha_actual: this.state.fecha_actual,
          especialidad: this.state.especialidad,
          cesfam: this.state.cesfamdatos,
          acumulado: this.state.gAcumulado,
          ces: this.state.id_ces,
          tipo: this.state.tipo,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          return;
        }
        this.setState({
          perfiles: res.data.datos,
        });
      });
  };

  actualizarDatos = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  actualizarFechaActual = (fecha) => {
    this.setState(
      {
        fecha_actual: "",
        fec_act: "",
        fecha_inicio: "",
        fecha_termino: "",
        fec_ini: "",
        fec_ter: "",
        mostrarGrafico: false,
      },
      () => {
        if (this.state.mostrarGraficoSector === true) {
          this.setState({ mostrarGraficoSector: false }, () => {
            this.setState(
              {
                fecha_actual: fecha["year"] + "-" + fecha["month"] + "-" + fecha["day"],
                fec_act: fecha,
                mostrarGraficoSector: true,
              },
              () => {
                this.filtroPerfiles();
              }
            );
          });
        } else if (this.state.mostrarGraficoCesfam === true) {
          this.setState({ mostrarGraficoCesfam: false }, () => {
            this.setState(
              {
                fecha_actual: fecha["year"] + "-" + fecha["month"] + "-" + fecha["day"],
                fec_act: fecha,
                mostrarGraficoCesfam: true,
              },
              () => {
                this.filtroPerfiles();
              }
            );
          });
        } else if (this.state.mostrarGraficoComunal === true) {
          this.setState({ mostrarGraficoComunal: false }, () => {
            this.setState(
              {
                fecha_actual: fecha["year"] + "-" + fecha["month"] + "-" + fecha["day"],
                fec_act: fecha,
                mostrarGraficoComunal: true,
              },
              () => {
                this.filtroPerfiles();
              }
            );
          });
        }
      }
    );
  };

  fechaActualLimpiar = () => {
    this.setState({
      fecha_actual: "",
      fec_act: "",
    });
  };

  actualizarFechaInicial = (fecha) => {
    this.setState({ fecha_actual: "", fec_act: "", fecha_inicio: "", fec_ini: "" }, () => {
      this.setState(
        {
          fecha_inicio: fecha["year"] + "-" + fecha["month"] + "-" + fecha["day"],
          fec_ini: fecha,
        },
        () => {
          if (this.state.fecha_termino !== "") {
            if (this.state.mostrarGraficoSector === true) {
              this.setState({ mostrarGraficoSector: false }, () => {
                this.setState({ mostrarGraficoSector: true }, () => {
                  this.filtroPerfiles();
                });
              });
            } else if (this.state.mostrarGraficoCesfam === true) {
              this.setState({ mostrarGraficoCesfam: false }, () => {
                this.setState({ mostrarGraficoCesfam: true }, () => {
                  this.filtroPerfiles();
                });
              });
            } else if (this.state.mostrarGraficoComunal === true) {
              this.setState({ mostrarGraficoComunal: false }, () => {
                this.setState({ mostrarGraficoComunal: true }, () => {
                  this.filtroPerfiles();
                });
              });
            }
          }
        }
      );
    });
  };

  fechaInicialLimpiar = () => {
    this.setState({
      fecha_inicio: "",
      fec_ini: "",
    });
  };

  actualizarFechaTermino = (fecha) => {
    this.setState({ fecha_termino: "", fec_ter: "", fec_act: "", fecha_actual: "" }, () => {
      this.setState(
        {
          fecha_termino: fecha["year"] + "-" + fecha["month"] + "-" + fecha["day"],
          fec_ter: fecha,
        },
        () => {
          if (this.state.fecha_inicio !== "") {
            if (this.state.mostrarGraficoSector === true) {
              this.setState({ mostrarGraficoSector: false }, () => {
                this.setState({ mostrarGraficoSector: true }, () => {
                  this.filtroPerfiles();
                });
              });
            } else if (this.state.mostrarGraficoCesfam === true) {
              this.setState({ mostrarGraficoCesfam: false }, () => {
                this.setState({ mostrarGraficoCesfam: true }, () => {
                  this.filtroPerfiles();
                });
              });
            } else if (this.state.mostrarGraficoComunal === true) {
              this.setState({ mostrarGraficoComunal: false }, () => {
                this.setState({ mostrarGraficoComunal: true }, () => {
                  this.filtroPerfiles();
                });
              });
            }
          }
        }
      );
    });
  };

  fechaTerminoLimpiar = () => {
    this.setState({
      fecha_termino: "",
      fec_ter: "",
    });
  };
  handleMouseEnterEsp = () => {
    this.setState({
      listaEsp: true,
    });
  };
  handleMouseOutEsp = () => {
    this.setState({
      listaEsp: false,
    });
  };

  clickEsp = (esp, nombre) => {
    if (this.state.mostrarGraficoComunal == true) {
      this.setState(
        {
          especialidad: esp,
          esp: nombre,
          mostrarGraficoComunal: false,
          mostrarGraficoCesfam: false,
        },
        () => {
          this.setState({
            mostrarGraficoComunal: true,
          });
        }
      );
    } else if (this.state.mostrarGraficoCesfam == true) {
      this.setState(
        {
          especialidad: esp,
          esp: nombre,
          mostrarGraficoComunal: false,
          mostrarGraficoCesfam: false,
        },
        () => {
          this.setState({
            mostrarGraficoCesfam: true,
          });
        }
      );
    }
  };

  clickCes = (ces, nombre) => {
    this.setState(
      {
        ces: nombre,
        cesfamdatos: ces,
        mostrarGraficoComunal: false,
        esp: "Seleccione Especialidad",
        especialidad: "",
      },
      () => {
        this.filtroPerfiles();
        this.traerPrimeraCarga();
        this.setState({
          mostrarGraficoComunal: true,
        });
      }
    );
  };

  clickGraf = (graf) => {
    if (graf == 0) {
      this.setState(
        {
          mostrarGraficoComunal: false,
          mostrarGraficoCesfam: false,
          tipoGraf: "",
        },
        () => {
          this.setState({
            mostrarGraficoComunal: true,
            tipoGraf: "Grafico Comunal",
          });
        }
      );
    }
    if (graf == 1) {
      this.setState(
        {
          mostrarGraficoComunal: false,
          mostrarGraficoCesfam: false,
        },
        () => {
          this.setState({
            mostrarGraficoCesfam: true,
            tipoGraf: "Grafico por Cesfam",
          });
        }
      );
    }
  };

  traerPrimeraCarga = () => {
    axios
      .get(global.config.modulos.grafico_demanda + "primer_horario/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfamdatos,
          cliente: this.state.idCliente,
        },
      })
      .then((res) => {
        if (!res.data.datos) {
          NotificationManager.info("");
          return;
        } else {
          this.setState({
            primeraCargaFecha: res.data.datos[0],
            primeraCargaSemana: res.data.datos[1],
            primeraCargaMes: res.data.datos[2],
            primeraCargaAño: res.data.datos[3],
          });
        }
      });
  };

  mostrarGraficoAcumulado = () => {
    this.setState(
      {
        fecha_inicio: this.state.primeraCargaFecha,
        fec_ini: "",
        fec_ter: "",
        fec_act: "",
        fecha_termino: "",
        gAcumulado: true,
        mostrarGraficoComunal: false,
      },
      () => {
        this.setState({
          mostrarGraficoComunal: true,
        });
        this.filtroPerfiles();
      }
    );
  };

  botonBorrar = () => {
    if (this.state.mostrarGraficoComunal == true) {
      this.setState(
        {
          fecha_inicio: "",
          fec_act: "",
          fecha_termino: "",
          fec_ini: "",
          fecha_actual: "",
          fec_ter: "",
          cesfamdatos: "",
          esp: "Seleccione Especialidad",
          ces: "Seleccione Cesfam",
          especialidad: "",
          mostrarGraficoComunal: false,
          perfiles: [],
        },
        () => {
          this.setState(
            {
              mostrarGraficoComunal: true,
            },
            () => {
              this.filtroPerfiles();
            }
          );
        }
      );
    } else if (this.state.mostrarGraficoCesfam == true) {
      this.setState(
        {
          fecha_inicio: "",
          fec_act: "",
          fecha_termino: "",
          fec_ini: "",
          fecha_actual: "",
          fec_ter: "",
          esp: "Seleccione Especialidad",
          ces: "Seleccione Cesfam",
          especialidad: "",
          mostrarGraficoCesfam: false,
          perfiles: [],
        },
        () => {
          this.setState(
            {
              mostrarGraficoCesfam: true,
            },
            () => {
              this.filtroPerfiles();
            }
          );
        }
      );
    }
  };

  handleMouseEnterGraf = () => {
    this.setState({
      listaGraf: true,
    });
  };
  handleMouseOutGraf = () => {
    this.setState({
      listaGraf: false,
    });
  };

  handleMouseEnterCes = () => {
    this.setState({
      listaCes: true,
    });
  };
  handleMouseOutCes = () => {
    this.setState({
      listaCes: false,
    });
  };

  componentDidMount() {
    this.datosSelecCesfam();
    this.titulo();
    this.traerPrimeraCarga();
  }

  render() {
    return (
      <div className="middle-area" style={{ zIndex: 45 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "99%",
          }}
        >
          <div
            style={{
              marginTop: "1%",
              display: "flex",
              zIndex: "2",
              width: "50%",
            }}
          >
            {this.state.tipo === "admin" && (
              <Tooltip title={"Seleccione el Cesfam"}>
                <button
                  style={{
                    margin: "1px 0px 1px 12px",
                    background: "linear-gradient(to bottom, #d8275e, #f45175, #ff7b9e)",
                    borderRadius: "10px",
                    color: "white",
                    width: "26%",
                    cursor: "pointer",
                  }}
                  onClick={() => this.handleMouseEnterCes()}
                >
                  <span className="button-text">{this.state.ces}</span>
                  <span className="arrow">&#9660;</span>
                </button>
              </Tooltip>
            )}
            {this.state.listaCes && (
              <ul
                onMouseLeave={() => this.handleMouseOutCes()}
                style={{
                  position: "absolute",
                  background: "linear-gradient(to bottom, #d8275e, #f45175, #ff7b9e)",
                  width: "13%",
                  zIndex: 2,
                  display: "inline-grid",
                  borderRadius: "10px",
                  left: "0.9%",
                  top: "3.5rem",
                  cursor: "pointer",
                }}
              >
                {this.state.cesfams.map((item) => (
                  <Button style={{ borderBottom: "1px solid white", color: "white" }} onClick={() => this.clickCes(item.id, item.nombre)} value={item.id}>
                    {item.nombre}
                  </Button>
                ))}
              </ul>
            )}
            <Tooltip title={"Seleccione la Especialidad"}>
              <button
                style={{
                  margin: "1px 0px 1px 12px",
                  background: "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
                  borderRadius: "10px",
                  color: "white",
                  width: "26%",
                  cursor: "pointer",
                }}
                onClick={() => this.handleMouseEnterEsp()}
              >
                <span className="button-text">{this.state.esp}</span>
                <span className="arrow">&#9660;</span>
              </button>
            </Tooltip>
            {this.state.listaEsp && (
              <ul
                onMouseLeave={() => this.handleMouseOutEsp()}
                style={{
                  position: "absolute",
                  background: "linear-gradient(to bottom, #6E45AF, #8156C1, #9B76E2)",
                  width: "13%",
                  zIndex: 2,
                  display: "inline-grid",
                  borderRadius: "10px",
                  left: this.state.leftesp,
                  top: "3.5rem",
                  cursor: "pointer",
                }}
              >
                {this.state.perfiles.map((item) => (
                  <Button
                    style={{
                      borderBottom: "1px solid white",
                      color: "white",
                      cursor: "pointer",
                    }}
                    onClick={() => this.clickEsp(item.id, item.nombre)}
                    value={item.id}
                  >
                    {item.nombre}
                  </Button>
                ))}
              </ul>
            )}
            <Tooltip title={"Limpia los filtros"}>
              <Button
                onClick={this.botonBorrar}
                style={{
                  fontSize: "80%",
                  marginLeft: "1%",
                  background: "orange",
                }}
              >
                <span style={{ color: "#FFFFFF" }}>Limpiar</span>
              </Button>
            </Tooltip>
          </div>
          <div
            style={{
              marginTop: "1%",
              display: "flex",
              zIndex: "2",
              width: "50%",
              flexDirection: "row-reverse",
            }}
          >
            <Tooltip title={"muestra datos desde el inicio con HoraSalud hasta el dia actual"}>
              <button
                style={{
                  margin: "1px 0px 1px 12px",
                  background: "linear-gradient(rgb(216, 180, 29), rgb(232, 196, 42), rgb(247, 216, 87))",
                  borderRadius: "10px",
                  color: "white",
                  width: "26%",
                  cursor: "pointer",
                }}
                onClick={() => this.mostrarGraficoAcumulado()}
              >
                <span className="button-text">{this.state.acumulado}</span>
              </button>
            </Tooltip>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
          <div
            style={{
              display: "flex",
              textAlign: "left",
              background: "transparent",
              color: "rgb(128, 128, 128)",
              marginLeft: "12px",
              borderRadius: "2px",
              border: "2px solid orange",
              fontSize: "1vw",
              position: "relative",
              alignItems: "center",
            }}
          >
            <Tooltip title={"Seleccione Dia especifico de los datos"}>
              <DatePicker
                name="fecha_actual"
                placeholder="Dia especifico"
                value={this.state.fec_act}
                onChange={this.actualizarFechaActual}
                weekDays={["D", "L", "M", "M", "J", "V", "S"]}
                months={["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]}
                style={{
                  background: "transparent",
                  border: "none",
                  width: "100%",
                }}
                minDate={this.state.primeraCargaFecha}
                format="DD/MM/YYYY"
              />
            </Tooltip>
            <img
              style={{
                width: "17px",
                position: "absolute",
                right: "5%",
                top: "15%",
                zIndex: "-5",
              }}
              src={img}
            />
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "left",
              background: "transparent",
              color: "rgb(128, 128, 128)",
              marginLeft: "12px",
              borderRadius: "2px",
              border: "2px solid orange",
              fontSize: "1vw",
              position: "relative",
              alignItems: "center",
            }}
          >
            <Tooltip title={"Seleccione Dia desde que se van a buscar los datos"}>
              <DatePicker
                name="fecha_inicio"
                placeholder="Desde"
                value={this.state.fec_ini}
                onChange={this.actualizarFechaInicial}
                weekDays={["D", "L", "M", "M", "J", "V", "S"]}
                months={["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]}
                style={{
                  background: "transparent",
                  border: "none",
                  width: "100%",
                }}
                minDate={this.state.primeraCargaFecha}
                format="DD/MM/YYYY"
              />
            </Tooltip>
            <img
              style={{
                width: "17px",
                position: "absolute",
                right: "5%",
                top: "15%",
                zIndex: "-5",
              }}
              src={img}
            />
          </div>

          <div
            style={{
              display: "flex",
              textAlign: "left",
              background: "transparent",
              color: "rgb(128, 128, 128)",
              marginLeft: "12px",
              borderRadius: "2px",
              border: "2px solid orange",
              fontSize: "1vw",
              position: "relative",
              alignItems: "center",
            }}
          >
            <Tooltip title={"Seleccione Dia final que se van a buscar los datos"}>
              <DatePicker
                name="fecha_termino"
                value={this.state.fec_ter}
                onChange={this.actualizarFechaTermino}
                placeholder="Hasta"
                weekDays={["D", "L", "M", "M", "J", "V", "S"]}
                months={["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]}
                style={{
                  background: "transparent",
                  border: "none",
                  width: "100%",
                }}
                minDate={this.state.primeraCargaFecha}
                format="DD/MM/YYYY"
              />
            </Tooltip>
            <img
              style={{
                width: "17px",
                position: "absolute",
                right: "5%",
                top: "15%",
                zIndex: "-5",
              }}
              src={img}
            />
          </div>
        </div>
        {this.state.mostrarGraficoComunal && <Grafico_Pro_Com fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} fecha_actual={this.state.fecha_actual} cliente={this.state.idCliente} especialidad={this.state.especialidad} cesfams={this.state.id_ces} tipo={this.state.tipo} cesdatos={this.state.cesfamdatos} acumulado={this.state.gAcumulado} />}
        {this.state.mostrarGraficoCesfam && <Grafico_Pro_Ces fecha_inicio={this.state.fecha_inicio} fecha_termino={this.state.fecha_termino} fecha_actual={this.state.fecha_actual} cliente={this.state.idCliente} especialidad={this.state.especialidad} cesfams={this.state.cesfamdatos} />}
      </div>
    );
  }
}
