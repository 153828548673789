import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { NotificationManager } from "react-notifications";
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";

import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default class GrafBloquCes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datos: [],
      fecha_actual: props.fecha_actual,
      fecha_inicio: props.fecha_inicio,
      fecha_termino: props.fecha_termino,
      root: "",
      chart: "",
      id_b_1: props.id_b_1,
    };
  }

  GrafBloquCes = () => {
    this.setState({ datos: [] });
    axios
      .get(global.config.modulos.grafico_demanda + "obtener_bloqueadas_cesfam/", {
        headers: authHeader(),
        params: {
          fecha_inicio: this.state.fecha_inicio,
          fecha_termino: this.state.fecha_termino,
          fecha_actual: this.state.fecha_actual,
          comuna: this.state.id_b_1,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No hay datos");
          return;
        }
        this.setState({ datos: res.data.datos }, () => {
          try {
            this.state.root.dispose();
          } catch (e) {}

          console.log(this.state.datos);

          let root = am5.Root.new("chartdiv21");
          root.setThemes([am5themes_Animated.new(root)]);

          // Create chart
          // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
          let chart = root.container.children.push(
            am5percent.PieChart.new(root, {
              endAngle: 270,
            })
          );

          // Create series
          // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
          let series = chart.series.push(
            am5percent.PieSeries.new(root, {
              valueField: "value",
              categoryField: "category",
              endAngle: 270,
            })
          );

          series.labels.template.setAll({
            textType: "circular",
            radius: 4,
          });
          series.labels.template.set("text", "{category}: [bold]{value} Horas");
          series.states.create("hidden", {
            endAngle: -90,
          });

          // Set data
          // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
          series.data.setAll(this.state.datos);

          series.appear(1000, 100);
        });
      });
  };

  componentDidMount() {
    this.GrafBloquCes();
  }

  render() {
    return (
      <div
        id="chartdiv21"
        style={{
          overflowY: "scroll",
          overflowX: "scroll",
          WebkitOverflowScrolling: "touch",
          width: "49%",
          height: "48%",
          zIndex: 1,
          position: "fixed",
          marginTop: "50px",
          left: "50%",
        }}
      >
        <h2 style={{ textAlign: "center" }}>Horas Bloqueadas por Cesfams</h2>
      </div>
    );
  }
}
