import React, { useLayoutEffect } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5locales_es_ES from "@amcharts/amcharts5/locales/es_ES";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

export default function GraficoNsps(props) {
  var tipo = props.tipoData;
  var timeUnit = "year";
  var formato = "yyyy";

  if (tipo == 2) {
    timeUnit = "month";
    formato = "yyyy-MM";
  } else if (tipo == 3) {
    timeUnit = "week";
    formato = "yyyy-MM-dd";
  } else if (tipo == 4) {
    timeUnit = "day";
    formato = "yyyy-MM-mm";
  } else if (tipo == 5) {
    timeUnit = "minute";
    formato = "yyyy-MM-mm HH:MM:SS";
  }

  console.log(props);

  useLayoutEffect(() => {
    // Create root and chart
    var root = am5.Root.new("GraficoNsp");

    root.setThemes([am5themes_Animated.new(root)]);

    root.locale = am5locales_es_ES;

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: "none",
        wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );

    // Create Y-axis
    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
        min: 0,
        extraMax: 0.4,
      })
    );

    // Create X-Axis
    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0,
        baseInterval: {
          timeUnit: timeUnit,
          count: 1,
        },
        groupData: true,
        renderer: am5xy.AxisRendererX.new(root, {}),
      })
    );

    function arreglarData(data) {
      var datos = [];
      for (let i = 0; i < data.length; i++) {
        datos.push({
          date: new Date(data[i].hor_atencion).getTime(),
          fecha: data[i].fecha,
          tipoData: tipo,
          texto: data[i].texto,
          cantidad: parseInt(data[i].cantidad),
        });
      }
      return datos;
    }
    var data = arreglarData(props.data);

    // Create series
    function createSeries(name, field) {
      let toolt = am5.Tooltip.new(root, {});

      toolt.events.on("click", function (ev) {
        var datos = ev.target._dataItem.dataContext;
        props.handleLineCLick(datos);
      });

      var series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          valueXField: "date",
          tooltip: toolt,
        })
      );

      series.strokes.template.setAll({
        strokeWidth: 2,
      });

      var modal = am5.Modal.new(root, {
        content: "No se encontraron nsps para las fechas seleccionadas",
      });

      series.events.on("datavalidated", function (ev) {
        var series = ev.target;
        if (ev.target.data.length < 0) {
          modal.open();
        } else {
          modal.close();
        }
      });

      var bulletTemplate = am5.Template.new(root, {});

      bulletTemplate.events.on("click", function (ev) {
        var datos = ev.target._dataItem.dataContext;
        props.handleLineCLick(datos);
      });

      series.bullets.push(function (root) {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(
            root,
            {
              radius: 7,
              fill: series.get("fill"),
            },
            bulletTemplate
          ),
        });
      });

      series.get("tooltip").label.set("text", "[bold]{texto} :[/]{valueY}");
      series.data.setAll(data);
    }

    createSeries("fecha", "cantidad");

    // Add cursor
    chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomX",
      })
    );

    xAxis.set(
      "tooltip",
      am5.Tooltip.new(root, {
        themeTags: ["axis"],
      })
    );

    yAxis.set(
      "tooltip",
      am5.Tooltip.new(root, {
        themeTags: ["axis"],
      })
    );

    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      })
    );

    return () => {
      root.dispose();
    };
  }, []);
  return <div id="GraficoNsp" style={{ width: "100%", height: "500px" }}></div>;
}
