import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import chileanRut from "chilean-rut";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { DialogContentText } from "@material-ui/core";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Buttons from "react-multi-date-picker/components/button";
import { Input } from "@material-ui/core";
import "../../assets/css/usuarios.css";

export default class UsuarioEnTratamiento extends Component {
  constructor(props) {
    super(props);

    var date = moment().format("YYYY-MM-DD");

    this.state = {
      cesfam: store.getState().cesfam,
      usp_id: store.getState().usp,

      open: true,

      loi_id: props.loi_id,

      fecha_inicio: date,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  componentDidMount() {}

  fecha_inicio = (e) => {
    const fecha_inicio = e.target.value;
    this.setState({ fecha_inicio: fecha_inicio });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  agregarTratamiento = () => {
    var datos = {};

    if (this.state.fecha_inicio == null) {
      NotificationManager.error("Debe ingresar una fecha de inicio");
      return;
    }

    datos["loi_estado"] = 2;
    datos["loi_motivo_eliminacion"] = 6;
    datos["loi_fecha_evaluacion"] = this.state.fecha_inicio + " " + moment().format("HH:mm");

    axios
      .patch(
        global.config.modulos.listaEspera + "agregar_cita_a_tratamiento/",
        {
          loi_id: this.state.loi_id,
          usp_id: this.state.usp_id,
          datos: datos,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Se ha citado a tratamiento al paciente");
          this.salir();
          this.setState({
            fecha_inicio: null,
            tratamiento: null,
          });
        } else {
          NotificationManager.error("No se ha podido agregar el tratamiento al usuario");
        }
      });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario" style={{ maxWidth: "100%", maxHeight: "100%" }}>
          <DialogContent
            style={{
              width: "500px",
              maxWidth: "100%",
              height: "100%",
              maxHeight: "100%",
            }}
          >
            <div style={{ marginBottom: "5px" }}>
              <h2>Programa</h2>
            </div>
            <br></br>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">fecha ingreso</InputLabel>
                  <Input type="date" name="fecha inicio" value={this.state.fecha_inicio} onChange={this.fecha_inicio} />
                </FormControl>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "25px",
                  }}
                >
                  <Button style={{ marginRight: 10, backgroundColor: "#00ac77", color: "#fff" }} onClick={() => this.agregarTratamiento()}>
                    Guardar
                  </Button>
                  <Button onClick={this.salir} style={{ color: "#fff" }} className="fondoNaranjo">
                    Cancelar
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
