import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import axios from "axios";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Paginacion from "./../../components/Table/Pagination.js";
import MenuTab from "./../../components/GestionReservaExterna/MenuTab.js";
import { NotificationManager } from "react-notifications";
import Tooltip from "@material-ui/core/Tooltip";
import ver_mas from "../../assets/img/gestion/ver_mas.svg";
import Observaciones from "./../../components/GestionReservaExterna/Observaciones";
import ReservaExternaDescargaExcel from "./../../components/GestionReservaExterna/ReservaExternaDescargaExcel";
import comentario from "../../assets/img/gestion/comentario.svg";
import { confirmAlert } from "react-confirm-alert";
import AgregarUsuario from "./../../components/Usuarios/AgregarUsuario";
import { store } from "./../../store/index";

// CSS
import "./../../assets/css/gestion.css";

export default class GestionNoExiste extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cliente: store.getState().cliente,
      uspId: props.usp,
      cesfam: props.cesfam,
      ubicacion: 6,
      pagina: 0,
      filas: 10,
      total: 0,
      modalDescargaExcel: false,
      modalObservaciones: false,
      agregarUsuario: false,
      demanda: [],
      texto_filtro: props.rutFiltro,
    };
  }

  agregarUsuario = () => {
    this.setState({
      agregarUsuario: true,
    });
  };

  ocultarAgregar = () => {
    this.setState({ agregarUsuario: false });
  };

  copiarRut = (rut) => {
    navigator.clipboard.writeText(rut);
    NotificationManager.info("Rut copiado");
  };

  componentDidMount() {
    this.fetchGestion(this.state.pagina, this.state.filas);
  }

  fetchGestion = async (pagina, filas) => {
    axios
      .get(global.config.modulos.reservas_externas + "ruts_desconocidos/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
          pagina: pagina,
          filas: filas,
          texto_filtro: this.state.texto_filtro,
        },
      })
      .then((res) => {
        this.setState({
          demanda: res.data.datos,
          total: res.data.total,
          pagina: res.data.pagina,
        });
      });
  };

  handlePagination = (pagina) => {
    this.setState({
      pagina: pagina,
    });
    this.fetchGestion(pagina, this.state.filas);
  };

  handleFilasPagination = (filas) => {
    this.setState({
      filas: filas,
    });
    this.fetchGestion(this.state.pagina, filas);
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  aplicarFiltros = () => {
    this.fetchGestion(this.state.pagina, this.state.filas);
  };

  mostrarObservaciones = (id) => {
    this.setState({
      idRegistro: id,
      modalObservaciones: true,
    });
  };

  ocultarObservaciones = () => {
    this.setState({
      modalObservaciones: false,
    });
  };

  mostrarDescarga = (id) => {
    this.setState({
      modalDescargaExcel: true,
    });
  };

  ocultarDescarga = () => {
    this.setState({
      modalDescargaExcel: false,
    });
  };

  alertaEliminarCaso = (id) => {
    confirmAlert({
      title: "",
      message: "El registro se eliminará permanentemente, desea continuar?",

      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => this.eliminarCaso(id),
          className: "btnAceptar",
        },
      ],
    });
  };

  eliminarCaso = (id) => {
    axios
      .put(
        global.config.modulos.reservas_externas + "eliminar_rut_desconocido/",
        {
          id: id,
          usuario_panel: this.state.uspId,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Caso eliminado exitosamente");
          this.fetchGestion(this.state.pagina, this.state.filas);
        }
      });
  };

  render() {
    const { demanda } = this.state;
    const colorUrgencia = {
      Alto: "rgb(193 39 45)",
      Medio: "rgb(255 255 0)",
      Bajo: "rgb(57 181 74)",
      AltoColor: "#fff",
      MedioColor: "#000",
      BajoColor: "#fff",
    };

    return (
      <GridContainer style={{ width: "100%" }}>
        <GridItem xs={12} sm={12} md={12}>
          <div>
            <div style={{ float: "left" }}>
              <p style={{ float: "left", marginLeft: 20, height: 80 }}>
                <input
                  type="text"
                  name="texto_filtro"
                  value={this.state.texto_filtro}
                  onChange={this.cambiarValores}
                  placeholder="Nombre o Rut"
                  className={"inputText"}
                  style={{
                    padding: 6,
                    border: "1px solid black",
                    borderRadius: 5,
                    float: "left",
                  }}
                />
                <a
                  onClick={this.aplicarFiltros}
                  color="primary"
                  className="btnAgregarCaso fondoNaranjo"
                  style={{
                    padding: "7px 13px 7px 13px",
                    fontSize: 14,
                    float: "left",
                    marginLeft: 30,
                  }}
                >
                  Buscar
                </a>
              </p>
            </div>
            <div style={{ float: "right" }}>
              <a
                onClick={this.mostrarDescarga}
                color="primary"
                className="btnAgregarCaso fondoVerde"
                style={{
                  padding: "7px 13px 7px 13px",
                  fontSize: 14,
                  float: "left",
                  marginRight: 30,
                }}
              >
                Descargar excel
              </a>
            </div>
            <MenuTab modal={8} cambiarVentana={this.props.cambiarVentana} />
            <div style={{ marginTop: 30, marginBottom: 100 }}>
              <TableContainer>
                <Table aria-label="simple table" className="tablaGeneralGestion bordeTabla">
                  <TableHead>
                    <TableRow>
                      <TableCell className="cellInicio" align="center">
                        Fecha de ingreso
                      </TableCell>
                      <TableCell align="center">Rut</TableCell>
                      <TableCell align="center">Descripción</TableCell>
                      <TableCell align="center">Fecha atención</TableCell>
                      <TableCell align="center">Tipo de atención</TableCell>
                      <TableCell align="center">Teléfonos</TableCell>
                      <TableCell align="center">Acciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {demanda.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{row.ger_fecha_ingreso ? row.ger_fecha_ingreso.replace("T", " ") : "--"}</TableCell>
                        <TableCell align="center">{row.ger_rut}</TableCell>
                        <TableCell align="center">{row.ger_descripcion}</TableCell>
                        <TableCell align="center">{row.ger_fecha_atencion ? row.ger_fecha_atencion.replace("T", " ") : "--"}</TableCell>
                        <TableCell align="center">{row.ger_tipo_atencion}</TableCell>
                        <TableCell align="center">{row.ger_telefonos}</TableCell>
                        <TableCell align="center">
                          <p>
                            <button className="reservas_externas_boton fondoRojo" onClick={() => this.alertaEliminarCaso(row.ger_id)}>
                              Eliminar caso
                            </button>
                          </p>
                          <p>
                            <button className="reservas_externas_boton fondoVerde" onClick={() => this.agregarUsuario()}>
                              Agregar paciente
                            </button>
                          </p>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Paginacion total={this.state.total} paginacion={this.handlePagination} filas_paginacion={this.handleFilasPagination} />
            </div>
          </div>
          {this.state.modalObservaciones && <Observaciones idRegistro={this.state.idRegistro} ocultar={this.ocultarObservaciones} />}
          {this.state.modalDescargaExcel && <ReservaExternaDescargaExcel ocultar={this.ocultarDescarga} />}
          {this.state.agregarUsuario ? <AgregarUsuario ocultar={this.ocultarAgregar} cliente={this.state.cliente}></AgregarUsuario> : null}
        </GridItem>
      </GridContainer>
    );
  }
}
