import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import "react-confirm-alert/src/react-confirm-alert.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import "./../../assets/css/usuarios.css";

// estilos

export default class ImageFullScreen extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      url: props.url,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="imagenFullScreen">
        <DialogContent>
          <img src={this.state.url} />
        </DialogContent>
      </Dialog>
    );
  }
}
