import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";

// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class Motivo extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: parseInt(store.getState().usp),
      open: true,
      ageId: props.ageId,
      usuId: props.usuId,
      gegId: props.gegId,
      paused: false,
      canal: 5,
      reserva: [],
    };
  }

  componentDidMount() {
    this.datosReserva();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  datosReserva = () => {
    axios
      .get(global.config.modulos.agendamientos + "agenda_por_id/" + this.state.ageId, {
        headers: authHeader(),
      })
      .then((res) => {
        res.data.hor_atencion = res.data.hor_atencion.replace("T", " ");
        this.setState({ reserva: res.data });
      });
  };

  generarHistorial = (accion, id, usp) => {
    axios
      .post(
        global.config.modulos.gestion + "historial/",
        {
          accion: accion,
          gestion: id,
          usuario_panel: usp,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {});
  };

  confirmacionAnulacion = () => {
    confirmAlert({
      title: "",
      message: "¿Seguro(a) que desea anular esta hora?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => this.anularHora(),
          className: "btnAceptar",
        },
      ],
    });
  };

  anularHora = () => {
    var data = {
      agenda: this.state.ageId,
      canal: this.state.canal,
      usp_id: this.state.uspId,
    };

    let apiUrl = global.config.modulos.agendamientos + "anular_hora/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      if (res.data.estado) {
        NotificationManager.success("Hora anulada con éxito");

        axios
          .patch(
            global.config.modulos.gestion,
            {
              geg_id: this.state.gegId,
              geg_agenda: null,
              geg_especialidad: null,
              geg_tipo_contacto: null,
              geg_telefono_contacto: null,
            },
            {
              headers: authHeader(),
            }
          )
          .then((res2) => {
            this.generarHistorial("Se anula la hora", this.state.gegId, this.state.uspId);
            this.salir();
          });
      }
    });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Hora reservada</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <p style={{ marginTop: 10 }}>
                    <label style={{ fontWeight: "bold" }}>Estado reserva:</label>
                    <label style={{ marginLeft: 20 }}>{this.state.reserva.estadoAgenda}</label>
                  </p>
                  <p style={{ marginTop: 10 }}>
                    <label style={{ fontWeight: "bold" }}>Cesfam:</label>
                    <label style={{ marginLeft: 20 }}>{this.state.reserva.cesfam}</label>
                  </p>
                  <p style={{ marginTop: 10 }}>
                    <label style={{ fontWeight: "bold" }}>Especialidad:</label>
                    <label style={{ marginLeft: 20 }}>{this.state.reserva.especialidad}</label>
                  </p>
                  <p style={{ marginTop: 10 }}>
                    <label style={{ fontWeight: "bold" }}>Profesional:</label>
                    <label style={{ marginLeft: 20 }}>{this.state.reserva.nombreProfesional}</label>
                  </p>
                  <p style={{ marginTop: 10 }}>
                    <label style={{ fontWeight: "bold" }}>Hora de atención:</label>
                    <label style={{ marginLeft: 20 }}>{this.state.reserva.hor_atencion}</label>
                  </p>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <Button onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                    Cerrar
                  </Button>
                  <Button
                    onClick={this.confirmacionAnulacion}
                    style={{
                      marginLeft: 20,
                      backgroundColor: "rgb(208 106 117)",
                    }}
                    color="primary"
                  >
                    Anular
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
