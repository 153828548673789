import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "../../store/index";
import { confirmAlert } from "react-confirm-alert"; // Import

import axios from "axios";
import moment from "moment";
import chileanRut from "chilean-rut";

// core components
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import NotificationManager from "react-notifications/lib/NotificationManager";

// iconos final
import iconoVer from "../../assets/img/usuarios/icono_ver_mas.svg";

import repetir from "../../assets/img/gestion/repetir.svg";
import finalizar from "../../assets/img/gestion/finalizar.svg";
import agendar from "../../assets/img/gestion/agendar.svg";
import derivar from "../../assets/img/gestion/derivar.svg";

import FinalizarCaso from "./FinalizarCaso";
import ModalAgendarHora from "./ModalAgendar";
import Telefonos from "./Telefonos";

import DetalleHoraBloqueada from "./../../components/Usuarios/DetalleHoraBloqueada";

import Tooltip from "@material-ui/core/Tooltip";

// estilos
import "../../assets/css/horasres.css";
import "./../../assets/css/bloqueos.css";
import "react-confirm-alert/src/react-confirm-alert.css";

export default class TablaHoras extends Component {
  constructor(props) {
    super(props);

    this.state = {
      horas_cam: [],

      canal: 5,

      cesfam: store.getState().cesfam,
      uspId: store.getState().usp,

      age_id: null,

      modalCaso: false,
      modalReactivar: false,
      modalHoraBloqueada: false,
      telefonos: false,
    };
  }

  componentDidMount() {
    this.traerData();
  }

  traerData() {
    let apiUrl = global.config.modulos.bloqueos + "lista_latenc_bloqueos/?cesfam=" + this.state.cesfam + "&filtro=" + this.props.opcion + "&uspId=" + this.state.uspId;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      this.setState({ horas_cam: res.data.latencias });
    });
  }

  mostrarDetalleHoraBloqueada = (age_id) => {
    this.setState({ modalHoraBloqueada: true, age_id: age_id });
  };

  ocultarDetalleHoraBloqueada = () => {
    this.setState({ modalHoraBloqueada: false });
  };

  mostrarAgendar = (latencia, usuario, especialidad, canal) => {
    this.setState({ modalAgendarHora: true, modalCaso: false, latencia: latencia, usuario: usuario, especialidad: especialidad, canal: canal });
  };

  ocultarAgendar = () => {
    this.setState({ modalAgendarHora: false });
    this.traerData();
  };

  finalizarCaso = (latencia) => {
    this.setState({ finalizarCaso: true, latencia: latencia });
  };

  cerrarFinalizarCaso = () => {
    this.setState({ finalizarCaso: false });
    this.traerData();
  };

  telefonos = (usuario) => {
    this.setState({ telefonos: true, usuario: usuario });
  };

  cerrarTelefonos = () => {
    this.setState({ telefonos: false });
  };

  confirmarTomarCaso = (latencia) => {
    confirmAlert({
      title: "",
      message: "Seguro que desea tomar este caso?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          className: "btnAceptar",
          onClick: () => {
            this.ingresarCaso(latencia, 1);
          },
        },
      ],
    });
  };

  ingresarCaso(latencia, estado) {
    var data = {
      lbc_estado: estado,
      lbc_latencia: latencia,
      lbc_usuario_panel: this.state.uspId,
    };
    let apiUrl = global.config.modulos.bloqueos + "tomar_caso/";
    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      if (estado == 1) {
        NotificationManager.info("Caso tomado correctamente");
      } else if (estado == 4) {
        NotificationManager.info("Caso reactivado correctamente");
      }
      this.traerData();
    });
  }

  render() {
    return (
      <>
        <div className="historialHoras">
          <Grid container spacing={2} className="scroll-container">
            <Grid item xs={12} id="tablaBloqueos">
              <MaterialTable
                title=""
                columns={[
                  {
                    title: "Nombre profesional",
                    headerStyle: { fontSize: "16px" },
                    field: "hr_prof_nom",
                    render: (rowData) => (
                      <p>
                        {rowData.hr_prof_nom} {rowData.hr_prof_apellido_p}
                      </p>
                    ),
                  },
                  {
                    title: "RUT paciente",
                    headerStyle: { fontSize: "16px" },
                    field: "hr_rut_pac",
                    render: (rowData) => <p>{chileanRut.format(rowData.hr_rut_pac, true)}</p>,
                  },
                  {
                    title: "Nombre Paciente",
                    headerStyle: { fontSize: "16px" },
                    field: "hr_nom_pac",
                    render: (rowData) => (
                      <Tooltip title="Ver datos personales y registros de canales" aria-label="Ver datos personales y registros de canales">
                        <p style={{ cursor: "pointer" }} onClick={() => this.telefonos(rowData.hr_id_pac)}>
                          {rowData.hr_nom_pac} {rowData.hr_ape_pac}
                        </p>
                      </Tooltip>
                    ),
                  },
                  {
                    title: "Sector",
                    headerStyle: { fontSize: "16px" },
                    field: "hr_sector",
                  },
                  {
                    title: "Tipo de atención",
                    headerStyle: { fontSize: "16px" },
                    field: "hr_ate",
                    render: (rowData) => (
                      <>
                        <p>{rowData.hr_ate}</p>
                        <p>{rowData.hr_rend}</p>
                      </>
                    ),
                  },
                  {
                    title: "Hora de Atencion",
                    headerStyle: { fontSize: "16px" },
                    field: "hr_hora_at",
                    defaultSort: "asc",
                    render: (rowData) => <p>{moment(rowData.hr_hora_at).format("DD-MM-YYYY HH:mm:ss")} hrs</p>,
                  },
                  {
                    title: "Reagendada",
                    headerStyle: { fontSize: "16px" },
                    hidden: this.props.opcion == 4 ? false : true,
                    field: "hr_reagendada",
                    render: (rowData) => <p>{moment(rowData.hr_reagendada).format("DD-MM-YYYY HH:mm:ss")} hrs</p>,
                  },
                  {
                    title: "Detalle",
                    hidden: this.props.opcion != 0 ? false : true,
                    headerStyle: { fontSize: "16px" },
                    render: (rowData) => (
                      <>
                        <Tooltip title="Ver Detalle cambio de hora" aria-label="" style={{ marginRight: 2 }}>
                          <img className="mediumIcon" style={{ marginTop: 0, marginRight: 5 }} onClick={this.mostrarDetalleHoraBloqueada.bind(this, rowData.hr_agenda_blo)} src={iconoVer} />
                        </Tooltip>
                      </>
                    ),
                  },
                  {
                    title: "Acciones",
                    hidden: [1, 2, 3, 6].includes(this.props.opcion) ? false : true,
                    render: (rowData) =>
                      this.props.opcion == 3 ? (
                        <React.Fragment>
                          <Tooltip title="Finalizar el caso" aria-label="Finalizar el caso">
                            <img className="mediumIcon" src={finalizar} onClick={() => this.finalizarCaso(rowData.hr_bloq_id)} />
                          </Tooltip>
                          <Tooltip title="Reservar una hora médica" aria-label="Reservar una hora médica">
                            <img className="mediumIcon" src={agendar} onClick={() => this.mostrarAgendar(rowData.hr_bloq_id, rowData.hr_id_pac, rowData.lab_especialidad, rowData.lab_canal)} />
                          </Tooltip>
                        </React.Fragment>
                      ) : this.props.opcion == 6 ? (
                        <React.Fragment>
                          <Tooltip title="Reactivar" aria-label="Reactivar">
                            <img className="mediumIcon" src={repetir} onClick={() => this.ingresarCaso(rowData.hr_bloq_id, 4)} />
                          </Tooltip>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Tooltip title="Tomar caso" aria-label="Tomar caso">
                            <img className="mediumIcon" src={derivar} onClick={() => this.confirmarTomarCaso(rowData.hr_bloq_id)} />
                          </Tooltip>
                        </React.Fragment>
                      ),
                  },
                ]}
                data={this.state.horas_cam}
                options={{
                  actionsColumnIndex: -1,
                  sorting: true,
                  search: false,
                  toolbar: false,
                }}
                localization={{
                  header: {
                    actions: "a",
                  },
                  toolbar: {
                    searchPlaceholder: "Buscar",
                    searchTooltip: "Buscar",
                    exportTitle: "Exportar",
                    exportAriaLabel: "Exportar",
                    exportCSVName: "Exportar como CSV",
                    exportPDFName: "Exportar como PDF",
                  },
                  body: {
                    emptyDataSourceMessage: "No se encuentran horas cambiadas para este cesfam",
                  },
                  pagination: {
                    labelRowsSelect: "Filas",
                    labelDisplayedRows: "{from}-{to} de {count}",
                  },
                }}
              ></MaterialTable>
            </Grid>
          </Grid>
        </div>
        <>
          {this.state.modalAgendarHora ? <ModalAgendarHora ocultar={this.ocultarAgendar} latencia={this.state.latencia} usuario={this.state.usuario} especialidad={this.state.especialidad} canal={this.state.canal}></ModalAgendarHora> : null}
          {this.state.modalHoraBloqueada ? <DetalleHoraBloqueada ocultar={this.ocultarDetalleHoraBloqueada} agenda={this.state.age_id}></DetalleHoraBloqueada> : null}
          {this.state.finalizarCaso ? <FinalizarCaso ocultar={this.cerrarFinalizarCaso} latencia={this.state.latencia} /> : null}
          {this.state.telefonos ? <Telefonos ocultar={this.cerrarTelefonos} usuario={this.state.usuario} /> : null}
        </>
      </>
    );
  }
}
