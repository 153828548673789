import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import moment from "moment";

// CSS
import "./../../assets/css/inicio.css";

export default class Contador extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fecha: props.fecha,
      tiempo_transcurrido: 0,
    };
  }

  componentDidMount() {
    var then = this.state.fecha;

    setInterval(() => {
      var now = moment().format("YYYY-MM-DD HH:mm:ss");
      var then = this.state.fecha;
      var diff = moment.utc(moment(now, "YYYY-MM-DD HH:mm:ss").diff(moment(then, "YYYY-MM-DD HH:mm:ss"))).format("HH:mm:ss");
      console.log(diff);
      this.setState({
        tiempo_transcurrido: diff,
      });
    }, 1000);
  }

  render() {
    return <div>{this.state.tiempo_transcurrido}</div>;
  }
}
