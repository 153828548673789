import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";

// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class AyudaMunicipalAgregar extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: parseInt(store.getState().usp),
      open: true,
      usuario: props.usuario,
      ayuda: "",
      fecha: "",
      gestion: "",
      ayudas: [],
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  validarDatos = () => {
    let respuesta = { estado: true, mensaje: "" };

    if (this.state.fecha == "") {
      respuesta["estado"] = false;
      respuesta["mensaje"] = "Selecciona una fecha";
    } else if (this.state.gestion == "") {
      respuesta["estado"] = false;
      respuesta["mensaje"] = "Selecciona una gestión";
    } else if (this.state.ayuda == "") {
      respuesta["estado"] = false;
      respuesta["mensaje"] = "Selecciona una ayuda";
    }

    return respuesta;
  };

  guardarAyudaMunicipal = () => {
    let datosValidados = this.validarDatos();
    if (!datosValidados["estado"]) {
      return NotificationManager.warning(datosValidados["mensaje"]);
    }

    axios
      .post(
        global.config.modulos.gestion + "ayuda_municipal/",
        {
          fecha: this.state.fecha,
          ayuda: this.state.ayuda,
          gestion: this.state.gestion,
          usuario: this.state.usuario,
          usuario_panel: this.state.uspId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Ayuda guardada con éxito");
          this.salir();
        }
      });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Agregar Ayuda Municipal</h2>
                </GridItem>

                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <select name="ayuda" value={this.state.ayuda} onChange={this.cambiarValores} className="estiloSelect">
                    <option value="">Seleccione la ayuda</option>
                    <option value="Alimentos">Alimentos</option>
                    <option value="Área de Salud">Área de Salud</option>
                    <option value="Llamada de acompañamiento">Llamada de acompañamiento</option>
                    <option value="Pañales">Pañales</option>
                    <option value="Red de protección del adulto mayor">Red de protección del adulto mayor</option>
                    <option value="Traslados">Traslados</option>
                    <option value="Vale de gas">Vale de gas</option>
                  </select>
                </GridItem>
                <GridItem xs={6} style={{ marginTop: 20 }}>
                  <input name="fecha" value={this.state.fecha} onChange={this.cambiarValores} type="date"></input>
                </GridItem>
                <GridItem xs={6} style={{ marginTop: 20 }}>
                  <select name="gestion" value={this.state.gestion} onChange={this.cambiarValores} className="estiloSelect">
                    <option value="">Seleccione la gestión</option>
                    <option value="En gestión">En gestión</option>
                    <option value="Entregado">Entregado</option>
                  </select>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <Button onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                    Cerrar
                  </Button>
                  <Button onClick={this.guardarAyudaMunicipal} color="primary" className="fondoVerde">
                    Guardar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
