import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "./../../store/index";
import axios from "axios";

// core components
import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

// estilos
import "assets/css/general.css";
import "assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import play from "../../assets/img/usuarios/play.svg";
import pause from "../../assets/img/usuarios/pause.svg";
import audio_salusin from "../../assets/img/usuarios/audio_salusin.svg";

export default class Motivo extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      open: true,

      audio: props.audioMotivo ? "http://200.111.252.138/grabaciones/" + props.audioMotivo : null,
      gegId: props.gegId,
      audioMotivo: props.audioMotivo,
      audioMotivoInicio: props.audioMotivoInicio,
      audioMotivoTermino: props.audioMotivoTermino,
      textoMotivo: props.textoMotivo,
      usuId: props.usuId,
      cuestionario: props.cuestionario,
      codigoCuestionario: props.codigoCuestionario,

      paused: false,
      porcentajeCarga: 0,
      duracion: 0,
      tiempoActual: 0,
      respuestas: [],
    };
  }

  componentDidMount() {
    if (this.state.audio) {
      let id = this.makeid(5);
      this.decargarAudio(this.state.audio, id);
    }
    this.traerRespuestas();
  }

  traerRespuestas = () => {
    axios
      .get(global.config.modulos.cuestionarios + "obtener_respuestas_paciente/", {
        headers: authHeader(),
        params: {
          usuario: this.state.usuId,
          cuestionario: this.state.cuestionario,
          codigo_cuestionario: this.state.codigoCuestionario,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ respuestas: res.data.datos });
        }
      });
  };

  decargarAudio = (url, registro) => {
    let urlCortada = url.replace("http://200.111.252.138/grabaciones/", "");
    axios
      .get(global.config.modulos.parametros + "descargar_audio/", {
        headers: authHeader(),
        params: { url: urlCortada, registro: registro },
      })
      .then((res) => {
        this.setState({ audio: new Audio(res.data) });
      });
  };

  makeid = (length) => {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  reproducirAudio = () => {
    let audio = this.state.audio;
    let inicio = this.state.audioMotivoInicio;
    let termino = this.state.audioMotivoTermino;

    if (!audio.paused) {
      audio.pause();
      this.setState({ paused: false });
      return;
    } else if (audio.currentTime) {
      audio.play();
      this.setState({ paused: true });
      return;
    }
    this.setState({ duracion: Math.trunc(audio.duration) });

    if (inicio) {
      audio.currentTime = inicio;
    }
    audio.play();
    let duracion = audio.duration;
    let calculoAumento = audio.duration;
    this.setState({ paused: true });

    let x = 0;
    let current = 0;
    if (inicio) {
      let tiempoTermino = termino;
      x = inicio + 1;
      current = inicio;
      duracion = tiempoTermino;
      calculoAumento = tiempoTermino - inicio;
      calculoAumento = calculoAumento - 1;
      this.setState({ duracion: calculoAumento });
    }

    let aumento = 100 / calculoAumento;
    let porcent = 0;
    let tiempoInicio = 0;

    let listen = audio.addEventListener("timeupdate", () => {
      current = Math.trunc(audio.currentTime);

      if (current >= x) {
        x++;
        tiempoInicio++;
        porcent = porcent + aumento;
        this.setState({ porcentajeCarga: porcent, tiempoActual: tiempoInicio });
      }
      if (current >= Math.trunc(duracion)) {
        audio.pause();
        this.setState({ paused: false });
        audio.currentTime = 0;
        this.setState({ porcentajeCarga: 0, tiempoActual: 0 });
      }
    });
  };
  handleProgressClick = (event) => {
    let audio = this.state.audio;
    let inicio = this.state.audioMotivoInicio;
    let termino = this.state.audioMotivoTermino;

    const barraProgreso = event.currentTarget;
    const barraProgresoWidth = barraProgreso.offsetWidth;
    const clickX = event.nativeEvent.offsetX;

    const clickPercentage = clickX / barraProgresoWidth;

    const newTime = inicio + clickPercentage * (termino - inicio);
    audio.currentTime = newTime;

    const relativeTime = newTime >= inicio ? newTime - inicio : 0;

    this.setState({
      tiempoActual: Math.trunc(relativeTime),
      porcentajeCarga: clickPercentage * 100,
    });

    audio.addEventListener("timeupdate", () => {
      const currentTime = audio.currentTime;
      const relativeTime = currentTime >= inicio ? currentTime - inicio : 0;
      const porcentajeCarga = ((currentTime - inicio) / (termino - inicio)) * 100;

      this.setState({
        tiempoActual: Math.trunc(relativeTime),
        porcentajeCarga: porcentajeCarga,
      });

      if (currentTime >= termino) {
        audio.pause();
        audio.currentTime = inicio;
        this.setState({ paused: true, porcentajeCarga: 0, tiempoActual: 0 });
      }
    });
  };

  salir = () => {
    if (this.state.audio) {
      let audio = this.state.audio;

      if (!audio.paused) {
        audio.pause();
        this.setState({ paused: false });
      }
    }

    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="escucharAudio">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  {this.state.respuestas.map((res) => {
                    if (res.cup_tipo != 2) {
                      return (
                        <React.Fragment>
                          <GridItem xs={12}>
                            <h3>Preguntas</h3>
                            <br />
                            <br />
                          </GridItem>
                          <p style={{ textAlign: "left", fontWeight: "bold" }}>{res.cup_pregunta}</p>
                          <br />
                          <p style={{ textAlign: "left" }}>{res.respuesta.texto_respuesta}</p>
                          <br />
                          <br />
                          <br />
                        </React.Fragment>
                      );
                    }
                  })}
                  {this.state.audioMotivo ? (
                    <React.Fragment>
                      <GridContainer className="agendar-container">
                        <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
                          <img className="marca-agua"></img>
                          <GridContainer className="agendar-container">
                            <GridItem xs={12}>
                              <h2>Escuchar audio</h2>
                              <p className="bordeAudio" style={{ marginTop: 20 }}>
                                <GridContainer className="agendar-container">
                                  <GridItem xs={3}>
                                    <div className="bordeAudio" style={{ padding: 3 }}>
                                      <img src={audio_salusin}></img>
                                    </div>
                                  </GridItem>
                                  <GridItem
                                    xs={9}
                                    style={{
                                      display: "block",
                                      alignContent: "center",
                                      paddingLef: "0px !important",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div>
                                        <p style={{ textAlign: "center" }}>
                                          <a style={{ cursor: "pointer" }} onClick={(e) => this.reproducirAudio()}>
                                            {!this.state.paused ? (
                                              <img
                                                style={{
                                                  width: 60,
                                                  paddingRight: 10,
                                                }}
                                                src={play}
                                              ></img>
                                            ) : (
                                              <img
                                                style={{
                                                  width: 60,
                                                  paddingRight: 10,
                                                }}
                                                src={pause}
                                              ></img>
                                            )}
                                          </a>
                                        </p>
                                      </div>
                                      <div
                                        style={{
                                          width: "75%",
                                          height: "5px",
                                          backgroundColor: "rgb(167 159 159)",
                                          borderRadius: "5px",
                                          position: "relative",
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                          this.handleProgressClick(e);
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: this.state.porcentajeCarga + "%",
                                            height: "5px",
                                            backgroundColor: "#3e3c3c",
                                            borderRadius: "5px",
                                          }}
                                        ></div>
                                        <div>
                                          <label style={{ float: "left" }}>{this.state.tiempoActual}s</label>
                                          <label style={{ float: "right" }}>{this.state.duracion}s</label>
                                        </div>
                                      </div>
                                    </div>
                                  </GridItem>
                                </GridContainer>
                              </p>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {this.state.textoMotivo ? (
                        <div
                          style={{
                            padding: 10,
                            textAlign: "left",
                            borderRadius: 10,
                          }}
                        >
                          <h1>Motivo</h1>
                          <br />
                          <label>{this.state.textoMotivo}</label>
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "rgb(191 91 91 / 70%)",
                            padding: 10,
                            textAlign: "center",
                            borderRadius: 10,
                          }}
                        >
                          No existe motivo ingresado.
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <Button className="fondoNaranjo" onClick={this.salir} style={{ marginLeft: 20, color: "white", float: "right" }}>
                    Cerrar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
