import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { NotificationManager } from "react-notifications";
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5xy from "@amcharts/amcharts5/xy";
import { any } from "@amcharts/amcharts5/.internal/core/util/Array";

export default class GraficosProfesionalesCesfam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fecha_actual: props.fecha_actual,
      fecha_inicio: props.fecha_inicio,
      fecha_termino: props.fecha_termino,
      cliente: props.cliente,
      especialidad: props.especialidad,
      tamaño: 10,
      cesfams: props.cesfams,
    };
  }

  graficosProCesfam() {
    console.log(this.state.cliente);
    this.setState({ datas: [] });
    axios
      .get(global.config.modulos.grafico_demanda + "grafica_profesionales_cesfams/", {
        headers: authHeader(),
        params: {
          fecha_actual: this.state.fecha_actual,
          fecha_inicio: this.state.fecha_inicio,
          fecha_termino: this.state.fecha_termino,
          cliente: this.state.cliente,
          especialidad: this.state.especialidad,
          cesfams: this.state.cesfams,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          NotificationManager.info("No hay datos en el grafico de demanda");
          return;
        }
        this.setState({ datas: res.data.datos }, () => {
          console.log(res.data.datos);
          try {
            this.state.root.dispose();
          } catch (e) {}

          let root = am5.Root.new("chartdiv2");
          root.setThemes([am5themes_Animated.new(root)]);

          root._logo.dispose();

          let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
              panX: false,
              panY: false,
              layout: root.verticalLayout,
              tooltipContainerBounds: {
                top: 50,
                right: 100,
                bottom: 50,
                left: 100,
              },
            })
          );

          var cursor = chart.set(
            "cursor",
            am5xy.XYCursor.new(root, {
              xAxis: xAxis,
            })
          );
          cursor.lineY.set("visible", false);

          let legend = chart.children.push(
            am5.Legend.new(root, {
              centerX: am5.p50,
              x: am5.p50,
            })
          );

          let data = this.state.datas;

          let xRenderer = am5xy.AxisRendererX.new(root, {
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
            minGridDistance: 20,
          });

          xRenderer.labels.template.setAll({
            rotation: 0,
            centerY: am5.p50,
            fontSize: 11,
            maxWidth: 70,
            oversizedBehavior: "wrap",
            textAlign: "center",
          });

          let xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
              categoryField: "Nombre",
              renderer: xRenderer,
              tooltip: am5.Tooltip.new(root, {}),
            })
          );

          xRenderer.grid.template.setAll({
            location: 1,
          });

          xAxis.data.setAll(data);

          let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
              renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 1,
              }),
            })
          );

          function makeSeries(name, fieldName, color, tamaño) {
            let series = chart.series.push(
              am5xy.ColumnSeries.new(root, {
                name: name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: fieldName,
                categoryXField: "Nombre",
                fill: am5.color(color),
                seriesTooltipTarget: "bullet",
                maskBullets: false,
                showTooltipOn: "always",
              })
            );

            series.columns.template.setAll({
              tooltipText: "{name}: {valueY}",
              width: am5.percent(90),
              tooltipY: 0,
              tooltip: am5.Tooltip.new(root, {
                labelText: "{name}: {valueY}",
                getStrokeFromSprite: true,
                autoTextColor: false,
                pointerOrientation: "vertical",
              }),
            });

            console.log(series);

            series.bullets.push(function () {
              return am5.Bullet.new(root, {
                locationX: 0.5,
                locationY: 1,
                sprite: am5.Circle.new(root, {
                  radius: tamaño,
                  fill: am5.Color.lighten(series.get("fill"), 0.7),
                }),
              });
            });

            series.bullets.push(function () {
              return am5.Bullet.new(root, {
                locationX: 0.5,
                locationY: 1,
                sprite: am5.Label.new(root, {
                  text: "{valueY}",
                  centerX: am5.percent(50),
                  centerY: am5.percent(50),
                  textAlign: "center",
                  fontSize: 10,
                  populateText: true,
                }),
              });
            });

            series.data.setAll(data);
            series.appear();
            legend.data.push(series);
          }

          if (data.length >= 8) {
            this.setState(
              {
                tamaño: 5,
              },
              () => {
                const colors = ["#FF9327", "#ef4597", "#4fc9ba", "#225093", "#A569BD", "#E74C3C"];

                let i = 0;
                for (const item of data) {
                  const numberOfFields = Object.keys(item).length;
                  for (const key in item) {
                    if (key !== "Nombre" && key !== "gpl_profesional" && key !== "Profesion") {
                      makeSeries(key, key, colors[i % colors.length], this.state.tamaño);
                      i++;
                    }
                  }
                  break;
                }
              }
            );
          } else {
            const colors = ["#FF9327", "#ef4597", "#4fc9ba", "#225093", "#A569BD", "#E74C3C"];

            let i = 0;
            for (const item of data) {
              const numberOfFields = Object.keys(item).length;
              for (const key in item) {
                if (key !== "Nombre" && key !== "gpl_profesional" && key !== "Profesion") {
                  makeSeries(key, key, colors[i % colors.length], this.state.tamaño);
                  i++;
                }
              }
              break;
            }
          }
          let exporting = am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {}),
            pngOptions: {
              quality: 0.8,
              maintainPixelRatio: true,
            },
            htmlOptions: {
              disabled: true,
            },
            pdfdataOptions: {
              disabled: true,
            },
            jsonOptions: {
              disabled: true,
            },
            printOptions: {
              disabled: true,
            },
            pdfOptions: {
              addURL: false,
              quality: 1,
              includeData: true,
            },
            dataSource: data,
            filePrefix: "DatosGraficoComuna",
          });

          chart.appear(1000, 100);
        });
      });
  }

  componentDidMount() {
    this.graficosProCesfam();
  }

  render() {
    return (
      <div>
        <div
          id="chartdiv2"
          style={{
            width: "80%",
            height: "30rem",
            zIndex: 1,
            position: "fixed",
            marginTop: "50px",
            overflowY: "scroll",
            overflowX: "scroll",
            left: "7%",
          }}
        ></div>
      </div>
    );
  }
}
