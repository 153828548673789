import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";

import { Grid, List, ListItem, MenuItem, Select } from "@material-ui/core";

import { store } from "store/index";
import { confirmAlert } from "react-confirm-alert";

// CSS
import "react-confirm-alert/src/react-confirm-alert.css";

export default class Ticket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tot_tipo: props.tot_tipo,
      mensaje_ticket: props.mensaje_ticket,
      tipo_ticket: props.tipo_ticket,
      tam_codigo: props.tam_codigo,
      logo_hs: props.logo_hs,
      logo_comuna: props.logo_comuna,
    };
  }

  componentDidUpdate() {
    if (this.state.mensaje_ticket != this.props.mensaje_ticket) {
      this.setState({
        mensaje_ticket: this.props.mensaje_ticket,
      });
    }
    if (this.state.tam_codigo != this.props.tam_codigo) {
      this.setState({
        tam_codigo: this.props.tam_codigo,
      });
    }
    if (this.state.tipo_ticket != this.props.tipo_ticket) {
      this.setState({
        tipo_ticket: this.props.tipo_ticket,
      });
    }
    if (this.state.logo_hs != this.props.logo_hs) {
      this.setState({
        logo_hs: this.props.logo_hs,
      });
    }
    if (this.state.logo_comuna != this.props.logo_comuna) {
      this.setState({
        logo_comuna: this.props.logo_comuna,
      });
    }
  }

  componentDidMount() {
    this.traer_logos();
  }

  traer_logos = () => {
    const apiUrl = global.config.modulos.admin_totem + "ruta_logo_comuna/";
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      console.log(res.data.logos);
    });
  };

  cambiarLogos = (event) => {
    const data = new FormData();
    data.append("file[]", event[0]);

    const file = event[0];
    const objectURL = URL.createObjectURL(file);

    this.setState({
      nombre_logo: event[0].name,
      urlLogo: objectURL,
    });
  };

  render() {
    return (
      <>
        {this.state.tot_tipo != 3 ? (
          <>
            {this.state.tipo_ticket == 1 ? (
              <div
                style={{
                  backgroundColor: "white",
                  width: "375px",
                  height: "685px",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <img
                    src={this.state.logo_comuna}
                    style={{
                      width: "180px",
                      marginTop: "84px",
                      marginBottom: "10px",
                    }}
                  ></img>

                  <p
                    style={{
                      marginBottom: "10px",
                      fontFamily: "sans-serif",
                      fontWeight: "200",
                      fontSize: "0.8rem",
                    }}
                  >
                    D1182
                  </p>
                  <hr
                    style={{
                      border: "none",
                      borderTop: "1px dashed black",
                      width: "90%",
                      marginLeft: "5%",
                    }}
                  />
                  <h1
                    style={{
                      fontSize: this.state.tam_codigo + "rem",
                      fontWeight: "100",
                    }}
                  >
                    {" "}
                    F-<span style={{ fontFamily: "" }}>000</span>
                  </h1>
                  <hr
                    style={{
                      border: "none",
                      borderTop: "1px dashed black",
                      width: "90%",
                      marginLeft: "5%",
                    }}
                  />
                  <p style={{ marginTop: "5px" }}>Hora de llegada: 23-05-2023 14:01:29</p>
                  <h2
                    style={{
                      fontFamily: " sans-serif",
                      fontWeight: "400",
                      fontSize: "1.8rem",
                    }}
                  >
                    Tiempo aproximado de <br />
                    espera 9.0 minutos
                  </h2>
                  <p style={{ marginTop: "10px", marginBottom: "10px" }}>Vecino(a): </p>
                  <p style={{ width: "90%", marginLeft: "5%" }}>{this.state.mensaje_ticket}</p>
                  <img src={this.state.logo_hs} style={{ width: "332px" }}></img>
                </div>
              </div>
            ) : this.state.tipo_ticket == 4 ? (
              <>
                <div
                  style={{
                    backgroundColor: "white",
                    width: "375px",
                    height: "360px",
                  }}
                >
                  <div style={{ textAlign: "center", marginTop: "25%" }}>
                    <p
                      style={{
                        marginBottom: "10px",
                        fontFamily: "sans-serif",
                        fontWeight: "200",
                        fontSize: "0.8rem",
                      }}
                    >
                      D1182 23-05-2023 14:01:29
                    </p>
                    <h2
                      style={{
                        fontFamily: " sans-serif",
                        fontWeight: "100",
                        fontSize: "0.8rem",
                        marginBottom: "10px",
                      }}
                    >
                      Tiempo aproximado de espera 9.0 minutos
                    </h2>
                    <hr
                      style={{
                        border: "none",
                        borderTop: "1px dashed black",
                        width: "90%",
                        marginLeft: "5%",
                      }}
                    />
                    <h1
                      style={{
                        fontSize: this.state.tam_codigo + "rem",
                        fontWeight: "100",
                      }}
                    >
                      {" "}
                      F-<span style={{ fontFamily: "" }}>000</span>
                    </h1>
                    <hr
                      style={{
                        border: "none",
                        borderTop: "1px dashed black",
                        width: "90%",
                        marginLeft: "5%",
                      }}
                    />

                    <img src={this.state.logo_hs} style={{ width: "222px", marginTop: "10px" }}></img>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  width: "375px",
                  height: "630px",
                  marginRight: "20px",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <img
                    src={this.state.logo_comuna}
                    style={{
                      width: "160px",
                      marginTop: "84px",
                      marginBottom: "10px",
                    }}
                  ></img>
                  <hr
                    style={{
                      border: "none",
                      borderTop: "1px dashed black",
                      width: "90%",
                      marginLeft: "5%",
                    }}
                  />
                  <p style={{ marginTop: "5px", fontSize: "0.9rem" }}>
                    Nombre apellido has anulado tu hora de atencion
                    <br />
                    del dia 07 de junio <br />
                    a las 17:15 hrs <br />
                    Para la consulta Medica <br />
                    con el profesional Nombre Apellido
                  </p>
                  <br />
                  <br />

                  <img src={this.state.logo_hs} style={{ width: "332px" }}></img>
                </div>
              </div>

              <div
                style={{
                  backgroundColor: "white",
                  width: "375px",
                  height: "885px",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <img
                    src={this.state.logo_comuna}
                    style={{
                      width: "180px",
                      marginTop: "84px",
                      marginBottom: "10px",
                    }}
                  ></img>
                  <hr
                    style={{
                      border: "none",
                      borderTop: "1px dashed black",
                      width: "90%",
                      marginLeft: "5%",
                    }}
                  />
                  <p style={{ marginTop: "5px", marginBottom: "10px" }}>Hora de llegada: 23-05-2023 14:01:29</p>
                  <h2
                    style={{
                      fontFamily: " sans-serif",
                      fontWeight: "100",
                      fontSize: "1rem",
                      textAlign: "center",
                      marginLeft: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    Su hora ha quedado reservada para <br />
                    el dia 00 del mes <br />
                    a las 00:00 hrs <br />
                    con el profesional Nombre Apellido <br />
                    <br />
                    ¡¡¡Recuerde!!! <br />
                    Llegar 15 minutos antes de <br />
                    la hora asignada
                    <br />
                    Y si no puede asistir, anula
                    <br />
                    llamando al 22 605 xxxx <br />o descarga la aplicacion
                  </h2>

                  <img src={"https://panelv2hs.cl/archivos/totem/publicidad/totem/tickets/app_qr.png"} style={{ width: "332px" }}></img>

                  <img src={this.state.logo_hs} style={{ width: "332px" }}></img>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
