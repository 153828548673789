import { store } from "./../../store/index";
export default function AuthHeader() {
  const token = localStorage.getItem("token");
  if (token) {
    //return { "x-access-token": token };
    return { Authorization: "Bearer " + token };
  } else {
    return {};
  }
}
