import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AgendarHora from "./../../components/Usuarios/AgendarHora.js";
import AgendarHoraOpciones from "./../../components/Usuarios/AgendarHoraOpciones.js";
import { store } from "./../../store/index";

// estilos
import "../../assets/css/usuarios.css";

export default class ModalAgendarHora extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usuId: props.usuario,
      ciclo: null,
      open: true,
      herramientas: store.getState().herramientas,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="agendarHora">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer className="agendar-container">
                <GridItem xs={12} sm={12} md={12}>
                  <h2>Reservar hora</h2>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <AgendarHoraOpciones usuario={this.state.usuId} ocultar={this.salir}></AgendarHoraOpciones>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
