import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// CSS
import "./../../assets/css/inicio.css";

export default class MenuTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: props.modal,
    };
  }

  render() {
    return (
      <div style={{ float: "left", marginLeft: 20, height: 38, width: "100%" }}>
        <span style={this.state.modal == 1 ? { backgroundColor: "#b5711c" } : {}} className="pestaniaMenu" onClick={() => this.props.cambiarVentana(1)}>
          Ingresados
        </span>
        <span
          style={
            this.state.modal == 2
              ? {
                  backgroundColor: "#b5711c",
                }
              : {}
          }
          className="pestaniaMenu"
          onClick={() => this.props.cambiarVentana(2)}
        >
          Confirmados
        </span>
        <span style={this.state.modal == 3 ? { backgroundColor: "#b5711c" } : {}} className="pestaniaMenu" onClick={() => this.props.cambiarVentana(3)}>
          Anulados
        </span>
        <span
          style={
            this.state.modal == 7
              ? {
                  backgroundColor: "#b5711c",
                }
              : {}
          }
          className="pestaniaMenu"
          onClick={() => this.props.cambiarVentana(7)}
        >
          Suspendidos
        </span>
        <span
          style={
            this.state.modal == 4
              ? {
                  backgroundColor: "#b5711c",
                }
              : {}
          }
          className="pestaniaMenu"
          onClick={() => this.props.cambiarVentana(4)}
        >
          No inscritos en HS
        </span>
        <span
          style={
            this.state.modal == 5
              ? {
                  backgroundColor: "#b5711c",
                }
              : {}
          }
          className="pestaniaMenu"
          onClick={() => this.props.cambiarVentana(5)}
        >
          No contestados
        </span>
        <span
          style={
            this.state.modal == 8
              ? {
                  backgroundColor: "#b5711c",
                }
              : {}
          }
          className="pestaniaMenu"
          onClick={() => this.props.cambiarVentana(8)}
        >
          No existe
        </span>
        <span
          style={
            this.state.modal == 6
              ? {
                  backgroundColor: "#b5711c",
                }
              : {}
          }
          className="pestaniaMenu"
          onClick={() => this.props.cambiarVentana(6)}
        >
          Histórico
        </span>
      </div>
    );
  }
}
