import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";

import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert"; // Import

import Cargando from "components/Helpers/Cargando.js";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Buttons from "react-multi-date-picker/components/button";

// estilos
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class MotivoReserva extends Component {
  constructor(props) {
    super(props);

    this.state = {
      motivo: "",
      profesional: "",
      esp_nombre: "",

      cesfam: store.getState().cesfam,
      uspId: store.getState().usp,

      horario: props.horarioReserva,
      usuId: props.usuarioReserva,
      motivos: props.motivos,
      tipo: props.tipoReserva,

      canal: 5,

      profesionales: [],

      formErrors: {},
      open: true,
    };

    this.traerProfesionales();

    if (props.tipoReserva == 2) {
      this.traerEspecialidad();
    }
  }

  traerEspecialidad() {
    const apiUrl = global.config.modulos.agendamientos + "cambiar_visual_horario/";
    axios
      .get(apiUrl, {
        params: { horario: this.state.horario },
        headers: authHeader(),
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            esp_nombre: res.data.esp_nombre,
          });
        }
      });
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  salirAgendar = () => {
    this.setState({
      open: false,
    });
    this.props.ocultarAgendar();
  };

  validaciones() {
    const { motivo, profesional } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (motivo == "" || motivo == 0) {
      formIsValid = false;

      formErrors["motivoErr"] = this.state.tipo == 1 ? "Selecciona el motivo." : "Ingresa la observación";
    }

    if (motivo == 4) {
      if (profesional == "" || profesional == 0) {
        formIsValid = false;
        formErrors["profErr"] = "Selecciona el profesional.";
      }
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  confirmarEnviarFormulario = (e) => {
    if (this.validaciones()) {
      confirmAlert({
        title: "Estás ingresando esta observación?",
        message: this.state.motivo,
        buttons: [
          {
            label: "Editar texto",
            className: "btnCancelar",
          },
          {
            label: "Reservar",
            className: "btnAceptar",
            onClick: () => {
              this.enviarFormulario();
            },
          },
        ],
      });
    }
  };

  enviarFormulario = (e) => {
    if (this.validaciones()) {
      let apiUrl = global.config.modulos.agendamientos + "registrar_motivos_reserva/";
      const options = {
        method: "POST",
        data: {
          horId: this.state.horario,
          usuId: this.state.usuId,
          uspId: this.state.uspId,
          motivo_reserva: this.state.motivo,
          profesional: this.state.profesional,
          tipo: this.state.tipo,
        },
        headers: authHeader(),
      };
      axios(apiUrl, options).then((res) => {
        if (res.data.estado) {
          this.salirAgendar();
        }
      });
    }
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  traerProfesionales() {
    const apiUrl = global.config.modulos.profesionales + this.state.cesfam;
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            profesionales: res.data.datos,
          });
        }
      });
  }

  render() {
    const { motivoErr, profErr } = this.state.formErrors;

    const cambioMotivo = (event) => {
      if (event.target.value == "4") {
        this.setState({
          motivo: event.target.value,
          mostrarProfesionales: true,
        });
      } else {
        this.setState({
          motivo: event.target.value,
          mostrarProfesionales: false,
        });
      }
    };

    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="anularHora">
          <DialogContent>
            <GridContainer className="agendar-container">
              <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
                <img className="marca-agua"></img>
                <GridContainer className="agendar-container">
                  <GridItem xs={12}>{this.state.tipo == 1 ? <h2>Por favor seleccionar el motivo de reserva de esta hora de atención:</h2> : <h2>¿Deseas ingresar una observación para {this.state.esp_nombre}?</h2>}</GridItem>
                  <GridItem xs={12} style={{ marginTop: 30 }}>
                    {this.state.tipo == 1 ? (
                      <Select id="motivo" name="motivo" value={this.state.motivo} onChange={cambioMotivo} displayEmpty>
                        <MenuItem value="" disabled>
                          <em>¿Porqué esta reservando la hora?</em>
                        </MenuItem>

                        {this.state.motivos &&
                          this.state.motivos.map((mb, index) => (
                            <MenuItem value={mb.mrh_id} key={index}>
                              {mb.mrh_motivo}
                            </MenuItem>
                          ))}
                      </Select>
                    ) : (
                      <TextField name="motivo" multiline rows={6} value={this.state.motivo} onChange={this.cambiarValores} style={{ border: "1px solid black", borderRadius: "5px", padding: "7px", width: "100%" }} />
                    )}

                    {motivoErr && <div style={{ color: "red", paddingBottom: 10 }}>{motivoErr}</div>}
                  </GridItem>
                  {this.state.mostrarProfesionales && (
                    <GridItem xs={12} sm={12} md={12} style={{ marginTop: "2vw" }}>
                      <InputLabel>Profesional que derivó*</InputLabel>
                      <Select id="profesional" name="profesional" value={this.state.profesional} onChange={this.cambiarValores} displayEmpty>
                        <MenuItem value="" disabled>
                          <em>Selecciona</em>
                        </MenuItem>

                        {this.state.profesionales &&
                          this.state.profesionales.map((mb, index) => (
                            <MenuItem value={mb.prc_profesional} key={index}>
                              {mb.nombreprofesional}
                            </MenuItem>
                          ))}
                      </Select>
                      {profErr && <div style={{ color: "red", paddingBottom: 10 }}>{profErr}</div>}
                    </GridItem>
                  )}
                  <GridItem xs={12} style={{ marginTop: 30 }}>
                    <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={this.salir} style={{ marginLeft: 20 }}>
                      Cancelar
                    </Button>
                    <Button className="botonDialog fondoVerde" onClick={this.state.tipo == 2 ? this.confirmarEnviarFormulario : this.enviarFormulario} style={{ marginLeft: 20 }}>
                      Ingresar
                    </Button>
                    {this.state.tipo == 2 && (
                      <Button className="botonDialog fondoNaranjo" onClick={this.salirAgendar}>
                        Omitir
                      </Button>
                    )}
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
