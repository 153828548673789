import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Grid from "@material-ui/core/Grid";
import { confirmAlert } from "react-confirm-alert"; // Import
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { NotificationManager } from "react-notifications";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import MultiSelect from "react-multi-select-component";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ThumbDownAltOutlinedIcon from "@material-ui/icons/ThumbDownAltOutlined";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default class PausarCaso extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().confCesfam,
      demanda: props.demanda,
      motivoFin: "",
      motivoLibre: "",
      like: true,
      open: true,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  finalizarCaso = () => {
    let statusLike = this.state.like ? 1 : 0;
    axios
      .patch(
        global.config.modulos.demanda,
        {
          ged_id: this.state.demanda,
          ged_ubicacion: 4,
          ged_satisfecho: statusLike,
          ged_motivo_fin_libre: this.state.motivoLibre,
          ged_motivo_fin: this.state.motivoFin,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        NotificationManager.success("Caso finalizado con éxito!");
        this.salir();
      });
  };

  handleRadioChange = (event) => {
    this.setState({ like: event.target.value });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth id="finalizarDemanda">
        <DialogContent>
          <div>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <h2>Pausar caso</h2>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField id="motivoLibre" name="motivoLibre" label="Motivo de la pausa *La siguiente respuesta será enviada al paciente exactamente como se encuentra escrito" multiline rows={4} rowsMax={2} defaultValue="" fullWidth onChange={this.handleChange} />

                <br />
                <br />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Button style={{ float: "right", marginLeft: 10 }} onClick={this.salir} className="botonSimple fondoRojo">
                  Cerrar
                </Button>
                <Button onClick={this.finalizarCaso} className="botonSimple fondoVerde" style={{ float: "right" }}>
                  Pausar
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
