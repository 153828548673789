import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class ControlSano extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      herramientas: store.getState().herramientas,
      uspId: parseInt(store.getState().usp),
      open: true,
      usuario: props.usuario,
      registros: [],
      limitado: [],
      examenes: [],
    };
  }

  componentDidMount() {
    this.tablaControlSano();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  tablaControlSano = () => {
    axios
      .get(global.config.modulos.ciclos + "secuencia_paciente/", {
        headers: authHeader(),
        params: {
          id_usuario: this.state.usuario,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          var data = res.data.datos;
          var examenes = res.data.examenes;
          if (data.length >= 1 && this.state.herramientas.includes("H72") && examenes.length >= 1) {
            for (let index = 0; index < examenes.length; index++) {
              var examen = { actual: false, agenda: null, color: "#cbdfb4", completado: false, fecha_inicio: examenes[index]["fecha_inicio"], fecha_termino: examenes[index]["fecha_termino"], sen_nombre: examenes[index]["sen_nombre"] };
              data.push(examen);
            }
          }
          this.setState({
            registros: res.data.datos,
            limitado: res.data.limitado,
            examenes: res.data.examenes,
          });
        }
      });
  };

  render() {
    const { registros, examenes, limitado } = this.state;
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="casosRegistrados">
        <DialogContent>
          <GridContainer className="agendar-container">
            {this.state.herramientas.includes("H72") ? (
              <GridItem xs={12} sm={12} md={12} className="">
                <h2>Tabla de Control Sano</h2>
                <GridContainer className="agendar-container">
                  <GridItem xs={12} sm={12} md={12} className="tabla">
                    <table style={{ width: "100%", marginTop: "30px", borderCollapse: "collapse" }}>
                      <thead>
                        <tr>
                          <th style={{ paddingBottom: 10 }}>Nivel</th>
                          <th style={{ paddingBottom: 10 }}>Fecha de inicio</th>
                          <th style={{ paddingBottom: 10 }}>Fecha de término</th>
                        </tr>
                      </thead>
                      <tbody>
                        {registros.map((registro, index) => (
                          <tr key={index} style={{ border: registro.actual ? "#a76f14 3px solid" : "#8d8d8d 1px solid", backgroundColor: registro.color }}>
                            <td>{registro.sen_nombre}</td>
                            <td>{registro.fecha_inicio}</td>
                            <td>{registro.fecha_termino}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </GridItem>

                  <GridItem xs={12} style={{ marginTop: 20 }}>
                    <a onClick={this.salir} style={{ marginLeft: 20 }} className="btnAgregarCaso fondoNaranjo">
                      Aceptar
                    </a>
                  </GridItem>
                </GridContainer>
              </GridItem>
            ) : (
              <>
                <GridItem xs={12} sm={12} md={12} className="">
                  <GridContainer className="agendar-container">
                    {limitado && limitado.length >= 1 && (
                      <GridItem xs={12} sm={12} md={12} className="tabla">
                        <h2>Control Sano</h2>
                        <br></br>
                        {limitado && limitado.length > 1 ? (
                          <>
                            <label style={{ fontWeight: "bold", fontSize: 20 }}>Atención actual: </label>
                            <label style={{ fontSize: 20 }}>Control Sano {limitado[0]["sen_nombre"]}</label>
                            <br />
                            <label style={{ fontWeight: "bold", fontSize: 20 }}>Próxima atención: </label>
                            <label style={{ fontSize: 20 }}>Control Sano {limitado[1]["sen_nombre"]}</label>
                          </>
                        ) : null}
                        {limitado && limitado.length == 1 ? (
                          <>
                            <label style={{ fontWeight: "bold", fontSize: 20 }}>Próxima atención: </label>
                            <label style={{ fontSize: 20 }}>Control Sano {limitado[0]["sen_nombre"]}</label>
                          </>
                        ) : null}
                        <br></br>
                        <br></br>
                      </GridItem>
                    )}
                    <GridItem xs={12} sm={12} md={12} className="">
                      {examenes.map((registro, index) => (
                        <>
                          <h2>{registro.sen_nombre}</h2>
                          <br></br>
                          <label style={{ fontWeight: "bold", fontSize: 20 }}>Próxima atención: </label>
                          <label style={{ fontSize: 20 }}>{registro.fecha_termino}</label>
                        </>
                      ))}
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </>
            )}
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
