import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { NotificationManager } from "react-notifications";
// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

export default class DerivarCaso extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      cesfam: store.getState().cesfam,
      uspId: store.getState().usp,

      open: true,
      derivacion: null,

      idDemanda: props.idDemanda,
      urgencia: props.urgencia,
      tipoDerivacion: 0,

      derivaciones: [],
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidMount() {
    this.configuracion();
  }

  configuracion = () => {
    axios.get(global.config.modulos.demanda + "configuracion_demanda/", { headers: authHeader(), params: { cesfam: this.state.cesfam } }).then((res) => {
      var data = res.data;
      this.setState({ tipoDerivacion: data.datos["gdc_tipo_derivacion"], derivaciones: data.datos["derivaciones"] });
    });
  };

  derivarCaso = () => {
    if (this.state.derivacion === null) {
      return NotificationManager.warning("Seleccione a dónde desea derivar");
    }

    axios
      .patch(
        global.config.modulos.demanda + "derivar_caso/",
        {
          id: this.state.idDemanda,
          derivacion: this.state.derivacion,
          cesfam: this.state.cesfam,
          marcar_derivado: this.state.urgencia,
          usp_deriva: this.state.uspId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        NotificationManager.success("Caso derivado con éxito");
        this.salir();
      });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Derivar caso</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">Derivar a</InputLabel>
                    {this.state.tipoDerivacion == 1 ? (
                      <Select style={{ marginTop: 8, minWidth: 400 }} labelId="demo-simple-select-outlined-label" id="derivacion" name="derivacion" value={this.state.derivacion} onChange={this.cambiarValores} label="Derivar a">
                        {this.state.derivaciones.map((item, index) => {
                          return this.state.cesfamOrigen != item.id ? (
                            <MenuItem key={index} value={item.id}>
                              {item.nombre}
                            </MenuItem>
                          ) : null;
                        })}
                      </Select>
                    ) : this.state.tipoDerivacion == 2 ? (
                      <Select style={{ marginTop: 8, minWidth: 400 }} labelId="demo-simple-select-outlined-label" id="derivacion" name="derivacion" value={this.state.derivacion} onChange={this.cambiarValores} label="Derivar a">
                        {this.state.derivaciones.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.usp_id}>
                              {item.usp_nombre + " " + item.usp_apellido_p}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    ) : null}
                  </FormControl>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <Button id={"modalModificarUsuarioBtnCancelar"} onClick={this.salir} style={{ marginLeft: 20 }} color="primary" className="fondoRojo">
                    Cancelar
                  </Button>
                  <Button style={{ marginLeft: 20 }} color="primary" onClick={this.derivarCaso} className="fondoVerde">
                    Aceptar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
