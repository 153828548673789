import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import moment from "moment";
import "moment/locale/es";
import axios from "axios";
// core components

import GridContainer from "../../components/grid/GridContainer.js";
import GridItem from "../../components/grid/GridItem";
import Titulo from "../../components/titulo/Titulo.js";
import { store } from "../../store/index";
import { Grid, List, ListItem } from "@material-ui/core";
import { ReactComponent as SalusinFeliz } from "../../assets/img/salusin/salusinfeliz.svg";
import { Input, InputLabel } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import GraficoApp from "./GraficoApp.js";

import signo from "./../../assets/img/signo.svg";

// CSS

import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";
import "./../../assets/css/graficos.css";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,

      fechaInicio: "",
      fechaFin: "",

      total: 0,
      totalDes: 0,

      opcion: 1,
    };
  }

  componentDidMount() {
    this.traerDatos(1);
    this.traerDatosDescarga(1);
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Ingresos a la App <span title='Podrá visualizar toda la información relacionada a los pacientes NSP'><img src=" + signo + "></img></span>";
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    if (name == "opcion") {
      this.traerDatos(value);
      this.traerDatosDescarga(value);
    }
  };

  traerDatos = (tipo) => {
    this.setState({
      data: [],
      total: 0,
      showGraf: false,
    });

    var apiUrl = "";
    if (tipo == 1) {
      apiUrl = global.config.modulos.aplicaciones + "usuarios_por_app/";
    } else {
      apiUrl = global.config.modulos.aplicaciones + "usuarios_por_comuna/";
    }

    const options = {
      method: "GET",
      headers: authHeader(),
      params: {
        cesfam: this.state.cesfam,
        fechaInicio: this.state.fechaInicio,
        fechaFin: this.state.fechaFin,
      },
    };
    axios(apiUrl, options).then((res) => {
      var tot = 0;
      var index = 0;
      res.data.map((res) => {
        tot = tot + res.total;
      });
      this.setState({
        data: res.data,
        total: tot,
        showGraf: true,
      });
    });
  };

  traerDatosDescarga = (tipo) => {
    this.setState({
      totalDes: 0,
    });

    var apiUrl = global.config.modulos.aplicaciones + "descargas_app/";

    const options = {
      method: "GET",
      headers: authHeader(),
      params: {
        cesfam: this.state.cesfam,
        fechaInicio: this.state.fechaInicio,
        fechaFin: this.state.fechaFin,
        tipo: tipo,
      },
    };

    axios(apiUrl, options).then((res) => {
      var tot = 0;
      var index = 0;
      res.data.map((res) => {
        tot = tot + 1;
      });
      this.setState({
        totalDes: tot,
      });
    });
  };

  botonFiltrarFechas = () => {
    this.traerDatos(this.state.opcion);
    this.traerDatosDescarga(this.state.opcion);
  };

  render() {
    const fecha_completa = moment().format("YYYY-MM-DD");

    return (
      <div className="middle-area">
        <br></br>
        <br></br>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} md={3}>
                <Select name="opcion" className={"selectSimple"} value={this.state.opcion} style={{ width: "100%" }} onChange={this.cambiarValores}>
                  <MenuItem value={1} key={1}>
                    {"Por Cesfam"}
                  </MenuItem>
                  <MenuItem value={2} key={2}>
                    {"Por Comuna"}
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: "25px" }}>
            <Grid container spacing={12}>
              <Grid item xs={12} sm={3} md={2}>
                <InputLabel>Fecha Inicio</InputLabel>
                <Input className={"calendarios-nsp"} style={{ border: "0px" }} type="date" name="fechaInicio" inputProps={{ max: fecha_completa }} value={this.state.fechaInicio} onChange={this.cambiarValores}></Input>
              </Grid>
              <Grid item xs={12} sm={3} md={2}>
                <InputLabel>Fecha Termino</InputLabel>
                <Input className={"calendarios-nsp"} style={{ border: "0px" }} type="date" name="fechaFin" inputProps={{ max: fecha_completa }} value={this.state.fechaFin} onChange={this.cambiarValores}></Input>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Button className="fondoVerde" onClick={this.botonFiltrarFechas}>
                  Filtrar
                </Button>
              </Grid>
            </Grid>
          </GridItem>
        </GridContainer>

        {this.state.showGraf ? (
          <div class="chartContainer chartGrid">
            <div class="totalArea salusinCont">
              <p class="totalText">Total ingresos: {this.state.total}</p>
              <SalusinFeliz class="salusinGrafico totalSalusin" />
            </div>

            <div class="totalArea salusinCont" style={{ top: "24%" }}>
              <p class="totalText">Total descargas: {this.state.totalDes}</p>
              <SalusinFeliz class="salusinGrafico totalSalusin" />
            </div>

            <div class="chartContainer chartGrid">
              <GraficoApp key={"chart"} data={this.state.data} />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
