import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";

// core components
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Titulo from "../../components/titulo/Titulo.js";
import { confirmAlert } from "react-confirm-alert"; // Import

import Button from "@material-ui/core/Button";

import MaterialTable from "material-table";
import Tooltip from "@material-ui/core/Tooltip";

import icono_reservar from "../../assets/img/usuarios/icono_reservar.svg";
import icono_eliminar from "../../assets/img/usuarios/icono_eliminar.svg";
import icono_revisado from "../../assets/img/gestion/finalizar.svg";

import editar from "../../assets/img/gestion/editar.svg";
import copiar from "../../assets/img/usuarios/copiar.svg";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";

import AgendarHora from "../../components/Agendar/ModalAgendarHora.js";
import AnularHora from "../../components/Usuarios/AnularHora";
import ModalModificarUsuario from "./../../components/Gestion/ModificarDatosUsuario.js";
import { NotificationManager } from "react-notifications";

import signo from "./../../assets/img/signo.svg";

class ListaAnulaciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabla: "",
      anuladas: [],

      cesfam: store.getState().cesfam,
      AgendarHora: false,
      horarioAgendar: "",

      anularHora: false,
      usuId: "",

      nombreProfesional: "",
      nombreEspecialidad: "",

      modalModificarUsuario: false,

      siglasHerramienta: ["H19"],
      herramientas: store.getState().herramientas,
    };
  }

  componentDidMount() {
    this.fetchAnulaciones();
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Horas anuladas <span title=''><img src=" + signo + "></img></span>";
  };

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  }

  fetchAnulaciones = async () => {
    let apiUrl = global.config.modulos.agendamientos + "horas_anuladas_funcionarios_interna/";
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var data = res.data;

      var anuladas = [];

      var estado = data.estado;
      if (estado) {
        var horario = data.datos;
        var conteoHorario = horario.length;
        for (var j = 0; j < conteoHorario; j++) {
          var rendimiento = horario[j].rendimiento + " min";
          var hor_id = horario[j].hor_id;
          var age_id = horario[j].age_id;

          var horAtencion = moment(horario[j]["hor_atencion"]);
          var fechaAtencion = horAtencion.format("DD-MM-YYYY");
          var horaAtencion = horAtencion.format("HH:mm:ss");
          horAtencion = horAtencion.format("YYYY-MM-DD HH:mm:ss");

          var horAnulacion = moment(horario[j]["fecha_anulacion"]);
          var fechaAnulacion = horAnulacion.format("DD-MM-YYYY");
          var horaAnulacion = horAnulacion.format("HH:mm:ss");

          var nombre_profesional = horario[j].nombre_profesional;

          var usu_id = horario[j].usuario;
          var rut_usuario = horario[j].rut_usuario;
          var nombre_usuario = horario[j].nombre_usuario;

          var id_usuario_reserva = horario[j].id_usuario_reserva;
          var rut_usuario_reserva = horario[j].rut_usuario_reserva;
          var nombre_usuario_reserva = horario[j].nombre_usuario_reserva;
          var edad_usuario_reserva = horario[j].edad_usuario_reserva;

          var sec_nombre = horario[j].sec_nombre;
          var esp_nombre = horario[j].esp_nombre;

          var estado_situacion = horario[j].estado_situacion;
          var estado_situacion_nm = horario[j].estado_situacion_nm;

          var anu = {
            rendimiento: rendimiento,
            hor_id: hor_id,
            age_id: age_id,
            horAtencion: horAtencion,
            fechaAtencion: fechaAtencion,
            horaAtencion: horaAtencion,
            fechaAnulacion: fechaAnulacion,
            horaAnulacion: horaAnulacion,
            nombre_profesional: nombre_profesional,
            usu_id: usu_id,
            rut_usuario: rut_usuario,
            nombre_usuario: nombre_usuario,
            sec_nombre: sec_nombre,
            esp_nombre: esp_nombre,
            estado_situacion: estado_situacion,
            estado_situacion_nm: estado_situacion_nm,
            id_usuario_reserva: id_usuario_reserva,
            rut_usuario_reserva: rut_usuario_reserva,
            nombre_usuario_reserva: nombre_usuario_reserva,
            edad_usuario_reserva: edad_usuario_reserva,
            ces_nombre: horario[j].ces_nombre,
          };
          anuladas.push(anu);
        }
      }

      this.setState({
        anuladas: anuladas,
      });
    });
  };

  agendarHora = (horId, esp_nombre, nombre_profesional) => {
    this.setState({
      agendarHora: true,
      horarioAgendar: horId,
      nombreProfesional: nombre_profesional,
      nombreEspecialidad: esp_nombre,
    });
  };

  ocultar_agendar = () => {
    this.setState({ agendarHora: false });
    this.fetchAnulaciones();
  };

  anularHora = (ageId, usuId) => {
    this.setState({
      anularHora: true,
      age_id: ageId,
      usuId: usuId,
    });
  };

  ocultar_anular = () => {
    this.setState({ anularHora: false });
    this.fetchAnulaciones();
  };

  marcarRevisado(ageId) {
    confirmAlert({
      title: "¿Seguro(a) de marcar como revisado este registro?, el registro desaparecerá de la lista",
      message: "",
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
          onClick: () => this.cambiarEstadoAgenda(ageId),
        },
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
      ],
    });
  }

  cambiarEstadoAgenda = (ageId) => {
    var data = {
      age_revisado: 1,
    };
    let apiUrl = global.config.modulos.agendamientos + "agenda_por_id/" + ageId;
    const options = {
      method: "PATCH",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      this.fetchAnulaciones();
    });
  };

  mostrarModalModificarUsuario = (usuario) => {
    this.setState({
      modalModificarUsuario: true,
      usuId: usuario,
    });
  };

  ocultarModalModificarUsuario = () => {
    this.setState({
      modalModificarUsuario: false,
    });
    this.fetchAnulaciones();
  };

  copiarRut = (rut) => {
    navigator.clipboard.writeText(rut);
    NotificationManager.info("Rut copiado");
  };

  render() {
    return (
      <div className="middle-area">
        {this.state.anularHora ? <AnularHora ocultar={this.ocultar_anular} ageid={this.state.age_id} usuId={this.state.usuId}></AnularHora> : null}
        {this.state.agendarHora ? <AgendarHora ocultar={this.ocultar_agendar} horario={this.state.horarioAgendar} nombreEspecialidad={this.state.nombreEspecialidad} nombreProfesional={this.state.nombreProfesional}></AgendarHora> : null}

        <div className="middle-content">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} id="tablaBloqueos">
              <MaterialTable
                title=""
                columns={[
                  { title: "Centro", field: "ces_nombre" },
                  { title: "Nombre profesional", field: "nombre_profesional" },
                  { title: "RUT paciente", field: "rut_usuario" },
                  { title: "Nombre paciente", field: "nombre_usuario" },
                  { title: "Sector", field: "sec_nombre" },
                  {
                    title: "Tipo de atención",
                    field: "esp_nombre",
                    field: "rendimiento",
                    render: (rowData) => (
                      <React.Fragment>
                        <p>{rowData.esp_nombre}</p>
                        <p>{rowData.rendimiento}</p>
                      </React.Fragment>
                    ),
                  },
                  {
                    title: "Hora de atención",
                    field: "fechaAtencion",
                    field: "horaAtencion",
                    defaultSort: "asc",
                    render: (rowData) => (
                      <React.Fragment>
                        <p>{rowData.fechaAtencion}</p>
                        <p>{rowData.horaAtencion}</p>
                      </React.Fragment>
                    ),
                  },
                  {
                    title: "Estado",
                    field: "estado_situacion",
                    render: (rowData) =>
                      rowData.estado_situacion_nm == 1 ? (
                        <React.Fragment>
                          <p className={"estadoAnulacion"} style={{ background: "#f74d4d" }}>
                            {rowData.estado_situacion}
                          </p>
                        </React.Fragment>
                      ) : rowData.estado_situacion_nm == 2 ? (
                        <React.Fragment>
                          <p className={"estadoAnulacion"} style={{ background: "#28b26d" }}>
                            {rowData.estado_situacion}
                          </p>
                        </React.Fragment>
                      ) : rowData.estado_situacion_nm == 3 ? (
                        <React.Fragment>
                          <p className={"estadoAnulacion"} style={{ background: "#0a5441" }}>
                            {rowData.estado_situacion}
                          </p>
                        </React.Fragment>
                      ) : rowData.estado_situacion_nm == 4 ? (
                        <React.Fragment>
                          <p className={"estadoAnulacion"} style={{ background: "#0a5441" }}>
                            <PopupState variant="popover" popupId="demo-popup-popover">
                              {(popupState) => (
                                <div>
                                  <a variant="contained" color="primary" style={{ cursor: "pointer" }} {...bindTrigger(popupState)}>
                                    {rowData.estado_situacion}
                                    <Tooltip title="Copiar RUT del paciente" aria-label="Copiar RUT del paciente">
                                      <img
                                        style={{
                                          width: 18,
                                          float: "inherit",
                                          marginBottom: -4,
                                        }}
                                        className="imagenBotonAccion "
                                        src={copiar}
                                        onClick={() => this.copiarRut(rowData.rut_usuario_reserva)}
                                      />
                                    </Tooltip>
                                  </a>
                                  <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                  >
                                    <Box p={2}>
                                      <Typography>
                                        <p>Nombre: {rowData.nombre_usuario_reserva}</p>
                                        <p>
                                          Edad: {rowData.edad_usuario_reserva ? rowData.edad_usuario_reserva : "0"} años{" "}
                                          <Tooltip title="Editar" aria-label="Editar">
                                            <img className="smallIcon" src={editar} onClick={() => this.mostrarModalModificarUsuario(rowData.id_usuario_reserva)} />
                                          </Tooltip>
                                        </p>
                                      </Typography>
                                    </Box>
                                  </Popover>
                                </div>
                              )}
                            </PopupState>
                          </p>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <p className={"estadoAnulacion"} style={{ background: "#28b26d" }}>
                            {rowData.estado_situacion}
                          </p>
                        </React.Fragment>
                      ),
                  },
                  {
                    title: "Acción",
                    render: (rowData) =>
                      this.state.herramientas.includes(this.state.siglasHerramienta[0]) ? (
                        rowData.estado_situacion_nm == 2 && rowData.horAtencion > moment().format("YYYY-MM-DD HH:mm:ss") ? (
                          <React.Fragment>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                              <img class="imagenBotonReservarHorario" src={icono_reservar} aria-label="Reservar una hora" title="Reservar una hora" onClick={this.agendarHora.bind(this, rowData.hor_id, rowData.esp_nombre, rowData.nombre_profesional)}></img>
                            </div>
                          </React.Fragment>
                        ) : rowData.estado_situacion_nm != 2 ? (
                          <React.Fragment>
                            <Button className="fondoNaranjo" style={{ color: "#fff" }} aria-label="No revisado" title="No revisado" onClick={this.marcarRevisado.bind(this, rowData.age_id)}>
                              Ingresado al Cesfam
                            </Button>
                          </React.Fragment>
                        ) : (
                          <></>
                        )
                      ) : rowData.estado_situacion_nm == 2 ? (
                        rowData.horAtencion > moment().format("YYYY-MM-DD HH:mm:ss") ? (
                          <React.Fragment>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                              <img class="imagenBotonReservarHorario" src={icono_reservar} aria-label="Reservar una hora" title="Reservar una hora" onClick={this.agendarHora.bind(this, rowData.hor_id, rowData.esp_nombre, rowData.nombre_profesional)}></img>
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>{"--"}</React.Fragment>
                        )
                      ) : (
                        <React.Fragment>{"--"}</React.Fragment>
                      ),
                  },
                ]}
                data={this.state.anuladas}
                options={{
                  actionsColumnIndex: -1,
                  search: true,
                  sorting: true,
                }}
                localization={{
                  header: {
                    actions: "Acciones",
                  },
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    labelRowsSelect: "filas",
                    firstTooltip: "primera",
                    previousTooltip: "anterior",
                    nextTooltip: "siguiente",
                    lastTooltip: "última",
                  },
                  toolbar: {
                    nRowsSelected: "{0} fila(s) seleccionadas",
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                  },
                  body: {
                    emptyDataSourceMessage: "No hay horas anuladas próximas a vencer",
                    filterRow: {
                      filterTooltip: "Buscar",
                    },
                  },
                }}
              />
            </GridItem>
            {this.state.modalModificarUsuario ? <ModalModificarUsuario ocultar={this.ocultarModalModificarUsuario} usuId={this.state.usuId}></ModalModificarUsuario> : null}
          </GridContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ListaAnulaciones);
