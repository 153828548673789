import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";

// core components
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TextField from "@material-ui/core/TextField";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import NotificationManager from "react-notifications/lib/NotificationManager";

// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default class AgregarEncuesta extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      cesfams: [],

      encuestaOpciones: [
        { value: "1", label: "No Covid" },
        { value: "2", label: "Covid" },
      ],
      encuestaOpcionesSelect: null,

      encuestaTipoOpciones: [
        { value: "1", label: "Para Cesfam" },
        { value: "2", label: "Para Especialidad" },
      ],
      redirigirOpciones: [
        { value: "0", label: "No redirigir" },
        { value: "1", label: "A pregunta" },
        { value: "2", label: "A Especialidad" },
        { value: "3", label: "A Fin de Encuesta" },
      ],
      especialidadOpciones: [],
      refresh: this.props,
      primeraPregunta: null,

      canalesOpciones: [],
      canalesOpcionesSelect: null,

      encuestaNombre: "",
      encuestaTipoSelect: null,
      edadMin: "",
      edadMax: "",

      primeraPregunta: null,
      preguntas: [],
      numsIvrDisponibles: [
        { label: "1", value: "1", disabled: false },
        { label: "2", value: "2", disabled: false },
        { label: "3", value: "3", disabled: false },
        { label: "4", value: "4", disabled: false },
        { label: "5", value: "5", disabled: false },
        { label: "6", value: "6", disabled: false },
        { label: "7", value: "7", disabled: false },
        { label: "8", value: "8", disabled: false },
        { label: "9", value: "9", disabled: false },
        { label: "0", value: "0", disabled: false },
      ],
      preguntaNueva: "",
      numeroPreguntaNueva: "",
      opcionesNuevas: [],
      maximoOpciones: false,
      preguntaEditando: null,

      encEspSelect: null,

      errorValid: [],

      preguntasNueva: false,
      preguntasVer: false,
      modalNuevaPregunta: false,
      preguntasEditar: false,
      open: true,
    };

    this.preguntaEdit = this.preguntaEdit.bind(this);
    this.actualizarPregunta = this.actualizarPregunta.bind(this);
  }

  componentDidMount() {
    if (this.props.editar) {
      this.setState({ open: false }, () => {
        this.obtenerListaEspecialidades();
        this.obtenerListaCanales();
        this.cargarEncuestaEdit();
      });
    } else {
      this.obtenerListaEspecialidades();
      this.obtenerListaCanales();
    }
  }

  cargarEncuestaEdit() {
    if (this.props.editar) {
      const cue_id = this.props.cue_edit.cue_id;
      let apiUrl = global.config.modulos.cuestionarios + "por_id/" + cue_id;

      axios.get(apiUrl, { headers: authHeader() }).then((res) => {
        /* console.log(res.data); */
        let encuestaOpcSelect = this.state.encuestaOpciones.filter((item) => item.value === res.data.cuestionario.cue_tipo.toString());
        let encuestaTipoSelect = res.data.cesfam.cuc_especialidad ? { value: "2", label: "Para Especialidad" } : { value: "1", label: "Para Cesfam" };
        let canalesOpcionesSelect = this.state.canalesOpciones.filter((item) => item.id === res.data.cesfam.cuc_canal);
        let encEspSelect = this.state.especialidadOpciones.filter((item) => item.id === res.data.cesfam.cuc_especialidad);
        let pregun = [];
        res.data.preguntas.map((preg) => {
          let pregunta = {};
          pregunta["num"] = preg.cup_primera;
          pregunta["label"] = preg.cup_pregunta;
          pregunta["id"] = preg.cup_id;
          pregun.push(pregunta);
        });
        pregun.map((preg) => {
          let opc = res.data.respuestas.filter((item) => item.crp_pregunta === preg.id);
          let opciones = [];
          opc.map((o) => {
            let opcion = {};
            opcion["id"] = o.crp_id;
            opcion["label"] = o.crp_respuesta;
            opcion["numIvr"] = this.state.numsIvrDisponibles.filter((item) => item.value === o.crp_numero_ivr.toString())[0];
            opcion["numIvr"]["disabled"] = true;
            opcion["puntaje"] = o.crp_puntaje;
            opcion["redirect"] = o.crp_redireccion_pregunta ? { value: "1", label: "A pregunta" } : { value: "0", label: "No redirigir" };
            opcion["redirect"] = o.crp_redireccion_especialidad ? { value: "2", label: "A Especialidad" } : opcion["redirect"];
            opcion["redirect"] = o.crp_terminar_cuestionario === 1 ? { value: "3", label: "A Fin de Encuesta" } : opcion["redirect"];
            opcion["redirectHacia"] = o.crp_redireccion_pregunta ? pregun.filter((item) => item.cup_id === o.crp_redireccion_pregunta)[0] : null;
            opcion["redirectHacia"] = o.crp_redireccion_especialidad ? this.state.especialidadOpciones.filter((item) => item.id === o.crp_redireccion_especialidad)[0] : opcion["redirectHacia"];
            opcion["redirectNoHrs"] = o.crp_redireccion_sin_hrs ? pregun.filter((item) => item.cup_id === o.crp_redireccion_sin_hrs)[0] : null;
            opciones.push(opcion);
          });
          /* console.log(opciones); */
          preg["opciones"] = opciones;
        });
        /* console.log(pregun); */
        this.setState(
          {
            encuestaNombre: res.data.cuestionario.cue_nombre,
            encuestaOpcionesSelect: encuestaOpcSelect[0],
            encuestaTipoSelect: encuestaTipoSelect,

            edadMin: res.data.cesfam.cuc_edad_min,
            edadMax: res.data.cesfam.cuc_edad_max,
            canalesOpcionesSelect: canalesOpcionesSelect[0],
            encEspSelect: encEspSelect[0],

            preguntas: pregun,
            primeraPregunta: pregun.filter((item) => item.num === res.data.cesfam.cuc_primera)[0],

            open: true,
          },
          () => {
            this.resetIvr();
          }
        );
      });
    }
  }

  salir = () => {
    this.setState({
      open: false,
      difusionDetalle: false,
    });
    this.props.ocultar();
  };

  resetIvr() {
    this.setState({
      numsIvrDisponibles: [
        { label: "1", value: "1", disabled: false },
        { label: "2", value: "2", disabled: false },
        { label: "3", value: "3", disabled: false },
        { label: "4", value: "4", disabled: false },
        { label: "5", value: "5", disabled: false },
        { label: "6", value: "6", disabled: false },
        { label: "7", value: "7", disabled: false },
        { label: "8", value: "8", disabled: false },
        { label: "9", value: "9", disabled: false },
        { label: "0", value: "0", disabled: false },
      ],
    });
  }

  abrirSegundoModal = () => {
    if (this.validaciones("1")) {
      this.setState({
        open: false,
        preguntasVer: true,
      });
    }
  };

  preguntaNueva = () => {
    /* console.log(this.state); */
    this.setState({
      modalNuevaPregunta: true,
      preguntasVer: false,
    });
  };

  preguntaEdit(p) {
    //let ind = p.num-1;
    this.setState(
      {
        preguntasVer: false,
        preguntaEditando: p,
        preguntaNueva: p.label,
        opcionesNuevas: p.opciones,
      },
      () => {
        /* console.log(this.state.numsIvrDisponibles); */
        this.cargarIvr();
        this.setState({ preguntasEditar: true });
      }
    );
  }

  volverPrimerModal = () => {
    this.setState({
      open: true,
      preguntasVer: false,
    });
  };

  volverSegundoModal = () => {
    this.setState(
      {
        preguntasVer: true,
        modalNuevaPregunta: false,
        preguntasEditar: false,
        preguntaNueva: "",
        numeroPreguntaNueva: "",
        opcionesNuevas: [],
        maximoOpciones: false,
      },
      () => {
        this.resetIvr();
      }
    );
  };

  cambiarTipo = (event, value) => {
    this.setState({ encuestaTipoSelect: value });
  };

  cambiarOpciones = (event, value) => {
    this.setState({ encuestaOpcionesSelect: value });
  };

  cambiarCanal = (event, value) => {
    this.setState({ canalesOpcionesSelect: value });
  };

  cambiarValores = (e) => {
    let { name, value } = e.target;

    const quitarNumeros = ["nombre"];
    if (quitarNumeros.includes(name)) {
      value = value.replace(/[0-9]/g, "");
    }
    const soloNumeros = ["repetir", "edadMin", "edadMax", "numeroPreguntaNueva"];
    if (soloNumeros.includes(name)) {
      value = value.replace(/[^0-9.]/g, "");
    }

    this.setState({ [name]: value });
  };

  obtenerListaCanales = () => {
    let apiUrl = global.config.modulos.difusion + "lista_canales/";

    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      let esp = [];
      let val = 0;
      res.data.canales.map((item) => {
        esp.push({
          label: item.can_nombre,
          val: val,
          id: item.can_id,
        });
        this.setState({ canalesOpciones: esp });
        val++;
      });
    });
  };

  validaciones(modal) {
    /* console.log(this.state); */
    let formErrors = {};
    let validado = true;

    switch (modal) {
      case "1":
        const { encuestaNombre, encuestaTipoSelect, encEspSelect, encuestaOpcionesSelect, edadMax, edadMin, canalesOpcionesSelect } = this.state;

        if (encuestaNombre === "" || encuestaNombre === null) {
          validado = false;
          formErrors["encuestNombreErr"] = "Introduzca un nombre para la encuesta";
        }

        if (encuestaTipoSelect === null) {
          validado = false;
          formErrors["encuestTipoSelectErr"] = "Seleccione un tipo de encuesta";
        }

        if (encuestaTipoSelect && encuestaTipoSelect.value === "2" && encEspSelect === null) {
          validado = false;
          formErrors["encEspSelectErr"] = "Seleccione una especialidad";
        }

        if (edadMin == "") {
          formErrors["edadMinErr"] = "Debe ingresar la edad mínima";
          validado = false;
        }

        if (edadMax == "") {
          formErrors["edadMaxErr"] = "Debe ingresar la edad máxima";
          validado = false;
        }

        if (edadMin > edadMax) {
          formErrors["edadErr"] = "La edad mínima debe ser menor a la edad máxima";
          validado = false;
        }

        if (encuestaOpcionesSelect == null) {
          formErrors["encuestOpcionesSelectErr"] = "Seleccione una opción";
          validado = false;
        }

        if (canalesOpcionesSelect == null) {
          formErrors["canalesOpcionesSelectErr"] = "Seleccione al menos un canal";
          validado = false;
        }

        this.setState({ errorValid: formErrors });
        break;

      case "2":
        const { preguntas, primeraPregunta } = this.state;

        if (preguntas.length < 1) {
          validado = false;
          formErrors["preguntasErr"] = "Debe haber al menos una pregunta";
        }

        if (primeraPregunta == null) {
          validado = false;
          formErrors["primeraPreguntaErr"] = "Debe seleccionar una pregunta";
        }
        this.setState({ errorValid: formErrors });
        break;

      case "3":
        const { preguntaNueva, opcionesNuevas } = this.state;

        /* console.log("pase aqui"); */
        if (preguntaNueva === "" || preguntaNueva === null) {
          validado = false;
          formErrors["preguntaNuevaErr"] = "Introduzca una pregunta";
        }

        if (opcionesNuevas.length < 1) {
          validado = false;
          formErrors["opcionesNuevasErr"] = "Debe haber al menos una opción";
        } else {
          /* console.log("pase aqui tambien"); */
          opcionesNuevas.map((opcion, i) => {
            if (opcion.label === "" || opcion.label === null) {
              validado = false;
              formErrors[`opcionesNuevasNomErr${opcion.value}`] = "Introduzca una opción";
            }

            if (opcion.numIvr === null) {
              validado = false;
              formErrors[`opcionesNuevasIvrErr${opcion.value}`] = "Seleccione un número IVR";
            }

            if (opcion.redirect === null) {
              validado = false;
              formErrors[`opcionesNuevasRedErr${opcion.value}`] = "Seleccione una redirección";
            }

            if (opcion.redirect && (opcion.redirect.value === "1" || opcion.redirect.value === "2") && opcion.redirectHacia === null) {
              validado = false;
              formErrors[`opcionesNuevasRedHaciaErr${opcion.value}`] = "Seleccione un destino de redirección";
            }

            /* if (opcion.puntaje === null){
                validado = false;
                formErrors[`opcionesNuevasPuntajeErr${opcion.value}`] = "Introduzca un puntaje";
              } */
          });
        }
        this.setState({ errorValid: formErrors });
        break;
    }

    return validado;
  }

  enviarCuestionario = (e) => {
    e.preventDefault();
    if (this.validaciones("2")) {
      /* console.log(this.state); */
      var data = {
        cuestionario: {
          cue_tipo: this.state.encuestaOpcionesSelect.value,
          cue_estado: "1",
          cue_fecha_registro: moment().format(),
          cue_nombre: this.state.encuestaNombre,
        },
        preguntas: this.state.preguntas,
        ces_esp: {
          cuc_cesfam: this.state.cesfam,
          cuc_especialidad: this.state.encEspSelect ? this.state.encEspSelect.id : null,
          cuc_edad_min: this.state.edadMin,
          cuc_edad_max: this.state.edadMax,
          cuc_canal: this.state.canalesOpcionesSelect.id,
          cuc_primera: this.state.primeraPregunta.num,
        },
      };
      let apiUrl = global.config.modulos.cuestionarios + "crear_cuestionario/";
      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };
      axios(apiUrl, options).then((res) => {
        /* console.log(res); */
        NotificationManager.info("Encuesta creada correctamente.");
        this.salir();
      });
    }
  };

  actualizarCuestionario = (e) => {
    e.preventDefault();
    if (this.validaciones("2")) {
      /* console.log(this.state.preguntas) */
      var data = {
        cuestionario: {
          cue_tipo: this.state.encuestaOpcionesSelect.value,
          cue_estado: "1",
          cue_nombre: this.state.encuestaNombre,
        },
        preguntas: this.state.preguntas,
        ces_esp: {
          cuc_cesfam: this.state.cesfam,
          cuc_especialidad: this.state.encEspSelect ? this.state.encEspSelect.id : null,
          cuc_edad_min: this.state.edadMin,
          cuc_edad_max: this.state.edadMax,
          cuc_canal: this.state.canalesOpcionesSelect.id,
          cuc_primera: this.state.primeraPregunta.num,
        },
      };
      let apiUrl = global.config.modulos.cuestionarios + "por_id/" + this.props.cue_edit.cue_id;
      const options = {
        method: "PATCH",
        data: data,
        headers: authHeader(),
      };
      axios(apiUrl, options).then((res) => {
        /* console.log(res); */
        NotificationManager.info("Encuesta actualizada correctamente");
        this.salir();
      });
    }
  };

  revisarHorario = () => {
    var data = {
      hor_revisada: 1,
    };
    let apiUrl = global.config.modulos.agendamientos + "horario_por_id/" + this.state.horario;
    const options = {
      method: "PATCH",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      this.salirActualizado();
    });
  };

  mostrarPreguntas() {
    const { preguntas } = this.state;

    const quitarPregunta = (p) => {
      this.setState({ preguntas: preguntas.filter((item) => item != p) });
    };

    return (
      <>
        {preguntas.map(
          (p, index) => (
            <>
              <GridItem xs={12} style={{ marginLeft: 10, marginTop: 20 }}>
                <GridContainer className="">
                  <GridItem xs={1}>
                    <InputLabel>Número</InputLabel>
                    <input type="text" readOnly="readonly" value={p.num} placeholder="" className="inputText" />
                  </GridItem>
                  <GridItem xs={6} style={{ marginLeft: 20 }}>
                    <InputLabel>Pregunta</InputLabel>
                    <input type="text" readonly="readonly" value={p.label} placeholder="" className="inputText" />
                  </GridItem>
                  <GridItem xs={3} style={{ marginTop: 10, marginLeft: 20 }}>
                    <Button
                      style={{ paddingLeft: 20, paddingRight: 20, borderRadius: 8, textAlign: "center" }}
                      className="fondoVerde"
                      onClick={this.preguntaEdit.bind(this, p)}
                      /*onClick={this.enviarDifusion} 
                      onClick={this.subirArchivo.bind(this)} */
                    >
                      Editar
                    </Button>
                  </GridItem>
                  <GridItem xs={1} style={{ marginTop: 10, marginLeft: 5 }}>
                    <Button style={{ marginRight: 4, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={quitarPregunta.bind(this, p)}>
                      Eliminar
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </>
          ),
          this
        )}
      </>
    );
  }

  cambiarOpcText = (o, index, e, value) => {
    let newOpciones = this.state.opcionesNuevas;
    newOpciones[index].label = e.target.value;
    let ivrNums = this.state.numsIvrDisponibles;
    this.setState({ opcionesNuevas: newOpciones });
  };

  obtenerListaEspecialidades = () => {
    let apiUrl = global.config.modulos.difusion + "lista_especialidades_por_cesfam/?cesfam=" + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      let esp = [];
      let val = 0;
      res.data.especialidades.map((item) => {
        esp.push({
          label: item.esp_nombre,
          val: val,
          id: item.esp_id,
        });
        this.setState({ especialidadOpciones: esp });
        val++;
      });
    });
  };

  cambiarPuntaje = (o, index, e) => {
    let { name, value } = e.target;
    value = value.replace(/[^0-9.]/g, "");
    let newOpciones = this.state.opcionesNuevas;
    newOpciones[index].puntaje = value;
    this.setState({ opcionesNuevas: newOpciones });
  };

  cambiarNumIVR = (o, index, event, value) => {
    let newIvr = this.state.numsIvrDisponibles.filter((item) => item.value === value);
    let newNum = value;
    newNum.disabled = true;
    newIvr.push(newNum);
    let prevIvr = this.state.opcionesNuevas[index].numIvr;
    if (prevIvr) {
      newIvr = newIvr.filter((item) => item !== prevIvr);
      prevIvr.disabled = false;
      newIvr.push(prevIvr);
    }
    let newOpciones = this.state.opcionesNuevas;
    newOpciones[index].numIvr = value;
    this.setState({ opcionesNuevas: newOpciones });
  };

  cambiarRedirigir = (o, index, event, value) => {
    let newOpciones = this.state.opcionesNuevas;
    newOpciones[index].redirect = value;
    newOpciones[index].redirectHacia = null;
    this.setState({ opcionesNuevas: newOpciones });
  };

  cambiarDondeRedirigir = (o, index, event, value) => {
    let newOpciones = this.state.opcionesNuevas;
    newOpciones[index].redirectHacia = value;
    this.setState({ opcionesNuevas: newOpciones });
  };

  cambiarRedirigirSinHoras = (o, index, event, value) => {
    let newOpciones = this.state.opcionesNuevas;
    newOpciones[index].redirectNoHrs = value;
    this.setState({ opcionesNuevas: newOpciones });
  };

  cambiarPrimeraPregunta = (o, index, event, value) => {
    this.setState({ primeraPregunta: value.option });
  };

  cambiarEspecialidadTipo = (o, index, event, value) => {
    this.setState({ encEspSelect: value.option });
  };

  cargarIvr() {
    /* console.log("antes de modificar ivr");
    console.log(this.state.numsIvrDisponibles); */
    let newIvr = [];
    this.state.opcionesNuevas.map((o, index) => {
      /* console.log(o.numIvr); */
      if (o.numIvr.disabled) {
        newIvr = this.state.numsIvrDisponibles.filter((item) => item.label != o.numIvr.label);
        newIvr.push(o.numIvr);
        newIvr.sort((a, b) => a.value - b.value);
      }
      this.setState({ numsIvrDisponibles: newIvr });
    });
  }

  guardarPregunta() {
    /* console.log(this.validaciones("3")); */
    if (this.validaciones("3")) {
      const { preguntas } = this.state;
      let newPreguntas = preguntas;
      let min_num = Math.min(...preguntas.map((p) => p.num));
      let num = min_num > 1 ? min_num - 1 : Math.max(...preguntas.map((p) => p.num)) + 1;
      newPreguntas.push({
        label: this.state.preguntaNueva,
        num: this.props.editar ? num : preguntas.length + 1,
        opciones: this.state.opcionesNuevas,
      });
      newPreguntas.sort((a, b) => a.num - b.num);
      this.setState(
        {
          preguntas: newPreguntas,
        },
        () => {
          /* console.log(this.state.preguntas); */
          this.volverSegundoModal();
        }
      );
    }
  }

  actualizarPregunta() {
    if (this.validaciones("3")) {
      const { preguntas, preguntaEditando, preguntaNueva, opcionesNuevas } = this.state;
      let newPreguntas = preguntas;
      /* console.log(newPreguntas);
    console.log(preguntaEditando);
    console.log(preguntaNueva); */
      let indice = preguntaEditando.num - 1;
      newPreguntas[indice].label = preguntaNueva;
      newPreguntas[indice].num = preguntaEditando.num;
      newPreguntas[indice].opciones = opcionesNuevas;
      this.setState(
        {
          preguntas: newPreguntas,
        },
        () => {
          /* console.log(this.state.preguntas); */
          this.volverSegundoModal();
        }
      );
    }
  }

  modalEditarPregunta() {
    const { preguntasEditar, opcionesNuevas, maximoOpciones, errorValid } = this.state;
    const { preguntaNuevaErr, opcionesNuevasErr, opcionesErr } = this.state.errorValid;

    const pregunta = this.state.preguntaEditando;

    const p_index = pregunta.num;

    const agregarOpcion = () => {
      if (!maximoOpciones) {
        this.setState(
          {
            opcionesNuevas: [...this.state.opcionesNuevas, { value: this.state.opcionesNuevas.length + 1, label: "", numIvr: null, redirect: null, redirectHacia: null, puntaje: null, redirectNoHrs: null }],
          },
          () => {
            if (opcionesNuevas.length === 9) {
              this.setState({ maximoOpciones: true });
            }
          }
        );
      }
    };

    const quitarOpcion = (o) => {
      let prevIvr = o.numIvr;
      let newIvr = this.state.numsIvrDisponibles.filter((item) => item.value !== o.numIvr);
      prevIvr.disabled = false;
      newIvr.push(prevIvr);
      newIvr.sort((a, b) => a.value - b.value);
      this.setState({ numsIvrDisponibles: newIvr, opcionesNuevas: this.state.opcionesNuevas.filter((item) => item != o) }, () => {
        if (opcionesNuevas.length < 9) {
          this.setState({ maximoOpciones: false });
        }
      });
    };
    return (
      <Dialog open={preguntasEditar} onClose={this.salir} className="modificarUsuario">
        <DialogContent>
          <GridContainer className="">
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={12} style={{ marginTop: 10, marginLeft: 10 }}>
                  <h1>Editar Pregunta</h1>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={1} style={{ marginTop: 20, marginLeft: 10 }}>
                  <InputLabel>Número</InputLabel>
                  <input type="text" name="numeroPreguntaNueva" value={p_index} readonly="readonly" placeholder="" className="inputText" />
                </GridItem>
                <GridItem xs={3} style={{ marginTop: 20, marginLeft: 10 }}>
                  <InputLabel>Pregunta</InputLabel>
                  <input type="text" name="preguntaNueva" value={this.state.preguntaNueva} onChange={this.cambiarValores} placeholder="" className="inputText" />
                  {preguntaNuevaErr ? <div style={{ color: "red", paddingBottom: 10 }}>{preguntaNuevaErr}</div> : null}
                </GridItem>
                <GridItem xs={6} style={{ marginTop: 30 }}>
                  <Button
                    style={{ marginRight: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }}
                    className="botonesAlineados botonAgregarUsuario fondoVerde"
                    onClick={agregarOpcion}
                    /*onClick={this.enviarDifusion} 
                        onClick={this.subirArchivo.bind(this)} */
                  >
                    Agregar Opción
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
            {opcionesNuevasErr ? (
              <>
                <GridItem xs={12} style={{ marginTop: 15 }}>
                  <GridContainer className="">
                    <GridItem xs={12} style={{ marginTop: 20, marginLeft: 10 }}>
                      <div style={{ color: "red", paddingBottom: 10 }}>{opcionesNuevasErr}</div>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </>
            ) : null}
            {opcionesNuevas.map(
              (o, index) => (
                <>
                  <GridItem xs={12} style={{ marginTop: 15 }}>
                    <GridContainer className="">
                      {/* <GridItem xs={1} style={{ marginTop: 20, marginLeft: 10 }}>
                  <InputLabel>Número</InputLabel>
                      <input
                          type="text"
                          name="numeroPreguntaNueva"
                          value={index+1}
                          readonly="readonly"
                          placeholder=""
                          className="inputText"
                      />
                  </GridItem> */}
                      <GridItem xs={1} style={{ marginTop: 20, marginLeft: 10 }}>
                        <InputLabel>Puntaje</InputLabel>
                        <input type="text" name="numeroPreguntaNueva" value={this.state.opcionesNuevas[index].puntaje} onChange={this.cambiarPuntaje.bind(this, o, index)} placeholder="" className="inputText" />
                        {errorValid[`opcionesNuevasPuntajeErr${o.value}`] ? <div style={{ color: "red", paddingBottom: 10 }}>{errorValid[`opcionesNuevasPuntajeErr${o.value}`]}</div> : null}
                      </GridItem>
                      <GridItem xs={2} style={{ marginTop: 20, marginLeft: 10 }}>
                        <InputLabel>Opción</InputLabel>
                        <input type="text" name="numeroPreguntaNueva" value={this.state.opcionesNuevas[index].label} onChange={this.cambiarOpcText.bind(this, o, index)} placeholder="" className="inputText" />
                        {errorValid[`opcionesNuevasNomErr${o.value}`] ? <div style={{ color: "red", paddingBottom: 10 }}>{errorValid[`opcionesNuevasNomErr${o.value}`]}</div> : null}
                      </GridItem>
                      <GridItem xs={1} style={{ marginTop: 20, marginLeft: 10 }}>
                        <Autocomplete name="ivr" disableClearable id="combo-box-tiempos" options={this.state.numsIvrDisponibles} getOptionDisabled={(option) => option.disabled} getOptionLabel={(option) => option.label} onChange={this.cambiarNumIVR.bind(this, o, index)} value={this.state.opcionesNuevas[index].numIvr} renderInput={(params) => <TextField {...params} label="Núm. IVR" />}></Autocomplete>
                        {errorValid[`opcionesNuevasIvrErr${o.value}`] ? <div style={{ color: "red", paddingBottom: 10 }}>{errorValid[`opcionesNuevasIvrErr${o.value}`]}</div> : null}
                      </GridItem>
                      <GridItem xs={2} style={{ marginTop: 5 }}>
                        <InputLabel>Redirigir</InputLabel>
                        <Autocomplete name="redirigir" disableClearable id="combo-box-tiempos" options={this.state.redirigirOpciones} getOptionLabel={(option) => option.label} onChange={this.cambiarRedirigir.bind(this, o, index)} value={this.state.opcionesNuevas[index].redirect} renderInput={(params) => <TextField {...params} label="Seleccionar redirección" />}></Autocomplete>
                        {errorValid[`opcionesNuevasRedErr${o.value}`] ? <div style={{ color: "red", paddingBottom: 10 }}>{errorValid[`opcionesNuevasRedErr${o.value}`]}</div> : null}
                      </GridItem>
                      {o.redirect && o.redirect.value === "1" ? (
                        <GridItem xs={2} style={{ marginTop: 5 }}>
                          <InputLabel>Redirigir a pregunta</InputLabel>
                          <Autocomplete name="redirigir" disableClearable id="combo-box-tiempos" options={this.state.preguntas} getOptionLabel={(option) => option.label} onChange={this.cambiarDondeRedirigir.bind(this, o, index)} value={this.state.opcionesNuevas[index].redirectHacia} renderInput={(params) => <TextField {...params} label="Seleccionar Pregunta" />}></Autocomplete>
                          {errorValid[`opcionesNuevasRedHaciaErr${o.value}`] ? <div style={{ color: "red", paddingBottom: 10 }}>{errorValid[`opcionesNuevasRedHaciaErr${o.value}`]}</div> : null}
                        </GridItem>
                      ) : null}
                      {o.redirect && o.redirect.value === "2" ? (
                        <>
                          <GridItem xs={2} style={{ marginTop: 5 }}>
                            <InputLabel>Redirigir a especialidad</InputLabel>
                            <Autocomplete name="redirigir" disableClearable id="combo-box-tiempos" options={this.state.especialidadOpciones} getOptionLabel={(option) => option.label} onChange={this.cambiarDondeRedirigir.bind(this, o, index)} value={this.state.opcionesNuevas[index].redirectHacia} renderInput={(params) => <TextField {...params} label="Seleccionar Especialidad" />}></Autocomplete>
                            {errorValid[`opcionesNuevasRedErr${o.value}`] ? <div style={{ color: "red", paddingBottom: 10 }}>{errorValid[`opcionesNuevasRedErr${o.value}`]}</div> : null}
                          </GridItem>
                          <GridItem xs={2} style={{ marginTop: 5 }}>
                            <InputLabel>Redirigir si no hay horas</InputLabel>
                            <Autocomplete name="redirigir" disableClearable id="combo-box-tiempos" options={this.state.preguntas} getOptionLabel={(option) => option.label} onChange={this.cambiarRedirigirSinHoras.bind(this, o, index)} value={this.state.opcionesNuevas[index].redirectNoHrs} renderInput={(params) => <TextField {...params} label="Seleccionar Pregunta" />}></Autocomplete>
                          </GridItem>
                        </>
                      ) : null}
                      <GridItem xs={1} style={{ marginLeft: 15, marginTop: 30 }}>
                        <Button style={{ marginRight: 20, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={quitarOpcion.bind(this, o)}>
                          Eliminar
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </>
              ),
              this
            )}
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={12} style={{ marginTop: 30, display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    style={{ marginRight: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }}
                    className="fondoVerde"
                    onClick={this.actualizarPregunta.bind(this, p_index)}
                    /* onClick={this.abrirSegundoModal}
                onClick={this.enviarDifusion} 
                onClick={this.subirArchivo.bind(this)} */
                  >
                    Guardar
                  </Button>
                  <Button style={{ marginLeft: 5, marginRight: 20, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={this.volverSegundoModal}>
                    Atrás
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }

  modalAgregarPregunta() {
    const { modalNuevaPregunta, opcionesNuevas, maximoOpciones, errorValid } = this.state;
    const { preguntaNuevaErr, opcionesNuevasErr, opcionesErr } = this.state.errorValid;

    const agregarOpcion = () => {
      if (!maximoOpciones) {
        this.setState(
          {
            opcionesNuevas: [...this.state.opcionesNuevas, { value: this.state.opcionesNuevas.length + 1, label: "", numIvr: null, redirect: null, redirectHacia: null, puntaje: null, redirectNoHrs: null }],
          },
          () => {
            if (opcionesNuevas.length === 9) {
              this.setState({ maximoOpciones: true });
            }
          }
        );
      }
    };

    const quitarOpcion = (o) => {
      let prevIvr = o.numIvr;
      let newIvr = this.state.numsIvrDisponibles.filter((item) => item.value !== o.numIvr);
      if (prevIvr) {
        prevIvr.disabled = false;
        newIvr.push(prevIvr);
      }
      newIvr.sort((a, b) => a.value - b.value);
      this.setState({ numsIvrDisponibles: newIvr, opcionesNuevas: this.state.opcionesNuevas.filter((item) => item != o) }, () => {
        if (opcionesNuevas.length < 9) {
          this.setState({ maximoOpciones: false });
        }
      });
    };
    return (
      <Dialog open={modalNuevaPregunta} onClose={this.salir} className="modificarUsuario">
        <DialogContent>
          <GridContainer className="">
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={12} style={{ marginTop: 10, marginLeft: 10 }}>
                  <h1>Agregar Pregunta</h1>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={1} style={{ marginTop: 20, marginLeft: 10 }}>
                  <InputLabel>Número</InputLabel>
                  <input type="text" name="numeroPreguntaNueva" value={this.state.preguntas.length + 1} readonly="readonly" placeholder="" className="inputText" />
                </GridItem>
                <GridItem xs={3} style={{ marginTop: 20, marginLeft: 10 }}>
                  <InputLabel>Pregunta</InputLabel>
                  <input type="text" name="preguntaNueva" value={this.state.preguntaNueva} onChange={this.cambiarValores} placeholder="" className="inputText" />
                  {preguntaNuevaErr ? <div style={{ color: "red", paddingBottom: 10 }}>{preguntaNuevaErr}</div> : null}
                </GridItem>
                <GridItem xs={6} style={{ marginTop: 30 }}>
                  <Button
                    style={{ marginRight: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }}
                    className="botonesAlineados botonAgregarUsuario fondoVerde"
                    onClick={agregarOpcion}
                    /*onClick={this.enviarDifusion} 
                        onClick={this.subirArchivo.bind(this)} */
                  >
                    Agregar Opción
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
            {opcionesNuevasErr ? (
              <>
                <GridItem xs={12} style={{ marginTop: 15 }}>
                  <GridContainer className="">
                    <GridItem xs={12} style={{ marginTop: 20, marginLeft: 10 }}>
                      <div style={{ color: "red", paddingBottom: 10 }}>{opcionesNuevasErr}</div>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </>
            ) : null}

            {opcionesNuevas.map(
              (o, index) => (
                <>
                  <GridItem xs={12} style={{ marginTop: 15 }}>
                    <GridContainer className="">
                      {/* <GridItem xs={1} style={{ marginTop: 20, marginLeft: 10 }}>
                  <InputLabel>Número</InputLabel>
                      <input
                          type="text"
                          name="numeroPreguntaNueva"
                          value={index+1}
                          readonly="readonly"
                          placeholder=""
                          className="inputText"
                      />
                  </GridItem> */}
                      <GridItem xs={1} style={{ marginTop: 20, marginLeft: 10 }}>
                        <InputLabel>Puntaje</InputLabel>
                        <input type="text" name="numeroPreguntaNueva" value={this.state.opcionesNuevas[index].puntaje} onChange={this.cambiarPuntaje.bind(this, o, index)} placeholder="" className="inputText" />
                        {errorValid[`opcionesNuevasPuntajeErr${o.value}`] ? <div style={{ color: "red", paddingBottom: 10 }}>{errorValid[`opcionesNuevasPuntajeErr${o.value}`]}</div> : null}
                      </GridItem>
                      <GridItem xs={2} style={{ marginTop: 20, marginLeft: 10 }}>
                        <InputLabel>Opción</InputLabel>
                        <input type="text" name="numeroPreguntaNueva" value={this.state.opcionesNuevas[index].label} onChange={this.cambiarOpcText.bind(this, o, index)} placeholder="" className="inputText" />
                        {errorValid[`opcionesNuevasNomErr${o.value}`] ? <div style={{ color: "red", paddingBottom: 10 }}>{errorValid[`opcionesNuevasNomErr${o.value}`]}</div> : null}
                      </GridItem>
                      <GridItem xs={1} style={{ marginTop: 20, marginLeft: 10 }}>
                        <Autocomplete name="ivr" disableClearable id="combo-box-tiempos" options={this.state.numsIvrDisponibles} getOptionDisabled={(option) => option.disabled} getOptionLabel={(option) => option.label} onChange={this.cambiarNumIVR.bind(this, o, index)} value={this.state.opcionesNuevas[index].numIvr} renderInput={(params) => <TextField {...params} label="Núm. IVR" />}></Autocomplete>
                        {errorValid[`opcionesNuevasIvrErr${o.value}`] ? <div style={{ color: "red", paddingBottom: 10 }}>{errorValid[`opcionesNuevasIvrErr${o.value}`]}</div> : null}
                      </GridItem>
                      <GridItem xs={2} style={{ marginTop: 5 }}>
                        <InputLabel>Redirigir</InputLabel>
                        <Autocomplete name="redirigir" disableClearable id="combo-box-tiempos" options={this.state.redirigirOpciones} getOptionLabel={(option) => option.label} onChange={this.cambiarRedirigir.bind(this, o, index)} value={this.state.opcionesNuevas[index].redirect} renderInput={(params) => <TextField {...params} label="Seleccionar redirección" />}></Autocomplete>
                        {errorValid[`opcionesNuevasRedErr${o.value}`] ? <div style={{ color: "red", paddingBottom: 10 }}>{errorValid[`opcionesNuevasRedErr${o.value}`]}</div> : null}
                      </GridItem>
                      {o.redirect && o.redirect.value === "1" ? (
                        <GridItem xs={2} style={{ marginTop: 5 }}>
                          <InputLabel>Redirigir a pregunta</InputLabel>
                          <Autocomplete name="redirigir" disableClearable id="combo-box-tiempos" options={this.state.preguntas} getOptionLabel={(option) => option.label} onChange={this.cambiarDondeRedirigir.bind(this, o, index)} value={this.state.opcionesNuevas[index].redirectHacia} renderInput={(params) => <TextField {...params} label="Seleccionar Pregunta" />}></Autocomplete>
                          {errorValid[`opcionesNuevasRedHaciaErr${o.value}`] ? <div style={{ color: "red", paddingBottom: 10 }}>{errorValid[`opcionesNuevasRedHaciaErr${o.value}`]}</div> : null}
                        </GridItem>
                      ) : null}
                      {o.redirect && o.redirect.value === "2" ? (
                        <>
                          <GridItem xs={2} style={{ marginTop: 5 }}>
                            <InputLabel>Redirigir a especialidad</InputLabel>
                            <Autocomplete name="redirigir" disableClearable id="combo-box-tiempos" options={this.state.especialidadOpciones} getOptionLabel={(option) => option.label} onChange={this.cambiarDondeRedirigir.bind(this, o, index)} value={this.state.opcionesNuevas[index].redirectHacia} renderInput={(params) => <TextField {...params} label="Seleccionar Especialidad" />}></Autocomplete>
                            {errorValid[`opcionesNuevasRedErr${o.value}`] ? <div style={{ color: "red", paddingBottom: 10 }}>{errorValid[`opcionesNuevasRedErr${o.value}`]}</div> : null}
                          </GridItem>
                          <GridItem xs={2} style={{ marginTop: 5 }}>
                            <InputLabel>Redirigir si no hay horas</InputLabel>
                            <Autocomplete name="redirigir" disableClearable id="combo-box-tiempos" options={this.state.preguntas} getOptionLabel={(option) => option.label} onChange={this.cambiarRedirigirSinHoras.bind(this, o, index)} value={this.state.opcionesNuevas[index].redirectNoHrs} renderInput={(params) => <TextField {...params} label="Seleccionar Pregunta" />}></Autocomplete>
                          </GridItem>
                        </>
                      ) : null}
                      <GridItem xs={1} style={{ marginLeft: 15, marginTop: 30 }}>
                        <Button style={{ marginRight: 20, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={quitarOpcion.bind(this, o)}>
                          Eliminar
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </>
              ),
              this
            )}
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={12} style={{ marginTop: 30, display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    style={{ marginRight: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }}
                    className="fondoVerde"
                    onClick={this.guardarPregunta.bind(this)}
                    /* onClick={this.abrirSegundoModal}
                onClick={this.enviarDifusion} 
                onClick={this.subirArchivo.bind(this)} */
                  >
                    Guardar
                  </Button>
                  <Button style={{ marginLeft: 5, marginRight: 20, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={this.volverSegundoModal}>
                    Atrás
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }

  render() {
    const { encuestNombreErr, encuestTipoSelectErr, encEspSelectErr, preguntasErr, encuestaOpcionesSelectErr, canalesOpcionesSelectErr, edadMinErr, edadMaxErr, edadErr, primeraPreguntaErr } = this.state.errorValid;
    return (
      <>
        <Dialog open={this.state.open} onClose={this.salir} className="modificarUsuario">
          <DialogContent>
            <GridContainer className="">
              <GridItem xs={12}>
                <GridContainer className="">
                  <GridItem xs={12} style={{ marginTop: 10, marginLeft: 10 }}>
                    <h1>{this.props.editar ? <>Editar</> : <>Agregar</>} Encuesta</h1>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12}>
                <GridContainer className="">
                  <GridItem xs={6} style={{ marginTop: 20, marginLeft: 10 }}>
                    <InputLabel>Nombre Encuesta</InputLabel>
                    <input type="text" name="encuestaNombre" value={this.state.encuestaNombre} onChange={this.cambiarValores} placeholder="" className="inputText" />
                    {encuestNombreErr ? <div style={{ color: "red", paddingBottom: 10 }}>{encuestNombreErr}</div> : null}
                  </GridItem>
                  <GridItem xs={4} style={{ marginTop: 5 }}>
                    <InputLabel>Tipo Encuesta</InputLabel>
                    <Autocomplete name="encuestaOpcSelect" disableClearable id="combo-box-opc" options={this.state.encuestaOpciones} getOptionLabel={(option) => option.label} onChange={this.cambiarOpciones.bind(this)} value={this.state.encuestaOpcionesSelect} renderInput={(params) => <TextField {...params} label="Seleccionar Tipo" />}></Autocomplete>
                    {encuestaOpcionesSelectErr ? <div style={{ color: "red", paddingBottom: 10 }}>{encuestaOpcionesSelectErr}</div> : null}
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12}>
                <GridContainer className="">
                  <GridItem xs={3} style={{ marginTop: 20, marginLeft: 10 }}>
                    <InputLabel>Cesfam o especialidad Encuesta</InputLabel>
                    <Autocomplete name="encuestaTipoSelect" disableClearable id="combo-box-ces" options={this.state.encuestaTipoOpciones} getOptionLabel={(option) => option.label} onChange={this.cambiarTipo.bind(this)} value={this.state.encuestaTipoSelect} renderInput={(params) => <TextField {...params} label="Seleccionar Tipo" />}></Autocomplete>
                    {encuestTipoSelectErr ? <div style={{ color: "red", paddingBottom: 10 }}>{encuestTipoSelectErr}</div> : null}
                  </GridItem>
                  {this.state.encuestaTipoSelect && this.state.encuestaTipoSelect.value === "2" ? (
                    <GridItem xs={3} style={{ marginTop: 20 }}>
                      <InputLabel>Seleccionar Especialidad </InputLabel>
                      <Autocomplete name="tipo-esp" disableClearable id="combo-box-tiempos" options={this.state.especialidadOpciones} getOptionLabel={(option) => option.label} onChange={this.cambiarEspecialidadTipo.bind(this)} value={this.state.encEspSelect} renderInput={(params) => <TextField {...params} label="Seleccionar Especialidad" />}></Autocomplete>
                      {encEspSelectErr ? <div style={{ color: "red", paddingBottom: 10 }}>{encEspSelectErr}</div> : null}
                    </GridItem>
                  ) : null}
                  <GridItem xs={1} style={{ marginTop: 35, marginLeft: 10 }}>
                    <InputLabel>Edad</InputLabel>
                    <input type="text" name="edadMin" maxLength={2} value={this.state.edadMin} onChange={this.cambiarValores} placeholder="" className="inputText" />
                    {edadMinErr ? <div style={{ color: "red", paddingBottom: 10 }}>{edadMinErr}</div> : null}
                    {edadErr ? <div style={{ color: "red", paddingBottom: 10 }}>{edadErr}</div> : null}
                  </GridItem>
                  <GridItem xs={1} style={{ marginTop: 52 }}>
                    <input type="text" name="edadMax" maxLength={2} value={this.state.edadMax} onChange={this.cambiarValores} placeholder="" className="inputText" />
                    {edadMaxErr ? <div style={{ color: "red", paddingBottom: 10 }}>{edadMaxErr}</div> : null}
                  </GridItem>
                  <GridItem xs={3} style={{ marginTop: 17, marginLeft: 10 }}>
                    <InputLabel>Canal</InputLabel>
                    <Autocomplete name="encuestaTipoSelect" disableClearable id="combo-box-ces" options={this.state.canalesOpciones} getOptionLabel={(option) => option.label} onChange={this.cambiarCanal.bind(this)} value={this.state.canalesOpcionesSelect} renderInput={(params) => <TextField {...params} label="Seleccionar Canal" />}></Autocomplete>
                    {canalesOpcionesSelectErr ? <div style={{ color: "red", paddingBottom: 10 }}>{canalesOpcionesSelectErr}</div> : null}
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12}>
                <GridContainer className="">
                  <GridItem xs={12} style={{ marginLeft: 100, marginTop: 30, display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      style={{ marginRight: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }}
                      className="fondoVerde"
                      onClick={this.abrirSegundoModal}
                      /* onClick={this.enviarCuestionario} */
                    >
                      Agregar
                    </Button>
                    <Button style={{ marginLeft: 5, marginRight: 20, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={this.salir}>
                      Cancelar
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.preguntasVer} onClose={this.salir} className="modificarUsuario">
          <DialogContent>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={5} style={{ marginTop: 10, marginLeft: 10 }}>
                  <h1>Preguntas</h1>
                </GridItem>
                <GridItem xs={4} style={{ marginTop: 10, display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    style={{ marginRight: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }}
                    className="fondoVerde"
                    onClick={this.preguntaNueva}
                    /*onClick={this.enviarDifusion} 
                        onClick={this.subirArchivo.bind(this)} */
                  >
                    Agregar Pregunta
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
            {this.state.preguntas.length > 0 ? (
              <>
                <GridItem xs={12}>
                  <GridContainer className="">
                    <GridItem xs={4} style={{ marginTop: 10, marginLeft: 10 }}>
                      <InputLabel>Primera Pregunta</InputLabel>
                      <Autocomplete name="redirigir" disableClearable id="combo-box-tiempos" options={this.state.preguntas} getOptionLabel={(option) => option.label} onChange={this.cambiarPrimeraPregunta.bind(this)} value={this.state.primeraPregunta} renderInput={(params) => <TextField {...params} label="Seleccionar Pregunta" />}></Autocomplete>
                      {primeraPreguntaErr ? <div style={{ color: "red", paddingBottom: 10 }}>{primeraPreguntaErr}</div> : null}
                    </GridItem>
                  </GridContainer>
                </GridItem>
                {this.mostrarPreguntas()}
              </>
            ) : (
              <GridItem xs={12}>
                <GridContainer className="">
                  <GridItem xs={12} style={{ marginTop: 30, display: "flex", justifyContent: "center" }}>
                    Esta encuesta no posee preguntas aún
                  </GridItem>
                </GridContainer>
              </GridItem>
            )}
            {preguntasErr ? (
              <GridItem xs={12}>
                <GridContainer className="">
                  <GridItem xs={12} style={{ marginTop: 30, display: "flex", justifyContent: "center" }}>
                    <div style={{ color: "red", paddingBottom: 10 }}>{preguntasErr}</div>
                  </GridItem>
                </GridContainer>
              </GridItem>
            ) : null}
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={12} style={{ marginTop: 30, display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    style={{ marginRight: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }}
                    className="fondoVerde"
                    onClick={this.props.editar ? this.actualizarCuestionario : this.enviarCuestionario}
                    /* onClick={this.abrirSegundoModal}
                onClick={this.enviarDifusion} 
                onClick={this.subirArchivo.bind(this)} */
                  >
                    Guardar
                  </Button>
                  <Button style={{ marginLeft: 5, marginRight: 20, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={this.volverPrimerModal}>
                    Atrás
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </DialogContent>
        </Dialog>
        {this.state.modalNuevaPregunta ? this.modalAgregarPregunta() : null}
        {this.state.preguntasEditar ? this.modalEditarPregunta() : null}
      </>
    );
  }
}
