import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import { store } from "store/index";

import GridContainer from "components/grid/GridContainer.js";
import axios from "axios";
import MaterialTable from "material-table";
import { Grid } from "@material-ui/core";
import moment from "moment";

//JS
import ModalSolicitud from "../Dialog/ModalSolicitud";

// IMG
import rechazar from "assets/img/solicitud_trabajo/iconos/rechazar.svg";
import aprobar from "assets/img/solicitud_trabajo/iconos/aprobar_morado.svg";

//CSS
import SolicitudTrabajo from "assets/css/solicitudTrabajo.module.css";

export default class Pendiente extends Component {
  constructor(props) {
    super(props);

    this.state = {
      abrirModal: false,
      dataSolicitud: null,
      tituloModalSolicitud: "",
      vistaModal: 0,
      seleccion: 1,
      solicitudes: [],
    };
  }

  componentDidMount() {
    this.solicitudes("0");
    this.colorTabla();
  }

  solicitudes = (estado) => {
    const apiUrl = global.config.modulos.solicitudTrabajo + "notificaciones/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          uspId: store.getState().usp,
          cliente: store.getState().cliente,
          estado: estado,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            solicitudes: res.data.datos,
          });
        } else {
          this.setState({
            solicitudes: [],
          });
        }
      });
  };

  colorTabla = () => {
    const tableHeaders = document.querySelectorAll(".MuiTableCell-head");
    tableHeaders.forEach((header) => {
      header.style.setProperty("border-bottom", "4px solid #c641a7", "important");
    });
  };

  cerrarModal = () => {
    this.setState({ abrirModal: false });
  };

  irTicket = (cod) => {
    this.props.history.push({
      pathname: "/solicitud_trabajo",
      params: { cod },
    });
  };

  abrirModalMotivo = (data, titulo, vista) => {
    this.setState({ abrirModal: true, dataSolicitud: data, tituloModalSolicitud: titulo, vistaModal: vista });
  };

  render() {
    const { seleccion } = this.state;
    return (
      <>
        <GridContainer style={{ width: "90%" }}>
          <Grid item xs={12} sm={12}>
            <div className={SolicitudTrabajo.divBorderTableSolicitudCesfamPendiente} style={{ paddingLeft: "20px" }}>
              <MaterialTable
                title=""
                columns={[
                  {
                    title: "Fecha de ingreso",

                    render: (rowData) => (
                      <React.Fragment>
                        <p>{rowData.fecha_solicitud.split(" ")[0]}</p>
                        <p>{rowData.fecha_solicitud.split(" ")[1]} hrs</p>
                      </React.Fragment>
                    ),
                    field: "fecha_solicitud",
                  },
                  {
                    title: "N° de ticket ",
                    field: "sol_codigo",
                  },
                  {
                    title: "CESFAM",
                    field: "ces_nombre",
                  },

                  {
                    title: "Tipo de solicitud",
                    field: "tipo",
                  },
                  {
                    title: "Motivo de solicitud",
                    render: (rowData) => (
                      <React.Fragment>
                        <div style={{ position: "relative" }}>
                          <textarea
                            className={SolicitudTrabajo.textAreaIngresado}
                            value={rowData.sol_motivo}
                            style={{
                              background: "transparent",
                              border: seleccion == 1 ? "solid 3px #c641a7" : seleccion == 2 ? "solid 3px #3fb8b7" : seleccion == 3 ? "solid 3px #eaa029" : seleccion == 4 ? "solid 3px #1bacdd" : null,
                            }}
                          ></textarea>
                        </div>
                      </React.Fragment>
                    ),
                    field: "sol_motivo",
                    cellStyle: (rowData) => ({
                      width: "550px",
                    }),
                  },

                  {
                    title: "Estado",
                    render: (rowData) => (
                      <React.Fragment>
                        <>
                          <p>
                            <img
                              className="botonesTablas"
                              style={{
                                marginRight: 5,
                                width: 30,
                              }}
                              src={aprobar}
                              onClick={() => this.abrirModalMotivo(rowData, "Aprobar solicitud", 14)}
                            />
                            <img className="botonesTablas" style={{ width: 30 }} src={rechazar} onClick={() => this.abrirModalMotivo(rowData, "Rechazar solicitud", 15)} />
                          </p>
                        </>
                      </React.Fragment>
                    ),
                  },
                ]}
                data={this.state.solicitudes}
                options={{
                  actionsColumnIndex: -1,
                  search: false,
                  toolbar: false,
                }}
                localization={{
                  header: {
                    actions: "Acciones",
                  },
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    labelRowsSelect: "filas",
                    firstTooltip: "primera",
                    previousTooltip: "anterior",
                    nextTooltip: "siguiente",
                    lastTooltip: "última",
                  },
                  toolbar: {
                    nRowsSelected: "{0} fila(s) seleccionadas",
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                  },
                  body: {
                    emptyDataSourceMessage: <span style={{ fontSize: "1.5rem" }}>No tiene tickets pendientes</span>,

                    filterRow: {
                      filterTooltip: "Buscar",
                    },
                  },
                }}
              />
            </div>

            {this.state.abrirModal && <ModalSolicitud data={this.state.dataSolicitud} titulo={this.state.tituloModalSolicitud} salir={this.cerrarModal} vista={this.state.vistaModal} solicitudes={() => this.solicitudes("0")} />}
          </Grid>
        </GridContainer>
      </>
    );
  }
}
