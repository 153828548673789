import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Telefonos from "./Telefonos";
import AgregarTelefono from "./../../components/Usuarios/AgregarTelefono";
import Tooltip from "@material-ui/core/Tooltip";
import copiar from "../../assets/img/usuarios/copiar.svg";
import { Grid, List, ListItem } from "@material-ui/core";
import { NotificationManager } from "react-notifications";

// estilos
//import "../../assets/css/agregar.css";

export default class ModalTelefonosGF extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      idFamiliar: props.idFamiliar,
      datosTelefonos: true,
      modalAgregarTelefono: false,
      parentesco: props.parentesco,
      rutFamiliar: props.rutFamiliar,
      nombreFamiliar: props.nombreFamiliar,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  copiarRut = (rut) => {
    navigator.clipboard.writeText(rut);
    NotificationManager.info("Rut copiado");
  };

  mostrarModalAgregarTelefono = () => {
    this.setState({
      modalAgregarTelefono: true,
      datosTelefonos: false,
    });
    this.setState({
      datosTelefonos: true,
    });
  };

  ocultarModalAgregarTelefono = () => {
    this.setState({ modalAgregarTelefono: false, datosTelefonos: false });
    this.setState({ datosTelefonos: true });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modalVacio">
          <DialogContent>
            <Grid container className="agendar-container">
              <Grid item xs={12}>
                <h2>Historial de teléfonos</h2>
              </Grid>
              <Grid item xs={4} style={{ marginTop: 20 }}>
                <p>
                  <label style={{ fontWeight: "bold" }}>Nombre:</label>
                </p>{" "}
                <p>{this.state.nombreFamiliar}</p>
              </Grid>
              <Grid item xs={4} style={{ marginTop: 20 }}>
                <p>
                  <label style={{ fontWeight: "bold" }}>RUT:</label>
                </p>{" "}
                <p>
                  {this.state.rutFamiliar}
                  &nbsp;&nbsp;&nbsp;
                  <Tooltip title="Copiar RUT del familiar" aria-label="Copiar RUT del familiar">
                    <img
                      style={{
                        width: 18,
                        float: "inherit",
                        marginBottom: -4,
                      }}
                      className="imagenBotonAccion "
                      src={copiar}
                      onClick={() => this.copiarRut(this.state.rutFamiliar)}
                    />
                  </Tooltip>
                </p>
              </Grid>
              <Grid item xs={4} style={{ marginTop: 20 }}>
                <p>
                  <label style={{ fontWeight: "bold" }}>Parentesco:</label>
                </p>{" "}
                <p>{this.state.parentesco}</p>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" className="botonesAlineados botonAgregarUsuario fondoVerde" onClick={this.mostrarModalAgregarTelefono}>
                  Agregar teléfono
                </Button>
              </Grid>

              <Grid item xs={12}>
                {this.state.datosTelefonos ? <Telefonos usuId={this.state.idFamiliar}></Telefonos> : null}
              </Grid>
              <Grid item xs={12} style={{ marginTop: 30, marginBottom: 30 }}>
                <Button className="botonDialog fondoRojo" onClick={this.salir}>
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        {this.state.modalAgregarTelefono ? <AgregarTelefono ocultar={this.ocultarModalAgregarTelefono} usuId={this.state.idFamiliar}></AgregarTelefono> : null}
      </div>
    );
  }
}
