import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import chileanRut from "chilean-rut";

// core components
import cargando from "../../assets/img/cargando_salusin.gif";
import descargar from "../../assets/img/lista_espera/descargar.svg";
import telefono from "../../assets/img/usuarios/telefono-datos-o.svg";
import ver_mas from "../../assets/img/iconos correccion/ver mas.svg";
import eliminar from "../../assets/img/iconos correccion/icono_eliminar.svg";
import modi_icon from "../../assets/img/usuarios/icono_modificar.svg";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Titulo from "../../components/titulo/Titulo.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import MaterialTable from "material-table";
import Paginacion from "./../../components/Table/Pagination.js";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Tooltip from "@material-ui/core/Tooltip";
import Buttons from "react-multi-date-picker/components/button";
import { FormControl } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "react-multi-date-picker/components/button";
import { Input } from "@material-ui/core";
import TableHeader from "../../components/Table/TableHeader";
import AgregarUsuarioPercapitado from "../../components/NoPercapitados/AgregarUsuarioPercapitado";
import EliminarPercapitado from "../../components/NoPercapitados/EliminarPercapitado";
import { DialogContentText } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import UsuarioCrearObservacionLibre from "../../components/ListaDeEspera/UsuarioCrearObservacionLibre";
import VerObservacionesLibre from "../../components/ListaDeEspera/VerObservacionesLibres";
import VerTelefonos from "../../components/ListaDeEspera/VerTelefonos";
import NotificationManager from "react-notifications/lib/NotificationManager";
import EditarRutListaOirs from "../../components/ListaDeEspera/EditarRutListaOirs";
import EditarFechaRegistro from "../../components/ListaDeEspera/EditarFechaRegistro";
import VistaUsuarios from "../../components/GestionPrioritarios/VistaUsuarios";
import Ver_detalle_llamadas from "components/ListaDeEspera/Ver_detalle_llamadas";

class ListaDeAltas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cesfam: store.getState().cesfam,

      datos: [],

      loading: false,
      pagina: 0,
      total: 0,
      filas: 10,
      nombreTitulo: [
        { nombre: "Fecha inscripción ", filtro: "loi_fecharegistro" },
        { nombre: "Rut", filtro: "loi_rut" },
        { nombre: "Nombres", filtro: "loi_nombre" },
        { nombre: "Edad", filtro: "loi_fecha_nacimiento" },
        { nombre: "Género", filtro: "loi_sexo" },
        { nombre: "Cesfam", filtro: "loi_cesfam" },
        { nombre: "Télefonos", filtro: "loi_telefono1" },
        { nombre: "Programa", filtro: "loi_tratamiento" },
        { nombre: "Fecha alta", filtro: "loi_fecha_alta" },
        { nombre: "Tipo de alta", filtro: "loi_tipo_alta" },
        { nombre: "Observación libre", filtro: "loi_observacion" },
      ],
      ordenarPrioridad: null,
      listadoOirs: [],
      mostrarAlertaConfirmarTrat: false,
      mostrarModalObservacionFija: false,
      mostrarModalEditarRut: false,
      mostrarModalEditarFechaRe: false,
      texto_filtro: props.busqueda,

      loi_id: null,
      cesfams: [],
      genero: null,
      cesfam_b: null,
      edad: null,
      fecha2: null,
      fecha1: null,
      altas: [],
      alta: null,
      edad2: null,

      loi_rut: null,
      loi_fecha_registro: null,
      id_usuario: null,
      modalUsuario: false,
      estado_gestion: 0,
      mostrarDetalle: false,
      trat_sec: null,
      sesiones: null,
      tratamientos: [],
      tratamiento: null,
    };
  }

  handleFilasPagination = (filas) => {
    this.setState({
      filas: filas,
    });
    this.obtenerTratamientoOirs(this.state.pagina, filas, this.state.ordenarPrioridad);
  };

  handlePagination = (pagina) => {
    this.setState({
      pagina: pagina,
    });
    this.obtenerTratamientoOirs(pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  handleFiltradorCampo = (ordenarPrioridad) => {
    this.setState({
      ordenarPrioridad: ordenarPrioridad,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, ordenarPrioridad);
  };

  componentDidMount() {
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
    this.obtenerCesfams();
    this.obtenerAltas();
    this.obtenerCesfamGestion();
    this.obtenerTratamientos();
  }

  abrirModaldetalle = (loi_id) => {
    this.setState({
      mostrarDetalle: true,
      loi_id: loi_id,
    });
  };

  cerrarModaldetalle = () => {
    this.setState({
      mostrarDetalle: false,
    });
  };

  obtenerTratamientos = () => {
    axios
      .get(global.config.modulos.listaEspera + "ver_tratamientos/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            tratamientos: res.data.datos,
          });
        }
      });
  };

  obtenerTratamientoOirs = async (pagina, fila, filtrador) => {
    axios
      .get(global.config.modulos.listaEspera + "ver_oirs/", {
        headers: authHeader(),
        params: {
          pagina: pagina,
          ordenarPrioridad: filtrador,
          textofiltro: this.state.texto_filtro,
          genero: this.state.genero,
          cesfam_b: this.state.cesfam_b,
          edad: this.state.edad,
          edad2: this.state.edad2,
          FechaInicio: this.state.fecha1,
          FechaTermino: this.state.fecha2,
          cesfam_admin: this.state.cesfam,
          tratamiento: this.state.tratamiento,
          tratamiento_sec: this.state.trat_sec,
          sesiones: this.state.sesiones,
          altas: this.state.alta,
          tipo: 4,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            total: res.data.total,
            listadoOirs: res.data.datos,
            pagina: res.data.pagina,
            ordenarPrioridad: res.data.prioridad,
          });
        } else {
          this.setState({
            total: 0,
            listadoOirs: [],
            pagina: 0,
            ordenarPrioridad: null,
          });
        }
      });
  };

  mostrarModalUsuario = (idusuario) => {
    if (idusuario) {
      this.setState({
        modalUsuario: true,
        id_usuario: idusuario,
      });
    }
  };

  ocultarModalUsuario = () => {
    this.setState({
      modalUsuario: false,
    });
  };

  tratamiento_sec = (e) => {
    const trat_sec = e.target.value;
    this.setState({ trat_sec: trat_sec });
  };

  obtenerCesfamGestion = () => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_cesfam_gestion/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            estado_gestion: 1,
            nombreTitulo: [
              { nombre: "Fecha inscripción ", filtro: "loi_fecharegistro" },
              { nombre: "Rut", filtro: "loi_rut" },
              { nombre: "Nombres", filtro: "loi_nombre" },
              { nombre: "Edad", filtro: "loi_fecha_nacimiento" },
              { nombre: "Género", filtro: "loi_sexo" },
              { nombre: "Cesfam", filtro: "loi_cesfam" },
              { nombre: "Télefonos", filtro: "loi_telefono1" },
              { nombre: "Programa", filtro: "loi_tratamiento" },
              { nombre: "Fecha alta", filtro: "loi_fecha_alta" },
              { nombre: "Tipo de alta", filtro: "loi_tipo_alta" },
              { nombre: "Observación libre", filtro: "loi_observacion" },
              { nombre: "Detalle", filtro: "nulo" },
            ],
          });
        } else {
          this.setState({
            estado_gestion: 0,
          });
        }
      });
  };

  LimpiarFiltros = () => {
    this.setState(
      {
        cesfam_b: null,
        genero: null,
        edad: "",
        edad2: "",
        fecha2: "",
        fecha1: "",
        texto_filtro: "",
        alta: null,
        trat_sec: null,
        sesiones: "",
      },
      () => {
        this.setearNull();
      }
    );
  };

  tratamiento = (e) => {
    const tratamiento = e.target.value;
    this.setState({ tratamiento: tratamiento });
  };

  setearNull = () => {
    this.setState(
      {
        cesfam_b: null,
        genero: null,
        edad: null,
        edad2: null,
        fecha2: null,
        fecha1: null,
        texto_filtro: null,
        sesiones: null,
      },
      () => {
        this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
      }
    );
  };

  abrirModalObservacionesLibre = (loi_id) => {
    this.setState({
      mostrarModalObservacionLibre: true,
      loi_id: loi_id,
    });
  };

  cerrarModalObservacionLibre = () => {
    this.setState({
      mostrarModalObservacionLibre: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  abrirModalCrearObservacionLibre = (loi_id) => {
    this.setState({
      mostrarModalCrearObservacionLibre: true,
      loi_id: loi_id,
    });
  };

  cerrarModalCrearObservacionLibre = () => {
    this.setState({
      mostrarModalCrearObservacionLibre: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  aplicarFiltros = () => {
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  filtroTexto = (e) => {
    const texto_filtro = e.target.value;
    this.setState({ texto_filtro: texto_filtro });
  };

  obtenerCesfams = () => {
    axios
      .get(global.config.modulos.listaEspera + "ver_cesfams/", {
        headers: authHeader(),
        params: {
          cesfam_admin: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            cesfams: res.data.datos,
          });
        }
      });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  obtenerAltas = () => {
    axios
      .get(global.config.modulos.listaEspera + "ver_tipo_altas/", {
        headers: authHeader(),
        params: {
          cesfam_admin: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            altas: res.data.datos,
          });
        }
      });
  };

  cambiartipoalta = (e) => {
    const alta = e.target.value;
    this.setState({ alta: alta });
  };

  abrirModalTelefonos = (loi_id) => {
    this.setState({
      mostrarModalTelefonos: true,
      loi_id: loi_id,
    });
  };

  cerrarModalTelefonos = () => {
    this.setState({
      mostrarModalTelefonos: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  descargarExcel = async () => {
    this.setState({ loading: true });
    NotificationManager.warning("Se inicio proceso de descarga de Excel esto puede tardar unos segundos espere por favor", "Descargando....", 3000);
    await axios.get((window.location.href = global.config.modulos.listaEspera + "Excel_lista_en_Alta/?FechaInicio=" + this.state.fecha1 + "&FechaTermino=" + this.state.fecha2 + "&genero=" + this.state.genero + "&cesfam=" + this.state.cesfam_b + "&edad=" + this.state.edad + "&textofiltro=" + this.state.texto_filtro + "&tratamiento=" + this.state.tratamiento + "&cesfam_admin=" + this.state.cesfam), { headers: authHeader() }).then((res) => {
      this.setState({ loading: false });
    });
  };

  abrirModalEditarRut = (loi_id, loi_rut) => {
    this.setState({
      mostrarModalEditarRut: true,
      loi_id: loi_id,
      loi_rut: loi_rut,
    });
  };

  cerrarModalEditarRut = () => {
    this.setState({
      mostrarModalEditarRut: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  abrirModalEditarFecha = (loi_id, loi_fecha_registro) => {
    this.setState({
      mostrarModalEditarFechaRe: true,
      loi_id: loi_id,
      loi_fecha_registro: loi_fecha_registro,
    });
  };

  cerrarModalEditarFecha = () => {
    this.setState({
      mostrarModalEditarFechaRe: false,
    });
    this.obtenerTratamientoOirs(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  render() {
    return (
      <div className="middle-content">
        <div style={{ display: "flex" }}>
          <FormControl style={{ width: "10%", marginLeft: "1%" }}>
            <InputLabel id="demo-simple-select-label">Nombre o Rut</InputLabel>
            <Input type="text" name="texto_filtro" value={this.state.texto_filtro} onChange={this.filtroTexto} />
          </FormControl>

          <FormControl style={{ width: "10%", marginLeft: "1%" }}>
            <InputLabel id="demo-simple-select-label">Genero</InputLabel>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" name="genero" value={this.state.genero} onChange={this.cambiarValores}>
              <MenuItem value={null}>--Seleccione Sexo--</MenuItem>
              <MenuItem value={"M"}>Masculino</MenuItem>
              <MenuItem value={"F"}>Femenino</MenuItem>
              <MenuItem value={"I"}>InterSex</MenuItem>
            </Select>
          </FormControl>

          <FormControl style={{ width: "7%", marginLeft: "1%" }}>
            <InputLabel id="demo-simple-select-label">Edad</InputLabel>
            <Input type="number" labelId="demo-simple-select-label" id="demo-simple-select" name="edad" value={this.state.edad} onChange={this.cambiarValores}></Input>
          </FormControl>

          <FormControl style={{ width: "7%", marginLeft: "1%" }}>
            <InputLabel id="demo-simple-select-label">Edad 2</InputLabel>
            <Input type="number" labelId="demo-simple-select-label" id="demo-simple-select" name="edad2" value={this.state.edad2} onChange={this.cambiarValores}></Input>
          </FormControl>

          <FormControl style={{ width: "8%", marginLeft: "1%" }}>
            <InputLabel id="demo-simple-select-label">Cesfam</InputLabel>
            <Select name="cesfam_b" value={this.state.cesfam_b} onChange={this.cambiarValores}>
              <MenuItem value={null}>Selecciona Cesfams</MenuItem>
              {this.state.cesfams.map((cesfam, i) => {
                return (
                  <MenuItem key={i} value={cesfam.ces_id}>
                    {cesfam.ces_nombre}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl style={{ minWidth: "10%", marginLeft: "1%" }}>
            <InputLabel id="demo-simple-select-label">Alta</InputLabel>
            <Select name="tratamiento" value={this.state.alta} onChange={this.cambiartipoalta}>
              <MenuItem value={null}>Seleccione Alta</MenuItem>
              {this.state.altas.map((alta, i) => {
                return (
                  <MenuItem key={i} value={alta.ta_id}>
                    {alta.ta_nombre}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Buttons onClick={this.aplicarFiltros} className={"btnOpcion fondoNaranjo"}>
            Filtro
          </Buttons>

          <Buttons onClick={this.LimpiarFiltros} className={"btnOpcion fondoNaranjo"}>
            Limpiar Filtro
          </Buttons>

          <Tooltip title={<h4>Descargar Excel lista de otros casos</h4>} aria-label="Lista de ingresos">
            <img className="imagenBotonAccionMenu" src={descargar} onClick={() => this.descargarExcel()} />
          </Tooltip>
          {this.state.loading ? <img style={{ width: "80px", height: "50px" }} src={cargando} alt="my-gif" /> : null}
        </div>

        <div
          style={{
            margin: "1% 0px",
          }}
        >
          <FormControl style={{ minWidth: "10%", marginLeft: "1%" }}>
            <Input type="date" labelId="demo-simple-select-label" id="demo-simple-select" name="fecha1" value={this.state.fecha1} onChange={this.cambiarValores}></Input>
          </FormControl>

          <FormControl style={{ minWidth: "10%", marginLeft: "1%" }}>
            <Input type="date" labelId="demo-simple-select-label" id="demo-simple-select" name="fecha2" value={this.state.fecha2} onChange={this.cambiarValores}></Input>
          </FormControl>
        </div>

        <TableContainer style={{ marginBottom: "120px" }}>
          <Table className="tablaGeneralGestion bordeTabla" aria-label="simple table">
            <TableHeader nombreTitulo={this.state.nombreTitulo} datocampo={this.handleFiltradorCampo} />

            <TableBody>
              {this.state.listadoOirs.map((row, index) => (
                <TableRow key={index}>
                  {row.loi_fecha_invalida === 1 ? (
                    <TableCell align="center">
                      <React.Fragment>
                        <Tooltip title={<h4>{["Código: ", row.loi_fila_b1]}</h4>}>
                          <p style={{ fontSize: "14px" }}>{moment(row.loi_fecharegistro).format("DD-MM-YYYY")}</p>
                        </Tooltip>
                      </React.Fragment>
                      <React.Fragment>
                        <Tooltip title={<h4>Modificar fecha registro</h4>}>
                          <div>
                            <img class="mediumIcon" src={modi_icon} onClick={() => this.abrirModalEditarFecha(row.loi_id, row.loi_fecha_texto_invalida)}></img>
                          </div>
                        </Tooltip>
                      </React.Fragment>
                    </TableCell>
                  ) : (
                    <TableCell align="center">
                      <React.Fragment>
                        <Tooltip title={<h4>{["Código: ", row.loi_fila_b1]}</h4>}>
                          <p style={{ fontSize: "14px" }}>{moment(row.loi_fecharegistro).format("DD-MM-YYYY")}</p>
                        </Tooltip>
                      </React.Fragment>
                    </TableCell>
                  )}
                  {row.loi_rut_valido === 1 ? (
                    <TableCell align="center">
                      <p style={{ fontSize: "14px" }}>{row.loi_rut}</p>{" "}
                      <React.Fragment>
                        <Tooltip title={<h4>Modificar Rut</h4>}>
                          <div>
                            <img class="mediumIcon" src={modi_icon} onClick={() => this.abrirModalEditarRut(row.loi_id, row.loi_rut)}></img>
                          </div>
                        </Tooltip>
                      </React.Fragment>
                    </TableCell>
                  ) : (
                    <TableCell>
                      {
                        <a style={{ cursor: "pointer" }} onClick={() => this.mostrarModalUsuario(row.loi_usuario)}>
                          {chileanRut.format(row.loi_rut, true)}
                        </a>
                      }
                    </TableCell>
                  )}
                  <TableCell align="center">
                    <React.Fragment>
                      <Tooltip title={<h4>{["Fecha de Nacimiento: ", row.nacimiento]}</h4>} style={{ fontSize: "20px" }}>
                        <p style={{ fontSize: "14px" }}>{row.loi_nombre} </p>
                      </Tooltip>
                    </React.Fragment>
                  </TableCell>
                  <TableCell align="center">{row.edad}</TableCell>
                  <TableCell align="center">{row.sexo}</TableCell>
                  <TableCell align="center">{row.cesfam_nombre}</TableCell>
                  <TableCell align="center">
                    <React.Fragment>
                      <Tooltip title={<h4>Ver telefonos del paciente</h4>}>
                        <div>
                          <img class="mediumIcon" src={telefono} onClick={() => this.abrirModalTelefonos(row.loi_id)}></img>
                        </div>
                      </Tooltip>
                    </React.Fragment>
                  </TableCell>
                  <TableCell align="center"> {row.nombre_tratamiento}</TableCell>
                  <TableCell align="center">
                    <React.Fragment>
                      <Tooltip
                        title={[
                          <div>
                            <h4>{["Fecha de cambio: ", row.fecha_modificado]}</h4>
                          </div>,
                          <div>
                            <h4>{["Funcionario/a: ", row.nombre_profesional]}</h4>
                          </div>,
                        ]}
                        style={{ fontSize: "20px" }}
                      >
                        <p style={{ fontSize: "14px" }}> {row.loi_fecha_alta} </p>
                      </Tooltip>
                    </React.Fragment>
                  </TableCell>
                  <TableCell align="center"> {row.nombre_alta}</TableCell>
                  <TableCell align="center">
                    {row.observacion_usuario}
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "20px",
                        marginTop: "5px",
                        justifyContent: "center",
                      }}
                    >
                      <React.Fragment>
                        <Tooltip title={<h4>Ver observación libre</h4>}>
                          <div style={{ marginRight: "10px" }}>
                            <img class="mediumIcon" src={ver_mas} onClick={() => this.abrirModalObservacionesLibre(row.loi_id)}></img>
                          </div>
                        </Tooltip>
                      </React.Fragment>
                      <React.Fragment>
                        <Tooltip title={<h4>Ingresar observación libre</h4>}>
                          <div>
                            <img class="mediumIcon" src={modi_icon} onClick={() => this.abrirModalCrearObservacionLibre(row.loi_id)}></img>
                          </div>
                        </Tooltip>
                      </React.Fragment>
                    </div>
                  </TableCell>
                  {this.state.estado_gestion === 1 ? (
                    <TableCell align="center">
                      <React.Fragment>
                        <Tooltip title={<h4>Ver detalle</h4>}>
                          <div>
                            <img class="mediumIcon" src={modi_icon} onClick={() => this.abrirModaldetalle(row.loi_id)}></img>
                          </div>
                        </Tooltip>
                      </React.Fragment>
                    </TableCell>
                  ) : null}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Paginacion total={this.state.total} paginacion={this.handlePagination} filas_paginacion={this.handleFilasPagination} />
        </TableContainer>

        {this.state.mostrarModalCrearObservacionLibre ? <UsuarioCrearObservacionLibre ocultar={this.cerrarModalCrearObservacionLibre} id_usuario={this.state.loi_id}></UsuarioCrearObservacionLibre> : null}
        {this.state.mostrarModalObservacionLibre ? <VerObservacionesLibre ocultar={this.cerrarModalObservacionLibre} id_usuario={this.state.loi_id}></VerObservacionesLibre> : null}
        {this.state.mostrarModalTelefonos ? <VerTelefonos ocultar={this.cerrarModalTelefonos} id_usuario={this.state.loi_id}></VerTelefonos> : null}
        {this.state.mostrarModalEditarRut ? <EditarRutListaOirs ocultar={this.cerrarModalEditarRut} id_usuario={this.state.loi_id} rut={this.state.loi_rut}></EditarRutListaOirs> : null}
        {this.state.mostrarModalEditarFechaRe ? <EditarFechaRegistro ocultar={this.cerrarModalEditarFecha} id_usuario={this.state.loi_id} fecha_inv={this.state.loi_fecha_registro}></EditarFechaRegistro> : null}
        {this.state.modalUsuario ? <VistaUsuarios ocultar={this.ocultarModalUsuario} usuario={this.state.id_usuario}></VistaUsuarios> : null}
        {this.state.mostrarDetalle ? <Ver_detalle_llamadas ocultar={this.cerrarModaldetalle} id_oirs={this.state.loi_id}></Ver_detalle_llamadas> : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ListaDeAltas);
