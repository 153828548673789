import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components

import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import DetalleCanal from "./DetalleCanal.js";
import Audio from "./../Helpers/EscucharAudio.js";
import MaterialTable from "material-table";
import { store } from "./../../store/index";
import Grid from "@material-ui/core/Grid";
import ficha from "../../assets/img/usuarios/ficha-medica.svg";
import play from "../../assets/img/usuarios/play.svg";
import icono_vista_7 from "../../assets/img/usuarios/icono_vista_7.svg";

// estilos
//import "../../assets/css/agregar.css";

export default class RegistroCanales extends Component {
  constructor(props) {
    super(props);

    this.state = {
      registros: [],
      formErrors: {},
      detalle: "",
      registro: "",
      detalleCanal: false,
      mostrarAudio: false,
      uspId: store.getState().usp,
      usuId: props.usuario,
      herramientas: store.getState().herramientas,
    };

    this.initialState = this.state;
  }

  componentDidMount() {
    this.traerRegistros();
  }

  mostrarDetalle(detalle, calldate) {
    this.setState({
      registro: calldate,
      detalleCanal: true,
      detalle: detalle,
    });
  }

  mostrarAudio(url, calldate) {
    this.setState({
      registro: calldate,
      mostrarAudio: true,
      grabacion: "http://200.111.252.138/grabaciones/" + url,
    });
  }

  traerRegistros() {
    let salientes = null;
    let entrantes = null;
    if (this.state.herramientas.includes("H1")) {
      salientes = true;
    }
    if (this.state.herramientas.includes("H4")) {
      entrantes = true;
    }
    axios
      .get(global.config.modulos.parametros + "lista_llamados/", {
        headers: authHeader(),
        params: {
          usuario: this.state.usuId,
          salientes: salientes,
          entrantes: entrantes,
        },
      })
      .then((res) => {
        var registros = res.data.datos;

        this.setState({
          registros: registros,
        });
      });
  }

  fechaTexto(dateString) {
    var meses = [" ", "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    var fecha = dateString.split("-");
    var ano = fecha[0];
    var mes = meses[parseInt(fecha[1])];
    var dia = fecha[2];

    var fechaCompleta = dia + " de " + mes + " del " + ano;

    return fechaCompleta;
  }

  ocultarDetalle = () => {
    this.setState({ detalleCanal: false });
  };

  ocultarAudio = () => {
    this.setState({ mostrarAudio: false });
  };

  render() {
    return (
      <div className="formDiv" style={{ marginLeft: "-10px", marginBottom: 180 }}>
        {this.state.detalleCanal ? <DetalleCanal ocultar={this.ocultarDetalle} detalle={this.state.detalle} registro={this.state.registro}></DetalleCanal> : null}
        {this.state.mostrarAudio ? <Audio ocultar={this.ocultarAudio} grabacion={this.state.grabacion} registro={this.state.registro}></Audio> : null}
        <Grid container spacing={2} className="fichaUsuario scroll-container">
          <Grid item xs={12}>
            <div>
              <img className="imagenDatoSeleccionado" src={icono_vista_7} style={{ marginTop: 70 }} />
              <div className="datosUsuario " style={{ border: "inherit" }}>
                <div className="divBordeTablaRegistroCanales" style={{ width: "100%", float: "left", marginTop: "-60px" }}>
                  <MaterialTable
                    title=""
                    columns={[
                      { title: "Fecha Registro", field: "calldate" },
                      { title: "Teléfono", field: "telefono" },
                      { title: "Última acción", field: "ultimaPosicion" },
                      { title: "Tipo", field: "nombreTipo" },
                    ]}
                    actions={[
                      {
                        icon: () => <img src={play} style={{ width: 40 }} />,
                        tooltip: "Escuchar audio de la llamada",
                        onClick: (event, rowData) => this.mostrarAudio(rowData["grabacion"], rowData["calldate"]),
                      },
                      {
                        icon: () => <img src={ficha} style={{ width: 40 }} />,
                        tooltip: "Ver detalle de la llamada",
                        onClick: (event, rowData) => this.mostrarDetalle(rowData["cdrDetalle"], rowData["calldate"]),
                      },
                    ]}
                    data={this.state.registros}
                    options={{
                      actionsColumnIndex: -1,
                      search: true,
                    }}
                    localization={{
                      header: {
                        actions: "Acciones",
                      },
                      pagination: {
                        labelDisplayedRows: "{from}-{to} de {count}",
                        labelRowsSelect: "filas",
                        firstTooltip: "primera",
                        previousTooltip: "anterior",
                        nextTooltip: "siguiente",
                        lastTooltip: "última",
                      },
                      toolbar: {
                        nRowsSelected: "{0} fila(s) seleccionadas",
                        searchTooltip: "Buscar",
                        searchPlaceholder: "Buscar",
                      },
                      body: {
                        emptyDataSourceMessage: "No hay registros de llamadas",
                        filterRow: {
                          filterTooltip: "Buscar",
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
