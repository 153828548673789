import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "./../../store/index";

// core components
import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

// estilos
import "assets/css/general.css";
import "assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class ModalExisteCaso extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      uspId: parseInt(store.getState().usp),
      open: true,
      mensaje: props.mensaje.mensaje,
      gestion: props.mensaje.gestion,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { mensaje, gestion } = this.state;
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="alertaHTML">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="">
              <GridContainer className="agendar-container">
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  {mensaje}
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  {gestion.map((reg, index) => (
                    <p
                      key={index}
                      style={{
                        marginTop: 10,
                        borderRadius: 10,
                        border: "2px solid #eaa028",
                        padding: 15,
                      }}
                    >
                      <GridContainer className="agendar-container">
                        <GridItem xs={6} style={{ marginTop: 10, marginBottom: 10 }}>
                          <p>
                            <label style={{ fontWeight: "bold" }}>Fecha de ingreso:</label> {reg.ged_fecha_registro ? reg.ged_fecha_registro.replace("T", " ") : ""}
                          </p>
                          <p>
                            <label style={{ fontWeight: "bold" }}>Cesfam:</label> {reg.cesfam}
                          </p>
                          <p>
                            <label style={{ fontWeight: "bold" }}>Ubicación:</label> {reg.ubicacion}
                          </p>
                        </GridItem>
                        <GridItem xs={6} style={{ marginTop: 10, marginBottom: 10 }}>
                          <p>
                            <label style={{ fontWeight: "bold" }}>Tomado por:</label> {reg.tomado}
                          </p>
                        </GridItem>
                      </GridContainer>
                    </p>
                  ))}
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <a onClick={this.salir} style={{ marginLeft: 20 }} className="btnAgregarCaso fondoNaranjo">
                    Aceptar
                  </a>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
