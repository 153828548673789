import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "store";
import axios from "axios";

// core components

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";

// CSS
import FuncionarioCSS from "assets/css/funcionario.module.css";
import SolicitudCSS from "assets/css/solicitudTrabajo.module.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import crearTotem from "assets/css/crearTotem.module.css";

//IMG
import Editar from "assets/img/usuarios/icono_modificar.svg";
import Eliminar from "assets/img/usuarios/icono_eliminar.svg";

export default class FuncionariosSectores extends Component {
  constructor(props) {
    super(props);

    this.state = {
      comuna: this.props.comuna,

      cargando: false,
      usp_id: null,
      usuariosPanel: [],
      modificarUsuarioPanel: false,

      cliente: store.getState().cliente,
      nombreusp: store.getState().nombreusp,
      usp: store.getState().usp,
      cesfam: store.getState().cesfam,
      herramientas: store.getState().herramientas,
    };

    this.initialState = this.state;
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Administrador de sectores<span title='Sectores'></span>";
  };

  componentDidMount() {
    this.fetchUsuariosPanel();
    this.titulo();
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  fetchUsuariosPanel = async () => {
    const apiUrl2 = global.config.modulos.sectores + "por_cesfam/" + this.state.cesfam;
    var resSec = await axios.get(apiUrl2, {
      headers: authHeader(),
    });
    var sectores = resSec.data.datos;

    var apiUrl = global.config.modulos.usuariospanel + "por_cesfam/" + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var datos = res.data.datos;
      var tabla = [];

      var rowsHead = [];
      rowsHead.push(
        <TableCell className={"tablaRow cellInicio"} key={0}>
          Nombre
        </TableCell>
      );
      rowsHead.push(
        <TableCell className={"tablaRow cellTermino"}>
          <p style={{ textAlign: "center" }}>Rol </p>
        </TableCell>
      );

      for (var x = 0; x < sectores.length; x++) {
        if (sectores[x]["sec_nombre"] != "Universal") {
          rowsHead.push(
            <TableCell className={x == sectores.length - 1 ? "tablaRow cellTermino" : "tablaRow"}>
              <p style={{ textAlign: "center" }}>{sectores[x]["sec_nombre"]} </p>
            </TableCell>
          );
        }
      }

      tabla.push(
        <TableHead>
          <TableRow>{rowsHead}</TableRow>
        </TableHead>
      );

      var rows = [];
      for (var j = 0; j < datos.length; j++) {
        if (this.state.usp_id) {
          if (datos[j]["usp_id"] != this.state.usp_id) {
            continue;
          }
        }
        var cell = [];
        cell.push(<TableCell className={"tablaRow"}>{datos[j]["nombrecompleto"]}</TableCell>);
        cell.push(<TableCell className={"tablaRow"}>{datos[j]["rolnombre"]}</TableCell>);
        for (var x = 0; x < sectores.length; x++) {
          if (sectores[x]["sec_nombre"] != "Universal") {
            cell.push(
              <TableCell className={"tablaRow"}>
                <FormControlLabel name={sectores[x]["sec_nombre"]} value={datos[j]["usp_id"] + ";" + datos[j]["usp_sector"] + ";" + sectores[x]["sec_id"]} control={<Checkbox className={crearTotem.checkbox} />} checked={datos[j]["usp_sector"] != null && datos[j]["usp_sector"] != "" && datos[j]["usp_sector"].split(",").includes(sectores[x]["sec_id"].toString())} onChange={this.enviarFormulario} />
              </TableCell>
            );
          }
        }
        rows.push(<TableRow className={"tablaRow"}>{cell}</TableRow>);
      }
      tabla.push(<TableBody>{rows}</TableBody>);
      this.setState({
        usuariosPanel: res.data.datos,
        tabla: (
          <Table stickyHeader className="bordeTabla" size="small" aria-label="a dense table">
            {tabla}
          </Table>
        ),
      });
    });
  };

  enviarFormulario = (event) => {
    const { value } = event.target;

    var opciones = value.split(";");
    var sector = opciones[2];
    var sectores = "";
    var uspId = opciones[0];
    if (opciones[1] != "null" && opciones[1] != "") {
      var opc = opciones[1].split(",");
      var check = event.target.checked;
      if (check == true) {
        opc.push(sector);
      } else {
        const index = opc.indexOf(sector);
        if (index > -1) {
          opc.splice(index, 1);
        }
      }
      for (var i = 0; i < opc.length; i++) {
        sectores += opc[i] + ",";
      }
      sectores = sectores.substring(0, sectores.length - 1);
    } else {
      sectores = sector;
    }

    var data = {
      usp_sector: sectores,
    };
    let apiUrl = global.config.modulos.usuariospanel + "por_id/" + uspId;
    const options = {
      method: "PATCH",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options)
      .then((res) => {
        this.fetchUsuariosPanel();
      })
      .catch((error) => {});
  };

  render() {
    const traerDatos = (res) => {
      if (res) {
        this.setState(
          {
            tabla: "",
            usp_id: res.usp_id,
          },
          () => {
            this.fetchUsuariosPanel();
          }
        );
      } else {
        this.setState(
          {
            tabla: "",
            usp_id: null,
          },
          () => {
            this.fetchUsuariosPanel();
          }
        );
      }
    };

    return (
      <div className="middle-area" style={{ width: "85%" }}>
        <div className="middle-content">
          <React.Fragment>
            <Grid item xs={12} sm={12} md={12}>
              <Grid item xs={12} sm={3} md={3} style={{ marginTop: "16px" }}>
                <InputLabel className={crearTotem.textosTitulos}>Funcionario</InputLabel>
                <Autocomplete
                  disablePortal
                  id="combo-box-comuna"
                  options={Object.values(this.state.usuariosPanel)}
                  getOptionLabel={(option) => option.nombrecompleto}
                  onChange={function (event, res) {
                    if (res != null) {
                      traerDatos(res);
                    } else {
                      traerDatos();
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Seleccionar"
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                ></Autocomplete>
              </Grid>
              <br></br>
              <div id="tablahorarioProfesional" className="cargarHorario">
                <TableContainer style={{ maxHeight: 600 }}>{this.state.tabla}</TableContainer>
              </div>
            </Grid>
          </React.Fragment>
        </div>
      </div>
    );
  }
}
