import React, { useEffect, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// Intervalo de la cuenta regresiva del Modal
const CuentaRegModal = (conteo) => {
  const [timer, setTimer] = useState(conteo);
  useEffect(() => {
    const myInterval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    if (timer === 0) {
      clearInterval(myInterval);
      return timer;
    }
    return () => {
      clearInterval(myInterval);
    };
  });
  return timer;
};

export default CuentaRegModal;
