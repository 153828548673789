import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import GridItem from "../../components/grid/GridItem.js";

import GridContainer from "../../components/grid/GridContainer.js";
import { Dialog, DialogContent } from "@material-ui/core/";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import ModalPoliconsultanteDetalles from "./ModalPoliconsultanteDetalles.js";
import axios from "axios";
import { store } from "../../store/index";
class ModalPoliconsultante extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      especialidad: "",
      contador: 0,
      especialidad_detalles: props.especialidad_detalles,
      especialidad_id: props.especialidad_id,
      showModalPoliconsultanteDetalles: false,
      usuario_id: props.id_usuario,
      datos_detalle: [],
      cesfam: store.getState().cesfam,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };
  componentDidMount() {
    this.cantidadPolis();
  }
  cantidadPolis = () => {
    axios
      .get(global.config.modulos.reportes + "listado_policonsultante/cantidadpoliconsulta/", {
        headers: authHeader(),
        params: {
          usuario_id: this.state.usuario_id,
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            datos_detalle: res.data.datos,
          });
        }
      });
  };

  showModalPoliconsultanteDetalles = (usuario_id, especialidad_id) => {
    this.setState({
      showModalPoliconsultanteDetalles: true,
      especialidad_id: especialidad_id,
      usuario_id: usuario_id,
    });
  };

  hideModalPoliconsultanteDetalles = () => {
    this.setState({ showModalPoliconsultanteDetalles: false });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="beneficioUsuario">
          <DialogContent>
            <GridContainer className="agendar-container">
              <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
                <img alt="" class="marca-agua"></img>
                <GridContainer className="agendar-container">
                  <GridItem xs={12} style={{ marginTop: 10 }}>
                    <div className="modal-ver-mas-titulo">
                      <h2>Total policonsultantes por especialidad</h2>
                    </div>
                  </GridItem>
                  <div>
                    {this.state.datos_detalle.length > 0 ? (
                      <div className="flex-container">
                        {this.state.datos_detalle.map((esp, index) => (
                          <div key={index}>
                            <div className="modal-ver-mas-contador" key={esp.age_usuario} onClick={() => this.showModalPoliconsultanteDetalles(esp.age_usuario, esp.age_especialidad)}>
                              {esp.veces}
                            </div>
                            <div className="modal-ver-mas-nombre">{esp.especialidad}</div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>No hay informacion</div>
                    )}
                  </div>
                  <GridItem xs={12} style={{ marginTop: 30 }}>
                    <button id="modalEliminarBtnCancelar" className="boton-cerrar" onClick={this.salir} style={{ marginLeft: 20 }}>
                      Cerrar
                    </button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
        {this.state.showModalPoliconsultanteDetalles ? <ModalPoliconsultanteDetalles ocultar={this.hideModalPoliconsultanteDetalles} especialidad_id={this.state.especialidad_id} usuario_id={this.state.usuario_id}></ModalPoliconsultanteDetalles> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ModalPoliconsultante);
