import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "./../../store/index";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Titulo from "./../../components/titulo/Titulo.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import MaterialTable from "material-table";
import IngresarBloqueoProfesional from "./IngresarBloqueoProfesional";
import IngresarBloqueoGeneral from "./IngresarBloqueoGeneral";
import ExcepcionBloqueo from "./ExcepcionBloqueo";
import ModificarExcepcion from "./ModificarExcepcion";
import ModificarBloqueo from "./ModificarBloqueo";
import ModificarBloqueoProfesional from "./ModificarBloqueoProfesional";
import VerBloqueo from "./VerBloqueo";
import VerBloqueoProfesional from "./VerBloqueoProfesional";
import icono_bloqueo_profesional from "../../assets/img/bloqueo_profesional.svg";
import icono_bloqueo_general from "../../assets/img/bloqueo_general.svg";
import { showLoader, hideLoader } from "../../store/actions/actions";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { Visibility, Delete, Edit, AddCircle, SettingsApplications } from "@material-ui/icons/";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import signo from "./../../assets/img/signo.svg";

import vista_ingreso from "assets/img/gestion/vista_ingreso.svg";
import vista_finalizado from "assets/img/gestion/vista_tomados.svg";
import vista_eliminados from "assets/img/gestion/vista_finalizados.svg";

import vista_ingreso_activa from "assets/img/gestion/vista_ingreso_activa2.svg";
import vista_finalizado_activa from "assets/img/gestion/vista_finalizados_activa2.svg";
import vista_eliminados_activa from "assets/img/gestion/vista_eliminados_activa.svg";

import "./../../assets/css/bloqueos.css";
import ModalExcepciones from "./ModalExcepciones";

class ListaBloqueos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cesfam: store.getState().cesfam,
      uspId: store.getState().usp,

      rec_id: 0,
      bloqueos: [],
      profesional: "",
      profesionales: [],
      tipo: 1,

      siglasHerramienta: ["H35"],
      herramientas: store.getState().herramientas,

      verBloqueo: false,
      verBloqueoProfesional: false,
      excepcionBloqueo: false,
      modificarExcepcion: false,
      modificarBloqueo: false,
      modificarBloqueoProfesional: false,
      agregarBloqueo: false,
      agregarBloqueoProfesional: false,
    };
  }

  componentDidMount() {
    this.traerProfesionales();
    this.fetchBloqueos(1, "");
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Gestión de bloqueos <span title='Permite ingresar bloqueos en la agenda del centro de salud para que los horarios no sean utilizados'><img src=" + signo + "></img></span>";
  };

  eliminarBloqueoConfirmar(recId) {
    confirmAlert({
      title: "¿Seguro(a) que deseas eliminar el bloqueo?",
      message: "",
      buttons: [
        {
          label: "Aceptar",
          onClick: () => this.eliminarBloqueo(recId),
        },
        {
          label: "Cancelar",
        },
      ],
    });
  }

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  }

  fetchBloqueos = async (tipo, profesional = "") => {
    this.props.dispatch(showLoader());

    const apiUrl = global.config.modulos.agendamientos + "lista_bloqueos/" + this.state.cesfam + "?tipo=" + tipo + "&profesional=" + profesional;
    axios
      .get(apiUrl, { headers: authHeader() })
      .then((res) => {
        this.props.dispatch(hideLoader());

        var bloqueo = [];

        var bloqueos = res.data["datos"];
        var cantidadBloqueos = bloqueos.length;
        for (var i = 0; i < cantidadBloqueos; i++) {
          var fechas = bloqueos[i]["rec_fecha_texto"];
          var clasificacion = bloqueos[i]["rec_clasificacion_fecha"];
          var motivoBloqueo = bloqueos[i]["rec_motivo_bloqueo"];
          var motivoBloqueoText = bloqueos[i]["rec_motivo_bloqueo_texto"];
          var fechaInicio = bloqueos[i]["rec_fecha_inicio"];
          var fechaTermino = bloqueos[i]["rec_fecha_termino"];
          var permanente = bloqueos[i]["rec_fecha_termino_activa"];
          var tipoReunion = bloqueos[i]["rec_tipo_reunion"];
          var excepcion = bloqueos[i]["excepcion"];

          fechaInicio = moment(fechaInicio).format("DD-MM-YYYY");
          if (permanente) {
            fechaTermino = "Permanente";
          } else {
            fechaTermino = moment(fechaTermino).format("DD-MM-YYYY");
          }

          if (clasificacion == 2) {
            fechas = "Fechas seleccionadas";
            fechaInicio = "--";
            fechaTermino = "--";
          }

          if (motivoBloqueo == "otro") {
            motivoBloqueo = motivoBloqueoText;
          }

          var horaTermino = moment(bloqueos[i]["rec_hora_termino"], "hh:mm:ss").subtract(1, "minutes").format("HH:mm:ss");

          var blo = {
            rec_id: bloqueos[i]["rec_id"],
            rec_tipo: bloqueos[i]["tipo_reunion"],
            rec_fecha_texto: fechas,
            rec_fecha_inicio: fechaInicio,
            rec_fecha_termino: fechaTermino,
            rec_hora_inicio: bloqueos[i]["rec_hora_inicio"],
            rec_hora_termino: horaTermino,
            rec_usuario_panel: bloqueos[i]["rec_usuario_panel"],
            tipoReunion: tipoReunion,
            rec_profesionales: bloqueos[i]["rec_profesionales"],
            motivoBloqueo: motivoBloqueo,
            excepcion: excepcion,
          };
          bloqueo.push(blo);
        }

        this.setState({
          tipo: tipo,
          bloqueos: bloqueo,
        });
      })
      .catch((err) => {
        this.props.dispatch(hideLoader());
      });
  };

  agregarBloqueo = (e) => {
    this.setState({ agregarBloqueo: true });
  };

  ocultarBloqueo = () => {
    this.setState({ agregarBloqueo: false });
    this.fetchBloqueos(this.state.tipo, this.state.profesional);
  };

  agregarBloqueoProfesional = (e) => {
    this.setState({ agregarBloqueoProfesional: true });
  };

  ocultarBloqueoProfesional = () => {
    this.setState({ agregarBloqueoProfesional: false });
    this.fetchBloqueos(this.state.tipo, this.state.profesional);
  };

  excepcionBloqueo = (id, tipoReunion) => {
    this.setState({ excepcionBloqueo: true, rec_id: id });
  };

  ocultarExcepcion = () => {
    this.setState({ excepcionBloqueo: false });
    this.fetchBloqueos(this.state.tipo, this.state.profesional);
  };

  modificarExcepcion = (id, tipoReunion) => {
    if (tipoReunion == 5) {
      this.setState({ modificarBloqueoProfesional: true, rec_id: id });
    } else {
      this.setState({ modificarExcepcion: true, rec_id: id });
    }
  };

  ocultarModificarExcepcion = () => {
    this.setState({ modificarExcepcion: false });
    this.fetchBloqueos(this.state.tipo, this.state.profesional);
  };

  modificarBloqueo = (id, tipoReunion) => {
    if (tipoReunion == 5) {
      this.setState({ modificarBloqueoProfesional: true, rec_id: id });
    } else {
      this.setState({ modificarBloqueo: true, rec_id: id });
    }
  };

  ocultarModificar = () => {
    this.setState({ modificarBloqueo: false });
    this.fetchBloqueos(this.state.tipo, this.state.profesional);
  };

  ocultarModificarProfesional = () => {
    this.setState({ modificarBloqueoProfesional: false });
    this.fetchBloqueos(this.state.tipo, this.state.profesional);
  };

  verBloqueo = (id, tipoReunion) => {
    if (tipoReunion == 5) {
      this.setState({ verBloqueoProfesional: true, rec_id: id });
    } else {
      this.setState({ verBloqueo: true, rec_id: id });
    }
  };

  ocultarVer = () => {
    this.setState({ verBloqueo: false });
  };

  ocultarVerProfesional = () => {
    this.setState({ verBloqueoProfesional: false });
  };

  eliminarBloqueo(recId) {
    this.props.dispatch(showLoader());

    var data = {
      uspId: this.state.uspId,
      cesfam: this.state.cesfam,
      rec_id: recId,
    };
    let apiUrl = global.config.modulos.agendamientos + "eliminar_bloqueos/";
    const options = {
      method: "DELETE",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options)
      .then((res) => {
        this.props.dispatch(hideLoader());
        setTimeout(() => {
          this.mostrarAlerta(res.data.mensaje);
          this.setState({
            bloqueos: this.state.bloqueos.filter((row) => row["rec_id"] !== recId),
          });
        }, 500);
      })
      .catch((err) => {
        this.props.dispatch(hideLoader());
      });
  }

  traerProfesionales() {
    const apiUrl = global.config.modulos.profesionales + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      this.setState({
        profesionales: res.data.datos,
      });
    });
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    this.fetchBloqueos(this.state.tipo, value);
  };

  render() {
    let botonGestionIngreso = this.state.tipo == 1 ? { marginLeft: 20, width: 70, marginRight: 0 } : { marginLeft: 20, width: 40, marginRight: 0 };
    let botonGestionFinalizado = this.state.tipo == 2 ? { marginLeft: 20, width: 70, marginRight: 0 } : { marginLeft: 20, width: 40, marginRight: 0 };
    let botonGestionEliminados = this.state.tipo == 3 ? { marginLeft: 20, width: 70, marginRight: 0 } : { marginLeft: 20, width: 40, marginRight: 0 };

    return (
      <div className="middle-area">
        <div className="middle-content">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Tooltip title="Ingresados" aria-label="Ingresados">
                <img style={botonGestionIngreso} className="imagenBotonAccionMenu" src={this.state.tipo == 1 ? vista_ingreso_activa : vista_ingreso} onClick={() => this.fetchBloqueos(1, this.state.profesional)} />
              </Tooltip>
              <Tooltip title="Finalizados" aria-label="Finalizados">
                <img style={botonGestionFinalizado} className="imagenBotonAccionMenu" src={this.state.tipo == 2 ? vista_finalizado_activa : vista_finalizado} onClick={() => this.fetchBloqueos(2, this.state.profesional)} />
              </Tooltip>
              <Tooltip title="Eliminados" aria-label="Eliminados">
                <img style={botonGestionEliminados} className="imagenBotonAccionMenu" src={this.state.tipo == 3 ? vista_eliminados_activa : vista_eliminados} onClick={() => this.fetchBloqueos(3, this.state.profesional)} />
              </Tooltip>
            </GridItem>

            <GridItem xs={12} sm={12} md={12} id="tablaBloqueos">
              <div className="botonesTablaBloq" style={this.state.tipo != 1 ? { bottom: "-1vw" } : {}}>
                <Select id="profesional" name="profesional" onChange={this.cambiarValores} value={this.state.profesional} displayEmpty style={{ marginLeft: "1vw" }}>
                  <MenuItem value="">
                    <em>Todos</em>
                  </MenuItem>

                  {this.state.profesionales.map((pro, index) => (
                    <MenuItem value={pro.prc_profesional} key={index}>
                      {pro.nombreprofesional}
                    </MenuItem>
                  ))}
                </Select>

                {this.state.tipo == 1 && (
                  <>
                    <img src={icono_bloqueo_general} style={{ width: "100%", cursor: "pointer", marginLeft: "1vw" }} onClick={this.agregarBloqueo} />
                    {this.state.cesfam == 29 && <img src={icono_bloqueo_profesional} style={{ width: "100%", cursor: "pointer", marginLeft: "1vw" }} onClick={this.agregarBloqueoProfesional} />}
                  </>
                )}
              </div>
              <MaterialTable
                title=""
                columns={[
                  { title: "Tipo de bloqueo", field: "rec_tipo" },
                  { title: "Fecha", field: "rec_fecha_texto" },
                  { title: "Profesionales", field: "rec_profesionales", render: (rowData) => rowData.rec_profesionales.substring(0, 6) + "..." },
                  { title: "Fecha inicio", field: "rec_fecha_inicio" },
                  { title: "Fecha término", field: "rec_fecha_termino" },
                  { title: "Hora inicio", field: "rec_hora_inicio" },
                  { title: "Hora término", field: "rec_hora_termino" },
                  { title: "Funcionario", field: "rec_usuario_panel" },
                  { title: "Motivo", field: "motivoBloqueo" },
                  {
                    title: "Acciónes",
                    render: (rowData) => (
                      <React.Fragment>
                        {rowData["excepcion"] != false && rowData["tipoReunion"] != 5 && (
                          <Tooltip title="Ver excepción" onClick={this.modificarExcepcion.bind(this, rowData["rec_id"], rowData["excepcion_tipo"])}>
                            <IconButton style={{ color: "#000" }}>
                              <SettingsApplications />
                            </IconButton>
                          </Tooltip>
                        )}

                        <Tooltip title="Ver bloqueo" onClick={this.verBloqueo.bind(this, rowData["rec_id"], rowData["tipoReunion"])}>
                          <IconButton style={{ color: "#000" }}>
                            <Visibility />
                          </IconButton>
                        </Tooltip>

                        {this.state.tipo == 1 && rowData["tipoReunion"] != 5 && (
                          <Tooltip title="Agregar Excepcion bloqueo" onClick={this.excepcionBloqueo.bind(this, rowData["rec_id"], rowData["tipoReunion"])}>
                            <IconButton style={{ color: "#000" }}>
                              <AddCircle />
                            </IconButton>
                          </Tooltip>
                        )}

                        {this.state.tipo == 1 && (
                          <Tooltip title="Editar bloqueo" onClick={this.modificarBloqueo.bind(this, rowData["rec_id"], rowData["tipoReunion"])}>
                            <IconButton style={{ color: "#000" }}>
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        )}

                        {this.state.tipo == 1 && (
                          <Tooltip title="Eliminar bloqueo" onClick={this.eliminarBloqueoConfirmar.bind(this, rowData["rec_id"])}>
                            <IconButton style={{ color: "#000" }}>
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        )}
                      </React.Fragment>
                    ),
                  },
                ]}
                data={this.state.bloqueos}
                actions={[]}
                options={{
                  actionsColumnIndex: -1,
                  search: true,
                }}
                localization={{
                  header: {
                    actions: "Acciones",
                  },
                }}
              />
              {this.state.agregarBloqueoProfesional ? <IngresarBloqueoProfesional ocultar={this.ocultarBloqueoProfesional}></IngresarBloqueoProfesional> : null}
              {this.state.agregarBloqueo ? <IngresarBloqueoGeneral ocultar={this.ocultarBloqueo}></IngresarBloqueoGeneral> : null}
              {this.state.excepcionBloqueo ? <ExcepcionBloqueo ocultar={this.ocultarExcepcion} configuracion={this.state.rec_id}></ExcepcionBloqueo> : null}
              {this.state.modificarExcepcion ? <ModalExcepciones ocultar={this.ocultarModificarExcepcion} configuracion={this.state.rec_id}></ModalExcepciones> : null}
              {this.state.modificarBloqueo ? <ModificarBloqueo ocultar={this.ocultarModificar} configuracion={this.state.rec_id}></ModificarBloqueo> : null}
              {this.state.modificarBloqueoProfesional ? <ModificarBloqueoProfesional ocultar={this.ocultarModificarProfesional} configuracion={this.state.rec_id}></ModificarBloqueoProfesional> : null}
              {this.state.verBloqueo ? <VerBloqueo ocultar={this.ocultarVer} configuracion={this.state.rec_id}></VerBloqueo> : null}
              {this.state.verBloqueoProfesional ? <VerBloqueoProfesional ocultar={this.ocultarVerProfesional} configuracion={this.state.rec_id}></VerBloqueoProfesional> : null}
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ListaBloqueos);
