import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import InputLabel from "@material-ui/core/InputLabel";
import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";

// estilos
//import "../../assets/css/agregar.css";

export default class CiclosModificar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ciclos: [],
      ciclosUsuario: [],
      especialidades: [],
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      usuId: props.usuario,
      ultimaAtencion: "",
      nivel: "",
      ciclo: props.ciclo,
      nombreCiclo: props.nombreCiclo,
      open: true,
      err: { especialidad: false, especialidadMensaje: "" },
    };
  }
  componentDidMount() {
    this.traeNiveles(this.props.ciclo);
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  traeNiveles = (ciclo) => {
    axios
      .get(global.config.modulos.ciclos + "niveles_por_ciclo/", {
        headers: authHeader(),
        params: { ciclo: ciclo },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ especialidades: res.data.datos });
        }
      });
  };

  agregarDetalle = () => {
    if (this.state.nivel == "" || this.state.ultimaAtencion == "") {
      return NotificationManager.error("Completa todos los campos");
    }
    axios
      .post(
        global.config.modulos.ciclos + "agregar_detalle/",
        {
          ciclo: this.state.ciclo,
          nivel: this.state.nivel,
          usuario: this.state.usuId,
          fecha: this.state.ultimaAtencion,
          usuario_panel: this.state.uspId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success(res.data.mensaje);
          this.salir();
        }
      });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  cambiarValoresNiveles = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, especialidades: [] });

    this.traeNiveles(value);
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="cicloUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
              <img className="marca-agua"></img>
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Modificar ciclo {this.state.nombreCiclo}</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 10 }}>
                  <label>Ingrese la última fecha de atención</label>
                </GridItem>
                <GridItem xs={4} style={{ marginTop: 30 }}>
                  <InputLabel>Especialidad</InputLabel>
                  <p>
                    <select className="selectTerminoBeneficio" name="nivel" value={this.state.nivel} onChange={this.cambiarValores} style={{ marginTop: 15 }}>
                      <option value="">Seleccione especialidad</option>
                      {this.state.especialidades.map((res, index) => (
                        <option key={index} value={res.cin_id}>
                          {res.especialidad}
                        </option>
                      ))}
                    </select>
                  </p>
                </GridItem>
                <GridItem xs={4} style={{ marginTop: 30 }}>
                  <InputLabel>Fecha última atención</InputLabel>
                  <input type="date" name="ultimaAtencion" value={this.state.ultimaAtencion} onChange={this.cambiarValores} placeholder="" className={"inputText fechaBeneficio"} style={{ marginTop: 7 }} />
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={this.salir} style={{ marginLeft: 20 }}>
                    Cerrar
                  </Button>
                  <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo asignarProfesional" onClick={this.agregarDetalle}>
                    Agregar atención
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
