import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AgendarHoraOpciones from "./../../components/Usuarios/AgendarHoraOpciones.js";
import Tooltip from "@material-ui/core/Tooltip";
import { NotificationManager } from "react-notifications";
import copiar from "../../assets/img/usuarios/copiar.svg";

// estilos
import "../../assets/css/usuarios.css";

export default class ModalAgendarHoraGF extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usuId: props.usuario,
      ciclo: null,
      open: true,
      reservador: props.reservador,
      parentesco: props.parentesco,
      rutFamiliar: props.rutFamiliar,
      nombreFamiliar: props.nombreFamiliar,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  copiarRut = (rut) => {
    navigator.clipboard.writeText(rut);
    NotificationManager.info("Rut copiado");
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="agendarHora">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer className="agendar-container">
                <GridItem xs={12} sm={12} md={12}>
                  <h2>Reservar hora</h2>
                </GridItem>
                <GridItem xs={4} style={{ marginTop: 20 }}>
                  <p>
                    <label style={{ fontWeight: "bold" }}>Nombre:</label>
                  </p>{" "}
                  <p>{this.state.nombreFamiliar}</p>
                </GridItem>
                <GridItem xs={4} style={{ marginTop: 20 }}>
                  <p>
                    <label style={{ fontWeight: "bold" }}>RUT:</label>
                  </p>{" "}
                  <p>
                    {this.state.rutFamiliar}
                    &nbsp;&nbsp;&nbsp;
                    <Tooltip title="Copiar RUT del familiar" aria-label="Copiar RUT del familiar">
                      <img
                        style={{
                          width: 18,
                          float: "inherit",
                          marginBottom: -4,
                        }}
                        className="imagenBotonAccion "
                        src={copiar}
                        onClick={() => this.copiarRut(this.state.rutFamiliar)}
                      />
                    </Tooltip>
                  </p>
                </GridItem>
                <GridItem xs={4} style={{ marginTop: 20 }}>
                  <p>
                    <label style={{ fontWeight: "bold" }}>Parentesco:</label>
                  </p>
                  <p>{this.state.parentesco}</p>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <AgendarHoraOpciones usuario={this.state.usuId} ocultar={this.salir} reservador={this.state.reservador} familiar={true}></AgendarHoraOpciones>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
