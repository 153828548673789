import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";

// core components
import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";
import { store } from "./../../store/index";
import chileanRut from "chilean-rut";

// CSS

import GesIngreso from "./GestionGeneralIngreso";
import GesTomados from "./GestionGeneralTomados";
import GesFinalizado from "./GestionGeneralFinalizado";

import vista_ingreso from "assets/img/gestion/vista_ingreso.svg";
import vista_tomados from "assets/img/gestion/vista_tomados.svg";
import vista_finalizado from "assets/img/gestion/vista_finalizados.svg";

import vista_ingreso_activa from "assets/img/gestion/vista_ingreso_activa.svg";
import vista_tomados_activa from "assets/img/gestion/vista_tomados_activa.svg";
import vista_finalizado_activa from "assets/img/gestion/vista_finalizados_activa.svg";

import signo from "assets/img/signo.svg";

import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { Input } from "@material-ui/core";

import NotificationManager from "react-notifications/lib/NotificationManager";

import "assets/css/inicio.css";
import "assets/css/gestion.css";

export default class GestionGeneral extends Component {
  constructor(props) {
    super(props);

    let redireccion_ubicacion = 1;
    if (this.props.history.location.state != undefined) {
      redireccion_ubicacion = this.props.history.location.state.ubicacion;
    }

    this.state = {
      texto_filtro: null,
      alerta: false,
      ubicacion: 1,
      demanda: [],
      gestionIngreso: redireccion_ubicacion == 1 ? true : false,
      gestionProceso: redireccion_ubicacion == 3 ? true : false,
      gestionFinalizado: redireccion_ubicacion == 6 ? true : false,
      nombre_ubicacion: "Ingresados",
      vistaActual: "gestionIngreso",

      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
    };
  }

  componentDidMount() {
    this.titulo();
  }

  titulo = () => {
    //document.getElementsByClassName("content-titulo")[0].innerHTML = "<label style='font-size:25px'>" + this.state.nombre_ubicacion + "</label> <span title='Permite gestionar de forma manual casos que ingresan con distintos requerimientos'><img src=" + signo + "></img></span>";
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Gestion de entrega";
  };

  actualizarVista = (ubicacion, rut, uspFiltro = true) => {
    this.setState({ rutFiltro: rut, uspFiltro: uspFiltro }, () => {
      this.handleChange(ubicacion);
    });
  };

  activarVista = (vistaActivada) => {
    this.setState(
      {
        gestionIngreso: false,
        gestionProceso: false,
        gestionFinalizado: false,
      },
      () => {
        this.setState({ vistaActual: vistaActivada, [vistaActivada]: true }, () => {
          this.setState({ rutFiltro: null, uspFiltro: true });
        });
      }
    );
  };

  handleChange = (vista) => {
    if (vista == 1) {
      this.setState({ nombre_ubicacion: "Ingresados" }, () => {
        this.activarVista("gestionIngreso");
        this.titulo();
      });
    }
    if (vista == 3) {
      this.setState({ nombre_ubicacion: "Tomados" }, () => {
        this.activarVista("gestionProceso");
        this.titulo();
      });
    }
    if (vista == 6) {
      this.setState({ nombre_ubicacion: "Finalizados" }, () => {
        this.activarVista("gestionFinalizado");
        this.titulo();
      });
    }
  };

  mostrarModalAgregarCaso = () => {
    this.setState({
      modalAgregarCaso: true,
    });
  };

  ocultarModalAgregarCaso = () => {
    this.setState({
      modalAgregarCaso: false,
    });
    this.setState({ gestionIngreso: false });
    this.activarVista("gestionIngreso");
  };

  mostrarModalBuscarUsuarioGestion = () => {
    this.setState({
      modalBuscarUsuarioGestion: true,
    });
  };

  ocultarModalBuscarUsuarioGestion = () => {
    this.setState({
      modalBuscarUsuarioGestion: false,
    });
  };

  mostrarMensajeGlobal = () => {
    this.setState({
      modalMensajeGlobal: true,
    });
  };

  ocultarMensajeGlobal = () => {
    this.setState({
      modalMensajeGlobal: false,
      gestionIngreso: false,
      gestionProceso: false,
      gestionFinalizado: false,
    });
    setTimeout(() => {
      this.setState({
        [this.state.vistaActual]: true,
      });
    }, 100);
  };

  busquedaGlobal = () => {
    if (this.state.texto_filtro == null) {
      NotificationManager.error("Escribe el rut");
      return;
    }

    if (!chileanRut.validate(this.state.texto_filtro)) {
      NotificationManager.error("Escribe el rut valido");
      return;
    }

    axios
      .get(global.config.modulos.gestion + "buscar_usuario_global/", {
        headers: authHeader(),
        params: {
          texto_filtro: this.state.texto_filtro,
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        var data = res.data;
        if (data.estado) {
          var datos = {
            gestionIngreso: false,
            gestionProceso: false,
            gestionFinalizado: false,
          };
          if (data.ubicacion == 1) {
            datos["gestionIngreso"] = true;
          } else if (data.ubicacion == 2) {
            datos["gestionProceso"] = true;
          } else if (data.ubicacion == 3) {
            datos["gestionFinalizado"] = true;
          }
          this.setState(datos);
        } else {
          NotificationManager.error("Paciente no se encuentra en ninguna lista", "Error", 3000);
        }
      });
  };

  limpiarBusqueda = () => {
    this.handleChange(1);
    this.setState({
      texto_filtro: "",
    });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    let botonGestionIngreso = this.state.gestionIngreso ? { marginLeft: 20, width: 70, marginRight: 0, marginTop: "-4px" } : { marginLeft: 20, width: 40, marginRight: 0 };
    let botonGestionProceso = this.state.gestionProceso ? { marginLeft: 20, width: 70, marginRight: 0, marginTop: "-5px" } : { marginLeft: 20, width: 40, marginRight: 0 };
    let botonGestionFinalizado = this.state.gestionFinalizado ? { marginLeft: 20, width: 70, marginRight: 0, marginTop: "-5px" } : { marginLeft: 20, width: 40, marginRight: 0 };
    return (
      <div className="middle-area" style={{ width: "85%" }}>
        <GridContainer>
          <GridItem xs={12} style={{ marginTop: 30 }}>
            <Tooltip title="Ingresados" aria-label="Ingresados">
              <img style={botonGestionIngreso} className="imagenBotonAccionMenu" src={this.state.gestionIngreso ? vista_ingreso_activa : vista_ingreso} onClick={() => this.handleChange(1)} />
            </Tooltip>
            <Tooltip title="Tomados" aria-label="Tomados">
              <img style={botonGestionProceso} className="imagenBotonAccionMenu" src={this.state.gestionProceso ? vista_tomados_activa : vista_tomados} onClick={() => this.handleChange(3)} />
            </Tooltip>
            <Tooltip title="Finalizados" aria-label="Finalizados">
              <img style={botonGestionFinalizado} className="imagenBotonAccionMenu" src={this.state.gestionFinalizado ? vista_finalizado_activa : vista_finalizado} onClick={() => this.handleChange(6)} />
            </Tooltip>
            <Input
              type="text"
              name="texto_filtro"
              value={this.state.texto_filtro}
              onChange={this.cambiarValores}
              style={{
                padding: 6,
                float: "left",
                marginLeft: "2%",
                height: "35px",
                width: "20%",
              }}
            />

            <Button
              onClick={this.busquedaGlobal}
              className={"btnOpcion fondoNaranjo"}
              style={{
                marginLeft: "3%",
                fontSize: "12px",
                float: "left",
                textAlign: "center",
                color: "#fff",
              }}
            >
              Buscar
            </Button>

            <Button
              onClick={this.limpiarBusqueda}
              className={"btnOpcion fondoNaranjo"}
              style={{
                marginLeft: "3%",
                fontSize: "12px",
                float: "left",
                textAlign: "center",
                color: "#fff",
              }}
            >
              Limpiar
            </Button>
          </GridItem>
        </GridContainer>

        <div className="middle-content">
          <GridContainer>
            <GridItem xs={12} style={{ margin: 0 }}>
              {this.state.gestionIngreso ? <GesIngreso rutFiltro={this.state.rutFiltro} texto_filtro={this.state.texto_filtro} uspId={this.state.uspId} cesfam={this.state.cesfam} nombre_cesfam={this.state.nombre_cesfam} actualizarVista={this.actualizarVista}></GesIngreso> : null}
              {this.state.gestionProceso ? <GesTomados rutFiltro={this.state.rutFiltro} texto_filtro={this.state.texto_filtro} uspFiltro={this.state.uspFiltro} uspId={this.state.uspId} cesfam={this.state.cesfam} nombre_cesfam={this.state.nombre_cesfam}></GesTomados> : null}
              {this.state.gestionFinalizado ? <GesFinalizado rutFiltro={this.state.rutFiltro} texto_filtro={this.state.texto_filtro} uspFiltro={this.state.uspFiltro} uspId={this.state.uspId} cesfam={this.state.cesfam} nombre_cesfam={this.state.nombre_cesfam} actualizarVista={this.actualizarVista}></GesFinalizado> : null}
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
