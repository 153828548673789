import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";

// core components
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TextField from "@material-ui/core/TextField";
import { debounce } from "lodash";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import NotificationManager from "react-notifications/lib/NotificationManager";
import AgregarDifusion from "../Difusiones/AgregarDifusion";

// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default class AsistidaGeneral extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfamId: store.getState().cesfam,

      open: true,

      atencionOpciones: [],
      atencionSelect: null,

      cesfams: [],
      cesfamSelect: null,

      sectores: [],
      sectorSelect: null,

      cantidad: null,

      fechaSelect: null,

      cesfam: false,
      sector: false,

      errorValid: false,
    };
  }

  componentDidMount = async () => {
    await this.obtenerListaCesfams();
    this.cargarData();
  };

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  obtenerListaSectores = async (cesId) => {
    let apiUrl = global.config.modulos.metas + "lista_sectores_metas/?cesfam=" + cesId;
    var res = await axios(apiUrl, { headers: authHeader() });
    let sec = [];
    let val = 0;
    res.data.sectores.map((item) => {
      sec.push({
        label: item.sec_nombre,
        val: val,
        id: item.sec_id,
        name: "sectorSelect",
      });
      val++;
    });
    return sec;
  };

  obtenerListaCesfams = async () => {
    let apiUrl = global.config.modulos.metas + "lista_cesfams_metas/?cesfam=" + this.state.cesfamId;
    var res = await axios(apiUrl, { headers: authHeader() });
    let ces = [];
    let val = 0;
    this.setState({ comunaSelect: res.data.comuna });
    res.data.cesfams.map((item) => {
      ces.push({
        label: item.ces_nombre,
        val: val,
        id: item.ces_id,
        name: "cesfamSelect",
      });
      val++;
    });
    return ces;
  };

  cambiarValores = (e) => {
    let { name, value } = e.target;

    const quitarNumeros = ["nombre"];
    if (quitarNumeros.includes(name)) {
      value = value.replace(/[0-9]/g, "");
    }
    const soloNumeros = ["cantidad"];
    if (soloNumeros.includes(name)) {
      value = value.replace(/[^0-9]/g, "");
    }

    this.setState({ [name]: value });
  };

  cambiarSeleccion(event, value) {
    const name = value.name;
    this.setState({ [name]: value }, async () => {
      switch (name) {
        case "cesfamSelect":
          let ces_sec = await this.obtenerListaSectores(value.id);
          this.setState({ sectores: ces_sec, sectorSelect: null, especialidadSelect: null }, () => {
            //this.obtenerListaEspecialidades();
          });
          break;

        case "especialidadSelect":
          this.setState({ atencionSelect: null }, () => {
            this.obtenerListaAtenciones();
          });
      }
    });
  }

  validaciones() {
    let formErrors = {};
    let validado = true;

    const { atencionSelect, cesfamSelect, sectorSelect, cantidad, fechaSelect } = this.state;

    if (!atencionSelect) {
      formErrors["atencionErr"] = "Debe seleccionar una atencion";
      validado = false;
    }

    if (!cesfamSelect) {
      formErrors["cesfamErr"] = "Debe seleccionar un cesfam";
      validado = false;
    }

    if (!sectorSelect) {
      formErrors["sectorErr"] = "Debe seleccionar un sector";
      validado = false;
    }

    if (cantidad == "") {
      formErrors["cantidadErr"] = "Debe ingresar una cantidad";
      validado = false;
    }

    if (!fechaSelect) {
      formErrors["fechaErr"] = "Debe ingresar una fecha valida";
      validado = false;
    }

    this.setState({ errorValid: formErrors });
    return validado;
  }

  cargarData = async () => {
    const met_id = this.props.met.met_id;
    const ces = this.props.met.met_cesfam ? this.props.met.met_cesfam : this.state.cesfamId;
    let apiUrl = global.config.modulos.metas + "meta_por_id/" + met_id + "?cesfam=" + ces;
    axios.get(apiUrl, { headers: authHeader() }).then(async (res) => {
      let atencionOpciones = [];
      let at_val = 0;
      res.data.atencion.map((item) => {
        atencionOpciones.push({
          label: item.atencion,
          val: at_val,
          id: item.id,
          name: "atencionSelect",
        });
        at_val++;
      });
      let sector = res.data.meta.met_area === 3 ? true : false;
      let cesfam = res.data.meta.met_area === 2 || res.data.meta.met_area === 3 ? true : false;
      //let comuna = (res.data.meta.met_area === 1) ? true : false;
      let cesfamSelect = res.data.meta.met_area === 1 ? null : { label: res.data.cesfam, val: 0, id: res.data.meta.met_cesfam, name: "cesfamSelect" };
      let sectorSelect = res.data.meta.met_area === 2 ? null : { label: res.data.sector, val: 0, id: res.data.meta.met_sector, name: "sectorSelect" };
      let cesfams = res.data.meta.met_area === 1 ? await this.obtenerListaCesfams() : [];
      let sectores = res.data.meta.met_area === 1 || res.data.meta.met_area === 2 ? await this.obtenerListaSectores(ces) : [];

      this.setState({
        atencionOpciones: atencionOpciones,
        sector: sector,
        cesfam: cesfam,
        //comuna: comuna,
        cesfamSelect: cesfamSelect,
        sectorSelect: sectorSelect,
        cesfams: cesfams,
        sectores: sectores,
      });
    });
  };

  cambioFecha = (e) => {
    this.setState({ fechaSelect: e });
  };

  guardarAsisGeneral() {
    if (this.validaciones()) {
      var data = {
        mag_tipo_atencion: this.state.atencionSelect.id,
        mag_sector: this.state.sectorSelect.id,
        mag_cantidad: this.state.cantidad,
        mag_fecha: this.state.fechaSelect,
        mag_usp: this.state.uspId,
      };
      let apiUrl = global.config.modulos.metas + "agregar_asistida_general/" + this.props.met.met_id;
      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };
      axios(apiUrl, options).then((res) => {
        NotificationManager.info("Asistida General creada correctamente");
        this.salir();
      });
    }
  }

  render() {
    const { atencionErr, cesfamErr, sectorErr, cantidadErr, fechaErr } = this.state.errorValid;
    const cargarArchivo = (e) => {
      this.setState({
        archivo: e,
      });
    };

    return (
      <>
        <Dialog open={this.state.open} onClose={this.salir} className="modificarUsuario">
          <DialogContent>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={12} style={{ marginTop: 10, marginLeft: 10 }}>
                  <h1>Asistida Manual General</h1>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={12} style={{ marginTop: 10 }}>
                  <TableCell style={{ textAlign: "center" }}>
                    <h3>Nombre Meta:</h3>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <h3 style={{ fontWeight: "normal" }}> {this.props.met.met_nombre} </h3>
                  </TableCell>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={4} style={{ marginTop: 10, marginLeft: 10 }}>
                  <Autocomplete name="atencion" disableClearable id="periodoAtencion" options={this.state.atencionOpciones} getOptionLabel={(option) => option.label} onChange={this.cambiarSeleccion.bind(this)} value={this.state.atencionSelect} renderInput={(params) => <TextField {...params} label="Tipo de Atencion" />}></Autocomplete>
                  {atencionErr ? <div style={{ color: "red", paddingBottom: 10 }}>{atencionErr}</div> : null}
                </GridItem>
                <GridItem xs={4} style={{ marginTop: 10, marginLeft: 10 }}>
                  <Autocomplete name="cesfam" disabled={this.state.cesfam} disableClearable id="cesfam" options={this.state.cesfams} getOptionLabel={(option) => option.label} onChange={this.cambiarSeleccion.bind(this)} value={this.state.cesfamSelect} renderInput={(params) => <TextField {...params} label="Cesfam" />}></Autocomplete>
                  {cesfamErr ? <div style={{ color: "red", paddingBottom: 10 }}>{cesfamErr}</div> : null}
                </GridItem>
                <GridItem xs={3} style={{ marginTop: 10, marginLeft: 10 }}>
                  <Autocomplete name="sector" disabled={this.state.sector} disableClearable id="sector" options={this.state.sectores} getOptionLabel={(option) => option.label} onChange={this.cambiarSeleccion.bind(this)} value={this.state.sectorSelect} renderInput={(params) => <TextField {...params} label="Sector" />}></Autocomplete>
                  {sectorErr ? <div style={{ color: "red", paddingBottom: 10 }}>{sectorErr}</div> : null}
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer className="">
                <GridItem xs={4} style={{ marginTop: 25, marginLeft: 10 }}>
                  <InputLabel>Cantidad</InputLabel>
                  <input type="text" maxLength={3} name="cantidad" value={this.state.cantidad} onChange={this.cambiarValores} placeholder="" className="inputText" />
                  {cantidadErr ? <div style={{ color: "red", paddingBottom: 10 }}>{cantidadErr}</div> : null}
                </GridItem>
                <GridItem xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <KeyboardDatePicker
                      style={{ marginTop: 25 }}
                      disableToolbar
                      invalidDateMessage="¡Fecha mal ingresada, por favor inténtalo nuevamente!"
                      disableFuture="true"
                      views={["year", "month", "date"]}
                      value={this.state.fechaSelect}
                      initialFocusedDate={this.state.fechaSelect}
                      format="yyyy-MM-dd"
                      id="date-picker-inline"
                      label="Fecha"
                      name="fecha"
                      onChange={(e) => this.cambioFecha(e)}
                      KeyboardButtonProps={{
                        "aria-label": "cambiar fecha",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {fechaErr ? <div style={{ color: "red", paddingBottom: 10 }}>{fechaErr}</div> : null}
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <GridItem xs={12} style={{ marginTop: 30, display: "flex", justifyContent: "flex-end" }}>
                <Button style={{ marginRight: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} className="fondoVerde" onClick={this.guardarAsisGeneral.bind(this)}>
                  Confirmar
                </Button>
                <Button style={{ marginLeft: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={this.salir}>
                  Cancelar
                </Button>
              </GridItem>
            </GridItem>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}
