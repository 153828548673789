import React, { useLayoutEffect } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5locales_es_ES from "@amcharts/amcharts5/locales/es_ES";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import "../../../src/assets/css/graficosDM.css";
import ModalGraficoOferta from "../../components/Reportes/ModalGraficoOferta.js";
export default function CargaGraficoOferta(props) {
  useLayoutEffect(() => {
    let root = am5.Root.new("consolidado");
    root._logo.dispose();

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
        pinchZoomX: true,
      })
    );

    chart.children.unshift(
      am5.Label.new(root, {
        paddingTop: 12,
        paddingBottom: -5,
        text: `Oferta / demanda de la semana `,
        fontSize: 20,
        x: am5.percent(40),
        y: am5.percent(-5.5),
      })
    );

    chart.get("colors").set("colors", [am5.color("#09A6D1"), am5.color("#DD1F95"), am5.color("#4FC9BA"), am5.color("#225093"), am5.color("#8156C1"), am5.color("#A00A47")]);

    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", false);

    let data = props.data;

    let xRenderer = am5xy.AxisRendererX.new(root, {});
    xRenderer.grid.template.set("location", 0.5);
    xRenderer.labels.template.setAll({
      location: 0.5,
      multiLocation: 0.5,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "Fecha",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxPrecision: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          inversed: false,
        }),
      })
    );

    function createSeries(name, field) {
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          categoryXField: "Fecha",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "[bold]{name}[/]\n{categoryX}: {valueY}",
            fill: am5.color(0xff5566),
            autoTextColor: false,
          }),
        })
      );

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 5,
            fill: series.get("fill"),
          }),
        });
      });

      series.set("setStateOnChildren", true);
      series.states.create("hover", {});

      series.mainContainer.set("setStateOnChildren", true);
      series.mainContainer.states.create("hover", {});

      series.strokes.template.states.create("hover", {
        strokeWidth: 4,
      });

      series.data.setAll(data);
      series.appear(1000);
    }

    createSeries("Oferta Inicial", "Oferta Inicial");
    createSeries("Bloqueos", "Bloqueos");
    createSeries("Oferta Final", "Oferta Final");
    createSeries("Reservas", "Reservas");
    createSeries("Anulaciones", "Anulaciones");
    createSeries("Demanda", "Demanda");

    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
        marginBottom: 20,
      })
    );

    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    legend.itemContainers.template.states.create("hover", {});

    legend.itemContainers.template.events.on("pointerover", function (e) {
      e.target.dataItem.dataContext.hover();
    });
    legend.itemContainers.template.events.on("pointerout", function (e) {
      e.target.dataItem.dataContext.unhover();
    });

    legend.data.setAll(chart.series.values);

    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: data,
      title: ``,
      pdfOptions: {
        imageFormat: "jpg",
        includeData: true,
        addColumnNames: true,
      },
      htmlOptions: {
        disabled: true,
      },
      jpgOptions: {
        disabled: true,
      },
      csvOptions: {
        disabled: true,
      },
      jsonOptions: {
        disabled: true,
      },
      printOptions: {
        disabled: true,
      },
      xlsxOptions: {
        imageFormat: "jpg",
        includeData: true,
        addColumnNames: true,
      },
      pngOptions: {
        disabled: true,
      },
      pdfdataOptions: {
        disabled: true,
      },
      filePrefix: `Oferta / demanda desde ${props.data[0]["Fecha"]} hasta ${props.data[6]["Fecha"]}`,
    });

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, []);
  return (
    <>
      <div id={"consolidado"} style={{ width: "98%", height: "580px", marginBottom: "8%", marginTop: "-3%" }}></div>
    </>
  );
}
