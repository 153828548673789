import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import CircularProgress from "@material-ui/core/CircularProgress";

class CargandoSimple extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0%",
            left: "0%",
            background: "black",
            opacity: 0.1,
            zIndex: "20",
          }}
        ></div>
        <CircularProgress style={{ width: "50px", height: "50px", position: "absolute", zIndex: "999", top: "50%", left: "50%" }} />
      </>
    );
  }
}

export default CargandoSimple;
