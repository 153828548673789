import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";
/* eslint-disable no-use-before-define */

import { store } from "store/index";
import { Grid } from "@material-ui/core";

import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/grid/GridItem.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";

import { confirmAlert } from "react-confirm-alert";

// CSS
import "react-confirm-alert/src/react-confirm-alert.css";
import SolicitudTrabajo from "assets/css/solicitudTrabajo.module.css";

//JS
import ModalAlerta from "./modalAlerta";

export default class IngresarSolicitud extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rut: "",
      nombre: "",
      comuna: "",
      centro: "",
      tel: "",
      correo: "",
      perfil: "",
      validarCorreo: 0,
      validarTelefono: 0,

      usuariopanel: store.getState().usp,

      modalDeAlerta: false,
      camposErr: {},
    };
  }

  componentDidMount() {
    this.datosUsuarios();
  }

  cerrarModal = (tipo) => {
    if (tipo == 1) {
      this.setState({
        soli_enviada: false,
      });
      this.props.cambiarVista("", 2);
    } else if (tipo == 2) {
      this.setState({
        modalDeAlerta: false,
      });
    }
  };

  datosUsuarios = () => {
    const apiUrl = global.config.modulos.solicitudTrabajo + "info_usuario/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          uspId: this.state.usuariopanel,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            rut: res.data.datos["usp_rut"],
            nombre: res.data.datos["nombre_apellido"],
            comuna: res.data.datos["comuna"],
            centro: res.data.datos["centro_salud"],
            tel: res.data.datos["usp_telefono"],
            correo: res.data.datos["usp_email"],
            perfil: res.data.datos["rol"],
            jefe: res.data.datos["jefatura"],
          });
        } else {
        }
      });
  };

  mostrarAlerta = (mensaje) => {
    confirmAlert({
      title: mensaje,
      buttons: [{ label: "Aceptar" }],
    });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  cambiarValores_rut = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  validaciones = () => {
    const { tel, correo } = this.state;
    let validacion = true;
    const camposErr = {};
    if (!tel) {
      validacion = false;
      camposErr["telErr"] = "Teléfono";
    }

    if (!correo) {
      validacion = false;
      camposErr["correoErr"] = "Correo electrónico";
    } else {
      if (correo && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(correo)) {
        validacion = false;
        camposErr["correoErr"] = "Correo electrónico inválido";
      }
    }

    this.setState({ camposErr: camposErr });

    if (validacion) {
      this.siguiente();
    } else {
      this.modalError(camposErr);
    }
  };

  siguiente = () => {
    const apiUrl = global.config.modulos.solicitudTrabajo + "info_usuario/";
    let data = {
      usu_id: this.state.usuariopanel,
      usu_tel: this.state.tel,
      usu_correo: this.state.correo,
    };
    axios.patch(apiUrl, data, { headers: authHeader() }).then((res) => {});
    this.setState({ validarTelefono: 0 });
    this.setState({ validarCorreo: 0 });
    this.props.siguiente(true, "", "");
  };

  modalError = (err) => {
    const contenido = [];
    contenido.push(
      <div>
        <h2 style={{ color: "black", fontSize: "3rem" }}>¡Atención!</h2>
        <br />
        <p style={{ fontSize: "1.2rem" }}>Para continuar debes ingresar los siguientes datos faltantes:</p>
        <br />
        {Object.entries(err).map(([key, value], index) => (
          <div key={index}>
            <li style={{ fontSize: "1.2rem", fontWeight: "bold" }}>{value}</li>
          </div>
        ))}
        <br />
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <button onClick={() => this.cerrarModal(2)} className={SolicitudTrabajo.btnAcciones} style={{ width: "150px" }}>
            Aceptar
          </button>
        </div>
      </div>
    );
    this.setState({ modalDeAlerta: true, contenido: contenido });
  };

  render() {
    const { telErr, correoErr } = this.state.camposErr;

    return (
      <>
        <div>
          <GridItem xs={12} sm={12} md={12}>
            <Grid item xs={12} sm={12} className={SolicitudTrabajo.contenedorFormIngreso}>
              <Grid item xs={3} sm={3} style={{ display: "flex", flexDirection: "column" }}>
                <InputLabel style={{ marginBottom: 5 }}>RUT</InputLabel>
                <input
                  type="text"
                  id="rut"
                  name="rut"
                  disabled
                  value={this.state.rut}
                  onChange={this.cambiarValores_rut}
                  className={SolicitudTrabajo.inputIngreso}
                  /* style={{ border: rutErr && "solid 8px red" }} */
                />
              </Grid>
              <Grid item xs={3} sm={3} style={{ display: "flex", flexDirection: "column" }}>
                <InputLabel style={{ marginBottom: 5 }}>Nombre Apellido</InputLabel>
                <input type="text" id="nombre" name="nombre" disabled value={this.state.nombre} onChange={this.cambiarValores} className={SolicitudTrabajo.inputIngreso} />
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <InputLabel style={{ marginBottom: 5 }}>Comuna</InputLabel>
                <input type="text" id="comuna" name="comuna" disabled value={this.state.comuna} onChange={this.cambiarValores} className={SolicitudTrabajo.inputIngreso} />{" "}
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InputLabel style={{ marginBottom: 5 }}>Centro de salud</InputLabel>
                <input type="text" id="centro" name="centro" disabled value={this.state.centro} onChange={this.cambiarValores} className={SolicitudTrabajo.inputIngreso} />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} className={SolicitudTrabajo.contenedorFormIngreso}>
              <Grid
                item
                xs={3}
                sm={3}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InputLabel style={{ color: correoErr && "#c1272d", marginBottom: 5 }}>Correo electrónico</InputLabel>
                <input type="email" id="correo" name="correo" value={this.state.correo} onChange={this.cambiarValores} className={`${SolicitudTrabajo.inputIngreso} ${correoErr && SolicitudTrabajo.inputError}`} />
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InputLabel style={{ marginBottom: 5 }}>Perfil</InputLabel>
                <input type="text" id="perfil" name="perfil" disabled value={this.state.perfil} onChange={this.cambiarValores} className={SolicitudTrabajo.inputIngreso} />
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InputLabel style={{ color: telErr && "#c1272d", marginBottom: 5 }}>Teléfono</InputLabel>
                <TextField
                  style={{
                    justifyContent: "center",
                  }}
                  multiline
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <p style={{ color: telErr && "#c1272d", paddingLeft: 10 }}>+56</p>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                  id="telefono_ingresar_solicitud"
                  label=""
                  error={telErr && true}
                  className={`${SolicitudTrabajo.inputIngreso} ${telErr && SolicitudTrabajo.inputError}`}
                  value={this.state.tel}
                  type="number"
                  name="tel"
                  onChange={this.cambiarValores}
                  onInput={(e) => {
                    e.target.value = e.target.value.length <= 9 ? e.target.value : e.target.value.slice(0, -1);
                  }}
                />
              </Grid>
            </Grid>
          </GridItem>
          <div
            style={{
              width: "100%",
              marginTop: "10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <a
              className={this.state.abrirInfo ? "btnAcciones botonAccionActivo" : "btnAcciones"}
              onClick={this.validaciones}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "3rem",
                width: "180px",
                marginTop: "5%",
              }}
            >
              Siguiente
            </a>
          </div>
        </div>
        {this.state.modalDeAlerta ? <ModalAlerta modal={this.state.modalDeAlerta} cerrarModal={this.cerrarModal} contenido={this.state.contenido} /> : null}
      </>
    );
  }
}
