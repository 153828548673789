var _createClass = (function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

var VerifNumero = (function () {
  function VerifNumero() {
    _classCallCheck(this, VerifNumero);
  }

  _createClass(VerifNumero, [
    {
      key: "verificarNumCanales",
      value: function verificarNumCanales(listaTelefonos) {
        var telefonos_sin_GF = [];
        for (var i = 0; i < listaTelefonos.length; i++) {
          if (listaTelefonos[i].grupo_familiar == 0) {
            telefonos_sin_GF.push(listaTelefonos[i]);
          }
        }
        if (telefonos_sin_GF.length < 1) {
          return false;
        } else {
          for (var n = 0, j = telefonos_sin_GF.length; n < j; n++) {
            var soloNumeros = /^\d+$/.test(telefonos_sin_GF[n].tel_telefono);
            if (telefonos_sin_GF[n].tel_telefono.length == 9 && soloNumeros) {
              return true;
            }
          }
          return false;
        }
      },
    },
    {
      key: "verifDate",
      value: function verifDate(listaTelefonos) {
        // Solo busca numeros invalidos en some, por lo que regresa true si no hay numeros
        var telefonos_sin_GF = [];
        for (var i = 0; i < listaTelefonos.length; i++) {
          let now = new Date();
          let last_reg = new Date(listaTelefonos[i].tel_fecha_registro);
          let difference = now.getMonth() - last_reg.getMonth() + 12 * (now.getFullYear() - last_reg.getFullYear());
          console.log(difference + " meses");
          if (difference < 6) {
            return true;
          } else {
            return false;
          }
        }
      },
    },
  ]);
  return VerifNumero;
})();

module.exports = new VerifNumero();
