import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import moment from "moment";
import { store } from "../../store/index";
import { connect } from "react-redux";

// core components
import GridItem from "components/grid/GridItem.js";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import MaterialTable from "material-table";

import { confirmAlert } from "react-confirm-alert"; // Import
import { showLoader, hideLoader } from "./../../store/actions/actions";
import { Delete, Edit } from "@material-ui/icons/";

import ModificarExcepcion from "./ModificarExcepcion";

class ModalExcepciones extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,

      configuracion: props.configuracion,

      open: true,
    };

    this.fetchExcepciones();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  fetchExcepciones = () => {
    this.props.dispatch(showLoader());

    const apiUrl = global.config.modulos.agendamientos + "lista_excepciones_por_bloqueo/?configuracion=" + this.state.configuracion;
    axios
      .get(apiUrl, { headers: authHeader() })
      .then((res) => {
        this.props.dispatch(hideLoader());

        var bloqueo = [];

        var bloqueos = res.data["datos"];
        var cantidadBloqueos = bloqueos.length;
        for (var i = 0; i < cantidadBloqueos; i++) {
          var fechas = bloqueos[i]["rec_fecha_texto"];
          var clasificacion = bloqueos[i]["rec_clasificacion_fecha"];
          var motivoBloqueo = bloqueos[i]["rec_motivo_bloqueo"];
          var motivoBloqueoText = bloqueos[i]["rec_motivo_bloqueo_texto"];
          var fechaInicio = bloqueos[i]["rec_fecha_inicio"];
          var fechaTermino = bloqueos[i]["rec_fecha_termino"];
          var permanente = bloqueos[i]["rec_fecha_termino_activa"];
          var tipoReunion = bloqueos[i]["rec_tipo_reunion"];
          var excepcion = bloqueos[i]["excepcion"];

          fechaInicio = moment(fechaInicio).format("DD-MM-YYYY");
          if (permanente) {
            fechaTermino = "Permanente";
          } else {
            fechaTermino = moment(fechaTermino).format("DD-MM-YYYY");
          }

          if (clasificacion == 2) {
            fechas = "Fechas seleccionadas";
            fechaInicio = "--";
            fechaTermino = "--";
          }

          if (motivoBloqueo == "otro") {
            motivoBloqueo = motivoBloqueoText;
          }

          var horaTermino = moment(bloqueos[i]["rec_hora_termino"], "hh:mm:ss").subtract(1, "minutes").format("HH:mm:ss");

          var blo = {
            rec_id: bloqueos[i]["rec_id"],
            rec_tipo: bloqueos[i]["tipo_reunion"],
            rec_fecha_texto: fechas,
            rec_fecha_inicio: fechaInicio,
            rec_fecha_termino: fechaTermino,
            rec_hora_inicio: bloqueos[i]["rec_hora_inicio"],
            rec_hora_termino: horaTermino,
            rec_usuario_panel: bloqueos[i]["rec_usuario_panel"],
            tipoReunion: tipoReunion,
            rec_profesionales: bloqueos[i]["rec_profesionales"],
            motivoBloqueo: motivoBloqueo,
            excepcion: excepcion,
          };
          bloqueo.push(blo);
        }

        this.setState({
          bloqueos: bloqueo,
        });
      })
      .catch((err) => {
        this.props.dispatch(hideLoader());
      });
  };

  modificarExcepcion = (id, tipoReunion) => {
    if (tipoReunion == 5) {
      this.setState({ modificarBloqueoProfesional: true, rec_id: id });
    } else {
      this.setState({ modificarExcepcion: true, rec_id: id });
    }
  };

  ocultarModificarExcepcion = () => {
    this.setState({ modificarExcepcion: false });
    this.fetchExcepciones();
  };

  eliminarExcepcionConfirmar(recId) {
    confirmAlert({
      title: "¿Seguro(a) que deseas eliminar esta excepción?",
      message: "",
      buttons: [
        {
          label: "Aceptar",
          onClick: () => this.eliminarExcepcion(recId),
        },
        {
          label: "Cancelar",
        },
      ],
    });
  }

  eliminarExcepcion = (recId) => {
    this.props.dispatch(showLoader());

    var data = {
      usp_id: this.state.uspId,
      rec_id: recId,
    };
    let apiUrl = global.config.modulos.agendamientos + "eliminar_excepcion/";
    const options = {
      method: "DELETE",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options)
      .then((res) => {
        this.props.dispatch(hideLoader());
        setTimeout(() => {
          this.mostrarAlerta(res.data.mensaje);
          this.fetchExcepciones();
        }, 500);
      })
      .catch((err) => {
        this.props.dispatch(hideLoader());
      });
  };

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  }

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
          <GridItem xs={12} sm={12} md={12}>
            <h2>Excepción Horario general inhabilitado</h2>
          </GridItem>
          <MaterialTable
            title=""
            columns={[
              { title: "Fecha", field: "rec_fecha_texto" },
              { title: "Profesionales", field: "rec_profesionales", render: (rowData) => rowData.rec_profesionales.substring(0, 6) + "..." },
              { title: "Fecha inicio", field: "rec_fecha_inicio" },
              { title: "Fecha término", field: "rec_fecha_termino" },
              { title: "Hora inicio", field: "rec_hora_inicio" },
              { title: "Hora término", field: "rec_hora_termino" },
              { title: "Funcionario", field: "rec_usuario_panel" },
              {
                title: "Acciónes",
                render: (rowData) => (
                  <React.Fragment>
                    <Tooltip title="Editar excepción" onClick={this.modificarExcepcion.bind(this, rowData["rec_id"], rowData["excepcion_tipo"])}>
                      <IconButton style={{ color: "#000" }}>
                        <Edit />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Eliminar excepción" onClick={this.eliminarExcepcionConfirmar.bind(this, rowData["rec_id"])}>
                      <IconButton style={{ color: "#000" }}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </React.Fragment>
                ),
              },
            ]}
            data={this.state.bloqueos}
            actions={[]}
            options={{
              actionsColumnIndex: -1,
              search: true,
            }}
            localization={{
              header: {
                actions: "Acciones",
              },
            }}
          />
        </DialogContent>
        {this.state.modificarExcepcion ? <ModificarExcepcion ocultar={this.ocultarModificarExcepcion} configuracion={this.state.rec_id}></ModificarExcepcion> : null}
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ModalExcepciones);
