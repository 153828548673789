import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";

import { confirmAlert } from "react-confirm-alert"; // Import
import { store } from "./../../store/index";
import { connect } from "react-redux";

import TextField from "@material-ui/core/TextField";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";

import GridItem from "../grid/GridItem";
import GridContainer from "../grid/GridContainer";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { NotificationManager } from "react-notifications";

import { FaTrash } from "react-icons/fa";
import { showLoader, hideLoader } from "./../../store/actions/actions";

// estilos
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./../../assets/css/horarios.css";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#fff",
  },
}))(Tooltip);

class CopiarSemanal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rendimiento: 15,

      tabla: "",
      tiempoInicio: "08:00",
      tiempoTermino: "18:00",

      tipoCarga: this.props.tipoCarga,

      semanaCopiar: this.props.nsemana,
      semanaMinNueva: moment(this.props.nsemana).add(1, "weeks").format("GGGG-[W]WW"),
      semanaNueva: "",

      proId: this.props.profesional,
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,

      arregloHorario: [],
      arregloDias: [],
      arregloTemp: [],
      arregloSum: [],
      arregloInicio: [],
      arregloTermino: [],
      cargahorario: false,
      open: true,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  salirActualizado = () => {
    this.setState({
      open: false,
    });
    this.props.ocultarCopiaSemanaActualizado();
  };

  componentDidMount() {
    this.traerRendimientosCarga(this.state.rendimiento, this.state.semanaCopiar, 1);
  }

  mostrarAlerta(mensaje) {
    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
        },
      ],
    });
  }

  mostrarAlertaErrorAlCargarHorario() {
    NotificationManager.error("El Horario ha cargar tuvo un problema y no se ha cargado la información");
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  traerRendimientosCarga = (rendimiento, semana, tipo) => {
    var sem = semana;
    var arrSemana = sem.split("-W");

    var nSemana = arrSemana[1];
    var añoPorNSemana = arrSemana[0];

    var fechaInicioSemana = moment().years(añoPorNSemana).isoWeek(nSemana).startOf("isoweek").format("YYYY-MM-DD");

    var fechaTerminoSemana = moment().years(añoPorNSemana).isoWeek(nSemana).endOf("isoweek").format("YYYY-MM-DD");

    var horarioInicio = this.state.tiempoInicio;
    var horarioTermino = this.state.tiempoTermino;

    var fechaInicio = fechaInicioSemana + " " + horarioInicio + ":00";
    var fechaTermino = fechaInicioSemana + " " + horarioTermino + ":00";

    var fecha = fechaInicio;

    var minutosAsumar = parseFloat(rendimiento);

    var arregloRendimiento = [];
    arregloRendimiento.push(fecha);
    do {
      fecha = moment(fecha).add(minutosAsumar, "minutes").format("YYYY-MM-DD HH:mm:ss");
      arregloRendimiento.push(fecha);
    } while (fecha < fechaTermino);

    this.horarios(fechaInicioSemana, fechaTerminoSemana, tipo, arregloRendimiento, rendimiento);
  };

  horarios = (fechaInicioSemana, fechaTerminoSemana, tipo, arregloRendimiento, rendimiento) => {
    var tabla = [];

    var rowsHead = [];
    rowsHead.push(<TableCell className={"tablaRow cellInicio"} key={0}></TableCell>);

    var fecha = moment(fechaInicioSemana);
    for (var i = 0; i <= 6; i++) {
      rowsHead.push(
        <TableCell className={i == 6 ? "tablaRow cellTermino" : "tablaRow"}>
          <p style={{ textAlign: "center" }}>{fecha.format("dddd")} </p>
          <p style={{ textAlign: "center" }}>{fecha.format("DD-MM-YYYY")}</p>
        </TableCell>
      );
      fecha = moment(fecha).add(1, "day");
    }

    tabla.push(
      <TableHead>
        <TableRow>{rowsHead}</TableRow>
      </TableHead>
    );

    var data = {
      fechaInicio: fechaInicioSemana,
      fechaTermino: fechaTerminoSemana,
      cesfam: this.state.cesfam,
      profesional: this.state.proId,
      uspId: this.state.uspId,
      tipo: tipo,
      tipoCarga: this.state.tipoCarga,
    };

    let apiUrl = global.config.modulos.agendamientos + "horario_temporal_semanal/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      var data = res.data;
      var estado = data.estado;

      if (!estado) {
        this.salir();
        this.mostrarAlerta(res.data.mensaje);
      } else {
        var horarioMedico = data.datos;
        var conteoHorarioMedico = horarioMedico.length;
        var feriados = data.feriados;
        var horarioFuncionamiento = data.horarioFuncionamiento;
        var reuniones = data.reuniones;
        var excepciones = res.data.excepciones;

        var arrfechaBloqueo = [];
        for (var e = 0; e < horarioMedico.length; e++) {
          arrfechaBloqueo.push(horarioMedico[e]["cht_atencion"].replace("T", " "));
        }

        var rows = [];
        for (var j = 0; j < arregloRendimiento.length; j++) {
          var cell = [];

          var fecha = arregloRendimiento[j].split(" ");
          var horasF = fecha[1];
          var horaR = horasF.substring(5, 0);

          cell.push(<TableCell className={"tablaRow"}>{horaR}</TableCell>);

          var fechaInicioS = moment(fechaInicioSemana).format("YYYY-MM-DD");

          for (var x = 0; x <= 6; x++) {
            var fechaHorario = moment(fechaInicioS).format("YYYY-MM-DD");

            var Hora = horaR + ":00";
            var fechaHora = fechaHorario + " " + Hora;

            var bloqueoPorReunion = 0;
            var diaFeriado = 0;
            var fueraDeHorario = 0;
            var horarioCopado = 0;
            var coompFecha = 0;
            var rendimiento2 = "";
            var restriccionEdad = "";

            var horId = "";
            var horAtencion = "";
            var horEspecialidad = "";
            var horEdadMenor = "";
            var horEdadMayor = "";
            var horSector = "";
            var horEspecialidadId = "";
            var horMotivoBloqueo = "";
            var horTipo = "";
            var horRendimiento = "";
            var horEstado = "";

            var tipoReunion = "";

            var arrfechaFuncionamiento = [];
            for (var l = 0; l < horarioFuncionamiento.length; l++) {
              var dia_semana = horarioFuncionamiento[l]["dia_semana"];
              var inicio_funcionamiento = horarioFuncionamiento[l]["hora_inicio"];
              var termino_funcionamiento = horarioFuncionamiento[l]["hora_termino"];

              arrfechaFuncionamiento.push(dia_semana);

              if (Hora < inicio_funcionamiento || (Hora > termino_funcionamiento && dia_semana == x)) {
                fueraDeHorario = 1;
              }
            }

            for (var m = 0; m < reuniones.length; m++) {
              var fecha_reunion = reuniones[m]["fecha_reunion"];
              var inicio_reunion = reuniones[m]["hora_inicio"];
              var termino_reunion = reuniones[m]["hora_termino"];

              if (Hora >= inicio_reunion && Hora < termino_reunion && fecha_reunion == fechaHorario) {
                bloqueoPorReunion = 1;
                tipoReunion = reuniones[m]["reunion"];
              }
            }

            for (var ex = 0; ex < excepciones.length; ex++) {
              var fecha_reunion = excepciones[ex]["fecha_reunion"];
              var inicio_reunion = excepciones[ex]["hora_inicio"];
              var termino_reunion = excepciones[ex]["hora_termino"];

              if (Hora >= inicio_reunion && Hora < termino_reunion && fecha_reunion == fechaHorario) {
                console.log();
                bloqueoPorReunion = 0;
              }
            }

            if (feriados.indexOf(fechaHorario) > 0) {
              diaFeriado = 1;
            } else if (arrfechaFuncionamiento.indexOf(x) < 0) {
              fueraDeHorario = 1;
            }

            for (var d = 0; d < conteoHorarioMedico; d++) {
              horAtencion = moment(horarioMedico[d]["cht_atencion"]);
              horRendimiento = horarioMedico[d]["cht_rendimiento"];

              var fechaAtencion = horAtencion.format("YYYY-MM-DD");
              var horaAtencion = horAtencion.format("HH:mm:ss");
              horAtencion = horAtencion.format("YYYY-MM-DD HH:mm:ss");

              var minutosAsumar = parseFloat(horRendimiento);

              var fechaFinal = moment(horAtencion).add(minutosAsumar, "minutes").format("YYYY-MM-DD HH:mm:ss");

              if (arrfechaBloqueo.indexOf(fechaHora) < 0 && horaAtencion < Hora && horAtencion < fechaFinal && fechaFinal > fechaHora && fechaAtencion == fechaHorario && horRendimiento != rendimiento) {
                horarioCopado = 1;
              } else if (arrfechaBloqueo.indexOf(fechaHora) < 0 && horaAtencion < Hora && horAtencion < fechaFinal && fechaFinal > fechaHora && fechaAtencion == fechaHorario && horRendimiento == rendimiento) {
                horarioCopado = 1;
              } else if (horAtencion == fechaHora && fechaAtencion == fechaHorario && diaFeriado == 0 && fueraDeHorario == 0 && bloqueoPorReunion == 0) {
                horId = horarioMedico[d]["cht_id"];
                horEspecialidad = horarioMedico[d]["cht_especialidad__esp_nombre"];
                horEdadMenor = horarioMedico[d]["cht_edad_menor"];
                horEdadMayor = horarioMedico[d]["cht_edad_mayor"];
                horSector = horarioMedico[d]["cht_sector__sec_nombre"];
                horEspecialidadId = horarioMedico[d]["cht_especialidad"];
                horMotivoBloqueo = horarioMedico[d]["cht_motivo"];
                horTipo = horarioMedico[d]["cht_tipo"];
                horEstado = horarioMedico[d]["cht_estado"];

                horarioCopado = 0;
                coompFecha += 1;

                rendimiento2 = horRendimiento + " minutos ";

                if ((horEdadMenor == null || horEdadMenor == 0) && (horEdadMayor == null || horEdadMayor == 0)) {
                  restriccionEdad = " Sin restricción";
                } else {
                  restriccionEdad = " de " + horEdadMenor + " a " + horEdadMayor + " años";
                }
              }
            }

            if (fueraDeHorario == 1) {
              cell.push(
                <TableCell className={"tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
                  <div>
                    <span>
                      <span className={"contenedorDatos"}>
                        <a
                          title="FUERA DE HORARIO DEL CENTRO"
                          className={"botonesCargaMasiva porSeleccionar"}
                          style={{
                            backgroundColor: "#6a6c6a",
                          }}
                        >
                          <label className={"textoBotonesCarga"} style={{ color: "#444444" }}>
                            Fuera de horario
                          </label>
                        </a>
                      </span>
                    </span>
                  </div>
                </TableCell>
              );
            } else if (bloqueoPorReunion == 1) {
              cell.push(
                <TableCell className={"tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
                  <div>
                    <span>
                      <span className={"contenedorDatos"}>
                        <a
                          title={"REUNIÓN " + tipoReunion}
                          className={"botonesCargaMasiva porSeleccionar"}
                          style={{
                            backgroundColor: "#6a6c6a",
                          }}
                        >
                          <label className={"textoBotonesCarga"} style={{ color: "#444444" }}>
                            Reunión
                          </label>
                        </a>
                      </span>
                    </span>
                  </div>
                </TableCell>
              );
            } else if (diaFeriado == 1) {
              cell.push(
                <TableCell className={"tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
                  <div>
                    <span>
                      <span className={"contenedorDatos"}>
                        <a
                          title="HORARIO BLOQUEADO"
                          className={"botonesCargaMasiva porSeleccionar"}
                          style={{
                            backgroundColor: "#6a6c6a",
                          }}
                        >
                          <label className={"textoBotonesCarga"} style={{ color: "#444444" }}>
                            Día feriado
                          </label>
                        </a>
                      </span>
                    </span>
                  </div>
                </TableCell>
              );
            } else if (horarioCopado == 1) {
              cell.push(
                <TableCell className={"tablaRow horarioBloqueado"} style={{ textAlign: "center" }}>
                  <div>
                    <span>
                      <span className={"contenedorDatos"}>
                        <a
                          title="HORARIO BLOQUEADO"
                          className={"botonesCargaMasiva porSeleccionar"}
                          style={{
                            backgroundColor: "#6a6c6a",
                          }}
                        >
                          <label className={"textoBotonesCarga"} style={{ color: "#444444" }}>
                            Horario Bloqueado
                          </label>
                        </a>
                      </span>
                    </span>
                  </div>
                </TableCell>
              );
            } else {
              if (coompFecha != 0) {
                if (horTipo == "2") {
                  cell.push(
                    <TableCell className={"tablaRow horarioCreado"} style={{ textAlign: "center" }}>
                      <HtmlTooltip
                        title={
                          <div>
                            <Table class="table table-striped table-hover tablaPopover">
                              <TableBody>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Especialidad&nbsp;&nbsp;&nbsp;</TableCell>
                                  <TableCell>{horEspecialidad}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Rendimiento&nbsp;&nbsp;&nbsp;</TableCell>
                                  <TableCell>{rendimiento2}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Sector&nbsp;</TableCell>
                                  <TableCell>{horSector}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Restricción de edad&nbsp;</TableCell>
                                  <TableCell>{restriccionEdad}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "900" }}>Motivo bloqueo&nbsp;</TableCell>
                                  <TableCell>{horMotivoBloqueo}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </div>
                        }
                      >
                        <a
                          title="HORARIO BLOQUEADO"
                          className={"botonesCargaMasiva porSeleccionar"}
                          style={{
                            backgroundColor: "#6a6c6a",
                          }}
                        >
                          <label
                            className={"textoBotonesCarga"}
                            style={{
                              color: "#fff",
                            }}
                          >
                            {horEspecialidad}
                          </label>
                        </a>
                      </HtmlTooltip>
                      <br></br>
                      <FaTrash
                        onClick={this.eliminarHorarioTemporal.bind(this, horId)}
                        style={{
                          color: "#baaaaa",
                          cursor: "pointer",
                          marginTop: 7,
                        }}
                      />
                    </TableCell>
                  );
                } else {
                  cell.push(
                    <TableCell className={"tablaRow horarioCreado"} style={{ textAlign: "center" }}>
                      <div>
                        <HtmlTooltip
                          title={
                            <div>
                              <Table class="table table-striped table-hover tablaPopover">
                                <TableBody>
                                  <TableRow>
                                    <TableCell style={{ fontWeight: "900" }}>Especialidad&nbsp;&nbsp;&nbsp;</TableCell>
                                    <TableCell>{horEspecialidad}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell style={{ fontWeight: "900" }}>Rendimiento&nbsp;&nbsp;&nbsp;</TableCell>
                                    <TableCell>{rendimiento2}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell style={{ fontWeight: "900" }}>Sector&nbsp;</TableCell>
                                    <TableCell>{horSector}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell style={{ fontWeight: "900" }}>Restricción de edad&nbsp;</TableCell>
                                    <TableCell>{restriccionEdad}</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </div>
                          }
                        >
                          <a
                            title="HORARIO CARGADO"
                            className={"botonesCargaMasiva porSeleccionar"}
                            style={{
                              backgroundColor: "rgb(38, 165, 48)",
                              borderColor: "rgb(38, 165, 48)",
                              color: "#fff",
                            }}
                          >
                            <label className={"textoBotonesCarga"}>{horEspecialidad}</label>
                          </a>
                        </HtmlTooltip>
                        <br></br>
                        <FaTrash
                          onClick={this.eliminarHorarioTemporal.bind(this, horId)}
                          style={{
                            color: "#baaaaa",
                            cursor: "pointer",
                            marginTop: 7,
                          }}
                        />
                      </div>
                    </TableCell>
                  );
                }
              } else {
                cell.push(
                  <TableCell className={"tablaRow horarioASeleccionar"} style={{ textAlign: "center" }}>
                    <div>
                      <span>
                        <span className={"contenedorDatos"}>
                          <a id={"seleccionarTemp" + fechaHora} className={"botonesCargaMasiva porSeleccionar"}>
                            <label className={"textoBotonesCarga"}>Libre</label>
                          </a>
                        </span>
                      </span>
                    </div>
                  </TableCell>
                );
              }
            }

            fechaInicioS = moment(fechaInicioS).add(1, "day").format("YYYY-MM-DD");
          }
          rows.push(<TableRow className={"tablaRow"}>{cell}</TableRow>);
        }

        tabla.push(<TableBody>{rows}</TableBody>);

        this.setState({
          cargahorario: true,
          tabla: (
            <Table stickyHeader className="bordeTabla" size="small" aria-label="a dense table">
              {tabla}
            </Table>
          ),
        });
      }
    });
  };

  enviarFormulario = (e) => {
    if (this.state.semanaNueva == "") {
      NotificationManager.warning("Debes elegir la fecha en la cual se copiara el horario");
    } else {
      this.props.dispatch(showLoader());

      var semCopiar = this.state.semanaCopiar;
      var arrSemanaCopiar = semCopiar.split("-W");

      var nSemanaCopiar = arrSemanaCopiar[1];
      var añoPorNSemanaCopiar = arrSemanaCopiar[0];

      var fechaInicioSemanaCopiar = moment().years(añoPorNSemanaCopiar).isoWeek(nSemanaCopiar).startOf("isoweek").format("YYYY-MM-DD");

      var semNueva = this.state.semanaNueva;
      var arrSemanaNueva = semNueva.split("-W");

      var nSemanaNueva = arrSemanaNueva[1];
      var añoPorNSemanaNueva = arrSemanaNueva[0];

      var fechaInicioSemanaNueva = moment().years(añoPorNSemanaNueva).isoWeek(nSemanaNueva).startOf("isoweek").format("YYYY-MM-DD");

      var data = {
        profesional: this.state.proId,
        cesfam: this.state.cesfam,
        uspId: this.state.uspId,
        fechaInicioSemanaCopiar: fechaInicioSemanaCopiar,
        fechaInicioSemanaNueva: fechaInicioSemanaNueva,
      };

      let apiUrl = global.config.modulos.agendamientos + "copiar_horario_semanal/";

      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };

      axios(apiUrl, options)
        .then((res) => {
          this.props.dispatch(hideLoader());

          setTimeout(() => {
            if (res.data.estado) {
              this.salirActualizado();
            } else {
              this.setState({
                arregloHorario: [],
                arregloDias: [],
                arregloTemp: [],
                arregloSum: [],
                arregloInicio: [],
                arregloTermino: [],
                tabla: "",
              });
              this.traerRendimientosCarga(this.state.rendimiento, this.state.semanaCopiar, 2);
            }

            this.mostrarAlerta(res.data.mensaje);
          }, 500);
        })
        .catch((err) => {
          this.props.dispatch(hideLoader());
        });
    }
  };

  eliminarHorarioTemporal(horId) {
    let apiUrl = global.config.modulos.agendamientos + "horario_temporal_por_id/" + horId;

    const options = {
      method: "DELETE",
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      this.setState({
        arregloHorario: [],
        arregloDias: [],
        arregloTemp: [],
        arregloSum: [],
        arregloInicio: [],
        arregloTermino: [],
        tabla: "",
      });

      this.traerRendimientosCarga(this.state.rendimiento, this.state.semanaCopiar, 2);
    });
  }

  render() {
    const { semanaNueva, semanaMinNueva, semanaCopiar, tipoCarga } = this.state;

    const cambiarSemana = (event) => {
      this.setState({
        semanaNueva: event.target.value,
      });
    };

    var titulo = "";
    if (tipoCarga == 2) {
      titulo = "Copiar semana última carga realizada";
    } else {
      titulo = "Copiar semana completa";
    }

    return (
      <Dialog open={this.state.open} onClose={this.salirActualizado} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modalCopiarSemana">
        <DialogContent>
          <div className="cargarHorario">
            <GridContainer>
              <GridItem xs={12}>
                <h2>{titulo}</h2>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={12} md={6}>
                    <InputLabel>Semana del horario a copiar</InputLabel>
                    <TextField
                      id="semanaCopiar"
                      name="semanaCopiar"
                      type="week"
                      value={semanaCopiar}
                      inputProps={{
                        min: semanaCopiar,
                        max: semanaCopiar,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <InputLabel>Semana del horario donde será copiada</InputLabel>
                    <TextField
                      id="semana"
                      name="semana"
                      type="week"
                      value={semanaNueva}
                      onChange={cambiarSemana}
                      inputProps={{
                        min: semanaMinNueva,
                      }}
                    />
                  </Grid>

                  {this.state.cargahorario ? (
                    <Grid item xs={12} sm={12} md={12}>
                      <Grid item xs={12} sm={12} md={12} style={{ float: "right" }}>
                        <Grid item xs={12} sm={3} md={3} style={{ float: "left", marginTop: 20 }} className="modalHorarioCopiar">
                          <Button onClick={this.enviarFormulario} className="fondoVerde">
                            Copiar semana
                          </Button>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          md={3}
                          style={{
                            float: "left",
                            marginTop: 20,
                            marginLeft: 20,
                          }}
                          className="modalHorarioCopiar"
                        >
                          <Button onClick={this.salirActualizado} className="fondoRojo">
                            Cancelar
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <div id="tablahorarioProfesional">
                          <TableContainer
                            component={Paper}
                            style={{
                              height: "98vh",
                              overflow: "auto",
                              backgroundColor: "transparent",
                            }}
                          >
                            {this.state.tabla}
                          </TableContainer>
                        </div>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </GridItem>
            </GridContainer>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(CopiarSemanal);
