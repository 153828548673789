import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import moment from "moment";
import axios from "axios";
import { store } from "../../store/index";
import { connect } from "react-redux";

// core components
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import DualListBox from "react-dual-listbox";
import Grid from "@material-ui/core/Grid";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { NotificationManager } from "react-notifications";
import MultiSelect from "react-multi-select-component";
import TextField from "@material-ui/core/TextField";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { Calendar } from "react-multi-date-picker";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import TimePicker from "rc-time-picker";
import { showLoader, hideLoader } from "../../store/actions/actions";

// estilos
import "react-dual-listbox/lib/react-dual-listbox.css";

class ModificarBloqueoProfesional extends Component {
  constructor(props) {
    super(props);

    var fechaActual = moment().format("YYYY-MM-DD");
    this.state = {
      rec_id: props.configuracion,

      profesionales: [],
      profesionalesSeleccionados: [],

      horaInicio: moment(fechaActual + " 08:00"),
      horaTermino: moment(fechaActual + " 17:00"),

      fecha: "",
      fechaInicio: "",
      fechaTermino: "",
      activarFechaTermino: false,
      limiteFecha: moment(),
      tipoHoras: 1,
      activarFechaTermino: false,
      tipoReunion: 5,

      motivosBloqueo: [],
      motivoBloqueo: "0",
      motivoBloqueoTxt: "",

      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      formErrors: {},
      open: true,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  componentDidMount() {
    this.traerProfesionales();
    this.traerMotivosBloqueo();
    this.traerConfiguracion();
  }

  mostrarAlertaAgregadaConExito() {
    NotificationManager.success("Configuración modificada con éxito");
  }

  validaciones() {
    const { tipoReunion, fechaInicio, fechaTermino, activarFechaTermino, profesionalesSeleccionados, motivoBloqueo, motivoBloqueoTxt } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (tipoReunion != 4 && profesionalesSeleccionados.length == 0) {
      formIsValid = false;
      formErrors["tipoProfErr"] = "Debes ingresar profesionales para que se les aplique el bloqueo.";
    }

    if (fechaInicio == "") {
      formIsValid = false;
      formErrors["fechaInicioErr"] = "Ingresa la fecha de inicio del bloqueo.";
    }

    if (fechaTermino == "" && activarFechaTermino == false) {
      formIsValid = false;
      formErrors["fechaTerminoErr"] = "Ingresa la fecha de termino del bloqueo o seleccione todas las horas.";
    }

    if (motivoBloqueo == "0") {
      formIsValid = false;
      NotificationManager.warning("Debe Seleccionar un motivo");
    } else if (motivoBloqueo == "otro" && motivoBloqueoTxt == "") {
      formIsValid = false;
      NotificationManager.warning("Debe escribir el motivo");
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  enviarFormulario = (e) => {
    const { motivoBloqueo, motivoBloqueoTxt } = this.state;

    if (this.validaciones()) {
      this.props.dispatch(showLoader());

      var motivoBloqueoHora = null;
      var motivoBloqueoHoraTexto = null;
      if (motivoBloqueo != "Activa") {
        motivoBloqueoHora = motivoBloqueo;
        motivoBloqueoHoraTexto = motivoBloqueoTxt;
      }

      var horaInicio = moment(this.state.horaInicio).format("HH:mm");
      var horaTermino = moment(this.state.horaTermino).format("HH:mm");

      var data = {
        rec_id: this.state.rec_id,
        fechas: this.state.fechasSeleccionadas,
        tipoReunion: this.state.tipoReunion,
        clasificacionFechas: this.state.clasificacion,
        especialidades: this.state.especialidadesSeleccionadas,
        profesionales: this.state.profesionalesSeleccionados,
        horaInicio: horaInicio,
        horaTermino: horaTermino,
        fechaInicio: this.state.fechaInicio,
        fechaTermino: this.state.fechaTermino,
        activarFechaTermino: this.state.activarFechaTermino,
        motivo: motivoBloqueoHora,
        motivo_texto: motivoBloqueoHoraTexto,
        diaSemana: "",
        tipo: this.state.tipoHoras,
        sector: this.state.sector,
        cesfam: this.state.cesfam,
        uspId: this.state.uspId,
      };
      let apiUrl = global.config.modulos.agendamientos + "modificar_bloqueos_profesional/";
      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };
      axios(apiUrl, options)
        .then((res) => {
          this.props.dispatch(hideLoader());

          var data = res.data;
          setTimeout(() => {
            var estado = data.estado;
            if (estado) {
              this.salir();
              this.mostrarAlertaAgregadaConExito();
            } else {
              NotificationManager.warning("Revise la configuración, no se ingreso correctamente");
            }
          }, 500);
        })
        .catch((err) => {
          this.props.dispatch(hideLoader());
        });
    }
  };

  traerProfesionales() {
    const apiUrl = global.config.modulos.profesionales + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data["estado"]) {
        let proResult = [];
        for (var i = 0; i < res.data["datos"].length; i++) {
          let pro = {
            value: res.data["datos"][i]["prc_profesional"],
            label: res.data["datos"][i]["nombreprofesional"],
          };
          proResult.push(pro);
        }
        this.setState({
          profesionales: proResult,
        });
      }
    });
  }

  traerMotivosBloqueo() {
    const apiUrl = global.config.modulos.agendamientos + "motivos_bloqueo_horario/?cesfam=" + this.state.cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.length > 0) {
        this.setState({
          motivosBloqueo: res.data,
        });
      }
    });
  }

  traerConfiguracion() {
    const apiUrl = global.config.modulos.agendamientos + "configuracion_bloqueo_por_id/" + this.state.rec_id;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      var motivoBloqueo = res.data.rec_motivo_bloqueo;
      var motivoBloqueoTexto = res.data.rec_motivo_bloqueo_texto;

      var mostrarMotivoTxt = false;
      if (motivoBloqueo == "otro") {
        mostrarMotivoTxt = true;
      }

      var profesionalesSeleccionados = [];
      if (res.data.rec_profesionales != "") {
        profesionalesSeleccionados = res.data.rec_profesionales.split(",").map(Number);
      }

      var fechaActual = moment().format("YYYY-MM-DD");

      var horaInicio = res.data.rec_hora_inicio;
      var horaTermino = res.data.rec_hora_termino;

      this.setState({
        profesionalesSeleccionados: profesionalesSeleccionados,
        fechaInicio: res.data.rec_fecha_inicio,
        fechaTermino: res.data.rec_fecha_termino,
        horaInicio: moment(fechaActual + " " + horaInicio),
        horaTermino: moment(fechaActual + " " + horaTermino),
        activarFechaTermino: res.data.rec_fecha_termino_activa,
        tipoHoras: res.data.rec_tipo_fechas,

        motivoBloqueo: motivoBloqueo,
        motivoBloqueoTxt: motivoBloqueoTexto,
        mostrarMotivoTxt: mostrarMotivoTxt,
      });
    });
  }

  cambiarValoresFecha = (e, fecha) => {
    const { name, value } = e.target;

    if (fecha == "inicio") {
      if (value > this.state.fechaTermino && this.state.fechaTermino != "") {
        NotificationManager.warning("La fecha de inicio no puede ser mayor a la de término");
      } else {
        this.setState({ fechaInicio: value });
      }
    }
    if (fecha == "termino") {
      if (value < this.state.fechaInicio && this.state.fechaInicio != "") {
        NotificationManager.warning("La fecha de término no puede ser menor a la de inicio");
      } else {
        this.setState({ fechaTermino: value });
      }
    }
  };

  cambiarValoresHora = (valor, hora) => {
    if (hora == "inicio") {
      if (valor > this.state.horaTermino) {
        NotificationManager.warning("La hora de inicio no puede ser mayor a la de término");
      } else {
        this.setState({ horaInicio: valor });
      }
    }
    if (hora == "termino") {
      if (valor < this.state.horaInicio) {
        NotificationManager.warning("La hora de término no puede ser menor a la de inicio");
      } else {
        this.setState({ horaTermino: valor });
      }
    }
  };

  render() {
    const { tipoProfErr, fechaInicioErr, fechaTerminoErr } = this.state.formErrors;

    const { profesionales, profesionalesSeleccionados, horaInicio, horaTermino, limiteFecha, fechaInicio, fechaTermino, tipoHoras, motivosBloqueo, motivoBloqueo, motivoBloqueoTxt } = this.state;

    const cambioMotivoBloqueo = (event) => {
      if (event.target.value == "otro") {
        this.setState({
          motivoBloqueo: event.target.value,
          mostrarMotivoTxt: true,
        });
      } else {
        this.setState({
          motivoBloqueo: event.target.value,
          mostrarMotivoTxt: false,
        });
      }
    };

    const cambiosListaProfesionales = (profesionalesSeleccionados) => {
      this.setState({ profesionalesSeleccionados });
    };

    const cambiarTipoHoras = (e) => {
      const { name, value } = e.target;

      this.setState({ [name]: parseInt(value) });
    };

    const cambiarFechaTermino = (e) => {
      const { name, checked } = e.target;
      this.setState({
        [name]: checked,
        activarFechaTermino: checked,
      });
    };

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="agendarHora">
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h2>Modificar Bloqueo por profesional</h2>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}></GridItem>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className="cargarHorario" style={{ marginTop: "2vw" }}>
                  <div>
                    <React.Fragment>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                          <InputLabel>Profesionales</InputLabel>
                          <br></br>
                          <DualListBox canFilter options={profesionales} selected={profesionalesSeleccionados} onChange={cambiosListaProfesionales} filterPlaceholder="Buscar..." />
                          {tipoProfErr && <div style={{ color: "red", paddingBottom: 10 }}>{tipoProfErr}</div>}
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={3}>
                              <InputLabel>Motivo de bloqueo</InputLabel>
                              <br></br>
                              <Select name="motivoBloqueo" className={"selectSimple"} value={motivoBloqueo} onChange={cambioMotivoBloqueo} style={{ minWidth: 250 }} displayEmpty>
                                <MenuItem value={"0"} key={0}>
                                  {"Selecciona"}
                                </MenuItem>

                                {motivosBloqueo &&
                                  motivosBloqueo.map((mb, index) => (
                                    <MenuItem value={mb.mb_descripcion} key={index}>
                                      {mb.mb_nombre}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                              {this.state.mostrarMotivoTxt ? (
                                <TextareaAutosize
                                  name="motivoBloqueoTxt"
                                  aria-label="minimum height"
                                  className={"selectSimple "}
                                  style={{
                                    float: "left",
                                    minWidth: 250,
                                    marginTop: 20,
                                  }}
                                  rowsMin={3}
                                  placeholder="Escribe el motivo..."
                                  value={motivoBloqueoTxt}
                                  onChange={this.cambiarValores}
                                />
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>
                        <br></br>

                        <Grid item xs={12} sm={12} md={12}>
                          <Grid item xs={12} sm={12} md={12}>
                            <InputLabel></InputLabel>
                            <RadioGroup name="tipoHoras" id="tipoHoras" value={tipoHoras} onChange={cambiarTipoHoras}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={3} md={2}>
                                  <FormControlLabel value={1} control={<Radio />} label="Horas libres" />
                                </Grid>
                                <Grid item xs={12} sm={3} md={4}>
                                  <FormControlLabel value={2} control={<Radio />} label="Horas libres y Reservadas" />
                                </Grid>
                              </Grid>
                            </RadioGroup>
                          </Grid>
                          <br></br>

                          <Grid item xs={12} sm={12} md={12}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6} md={3}>
                                <InputLabel>Fecha de inicio</InputLabel>
                                <br></br>
                                <TextField
                                  id="fechaInicio"
                                  name="fechaInicio"
                                  type="date"
                                  value={fechaInicio}
                                  inputProps={{
                                    min: limiteFecha.format("YYYY-MM-DD"),
                                  }}
                                  onChange={(e) => this.cambiarValoresFecha(e, "inicio")}
                                />
                                {fechaInicioErr && (
                                  <div
                                    style={{
                                      color: "red",
                                      paddingBottom: 10,
                                    }}
                                  >
                                    {fechaInicioErr}
                                  </div>
                                )}
                              </Grid>

                              <Grid item xs={12} sm={6} md={3}>
                                <InputLabel>Fecha de término</InputLabel>
                                <br></br>
                                <TextField
                                  id="fechaTermino"
                                  name="fechaTermino"
                                  type="date"
                                  value={fechaTermino}
                                  inputProps={{
                                    min: limiteFecha.format("YYYY-MM-DD"),
                                  }}
                                  onChange={(e) => this.cambiarValoresFecha(e, "termino")}
                                  disabled={this.state.activarFechaTermino}
                                />
                                {fechaTerminoErr && (
                                  <div
                                    style={{
                                      color: "red",
                                      paddingBottom: 10,
                                    }}
                                  >
                                    {fechaTerminoErr}
                                  </div>
                                )}
                              </Grid>

                              <Grid item xs={12} sm={6} md={6}>
                                <FormControlLabel control={<Checkbox name="activarFechaTermino" checked={this.state.activarFechaTermino} onChange={cambiarFechaTermino} />} label="Permanente" />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={2}>
                              <p>
                                <label
                                  style={{
                                    color: "rgba(0, 0, 0, 0.54)",
                                  }}
                                >
                                  Hora inicio
                                </label>
                              </p>
                              <br></br>
                              <TimePicker
                                minuteStep={5}
                                showSecond={false}
                                name="horaInicio"
                                id="horaInicio"
                                value={horaInicio}
                                style={{
                                  width: 100,
                                }}
                                onChange={(e) => this.cambiarValoresHora(e, "inicio")}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={2}>
                              <p>
                                <label
                                  style={{
                                    color: "rgba(0, 0, 0, 0.54)",
                                  }}
                                >
                                  Hora Termino
                                </label>
                              </p>
                              <br></br>
                              <TimePicker
                                minuteStep={5}
                                showSecond={false}
                                name="horaTermino"
                                id="horaTermino"
                                value={horaTermino}
                                style={{
                                  width: 100,
                                }}
                                onChange={(e) => this.cambiarValoresHora(e, "termino")}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <br></br>

                        <div
                          style={{
                            width: "100%",
                            float: "left",
                            marginTop: "32px",
                          }}
                        >
                          <Button
                            onClick={this.salir}
                            className="fondoRojo"
                            style={{
                              float: "right",
                              marginLeft: 10,
                              color: "#fff",
                            }}
                          >
                            Cancelar
                          </Button>
                          <Button onClick={this.enviarFormulario} className="fondoVerde" style={{ float: "right", color: "#fff" }}>
                            Modificar
                          </Button>
                        </div>
                      </Grid>
                    </React.Fragment>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ModificarBloqueoProfesional);
