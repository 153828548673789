import React, { useEffect } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import chileanRut from "chilean-rut";

import { store } from "./../../store/index";
import { confirmAlert } from "react-confirm-alert"; // Import
import { connect } from "react-redux";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import DetalleCanal from "./../Usuarios/DetalleCanal.js";
import Audio from "./../Helpers/EscucharAudio.js";
import ficha from "../../assets/img/usuarios/ficha-medica.svg";
import play from "../../assets/img/usuarios/play.svg";

import volver from "../../assets/img/volver.svg";
import salir from "../../assets/img/salir.svg";

import eliminar from "../../assets/img/eliminar_profesional.svg";
import modificar from "../../assets/img/modificar_profesional.svg";
import Chat from "../../assets/img/menu-chat.svg";

import { showLoader, hideLoader } from "./../../store/actions/actions";

import "./../../assets/css/bottombar.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

let timer = null;
let contador = 0;
let contadorRevisar = 0;
let countdown = null;
const Bottombar = (props) => {
  clearInterval(timer);

  const [uspNombre] = React.useState(store.getState().nombreusp);
  const [usp] = React.useState(store.getState().usp);
  const [cesfam] = React.useState(store.getState().cesfam);
  const [contenedor, setContenedor] = React.useState([]);
  const [rutUsuarioChat, setRutUsuarioChat] = React.useState("");
  const [idUsuarioChat, setIdUsuarioChat] = React.useState("");

  const [detalleCanal, setDetalleCanal] = React.useState(false);
  const [mostrarAudio, setMostrarAudio] = React.useState(false);

  const [detalle, setDetalle] = React.useState("");
  const [grabacion, setGrabacion] = React.useState("");
  const [registro, setRegistro] = React.useState("");

  const [siglas, setSiglas] = React.useState(["V10"]);
  const [herramientas, setHerramientas] = React.useState(store.getState().herramientas);

  /*
  timer = setInterval(() => {
    contador++;
    if (contador == 1) {
      var element = document.getElementById("salusin");
      element.classList.add("salusin-corriendo");
      element.classList.add("transicion-corriendo");
      setTimeout(() => {
        element.classList.add("salusin-corriendo-vuelta");
        element.classList.add("transicion-corriendo-vuelta");
        element.classList.add("transicion-idle");
        contador = 0;
        setTimeout(() => {
          element.classList.remove("salusin-corriendo");
          element.classList.remove("transicion-corriendo");
          element.classList.remove("salusin-corriendo-vuelta");
          element.classList.remove("transicion-corriendo-vuelta");
          element.classList.add("transicion-idle");
          contador = 0;
        }, 5000);
      }, 5000);
    }
  }, 20000);
  */

  const alertaEliminarProfesionalPrefe = (profesionalPreferencia, tipo) => {
    confirmAlert({
      title: "",
      message: "Deseas eliminar este profesional del paciente",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => eliminarProfesionalPrefe(profesionalPreferencia, tipo),
          className: "btnAceptar",
        },
      ],
    });
  };

  const eliminarProfesionalPrefe = (profesionalPreferencia, tipo) => {
    var data = {
      profesionalPreferencia: profesionalPreferencia,
      tipo: tipo,
    };

    let apiUrl = global.config.modulos.profesionales + "eliminar_profesional_pref/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      var data = res.data;
      mostrarAlerta(data.mensaje);
      form_profesionales_preferentes(tipo);
    });
  };

  const mostrarAlerta = (mensaje) => {
    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
        },
      ],
    });
  };

  const abrirChat = () => {
    let chat = document.getElementById("menu-chat");
    let salusin = document.getElementById("salusin");

    if (chat.classList.contains("active")) {
      salusin.classList.remove("salusin-mira-hacia-abajo");
      chat.classList.remove("active");
      chat.classList.remove("revisar");
    } else {
      salusin.classList.add("salusin-mira-hacia-abajo");
      chat.classList.add("active");
      chat.classList.remove("revisar");
    }
  };

  const buscarUsuarios = (event) => {
    var rut = event.target.value;
    setRutUsuarioChat(rut);
  };

  useEffect(() => {
    ingresoRut();

    countdown = setInterval(() => {
      var element = document.getElementById("menu-chat");
      var rutUsuarioChat = document.getElementById("rutUsuarioChat").value;

      if (element.classList.contains("revisar")) {
        if (contadorRevisar == 5) {
          element.classList.remove("revisar");
        }
        contadorRevisar++;
      } else {
        contadorRevisar = 0;
      }

      if (!element.classList.contains("active") && rutUsuarioChat != "") {
        if (contador == 60) {
          ingresoRut();
        }
        contador++;
      } else {
        contador = 0;
      }
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, []);

  const ingresoRut = () => {
    setRutUsuarioChat("");
    setIdUsuarioChat("");

    var contenedorDatos = [];
    contenedorDatos.push(
      <div className={"contendor_chat_datos"} style={{ textAlign: "center" }}>
        <label className={"titulo_chat"}>Ingrese el RUT del paciente</label>

        <div style={{ marginTop: "10px" }}>
          <input className="input-ingreso-rut" id="input-ingreso-rut" placeholder="Escriba el rut..." onChange={buscarUsuarios}></input>
        </div>

        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <button className={"boton_accion_chat"} onClick={comprobarUsuarioRut}>
            Ingresar
          </button>
        </div>
      </div>
    );
    setContenedor(contenedorDatos);
  };

  const comprobarUsuarioRut = () => {
    var rutUsuarioChat = document.getElementById("rutUsuarioChat").value;

    if (rutUsuarioChat == "") {
      mostrarAlerta("Ingresa el rut");
      return;
    }

    if (!chileanRut.validate(rutUsuarioChat)) {
      mostrarAlerta("Rut ingresado incorrecto");
    } else {
      var data = {
        rut: rutUsuarioChat,
        canal: 5,
        cesfam: cesfam,
      };

      let apiUrl = global.config.modulos.usuarios + "por_rut/";

      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };

      axios(apiUrl, options).then((res) => {
        var data = res.data;

        var estado = data.estado;
        if (estado) {
          setRutUsuarioChat(data.datos.usu_rut);
          setIdUsuarioChat(data.datos.usu_id);

          botones_acciones();
        } else {
          var datos = data["datos"];
          if (datos != undefined) {
            setRutUsuarioChat(data.datos.usu_rut);
            setIdUsuarioChat(data.datos.usu_id);
          }

          var contenedorDatos = [];
          contenedorDatos.push(
            <div className={"contendor_chat_datos"}>
              <div style={{ marginTop: "10px" }}>
                <label>{data.mensaje}</label>
              </div>

              <div style={{ textAlign: "center", marginTop: "10px" }}>
                {datos != undefined ? (
                  <button className={"boton_accion_chat"} onClick={botones_acciones}>
                    Continuar
                  </button>
                ) : (
                  <button className={"boton_accion_chat"} onClick={ingresoRut}>
                    Entendido
                  </button>
                )}
              </div>
            </div>
          );
          setContenedor(contenedorDatos);
        }
      });
    }
  };

  const botones_acciones = () => {
    var contenedorDatos = [];

    contenedorDatos.push(
      <div>
        {herramientas.includes("H25") ? (
          <button className={"boton_accion_menu_chat"} onClick={consultar_usuario_sistema}>
            Call center no reconoce el RUT
          </button>
        ) : (
          ""
        )}
        {herramientas.includes("H26") ? (
          <button className={"boton_accion_menu_chat"} onClick={form_cambiar_cesfam_usuario}>
            Actualizar Cesfam del paciente
          </button>
        ) : (
          ""
        )}
        {herramientas.includes("H27") ? (
          <button className={"boton_accion_menu_chat"} onClick={form_cambiar_rut_definitivo}>
            Ingresar RUT definitivo de extranjero
          </button>
        ) : (
          ""
        )}
        {herramientas.includes("H28") ? (
          <button className={"boton_accion_menu_chat"} onClick={botones_problemas_app}>
            Tiene problemas con la App
          </button>
        ) : (
          ""
        )}
        {herramientas.includes("H29") ? (
          <button className={"boton_accion_menu_chat"} onClick={form_profesionales_preferentes.bind(this, 1)}>
            Profesionales preferidos del paciente
          </button>
        ) : (
          ""
        )}
        {herramientas.includes("H30") ? (
          <button className={"boton_accion_menu_chat"} onClick={form_profesionales_preferentes.bind(this, 2)}>
            Profesionales bloqueados del paciente
          </button>
        ) : (
          ""
        )}
        {herramientas.includes("H31") ? (
          <button className={"boton_accion_menu_chat"} onClick={tiene_hora_reservada}>
            Consultar hora reservada
          </button>
        ) : (
          ""
        )}
        {herramientas.includes("H32") ? (
          <button className={"boton_accion_menu_chat"} onClick={botones_envio_grabacion}>
            Solicitar grabación
          </button>
        ) : (
          ""
        )}
        {herramientas.includes("H33") ? (
          <button className={"boton_accion_menu_chat"} onClick={botones_otra_consulta}>
            Consulta diferente a las anteriores
          </button>
        ) : (
          ""
        )}
        <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
      </div>
    );
    setContenedor(contenedorDatos);
  };

  const consultar_usuario_sistema = () => {
    var idUsuarioChat = document.getElementById("idUsuarioChat").value;

    let apiUrl = global.config.modulos.usuarios + "por_id/" + idUsuarioChat;

    axios(apiUrl, {
      headers: authHeader(),
    }).then(async (res) => {
      var data = res.data.datos;

      var contenedorDatos = [];
      var estado = data.usu_estado;
      if (estado == 1) {
        var mensaje = await mensajes("msg_chat_2");

        contenedorDatos.push(
          <div className={"contendor_chat_datos"}>
            <label className={"titulo_chat"}>Call center no reconoce el RUT</label>

            <div style={{ marginTop: "10px" }}>
              <label>{mensaje}</label>
            </div>

            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <button className={"boton_accion_chat"} onClick={ingresoRut}>
                Entendido
              </button>
            </div>

            <img src={volver} className="boton_volver_chat" onClick={botones_acciones} />
            <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
          </div>
        );
      } else {
        var mensaje = await mensajes("msg_chat_3");

        contenedorDatos.push(
          <div className={"contendor_chat_datos"}>
            <label className={"titulo_chat"}>Call center no reconoce el RUT</label>

            <div style={{ marginTop: "10px" }}>
              <label>{mensaje}</label>
            </div>

            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <button className={"boton_accion_chat"} onClick={ingresoRut}>
                Entendido
              </button>
            </div>

            <img src={volver} className="boton_volver_chat" onClick={botones_acciones} />
            <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
          </div>
        );
      }
      setContenedor(contenedorDatos);
    });
  };

  const form_cambiar_rut_definitivo = () => {
    var rutUsuarioChat = document.getElementById("rutUsuarioChat").value;

    var contenedorDatos = [];

    contenedorDatos.push(
      <div className={"contendor_chat_datos"}>
        <label className={"titulo_chat"}>Ingresar RUT definitivo de extranjero</label>

        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <div style={{ marginTop: "10px" }}>
            <label className={"chat-pregunta"}>RUT Provisorio</label>
            <input className="input-ingreso-rut" id="input-ingreso-rut" value={rutUsuarioChat} disabled></input>
          </div>

          <div style={{ marginTop: "10px" }}>
            <label className={"chat-pregunta"}>RUT Definitivo</label>
            <input className="input-ingreso-rut" id="input-ingreso-rut-definitivo" placeholder="Escriba el rut..."></input>
          </div>
        </div>

        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <button className={"boton_accion_chat"} onClick={cambiar_rut_definitivo}>
            Confirmar cambio
          </button>
        </div>

        <img src={volver} className="boton_volver_chat" onClick={botones_acciones} />
        <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
      </div>
    );
    setContenedor(contenedorDatos);
  };

  const cambiar_rut_definitivo = () => {
    var rutUsuarioChat = document.getElementById("rutUsuarioChat").value;

    var rut_definitivo = document.getElementById("input-ingreso-rut-definitivo").value;

    if (rut_definitivo == "") {
      mostrarAlerta("Ingresa el rut definitivo");
      return;
    }

    if (!chileanRut.validate(rut_definitivo)) {
      mostrarAlerta("Rut ingresado incorrecto");
    } else {
      var idUsuarioChat = document.getElementById("idUsuarioChat").value;

      let apiUrl = global.config.modulos.usuarios + "por_id/" + idUsuarioChat;

      var data = {
        usu_rut: rut_definitivo,
        usp_id: usp,
      };

      const options = {
        method: "PATCH",
        data: data,
        headers: authHeader(),
      };

      axios(apiUrl, options).then((res) => {
        var data = res.data;

        var estado = data.estado;
        if (estado) {
          var contenedorDatos = [];
          contenedorDatos.push(
            <div className={"contendor_chat_datos"}>
              <label className={"titulo_chat"}>Ingresar RUT definitivo de extranjero</label>

              <div style={{ marginTop: "10px" }}>
                <label>
                  Gracias por informar. Al paciente se le ha cambiado el rut de
                  {rutUsuarioChat} a {rut_definitivo}. según su instrucción {uspNombre}
                </label>
              </div>

              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <button className={"boton_accion_chat"} onClick={ingresoRut}>
                  Entendido
                </button>
              </div>

              <img src={volver} className="boton_volver_chat" onClick={botones_acciones} />
              <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
            </div>
          );
          setContenedor(contenedorDatos);
        } else {
          mostrarAlerta(data.mensaje);
        }
      });
    }
  };

  const botones_problemas_app = () => {
    var contenedorDatos = [];

    contenedorDatos.push(
      <div>
        <button className={"boton_accion_menu_chat"} onClick={form_envio_clave_app}>
          No recuerda su contraseña
        </button>
        <button className={"boton_accion_menu_chat"} onClick={no_abre_app}>
          La app no funciona o no abre
        </button>

        <img src={volver} className="boton_volver_chat" onClick={botones_acciones} />
        <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
      </div>
    );
    setContenedor(contenedorDatos);
  };

  const form_envio_clave_app = () => {
    var rutUsuarioChat = document.getElementById("rutUsuarioChat").value;

    let apiUrl = global.config.modulos.aplicaciones + "traer_cuenta/?rut=" + rutUsuarioChat + "&tipo_usuario=1";

    axios(apiUrl, {
      headers: authHeader(),
    }).then(async (res) => {
      var data = res.data;

      var contenedorDatos = [];
      var estado = data.estado;
      if (estado) {
        var mensaje = await mensajes("msg_chat_5");
        contenedorDatos.push(
          <div className={"contendor_chat_datos"}>
            <label className={"titulo_chat"}>No recuerda su contraseña</label>

            <div style={{ marginTop: "10px" }}>
              <label>{mensaje}</label>
            </div>

            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <button className={"boton_accion_chat"} onClick={ingresoRut}>
                Entendido
              </button>
            </div>

            <img src={volver} className="boton_volver_chat" onClick={botones_acciones} />
            <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
          </div>
        );
      } else {
        var mensaje = await mensajes("msg_chat_6");
        contenedorDatos.push(
          <div className={"contendor_chat_datos"}>
            <label className={"titulo_chat"}>No recuerda su contraseña</label>

            <div style={{ marginTop: "10px" }}>
              <label>{mensaje}</label>
            </div>

            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <button className={"boton_accion_chat"} onClick={ingresoRut}>
                Entendido
              </button>
            </div>

            <img src={volver} className="boton_volver_chat" onClick={botones_acciones} />
            <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
          </div>
        );
      }
      setContenedor(contenedorDatos);
    });
  };

  const no_abre_app = async () => {
    var mensaje = await mensajes("msg_chat_7");

    var contenedorDatos = [];
    contenedorDatos.push(
      <div className={"contendor_chat_datos"}>
        <label className={"titulo_chat"}>La app no funciona o no abre</label>

        <div style={{ marginTop: "10px" }}>
          <label>{mensaje}</label>
        </div>

        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <button className={"boton_accion_chat"} onClick={ingresoRut}>
            Entendido
          </button>
        </div>

        <img src={volver} className="boton_volver_chat" onClick={botones_acciones} />
        <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
      </div>
    );
    setContenedor(contenedorDatos);
  };

  const form_cambiar_cesfam_usuario = () => {
    const apiUrl = global.config.modulos.cesfams + "cesfams_chat_usp/";
    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          usp: usp,
        },
      })
      .then(async (res) => {
        var cesfams = res.data.datos;
        var mensaje = await mensajes("msg_chat_4");

        var contenedorDatos = [];
        contenedorDatos.push(
          <div className={"contendor_chat_datos"}>
            <label className={"titulo_chat"}>Actualizar Cesfam del paciente</label>

            <div style={{ marginTop: "10px" }}>
              <label>{mensaje}</label>
            </div>

            <div style={{ marginTop: "10px" }}>
              <label>Seleccione el Cesfam al cual pertenece el paciente:</label>
            </div>

            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <select className={"select-cesfams-chat"} id="select-cesfams-chat">
                {cesfams.map(function (cesfam, i) {
                  return (
                    <option value={cesfam.ces_id} key={i}>
                      {cesfam.ces_nombre}
                    </option>
                  );
                })}
              </select>
            </div>

            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <button className={"boton_accion_chat"} onClick={form_cambiar_sector_usuario}>
                Confirmar
              </button>
            </div>

            <img src={volver} className="boton_volver_chat" onClick={botones_acciones} />
            <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
          </div>
        );
        setContenedor(contenedorDatos);
      });
  };

  const form_cambiar_sector_usuario = () => {
    var sel = document.getElementById("select-cesfams-chat");
    var cesfam_seleccionado = sel.options[sel.selectedIndex].value;
    var cesfam_seleccionado_nombre = sel.options[sel.selectedIndex].text;

    const apiUrl = global.config.modulos.sectores + "por_cesfam/" + cesfam_seleccionado;
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then(async (res) => {
        var sectores = res.data.datos;
        var mensaje = await mensajes("msg_chat_4");

        var contenedorDatos = [];
        contenedorDatos.push(
          <div className={"contendor_chat_datos"}>
            <label className={"titulo_chat"}>Actualizar Cesfam del paciente</label>

            <div style={{ marginTop: "10px" }}>
              <label>{mensaje}</label>
            </div>

            <div style={{ marginTop: "10px" }}>
              <label>Seleccione el Sector al cual pertenece el paciente:</label>
            </div>

            <input id="cesfamSelect" value={cesfam_seleccionado} hidden></input>
            <input id="cesfamNomSelect" value={cesfam_seleccionado_nombre} hidden></input>

            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <select className={"select-cesfams-chat"} id="select-sector-chat">
                {sectores.map(function (sect, i) {
                  return (
                    <option value={sect.sec_id} key={i}>
                      {sect.sec_nombre}
                    </option>
                  );
                })}
              </select>
            </div>

            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <button className={"boton_accion_chat"} onClick={cambiar_cesfam_usuario}>
                Confirmar
              </button>
            </div>

            <img src={volver} className="boton_volver_chat" onClick={botones_acciones} />
            <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
          </div>
        );
        setContenedor(contenedorDatos);
      });
  };

  const cambiar_cesfam_usuario = () => {
    var idUsuarioChat = document.getElementById("idUsuarioChat").value;

    let apiUrl = global.config.modulos.usuarios + "por_id/" + idUsuarioChat;

    var cesfam_seleccionado = document.getElementById("cesfamSelect").value;
    var cesfam_seleccionado_nombre = document.getElementById("cesfamNomSelect").value;

    var sel = document.getElementById("select-sector-chat");
    var sector_seleccionado = sel.options[sel.selectedIndex].value;
    var sector_seleccionado_nombre = sel.options[sel.selectedIndex].text;

    var data = {
      usu_cesfam: cesfam_seleccionado,
      usu_sector: sector_seleccionado,
      usp_id: usp,
    };

    const options = {
      method: "PATCH",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      var data = res.data;

      var estado = data.estado;
      if (estado) {
        var contenedorDatos = [];
        contenedorDatos.push(
          <div className={"contendor_chat_datos"}>
            <label className={"titulo_chat"}>Actualizar Cesfam del paciente</label>

            <div style={{ marginTop: "10px" }}>
              <label>
                Gracias por informar. El paciente quedó inscrito en el Cesfam {cesfam_seleccionado_nombre} con el sector {sector_seleccionado_nombre}. De acuerdo a su instrucción {uspNombre}.
              </label>
            </div>

            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <button className={"boton_accion_chat"} onClick={ingresoRut}>
                Entendido
              </button>
            </div>

            <img src={volver} className="boton_volver_chat" onClick={botones_acciones} />
            <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
          </div>
        );
        setContenedor(contenedorDatos);
      }
    });
  };

  const form_profesionales_preferentes = (tipo) => {
    var idUsuarioChat = document.getElementById("idUsuarioChat").value;

    var contenedorDatos = [];

    const apiUrl = global.config.modulos.profesionales + cesfam;
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        var profesionales = res.data.datos;

        const apiUrl = global.config.modulos.profesionales + "lista_profesionales_preferencia/?usuId=" + idUsuarioChat + "&tipo=" + tipo + "&cesfam=" + cesfam;

        axios
          .get(apiUrl, {
            headers: authHeader(),
          })
          .then((res) => {
            var lista_profesionales = res.data.datos;

            var titulo = "";
            if (tipo == 1) {
              titulo = "Profesionales preferidos del paciente";
            } else {
              titulo = "Profesionales bloqueados del paciente";
            }

            if (lista_profesionales != null) {
              contenedorDatos.push(
                <div className={"contendor_chat_datos"}>
                  <label className={"titulo_chat"}>{titulo}</label>

                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <input value="" id="idProfPreferencia" style={{ display: "none" }}></input>

                    <select className={"select-prioridad-tabla-chat"} id="select-prioridad-tabla-chat" style={{ display: "none" }}>
                      <option value={1} key={1}>
                        1
                      </option>
                      <option value={2} key={2}>
                        2
                      </option>
                      <option value={3} key={3}>
                        3
                      </option>
                    </select>

                    <select className={"select-tabla-chat"} id="select-tabla-chat">
                      {profesionales.map(function (profe, i) {
                        return (
                          <option value={profe.prc_profesional} key={i}>
                            {profe.nombreprofesional}
                          </option>
                        );
                      })}
                    </select>

                    <button className={"boton_tabla_chat"} id="btnAgregarProfesional" onClick={agregar_profesional_preferente.bind(this, tipo)}>
                      Agregar
                    </button>
                    <button className={"boton_tabla_chat"} onClick={limpiar_profesional_preferente}>
                      Limpiar
                    </button>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <div className="contenedor-tabla-chat">
                      <table className="table-chat">
                        <thead>
                          <tr>
                            <th>N° prioridad</th>
                            <th>Nombre profesional</th>
                            <th>Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lista_profesionales.map(function (profe, i) {
                            return (
                              <tr>
                                <td>{profe.prp_orden}</td>
                                <td>{profe.nombreProfesional}</td>
                                <td>
                                  <img src={modificar} className="botones_acciones_tabla_chat" onClick={datos_modificar_profesional_preferente.bind(this, profe.prp_id, profe.prp_profesional, profe.prp_orden)} />
                                  <img src={eliminar} className="botones_acciones_tabla_chat" onClick={alertaEliminarProfesionalPrefe.bind(this, profe.prp_id, tipo)} />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                      <img src={volver} className="boton_volver_chat" onClick={botones_acciones} />
                      <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
                    </div>
                  </div>
                </div>
              );
            } else {
              var mensaje = "";
              if (tipo == 1) {
                mensaje = "Sin profesionales preferentes";
              } else {
                mensaje = "Sin profesionales bloqueados";
              }

              contenedorDatos.push(
                <div className={"contendor_chat_datos"}>
                  <label className={"titulo_chat"}>{titulo}</label>

                  <div style={{ marginTop: "10px" }}>
                    <input value="" id="idProfPreferencia" style={{ display: "none" }}></input>

                    <select className={"select-tabla-chat"} id="select-tabla-chat">
                      {profesionales.map(function (profe, i) {
                        return (
                          <option value={profe.prc_profesional} key={i}>
                            {profe.nombreprofesional}
                          </option>
                        );
                      })}
                    </select>

                    <button className={"boton_tabla_chat"} onClick={agregar_profesional_preferente.bind(this, tipo)}>
                      Agregar
                    </button>
                  </div>

                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      fontSize: "30px",
                    }}
                  >
                    <label>{mensaje}</label>
                  </div>

                  <img src={volver} className="boton_volver_chat" onClick={botones_acciones} />
                  <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
                </div>
              );
            }

            setContenedor(contenedorDatos);
          });
      });
  };

  const agregar_profesional_preferente = (tipo) => {
    var idProfPreferencia = document.getElementById("idProfPreferencia").value;

    if (idProfPreferencia != "") {
      modificar_profesional_preferente(tipo);
    } else {
      var idUsuarioChat = document.getElementById("idUsuarioChat").value;

      var sel = document.getElementById("select-tabla-chat");
      var profe_seleccionado = sel.options[sel.selectedIndex].value;

      var apiUrl = "";
      if (tipo == 1) {
        apiUrl = global.config.modulos.profesionales + "agregar_profesional_preferido/";
      } else {
        apiUrl = global.config.modulos.profesionales + "agregar_profesional_bloqueado/";
      }

      var data = {
        proId: profe_seleccionado,
        usuId: idUsuarioChat,
        cesfam: cesfam,
        uspId: usp,
      };

      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };

      axios(apiUrl, options).then((res) => {
        var data = res.data;

        var estado = data.estado;
        if (estado) {
          form_profesionales_preferentes(tipo);
        } else {
          mostrarAlerta(data.mensaje);
        }
      });
    }
  };

  const datos_modificar_profesional_preferente = (idProfePreferente, profesional, orden) => {
    document.getElementById("idProfPreferencia").value = idProfePreferente;
    document.getElementById("btnAgregarProfesional").innerText = "Modificar";
    document.getElementById("select-tabla-chat").value = profesional;
    document.getElementById("select-prioridad-tabla-chat").value = orden;
    document.getElementById("select-prioridad-tabla-chat").style.display = "initial";
  };

  const modificar_profesional_preferente = (tipo) => {
    var idUsuarioChat = document.getElementById("idUsuarioChat").value;
    var idProfPreferencia = document.getElementById("idProfPreferencia").value;

    var selPrio = document.getElementById("select-prioridad-tabla-chat");
    var prioridad_seleccionado = selPrio.options[selPrio.selectedIndex].value;

    var sel = document.getElementById("select-tabla-chat");
    var profe_seleccionado = sel.options[sel.selectedIndex].value;

    var apiUrl = global.config.modulos.profesionales + "modificar_profesional_pref/";

    var data = {
      profesionalPreferencia: idProfPreferencia,
      proId: profe_seleccionado,
      usuId: idUsuarioChat,
      cesfam: cesfam,
      orden: prioridad_seleccionado,
      tipo: tipo,
    };

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      limpiar_profesional_preferente();
      form_profesionales_preferentes(tipo);
    });
  };

  const limpiar_profesional_preferente = () => {
    document.getElementById("idProfPreferencia").value = "";
    document.getElementById("btnAgregarProfesional").innerText = "Agregar";
    document.getElementById("select-prioridad-tabla-chat").value = "1";
    document.getElementById("select-prioridad-tabla-chat").style.display = "none";
  };

  const tiene_hora_reservada = () => {
    var idUsuarioChat = document.getElementById("idUsuarioChat").value;

    const apiUrl = global.config.modulos.agendamientos + "tiene_hora_reservada_chat/?usuario=" + idUsuarioChat;

    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        var mensaje = res.data.mensaje;

        var contenedorDatos = [];
        contenedorDatos.push(
          <div className={"contendor_chat_datos"}>
            <label className={"titulo_chat"}>Consultar hora reservada</label>

            <div style={{ marginTop: "10px" }}>
              <label>{mensaje}</label>
            </div>

            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <button className={"boton_accion_chat"} onClick={ingresoRut}>
                Entendido
              </button>
            </div>

            <img src={volver} className="boton_volver_chat" onClick={botones_acciones} />
            <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
          </div>
        );
        setContenedor(contenedorDatos);
      });
  };

  const botones_envio_grabacion = () => {
    var contenedorDatos = [];

    contenedorDatos.push(
      <div>
        <button className={"boton_accion_menu_chat"} onClick={botones_envio_grabacion_solicitud.bind(this)}>
          Solicitud de hora
        </button>
        <button className={"boton_accion_menu_chat"} onClick={botones_envio_grabacion_anulacion.bind(this)}>
          Anulación de hora
        </button>
        <img src={volver} className="boton_volver_chat" onClick={botones_acciones} />
        <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
      </div>
    );
    setContenedor(contenedorDatos);
  };

  const botones_envio_grabacion_solicitud = () => {
    var idUsuarioChat = document.getElementById("idUsuarioChat").value;

    const apiUrl = global.config.modulos.agendamientos + "solicitudes_de_hora_chat/?usuario=" + idUsuarioChat;

    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then(async (res) => {
        var datos = res.data;
        var especialidades = datos.especialidades;
        var cantEsp = especialidades.length;
        var mensaje = await mensajes("msg_chat_9");

        if (cantEsp > 0) {
          mensaje = "";
        }

        var contenedorDatos = [];
        contenedorDatos.push(
          <div className={"contendor_chat_datos"}>
            <label className={"titulo_chat"}>Solicitud de hora</label>
            <div style={{ height: "75%", overflowY: "scroll", textAlign: "center" }}>
              {especialidades.map(function (espe, i) {
                return (
                  <button class="boton_accion_menu_chat" onClick={solicitud_hora.bind(this, espe.esp_sigla, espe.esp_nombre)}>
                    {espe.esp_nombre}{" "}
                  </button>
                );
              })}
              <div style={{ marginTop: "10px" }}>
                <label>{mensaje}</label>
              </div>
            </div>
            <img src={volver} className="boton_volver_chat" onClick={botones_envio_grabacion} />
            <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
          </div>
        );
        setContenedor(contenedorDatos);
      });
  };

  const solicitud_hora = (sigla) => {
    var idUsuarioChat = document.getElementById("idUsuarioChat").value;

    const apiUrl = global.config.modulos.agendamientos + "solicitud_hora_chat/?usuario=" + idUsuarioChat + "&espSigla=" + sigla;

    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        var mensaje = res.data.mensaje;
        var grabacion = res.data.grabacion;
        var grabacion_detalle = res.data.grabacion_detalle;
        var fecha_llamado = res.data.fecha_llamado;

        var contenedorDatos = [];

        if (grabacion == "") {
          contenedorDatos.push(
            <div className={"contendor_chat_datos"}>
              <label className={"titulo_chat"}>Solicitud de hora</label>

              <div style={{ marginTop: "10px" }}>
                <label>{mensaje}</label>
              </div>

              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <button className={"boton_accion_chat"} onClick={ingresoRut}>
                  Entendido
                </button>
              </div>

              <img src={volver} className="boton_volver_chat" onClick={botones_envio_grabacion_solicitud} />
              <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
            </div>
          );
        } else {
          contenedorDatos.push(
            <div className={"contendor_chat_datos"}>
              <label className={"titulo_chat"}>Solicitud de hora</label>

              <div style={{ marginTop: "10px" }}>
                <label>{mensaje}</label>
              </div>

              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <img src={play} className="botones-grabaciones-chat " aria-label="Grabación" title="Grabación" onClick={mostrar_audio.bind(this, grabacion, fecha_llamado)} />
                <img src={ficha} className="botones-grabaciones-chat " aria-label="Detalle Grabación" title="Detalle Grabación" onClick={mostrar_detalle.bind(this, grabacion_detalle, fecha_llamado)} />
              </div>

              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <button className={"boton_accion_chat"} onClick={ingresoRut}>
                  Entendido
                </button>
              </div>

              <img src={volver} className="boton_volver_chat" onClick={botones_envio_grabacion_solicitud} />
              <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
            </div>
          );
        }
        setContenedor(contenedorDatos);
      });
  };

  const botones_envio_grabacion_anulacion = () => {
    var idUsuarioChat = document.getElementById("idUsuarioChat").value;

    const apiUrl = global.config.modulos.agendamientos + "anulaciones_de_hora_chat/?usuario=" + idUsuarioChat;

    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then(async (res) => {
        var datos = res.data;
        var especialidades = datos.especialidades;
        var cantEsp = especialidades.length;
        var mensaje = await mensajes("msg_chat_10");

        if (cantEsp > 0) {
          mensaje = "";
        }

        var contenedorDatos = [];
        contenedorDatos.push(
          <div className={"contendor_chat_datos"}>
            <label className={"titulo_chat"}>Anulación de hora</label>
            <div style={{ height: "75%", overflowY: "scroll", textAlign: "center" }}>
              {especialidades.map(function (espe, i) {
                return (
                  <button class="boton_accion_menu_chat" onClick={anulacion_hora.bind(this, espe.esp_sigla, espe.esp_nombre)}>
                    {espe.esp_nombre}
                  </button>
                );
              })}

              <div style={{ marginTop: "10px" }}>
                <label>{mensaje}</label>
              </div>
            </div>
            <img src={volver} className="boton_volver_chat" onClick={botones_envio_grabacion} />
            <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
          </div>
        );
        setContenedor(contenedorDatos);
      });
  };

  const anulacion_hora = (sigla) => {
    var idUsuarioChat = document.getElementById("idUsuarioChat").value;

    const apiUrl = global.config.modulos.agendamientos + "anulacion_hora_chat/?usuario=" + idUsuarioChat + "&espSigla=" + sigla;

    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        var mensaje = res.data.mensaje;
        var grabacion = res.data.grabacion;
        var grabacion_detalle = res.data.grabacion_detalle;
        var fecha_llamado = res.data.fecha_llamado;

        var contenedorDatos = [];

        if (grabacion == "") {
          contenedorDatos.push(
            <div className={"contendor_chat_datos"}>
              <label className={"titulo_chat"}>Anulación de hora</label>

              <div style={{ marginTop: "10px" }}>
                <label>{mensaje}</label>
              </div>

              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <button className={"boton_accion_chat"} onClick={ingresoRut}>
                  Entendido
                </button>
              </div>

              <img src={volver} className="boton_volver_chat" onClick={botones_envio_grabacion_anulacion} />
              <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
            </div>
          );
        } else {
          contenedorDatos.push(
            <div className={"contendor_chat_datos"}>
              <label className={"titulo_chat"}>Anulación de hora</label>

              <div style={{ marginTop: "10px" }}>
                <label>{mensaje}</label>
              </div>

              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <img src={play} className="botones-grabaciones-chat " onClick={mostrar_audio.bind(this, grabacion, fecha_llamado)} />
                <img src={ficha} className="botones-grabaciones-chat " onClick={mostrar_detalle.bind(this, grabacion_detalle, fecha_llamado)} />
              </div>

              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <button className={"boton_accion_chat"} onClick={ingresoRut}>
                  Entendido
                </button>
              </div>

              <img src={volver} className="boton_volver_chat" onClick={botones_envio_grabacion_anulacion} />
              <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
            </div>
          );
        }
        setContenedor(contenedorDatos);
      });
  };

  const botones_otra_consulta = () => {
    var contenedorDatos = [];

    contenedorDatos.push(
      <div className={"contendor_chat_datos"}>
        <label className={"titulo_chat"}>Su consulta es:</label>

        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <button className={"boton_accion_menu_chat"} onClick={form_enviar_otra_consulta.bind(this, 1)}>
            Funcionamiento
          </button>
          <button className={"boton_accion_menu_chat"} onClick={form_enviar_otra_consulta.bind(this, 2)}>
            Reclamo
          </button>
          <button className={"boton_accion_menu_chat"} onClick={form_enviar_otra_consulta.bind(this, 3)}>
            Técnico
          </button>
        </div>

        <img src={volver} className="boton_volver_chat" onClick={botones_acciones} />
        <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
      </div>
    );
    setContenedor(contenedorDatos);
  };

  const form_enviar_otra_consulta = (tipo) => {
    var placeholder = "Consulta...";
    if (tipo == 1) {
      var placeholder = "Funcionamiento...";
    } else if (tipo == 2) {
      var placeholder = "Reclamo...";
    } else if (tipo == 3) {
      var placeholder = "Técnico...";
    }

    var contenedorDatos = [];

    contenedorDatos.push(
      <div className={"contendor_chat_datos"}>
        <label className={"titulo_chat"}>Consulta diferente a las anteriores</label>

        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <textarea className="textarea-ingreso-rut" id="textarea-consulta" placeholder={placeholder}></textarea>
        </div>

        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <button className={"boton_accion_chat"} onClick={enviar_otra_consulta.bind(this, tipo)}>
            Enviar consulta
          </button>
        </div>

        <img src={volver} className="boton_volver_chat" onClick={botones_acciones} />
        <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
      </div>
    );
    setContenedor(contenedorDatos);
  };

  const enviar_otra_consulta = (tipo) => {
    var rutUsuarioChat = document.getElementById("rutUsuarioChat").value;

    var text = document.getElementById("textarea-consulta").value;

    if (text == "") {
      mostrarAlerta("Ingresa la consulta a realizar");
      return;
    } else {
      props.dispatch(showLoader());
    }

    let apiUrl = global.config.modulos.agendamientos + "enviar_otra_consulta_chat/";

    var data = {
      uspId: usp,
      rutUsuario: rutUsuarioChat,
      consulta: text,
      tipo: tipo,
    };

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options)
      .then((res) => {
        var data = res.data;

        props.dispatch(hideLoader());
        setTimeout(async () => {
          var mensaje = await mensajes("msg_chat_8");
          var estado = data.estado;
          if (estado) {
            var contenedorDatos = [];
            contenedorDatos.push(
              <div className={"contendor_chat_datos"}>
                <label className={"titulo_chat"}>Consulta diferente a las anteriores</label>

                <div style={{ marginTop: "10px" }}>
                  <label>{mensaje}</label>
                </div>

                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <button className={"boton_accion_chat"} onClick={ingresoRut}>
                    Entendido
                  </button>
                </div>

                <img src={volver} className="boton_volver_chat" onClick={botones_acciones} />
                <img src={salir} className="boton_salir_chat" onClick={ingresoRut} />
              </div>
            );
            setContenedor(contenedorDatos);
          }
        }, 500);
      })
      .catch((err) => {
        this.props.dispatch(hideLoader());
      });
  };

  const mensajes = async (titulo) => {
    const datos = { titulo: titulo, canal: 5 };
    var res = await axios.get(global.config.modulos.aplicaciones + "mensajes_por_titulo/", {
      headers: authHeader(),
      params: datos,
    });
    var data = res.data;

    var mensaje = data["msg_mensaje"];
    return mensaje;
  };

  const mostrar_detalle = (detalle, calldate) => {
    var texto = detalle.replaceAll("*", " ");
    var arrTexto = texto.split(";");

    var textoFinal = "";
    var index = 0;
    for (var i = 0; i < arrTexto.length - 1; i++) {
      var textoD = arrTexto[i];
      var hora = textoD.substring(11, 19);
      var descripcion = textoD.substring(21);

      index = index + 1;
      if (index < 10) {
        textoFinal += '<p style = "margin-bottom: 18px" > <span style ="padding: 3px 8px 3px 8px;border-radius: 9px;color: #eaa028;margin-right: 5px;font-weight: 900;font-size: 17px;border: 2px solid #eaa028;">' + index + '</span><label style="font-weight: bold;">' + hora + "</label>" + descripcion + "</p>";
      } else {
        textoFinal += '<p style = "margin-bottom:18px" > <span style ="padding: 3px 8px 3px 8px;border-radius: 9px;color: #eaa028;margin-right: 5px;font-weight: 900;font-size: 17px;border: 2px solid #eaa028;">' + index + '</span><label style="font-weight: bold;">' + hora + "</label>" + descripcion + "</p>";
      }
    }

    setRegistro(calldate);
    setDetalleCanal(true);
    setDetalle(textoFinal);
  };

  const mostrar_audio = (url, calldate) => {
    setRegistro(calldate);
    setMostrarAudio(true);
    setGrabacion("http://200.111.252.138/grabaciones/" + url);
  };

  const ocultar_detalle = () => {
    setDetalleCanal(false);
  };

  const ocultar_audio = () => {
    setMostrarAudio(false);
  };

  const clickAwayChat = () => {
    let chat = document.getElementById("menu-chat");
    let salusin = document.getElementById("salusin");

    if (chat.classList.contains("active")) {
      salusin.classList.remove("salusin-mira-hacia-abajo");
      chat.classList.remove("active");
      chat.classList.remove("revisar");
    }
  };

  const verChat = () => {
    let chat = document.getElementById("menu-chat");
    chat.classList.add("revisar");
  };

  return (
    <div className="bottom-footer">
      {detalleCanal ? <DetalleCanal ocultar={ocultar_detalle} detalle={detalle} registro={registro}></DetalleCanal> : null}
      {mostrarAudio ? <Audio ocultar={ocultar_audio} grabacion={grabacion} registro={registro}></Audio> : null}

      {herramientas.includes(siglas[0]) ? (
        <ClickAwayListener onClickAway={clickAwayChat}>
          <div id="menu-chat" className={"menu-chat"}>
            <div className={"divSalusin"}>
              <img id="salusin" onClick={abrirChat} className="chat-img" style={{ cursor: "pointer" }} onMouseOver={verChat}></img>
            </div>
            <div className={"chat-content"}>
              <div className={"chat-top"} onClick={abrirChat} style={{ cursor: "pointer" }}>
                <span className={"chat-titulo"}>Chat de ayuda</span>
              </div>
              <div className={"chat-menus"}>
                <input id="rutUsuarioChat" value={rutUsuarioChat} style={{ display: "none" }}></input>
                <input id="idUsuarioChat" value={idUsuarioChat} style={{ display: "none" }}></input>

                {contenedor}
              </div>
            </div>
          </div>
        </ClickAwayListener>
      ) : (
        <div id="menu-chat" className={"menu-chat"}>
          <div className={"divSalusinSinChat"}>
            <img id="salusin" className="chat-img"></img>
          </div>
        </div>
      )}
      <img className="bottom-img"></img>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Bottombar);
