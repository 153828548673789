import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import ProgressBar from "../../components/ProgressBar/ProgressBar.js";
import { ReactComponent as SalusinFeliz } from "../../assets/img/salusin/salusinfeliz.svg";

import Autocomplete from "@material-ui/lab/Autocomplete";
import "moment/locale/es";
import axios from "axios";

// CSS
import "./../../assets/css/general.css";
import "./../../assets/css/graficos.css";
import "./../../assets/css/usuarios.css";

export default class MetasVacunas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cesfams: [],
      cesfamShow: true,
      cesfamNom: "",
      cesfamSelected: 0,
      cesOpcion: null,
      comunaNom: "",
      comunaSelected: 0,
      comOpcion: null,
      vacunados: 0,
      sevVacunados: 0,
      soliVacunados: 0,
      metaVacunas: 0,
      sevMeta: 0,
      soliMeta: 0,
    };
  }

  cambiaBar(event, value) {
    if (value.val === 0) {
      this.initBars();
      this.setState({
        cesfamShow: true,
        cesOpcion: null,
        cesfamSelected: 0,
        cesfamNom: "",
        comOpcion: null,
        comunaSelected: 0,
        comunaNom: "",
        cesfams: [],
      });
    } else if (value.val === 1) {
      this.initBars();
      this.setState({
        cesfamShow: false,
        cesOpcion: null,
        cesfamSelected: 0,
        cesfamNom: "",
        comOpcion: null,
        comunaSelected: 0,
        comunaNom: "",
        cesfams: [],
      });
    }
  }

  initBars = () => {
    this.setState({
      vacunados: 0,
      sevVacunados: 0,
      soliVacunados: 0,
      metaVacunas: 0,
      sevMeta: 0,
      soliMeta: 0,
    });
  };

  setComuna = (event, value) => {
    if (this.state.cesfamShow) {
      this.initBars();
      this.setState({
        comunaNom: value.label,
        comunaSelected: value.id,
        comOpcion: value,
        cesOpcion: null,
        cesfamNom: "Seleccionar Cesfam",
        cesfamSelected: 0,
      });
      this.cargarCesfams(value.id);
    } else if (!this.state.cesfamShow) {
      this.setState({ comunaNom: value.label, comunaSelected: value.id, comOpcion: value });
    }
  };

  setCesfam = (event, value) => {
    this.setState({ cesfamNom: value.label, cesfamSelected: value.id, cesOpcion: value });
  };

  cargarCesfams = async (comuna) => {
    const apiUrl = global.config.modulos.cesfams + "por_comuna/?id=" + comuna;

    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.datos.length > 0) {
        this.setState({ cesfams: [] });
        res.data.datos.map((res) => {
          if (res.ces_id == 1 || res.ces_id == 2 || res.ces_id == 3) {
            this.setState({
              cesfams: [...this.state.cesfams, { label: res.ces_nombre, val: res.ces_id, id: res.ces_id }],
            });
          }
        });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.cesfamShow) {
      if (prevState.cesOpcion !== this.state.cesOpcion || prevState.comOpcion !== this.state.comOpcion) {
        this.cargarDatos();
      }
    } else if (!this.state.cesfamShow) {
      if (prevState.comOpcion !== this.state.comOpcion) {
        this.cargarDatos();
      }
    }
  }

  cargarDatos = async () => {
    if (this.state.cesfamShow) {
      if (this.state.cesfamSelected != null) {
        const apiUrl = global.config.modulos.repartos + "vacunados_covid/";
        const options = {
          method: "GET",
          params: {
            opcion: "1",
            cesfam: this.state.cesfamSelected,
          },
          headers: authHeader(),
        };
        await axios(apiUrl, options).then((res) => {
          this.setState({
            metaVacunas: res.data.total,
            vacunados: res.data.total_vac,
            sevMeta: res.data.total_por_tipo[1].cantidad_usr,
            sevVacunados: res.data.vacunados_por_tipo.length > 0 ? res.data.vacunados_por_tipo[1].cantidad_usr : 0,
            soliVacunados: res.data.vacunados_por_tipo.length > 0 ? res.data.vacunados_por_tipo[0].cantidad_usr : 0,
            soliMeta: res.data.total_por_tipo[0].cantidad_usr,
          });
        });
      }
    } else if (!this.state.cesfamShow) {
      if (this.state.comunaNom != null) {
        const apiUrl = global.config.modulos.repartos + "vacunados_covid/";
        const options = {
          method: "GET",
          params: {
            opcion: "2",
            comn: this.state.comunaSelected,
          },
          headers: authHeader(),
        };
        await axios(apiUrl, options).then((res) => {
          this.setState({
            metaVacunas: res.data.total,
            vacunados: res.data.total_vac,
            sevMeta: res.data.total_por_tipo[1].cantidad_usr,
            sevVacunados: res.data.vacunados_por_tipo.length > 0 ? res.data.vacunados_por_tipo[1].cantidad_usr : 0,
            soliVacunados: res.data.vacunados_por_tipo.length > 0 ? res.data.vacunados_por_tipo[0].cantidad_usr : 0,
            soliMeta: res.data.total_por_tipo[0].cantidad_usr,
          });
        });
      }
    }
  };

  componentDidMount() {
    this.cargarCesfams();
    this.cargarDatos();
  }

  calcFill(vac, meta) {
    if (vac === 0 && meta === 0) {
      return 0;
    } else {
      var perc = vac / meta;
      return perc * 100;
    }
  }

  render() {
    return (
      <div style={{ marginBottom: 180 }}>
        <GridItem xs={12} sm={12} md={12}>
          <Grid container spacing={3}>
            <Grid item xs={2} sm={2} md={2}>
              <Autocomplete disablePortal disableClearable id="combo-box-" options={this.props.opciones} getOptionLabel={(option) => option.label} defaultValue={this.props.opciones[0]} onChange={this.cambiaBar.bind(this)} renderInput={(params) => <TextField {...params} label="Selecionar Opcion" />}></Autocomplete>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <Autocomplete disablePortal disableClearable value={this.state.comOpcion} id="combo-box-" options={this.props.comunas} getOptionLabel={(option) => option.label} defaultValue={this.state.comOpcion} onChange={this.setComuna.bind(this)} renderInput={(params) => <TextField {...params} label="Selecionar Comuna" />}></Autocomplete>
            </Grid>
            {this.state.cesfamShow ? (
              <Grid item xs={2} sm={2} md={2}>
                <Autocomplete disablePortal disableClearable value={this.state.cesOpcion} id="combo-box-" defaultValue={this.state.cesOpcion} options={this.state.cesfams} getOptionLabel={(option) => option.label} noOptionsText={"Seleccione una comuna primero"} onChange={this.setCesfam.bind(this)} renderInput={(params) => <TextField {...params} label="Selecionar Cesfam" />}></Autocomplete>
              </Grid>
            ) : null}
          </Grid>
        </GridItem>
        <div class="middle-content chartContainer">
          <GridItem xs={12} sm={12} md={12} className="scroll-container">
            {this.state.cesfamShow ? <h3 className="textColor">Meta de vacunas Cesfam {this.state.cesfamNom}</h3> : <h3 className="textColor">Meta de vacunas Comuna {this.state.comunaNom}</h3>}
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} md={6}>
                <ProgressBar id="covids" bgcolor="#6a1b9a" completed={this.calcFill(this.state.vacunados, this.state.metaVacunas)} amount={this.state.vacunados} tooltipColor="#2b6ac9" />
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <div class="vacunasArea salusinVac">
                  <p class="vacunasText">
                    Meta de vacunas:
                    <br></br>
                    <b class="vacNumber">{this.state.metaVacunas}</b>
                  </p>
                  <SalusinFeliz class="salusinCovid vacunasSalusin" />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={5} sm={5} md={5}>
                <p class="textColor">
                  <b>Vacunas dependientes severos</b>
                </p>
                <ProgressBar id="severos" bgcolor="#6a1b9a" completed={this.calcFill(this.state.sevVacunados, this.state.sevMeta)} amount={this.state.sevVacunados} small={true} tooltipColor="#38a18f" />
              </Grid>
              <Grid item xs={5} sm={5} md={5}>
                <div class="vacunasArea depVac">
                  <p class="vacunasTextSmall">
                    Meta dep. severos:
                    <br></br>
                    <b class="vacNumber">{this.state.sevMeta}</b>
                  </p>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={5} sm={5} md={5}>
                <p class="textColor scrollingFix">
                  <b>Vacunas solicitudes externas</b>
                </p>
                <ProgressBar id="externos" bgcolor="#6a1b9a" completed={this.calcFill(this.state.soliVacunados, this.state.soliMeta)} amount={this.state.soliVacunados} small={true} tooltipColor="#65329e" />
              </Grid>
              <Grid item xs={5} sm={5} md={5}>
                <div class="vacunasArea extVac">
                  <p class="vacunasTextSmall">
                    Meta solicitudes <br></br>externas:
                    <br></br>
                    <b class="vacNumber">{this.state.soliMeta}</b>
                  </p>
                </div>
              </Grid>
            </Grid>
          </GridItem>
        </div>
      </div>
    );
  }
}
