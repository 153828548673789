import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import axios from "axios";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";

import { CSVReader } from "react-papaparse";
import { NotificationManager } from "react-notifications";
import { store } from "./../../store/index";

import signo from "./../../assets/img/signo.svg";

// CSS
import "./../../assets/css/usuarios.css";

export default class CargaMasivaRecordatorios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      atencion: null,
      datos: [],
      atenciones: [],
      texto_atenciones: [],
      ultimaCarga: null,
    };

    this.cargaMasiva = this.cargaMasiva.bind(this);
  }

  componentDidMount() {
    this.obtenerAtenciones();
    this.titulo();
    this.ultimaCarga();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Carga masiva recordatorios <span title='Permite ingresar a través de un archivo .csv información sobre los recordatorios externos'><img src=" + signo + "></img></span>";
  };

  volver = () => {
    this.props.history.push("/menuReservaExterna");
  };

  obtenerAtenciones = () => {
    axios
      .get(global.config.modulos.reservas_externas + "atenciones_cesfam/", {
        headers: authHeader(),
        params: { cesfam: this.state.cesfam },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            atenciones: res.data.datos,
            texto_atenciones: res.data.texto_atenciones,
          });
        } else {
          this.setState({ atenciones: [] });
        }
      });
  };

  cambioValor = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleOnDrop = (data) => {
    let registros = [];
    data.forEach((reg) => {
      registros.push(reg.data);
      console.log(reg.data);
    });

    this.setState({ datos: registros });
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOnRemoveFile = (data) => {
    this.setState({ datos: [] });
  };

  cargaMasiva() {
    document.getElementById("cargando").style.display = "block";

    axios
      .post(
        global.config.modulos.reservas_externas + "carga_masiva/",
        {
          registros: this.state.datos,
          uspId: this.state.uspId,
          cesfam: this.state.cesfam,
          atencion: this.state.atencion,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        document.getElementById("cargando").style.display = "none";
        NotificationManager.success(res.data.mensaje);
      })
      .catch((error) => {
        document.getElementById("cargando").style.display = "none";
        NotificationManager.error("Ocurrió un error al cargar los registros");
      });
  }

  ultimaCarga() {
    axios
      .get(global.config.modulos.reservas_externas + "ultima_carga/", {
        headers: authHeader(),
        params: { id_usp: this.state.uspId },
      })
      .then((res) => {
        if (res.data.estado) {
          let fecha = res.data.datos.grc_fecha_carga.split("T");
          dia = String(fecha[0]).split("-");
          let dia = dia[0] + "-" + dia[1] + "-" + dia[2];
          this.setState({ ultimaCarga: "Última carga: " + dia + " a las " + fecha[1] });
        } else {
          this.setState({ ultimaCarga: null });
        }
      });
  }

  descargarExcel = (e) => {
    const url = global.config.modulos.reservas_externas + "excel_errores/?id_usp=" + this.state.uspId;
    window.open(url, "_blank").focus();
  };

  render() {
    const { atencion, datos, atenciones, texto_atenciones, ultimaCarga } = this.state;

    return (
      <div className="middle-area">
        <div className="middle-content">
          <GridContainer>
            <GridItem xs={12} style={{ margin: 0 }}>
              <a onClick={this.volver} className={"btnAgregarCaso fondoNaranjo"} style={{ cursor: "pointer", marginRight: 10, float: "left" }}>
                Volver al Menu
              </a>
            </GridItem>
            <Grid item xs={12} sm={12}>
              {ultimaCarga && (
                <Grid item xs={12} sm={12}>
                  <Button
                    onClick={this.descargarExcel}
                    style={{
                      backgroundColor: "#bf3c50",
                      color: "white",
                      marginTop: 20,
                    }}
                  >
                    Descargar errores - {ultimaCarga}
                  </Button>
                </Grid>
              )}
            </Grid>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: 25 }}>
              <div className="cargarUsuarios">
                <div>
                  <Grid container spacing={3}>
                    {atencion ? (
                      <React.Fragment>
                        <Grid item xs={12} sm={12}>
                          <label>
                            Ejemplo de llenado de archivo .csv
                            <br />
                            <br />
                            {atenciones[atencion]["gra_texto_imagen"]}
                          </label>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <img style={{ width: "70%" }} src={require("./../../assets/img/reservas_externas/" + atenciones[atencion]["gra_url_imagen"]).default} alt="csv" />
                        </Grid>
                      </React.Fragment>
                    ) : null}

                    <Grid item xs={12} sm={12}>
                      <div style={{ float: "left" }}>
                        <InputLabel shrink htmlFor="age-native-label-placeholder" required>
                          Atención
                        </InputLabel>
                        <NativeSelect
                          value={this.state.atencion}
                          onChange={this.cambioValor}
                          inputProps={{
                            name: "atencion",
                            id: "atencion",
                          }}
                        >
                          <option value="">Seleccione recordatorio</option>
                          {texto_atenciones &&
                            texto_atenciones.map((atencion, index) => (
                              <option key={index} value={atenciones[atencion]["gra_id"]}>
                                {atenciones[atencion]["gra_atencion"]}
                              </option>
                            ))}
                        </NativeSelect>
                      </div>
                    </Grid>
                    {atencion && (
                      <Grid item xs={12} sm={12}>
                        <CSVReader
                          onDrop={this.handleOnDrop}
                          onError={this.handleOnError}
                          addRemoveButton
                          removeButtonColor="#659cef"
                          onRemoveFile={this.handleOnRemoveFile}
                          config={{
                            header: true,
                            dynamicTyping: true,
                            skipEmptyLines: true,
                            transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
                            encoding: "windows-1252",
                          }}
                        >
                          <span>Arrastra el archivo CSV o presiona para cargar.</span>
                        </CSVReader>
                        <LinearProgress id="cargando" style={{ display: "none" }} />
                      </Grid>
                    )}
                  </Grid>
                  {atencion && datos.length > 0 && (
                    <Grid item xs={12}>
                      <Button
                        onClick={this.cargaMasiva}
                        style={{
                          backgroundColor: "#3cbf71",
                          color: "white",
                          marginTop: 20,
                        }}
                      >
                        Cargar registros
                      </Button>
                    </Grid>
                  )}
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
