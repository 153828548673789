import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

// estilos
//import "../../assets/css/agregar.css";

export default class AgregarTelefonoPercapitado extends Component {
  constructor(props) {
    super(props);

    this.state = {
      telefono: "",
      uspId: store.getState().usp,
      percapitadoId: props.percapitadoId,
      id_listaOirs: props.id_listaOirs,
      formErrors: {},
      open: true,
      telefonoErr: "",
    };
    console.log(this.state.percapitadoId);
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  enviarFormulario = (e) => {
    if (this.state.telefono.length < 9) {
      return this.setState({
        telefonoErr: "El teléfono debe ser de 9 dígitos",
      });
    } else {
      this.setState({ telefonoErr: "" });
    }
    var data = {
      telefono: this.state.telefono,
      id_percapitado: this.state.percapitadoId,
      tipo: 2,
      canal: 5,
      usp_id: this.state.uspId,
      id_ListadoOirs: this.state.id_listaOirs,
    };

    let apiUrl = global.config.modulos.listaEspera + "modificar_telefono_percapitado/";

    const options = {
      method: "PATCH",
      data: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      NotificationManager.success("Teléfono agregado con éxito");
      this.salir();
    });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { motivoErr } = this.state.formErrors;

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="eliminarUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
              <img className="marca-agua"></img>
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Agregar teléfono a usuario percapitado</h2>
                </GridItem>
                <GridItem xs={8} style={{ marginTop: 30 }}>
                  <TextField
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+56</InputAdornment>,
                    }}
                    id="standard-basic"
                    label="Teléfono"
                    value={this.state.telefono}
                    style={{ with: 300 }}
                    type="number"
                    name="telefono"
                    onChange={this.cambiarValores}
                    onInput={(e) => {
                      e.target.value = e.target.value.length <= 9 ? e.target.value : e.target.value.slice(0, -1);
                    }}
                    className={this.state.telefonoErr ? "inputText showError" : "inputText"}
                  />
                  {this.state.telefonoErr && <div style={{ color: "red", paddingBottom: 10 }}>{this.state.telefonoErr}</div>}
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={this.salir} style={{ marginLeft: 20 }}>
                    Cancelar
                  </Button>
                  <Button className="botonDialog fondoVerde" onClick={this.enviarFormulario}>
                    Aceptar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
