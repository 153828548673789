import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Grid } from "@material-ui/core";
import graf from "../../../assets/css/graf.module.css";

export default class GraficoOferta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      datos: "",
      fecha_inicio: props.fecha_inicio,
      fecha_final: props.fecha_termino,
      id_ces: props.cesfam,
      root: "",
    };
  }

  GraficoOferta() {
    this.setState({ datas: [] });
    axios
      .get(global.config.modulos.grafico_demanda + "datos_generales_oferta/", {
        headers: authHeader(),
        params: {
          fecha_inicio: this.state.fecha_inicio,
          fecha_termino: this.state.fecha_final,
          cesfam: this.state.id_ces,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          return;
        }
        this.setState({ datas: res.data.datos, datos: res.data.datos1 }, () => {
          let root = am5.Root.new("chartdiv");

          root.setThemes([am5themes_Animated.new(root)]);

          root._logo.dispose();

          let chart = root.container.children.push(
            am5percent.PieChart.new(root, {
              layout: root.horizontalLayout,
            })
          );

          let series = chart.series.push(
            am5percent.PieSeries.new(root, {
              valueField: "valor",
              categoryField: "categoria",
              legendLabelText: "{category}: [bold]{value} -- [/] [bold {fill}]{valuePercentTotal.formatNumber('0.00')}%[/]",
              legendValueText: "",
              tooltip: am5.Tooltip.new(root, {
                labelText: "{category}: [bold]{value}[/]",
                autoTextColor: false,
              }),
            })
          );

          series.labels.template.setAll({
            fontSize: 13,
            fill: am5.color("#808080"),
            text: " [bold]{category}[/]\n {valuePercentTotal.formatNumber('0.00')}%\n {value}",
            textAlign: "left",
          });

          series.get("colors").set("colors", [am5.color("#d34a4a"), am5.color("#3cbf71")]);

          series.labels.template.set("visible", false);
          series.ticks.template.set("visible", false);
          series.slices.template.set("toggleKey", "none");

          series.data.setAll(this.state.datas);

          let legend = chart.children.push(
            am5.Legend.new(root, {
              centerY: am5.percent(50),
              y: am5.percent(50),
              layout: root.verticalLayout,
              clickTarget: "none",
            })
          );

          legend.valueLabels.template.setAll({ textAlign: "right" });

          legend.labels.template.setAll({
            maxWidth: 150,
            width: 250,
          });

          legend.data.setAll(series.dataItems);

          series.appear(1000, 100);
        });
      });
  }

  componentDidMount() {
    this.GraficoOferta();
  }

  render() {
    return (
      <Grid id="chartdiv" className={graf.grafico1}>
        <label style={{ position: "absolute", right: "5%", top: "5%", color: "black" }}>
          Oferta Inicial: <b>{this.state.datos}</b> -- <b>100%</b>
        </label>
      </Grid>
    );
  }
}
