import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components

import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Titulo from "./../../components/titulo/Titulo.js";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Grid, List, ListItem } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";

// CSS
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";
import InformacionUsuarioCSS from "./../../assets/css/usuarios.module.css";

// custom components
import InscribirApp from "./InscribirApp";
import ModificarClaveApp from "./ModificarClaveApp";

import AgregarFamiliar from "./../../components/Usuarios/GrupoFamiliarAgregar";
import EliminarUsuario from "./../../components/Usuarios/EliminarUsuario";
import ModificarUsuario from "./../../components/Usuarios/ModificarUsuario";
import AgregarUsuario from "./../../components/Usuarios/AgregarUsuario";
import AgendarUsuario from "./../../components/Usuarios/ModalAgendarHora";
import Comentarios from "./../../components/Usuarios/Comentarios";
import AgregarComentario from "./../../components/Usuarios/AgregarComentario";
import UltimaSolicitud from "./../../components/Usuarios/UltimaSolicitud";
import HorasOfrecidas from "./../../components/Usuarios/HorasOfrecidas";
import Telefonos from "./../../components/Usuarios/Telefonos";
import RegistroCanales from "./../../components/Usuarios/RegistroCanales";
import HistorialHoras from "./../../components/Usuarios/HistorialHoras";
import HorasFuturas from "./../../components/Usuarios/HorasFuturas";
import GrupoFamiliar from "./../../components/Usuarios/GrupoFamiliar";
import AgregarTelefono from "./../../components/Usuarios/AgregarTelefono";
import Beneficios from "./../../components/Usuarios/Beneficios";
import Ciclos from "./../../components/Usuarios/Ciclos";
import { store } from "./../../store/index";
import CasosRegistrados from "./../../components/Usuarios/CasosRegistrados";
import ControlSano from "./../../components/Usuarios/ControlSano.js";
import SolicitudHoras from "./SolicitudHoras";

import icono_vista_1 from "../../assets/img/usuarios/icono_vista_1.svg";
import icono_vista_3 from "../../assets/img/usuarios/icono_vista_3.svg";
import vista_1 from "../../assets/img/usuarios/iconos_colores/1_datos_personales.svg";
import vista_2 from "../../assets/img/usuarios/iconos_colores/2_registro_de_telefono.svg";
import vista_3 from "../../assets/img/usuarios/iconos_colores/3_ficha_del_paciente.svg";
import vista_4 from "../../assets/img/usuarios/iconos_colores/4_grupo_familiar.svg";
import vista_5 from "../../assets/img/usuarios/iconos_colores/5_historial_de_horas.svg";
import vista_6 from "../../assets/img/usuarios/iconos_colores/6_horas_futuras.svg";
import vista_7 from "../../assets/img/usuarios/iconos_colores/7_registro_llamadas.svg";
import vista_8 from "../../assets/img/usuarios/iconos_colores/8_comentarios.svg";

import icono_guardar from "../../assets/img/usuarios/icono_anunciar_llegada.svg";
import icono_modificar from "../../assets/img/usuarios/icono_modificar.svg";
import icono_reservar from "../../assets/img/usuarios/icono_reservar.svg";
import icono_eliminar from "../../assets/img/usuarios/icono_eliminar.svg";
import icono_inscribir from "../../assets/img/usuarios/inscribir.svg";
import icono_ver_mas from "../../assets/img/usuarios/icono_ver_mas.svg";
import copiar from "../../assets/img/usuarios/copiar.svg";

import tamanio_letra from "../../assets/img/usuarios/iconos_colores/tamaño_letra.svg";

import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";
import { NotificationManager } from "react-notifications";

import verifNumero from "../../components/Telefonos/VerificadorTelef";
import { ModalTelefono } from "../../components/Telefonos/ModalTelef";
import ModalHorasReservadas from "../../components/Usuarios/ModalHorasReservadas";

//3th components
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import Popover from "@material-ui/core/Popover";
import { get } from "react-hook-form";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 20,
  },
}))(Tooltip);

export default class InformacionUsuario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usu_id: props.usu_id,
      buscar_usuario: "",
      rut: "",
      nombre: "",
      nombre_social: "",
      direccion: "",
      comunaDireccion: "",
      estado: 0,

      telefono1: "",
      telefono2: "",
      ultimoTelefono: "",
      telefonoIngresado: "",

      fechanacimiento: "",
      sexo: "",
      edad: "",
      edadPrematuro: "",
      fechaNacPrematuro: "",

      correo: "",
      condicion: "",
      situacion: "",
      discapacidad: "",
      nombre_discapacidad: "",
      nombre_condicion: "",
      nombre_cronicos: "",
      diasPrematuro: "",
      usuario_bloqueado: "",
      estratificacion: "",
      comuna: "",
      sector: "",
      secuencia_usuario: false,

      motivoEliminar: 0,
      existe: 0,
      totalHorasOfrecidas: 0,

      letraTamanio: 4,
      tiempoBusquedaAsistidas: 0,
      tiempoBusquedaPerdidas: 0,

      formErrors: {},

      mensajeinscripcion: "",
      datosUsuario: false,
      botonesDatosPersonales: false,
      cambioVista: false,
      mostrarOpciones: false,
      datosGenerales: false,
      fichaUsuario: false,
      datosCanales: false,
      datosTelefono: false,
      datosHistorialHoras: false,
      datosHorasFuturas: false,
      datosRegistroCanales: false,
      datosGrupoFamiliar: false,
      modificarUsuario: false,
      agregarUsuario: false,
      agendarUsuario: false,
      eliminarUsuario: false,
      comentarios: false,
      modalAgregarTelefono: false,
      modalAgregarFamiliar: false,
      modalAgregarComentario: false,
      modalUltimaSolicitud: false,
      modalHorasOfrecidas: false,
      modalBeneficios: false,
      modalCiclos: false,
      extranjero: this.props.extranjero,
      programas: [],
      mostrarProgramas: null,
      modalCasosRegistrados: false,
      modalControlSano: false,
      modalTelefonoAlerta: false,
      tipo_agenda: 7,
      modalHorasReservadas: false,
      alertaTelActiva: false,
      sinNumeros: false,
      inscribirApp: false,
      modificarClaveApp: false,
      modalSolicitudHoras: false,
      pacienteBloqueadoPorCuestionario: false,

      horario: "",
      canal: "",
      especialidad: "",
      estadoNombre: "",
      tipoUsuario: null,

      mostrarBotonLimpiar: false,
      usuarios: [],
      busquedaUsuario: true,

      cliente: store.getState().cliente,
      cesfam: store.getState().cesfam,
      uspId: store.getState().usp,
      herramientas: store.getState().herramientas,
    };

    this.revisaExisteRut = this.revisaExisteRut.bind(this);
    this.mostrarAlertaCambiarEstado = this.mostrarAlertaCambiarEstado.bind(this);
    this.agregarUsuario = this.agregarUsuario.bind(this);

    this.modificarUsuario = this.modificarUsuario.bind(this);
    this.agendarUsuario = this.agendarUsuario.bind(this);
    this.eliminarUsuario = this.eliminarUsuario.bind(this);
    this.usuarioEliminado = this.usuarioEliminado.bind(this);
  }

  componentDidMount() {
    if (this.state.extranjero) {
      this.buscarUsuarioExtranjero();
    } else {
      this.buscarUsuario();
    }
    this.estadoBloqueoCuestionarioRecoleta();
  }

  asignarTamanio = () => {
    const apiUrl = global.config.modulos.usuariospanel + "por_id/" + store.getState().usp;
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        this.setState({ letraTamanio: res.data.usp_tam_letra });
        this.cambiarTamanio(res.data.usp_tam_letra, 2);
      });
  };

  handleClick = (event) => {
    this.setState({ mostrarProgramas: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ mostrarProgramas: null });
  };

  mostrarAlertaCambiarEstado() {
    var mensaje = "";
    var boton = "";
    var clase = "";
    if (this.state.estado === 0) {
      mensaje = "¿Deseas inscribir al paciente en HoraSalud?";
      boton = "Inscribir";
    } else {
      mensaje = "¿Deseas desinscribir al paciente de HoraSalud?";
      boton = "Desinscribir";
      clase = "background-rojo";
    }

    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: boton,
          onClick: () => this.cambiarEstadoUsuario(),
          className: "btnAceptar " + clase,
        },
      ],
    });
  }

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  mostrarCasosRegistrados = () => {
    this.setState({
      modalCasosRegistrados: true,
    });
  };

  ocultarCasosRegistrados = () => {
    this.setState({
      modalCasosRegistrados: false,
    });
  };

  mostrarControlSano = () => {
    this.setState({
      modalControlSano: true,
    });
  };

  ocultarControlSano = () => {
    this.setState({
      modalControlSano: false,
    });
  };

  revisaExisteRut() {
    var rut = document.getElementById("rut").value;

    this.setState({ rut: rut });

    var data = {
      rut: rut,
      canal: 5,
      cesfam: this.state.cesfam,
    };
    let apiUrl = global.config.modulos.usuarios + "por_rut/";
    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };
    if (rut.length <= 8) {
      axios(apiUrl, options).then((res) => {
        if (res.data === "noExiste") {
          this.setState({
            existe: 0,
          });
        } else {
          if (res.data["usuestado"] === 2) {
            this.setState({
              existe: 2,
            });
          } else {
            this.setState({
              existe: 1,
            });
          }
        }
      });
    }
  }

  validaciones() {
    const { buscar_usuario } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (buscar_usuario == "") {
      formIsValid = false;
      formErrors["usuarioErr"] = "Escribe el rut o nombre del paciente a buscar.";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  limpiarDatos = () => {
    this.setState({ mostrarBotonLimpiar: false });
    this.ocultarDatos();
  };

  buscarUsuario = (e) => {
    this.setState({ extranjero: false, mostrarBotonLimpiar: true });
    this.traerDatosUsuario();
  };

  buscarUsuarioExtranjero = (e) => {
    this.setState({ extranjero: true, mostrarBotonLimpiar: true });
    this.traerDatosUsuario();
  };

  estadoBloqueoCuestionarioRecoleta = () => {
    axios
      .get(global.config.modulos.cuestionarios + "validar_respuestas_recoleta/", {
        headers: authHeader(),
        params: {
          usuario: this.state.usu_id,
        },
      })
      .then((res) => {
        if (res.data.bloquear_perm) {
          this.setState({ pacienteBloqueadoPorCuestionario: true });
        }
      });
  };

  desbloqueoCuestionarioRecoleta = () => {
    confirmAlert({
      title: "",
      message: "¿Desea desbloquear este paciente?",
      buttons: [
        {
          label: "No",
          className: "btnCancelar",
        },
        {
          label: "Sí",
          className: "btnAceptar",
          onClick: () => {
            axios
              .patch(
                global.config.modulos.cuestionarios + "desbloquear_paciente_recoleta/",
                {
                  usuario: this.state.usu_id,
                  cesfam: this.state.cesfam,
                  usp_id: this.state.uspId,
                },
                {
                  headers: authHeader(),
                }
              )
              .then((res) => {
                this.setState({ pacienteBloqueadoPorCuestionario: false });
              });
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  traerDatosUsuario(id = null, redireccion = null) {
    let idUsuario = this.state.usu_id;
    if (id) {
      idUsuario = id;
    }

    let apiUrl = global.config.modulos.usuarios + "por_id/" + idUsuario;
    axios.get(apiUrl, { headers: authHeader(), params: { canal: 5, cesfam: this.state.cesfam, uspId: this.state.uspId } }).then((res) => {
      let fechaNac = res.data["datos"]["usu_fecha_nacimiento"];
      let fechaNacPrematuro = res.data["datos"]["fecha_nacimiento_prematuro"];
      let edad = "--";
      let edadPrematuro = "--";

      if (fechaNac) {
        edad = this.edad(fechaNac.split("T")[0]);
        fechaNac = this.fechaTexto(fechaNac.split("T")[0]);
      } else {
        fechaNac = "--";
      }

      if (fechaNacPrematuro) {
        edadPrematuro = this.edad(fechaNacPrematuro.split("T")[0]);
        fechaNacPrematuro = this.fechaTexto(fechaNacPrematuro.split("T")[0]);
      }

      if (res.data.datos.ultimaAgenda) {
        this.setState({
          horario: res.data.datos.ultimaAgenda.horario.replace("T", " "),
          canal: res.data.datos.ultimaAgenda.canal,
          especialidad: res.data.datos.ultimaAgenda.especialidad,
          estadoNombre: res.data.datos.ultimaAgenda.estado,
        });
      }

      var nombre_usuario = (res.data["datos"]["usu_nombre"] ? res.data["datos"]["usu_nombre"] : "") + " " + (res.data["datos"]["usu_apellido_p"] ? res.data["datos"]["usu_apellido_p"] : "") + " " + (res.data["datos"]["usu_apellido_m"] ? res.data["datos"]["usu_apellido_m"] : "");

      if (this.props.modificarRutNombre) {
        this.props.modificarRutNombre(res.data["datos"]["usu_rut"], nombre_usuario);
      }

      var pasaporte = "";
      if (res.data["datos"]["usu_pasaporte"] != null) {
        pasaporte = res.data["datos"]["usu_pasaporte"];
      } else {
        pasaporte = "";
      }

      this.setState(
        {
          usu_id: res.data["datos"]["usu_id"],
          nombre: nombre_usuario,
          nombre_social: res.data["datos"]["usu_nombre_social"],
          rut: res.data["datos"]["usu_rut"],
          direccion: res.data["datos"]["usu_direccion"],
          comunaDireccion: res.data["datos"]["usu_comuna_direccion"],
          estado: res.data["datos"]["usu_estado"],
          telefono1: res.data["datos"]["usu_telefono1"],
          telefono2: res.data["datos"]["usu_telefono2"],
          ultimoTelefono: res.data["datos"]["ultimoTelefono"],
          telefonoIngresado: res.data["datos"]["telefonoIngresado"],
          fechanacimiento: fechaNac,
          sexo: res.data["datos"]["usu_sexo"],
          edad: edad,
          edadPrematuro: edadPrematuro,
          fechaNacPrematuro: fechaNacPrematuro,
          totalHorasOfrecidas: res.data["datos"]["horas_ofrecidas"],
          secuencia_usuario: res.data["datos"]["secuencia_usuario"],

          correo: res.data["datos"]["usu_correo_electronico"],
          correoModi: res.data["datos"]["usu_correo_electronico"],
          condicion: res.data["datos"]["usu_condicion"],
          situacion: res.data["datos"]["usu_situacion"],
          discapacidad: res.data["datos"]["usu_discapacidad"],
          nombre_discapacidad: res.data["datos"]["str_discapacidades"],
          nombre_condicion: res.data["datos"]["str_condiciones"],
          nombre_cronicos: res.data["datos"]["str_cronicos"],
          diasPrematuro: res.data["datos"]["datos_prematuro"] ? res.data["datos"]["datos_prematuro"]["usp_dias"] : 0,
          usuario_bloqueado: res.data["datos"]["usuario_bloqueado"],
          estratificacion: res.data["datos"]["usu_estratificacion"],
          programas: res.data["datos"]["programas"],

          comuna: res.data["datos"]["usu_comuna"],
          cesfamUsuario: res.data["datos"]["cesfam"],
          sector: res.data["datos"]["sector"],
          tipoUsuario: res.data["datos"]["usu_tipo"],
          aplicacion: res.data["datos"]["aplicacion"],

          nro_ficha: res.data["datos"]["usu_ficha"],
          nro_carpeta: res.data["datos"]["usu_carpeta"],

          usu_pasaporte: pasaporte,
        },
        () => {
          if (this.state.tipo_agenda == 7 || this.state.tipo_agenda == 9) {
            this.mostrarModalHorasReservadasPorTipo(this.state.tipo_agenda);
          }

          this.verificarCondicion(res.data["datos"]["usu_id"]);
          setTimeout(() => {
            this.asignarTamanio();
          }, 10);
        }
      );

      if (redireccion) {
        this.activarVista(redireccion);
      } else {
        this.mostrarDatos();
      }
    });
  }

  verificarCondicion = (usuario) => {
    axios
      .get(global.config.modulos.usuarios + "condicion_por_confirmar/", {
        headers: authHeader(),
        params: { usuario: usuario, condicion: 8 },
      })
      .then((res) => {
        if (res.data.estado) {
          confirmAlert({
            title: "",
            message: "Este paciente ha indicado tener movilidad reducida a través de HoraSalud ¿usted siendo funcionario público considera y confirma que este paciente tiene movilidad reducida?",
            buttons: [
              {
                label: "Cancelar",
                className: "btnCancelar",
              },
              {
                label: "No",
                className: "btnCancelar",
                onClick: () => {
                  this.actualizarTipoCondicion(usuario, 8, 2);
                },
              },
              {
                label: "Sí",
                className: "btnAceptar",
                onClick: () => {
                  this.actualizarTipoCondicion(usuario, 8, 1);
                },
              },
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
          });
        }
      });
  };

  actualizarTipoCondicion = (usuario, condicion, tipo) => {
    axios
      .patch(
        global.config.modulos.usuarios + "actualizar_tipo_condicion/",
        {
          usuario: usuario,
          condicion: condicion,
          tipo: tipo,
          usp: this.state.uspId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          confirmAlert({
            title: "",
            message: "Gracias por ayudarnos a validar esta información.",
            buttons: [
              {
                label: "Aceptar",
                className: "btnAceptar",
              },
            ],
          });
        }
      });
  };

  mostrarModalHorasReservadasPorTipo = (tipo_agenda) => {
    let cesfam = this.state.cesfam;
    let apiUrl = global.config.modulos.cesfams + "verificar_modal_reservas/";

    axios
      .get(apiUrl, {
        headers: authHeader(),
        params: {
          cesfam: cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            tiempoBusquedaAsistidas: res.data.datos["cmr_dias_asistidas"],
            tiempoBusquedaPerdidas: res.data.datos["cmr_dias_perdidas"],
          });
          if (!res.data.datos["cmr_asistidas"] && !res.data.datos["cmr_perdidas"]) {
            tipo_agenda = null;
            this.setState({ tipo_agenda: null });
            this.VerificarNumeros();
          }

          if (tipo_agenda == 9 && res.data.datos["cmr_perdidas"]) {
            this.setState({ tipo_agenda: tipo_agenda }, () => {
              this.setState({ modalHorasReservadas: true });
            });
          } else if (tipo_agenda == 9 && !res.data.datos["cmr_perdidas"]) {
            this.setState({ tipo_agenda: null });
            this.VerificarNumeros();
          }

          if (tipo_agenda == 7 && res.data.datos["cmr_asistidas"]) {
            this.setState({ tipo_agenda: tipo_agenda }, () => {
              this.setState({ modalHorasReservadas: true });
            });
          } else {
            this.setState({ tipo_agenda: 9 });
          }

          if (tipo_agenda == 7 && !res.data.datos["cmr_asistidas"]) {
            tipo_agenda = 9;
            this.setState({ tipo_agenda: tipo_agenda }, () => {
              this.setState({ modalHorasReservadas: true });
            });
          }
        } else {
          this.setState({ tipo_agenda: null });
        }
      });
  };

  ocultarModalHorasReservadasPorTipo = () => {
    this.setState({ modalHorasReservadas: false }, () => {
      console.log("t2", this.state.tipo_agenda);
      if (this.state.tipo_agenda == 7) {
        this.mostrarModalHorasReservadasPorTipo(9);
      } else if (this.state.tipo_agenda == 9) {
        this.setState({ tipo_agenda: null });
        this.VerificarNumeros();
      }
    });
  };

  VerificarNumeros = () => {
    if (this.state.tipoUsuario == 4) {
      return;
    }

    let apiUrl = global.config.modulos.telefonos + "telefonos_por_usuario/" + this.state.usu_id;
    axios(apiUrl, {
      headers: authHeader(),
    }).then((res) => {
      if (res.data.datos === 0) {
        this.setState({ sinNumeros: true });
      }
      const verifExist = verifNumero.verificarNumCanales(res.data.datos);
      const verifDate = verifNumero.verifDate(res.data.datos);

      // Verifica si hay numeros asociados al paciente que no sean de GF y los valida
      if (!verifExist && !verifDate && !this.state.alertaTelActiva) {
        this.setState({ modalTelefonoAlerta: true, alertaTelActiva: true });
      } else if (!verifExist && !verifDate && this.state.alertaTelActiva) {
        this.setState({ alertaTelActiva: false }, () => {
          try {
            this.props.limpiarDatos();
          } catch (e) {
            this.props.onClose();
          }
        });
      } else {
        // Si detecta numeros sin GF, valida los ingresos de los numeros de SOME
        /* if (!verifSome && !this.state.alertaTelActiva) {
          this.setState({ modalTelefonoAlerta: true, alertaTelActiva: true });
        } else if (!verifSome && this.state.alertaTelActiva) {
          this.setState({ alertaTelActiva: false }, () => {
            this.props.limpiarDatos();
          });
        } else {
          this.setState({ alertaTelActiva: false });
        } */
      }
    });
  };

  onClose = () => {
    console.log("cerrar modal");
    this.props.onClose();
  };

  cerrarAlertaTelefono = () => {
    this.setState({ modalTelefonoAlerta: false }, () => {
      try {
        this.limpiarDatos();
        this.props.limpiarDatos();
      } catch (e) {
        this.props.onClose();
      }
    });
  };

  confirmarAlertaTelefono = () => {
    this.setState({ modalTelefonoAlerta: false }, () => {
      try {
        this.cambioOpciones(2);
        this.mostrarModalAgregarTelefono();
      } catch (e) {
        this.props.onClose();
      }
    });
  };

  fechaTexto(dateString, diasPrematuro = 0) {
    var meses = [" ", "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    var fecha = dateString.split("-");
    var ano = fecha[0];
    var mes = meses[parseInt(fecha[1])];
    var dia = fecha[2];

    var fechaCompleta = dia + " de " + mes + " del " + ano;

    return fechaCompleta;
  }

  edad(date) {
    var fechaDate = new Date(date);
    date = date.split("-");
    var hoy = new Date();
    var ano = hoy.getFullYear();
    var mes = hoy.getMonth() + 1;
    var dia = hoy.getDate();
    var yy = parseInt(date[0]);
    var mm = parseInt(date[1]);
    var dd = parseInt(date[2]);
    var anos, meses, dias;

    if (fechaDate > hoy) {
      const diferenciaMilisegundos = fechaDate - hoy;
      const diasRestantes = Math.ceil(diferenciaMilisegundos / (24 * 60 * 60 * 1000));
      return "-" + diasRestantes + " días";
    } else {
      // months
      meses = mes - mm;
      if (dia < dd) {
        meses = meses - 1;
      }
      // years
      anos = ano - yy;
      if (mes * 100 + dia < mm * 100 + dd) {
        anos = anos - 1;
        meses = meses + 12;
      }
      // days
      dias = Math.floor((hoy.getTime() - new Date(yy + anos, mm + meses - 1, dd).getTime()) / (24 * 60 * 60 * 1000));
      //
      return anos + " años " + meses + " meses y " + dias + " días";
    }
  }

  cambiarEstadoUsuario() {
    var data;
    if (this.state.estado === 1) {
      data = {
        usu_estado: 0,
        usp_id: this.state.uspId,
      };
    } else {
      data = {
        usu_estado: 1,
        usu_fecha_inscripcion: moment().format("YYYY-MM-DD HH:mm:ss"),
        usu_canal_inscripcion: 5,
        usp_id: this.state.uspId,
      };
    }
    let apiUrl = global.config.modulos.usuarios + "por_id/" + this.state.usu_id;
    const options = {
      method: "PATCH",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      this.traerDatosUsuario(null, "fichaUsuario");
    });
  }

  ocultarComentarios = () => {
    this.setState({ comentarios: false });
  };

  mostrarModalAgregarTelefono = () => {
    this.setState({
      modalAgregarTelefono: true,
    });
  };

  ocultarCiclos = () => {
    this.setState({ modalCiclos: false });
  };

  mostrarCiclos = () => {
    this.setState({
      modalCiclos: true,
    });
  };
  ocultarBeneficios = () => {
    this.setState({ modalBeneficios: false });
  };

  mostrarBeneficios = () => {
    this.setState({
      modalBeneficios: true,
    });
  };

  ocultarModalAgregarTelefono = () => {
    this.setState({ modalAgregarTelefono: false, datosCanales: false }, () => {
      this.VerificarNumeros();
    });
    this.setState({ datosCanales: true });
  };

  mostrarModalAgregarFamiliar = () => {
    this.setState({
      modalAgregarFamiliar: true,
    });
  };

  ocultarModalAgregarFamiliar = () => {
    this.setState({ modalAgregarFamiliar: false, datosGrupoFamiliar: false });
    this.setState({ datosGrupoFamiliar: true });
  };

  mostrarModalAgregarComentario = () => {
    this.setState({
      modalAgregarComentario: true,
    });
  };

  ocultarModalAgregarComentario = () => {
    this.setState({ modalAgregarComentario: false, comentarios: false });
    this.setState({ comentarios: true });
  };

  mostrarModalUltimaSolicitud = () => {
    this.setState({
      modalUltimaSolicitud: true,
    });
  };

  ocultarModalUltimaSolicitud = () => {
    this.setState({ modalUltimaSolicitud: false });
  };

  mostrarHorasOfrecidas = () => {
    this.setState({
      modalHorasOfrecidas: true,
    });
  };

  ocultarHorasOfrecidas = () => {
    this.setState({ modalHorasOfrecidas: false });
  };

  modificarUsuario() {
    this.setState({
      modificarUsuario: true,
    });
  }

  agregarUsuario = () => {
    this.setState({
      agregarUsuario: true,
    });
  };

  agendarUsuario() {
    if (this.state.estado == 0 && this.state.cesfam != 76) {
      confirmAlert({
        title: "",
        message: "Para poder reservar una hora, primero debe inscribir al paciente en HoraSalud",
        buttons: [
          {
            label: "Aceptar",
            className: "btnAceptar",
          },
        ],
      });
    } else {
      this.setState({
        agendarUsuario: true,
      });
    }
  }

  ocultarModificar = () => {
    this.setState({ modificarUsuario: false });
    this.traerDatosUsuario();
  };

  ocultarAgregar = () => {
    this.setState({ agregarUsuario: false });
    //this.traerDatosUsuario(this.state.rut);
  };

  ocultarAgendar = () => {
    this.setState({ agendarUsuario: false });
    this.traerDatosUsuario();
  };

  eliminarUsuario() {
    this.setState({
      eliminarUsuario: true,
    });
  }

  ocultarEliminar = () => {
    this.setState({
      eliminarUsuario: false,
    });
  };

  usuarioEliminado = () => {
    this.setState({
      eliminarUsuario: false,
    });
    this.ocultarDatos();
    this.limpiarDatos();
    this.props.limpiarDatos();
    this.setState({ rut: "" });
  };

  mostrarDatos() {
    this.setState({
      datosUsuario: true,
      botonesDatosPersonales: true,
      cambioVista: true,
      mostrarOpciones: true,
      datosTelefono: false,
      datosGrupoFamiliar: false,
      datosHistorialHoras: false,
      datosHorasFuturas: false,
      datosRegistroCanales: false,
      datosGenerales: false,
      fichaUsuario: false,
      datosCanales: false,
      comentarios: false,
    });
    this.cambioOpciones(1);
  }

  ocultarDatos = () => {
    this.setState({
      cambioVista: false,
      datosUsuario: false,
      botonesDatosPersonales: false,
      mostrarOpciones: false,
      datosTelefono: false,
      datosGrupoFamiliar: false,
      datosHistorialHoras: false,
      datosHorasFuturas: false,
      datosRegistroCanales: false,
      datosGenerales: false,
      fichaUsuario: false,
      datosCanales: false,
      comentarios: false,
    });
  };

  activarVista(nombre) {
    this.setState({
      datosUsuario: false,
      datosTelefono: false,
      datosGrupoFamiliar: false,
      datosHistorialHoras: false,
      datosHorasFuturas: false,
      datosRegistroCanales: false,
      datosGenerales: false,
      fichaUsuario: false,
      datosCanales: false,
      comentarios: false,
      [nombre]: true,
    });
  }

  seleccionarMenu = () => {
    document.getElementById("datosPersonales").src = vista_1;
    if (!this.state.extranjero) {
      document.getElementById("registrosCanales").src = vista_2;

      document.getElementById("fichaUsuario").src = vista_3;
      document.getElementById("grupoFamiliar").src = vista_4;
    }
    document.getElementById("historialHoras").src = vista_5;
    document.getElementById("horasFuturas").src = vista_6;
    if (!this.state.extranjero) {
      if ((this.state.herramientas.includes("H1") || this.state.herramientas.includes("H4")) && this.state.cesfam != 76) {
        document.getElementById("registrosLlamadas").src = vista_7;
      }
      document.getElementById("comentarios").src = vista_8;
    }

    document.getElementById("datosPersonales").classList.remove(InformacionUsuarioCSS.imagenUsuarioSeleccionado, InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);

    if (!this.state.extranjero) {
      document.getElementById("registrosCanales").classList.remove(InformacionUsuarioCSS.imagenUsuarioSeleccionado, InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);

      document.getElementById("fichaUsuario").classList.remove(InformacionUsuarioCSS.imagenUsuarioSeleccionado, InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);
      document.getElementById("grupoFamiliar").classList.remove(InformacionUsuarioCSS.imagenUsuarioSeleccionado, InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);
    }
    document.getElementById("historialHoras").classList.remove(InformacionUsuarioCSS.imagenUsuarioSeleccionado, InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);
    document.getElementById("horasFuturas").classList.remove(InformacionUsuarioCSS.imagenUsuarioSeleccionado, InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);
    if (!this.state.extranjero) {
      if ((this.state.herramientas.includes("H1") || this.state.herramientas.includes("H4")) && this.state.cesfam != 76) {
        document.getElementById("registrosLlamadas").classList.remove(InformacionUsuarioCSS.imagenUsuarioSeleccionado, InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);
      }
      document.getElementById("comentarios").classList.remove(InformacionUsuarioCSS.imagenUsuarioSeleccionado, InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);
    }
  };

  cambioOpciones = (tipo) => {
    this.seleccionarMenu();

    if (tipo == 1) {
      if (this.state.letraTamanio <= 2) {
        document.getElementById("datosPersonales").classList.add(InformacionUsuarioCSS.imagenUsuarioSeleccionado);
      } else {
        document.getElementById("datosPersonales").classList.add(InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);
      }
      this.activarVista("datosGenerales");
      setTimeout(() => {
        this.cambiarTamanio(this.state.letraTamanio, 2);
      }, 10);
    } else if (tipo == 2) {
      if (this.state.letraTamanio <= 2) {
        document.getElementById("registrosCanales").classList.add(InformacionUsuarioCSS.imagenUsuarioSeleccionado);
      } else {
        document.getElementById("registrosCanales").classList.add(InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);
      }
      this.activarVista("datosCanales");
      setTimeout(() => {
        this.cambiarTamanio(this.state.letraTamanio, 2);
      }, 10);
    } else if (tipo == 3) {
      if (this.state.letraTamanio <= 2) {
        document.getElementById("fichaUsuario").classList.add(InformacionUsuarioCSS.imagenUsuarioSeleccionado);
      } else {
        document.getElementById("fichaUsuario").classList.add(InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);
      }
      this.activarVista("fichaUsuario");
      setTimeout(() => {
        this.cambiarTamanio(this.state.letraTamanio, 2);
      }, 10);
    } else if (tipo == 4) {
      if (this.state.letraTamanio <= 2) {
        document.getElementById("comentarios").classList.add(InformacionUsuarioCSS.imagenUsuarioSeleccionado);
      } else {
        document.getElementById("comentarios").classList.add(InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);
      }
      this.activarVista("comentarios");
      setTimeout(() => {
        this.cambiarTamanio(this.state.letraTamanio, 2);
      }, 10);
    } else if (tipo == 6) {
      if (this.state.letraTamanio <= 2) {
        document.getElementById("grupoFamiliar").classList.add(InformacionUsuarioCSS.imagenUsuarioSeleccionado);
      } else {
        document.getElementById("grupoFamiliar").classList.add(InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);
      }
      this.activarVista("datosGrupoFamiliar");
    } else if (tipo == 7) {
      if (this.state.letraTamanio <= 2) {
        document.getElementById("historialHoras").classList.add(InformacionUsuarioCSS.imagenUsuarioSeleccionado);
      } else {
        document.getElementById("historialHoras").classList.add(InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);
      }
      this.activarVista("datosHistorialHoras");
    } else if (tipo == 8) {
      if (this.state.letraTamanio <= 2) {
        document.getElementById("registrosLlamadas").classList.add(InformacionUsuarioCSS.imagenUsuarioSeleccionado);
      } else {
        document.getElementById("registrosLlamadas").classList.add(InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);
      }
      this.activarVista("datosRegistroCanales");
    } else if (tipo == 9) {
      if (this.state.letraTamanio <= 2) {
        document.getElementById("horasFuturas").classList.add(InformacionUsuarioCSS.imagenUsuarioSeleccionado);
      } else {
        document.getElementById("horasFuturas").classList.add(InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);
      }
      this.activarVista("datosHorasFuturas");
    }
  };

  copiarRut = (rut, pass) => {
    navigator.clipboard.writeText(rut);
    if (pass) {
      NotificationManager.info("Pasaporte copiado");
    } else {
      NotificationManager.info("Rut copiado");
    }
  };

  mostrarAlertaDesbloquearUsuario = () => {
    var mensaje = "";
    var clase = "";
    mensaje = "¿Deseas desbloquear a este paciente?";

    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Desbloquear",
          onClick: () => this.desbloquearUsuario(),
          className: "btnAceptar " + clase,
        },
      ],
    });
  };

  desbloquearUsuario = () => {
    axios
      .patch(
        global.config.modulos.usuarios + "desbloquear_usuario/",
        {
          usuario: this.state.usu_id,
          usuario_panel: this.state.uspId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        this.traerDatosUsuario(null, "fichaUsuario");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  inscribirApp = () => {
    this.setState({
      inscribirApp: true,
    });
  };

  ocultarInscribirApp = () => {
    this.traerDatosUsuario();
    this.setState({
      inscribirApp: false,
    });
  };

  modificarClaveApp = () => {
    this.setState({
      modificarClaveApp: true,
    });
  };

  ocultarModificarClaveApp = () => {
    this.setState({
      modificarClaveApp: false,
    });
  };

  modificarCorreo = () => {
    this.setState(
      {
        modCorreo: true,
        correoModi: this.state.correo,
      },
      () => {
        this.cambiarTamanio(this.state.letraTamanio, 2);
      }
    );
  };

  confirmarModificarCorreo = () => {
    const { correoModi } = this.state;
    if (correoModi == "") {
      NotificationManager.warning("Ingresa el correo.");
      return false;
    }

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(correoModi)) {
      NotificationManager.warning("Verifica el formato del correo.");
      return false;
    }

    confirmAlert({
      title: correoModi,
      message: "¿Es correcto?",
      buttons: [
        {
          label: "Editar",
          className: "btnCancelar",
        },
        {
          label: "Sí",
          className: "btnAceptar",
          onClick: () =>
            axios
              .patch(
                global.config.modulos.usuarios + "por_id/" + this.state.usu_id,
                {
                  usp_id: this.state.uspId,
                  usu_correo_electronico: this.state.correoModi,
                },
                {
                  headers: authHeader(),
                }
              )
              .then((res) => {
                this.ocultarModificarCorreo();
                this.traerDatosUsuario(null, null);
              })
              .catch((error) => {
                console.log(error);
              }),
        },
      ],
    });
  };

  cambiarCorreo = (e) => {
    const { name, value } = e.target;
    this.setState({ correoModi: value });
  };

  ocultarModificarCorreo = () => {
    this.setState({
      modCorreo: false,
    });
  };

  mostrarModalSolicitudHoras = () => {
    this.setState({
      modalSolicitudHoras: true,
    });
  };

  ocultarModalSolicitudHoras = () => {
    this.setState({
      modalSolicitudHoras: false,
    });
  };

  cambiarTamanio = (tipo, transition) => {
    this.removerClasses();
    const allElements = document.querySelectorAll("[name='labelTam']");
    const allElements_2 = document.querySelectorAll("[name='icon_3']");
    const allElements_3 = document.querySelectorAll("[name='icon_paciente_ficha']");
    const imgCambioOpciones = document.querySelectorAll("[name='img_cambioOpciones']");

    const classMap = {
      1: InformacionUsuarioCSS.label_11,
      2: InformacionUsuarioCSS.label_13,
      3: InformacionUsuarioCSS.label_16,
      4: InformacionUsuarioCSS.label_20,
    };

    const imageIdMap = {
      1: "img1",
      2: "img2",
      3: "img3",
      4: "img4",
    };

    if (allElements.length === 0) {
      if (tipo in imageIdMap) {
        const imgId = imageIdMap[tipo];
        document.getElementById(imgId).style.opacity = 1;
      }
    } else {
      allElements.forEach((element) => {
        if (tipo in classMap) {
          console.log(classMap[tipo]);
          if (transition == 1) {
            element.style.transition = "all 300ms linear";
          }
          element.classList.add(classMap[tipo]);
          const imgId = imageIdMap[tipo];
          document.getElementById(imgId).style.opacity = 1;
        }
      });
    }

    allElements_2.forEach((element) => {
      if (tipo == "1" || tipo == "2") {
        element.classList.replace(InformacionUsuarioCSS.imagenBotonAccion_g, InformacionUsuarioCSS.imagenBotonAccion_p);
      } else if (tipo == "3" || tipo == "4") {
        element.classList.replace(InformacionUsuarioCSS.imagenBotonAccion_p, InformacionUsuarioCSS.imagenBotonAccion_g);
      }
      if (transition == 1) {
        element.style.transition = "all 300ms linear";
      }
    });

    imgCambioOpciones.forEach((element) => {
      if (tipo == "1" || tipo == "2") {
        element.classList.replace(InformacionUsuarioCSS.imagenUsuarioSeleccionado_2, InformacionUsuarioCSS.imagenUsuarioSeleccionado);

        element.classList.add(InformacionUsuarioCSS.img_tam);
      } else if (tipo == "3" || tipo == "4") {
        element.classList.replace(InformacionUsuarioCSS.imagenUsuarioSeleccionado, InformacionUsuarioCSS.imagenUsuarioSeleccionado_2);
        element.classList.add(InformacionUsuarioCSS.img_tam_2);
      }
      /* if (transition == 1) {
        element.style.transition = "all 300ms linear";
      } */
    });

    allElements_3.forEach((element) => {
      if (tipo == "1" || tipo == "2") {
        element.classList.replace(InformacionUsuarioCSS.imagenDatos, InformacionUsuarioCSS.imagenDatosPequeño);
      } else if (tipo == "3" || tipo == "4") {
        element.classList.replace(InformacionUsuarioCSS.imagenDatosPequeño, InformacionUsuarioCSS.imagenDatos);
      }
      if (transition == 1) {
        element.style.transition = "all 300ms linear";
      }
    });

    axios
      .patch(
        global.config.modulos.usuariospanel + "cambio_tamanio_letra/",
        {
          usuarioPanel: store.getState().usp,
          tipo_letra: tipo,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        this.setState({ letraTamanio: tipo });
      });
  };

  removerClasses = () => {
    const allElements = document.querySelectorAll("[name='labelTam']");
    const allElements_img = document.querySelectorAll("[name='img_tamTexto']");
    const allElements_img_2 = document.querySelectorAll("[name='img_cambioOpciones']");
    allElements.forEach((element) => {
      element.classList.remove(InformacionUsuarioCSS.label_11, InformacionUsuarioCSS.label_13, InformacionUsuarioCSS.label_16, InformacionUsuarioCSS.label_20);
    });
    allElements_img.forEach((element) => {
      element.style.opacity = 0.3;
    });
    allElements_img_2.forEach((element) => {
      element.classList.remove(InformacionUsuarioCSS.img_tam, InformacionUsuarioCSS.img_tam_2);
    });
  };

  render() {
    const { usuarioErr } = this.state.formErrors;
    const { rutErr } = this.state.formErrors;
    const { extranjero } = this.state;
    //console.log(this.state);

    let sexoUser;
    if (this.state.sexo === "M") {
      sexoUser = "Masculino";
    } else if (this.state.sexo === "F") {
      sexoUser = "Femenino";
    } else if (this.state.sexo === "I") {
      sexoUser = "Intersex";
    } else {
      sexoUser = "Indefinido";
    }

    let estadoUser;
    if (this.state.estado === 1) {
      estadoUser = "Inscrito";
    } else {
      estadoUser = "No Inscrito";
    }

    let telefono1;
    if (this.state.telefono1 === "") {
      telefono1 = "Sin teléfono";
    } else {
      telefono1 = this.state.telefono1;
    }

    let telefono2;
    if (this.state.telefono2 === "") {
      telefono2 = "Sin teléfono";
    } else {
      telefono2 = this.state.telefono2;
    }

    let situacion;
    if (this.state.situacion === 0) {
      situacion = "Adscrito";
    } else if (this.state.situacion === 1) {
      situacion = "Inscrito";
    }

    let ultimoTelefono;
    if (this.state.ultimoTelefono === "") {
      ultimoTelefono = "--";
    } else {
      ultimoTelefono = this.state.ultimoTelefono;
    }

    let telefonoIngresado;
    if (this.state.telefonoIngresado === "") {
      telefonoIngresado = "--";
    } else {
      telefonoIngresado = this.state.telefonoIngresado;
    }

    const elegirUsuario = (usu_id) => {
      window.localStorage.setItem("usuId", usu_id);
      this.setState({
        usu_id: usu_id,
      });
    };

    var nro_ficha = this.state.nro_ficha;
    if (this.state.nro_ficha == null) {
      nro_ficha = "--";
    }

    var nro_carpeta = this.state.nro_carpeta;
    if (this.state.nro_carpeta == null) {
      nro_carpeta = "--";
    }

    const open = Boolean(this.state.mostrarProgramas);
    const id = open ? "simple-popover" : undefined;

    return (
      <>
        <div className={InformacionUsuarioCSS.seleccioonTamanio}>
          <img src={tamanio_letra} id="img4" name="img_tamTexto" onClick={() => this.cambiarTamanio(4, 1)} style={{ width: "60%", cursor: "pointer", opacity: "0.3" }} />
          <img src={tamanio_letra} name="img_tamTexto" id="img3" onClick={() => this.cambiarTamanio(3, 1)} style={{ width: "50%", cursor: "pointer", opacity: "0.3" }} />
          <img src={tamanio_letra} id="img2" name="img_tamTexto" onClick={() => this.cambiarTamanio(2, 1)} style={{ width: "45%", cursor: "pointer", opacity: "0.3" }} />
          <img src={tamanio_letra} name="img_tamTexto" id="img1" onClick={() => this.cambiarTamanio(1, 1)} style={{ width: "40%", cursor: "pointer", opacity: "0.3" }} />
        </div>
        <div>
          <div>
            {this.state.cambioVista ? (
              <div style={{ marginBottom: 20 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={9}>
                    <React.Fragment>
                      <div className="divImageUser">
                        <img id="datosPersonales" className={InformacionUsuarioCSS.imagenUsuario} src={vista_1} onClick={() => this.cambioOpciones(1)} name="img_cambioOpciones" />
                      </div>
                      {!extranjero ? (
                        <React.Fragment>
                          <div className="divImageUser">
                            <img id="registrosCanales" className={InformacionUsuarioCSS.imagenUsuario} src={vista_2} onClick={() => this.cambioOpciones(2)} name="img_cambioOpciones" />
                          </div>

                          <div className="divImageUser">
                            <img id="fichaUsuario" className={InformacionUsuarioCSS.imagenUsuario} src={vista_3} onClick={() => this.cambioOpciones(3)} name="img_cambioOpciones" />
                          </div>

                          <div className="divImageUser">
                            <img id="grupoFamiliar" className={InformacionUsuarioCSS.imagenUsuario} src={vista_4} onClick={() => this.cambioOpciones(6)} name="img_cambioOpciones" />
                          </div>
                        </React.Fragment>
                      ) : null}

                      <div className="divImageUser">
                        <img id="historialHoras" className={InformacionUsuarioCSS.imagenUsuario} src={vista_5} onClick={() => this.cambioOpciones(7)} name="img_cambioOpciones" />
                      </div>

                      <div className="divImageUser">
                        <img id="horasFuturas" className={InformacionUsuarioCSS.imagenUsuario} src={vista_6} onClick={() => this.cambioOpciones(9)} name="img_cambioOpciones" />
                      </div>

                      {!extranjero ? (
                        <React.Fragment>
                          {(this.state.herramientas.includes("H1") || this.state.herramientas.includes("H4")) && this.state.cesfam != 76 ? (
                            <div className="divImageUser">
                              <img id="registrosLlamadas" className={InformacionUsuarioCSS.imagenUsuario} src={vista_7} onClick={() => this.cambioOpciones(8)} name="img_cambioOpciones" style={this.state.letraTamanio <= 2 ? { width: "5.2vw" } : { width: "6vw" }} />
                            </div>
                          ) : null}
                          <div className="divImageUser">
                            <img id="comentarios" className={InformacionUsuarioCSS.imagenUsuario} src={vista_8} onClick={() => this.cambioOpciones(4)} name="img_cambioOpciones" />
                          </div>
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    {this.state.datosGenerales ? (
                      <React.Fragment>
                        {!this.state.extranjero ? (
                          <React.Fragment>
                            {this.state.herramientas.includes("H22") ? (
                              <Tooltip title="Eliminar" aria-label="Eliminar">
                                <img className={InformacionUsuarioCSS.imagenBotonAccion_g} name="icon_3" src={icono_eliminar} onClick={this.eliminarUsuario} />
                              </Tooltip>
                            ) : null}

                            {this.state.herramientas.includes("H23") ? (
                              <Tooltip title="Modificar" aria-label="Modificar">
                                <img className={InformacionUsuarioCSS.imagenBotonAccion_g} name="icon_3" src={icono_modificar} onClick={this.modificarUsuario} />
                              </Tooltip>
                            ) : null}
                          </React.Fragment>
                        ) : null}

                        {this.state.herramientas.includes("H21") ? (
                          <Tooltip title="Reservar" aria-label="Reservar">
                            <img className={InformacionUsuarioCSS.imagenBotonAccion_g} name="icon_3" src={icono_reservar} onClick={this.agendarUsuario} />
                          </Tooltip>
                        ) : null}
                      </React.Fragment>
                    ) : null}

                    {this.state.datosCanales ? (
                      <React.Fragment>
                        <Button variant="contained" className="botonesAlineados botonAgregarUsuario fondoVerde" onClick={this.mostrarModalAgregarTelefono}>
                          Agregar teléfono
                        </Button>
                      </React.Fragment>
                    ) : null}
                    {this.state.datosGrupoFamiliar ? (
                      <React.Fragment>
                        <Button variant="contained" className="botonesAlineados botonAgregarUsuario fondoVerde" onClick={this.mostrarModalAgregarFamiliar}>
                          Agregar familiar
                        </Button>
                      </React.Fragment>
                    ) : null}
                    {this.state.comentarios ? (
                      <React.Fragment>
                        <Button variant="contained" className="botonesAlineados botonAgregarUsuario fondoVerde" onClick={this.mostrarModalAgregarComentario}>
                          Agregar comentario
                        </Button>
                      </React.Fragment>
                    ) : null}
                  </Grid>
                </Grid>
              </div>
            ) : null}
            {this.state.datosGenerales ? (
              <div className="User">
                <Grid container spacing={2} className="fichaUsuario">
                  <div id="datosFicha1">
                    <Grid item xs={2} sm={1}></Grid>
                    <Grid item xs={9}>
                      <div style={{ marginLeft: "3vw" }}>
                        <img className={this.state.letraTamanio > 2 ? InformacionUsuarioCSS.imagenDatos : InformacionUsuarioCSS.imagenDatosPequeño} src={icono_vista_1} name="icon_paciente_ficha" />
                        <div className={this.state.letraTamanio > 2 ? InformacionUsuarioCSS.tablaDatosUsuario : InformacionUsuarioCSS.tablaDatosUsuario_peque} id="tablaUsuario" style={{ border: "2px solid #reaa028" }}>
                          <Grid container spacing={2} className="contenidoDatosUsuario">
                            <Grid className={InformacionUsuarioCSS.datosIndividuales} item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                Nombre:
                              </label>
                              <label name="labelTam" className={InformacionUsuarioCSS.labelDatosUsuario}>
                                {this.state.nombre}
                              </label>
                            </Grid>
                            <Grid className={InformacionUsuarioCSS.datosIndividuales} item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                Nombre social:
                              </label>
                              <label name="labelTam" className={InformacionUsuarioCSS.labelDatosUsuario}>
                                {this.state.nombre_social ? this.state.nombre_social : "--"}
                              </label>
                            </Grid>
                            <Grid className={InformacionUsuarioCSS.datosIndividuales} item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                Fecha de nacimiento:
                              </label>
                              <label name="labelTam" className={InformacionUsuarioCSS.labelDatosUsuario}>
                                {this.state.fechanacimiento}
                                {this.state.diasPrematuro ? (
                                  <LightTooltip title={"Paciente prematuro, fecha corregida: " + this.state.fechaNacPrematuro} aria-label={"Paciente prematuro, fecha corregida: " + this.state.fechaNacPrematuro}>
                                    <img
                                      style={{
                                        width: 18,
                                        float: "inherit",
                                        marginBottom: -4,
                                      }}
                                      className="imagenBotonAccion "
                                      src={icono_ver_mas}
                                    />
                                  </LightTooltip>
                                ) : (
                                  ""
                                )}
                              </label>
                            </Grid>
                            <Grid className={InformacionUsuarioCSS.datosIndividuales} item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                Rut:
                              </label>
                              <label name="labelTam" className={InformacionUsuarioCSS.labelDatosUsuario}>
                                {this.state.rut}
                              </label>
                              <Tooltip title="Copiar RUT del paciente" aria-label="Copiar RUT del paciente">
                                <img
                                  style={{
                                    width: 18,
                                    float: "inherit",
                                    marginBottom: -4,
                                  }}
                                  className="imagenBotonAccion "
                                  src={copiar}
                                  onClick={() => this.copiarRut(this.state.rut)}
                                />
                              </Tooltip>
                              {this.state.usu_pasaporte != "" && (
                                <>
                                  <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                    Pasaporte:
                                  </label>
                                  <label name="labelTam" className={InformacionUsuarioCSS.labelDatosUsuario}>
                                    {this.state.usu_pasaporte}
                                  </label>
                                  <Tooltip title="Copiar RUT del paciente" aria-label="Copiar RUT del paciente">
                                    <img
                                      style={{
                                        width: 18,
                                        float: "inherit",
                                        marginBottom: -4,
                                      }}
                                      className="imagenBotonAccion "
                                      src={copiar}
                                      onClick={() => this.copiarRut(this.state.usu_pasaporte, true)}
                                    />
                                  </Tooltip>
                                </>
                              )}
                            </Grid>
                            <Grid className={InformacionUsuarioCSS.datosIndividuales} item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                Dirección:
                              </label>
                              <label name="labelTam" className={InformacionUsuarioCSS.labelDatosUsuario}>
                                {this.state.direccion}
                                {this.state.comunaDireccion ? ", " + this.state.comunaDireccion : ""}
                              </label>
                            </Grid>

                            <Grid className={InformacionUsuarioCSS.datosIndividuales} item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                Sexo:
                              </label>
                              <label name="labelTam" className={InformacionUsuarioCSS.labelDatosUsuario}>
                                {sexoUser}
                              </label>
                            </Grid>
                            <Grid className={InformacionUsuarioCSS.datosIndividuales} item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                Cesfam:
                              </label>
                              <label name="labelTam" className={InformacionUsuarioCSS.labelDatosUsuario}>
                                {this.state.cesfamUsuario}
                              </label>
                              {this.state.pacienteBloqueadoPorCuestionario ? (
                                <a
                                  onClick={() => this.desbloqueoCuestionarioRecoleta()}
                                  style={{
                                    backgroundColor: "#db3636",
                                    marginLeft: 5,
                                    color: "white",
                                    padding: 3,
                                    borderRadius: 5,
                                    cursor: "pointer",
                                  }}
                                >
                                  Desbloquear
                                </a>
                              ) : null}
                            </Grid>

                            <Grid className={InformacionUsuarioCSS.datosIndividuales} item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                Edad:
                              </label>
                              <label name="labelTam" className={InformacionUsuarioCSS.labelDatosUsuario}>
                                {this.state.edad}
                                {this.state.diasPrematuro ? (
                                  <LightTooltip title={"Paciente prematuro, edad corregida: " + this.state.edadPrematuro} aria-label={"Paciente prematuro, edad corregida: " + this.state.edadPrematuro}>
                                    <img
                                      style={{
                                        width: 18,
                                        float: "inherit",
                                        marginBottom: -4,
                                      }}
                                      className="imagenBotonAccion "
                                      src={icono_ver_mas}
                                    />
                                  </LightTooltip>
                                ) : (
                                  ""
                                )}
                              </label>
                            </Grid>
                            <Grid className={InformacionUsuarioCSS.datosIndividuales} item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                Sector:
                              </label>
                              <label name="labelTam" className={InformacionUsuarioCSS.labelDatosUsuario}>
                                {this.state.sector}
                              </label>
                            </Grid>
                            <Grid className={InformacionUsuarioCSS.datosIndividuales} item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                Última solicitud:
                              </label>
                              <label name="labelTam">
                                <Tooltip title="Ver la última solicitud del paciente" aria-label="Ver la última solicitud del paciente">
                                  <img
                                    style={{
                                      width: 18,
                                      float: "inherit",
                                      marginBottom: -4,
                                    }}
                                    className="imagenBotonAccion "
                                    src={icono_ver_mas}
                                    onClick={this.mostrarModalUltimaSolicitud}
                                  />
                                </Tooltip>
                              </label>
                            </Grid>
                            <Grid className={InformacionUsuarioCSS.datosIndividuales} item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                Horas ofrecidas:
                              </label>
                              <label name="labelTam">
                                {this.state.totalHorasOfrecidas}
                                <Tooltip title="Ver horas ofrecidas" aria-label="Ver horas ofrecidas">
                                  <img
                                    style={{
                                      width: 18,
                                      float: "inherit",
                                      marginBottom: -4,
                                    }}
                                    className="imagenBotonAccion "
                                    src={icono_ver_mas}
                                    onClick={this.mostrarHorasOfrecidas}
                                  />
                                </Tooltip>
                              </label>
                            </Grid>
                            <Grid className={InformacionUsuarioCSS.datosIndividuales} item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                Solicitud de hora:
                              </label>
                              <label>
                                <Tooltip title="Ver la última solicitud del paciente" aria-label="Ver la última solicitud del paciente">
                                  <img
                                    style={{
                                      width: 18,
                                      float: "inherit",
                                      marginBottom: -4,
                                    }}
                                    className="imagenBotonAccion "
                                    src={icono_ver_mas}
                                    onClick={this.mostrarModalSolicitudHoras}
                                  />
                                </Tooltip>
                              </label>
                            </Grid>
                            <Grid className={InformacionUsuarioCSS.datosIndividuales} item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                Correo:
                              </label>
                              <label name="labelTam" className={InformacionUsuarioCSS.labelDatosUsuario}>
                                {this.state.correo || this.state.modCorreo ? (
                                  <>
                                    {this.state.modCorreo ? (
                                      <>
                                        <input type="text" name="labelTam" value={this.state.correoModi} onChange={this.cambiarCorreo} placeholder="" style={{ padding: "0" }} />
                                        <Tooltip title="Actualizar correo" aria-label="Actualizar correo">
                                          <img
                                            style={{
                                              width: 18,
                                              float: "inherit",
                                              marginBottom: -4,
                                            }}
                                            onClick={this.confirmarModificarCorreo}
                                            className="imagenBotonAccion "
                                            src={icono_guardar}
                                          />
                                        </Tooltip>
                                        <Tooltip title="Cancelar" aria-label="Cancelar">
                                          <img
                                            style={{
                                              width: 18,
                                              float: "inherit",
                                              marginBottom: -4,
                                            }}
                                            onClick={this.ocultarModificarCorreo}
                                            className="imagenBotonAccion "
                                            src={icono_eliminar}
                                          />
                                        </Tooltip>
                                      </>
                                    ) : (
                                      <>
                                        {this.state.correo}
                                        <Tooltip title="Modificar correo" aria-label="Modificar correo">
                                          <img
                                            style={{
                                              width: 18,
                                              float: "inherit",
                                              marginBottom: -4,
                                            }}
                                            onClick={this.modificarCorreo}
                                            className="imagenBotonAccion "
                                            src={icono_modificar}
                                          />
                                        </Tooltip>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {"No informado"}
                                    <Tooltip title="Modificar correo" aria-label="Modificar correo">
                                      <img
                                        style={{
                                          width: 18,
                                          float: "inherit",
                                          marginBottom: -4,
                                        }}
                                        onClick={this.modificarCorreo}
                                        className="imagenBotonAccion "
                                        src={icono_modificar}
                                      />
                                    </Tooltip>
                                  </>
                                )}
                              </label>
                            </Grid>
                            <Grid className={InformacionUsuarioCSS.datosIndividuales} item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                App móvil y portal web:
                              </label>
                              <label name="labelTam" className={InformacionUsuarioCSS.labelDatosUsuario}>
                                {this.state.aplicacion ? (
                                  <a style={{ cursor: "pointer" }}>
                                    {"Activada"}
                                    <Tooltip title="Modificar contraseña" aria-label="Modificar contraseña">
                                      <img
                                        style={{
                                          width: 18,
                                          float: "inherit",
                                          marginBottom: -4,
                                        }}
                                        onClick={this.modificarClaveApp}
                                        className="imagenBotonAccion "
                                        src={icono_modificar}
                                      />
                                    </Tooltip>
                                  </a>
                                ) : (
                                  <a style={{ cursor: "pointer" }}>
                                    {"Activar"}
                                    <Tooltip title="Activar app móvil y portal web" aria-label="Activar app móvil y portal web">
                                      <img
                                        style={{
                                          width: 18,
                                          float: "inherit",
                                          marginBottom: -4,
                                        }}
                                        onClick={this.inscribirApp}
                                        className="imagenBotonAccion "
                                        src={icono_inscribir}
                                      />
                                    </Tooltip>
                                  </a>
                                )}
                              </label>
                            </Grid>
                            {this.state.herramientas.includes("H45") && (
                              <>
                                <Grid className={InformacionUsuarioCSS.datosIndividuales} item xs={12} sm={6}>
                                  <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                    N° de la Carpeta:
                                  </label>
                                  <label name="labelTam" className={InformacionUsuarioCSS.labelDatosUsuario}>
                                    {nro_carpeta}
                                  </label>
                                </Grid>
                                <Grid className={InformacionUsuarioCSS.datosIndividuales} item xs={12} sm={6}>
                                  <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                    N° de Ficha:
                                  </label>
                                  <label name="labelTam" className={InformacionUsuarioCSS.labelDatosUsuario}>
                                    {nro_ficha}
                                  </label>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </div>
                      </div>
                    </Grid>
                  </div>
                </Grid>

                <br></br>
                <br></br>
              </div>
            ) : null}
            {this.state.datosCanales ? <Telefonos tamanio={this.state.letraTamanio} usuId={this.state.usu_id} onClose={this.onClose} validar={this.VerificarNumeros}></Telefonos> : null}
            {this.state.fichaUsuario ? (
              <div className="User">
                <Grid container spacing={2} className="fichaUsuario">
                  <div id="datosFicha3">
                    <Grid item xs={2} sm={1}></Grid>
                    <Grid item xs={9}>
                      <div>
                        <img
                          style={{
                            marginLeft: "3vw",
                          }}
                          name="icon_paciente_ficha"
                          className={this.state.letraTamanio > 2 ? InformacionUsuarioCSS.imagenDatos : InformacionUsuarioCSS.imagenDatosPequeño}
                          src={icono_vista_3}
                        />
                        <div className={this.state.letraTamanio > 2 ? InformacionUsuarioCSS.tablaDatosUsuario : InformacionUsuarioCSS.tablaDatosUsuario_peque} style={this.state.letraTamanio > 2 ? { marginLeft: "10.4vw" } : { marginLeft: "8.4vw" }}>
                          <Grid container spacing={2} className="contenidoDatosUsuario">
                            <Grid className="datosIndividuales" item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                Situación:
                              </label>
                              <label name="labelTam">{situacion}</label>
                            </Grid>
                            <Grid className="datosIndividuales" item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                Programas:
                              </label>
                              <label name="labelTam">
                                {this.state.programas ? (
                                  <React.Fragment>
                                    <Tooltip title="Ver programas del paciente" aria-label="Ver programas del paciente">
                                      <img
                                        aria-describedby={id}
                                        style={{
                                          width: 18,
                                          float: "inherit",
                                          marginBottom: -4,
                                        }}
                                        className="imagenBotonAccion "
                                        src={icono_ver_mas}
                                        onClick={this.handleClick}
                                      />
                                    </Tooltip>

                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={this.state.mostrarProgramas}
                                      onClose={this.handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "center",
                                      }}
                                    >
                                      {this.state.programas.map((res, index) => (
                                        <p key={index}>{res.programa}</p>
                                      ))}
                                    </Popover>
                                  </React.Fragment>
                                ) : (
                                  "--"
                                )}
                              </label>
                            </Grid>
                            <Grid className="datosIndividuales" item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                Discapacidad:
                              </label>
                              <label name="labelTam">{this.state.nombre_discapacidad}</label>
                            </Grid>
                            {this.state.herramientas.includes("H14") ? (
                              <Grid className="datosIndividuales" item xs={12} sm={6}>
                                <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                  Ciclos:
                                </label>
                                <label name="labelTam">
                                  <Tooltip title="Ver ciclos del paciente" aria-label="Ver ciclos del paciente">
                                    <img
                                      aria-describedby={id}
                                      style={{
                                        width: 18,
                                        float: "inherit",
                                        marginBottom: -4,
                                      }}
                                      className="imagenBotonAccion "
                                      src={icono_ver_mas}
                                      onClick={this.mostrarCiclos}
                                    />
                                  </Tooltip>
                                </label>
                              </Grid>
                            ) : null}

                            <Grid className="datosIndividuales" item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                Condición:
                              </label>
                              <label name="labelTam">{this.state.nombre_condicion}</label>
                            </Grid>
                            <Grid className="datosIndividuales" item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                Tipo:
                              </label>
                              <label name="labelTam">{this.state.nombre_cronicos}</label>
                            </Grid>
                            <Grid className="datosIndividuales" item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                Estado en HoraSalud:
                              </label>
                              <a name="labelTam" style={{ cursor: "pointer" }}>
                                {estadoUser}
                                <Tooltip title={estadoUser == "Inscrito" ? "Desinscribir al paciente en HoraSalud" : "Inscribir al paciente en HoraSalud"}>
                                  <img
                                    style={{
                                      width: 18,
                                      float: "inherit",
                                      marginBottom: -4,
                                    }}
                                    onClick={this.mostrarAlertaCambiarEstado}
                                    className="imagenBotonAccion "
                                    src={icono_inscribir}
                                  />
                                </Tooltip>
                              </a>
                            </Grid>

                            <Grid className="datosIndividuales" item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                Estratificación:
                              </label>
                              <label name="labelTam">{this.state.estratificacion ? "G" + this.state.estratificacion : "--"}</label>
                            </Grid>
                            {this.state.herramientas.includes("H13") ? (
                              <Grid className="datosIndividuales" item xs={12} sm={6}>
                                <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                  Beneficios:
                                </label>
                                <Tooltip title="Ver beneficios del paciente" aria-label="Ver beneficios del paciente">
                                  <img
                                    aria-describedby={id}
                                    style={{
                                      width: 18,
                                      float: "inherit",
                                      marginBottom: -4,
                                    }}
                                    className="imagenBotonAccion "
                                    src={icono_ver_mas}
                                    onClick={this.mostrarBeneficios}
                                  />
                                </Tooltip>
                              </Grid>
                            ) : null}
                            {this.state.cesfam != 76 && (
                              <Grid className="datosIndividuales" item xs={12} sm={6}>
                                <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                  Gestiones:
                                </label>
                                <Tooltip title="Ver gestiones activas del paciente" aria-label="Ver gestiones activas del paciente">
                                  <img
                                    aria-describedby={id}
                                    style={{
                                      width: 18,
                                      float: "inherit",
                                      marginBottom: -4,
                                    }}
                                    className="imagenBotonAccion "
                                    src={icono_ver_mas}
                                    onClick={this.mostrarCasosRegistrados}
                                  />
                                </Tooltip>
                              </Grid>
                            )}
                            {this.state.secuencia_usuario && (
                              <Grid className="datosIndividuales" item xs={12} sm={6}>
                                <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                  Control Sano:
                                </label>
                                <Tooltip title="Ver tabla de control sano" aria-label="Ver tabla de control sano">
                                  <img
                                    aria-describedby={id}
                                    style={{
                                      width: 18,
                                      float: "inherit",
                                      marginBottom: -4,
                                    }}
                                    className="imagenBotonAccion "
                                    src={icono_ver_mas}
                                    onClick={this.mostrarControlSano}
                                  />
                                </Tooltip>
                              </Grid>
                            )}
                            {this.state.diasPrematuro > 0 ? (
                              <Grid className="datosIndividuales" item xs={12} sm={6}>
                                <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                  Prematuro:
                                </label>
                                <label name="labelTam">{this.state.diasPrematuro} días</label>
                              </Grid>
                            ) : null}
                            <Grid className="datosIndividuales" item xs={12} sm={6}>
                              <label name="labelTam" className={InformacionUsuarioCSS.tituloDatosUsuario}>
                                Pausado:
                              </label>
                              {this.state.usuario_bloqueado["estado"] ? (
                                <label name="labelTam">
                                  {this.state.usuario_bloqueado["fecha_termino"] ? (
                                    <span>
                                      {this.state.usuario_bloqueado["fecha_termino"]}
                                      <Tooltip title="Desbloquear paciente" aria-label="Desbloquear paciente">
                                        <img
                                          aria-describedby={id}
                                          style={{
                                            width: 18,
                                            float: "inherit",
                                            marginBottom: -4,
                                          }}
                                          className="imagenBotonAccion "
                                          src={icono_eliminar}
                                          onClick={this.mostrarAlertaDesbloquearUsuario}
                                        />
                                      </Tooltip>
                                    </span>
                                  ) : (
                                    <span>
                                      Indefinidamente{" "}
                                      <Tooltip title="Desbloquear paciente" aria-label="Desbloquear paciente">
                                        <img
                                          aria-describedby={id}
                                          style={{
                                            width: 18,
                                            float: "inherit",
                                            marginBottom: -4,
                                          }}
                                          className="imagenBotonAccion "
                                          src={icono_eliminar}
                                          onClick={this.mostrarAlertaDesbloquearUsuario}
                                        />
                                      </Tooltip>
                                    </span>
                                  )}
                                </label>
                              ) : (
                                <label name="labelTam">No</label>
                              )}
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Grid>
                  </div>
                </Grid>

                <br></br>
                <br></br>
              </div>
            ) : null}
          </div>
          <div className="middle-content ">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {this.state.datosGrupoFamiliar ? <GrupoFamiliar cambiarTamanio={this.cambiarTamanio} tamanio={this.state.letraTamanio} usuario={this.state.usu_id}></GrupoFamiliar> : null}
                {this.state.datosHistorialHoras ? <HistorialHoras usuario={this.state.usu_id}></HistorialHoras> : null}
                {this.state.datosHorasFuturas ? <HorasFuturas usuario={this.state.usu_id}></HorasFuturas> : null}
                {this.state.datosRegistroCanales ? <RegistroCanales usuario={this.state.usu_id}></RegistroCanales> : null}
                {this.state.agendarUsuario ? <AgendarUsuario ocultar={this.ocultarAgendar} usuario={this.state.usu_id}></AgendarUsuario> : null}
                {this.state.comentarios ? <Comentarios ocultar={this.ocultarComentarios} usuId={this.state.usu_id}></Comentarios> : null}
                {this.state.modalBeneficios ? <Beneficios ocultar={this.ocultarBeneficios} usuId={this.state.usu_id}></Beneficios> : null}
                {this.state.modalCiclos ? <Ciclos ocultar={this.ocultarCiclos} usuId={this.state.usu_id}></Ciclos> : null}
                {this.state.modalAgregarComentario ? <AgregarComentario ocultar={this.ocultarModalAgregarComentario} usuId={this.state.usu_id}></AgregarComentario> : null}
                {this.state.modificarUsuario ? <ModificarUsuario ocultar={this.ocultarModificar} usuario={this.state.usu_id} cliente={this.state.cliente}></ModificarUsuario> : null}

                {this.state.eliminarUsuario ? <EliminarUsuario ocultar={this.ocultarEliminar} eliminar={this.usuarioEliminado} usuario={this.state.usu_id}></EliminarUsuario> : null}
                {this.state.modalAgregarTelefono ? <AgregarTelefono ocultar={this.ocultarModalAgregarTelefono} usuId={this.state.usu_id}></AgregarTelefono> : null}
                {this.state.modalTelefonoAlerta ? <ModalTelefono showModal={this.state.modalTelefonoAlerta} handleConfirm={this.confirmarAlertaTelefono} handleCancel={this.cerrarAlertaTelefono} noPhones={this.state.sinNumeros}></ModalTelefono> : null}
                {this.state.modalHorasReservadas && <ModalHorasReservadas abrir={this.state.modalHorasReservadas} ocultar={this.ocultarModalHorasReservadasPorTipo} usuario={this.state.usu_id} tipo={this.state.tipo_agenda} diasAsistidas={this.state.tiempoBusquedaAsistidas} diasPerdidas={this.state.tiempoBusquedaPerdidas}></ModalHorasReservadas>}
                {this.state.modalAgregarFamiliar ? <AgregarFamiliar ocultar={this.ocultarModalAgregarFamiliar} usuId={this.state.usu_id}></AgregarFamiliar> : null}
                {this.state.modalUltimaSolicitud ? <UltimaSolicitud ocultar={this.ocultarModalUltimaSolicitud} usuario={this.state.usu_id}></UltimaSolicitud> : null}
                {this.state.modalHorasOfrecidas ? <HorasOfrecidas ocultar={this.ocultarHorasOfrecidas} usuario={this.state.usu_id}></HorasOfrecidas> : null}
                {this.state.modalCasosRegistrados ? <CasosRegistrados ocultar={this.ocultarCasosRegistrados} gestion={null} usuario={this.state.usu_id}></CasosRegistrados> : null}
                {this.state.modalControlSano ? <ControlSano ocultar={this.ocultarControlSano} usuario={this.state.usu_id}></ControlSano> : null}
                {this.state.inscribirApp ? <InscribirApp ocultar={this.ocultarInscribirApp} rut_usuario={this.state.rut} correo={this.state.correo}></InscribirApp> : null}
                {this.state.modificarClaveApp ? <ModificarClaveApp ocultar={this.ocultarModificarClaveApp} rut_usuario={this.state.rut} correo={this.state.correo}></ModificarClaveApp> : null}
                {this.state.modalSolicitudHoras ? <SolicitudHoras ocultar={this.ocultarModalSolicitudHoras} usuario={this.state.usu_id}></SolicitudHoras> : null}
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </>
    );
  }
}
