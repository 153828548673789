import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import ReproducirAudio from "./../../components/Helpers/ReproducirAudio.js";
import ImageFullScreen from "./ImageFullScreen.js";
import imagenPDF from "./../../assets/img/imagenPDF.png";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import moment from "moment";
import { store } from "./../../store/index";
import "./../../assets/css/chat.css";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Send from "@material-ui/icons/Send";
import { socketChat } from "../../service/socket";

export default class Chat extends Component {
  constructor(props) {
    super(props);
    this.finalChat = React.createRef();

    this.state = {
      beneficios: [],
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      textoChat: "",
      chat: [],
      estado_chat: 0,
      idChat: props.chat,
      open: true,
      url: "",
      ultimaFecha: "",
      archivo: "",
      modalImagen: false,
      participante: null,
      nombreUsp: store.getState().nombreusp,
      usuario: props.usuario,
      nombreUsuario: props.nombre_usuario,

      audio: null,
      paused: false,
      porcentajeCarga: 0,
      duracion: 0,
      tiempoActual: 0,
      escribiendo: "",
      participantesVerificados: false,
    };
  }

  salir = () => {
    socketChat.off("message");
    socketChat.off("typing");
    socketChat.off("stoptyping");
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  traeParticipante = () => {
    axios
      .get(global.config.modulos.chat + "existe_participante/", {
        headers: authHeader(),
        params: { usuario: this.state.uspId, tipo: 2, chat: this.state.idChat },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ participante: res.data.datos.ctp_id });
          //this.enviarMensaje(res.data.datos.ctp_id);
        } else {
          axios
            .post(
              global.config.modulos.chat + "agregar_participante/",
              {
                chat: this.state.idChat,
                usuario: this.state.uspId,
                nombreUsuario: this.state.nombreUsp,
                tipoUsuario: 2,
              },
              {
                headers: authHeader(),
              }
            )
            .then((res) => {
              if (res.data.estado) {
                this.setState({ participante: res.data.ctp_id });
                //this.enviarMensaje(res.data.ctp_id);
              }
            });
        }
      });
  };

  desactivarChat = () => {
    axios
      .patch(
        global.config.modulos.chat + "modificar_chat/",
        {
          id: this.state.idChat,
          cht_estado: 0,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          this.setState({ estado_chat: 0 });
        }
      });
  };

  activarChat = () => {
    axios
      .patch(
        global.config.modulos.chat + "modificar_chat/",
        {
          id: this.state.idChat,
          cht_estado: 1,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          this.setState({ estado_chat: 1 });
        }
      });
  };

  verificaUsuarioParticipante = () => {
    axios
      .get(global.config.modulos.chat + "existe_participante/", {
        headers: authHeader(),
        params: {
          usuario: this.state.usuario,
          tipo: 1,
          chat: this.state.idChat,
        },
      })
      .then((res) => {
        if (!res.data.estado) {
          axios
            .post(
              global.config.modulos.chat + "agregar_participante/",
              {
                chat: this.state.idChat,
                usuario: this.state.usuario,
                nombreUsuario: this.state.nombreUsuario,
                tipoUsuario: 1,
              },
              {
                headers: authHeader(),
              }
            )
            .then((res) => {
              if (res.data.estado) {
              }
            });
        }
      });
  };

  mostrarModalImagen = (url) => {
    this.setState({ modalImagen: true, url: url });
  };

  ocultarModalImagen = () => {
    this.setState({ modalImagen: false });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    if (value.length == 0) {
      socketChat.emit("stoptyping", {
        quienEnvia: this.state.participante,
        nombreUsuario: this.state.nombreUsp,
        chat: this.state.idChat,
      });
    } else {
      socketChat.emit("typing", {
        quienEnvia: this.state.participante,
        nombreUsuario: this.state.nombreUsp,
        chat: this.state.idChat,
      });
    }
  };

  cambiarValoresArchivo = (e) => {
    //this.setState({ archivo: e.target.files[0] });

    const data = new FormData();
    data.append("archivoSubir", e[0]);
    data.append("nombreUsuario", this.state.nombreUsp);
    data.append("chat", this.state.idChat);
    data.append("tipoMensaje", 2);
    data.append("quienEnvia", this.state.participante);

    axios
      .post(global.config.modulos.chatSocket + "carga_archivos/", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {});
  };

  traeMensajes = () => {
    axios
      .get(global.config.modulos.chat + "mensajes_chat/", {
        headers: authHeader(),
        params: { chat: this.state.idChat, posicion: 0 },
      })
      .then((res) => {
        if (res.data.estado) {
          let textoChat = [];
          let fecha = "";
          let mensaje = "";

          if (res.data.datos.length > 0) {
            this.setState({
              estado_chat: res.data.datos[0]["estado_chat"],
            });
          }

          res.data.datos.map((res) => {
            let tiempo = res.ctm_fecha_registro.split("T");
            let paddingMensaje = "8px 20px 8px 20px";
            let tamanioMensaje = "80%";
            let tipoMensaje = "";
            let divParticipante = "";

            if (res.ctm_participante == this.state.participante) {
              tipoMensaje = "interno";
            } else {
              tipoMensaje = "externo";
              divParticipante = <p className="nombreUsuario">{res.nombre_participante}</p>;
            }

            if (fecha == "") {
              this.setState({ ultimaFecha: tiempo[0] });
            }

            if (fecha != tiempo[0] && fecha != "") {
              textoChat.unshift(
                <div className="chat-div-badge-fecha">
                  <label className="chat-badge-fecha">{fecha}</label>
                </div>
              );
            }
            fecha = tiempo[0];

            let arrImagenes = ["image/png", "image/jpg", "image/jpeg"];
            if (arrImagenes.includes(res.ctm_formato_archivo)) {
              mensaje = <img onClick={() => this.mostrarModalImagen(res.ctm_archivo)} className="chat-img-mensaje" src={res.ctm_archivo} />;
              paddingMensaje = "2px 2px 8px 2px";
            } else if (res.ctm_formato_archivo == "application/pdf") {
              mensaje = <img onClick={() => this.descargarArchivo(res.ctm_archivo)} className="chat-pdf-mensaje" src={imagenPDF} />;
              paddingMensaje = "2px 2px 8px 2px";
              tamanioMensaje = "50%";
            } else if (res.ctm_formato_archivo == "audio/wav" || res.ctm_formato_archivo == "audio/mp3") {
              mensaje = <ReproducirAudio url={res.ctm_archivo}></ReproducirAudio>;
              paddingMensaje = "2px 2px 8px 2px";
            } else {
              mensaje = <p>{res.ctm_mensaje}</p>;
              paddingMensaje = "8px 20px 8px 20px";
            }

            textoChat.unshift(
              <div className="contenedor-mensaje">
                <div className={"mensaje " + tipoMensaje} style={{ padding: paddingMensaje, width: tamanioMensaje }}>
                  {divParticipante}
                  {mensaje}
                  <p className="chat-hora">
                    {tiempo[1].substring(0, 5)}
                    <label className="chat-mark">✓✓</label>
                  </p>
                </div>
              </div>
            );
          });

          if (this.state.ultimaFecha == "") {
            this.setState({ ultimaFecha: fecha });
          }

          if (fecha != "") {
            textoChat.unshift(
              <div className="chat-div-badge-fecha">
                <label className="chat-badge-fecha">{fecha}</label>
              </div>
            );
          }

          this.setState({ chat: textoChat });
        }
      });
  };

  agregarMensaje = () => {
    if (!this.state.participantesVerificados) {
      this.verificaUsuarioParticipante();

      this.setState({ participantesVerificados: true });
    }
    this.enviarMensaje(this.state.participante);
  };

  enviarMensaje = (participante) => {
    let textoCh = this.state.textoChat;
    socketChat.emit("message", {
      quienEnvia: participante.toString(),
      nombreUsuario: this.state.nombreUsp,
      chat: this.state.idChat.toString(),
      mensaje: textoCh,
      tipoMensaje: 1,
    });
    socketChat.emit("stoptyping", {
      quienEnvia: participante,
      nombreUsuario: this.state.nombreUsp,
      chat: this.state.idChat,
    });
  };

  agregarMensajeAlChat = (ultimaFecha, textoChat, posicionChat, tiempo = null, nombreUsuario = null, urlArchivo = null, urlAudio = null, nombreArchivo = null, formatoArchivo = null) => {
    let hora = moment().format("HH:mm");
    if (tiempo != null) {
      hora = tiempo;
    }
    let fecha = moment().format("YYYY-MM-DD");

    let badgeFecha = "";
    if (fecha != ultimaFecha) {
      badgeFecha = (
        <div className="chat-div-badge-fecha">
          <label className="chat-badge-fecha">{fecha}</label>
        </div>
      );
      this.setState({ ultimaFecha: fecha });
    }

    let paddingMensaje = "8px 20px 8px 20px";
    let tamanioMensaje = "80%";
    let mensaje = "";

    let arrImagenes = ["image/png", "image/jpg", "image/jpeg"];
    if (arrImagenes.includes(formatoArchivo)) {
      mensaje = <img onClick={() => this.mostrarModalImagen(urlArchivo)} className="chat-img-mensaje" src={urlArchivo} />;
      paddingMensaje = "2px 2px 8px 2px";
    } else if (formatoArchivo == "application/pdf") {
      mensaje = <img onClick={() => this.descargarArchivo(urlArchivo)} className="chat-pdf-mensaje" src={imagenPDF} />;
      paddingMensaje = "2px 2px 8px 2px";
      tamanioMensaje = "50%";
    } else if (formatoArchivo == "audio/wav" || formatoArchivo == "audio/mp3") {
      mensaje = <ReproducirAudio url={urlArchivo}></ReproducirAudio>;
      paddingMensaje = "8px 20px 8px 20px";
    } else {
      mensaje = <p>{textoChat}</p>;
      paddingMensaje = "8px 20px 8px 20px";
    }

    let divParticipante = "";
    if (posicionChat == "externo") {
      divParticipante = <p className="nombreUsuario">{nombreUsuario}</p>;
    }

    let nuevoTexto = (
      <React.Fragment>
        {badgeFecha}
        <div className="contenedor-mensaje">
          <div className={"mensaje " + posicionChat} style={{ padding: paddingMensaje, width: tamanioMensaje }}>
            {divParticipante}
            <p>{mensaje}</p>
            <p className="chat-hora">
              {hora}
              <label className="chat-mark">✓✓</label>
            </p>
          </div>
        </div>
      </React.Fragment>
    );

    this.setState({
      chat: [...this.state.chat, nuevoTexto],
      textoChat: "",
    });

    this.scrollToBottom();
  };

  descargarArchivo = (url) => {
    window.open(url, "_blank");
  };

  scrollToBottom = () => {
    if (this.finalChat) {
      setTimeout(() => {
        this.finalChat.current.scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
  };

  componentDidMount = () => {
    this.scrollToBottom();
    let promiseParticipante = new Promise((resolve, reject) => {
      this.traeParticipante();
      setTimeout(function () {
        resolve("ok");
      }, 250);
    });
    promiseParticipante.then((resultado) => {
      this.traeMensajes();
    });

    socketChat.on("message", (data) => {
      let posicionMensaje = "";
      if (data.quienEnvia == this.state.participante) {
        posicionMensaje = "interno";
      } else {
        posicionMensaje = "externo";
      }

      this.agregarMensajeAlChat(this.state.ultimaFecha, data.mensaje, posicionMensaje, data.tiempo, data.nombreUsuario, data.urlArchivo, data.urlAudio, data.nombreArchivo, data.formatoArchivo);
    });

    socketChat.on("typing", (data) => {
      this.setState({ escribiendo: "Escribiendo..." });
    });

    socketChat.on("stoptyping", (data) => {
      this.setState({ escribiendo: "" });
    });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="chat">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
              <img className="marca-agua"></img>
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <div className="top-chat">
                    <p className="chat-nombre-usuario">{this.state.nombreUsuario}</p>
                    <p className="chat-estado-usuario">{this.state.escribiendo}</p>
                  </div>

                  <div className="contenedor-chat">
                    {this.state.chat}
                    <div ref={this.finalChat} />
                  </div>
                  <div className="bottom-chat">
                    {this.state.estado_chat == 1 ? (
                      <React.Fragment>
                        <div className="enviar-mensaje">
                          <textarea name="textoChat" value={this.state.textoChat} onChange={this.cambiarValores} className="chat-input" placeholder="Escribe tu mensaje"></textarea>
                          <label for="attach">
                            <AttachFileIcon className="chat-file" />
                          </label>
                          <input accept=".pdf,.jpeg,.jpg,.png,.wav,.mp3" type="file" id="attach" name="archivo" style={{ display: "none", visibility: "none" }} onChange={(e) => this.cambiarValoresArchivo(e.target.files)} />
                        </div>
                        <div className="enviar-audio">{this.state.textoChat == "" ? <Send className="chat-mic" style={{ color: "#858585", cursor: "default" }} /> : <Send onClick={this.agregarMensaje} className="chat-mic" />}</div>
                      </React.Fragment>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <label>Chat desactivado</label>
                      </div>
                    )}
                  </div>
                </GridItem>

                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={this.salir} style={{ marginLeft: 20 }}>
                    Cerrar
                  </Button>
                  {this.state.estado_chat == 1 ? (
                    <Button id="" className="botonDialog " onClick={this.desactivarChat} style={{ marginLeft: 20, backgroundColor: "#bf4b3c" }}>
                      Desactivar chat
                    </Button>
                  ) : (
                    <Button id="" className="botonDialog " onClick={this.activarChat} style={{ marginLeft: 20, backgroundColor: "#3cbf71" }}>
                      Activar chat
                    </Button>
                  )}
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
        {this.state.modalImagen ? <ImageFullScreen ocultar={this.ocultarModalImagen} url={this.state.url}></ImageFullScreen> : null}
      </Dialog>
    );
  }
}
