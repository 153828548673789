import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";

import { store } from "../../store/index";
import { connect } from "react-redux";
import chileanRut from "chilean-rut";

// core components
import Listaespera from "../../assets/img/lista_espera/lista de ingreso.svg";
import ListaesperaText from "../../assets/img/lista_espera/lista de ingreso con texto.svg";
import enTratamiento from "../../assets/img/lista_espera/en tratamiento.svg";
import enTratamientotext from "../../assets/img/lista_espera/en tratamiento texto.svg";
import enAlta from "../../assets/img/lista_espera/alta.svg";
import enAltaText from "../../assets/img/lista_espera/alta con texto.svg";
import enObservacion from "../../assets/img/lista_espera/observaciones.svg";
import enObservacionText from "../../assets/img/lista_espera/observaciones con texto.svg";
import citadoTTo from "../../assets/img/lista_espera/citado a tto.svg";
import citadoTTonText from "../../assets/img/lista_espera/citado a tto texto.svg";
import Tooltip from "@material-ui/core/Tooltip";
import Buttons from "react-multi-date-picker/components/button";
import { Input } from "@material-ui/core";
import ListaDeEspera from "./ListaDeEspera";
import ListaDeAltas from "./ListaDeAltas";
import ListaDeTratamiento from "./ListaDeTratamiento";
import AgregarUsuarioTratamiento from "../../components/ListaDeEspera/AgregarUsuarioTratamiento";
import ListaCitadosTratamiento from "./ListaCitadosTratamiento";
import ListaDeObservados from "./ListaDeObservados";
import CargarTratamientos from "./CargaTratamientos";
import NotificationManager from "react-notifications/lib/NotificationManager";

import signo from "./../../assets/img/signo.svg";

class FiltroListaEspera extends Component {
  constructor() {
    super();
    this.state = {
      mostrarModalNuevoPeriodo: false,
      datos: [],
      Periodos: [],
      periodo: null,
      validos: null,
      mostrarespera: false,
      mostrartratamiento: false,
      mostraraltas: false,
      cesfam: store.getState().cesfam,
      mostrarModalAgregarTratamiento: false,
      mostrarobservados: false,
      mostrarcargaexcels: false,
      texto_filtro: null,
      estado_espera: false,
      estado_altas: false,
      estado_tratamiento: false,
      estado_observados: false,
      mostrarcitados: false,
      estado_citados: false,
      busqueda: null,
    };
  }

  componentDidMount() {
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Pacientes en lista de espera <span title='Pacientes que actualmente estan en lista de espera, tratamiento o alta en HoraSalud.'><img src=" + signo + "></img></span>";
  };

  abrirModalListaDeEspera = () => {
    this.setState({
      mostrarespera: true,
      mostrarcitados: false,
      mostrartratamiento: false,
      mostraraltas: false,
      mostrarobservados: false,
      mostrarcargaexcels: false,
    });
  };

  hideModalListaDeEspera = () => {
    this.setState({
      mostrarespera: false,
    });
  };
  abrirModalListaDeAltas = () => {
    this.setState({
      mostraraltas: true,
      mostrarcitados: false,
      mostrarespera: false,
      mostrartratamiento: false,
      mostrarobservados: false,
      mostrarcargaexcels: false,
    });
  };
  hideModalListaDeAltas = () => {
    this.setState({
      mostraraltas: false,
    });
  };
  abrirModalListaEnTratamiento = () => {
    this.setState({
      mostrartratamiento: true,
      mostrarcitados: false,
      mostrarespera: false,
      mostraraltas: false,
      mostrarobservados: false,
      mostrarcargaexcels: false,
    });
  };
  hideModalListaEnTratamiento = () => {
    this.setState({
      mostrartratamiento: false,
    });
  };
  abrirModalListaCitados = () => {
    this.setState({
      mostrarcitados: true,
      mostrartratamiento: false,
      mostrarespera: false,
      mostraraltas: false,
      mostrarobservados: false,
      mostrarcargaexcels: false,
    });
  };
  hideModalListaCitados = () => {
    this.setState({
      mostrarcitados: false,
    });
  };

  abrirModalListaObservados = () => {
    this.setState({
      mostrarobservados: true,
      mostrartratamiento: false,
      mostrarespera: false,
      mostraraltas: false,
      mostrarcargaexcels: false,
      mostrarcitados: false,
    });
  };

  hideModalListaObservados = () => {
    this.setState({
      mostrarobservados: false,
    });
  };

  abrirModalCargaExcels = () => {
    this.setState({
      mostrarcargaexcels: true,
      mostrartratamiento: false,
      mostrarespera: false,
      mostraraltas: false,
      mostrarobservados: false,
      mostrarcitados: false,
    });
  };

  hideModalCargaExcels = () => {
    this.setState({
      mostrarcargaexcels: false,
    });
  };

  abrirModalAgregarTratamiento = () => {
    this.setState({
      mostrarModalAgregarTratamiento: true,
    });
  };

  hideModalAgregarTratamiento = () => {
    if (this.state.mostrarespera == true) {
      this.setState({
        mostrarespera: false,
      });
    }
    this.setState({
      mostrarModalAgregarTratamiento: false,
      mostrarespera: true,
      mostrarcargaexcels: false,
      mostrartratamiento: false,
      mostraraltas: false,
      mostrarobservados: false,
      mostrarcitados: false,
    });
  };
  filtroTexto = (e) => {
    const texto_filtro = e.target.value;
    this.setState({ texto_filtro: texto_filtro });
  };
  buscedaGlobal = () => {
    if (this.state.texto_filtro == null) {
      NotificationManager.error("Debe de ingresar un rut o nombre para buscar");
      return;
    }
    if (chileanRut.validate(this.state.texto_filtro) == false) {
      NotificationManager.error("Debe de ingresar un rut valido");
      return;
    }

    axios
      .get(global.config.modulos.listaEspera + "buscar_usuario_global/", {
        headers: authHeader(),
        params: {
          texto_filtro: this.state.texto_filtro,
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            estado_espera: res.data.estado_espera,
            estado_altas: res.data.estado_alta,
            estado_tratamiento: res.data.estado_tratamiento,
            estado_observados: res.data.estado_observado,
            estado_citados: res.data.estado_citado,
            busqueda: this.state.texto_filtro,
            texto_filtro: "",
          });
          this.aplicarBusqueda(this.state.estado_espera, this.state.estado_altas, this.state.estado_tratamiento, this.state.estado_observados, this.state.estado_citados);
        } else {
          NotificationManager.error("Paciente no se encuentra en ninguna lista de espera", "Error", 3000);
        }
      });
  };
  mensajeResultadoExcel = () => {
    NotificationManager.success("Se Terminaron de cargar el excel de Carga en lista de espera ", "Excel Cargado", 3000);
  };
  aplicarBusqueda(estado_espera, estado_altas, estado_tratamiento, estado_observado, estado_citados) {
    if (estado_espera) {
      if (this.state.mostrarespera == true) {
        this.setState({
          mostrarespera: false,
        });
      }
      this.setState(
        {
          mostrarespera: true,
          mostrartratamiento: false,
          mostraraltas: false,
          mostrarobservados: false,
          mostrarcargaexcels: false,
          texto_filtro: null,
          mostrarcitados: false,
        },
        () => {
          this.limpiarBusqueda();
        }
      );
    } else if (estado_altas) {
      if (this.state.mostraraltas == true) {
        this.setState({
          mostraraltas: false,
        });
      }
      this.setState(
        {
          mostraraltas: true,
          mostrarespera: false,
          mostrartratamiento: false,
          mostrarobservados: false,
          mostrarcargaexcels: false,
          texto_filtro: null,
          mostrarcitados: false,
        },
        () => {
          this.limpiarBusqueda();
        }
      );
    } else if (estado_tratamiento) {
      if (this.state.mostrartratamiento == true) {
        this.setState({
          mostrartratamiento: false,
        });
      }
      this.setState(
        {
          mostrartratamiento: true,
          mostrarespera: false,
          mostraraltas: false,
          mostrarobservados: false,
          mostrarcargaexcels: false,
          texto_filtro: null,
          mostrarcitados: false,
        },
        () => {
          this.limpiarBusqueda();
        }
      );
    } else if (estado_observado) {
      if (this.state.mostrarobservados == true) {
        this.setState({
          mostrarobservados: false,
        });
      }
      this.setState(
        {
          mostrarobservados: true,
          mostrartratamiento: false,
          mostrarespera: false,
          mostraraltas: false,
          mostrarcargaexcels: false,
          texto_filtro: null,
          mostrarcitados: false,
        },
        () => {
          this.limpiarBusqueda();
        }
      );
    } else if (estado_citados) {
      if (this.state.mostrarcitados == true) {
        this.setState({
          mostrarcitados: false,
        });
      }
      this.setState(
        {
          mostrarcitados: true,
          mostrartratamiento: false,
          mostrarespera: false,
          mostraraltas: false,
          mostrarobservados: false,
          mostrarcargaexcels: false,
          texto_filtro: null,
        },
        () => {
          this.limpiarBusqueda();
        }
      );
    } else {
      NotificationManager.error("Paciente no se encuentra ingresado en ninguna lista", "Error", 3000);
      this.limpiarBusqueda();
    }
  }

  limpiarBusqueda = () => {
    this.setState({
      busqueda: null,
    });
  };

  render() {
    return (
      <div className="middle-area" style={{ width: "85%" }}>
        <div style={{ display: "flex", marginBottom: "1%", marginTop: "1%" }}>
          {this.state.mostrarespera ? (
            <Tooltip title="Pacientes que esperan la atención." aria-label="Pacientes que esperan la atención.">
              <img className="imagenBotonAccionMenu" src={ListaesperaText} onClick={() => this.abrirModalListaDeEspera()} style={{ width: "70px", height: "70px" }} />
            </Tooltip>
          ) : (
            <Tooltip title="Lista de ingreso" aria-label="Pacientes que esperan la atención.">
              <img className="imagenBotonAccionMenu" src={Listaespera} onClick={() => this.abrirModalListaDeEspera()} style={{ width: "50px", height: "50px" }} />
            </Tooltip>
          )}
          {this.state.mostrarcitados ? (
            <Tooltip title="Pacientes que tienen una hora reservada para su atención." aria-label="Pacientes que tienen una hora reservada para su atención.">
              <img className="imagenBotonAccionMenu" src={citadoTTonText} onClick={() => this.abrirModalListaCitados()} style={{ width: "70px", height: "70px" }} />
            </Tooltip>
          ) : (
            <Tooltip title="Citados a tto" aria-label="Pacientes que tienen una hora reservada para su atención.">
              <img className="imagenBotonAccionMenu" src={citadoTTo} onClick={() => this.abrirModalListaCitados()} style={{ width: "50px", height: "50px" }} />
            </Tooltip>
          )}

          {this.state.mostrartratamiento ? (
            <Tooltip title="Pacientes que asistieron a su atención." aria-label="Pacientes que asistieron a su atención.">
              <img className="imagenBotonAccionMenu" src={enTratamientotext} onClick={() => this.abrirModalListaEnTratamiento()} style={{ width: "70px", height: "70px" }} />
            </Tooltip>
          ) : (
            <Tooltip title="En tratamiento" aria-label="Pacientes que asistieron a su atención.">
              <img className="imagenBotonAccionMenu" src={enTratamiento} onClick={() => this.abrirModalListaEnTratamiento()} style={{ width: "50px", height: "50px" }} />
            </Tooltip>
          )}
          {this.state.mostraraltas ? (
            <Tooltip title="Pacientes que han sido dados de alta." aria-label="Pacientes que han sido dados de alta.">
              <img className="imagenBotonAccionMenu" src={enAltaText} onClick={() => this.abrirModalListaDeAltas()} style={{ width: "70px", height: "70px" }} />
            </Tooltip>
          ) : (
            <Tooltip title="Lista de altas" aria-label="Pacientes que han sido dados de alta.">
              <img className="imagenBotonAccionMenu" src={enAlta} onClick={() => this.abrirModalListaDeAltas()} style={{ width: "50px", height: "50px" }} />
            </Tooltip>
          )}
          {this.state.mostrarobservados ? (
            <Tooltip title="Pacientes que han sido descartados por distintos motivos." aria-label="Pacientes que han sido descartados por distintos motivos.">
              <img className="imagenBotonAccionMenu" src={enObservacionText} onClick={() => this.abrirModalListaObservados()} style={{ width: "70px", height: "70px" }} />
            </Tooltip>
          ) : (
            <Tooltip title="Lista de otros casos" aria-label="Pacientes que han sido descartados por distintos motivos.">
              <img className="imagenBotonAccionMenu" src={enObservacion} onClick={() => this.abrirModalListaObservados()} style={{ width: "50px", height: "50px" }} />
            </Tooltip>
          )}
          <Input
            type="text"
            name="texto_filtro"
            value={this.state.texto_filtro}
            onChange={this.filtroTexto}
            style={{
              padding: 6,
              border: "0px solid black",
              borderRadius: 5,
              marginTop: "2%",
              float: "left",
              marginLeft: "2%",
              height: "35px",
              width: "20%",
            }}
          />

          <Buttons
            onClick={this.buscedaGlobal}
            className={"btnOpcion fondoNaranjo"}
            style={{
              cursor: "pointer",
              marginLeft: "3%",
              marginTop: "2%",
              fontSize: "12px",
              float: "left",
              border: "0",
              paddingBottom: "6px",
              textAlign: "center",
              height: "35px",
            }}
          >
            Buscar
          </Buttons>

          <Buttons
            onClick={() => {
              this.abrirModalAgregarTratamiento();
            }}
            className={"btnOpcion fondoNaranjo"}
            style={{
              paddingLeft: "2%",
              paddingRight: "2%",
              cursor: "pointer",
              marginTop: "1%",
              fontSize: "12px",
              float: "left",
              width: "190px",
              marginLeft: "10%",
              height: "45px",
              border: "0",
              textAlign: "center",
            }}
          >
            Ingresar paciente a lista de espera
          </Buttons>
          {this.state.mostrarcargaexcels ? (
            <Buttons
              onClick={() => {
                this.abrirModalCargaExcels();
              }}
              style={{
                paddingLeft: "2%",
                paddingRight: "2%",
                cursor: "pointer",
                marginTop: "1%",
                fontSize: "12px",
                float: "left",
                width: "150px",
                backgroundColor: "#fcb65c",
                border: "0",
                textAlign: "center",
                height: "45px",
              }}
            >
              Cargar excel
            </Buttons>
          ) : (
            <Buttons
              onClick={() => {
                this.abrirModalCargaExcels();
              }}
              className={"btnOpcion fondoNaranjo"}
              style={{
                paddingLeft: "2%",
                paddingRight: "2%",
                cursor: "pointer",
                marginTop: "1%",
                fontSize: "12px",
                float: "left",
                width: "150px",
                border: "0",
                textAlign: "center",
                height: "45px",
              }}
            >
              Cargar excel
            </Buttons>
          )}
        </div>

        {this.state.mostrarespera ? <ListaDeEspera ocultar={this.hideModalListaDeEspera} busqueda={this.state.busqueda}></ListaDeEspera> : null}
        {this.state.mostraraltas ? <ListaDeAltas ocultar={this.hideModalListaDeAltas} busqueda={this.state.busqueda}></ListaDeAltas> : null}
        {this.state.mostrartratamiento ? <ListaDeTratamiento ocultar={this.hideModalListaEnTratamiento} busqueda={this.state.busqueda}></ListaDeTratamiento> : null}
        {this.state.mostrarobservados ? <ListaDeObservados ocultar={this.hideModalAgregarTratamiento} busqueda={this.state.busqueda}></ListaDeObservados> : null}
        {this.state.mostrarModalAgregarTratamiento ? <AgregarUsuarioTratamiento ocultar={this.hideModalAgregarTratamiento}></AgregarUsuarioTratamiento> : null}
        {this.state.mostrarcargaexcels ? <CargarTratamientos ocultar={this.hideModalCargaExcels}></CargarTratamientos> : null}
        {this.state.mostrarcitados ? <ListaCitadosTratamiento ocultar={this.hideModalListaCitados} busqueda={this.state.busqueda}></ListaCitadosTratamiento> : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(FiltroListaEspera);
