import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";

import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Grid, List, ListItem } from "@material-ui/core";

// estilos
//import "../../assets/css/agregar.css";

export default class BeneficioInscribirPorSesiones extends Component {
  constructor(props) {
    super(props);

    this.state = {
      beneficios: [],
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      usuId: props.usuario,
      beneficio: props.beneficio,
      formErrors: {},
      profesionales: [],
      profesional: null,
      atencionExterna: null,
      open: true,
      nombreBeneficio: props.nombreBeneficio,
      especialidades: props.especialidades,
      sesiones: 0,
      vecesxsemana: 0,
      frecuencia: 0,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  componentDidMount() {}

  inscribir = () => {
    if (this.state.sesiones == 0) {
      return NotificationManager.warning("Indica la cantidad de sesiones");
    }
    if (this.state.frecuencia == 0) {
      return NotificationManager.warning("Selecciona la frecuencia");
    }
    if (this.state.frecuencia == 3 && this.state.vecesxsemana == 0) {
      return NotificationManager.warning("Indica las veces por semana");
    }
    let arrEspecialidades = this.state.especialidades.split(",");

    axios
      .post(
        global.config.modulos.beneficios + "inscribir/",
        {
          fechaInicio: null,
          fechaExtra: null,
          fechaTermino: null,
          usuario: this.state.usuId,
          beneficio: this.state.beneficio,
          usuarioPanel: this.state.uspId,
          profesional: this.state.profesional,
          atencionExterna: this.state.atencionExterna,
          especialidad: parseInt(arrEspecialidades[0]),
          sesiones: this.state.sesiones,
          frecuencia: this.state.frecuencia,
          vecesxsemana: this.state.vecesxsemana,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Inscrito con éxito");
        } else {
          NotificationManager.error("Ha ocurrido un error al inscribir");
        }
        this.salir();
      });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="beneficioUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
              <img alt="" class="marca-agua"></img>
              <GridItem xs={12}>
                <h2>Inscribir beneficio {this.state.nombreBeneficio}</h2>
              </GridItem>
              <GridItem xs={3} style={{ marginTop: 20 }}>
                <InputLabel>Cantidad de sesiones</InputLabel>
                <input type="number" name="sesiones" min={0} value={this.state.sesiones} onChange={this.cambiarValores} placeholder="" className={"inputText fechaBeneficio"} />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
                <Grid container spacing={3}>
                  <GridItem xs={3} style={{ marginTop: 20 }}>
                    <InputLabel>Frecuencia</InputLabel>
                    <select name="frecuencia" value={this.state.frecuencia} onChange={this.cambiarValores} className={"inputText fechaBeneficio"}>
                      <option value="0" disabled>
                        Seleccione
                      </option>
                      <option value={1}>{"Todos los días"}</option>
                      <option value={2}>{"⁠Día por medio"}</option>
                      <option value={3}>{"Veces a la semana"}</option>
                    </select>
                  </GridItem>

                  {this.state.frecuencia == 3 && (
                    <GridItem xs={3} style={{ marginTop: 20 }}>
                      <InputLabel>Veces por semana </InputLabel>
                      <input type="number" name="vecesxsemana" min={0} value={this.state.vecesxsemana} onChange={this.cambiarValores} placeholder="" className={"inputText fechaBeneficio"} />
                    </GridItem>
                  )}
                </Grid>
              </GridItem>

              <GridItem xs={12} style={{ marginTop: 30 }}>
                <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={this.salir} style={{ marginLeft: 20 }}>
                  Cerrar
                </Button>
                <Button id="modalEliminarBtnAceptar" className="botonDialog fondoVerde" onClick={this.inscribir}>
                  Inscribir
                </Button>
              </GridItem>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
