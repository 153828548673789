import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { store } from "./../../store";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert";

import axios from "axios";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class Requerimiento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,

      idDemanda: props.idDemanda,
      telefonos: props.telefonos,
      requerimiento: props.requerimiento,
      idRequerimiento: props.idRequerimiento,
      motivoLibre: props.observacion_libre,
      fechaDerivacion: props.fechaDerivacion,

      like: true,
      open: true,
      opciones: [],
      formErrors: {},
    };
  }

  componentWillMount() {
    this.traerRequerimientos();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  validaciones() {
    const { idRequerimiento } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (idRequerimiento == "" || idRequerimiento == null) {
      formIsValid = false;
      formErrors["requerimientoErr"] = "Selecciona el requerimiento.";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  guardarRequerimiento = () => {
    if (this.validaciones()) {
      axios
        .patch(
          global.config.modulos.demanda + "guardar_requerimiento/",
          {
            id: this.state.idDemanda,
            motivo_libre: this.state.motivoLibre,
            requerimiento: this.state.idRequerimiento,
            usp: this.state.uspId,
          },
          {
            headers: authHeader(),
          }
        )
        .then((res) => {
          NotificationManager.success("Requerimiento guardado");
          this.salir();
        });
    }
  };

  traerRequerimientos = () => {
    axios
      .get(global.config.modulos.demanda + "traer_requerimientos/", {
        headers: authHeader(),
        params: { cesfam: this.state.cesfam },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ opciones: res.data.datos });
        }
      });
  };

  handleRadioChange = (event) => {
    this.setState({ like: event.target.value });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    if (event.target.name == "idRequerimiento") {
      let requerimiento = this.state.opciones.find((e) => e.gdr_id == event.target.value);

      if (requerimiento.gdr_mensaje != null && requerimiento.gdr_mensaje != "") {
        confirmAlert({
          title: "",
          message: requerimiento.gdr_mensaje,
          buttons: [
            {
              label: "Aceptar",
              className: "btnAceptar",
            },
          ],
        });
      }

      this.setState({ motivoLibre: requerimiento.gdr_mensaje });
    }
  };

  render() {
    const { requerimientoErr } = this.state.formErrors;

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth id="finalizarDemanda">
        <DialogContent>
          <div>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <h2>Requerimiento</h2>
              </Grid>

              {this.state.fechaDerivacion == null ? (
                <>
                  <Grid item xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
                    <FormControl fullWidth>
                      <InputLabel id="label-sector">Requerimiento</InputLabel>
                      <Select labelId="label-sector" id="idRequerimiento" name="idRequerimiento" value={this.state.idRequerimiento || ""} fullWidth onChange={this.handleChange}>
                        {this.state.opciones.map((e) => (
                          <MenuItem key={e.gdr_id} value={e.gdr_id}>
                            {e.gdr_requerimiento}
                          </MenuItem>
                        ))}
                      </Select>
                      {requerimientoErr && <div style={{ color: "red", paddingBottom: 10 }}>{requerimientoErr}</div>}
                    </FormControl>

                    <br />
                    <br />
                    <InputLabel id="label-sector" style={{ color: "#000" }}>
                      {this.state.motivoLibre}
                    </InputLabel>
                    <br />
                    <br />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Button style={{ float: "right", marginLeft: 10 }} onClick={this.salir} className="botonSimple fondoRojo">
                      Cerrar
                    </Button>
                    <Button onClick={this.guardarRequerimiento} className="botonSimple fondoVerde" style={{ float: "right" }}>
                      Guardar
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} sm={12} md={12}>
                  <br />
                  <InputLabel id="label-sector" style={{ color: "#000" }}>
                    {this.state.requerimiento}
                  </InputLabel>
                  <br />
                  <br />
                  <InputLabel id="label-sector" style={{ color: "#000" }}>
                    {this.state.motivoLibre}
                  </InputLabel>
                  <br />
                  <br />
                  <Button style={{ float: "right", marginLeft: 10 }} onClick={this.salir} className="botonSimple fondoRojo">
                    Cerrar
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
