import React, { Component, useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import axios from "axios";
/* eslint-disable no-use-before-define */

import { store } from "store/index";
import { Grid, List, ListItem, MenuItem, Select } from "@material-ui/core";

import InputLabel from "@material-ui/core/InputLabel";
import GridContainer from "components/grid/GridContainer.js";
import GridItem from "components/grid/GridItem.js";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { confirmAlert } from "react-confirm-alert";
import HelpIcon from "@material-ui/icons/Help";

// CSS
import "react-confirm-alert/src/react-confirm-alert.css";
import crearTotem from "assets/css/crearTotem.module.css";

export default class Apariencia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      abrirInfo: true,
      mostrarClaveGmail: true,
      mostrarPin: true,
      totem: store.getState().totem,
      acceso: "",
    };
  }

  componentDidMount() {}

  mostrarAlerta = (mensaje) => {
    confirmAlert({
      title: mensaje,
      message: "",
      buttons: [
        {
          label: "Aceptar",
        },
      ],
    });
  };

  traerUrlAccesoTotem = () => {
    axios
      .get(global.config.modulos.admin_totem + "traer_url_acceso_totem/", {
        headers: authHeader(),
        params: {
          totem: this.state.totem,
        },
      })
      .then((response) => {
        if (response.data.estado) {
          this.setState({ acceso: response.data.acceso });
          window.open(response.data.acceso);
        } else {
          this.mostrarAlerta(response.data.mensaje_error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  abrirModalInformacion = () => {
    this.setState({ abrirInfo: true });
  };

  salirModalInformacion = () => {
    this.setState({ abrirInfo: false });
  };

  mostrarClave = () => {
    this.setState({ mostrarClaveGmail: !this.state.mostrarClaveGmail });
  };
  mostrarPin = () => {
    this.setState({ mostrarPin: !this.state.mostrarPin });
  };
  render() {
    return (
      <>
        <GridContainer style={{ width: "100%", marginTop: "10px" }}>
          <GridItem xs={9} sm={9} md={9}>
            <Grid item xs={12} sm={12}>
              <Grid item xs={12} sm={12}>
                <Grid item xs={6} sm={6}>
                  <a
                    className={this.state.abrirInfo ? "btnAcciones botonAccionActivo" : "btnAcciones"}
                    onClick={this.abrirModalInformacion}
                    style={{
                      width: "33%",
                      height: "4.5rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Credenciales de <br /> acceso
                  </a>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <a onClick={this.traerUrlAccesoTotem} className={crearTotem.accesoTotem}>
                    Conexión remoto
                  </a>
                </Grid>
              </Grid>
            </Grid>
            {this.state.abrirInfo && (
              <>
                <Grid item xs={12} sm={12} style={{ marginTop: "7%" }}>
                  <Grid container spacing={3} style={{ justifyContent: "center" }}>
                    <Grid item xs={4} sm={4}>
                      <InputLabel className={crearTotem.textosTitulos}>Correo eléctronico </InputLabel>
                      <input
                        type="text"
                        className={crearTotem.input}
                        id="tamano_logo"
                        name="tamano_logo"
                        defaultValue="hsconexiontotem@gmail.com" //hsconexiontotem
                        disabled
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <InputLabel className={crearTotem.textosTitulos}>Clave correo eléctronico </InputLabel>
                      <input
                        className={crearTotem.input}
                        id="standard-adornment-password"
                        type={this.state.mostrarClaveGmail ? "text" : "password"}
                        value={"Ys3m1HS1"} //Ys3m1HS1
                        disabled
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={this.mostrarClave}>
                              {this.state.mostrarClaveGmail ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <InputLabel className={crearTotem.textosTitulos}>PIN acceso </InputLabel>
                      <input
                        id="standard-adornment-password"
                        type={this.state.mostrarPin ? "text" : "password"}
                        value={"951066424"}
                        className={crearTotem.input}
                        disabled
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={this.mostrarPin}>
                              {this.state.mostrarPin ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <br></br>
                <br></br>
              </>
            )}
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
