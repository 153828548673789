import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";

import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import { store } from "./../../store/index";

import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";

// estilos
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class MotivoBloqueoFamiliar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      motivo: "",

      cesfam: store.getState().cesfam,
      uspId: store.getState().usp,

      usuId: props.usuario,
      familiar: props.usuarioFamiliar,

      canal: 5,

      formErrors: {},
      open: true,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  validaciones() {
    const { motivo } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (motivo == "" || motivo == 0) {
      formIsValid = false;
      formErrors["motivoErr"] = "Ingresa el motivo.";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  enviarFormulario = (e) => {
    if (this.validaciones()) {
      let apiUrl = global.config.modulos.grupo_familiar + "bloquear_familiar/";
      const options = {
        method: "POST",
        data: {
          usuario: this.state.usuId,
          familiar: this.state.familiar,
          funcionario: this.state.uspId,
          motivo: this.state.motivo,
        },
        headers: authHeader(),
      };
      axios(apiUrl, options).then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Familiar bloqueado con éxito");
          this.salir();
        }
      });
    }
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { motivoErr } = this.state.formErrors;

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="anularHora">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
              <img className="marca-agua"></img>
              <GridContainer className="agendar-container">
                <GridItem xs={12}>{<h2>¿Porqué estás bloqueado este rut para el grupo familiar?</h2>}</GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <TextField name="motivo" multiline rows={6} value={this.state.motivo} onChange={this.cambiarValores} style={{ border: "1px solid black", borderRadius: "5px", padding: "7px", width: "100%" }} />
                  {motivoErr && <div style={{ color: "red", paddingBottom: 10 }}>{motivoErr}</div>}
                </GridItem>

                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <Button id="modalEliminarBtnCancelar" className="botonDialog fondoNaranjo" onClick={this.salir} style={{ marginLeft: 20 }}>
                    Cancelar
                  </Button>
                  <Button className="botonDialog" onClick={this.enviarFormulario} style={{ marginLeft: 20, backgroundColor: "#D34A4A" }}>
                    Bloquear
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
