import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";

import GridItem from "./../../components/grid/GridItem.js";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import { store } from "./../../store/index";

import MaterialTable from "material-table";

// estilos
import "../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class DetalleHoraBloqueada extends Component {
  constructor(props) {
    super(props);

    this.state = {
      motivo: "",
      uspid: store.getState().usp,
      agenda: props.agenda,
      intentosContacto: [],
      open: true,
    };
  }

  componentDidMount() {
    this.traerDetalle();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  traerDetalle = () => {
    axios
      .get(global.config.modulos.bloqueos + "intentos_bloqueo/", {
        headers: authHeader(),
        params: {
          agenda: this.state.agenda,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ intentosContacto: res.data.datos });
        }
      });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="detalleHorasBloqueadas">
          <DialogContent>
            <h2>Detalle contacto</h2>
            <GridItem xs={12} sm={12} md={12} id="tablaBloqueos">
              {this.state.intentosContacto.length > 0 ? (
                <MaterialTable
                  title=""
                  columns={[
                    {
                      title: "Fecha contacto",
                      field: "lbd_fecha_envio",
                      defaultSort: "desc",
                      render: (rowData) => (
                        <React.Fragment>
                          <p>{rowData.lbd_fecha_envio.replace("T", " ")}</p>
                        </React.Fragment>
                      ),
                    },
                    {
                      title: "Teléfono",
                      field: "lbd_telefono",
                      render: (rowData) => (
                        <React.Fragment>
                          <p>{rowData.lbd_telefono ? rowData.lbd_telefono : "--"}</p>
                        </React.Fragment>
                      ),
                    },
                    { title: "Intento", field: "intento" },
                    { title: "Canal", field: "canal" },
                    {
                      title: "Detalle",
                      field: "detalle",
                      render: (rowData) => (
                        <React.Fragment>
                          <p style={{ backgroundColor: rowData.color_detalle, color: "#fff", borderRadius: "5px" }}>{rowData.detalle}</p>
                        </React.Fragment>
                      ),
                    },
                    {
                      title: "Estado",
                      field: "estado",
                      render: (rowData) => (
                        <React.Fragment>
                          <p style={{ backgroundColor: rowData.color_estado, color: "#fff", borderRadius: "5px" }}>{rowData.estado}</p>
                        </React.Fragment>
                      ),
                    },
                  ]}
                  actions={[]}
                  data={this.state.intentosContacto}
                  options={{
                    actionsColumnIndex: -1,
                    search: false,
                    sorting: true,
                  }}
                  localization={{
                    header: {
                      actions: "Acciones",
                    },
                    pagination: {
                      labelDisplayedRows: "{from}-{to} de {count}",
                      labelRowsSelect: "filas",
                      firstTooltip: "primera",
                      previousTooltip: "anterior",
                      nextTooltip: "siguiente",
                      lastTooltip: "última",
                    },
                    toolbar: {
                      nRowsSelected: "{0} fila(s) seleccionadas",
                      searchTooltip: "Buscar",
                      searchPlaceholder: "Buscar",
                    },
                    body: {
                      emptyDataSourceMessage: "No hay comentarios registrados",
                      filterRow: {
                        filterTooltip: "Buscar",
                      },
                    },
                  }}
                />
              ) : null}
            </GridItem>
          </DialogContent>
          <DialogActions>
            <Button className="botonDialog fondoRojo" onClick={this.salir}>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
