import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import "react-confirm-alert/src/react-confirm-alert.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import "./../../assets/css/usuarios.css";
import ReactLoading from "react-loading";
import sin_internet from "../../assets/img/sin_internet.gif";

// estilos

export default class SinInternet extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      type: props.type,
      color: props.color,
    };
  }

  render() {
    return (
      <div>
        <Dialog open={this.state.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="dialog-cargando">
          <DialogContent>
            <img src={sin_internet} style={{ width: "100%" }} />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
