import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";
import "moment/locale/es";
import { connect } from "react-redux";
import ModalNSP from "../../components/Reportes/ModalNSP";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import MaterialTable from "material-table";
import ver_mas from "../../assets/img/gestion/ver_mas.svg";
import reportes from "./../../assets/css/reportes.css";
import chileanRut from "chilean-rut";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHeader from "../../components/Table/TableHeader";
import Paginacion from "./../../components/Table/Pagination.js";
import { TableCell } from "@material-ui/core";

class ListaNSP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuariosNSP: [],
      cesfam: store.getState().cesfam,
      showModalUsuarioNSP: false,
      filas: 10,
      total: 0,
      pagina: 0,
      startDate: props.startDate,
      endDate: props.endDate,
      age_usuario: null,
      fecha_inicial: null,
      fecha_final: null,
      especialidad_id: null,
      nombreTitulo: [
        { nombre: "Rut", filtro: "usu_rut" },
        { nombre: "Nombre", filtro: "nombre_usuario" },
        { nombre: "Edad", filtro: "fecha_nacimiento" },
        { nombre: "Sector", filtro: "nombre_sector" },
        { nombre: "N° horas perdidas", filtro: "total" },
        { nombre: "Ver horas perdidas", filtro: "nulo" },
      ],
      ordenarPrioridad: null,
    };
  }

  componentDidMount() {
    this.fetchNSP(this.state.pagina, this.state.filas, this.state.ordenarPrioridad);
  }

  handleFilasPagination = (filas) => {
    this.setState({
      filas: filas,
    });
    this.fetchNSP(this.state.pagina, filas, this.state.ordenarPrioridad);
  };

  handleFiltradorCampo = (ordenarPrioridad) => {
    this.setState({
      ordenarPrioridad: ordenarPrioridad,
      //orden: ordens,
    });
    this.fetchNSP(this.state.pagina, this.state.filas, ordenarPrioridad);
  };

  handlePagination = (pagina) => {
    this.setState({
      pagina: pagina,
    });
    this.fetchNSP(pagina, this.state.filas, this.state.ordenarPrioridad);
  };

  fetchNSP = async (pagina, fila, filtrador) => {
    const apiURL = global.config.modulos.reportes + "listado_NSP/";
    //+ this.state.cesfam;

    axios
      .get(apiURL, {
        headers: authHeader(),
        params: {
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          cesfam: this.state.cesfam,
          pagina: pagina,
          fila: fila,
          ordenarPrioridad: filtrador,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            usuariosNSP: res.data.datos,
            total: res.data.total,
            pagina: res.data.pagina,
            ordenarPrioridad: res.data.prioridad,
          });
        }
      });
  };

  showModalUsuarioNSP = (age_usuario, startDate, endDate, age_especialidad) => {
    this.setState({ showModalUsuarioNSP: true, age_usuario: age_usuario, fecha_inicial: startDate, fecha_final: endDate, especialidad_id: age_especialidad });
  };

  hideModalListadoNSP = () => {
    this.setState({ showModalUsuarioNSP: false });
  };

  render() {
    console.log(this.state.usuariosNSP);
    return (
      <div className="middle-content">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} id="tablaBloqueos" style={{ marginTop: "10px" }}>
            <GridItem xs={12}></GridItem>
            <TableContainer>
              <Table className="tablaGeneralGestion bordeTabla" aria-label="simple table">
                <TableHeader nombreTitulo={this.state.nombreTitulo} datocampo={this.handleFiltradorCampo} />
                <TableBody>
                  {this.state.usuariosNSP.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{chileanRut.format(row.usu_rut, true)}</TableCell>
                      <TableCell align="center">{row.nombre_usuario}</TableCell>
                      <TableCell align="center">{row.edad}</TableCell>
                      <TableCell align="center">{row.nombre_sector}</TableCell>
                      <TableCell align="center">{row.total}</TableCell>
                      <TableCell align="center">
                        <React.Fragment>
                          <div>
                            <img class="btnAccionesAnulacion" src={ver_mas} aria-label="Ver horas perdidas" title="Ver horas perdidas" onClick={() => this.showModalUsuarioNSP(row.age_usuario, this.state.startDate, this.state.endDate, row.age_especialidad)}></img>
                          </div>
                        </React.Fragment>{" "}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Paginacion total={this.state.total} paginacion={this.handlePagination} filas_paginacion={this.handleFilasPagination} />
            </TableContainer>
          </GridItem>
        </GridContainer>

        {this.state.showModalUsuarioNSP ? <ModalNSP ocultar={this.hideModalListadoNSP} id_usuario={this.state.age_usuario} startDate={this.state.startDate} endDate={this.state.endDate} especialidad_id={this.state.age_especialidad}></ModalNSP> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ListaNSP);
