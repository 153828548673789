import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components

import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import { Grid, List, ListItem } from "@material-ui/core";

// CSS
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";

// custom components
import ListadoDifusiones from "./../../views/Difusiones/ListadoDifusiones";
import ListadoEncuestas from "./ListadoEncuestas.js";
import { store } from "./../../store/index";
import AgregarDifusion from "./AgregarDifusion.js";
import AgregarEncuesta from "./AgregarEncuesta.js";

import lista_dif from "./../../assets/img/difusion/lista_difusion.svg";
import lista_dif_select from "./../../assets/img/difusion/lista_difusion_texto.svg";
import dif_pasadas from "./../../assets/img/difusion/difusiones_pasadas.svg";
import dif_pasadas_select from "./../../assets/img/difusion/difusiones_pasadas_texto.svg";
import dif_eliminadas from "./../../assets/img/difusion/difusiones_eliminadas.svg";
import dif_eliminadas_select from "./../../assets/img/difusion/difusiones_eliminadas_texto.svg";
import encuestas from "./../../assets/img/difusion/encuestas.svg";
import encuestas_select from "./../../assets/img/difusion/encuestas_texto.svg";

import Tooltip from "@material-ui/core/Tooltip";

import signo from "./../../assets/img/signo.svg";

export default class Difusiones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usu_id: props.usu_id,

      modalDifusion: false,
      modalEncuestas: false,
      listadifusiones: false,
      difusionesPasadas: false,
      difusionesEliminadas: false,
      listaEncuestas: false,
      refresh: false,

      cesfam: store.getState().cesfam,
      uspId: store.getState().usp,
      herramientas: store.getState().herramientas,
    };

    this.nuevaDifusion = this.nuevaDifusion.bind(this);
    this.cerrarNuevaDifusion = this.cerrarNuevaDifusion.bind(this);
    this.nuevaEncuesta = this.nuevaEncuesta.bind(this);
    this.cerrarNuevaEncuesta = this.cerrarNuevaEncuesta.bind(this);
  }

  componentDidMount() {
    this.cambioOpciones(1);
    this.titulo();
  }

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Difusión <span title=''><img src=" + signo + "></img></span>";
  };

  nuevaDifusion = () => {
    this.setState({ modalDifusion: true });
  };

  cerrarNuevaDifusion = () => {
    this.setState({ modalDifusion: false }, () => {
      this.refresh();
    });
  };

  nuevaEncuesta = () => {
    this.setState({ modalEncuestas: true });
  };

  cerrarNuevaEncuesta = () => {
    this.setState({ modalEncuestas: false }, () => {
      this.refresh();
    });
  };

  refresh = () => {
    this.setState({ refresh: !this.state.refresh });
  };

  activarVista(nombre) {
    this.setState({
      listadifusiones: false,
      difusionesEliminadas: false,
      listaEncuestas: false,
      difusionesPasadas: false,
      [nombre]: true,
    });
  }

  seleccionarMenu = () => {
    document.getElementById("listaDifusiones").src = lista_dif;
    document.getElementById("difusionesPasadas").src = dif_pasadas;
    document.getElementById("difusionesEliminadas").src = dif_eliminadas;
    document.getElementById("encuestas").src = encuestas;
    document.getElementById("listaDifusiones").classList.remove("menuSeleccionado");
    document.getElementById("difusionesPasadas").classList.remove("menuSeleccionado");

    document.getElementById("difusionesEliminadas").classList.remove("menuSeleccionado");
    document.getElementById("encuestas").classList.remove("menuSeleccionado");
  };

  cambioOpciones = (tipo) => {
    this.seleccionarMenu();

    if (tipo == 1) {
      document.getElementById("listaDifusiones").classList.add("menuSeleccionado");
      document.getElementById("listaDifusiones").src = lista_dif_select;
      this.activarVista("listadifusiones");
    } else if (tipo == 2) {
      document.getElementById("difusionesPasadas").classList.add("menuSeleccionado");
      document.getElementById("difusionesPasadas").src = dif_pasadas_select;
      this.activarVista("difusionesPasadas");
    } else if (tipo == 3) {
      document.getElementById("difusionesEliminadas").classList.add("menuSeleccionado");
      document.getElementById("difusionesEliminadas").src = dif_eliminadas_select;
      this.activarVista("difusionesEliminadas");
    } else if (tipo == 4) {
      document.getElementById("encuestas").classList.add("menuSeleccionado");
      document.getElementById("encuestas").src = encuestas_select;
      this.activarVista("listaEncuestas");
    }
  };

  render() {
    return (
      <div>
        <div className="middle-area" style={{ width: "85%" }}>
          <div>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
                  <div style={{ marginBottom: 20 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <React.Fragment>
                          <Tooltip title="Difusión" aria-label="Lista de difusión">
                            <div className="divImageUser">
                              <img id="listaDifusiones" className="imagenUsuario  centerImage menuSeleccionado" src={lista_dif} onClick={() => this.cambioOpciones(1)} />
                            </div>
                          </Tooltip>
                          <React.Fragment>
                            <Tooltip title="Difusiones Pasadas" aria-label="Difusiones Pasadas">
                              <div className="divImageUser">
                                <img id="difusionesPasadas" className="imagenUsuario  centerImage" src={dif_pasadas} onClick={() => this.cambioOpciones(2)} />
                              </div>
                            </Tooltip>
                            <Tooltip title="Difusiones Eliminadas" aria-label="Difusiones Eliminadas">
                              <div className="divImageUser">
                                <img id="difusionesEliminadas" className="imagenUsuario  centerImage" src={dif_eliminadas} onClick={() => this.cambioOpciones(3)} />
                              </div>
                            </Tooltip>

                            <Tooltip title="Encuestas" aria-label="Encuestas">
                              <div className="divImageUser">
                                <img id="encuestas" className="imagenUsuario  centerImage" src={encuestas} onClick={() => this.cambioOpciones(4)} />
                              </div>
                            </Tooltip>
                          </React.Fragment>
                        </React.Fragment>
                        {this.state.listadifusiones ? (
                          <React.Fragment>
                            <Button variant="contained" className="botonesAlineados botonAgregarUsuario fondoVerde" onClick={() => this.nuevaDifusion()}>
                              Agregar difusion
                            </Button>
                          </React.Fragment>
                        ) : null}
                        {this.state.listaEncuestas ? (
                          <React.Fragment>
                            <Button variant="contained" className="botonesAlineados botonAgregarUsuario fondoVerde" onClick={() => this.nuevaEncuesta()}>
                              Agregar encuesta
                            </Button>
                          </React.Fragment>
                        ) : null}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </React.Fragment>
          </div>
          <div className="middle-content ">
            {this.state.listadifusiones ? <ListadoDifusiones opcion={0} refresh={this.refresh} /> : null}
            {this.state.difusionesPasadas ? <ListadoDifusiones opcion={2} refresh={this.refresh} /> : null}
            {this.state.difusionesEliminadas ? <ListadoDifusiones opcion={1} refresh={this.refresh} /> : null}
            {this.state.listaEncuestas ? <ListadoEncuestas refresh={this.refresh} /> : null}
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}></GridItem>
            </GridContainer>
            {this.state.modalDifusion ? <AgregarDifusion ocultar={this.cerrarNuevaDifusion} editar={false} clon={false} dif_edit={null} /> : null}
            {this.state.modalEncuestas ? <AgregarEncuesta ocultar={this.cerrarNuevaEncuesta} /> : null}
          </div>
        </div>
      </div>
    );
  }
}
