import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";

import ModalTelefonos from "./ModalTelefonosGF";
import ModalHorasFuturas from "./ModalHorasFuturasGF";
import ModalAgendar from "./ModalAgendarHoraGF";
import ModalModificar from "./GrupoFamiliarModificar";

import conyuge from "assets/img/usuarios/conyuge.svg";
import abuelo from "assets/img/usuarios/abuelo.svg";
import hermano from "assets/img/usuarios/hermano.svg";
import hijo from "assets/img/usuarios/hijo.svg";
import nieto from "assets/img/usuarios/nieto.svg";
import padre from "assets/img/usuarios/padres.svg";
import madre from "assets/img/usuarios/madre.svg";
import primo from "assets/img/usuarios/primo.svg";
import sobrino from "assets/img/usuarios/sobrino.svg";
import tio from "assets/img/usuarios/tio.svg";
import vecino from "assets/img/usuarios/vecino.svg";
import otro from "assets/img/usuarios/icono_vista_1.svg";

import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert"; // Import
import { store } from "./../../store/index";

import vista_2 from "assets/img/usuarios/vista_2.svg";
import vista_6 from "assets/img/usuarios/vista_6.svg";
import Popover from "@material-ui/core/Popover";
import copiar from "assets/img/usuarios/copiar.svg";
import icono_modificar from "assets/img/usuarios/icono_modificar.svg";
import icono_reservar from "assets/img/usuarios/icono_reservar.svg";
import icono_ver_mas from "assets/img/usuarios/icono_ver_mas.svg";
import icono_bloquear from "assets/img/usuarios/icono_bloquear.svg";
import icono_desbloquear from "assets/img/usuarios/icono_desbloquear.svg";
import icono_eliminar from "assets/img/usuarios/icono_eliminar.svg";

// estilos
//import "assets/css/agregar.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import InformacionUsuarioCSS from "assets/css/usuarios.module.css";
import MotivoBloqueoFamiliar from "./MotivoBloqueoFamiliar";

export default class GrupoFamiliar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      parentescos: [],
      GFamiliar: [],
      gfaid: "",
      parentesco: "",
      rutFamiliar: "",
      nombreFamiliar: "",
      idFamiliar: null,
      canal: 5,
      existe: 0,
      formErrors: {},
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      usuId: props.usuario,

      modalAgendar: false,
      modalTelefonos: false,
      modalHorasFuturas: false,
      motivoBloqueoFamiliar: false,

      programas: [],
      mostrarProgramas: null,

      tamanio: props.tamanio,
    };
  }

  componentDidMount() {
    this.traerParentescos();
    this.traerGrupoFamiliar();
  }
  componentDidUpdate() {
    if (this.props.tamanio != this.state.tamanio) {
      this.setState(
        {
          tamanio: this.props.tamanio,
        },
        () => {
          this.cambiarTamanio(this.state.tamanio);
        }
      );
    }
  }

  cambiarTamanio = (tipo) => {
    this.removerClasses();
    const allElements = document.querySelectorAll("[name='labelTam']");

    allElements.forEach((element) => {
      if (tipo == "1") {
        element.classList.add(InformacionUsuarioCSS.label_11);
      } else if (tipo == "2") {
        element.classList.add(InformacionUsuarioCSS.label_13);
      } else if (tipo == "3") {
        element.classList.add(InformacionUsuarioCSS.label_16);
      } else if (tipo == "4") {
        element.classList.add(InformacionUsuarioCSS.label_20);
      }
    });

    axios
      .patch(
        global.config.modulos.usuariospanel + "cambio_tamanio_letra/",
        {
          usuarioPanel: store.getState().usp,
          tipo_letra: tipo,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        this.setState({ letraTamanio: tipo });
      });
  };

  removerClasses = () => {
    const allElements = document.querySelectorAll("[name='labelTam']");

    allElements.forEach((element) => {
      element.classList.remove(InformacionUsuarioCSS.label_11, InformacionUsuarioCSS.label_13, InformacionUsuarioCSS.label_16, InformacionUsuarioCSS.label_20);
    });
  };

  handleClick = (event) => {
    this.setState({ mostrarProgramas: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ mostrarProgramas: null });
  };

  copiarRut = (rut) => {
    navigator.clipboard.writeText(rut);
    NotificationManager.info("Rut copiado");
  };

  mostrarModalAgendar = (idFamiliar, parentesco, rutFamiliar, nombreFamiliar) => {
    this.setState({
      modalAgendar: true,
      idFamiliar: idFamiliar,
      parentesco: parentesco,
      rutFamiliar: rutFamiliar,
      nombreFamiliar: nombreFamiliar,
    });
  };

  ocultarModalAgendar = () => {
    this.setState({ modalAgendar: false });
  };

  mostrarModalTelefonos = (idFamiliar, parentesco, rutFamiliar, nombreFamiliar) => {
    this.setState({
      modalTelefonos: true,
      idFamiliar: idFamiliar,
      parentesco: parentesco,
      rutFamiliar: rutFamiliar,
      nombreFamiliar: nombreFamiliar,
    });
  };

  ocultarModalTelefonos = () => {
    this.setState({ modalTelefonos: false });
  };

  mostrarModalHorasFuturas = (idFamiliar, parentesco, rutFamiliar, nombreFamiliar) => {
    this.setState({
      modalHorasFuturas: true,
      idFamiliar: idFamiliar,
      parentesco: parentesco,
      rutFamiliar: rutFamiliar,
      nombreFamiliar: nombreFamiliar,
    });
  };

  ocultarModalHorasFuturas = () => {
    this.setState({ modalHorasFuturas: false });
  };

  mostrarModalModificar = (gfaid, parentesco, rutFamiliar, usuId) => {
    this.setState({
      modalModificar: true,
      gfaid: gfaid,
      parentesco: parentesco,
      rutFamiliar: rutFamiliar,
      usuId: usuId,
    });
  };

  ocultarModalModificar = () => {
    this.setState({ modalModificar: false });
  };

  ocultarModalModificarActualizado = () => {
    this.setState({ modalModificar: false });
    this.traerGrupoFamiliar();
  };

  mostrarAlertaEliminarFamiliar(gfaid, gfausuarioparentesco) {
    this.setState({
      gfaid: gfaid,
      idFamiliar: gfausuarioparentesco,
    });

    confirmAlert({
      title: "",
      message: "¿Deseas eliminar a este paciente del grupo familiar?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => this.eliminarFamiliar(),
          className: "btnAceptar",
        },
      ],
    });
  }

  traerParentescos() {
    let apiUrl = global.config.modulos.usuarios + "parentescos/";
    axios(apiUrl, {
      headers: authHeader(),
    }).then((res) => {
      this.setState({
        parentescos: res.data.datos,
      });
    });
  }

  traerGrupoFamiliar() {
    let apiUrl = global.config.modulos.grupo_familiar + "por_usuario_ficha/" + this.state.usuId;
    axios(apiUrl, {
      headers: authHeader(),
    }).then((res) => {
      console.log(res.data.datos);
      this.setState(
        {
          GFamiliar: res.data.datos,
        },
        () => {
          this.cambiarTamanio(this.state.tamanio);
        }
      );
    });
  }

  eliminarFamiliar() {
    var data = {
      id_familiar: this.state.idFamiliar,
      usu_id: this.state.usuId,
      usp_id: this.state.uspId,
    };
    let apiUrl = global.config.modulos.grupo_familiar + "familiar_por_id/" + this.state.gfaid;
    const options = {
      method: "DELETE",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      NotificationManager.success("Familiar eliminado del grupo");
      this.traerGrupoFamiliar();
    });
  }

  confirmarDesbloquearFamiliar(familiar) {
    confirmAlert({
      title: "",
      message: "¿Deseas desbloquear a este paciente del grupo familiar?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => this.desbloquearFamiliar(familiar),
          className: "btnAceptar",
        },
      ],
    });
  }

  desbloquearFamiliar(familiar) {
    let apiUrl = global.config.modulos.grupo_familiar + "desbloquear_familiar/";
    const options = {
      method: "POST",
      data: {
        usuario: this.state.usuId,
        familiar: familiar,
        funcionario: this.state.uspId,
      },
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      if (res.data.estado) {
        NotificationManager.success("Familiar desbloqueado con éxito");
        this.traerGrupoFamiliar();
      }
    });
  }

  confirmarBloquearFamiliar(familiar) {
    this.setState({
      idFamiliar: familiar,
    });

    confirmAlert({
      title: "",
      message: "¿Deseas bloquear a este paciente del grupo familiar?",
      buttons: [
        {
          label: "Cancelar",
          className: "btnCancelar",
        },
        {
          label: "Aceptar",
          onClick: () => this.bloquearFamiliar(),
          className: "btnAceptar",
        },
      ],
    });
  }

  bloquearFamiliar = () => {
    this.setState({ motivoBloqueoFamiliar: true });
  };

  ocultarMotivoBloqueoFamiliar = () => {
    this.setState({ motivoBloqueoFamiliar: false });
    this.traerGrupoFamiliar();
  };

  render() {
    const { GFamiliar } = this.state;

    const lista_parentescos = {
      1: abuelo,
      2: conyuge,
      3: hermano,
      4: hijo,
      5: nieto,
      6: madre,
      7: primo,
      8: sobrino,
      9: tio,
      10: vecino,
      11: padre,
      12: otro,
    };

    const open = Boolean(this.state.mostrarProgramas);
    const id = open ? "simple-popover" : undefined;

    return (
      <div className="grupoFamiliarUsuario" style={{ marginLeft: "-10px", marginBottom: 180 }}>
        <div style={{ width: "100%", float: "left", marginTop: "25px" }}>
          <Grid container spacing={2} className="fichaUsuario scroll-container">
            {GFamiliar.length > 0 ? (
              <React.Fragment>
                {GFamiliar.map((grupo, index) => (
                  <>
                    {this.state.tamanio > 2 ? (
                      <Grid item xs={9}>
                        <div
                          style={{
                            width: "100%",
                            float: "left",
                            marginTop: "25px",
                          }}
                        >
                          <img className={InformacionUsuarioCSS.imagenDatos_GrupoFamiliar} style={{ marginLeft: "2.3vw", width: "6.5vw" }} src={lista_parentescos[grupo.gfa_parentesco]} />
                          <div className={InformacionUsuarioCSS.tablaGrupoFamiliar}>
                            <React.Fragment>
                              <Grid container spacing={2} className="contenidoDatosUsuario">
                                <React.Fragment key={index}>
                                  <Grid className="datosIndividuales" item xs={12}>
                                    <label name="labelTam" className="tituloDatosUsuario">
                                      Parentesco:
                                    </label>
                                    <label name="labelTam">
                                      {grupo.parentesco}
                                      <img
                                        style={{
                                          width: 18,
                                          float: "inherit",
                                          marginBottom: -4,
                                        }}
                                        className="imagenBotonAccion "
                                        src={icono_modificar}
                                        onClick={() => this.mostrarModalModificar(grupo.gfa_id, grupo.gfa_parentesco, grupo.rutFamiliar, grupo.gfa_usuario)}
                                      />
                                    </label>
                                  </Grid>
                                  <Grid className="datosIndividuales" item xs={12}>
                                    <label name="labelTam" className="tituloDatosUsuario">
                                      Rut:
                                    </label>
                                    <label name="labelTam">
                                      {grupo.rutFamiliar}{" "}
                                      <img
                                        style={{
                                          width: 18,
                                          float: "inherit",
                                          marginBottom: -4,
                                        }}
                                        className="imagenBotonAccion "
                                        src={copiar}
                                        onClick={() => this.copiarRut(grupo.rutFamiliar)}
                                      />
                                    </label>
                                  </Grid>

                                  <Grid className="datosIndividuales" item xs={12}>
                                    <label name="labelTam" className="tituloDatosUsuario">
                                      Nombre:
                                    </label>
                                    <label name="labelTam">{grupo.nombreUsuario}</label>
                                  </Grid>
                                  <Grid className="datosIndividuales" item xs={12}>
                                    <label name="labelTam" className="tituloDatosUsuario">
                                      Cesfam:
                                    </label>
                                    <label name="labelTam">{grupo.nombreCesfamFamiliar}</label>
                                  </Grid>
                                  <Grid className="datosIndividuales" item xs={12}>
                                    <label name="labelTam" className="tituloDatosUsuario">
                                      Programas:
                                    </label>
                                    {grupo.programas ? (
                                      <label name="labelTam">
                                        <Tooltip title="Ver programas del paciente" aria-label="Ver programas del paciente">
                                          <img
                                            aria-describedby={id}
                                            style={{
                                              width: 18,
                                              float: "inherit",
                                              marginBottom: -4,
                                            }}
                                            className={InformacionUsuarioCSS.icono_grande}
                                            src={icono_ver_mas}
                                            onClick={this.handleClick}
                                          />
                                        </Tooltip>

                                        <Popover
                                          id={id}
                                          open={open}
                                          anchorEl={this.state.mostrarProgramas}
                                          onClose={this.handleClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "center",
                                          }}
                                        >
                                          {grupo.programas.map((res, index) => (
                                            <p key={index}>{res.programa}</p>
                                          ))}
                                        </Popover>
                                      </label>
                                    ) : (
                                      <label name="labelTam">--</label>
                                    )}
                                  </Grid>
                                  <Grid className="datosIndividuales" item xs={12} style={{ marginRight: 20 }}>
                                    {grupo.gfa_estado == 1 ? (
                                      <label name="labelTam" className="tituloDatosUsuario">
                                        <Tooltip title="Eliminar familiar" aria-label="Eliminar familiar">
                                          <img className={InformacionUsuarioCSS.icono_grande} src={icono_eliminar} onClick={() => this.mostrarAlertaEliminarFamiliar(grupo.gfa_id, grupo.gfa_usuario_parentesco)} />
                                        </Tooltip>

                                        <Tooltip title="Ver las horas futuras del familiar" aria-label="Ver las horas futuras del familiar">
                                          <img className={InformacionUsuarioCSS.icono_grande} src={vista_6} onClick={() => this.mostrarModalHorasFuturas(grupo.gfa_usuario_parentesco, grupo.parentesco, grupo.rutFamiliar, grupo.nombreUsuario)} />
                                        </Tooltip>

                                        <Tooltip title="Ver los registros de canales del familiar" aria-label="Ver los registros de canales del familiar">
                                          <img src={vista_2} className={InformacionUsuarioCSS.icono_grande} onClick={() => this.mostrarModalTelefonos(grupo.gfa_usuario_parentesco, grupo.parentesco, grupo.rutFamiliar, grupo.nombreUsuario)} />
                                        </Tooltip>

                                        {grupo.cesfamFamiliar == this.state.cesfam ? (
                                          <Tooltip title="Reservar una hora para el familiar" aria-label="Reservar una hora para el familiar">
                                            <img className={InformacionUsuarioCSS.icono_grande} src={icono_reservar} onClick={() => this.mostrarModalAgendar(grupo.gfa_usuario_parentesco, grupo.parentesco, grupo.rutFamiliar, grupo.nombreUsuario)} />
                                          </Tooltip>
                                        ) : null}

                                        <Tooltip title="Bloquear familiar" aria-label="Bloquear familiar">
                                          <img className={InformacionUsuarioCSS.icono_grande} src={icono_bloquear} onClick={() => this.confirmarBloquearFamiliar(grupo.gfa_usuario_parentesco)} />
                                        </Tooltip>
                                      </label>
                                    ) : (
                                      <label name="labelTam" className="tituloDatosUsuario">
                                        <Tooltip title="Eliminar familiar" aria-label="Eliminar familiar">
                                          <img className={InformacionUsuarioCSS.icono_grande} src={icono_eliminar} onClick={() => this.mostrarAlertaEliminarFamiliar(grupo.gfa_id, grupo.gfa_usuario_parentesco)} />
                                        </Tooltip>

                                        <Tooltip title="Desbloquear familiar" aria-label="Desbloquear familiar">
                                          <img className={InformacionUsuarioCSS.icono_grande} src={icono_desbloquear} onClick={() => this.confirmarDesbloquearFamiliar(grupo.gfa_usuario_parentesco)} />
                                        </Tooltip>
                                      </label>
                                    )}
                                  </Grid>
                                </React.Fragment>
                              </Grid>
                            </React.Fragment>
                          </div>
                        </div>
                      </Grid>
                    ) : (
                      <Grid item xs={6}>
                        <div
                          style={{
                            width: "100%",
                            float: "left",
                            marginTop: "25px",
                          }}
                        >
                          <img className={InformacionUsuarioCSS.imagenDatos_GrupoFamiliar_pequenio} style={{ marginLeft: "4.3vw" }} src={lista_parentescos[grupo.gfa_parentesco]} />
                          <div
                            className="datosUsuario "
                            style={{
                              border: "4px solid #eaa028",
                              marginLeft: "8.6vw",
                              marginTop: "-3.5vw",
                            }}
                          >
                            <React.Fragment>
                              <Grid container spacing={2} className="contenidoDatosUsuario">
                                <React.Fragment key={index}>
                                  <Grid className="datosIndividuales" item xs={12}>
                                    <label name="labelTam" className="tituloDatosUsuario">
                                      Parentesco:
                                    </label>
                                    <label name="labelTam">
                                      {grupo.parentesco}
                                      <img
                                        style={{
                                          width: 18,
                                          float: "inherit",
                                          marginBottom: -4,
                                        }}
                                        className="imagenBotonAccion "
                                        src={icono_modificar}
                                        onClick={() => this.mostrarModalModificar(grupo.gfa_id, grupo.gfa_parentesco, grupo.rutFamiliar, grupo.gfa_usuario)}
                                      />
                                    </label>
                                  </Grid>
                                  <Grid className="datosIndividuales" item xs={12}>
                                    <label name="labelTam" className="tituloDatosUsuario">
                                      Rut:
                                    </label>
                                    <label name="labelTam">
                                      {grupo.rutFamiliar}{" "}
                                      <img
                                        style={{
                                          width: 18,
                                          float: "inherit",
                                          marginBottom: -4,
                                        }}
                                        className="imagenBotonAccion "
                                        src={copiar}
                                        onClick={() => this.copiarRut(grupo.rutFamiliar)}
                                      />
                                    </label>
                                  </Grid>

                                  <Grid className="datosIndividuales" item xs={12}>
                                    <label name="labelTam" className="tituloDatosUsuario">
                                      Nombre:
                                    </label>
                                    <label name="labelTam">{grupo.nombreUsuario}</label>
                                  </Grid>
                                  <Grid className="datosIndividuales" item xs={12}>
                                    <label name="labelTam" className="tituloDatosUsuario">
                                      Cesfam:
                                    </label>
                                    <label name="labelTam">{grupo.nombreCesfamFamiliar}</label>
                                  </Grid>
                                  <Grid className="datosIndividuales" item xs={12}>
                                    <label name="labelTam" className="tituloDatosUsuario">
                                      Programas:
                                    </label>
                                    {grupo.programas ? (
                                      <label name="labelTam">
                                        <Tooltip title="Ver programas del paciente" aria-label="Ver programas del paciente">
                                          <img
                                            aria-describedby={id}
                                            style={{
                                              width: 18,
                                              float: "inherit",
                                              marginBottom: -4,
                                            }}
                                            className="imagenBotonAccion "
                                            src={icono_ver_mas}
                                            onClick={this.handleClick}
                                          />
                                        </Tooltip>

                                        <Popover
                                          id={id}
                                          open={open}
                                          anchorEl={this.state.mostrarProgramas}
                                          onClose={this.handleClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "center",
                                          }}
                                        >
                                          {grupo.programas.map((res, index) => (
                                            <p key={index}>{res.programa}</p>
                                          ))}
                                        </Popover>
                                      </label>
                                    ) : (
                                      <label name="labelTam">--</label>
                                    )}
                                  </Grid>
                                  <Grid className="datosIndividuales" item xs={12} style={{ marginRight: 20 }}>
                                    {grupo.gfa_estado == 1 ? (
                                      <label name="labelTam" className="tituloDatosUsuario">
                                        <Tooltip title="Eliminar familiar" aria-label="Eliminar familiar">
                                          <img className={InformacionUsuarioCSS.icono_pequenio} src={icono_eliminar} onClick={() => this.mostrarAlertaEliminarFamiliar(grupo.gfa_id, grupo.gfa_usuario_parentesco)} />
                                        </Tooltip>

                                        <Tooltip title="Ver las horas futuras del familiar" aria-label="Ver las horas futuras del familiar">
                                          <img className={InformacionUsuarioCSS.icono_pequenio} src={vista_6} onClick={() => this.mostrarModalHorasFuturas(grupo.gfa_usuario_parentesco, grupo.parentesco, grupo.rutFamiliar, grupo.nombreUsuario)} />
                                        </Tooltip>

                                        <Tooltip title="Ver los registros de canales del familiar" aria-label="Ver los registros de canales del familiar">
                                          <img className={InformacionUsuarioCSS.icono_pequenio} src={vista_2} onClick={() => this.mostrarModalTelefonos(grupo.gfa_usuario_parentesco, grupo.parentesco, grupo.rutFamiliar, grupo.nombreUsuario)} />
                                        </Tooltip>

                                        {grupo.cesfamFamiliar == this.state.cesfam ? (
                                          <Tooltip title="Reservar una hora para el familiar" aria-label="Reservar una hora para el familiar">
                                            <img className={InformacionUsuarioCSS.icono_pequenio} src={icono_reservar} onClick={() => this.mostrarModalAgendar(grupo.gfa_usuario_parentesco, grupo.parentesco, grupo.rutFamiliar, grupo.nombreUsuario)} />
                                          </Tooltip>
                                        ) : null}

                                        <Tooltip title="Bloquear familiar" aria-label="Bloquear familiar">
                                          <img className={InformacionUsuarioCSS.icono_pequenio} src={icono_bloquear} onClick={() => this.confirmarBloquearFamiliar(grupo.gfa_usuario_parentesco, grupo.parentesco, grupo.rutFamiliar, grupo.nombreUsuario)} />
                                        </Tooltip>
                                      </label>
                                    ) : (
                                      <label name="labelTam" className="tituloDatosUsuario">
                                        <Tooltip title="Eliminar familiar" aria-label="Eliminar familiar">
                                          <img className={InformacionUsuarioCSS.icono_pequenio} src={icono_eliminar} onClick={() => this.mostrarAlertaEliminarFamiliar(grupo.gfa_id, grupo.gfa_usuario_parentesco)} />
                                        </Tooltip>

                                        <Tooltip title="Desbloquear familiar" aria-label="Desbloquear familiar">
                                          <img className={InformacionUsuarioCSS.icono_pequenio} src={icono_desbloquear} onClick={() => this.confirmarDesbloquearFamiliar(grupo.gfa_usuario_parentesco)} />
                                        </Tooltip>
                                      </label>
                                    )}
                                  </Grid>
                                </React.Fragment>
                              </Grid>
                            </React.Fragment>
                          </div>
                        </div>
                      </Grid>
                    )}
                  </>
                ))}
              </React.Fragment>
            ) : (
              <Grid item xs={6}>
                <div>
                  <h2 style={{ marginLeft: 50 }}>Grupo familiar no asociado</h2>
                </div>
              </Grid>
            )}
          </Grid>
        </div>

        {this.state.modalTelefonos ? <ModalTelefonos ocultar={this.ocultarModalTelefonos} idFamiliar={this.state.idFamiliar} parentesco={this.state.parentesco} rutFamiliar={this.state.rutFamiliar} nombreFamiliar={this.state.nombreFamiliar}></ModalTelefonos> : null}
        {this.state.modalHorasFuturas ? <ModalHorasFuturas ocultar={this.ocultarModalHorasFuturas} idFamiliar={this.state.idFamiliar} parentesco={this.state.parentesco} rutFamiliar={this.state.rutFamiliar} nombreFamiliar={this.state.nombreFamiliar}></ModalHorasFuturas> : null}
        {this.state.modalAgendar ? <ModalAgendar ocultar={this.ocultarModalAgendar} usuario={this.state.idFamiliar} reservador={this.state.usuId} parentesco={this.state.parentesco} rutFamiliar={this.state.rutFamiliar} nombreFamiliar={this.state.nombreFamiliar}></ModalAgendar> : null}
        {this.state.modalModificar ? <ModalModificar ocultar={this.ocultarModalModificar} ocultarActualizado={this.ocultarModalModificarActualizado} gfaid={this.state.gfaid} usuId={this.state.usuId} parentesco={this.state.parentesco} rutFamiliar={this.state.rutFamiliar}></ModalModificar> : null}
        {this.state.motivoBloqueoFamiliar ? <MotivoBloqueoFamiliar usuario={this.state.usuId} usuarioFamiliar={this.state.idFamiliar} ocultar={this.ocultarMotivoBloqueoFamiliar} /> : null}
      </div>
    );
  }
}
