import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import chileanRut from "chilean-rut";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "./../../store/index";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { DialogContentText } from "@material-ui/core";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Buttons from "react-multi-date-picker/components/button";
import { Input } from "@material-ui/core";
import "../../assets/css/usuarios.css";

export default class EditarFechaRegistro extends Component {
  constructor(props) {
    super(props);
    var today = new Date(),
      date = moment(today).format("YYYY-MM-DD");

    this.state = {
      cesfamus: store.getState().cesfam,
      open: true,
      pagina: 0,
      filas: 10,
      total: 0,
      id_usuario: props.id_usuario,
      tratamientos: [],
      fecha_reinv: props.fecha_inv,
      fecha_registro: date,
      cesfam_admin: store.getState().cesfam,
      usp_id: store.getState().usp,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  obtenerTratamientos = () => {
    axios
      .get(global.config.modulos.listaEspera + "ver_tratamientos/", {
        headers: authHeader(),
        params: {
          cesfam_admin: this.state.cesfam_admin,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            tratamientos: res.data.datos,
          });
        }
      });
  };

  componentDidMount() {
    this.obtenerTratamientos();
  }

  modiFecha = (e) => {
    const fecha_registro = e.target.value;
    this.setState({ fecha_registro: fecha_registro });
  };

  agregarTratamiento = () => {
    if (this.state.fecha_registro === " " || this.state.fecha_registro === null) {
      NotificationManager.error("Debe ingresar una fecha", "Atencion", 3000);
      return;
    }
    axios
      .patch(
        global.config.modulos.listaEspera + "editar_fecha_registro/",
        {
          fecha_registro: this.state.fecha_registro,
          id_usuario: this.state.id_usuario,
          usp_id: this.state.usp_id,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Fecha  modificada  correctamente", "Atencion", 3000);
          this.setState({
            rutM: null,
          });
          this.salir();
        } else {
          NotificationManager.error(res.data.mensaje, "Atencion", 3000);
          return;
        }
      });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario" style={{ maxWidth: "100%", maxHeight: "100%" }}>
          <DialogContent
            style={{
              width: "700px",
              maxWidth: "100%",
              height: "100%",
              maxHeight: "100%",
            }}
          >
            <div style={{ marginBottom: "20px" }}>
              <h2>Modificar fecha registro</h2>
            </div>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div style={{ marginBottom: "3%" }}>
                  <h4>Razón fecha inválida: {this.state.fecha_reinv}</h4>
                  <div style={{ marginBottom: "5px" }}>
                    <FormControl style={{ width: "90%", marginRight: "4%" }}>
                      <Input type="date" name="fecha_registro" value={this.state.fecha_registro} onChange={this.modiFecha} />
                    </FormControl>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginBottom: "3%",
                    marginLeft: "65%",
                    marginTop: "30px",
                  }}
                >
                  <Buttons
                    onClick={() => this.agregarTratamiento()}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2%",
                      marginTop: "2%",
                      fontSize: "16px",
                      float: "left",
                      border: "0",
                      textAlign: "center",
                      backgroundColor: "#00ac77",
                      height: "40px",
                      width: "150px",
                    }}
                  >
                    Modificar
                  </Buttons>
                  <Buttons
                    onClick={this.salir}
                    className={"btnOpcion fondoNaranjo"}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2%",
                      marginTop: "2%",
                      fontSize: "16px",
                      float: "left",
                      border: "0",
                      textAlign: "center",
                      height: "40px",
                      width: "150px",
                    }}
                  >
                    Cancelar
                  </Buttons>
                </div>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
