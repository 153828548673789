import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "../../store/index";

// core components
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

// estilos
import "./../../assets/css/general.css";
import "./../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css";

export default class ModalHoras extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,

      horario: props.horario,
      open: true,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  salirActualizado = () => {
    this.setState({
      open: false,
    });
    this.props.ocultarActualizado();
  };

  revisarHorario = () => {
    var data = {
      hor_revisada: 1,
    };
    let apiUrl = global.config.modulos.agendamientos + "horario_por_id/" + this.state.horario;
    const options = {
      method: "PATCH",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      this.salirActualizado();
    });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} className="modificarUsuario">
        <DialogContent>
          <GridContainer className="">
            <GridItem xs={12} style={{ marginTop: 10 }}>
              <h1>
                ¿Estás seguro que este registro ya <br /> esta ingresado en la ficha clínica?
              </h1>
            </GridItem>
            <GridItem xs={12} style={{ marginTop: 30, display: "flex", justifyContent: "flex-end" }}>
              <Button style={{ marginRight: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} className="fondoVerde" onClick={this.revisarHorario}>
                Confirmar
              </Button>
              <Button style={{ marginLeft: 5, marginRight: 20, paddingLeft: 20, paddingRight: 20, borderRadius: 8 }} color="primary" className="botonGeneral" onClick={this.salir}>
                Cancelar
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
