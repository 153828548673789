import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components

import GridContainer from "./../../../components/grid/GridContainer.js";
import GridItem from "./../../../components/grid/GridItem";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { store } from "./../../../store/index";
import { Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import "moment/locale/es";
import axios from "axios";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

// CSS
import "./../../../assets/css/general.css";
import "./../../../assets/css/usuarios.css";
import "./../../../assets/css/graficos.css";
import GraficoProfesionales from "./GraficoProfesionales.js";

export default class CantidadProfesionales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uspId: store.getState().usp,
      selected: 0,
      showCes: false,
      showAte: false,
      showDate: false,
      showApply: false,
      showGraf: false,
      showTable: false,
      filename: "",
      cesfams: [],
      especialidades: [],
      espSelected: null,
      espNombre: "",
      profNombre: "",
      espSigla: "",
      data: [],
      cesfamNom: "",
      cesOpcion: null,
      comunaNom: "",
      comOpcion: null,
      dateSelect: null,
    };
  }

  componentDidMount() {}

  cambiaGraf(event, value) {
    if (value.val == 0) {
      this.setState({ grafComuna: false });
    } else if (value.val == 1) {
      this.setState({ grafComuna: true });
    }
  }

  parametrosUrl() {
    let urlparams = "";
    let nombreArchivo = "Intentos de Llamada - ";
    if (this.state.comOpcion) {
      urlparams = urlparams + "&comuna=" + this.state.comOpcion.id;
      nombreArchivo = nombreArchivo + this.state.comOpcion.label;
    }
    if (this.state.comOpcion && this.state.cesOpcion) {
      urlparams = urlparams + "&cesfam=" + this.state.cesOpcion.id;
      nombreArchivo = nombreArchivo + " - " + this.state.cesOpcion.label;
    }
    if (this.state.comOpcion && this.state.cesOpcion && this.state.espSelected) {
      urlparams = urlparams + "&especialidad=" + this.state.espSelected.id;
      nombreArchivo = nombreArchivo + " - " + this.state.espSelected.label;
    }
    if (this.state.comOpcion && this.state.cesOpcion && this.state.espSelected && this.state.profSelected) {
      urlparams = urlparams + "&profesional=" + this.state.profSelected.id;
      nombreArchivo = nombreArchivo + " - " + this.state.profSelected.label;
    }
    nombreArchivo = nombreArchivo + " - " + moment(this.state.dateSelect).format("DD-MM-YYYY");
    this.setState({ filename: nombreArchivo });
    return urlparams;
  }

  cargarDatos = async () => {
    const options = {
      method: "GET",
      headers: authHeader(),
    };
    let data = [];
    let fecha = moment(this.state.dateSelect).format("YYYY-MM-DD");
    let params = this.parametrosUrl();
    const apiUrl = global.config.modulos.videollamada_grafico + "llamadas_profesionales/?fecha=" + fecha + params;
    await axios(apiUrl, options).then((res) => {
      res.data.llamadas.map((res) => {
        let entrada = { prof: res.pro_nombre + " " + res.pro_apellido, tconsultas_realizadas: res.tconsultas_realizadas, tconsultas_fallidas: res.tconsultas_fallidas };
        data.push(entrada);
      });
      this.setState({ data: data, showGraf: true, llamadas: res.data.lista });
    });
  };

  setComuna = (event, value) => {
    this.setState({ data: [], comunaNom: value.label, comunaSelected: value.id, comOpcion: value, cesOpcion: null, cesfamNom: "", cesfamSelected: 0, showDownload: false }, () => {
      this.cargarCesfams(value.id);
      this.setState({ showCes: true, showAte: false, showProf: false, showDate: true, showApply: false, cesfamSelected: null, espSelected: null, profSelected: null, dateSelect: null, showGraf: false });
    });
  };

  cargarCesfams = async (comuna) => {
    const apiUrl = global.config.modulos.videollamada_grafico + "lista_cesfams/?comuna=" + comuna;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.cesfams.length > 0) {
        var ind = 0;
        this.setState({ cesfams: [] });
        res.data.cesfams.map((res) => {
          this.setState({ cesfams: [...this.state.cesfams, { label: res.ces_nom, val: ind, id: res.ces_id }] });
          ind = ind + 1;
        });
      }
    });
  };

  cargarEspecialidades = async (cesfam) => {
    const apiUrl = global.config.modulos.videollamada_grafico + "lista_especialidades/?cesfam=" + cesfam;
    axios.get(apiUrl, { headers: authHeader() }).then((res) => {
      if (res.data.especialidades.length > 0) {
        var ind = 0;
        this.setState({ especialidades: [] });
        res.data.especialidades.map((res) => {
          //console.log(res)
          this.setState({ especialidades: [...this.state.especialidades, { label: res.esp_nombre, val: ind, id: res.esp_id, sig: res.esp_sigla }] });
          ind = ind + 1;
        });
      }
    });
  };

  setCesfam = (event, value) => {
    if (value === null) {
      this.setState({ cesfamNom: null, cesfamSelected: null, cesOpcion: null, espSelected: null }, () => {
        this.setState({ showAte: false, showProf: false, showDate: true, showApply: false, espSelected: null, profSelected: null, dateSelect: null, showGraf: false });
      });
    } else {
      this.setState({ cesfamNom: value.label, cesfamSelected: value.id, cesOpcion: value, espSelected: null }, () => {
        this.cargarEspecialidades(value.id);
        this.setState({ showAte: true, showProf: false, showDate: true, showApply: false, espSelected: null, profSelected: null, dateSelect: null, showGraf: false });
      });
    }
  };

  setEspecialidad = (event, value) => {
    if (value === null) {
      this.setState({ espSelected: null, espNombre: null, espSigla: null }, () => {
        this.setState({ showProf: true, showDate: true, showApply: false, profSelected: null, dateSelect: null, showGraf: false });
      });
    } else {
      this.setState({ espSelected: value, espNombre: value.label, espSigla: value.sig }, () => {
        this.setState({ showProf: true, showDate: true, showApply: false, profSelected: null, dateSelect: null, showGraf: false });
      });
    }
  };

  setFecha = (e) => {
    this.setState({ dateSelect: e, showGraf: false, showApply: true });
  };

  onComunaChange(event, value) {
    this.setState({ selected: value.id });
  }

  verTabla() {
    this.setState({ showTable: true, showGraf: false });
  }

  verGrafico() {
    this.setState({ showTable: false, showGraf: true });
  }

  render() {
    //console.log(this.state);
    //console.log(this.props);
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Grid container spacing={3}>
              <Grid item xs={2} sm={2} md={2}>
                <Autocomplete disablePortal disableClearable value={this.state.comOpcion} id="combo-box-comuna" options={this.props.comunas} getOptionLabel={(option) => option.label} defaultValue={this.state.comOpcion} onChange={this.setComuna.bind(this)} renderInput={(params) => <TextField {...params} label="Comuna" />}></Autocomplete>
              </Grid>
              {this.state.showCes ? (
                <Grid item xs={2} sm={2} md={2}>
                  <Autocomplete disablePortal value={this.state.cesOpcion} id="combo-box-cesfam" defaultValue={this.state.cesOpcion} options={this.state.cesfams} noOptionsText={"Seleccione una comuna primero"} getOptionLabel={(option) => option.label} onChange={this.setCesfam.bind(this)} renderInput={(params) => <TextField {...params} label="Cesfam" />}></Autocomplete>
                </Grid>
              ) : null}
              {this.state.showAte ? (
                <Grid item xs={2} sm={2} md={2}>
                  <Autocomplete disablePortal id="combo-box-atencion" value={this.state.espSelected} options={this.state.especialidades} getOptionLabel={(option) => option.label} onChange={this.setEspecialidad.bind(this)} renderInput={(params) => <TextField {...params} label="Tipo de Atencion" />}></Autocomplete>
                </Grid>
              ) : null}
              {this.state.showDate ? (
                <Grid item xs={2} sm={2} md={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <KeyboardDatePicker
                      disableToolbar
                      invalidDateMessage="¡Fecha mal ingresada, por favor inténtalo nuevamente!"
                      disableFuture="true"
                      openTo="year"
                      views={["year", "month", "date"]}
                      value={this.state.dateSelect}
                      initialFocusedDate={this.dateSelect}
                      format="yyyy-MM-dd"
                      id="date-picker-inline"
                      label="Fecha"
                      name="yearmonthweekpick"
                      onChange={(e) => this.setFecha(e)}
                      KeyboardButtonProps={{
                        "aria-label": "cambiar fecha",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              ) : null}
              {this.state.showApply ? (
                <Grid item xs={1} sm={1} md={1}>
                  <Button className="botonDialog fondoNaranjo downloadButton" onClick={this.cargarDatos}>
                    Aplicar
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </GridItem>
        </GridContainer>

        <div class="middle-content">
          {this.state.showGraf ? (
            <div class="chartContainer chartGrid scroll-container">
              <GraficoProfesionales key={"chart"} data={this.state.data} fecha={moment(this.state.dateSelect).format("YYYY-MM-DD")} sig={this.state.espSigla} esp={this.state.espNombre} filename={this.state.filename} />
            </div>
          ) : null}
        </div>
      </>
    );
  }
}
