import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import InputLabel from "@material-ui/core/InputLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";

// estilos
//import "../../assets/css/agregar.css";

export default class CiclosAsignarProfesional extends Component {
  constructor(props) {
    super(props);

    this.state = {
      niveles: [],
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      usuId: props.usuario,
      ciclosUsuario: props.ciclosUsuario,
      cicloUsuario: "",
      formErrors: {},
      registroAsignacion: [],
      open: true,
      mostrarNiveles: false,
    };

    this.actualizarRegistro = this.actualizarRegistro.bind(this);
  }

  componentDidMount() {
    this.traeCiclos();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  traeCiclos = () => {
    axios
      .get(global.config.modulos.ciclos, {
        headers: authHeader(),
        params: { cesfam: this.state.cesfam },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ ciclos: res.data.datos });
        }
      });
  };

  traeCiclosUsuario = () => {
    axios
      .get(global.config.modulos.ciclos + "por_usuario/", {
        headers: authHeader(),
        params: { usuario: this.state.usuId, cesfam: this.state.cesfam },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ ciclosUsuario: res.data.datos });
        }
      });
  };

  profesionalesPorNivel = () => {
    axios
      .get(global.config.modulos.ciclos + "niveles_por_ciclo/", {
        headers: authHeader(),
        params: { ciclo: this.state.cicloUsuario, usuario: this.state.usuId },
      })
      .then((res) => {
        if (res.data.estado) {
          let registros = this.state.registroAsignacion;
          res.data.datos.map((niv) => {
            registros[niv.cin_especialidad] = {
              especialidad: niv.cin_especialidad,
              profesional: niv.profesional,
            };
          });

          this.setState({
            registroAsignacion: registros,
          });
          this.setState({ niveles: res.data.datos, mostrarNiveles: true });
        }
      });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, mostrarNiveles: false });
  };

  actualizarRegistro = (especialidad, profesional) => {
    let registros = this.state.registroAsignacion;

    registros[especialidad] = {
      especialidad: especialidad,
      profesional: profesional,
    };
    this.setState({
      registroAsignacion: registros,
    });
  };

  asignarProfesional = () => {
    let registros = this.state.registroAsignacion;

    NotificationManager.success("Profesionales asignados con éxito");

    registros.map((res) => {
      axios
        .post(
          global.config.modulos.ciclos + "asignar_profesional/",
          {
            ciclo: this.state.cicloUsuario,
            usuario: this.state.usuId,
            usuario_panel: this.state.uspId,
            especialidad: res["especialidad"],
            profesional: res["profesional"],
          },
          {
            headers: authHeader(),
          }
        )
        .then((res) => {
          this.salir();
        });
    });
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="cicloUsuario">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12} className="comentariosUsuario">
              <img className="marca-agua"></img>
              <GridContainer className="agendar-container">
                <GridItem xs={12}>
                  <h2>Asignar profesional</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <InputLabel>Ciclos del usuario</InputLabel>
                  <select className="selectTerminoBeneficio" name="cicloUsuario" value={this.state.cicloUsuario} onChange={this.cambiarValores}>
                    <option>Seleccione ciclo</option>
                    {this.state.ciclosUsuario.map((res, index) => (
                      <option key={index} value={res.ciu_ciclo}>
                        {res.ciclo}
                      </option>
                    ))}
                  </select>
                  <Button id="" className=" fondoRojo asignarProfesional" onClick={this.profesionalesPorNivel} style={{ marginLeft: 20 }}>
                    Asignar
                  </Button>
                </GridItem>
                {this.state.mostrarNiveles ? (
                  <GridItem xs={12} style={{ marginTop: 30 }}>
                    <Table aria-label="simple table" className="bordeTabla">
                      <TableHead>
                        <TableRow>
                          <TableCell className="cellInicio" align="center">
                            Nombre
                          </TableCell>
                          <TableCell align="center">Especialidad</TableCell>
                          <TableCell align="center">Profesional</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.niveles.map((niv, index) => (
                          <TableRow key={index}>
                            <TableCell align="center">{niv.cin_nombre}</TableCell>
                            <TableCell align="center">{niv.especialidad}</TableCell>
                            <TableCell align="center">
                              <Select especialidad={niv.cin_especialidad} profesional={niv.profesional} actualizarRegistro={this.actualizarRegistro} profesional_requerido={niv.cin_profesional_requerido} />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </GridItem>
                ) : null}
                {this.state.mostrarNiveles ? (
                  <GridItem xs={12} style={{ marginTop: 30 }}>
                    <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={this.salir} style={{ marginLeft: 20 }}>
                      Cancelar
                    </Button>
                    <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo asignarProfesional" onClick={this.asignarProfesional} style={{ marginLeft: 20 }}>
                      Guardar
                    </Button>
                  </GridItem>
                ) : (
                  <GridItem xs={12} style={{ marginTop: 30 }}>
                    <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" onClick={this.salir} style={{ marginLeft: 20 }}>
                      Cerrar
                    </Button>
                  </GridItem>
                )}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}

class Select extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profesional_requerido: props.profesional_requerido,
      especialidad: props.especialidad,
      profesionales: [],
      profesional: props.profesional,
    };
  }

  componentDidMount() {
    this.selectProfesional(this.props.especialidad);
  }

  selectProfesional = (especialidad) => {
    axios
      .get(global.config.modulos.profesionales + "por_especialidad/", {
        headers: authHeader(),
        params: { especialidad: especialidad },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({ profesionales: res.data.datos });
        }
      });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    this.props.actualizarRegistro(this.state.especialidad, value);
  };

  render() {
    return (
      <React.Fragment>
        {this.state.profesional_requerido == 1 ? (
          <p>
            <label style={{ fontWeight: "bold", color: "red" }}>Este profesional es obligatorio *</label>
          </p>
        ) : null}
        <select name="profesional" onChange={this.cambiarValores} value={this.state.profesional} className="selectTerminoBeneficio">
          {this.state.profesional_requerido == 1 ? null : <option value="">Sin profesional</option>}
          {this.state.profesionales.map((res, index) => (
            <option key={index} value={res.pro_id}>
              {res.nombreProfesional}
            </option>
          ))}
        </select>
      </React.Fragment>
    );
  }
}
