import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import moment from "moment";
import { store } from "./../../store/index";

// core components
import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import chileanRut from "chilean-rut";

import { confirmAlert } from "react-confirm-alert"; // Import

// estilos
import "../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class ModalCambiarRut extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usp_id: store.getState().usp,
      cesfam: store.getState().cesfam,

      agendaReservada: props.ageId,
      horario: props.horario,
      horaAtencion: "",
      especialidad: "",

      rut_usuario: "",
      usu_id: "",

      nombreProfesional: props.nombreProfesional,
      nombreEspecialidad: props.nombreEspecialidad,
      open: true,
      formErrors: [],
    };

    this.traerHorario();
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  alerta = (mensaje) => {
    confirmAlert({
      title: "",
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
          className: "btnAceptar",
        },
      ],
    });
  };

  traerHorario = () => {
    let apiUrl = global.config.modulos.agendamientos + "horario_por_id/" + this.state.horario;
    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        var data = res.data;
        var estado = data.estado;

        if (estado) {
          var horaAtencion = moment(data.hor_atencion).format("DD-MM-YYYY HH:mm:ss");

          this.setState({
            horaAtencion: horaAtencion,
            especialidad: data.hor_especialidad,
          });
        }
      });
  };

  validaciones() {
    const { rut_usuario } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (!rut_usuario) {
      formIsValid = false;
      formErrors["usuarioErr"] = "Escribe el rut.";
    } else if (!chileanRut.validate(rut_usuario)) {
      formIsValid = false;
      formErrors["usuarioErr"] = "Escribe el rut válido.";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  reservarHora = () => {
    if (this.validaciones()) {
      var data = {
        rut: this.state.rut_usuario,
        canal: 5,
        cesfam: store.getState().cesfam,
      };

      let apiUrl = global.config.modulos.usuarios + "por_rut/";

      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };
      axios(apiUrl, options).then((res) => {
        var data = res.data;
        var estado = data.estado;

        if (estado) {
          if (data.datos.usu_estado == 2) {
            let formErrors = {};

            formErrors["usuarioErr"] = "Paciente eliminado de HoraSalud.";
            this.setState({ formErrors: formErrors });
          } else if (data.datos.usu_estado == 0) {
            let formErrors = {};

            formErrors["usuarioErr"] = "Paciente no inscrito en HoraSalud.";
            this.setState({ formErrors: formErrors });
          } else {
            var usu_id = data.datos.usu_id;

            confirmAlert({
              title: "",
              message: "Segur@ que desea cambiar RUT de paciente en la reserva?",
              buttons: [
                {
                  label: "Cancelar",
                  className: "btnCancelar",
                  onClick: () => null,
                },
                {
                  label: "Aceptar",
                  className: "btnAceptar",
                  onClick: () => {
                    let apiUrl = global.config.modulos.agendamientos + "cambiar_usuario_agenda/";
                    const options = {
                      method: "PATCH",
                      data: {
                        agenda: this.state.agendaReservada,
                        usuario: usu_id,
                        uspId: this.state.usp_id,
                      },
                      headers: authHeader(),
                    };
                    axios(apiUrl, options).then((res) => {
                      this.salir();
                    });
                  },
                },
              ],
            });
          }
        } else {
          let formErrors = {};

          formErrors["usuarioErr"] = res.data.mensaje;
          this.setState({ formErrors: formErrors });
        }
      });
    }
  };

  cambiarValores = (e) => {
    let { name, value } = e.target;

    this.setState({ [name]: value });
  };

  render() {
    const { usuarioErr } = this.state.formErrors;
    const { rut_usuario } = this.state;

    return (
      <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="agendarHora">
        <DialogContent>
          <GridContainer className="agendar-container">
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer className="agendar-container">
                <GridItem xs={12} sm={12} md={12}>
                  <h2>Cambiar Rut de paciente en la reserva</h2>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 30 }}>
                  <GridContainer className="agendar-container">
                    <GridItem xs={3}>
                      <p style={{ fontWeight: "bold" }}>Nombre profesional</p>
                      <p style={{ marginTop: 5 }}>{this.state.nombreProfesional}</p>
                    </GridItem>
                    <GridItem xs={3}>
                      <p style={{ fontWeight: "bold" }}>Especialidad</p>
                      <p style={{ marginTop: 5 }}>{this.state.nombreEspecialidad}</p>
                    </GridItem>
                    <GridItem xs={3}>
                      <p style={{ fontWeight: "bold" }}>Horario</p>
                      <p style={{ marginTop: 5 }}>{this.state.horaAtencion}</p>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <br></br>
                  <InputLabel>Rut</InputLabel>
                  <input type="text" id="rut_usuario" name="rut_usuario" value={rut_usuario} onChange={this.cambiarValores} placeholder="Ejemplo 12345678-9" className={usuarioErr ? "inputTextAgendar showError" : "inputTextAgendar"} maxLength={12} />

                  {usuarioErr && <div style={{ color: "red", paddingBottom: 10 }}>{usuarioErr}</div>}
                  <Button variant="contained" className="btnCargaHorario fondoVerde" onClick={this.reservarHora}>
                    Cambiar
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}
