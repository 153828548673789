import React, { useState } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import { useHistory } from "react-router-dom";
import { store } from "./../../store/index";

import axios from "axios";

// core components
import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";
import ComentariosDemanda from "components/Demanda/AgregarObservacion";

import Button from "@material-ui/core/Button";
import TableContainer from "@material-ui/core/TableContainer";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";

import icono_ir from "assets/img/gestion/ir.svg";

import respuestas from "assets/img/gestion/respuestas.svg";

// estilos
import "assets/css/general.css";
import "assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css";

const BuscarUsuarioGestion = (props) => {
  const [cesfam] = useState(store.getState().cesfam);
  const [open] = useState(true);
  const [usuarios, setUsuarios] = useState([]);
  const [comentariosDemanda, setComentariosDemanda] = useState(false);
  const [usuId, setUsuId] = useState(null);
  const [estadoCaso, setEstadoCaso] = useState(null);
  const [demandaTomado, setDemandaTomado] = useState(null);

  const redirigirGestion = (ubicacion, rut) => {
    console.log(ubicacion, rut);
    props.activarVista(ubicacion, rut);
    props.ocultar();
  };

  const abrirComentariosDemanda = (usuario, estado, usp) => {
    setUsuId(usuario);
    setEstadoCaso(estado);
    setDemandaTomado(usp);
    setComentariosDemanda(true);
  };
  const ocultarComentariosDemanda = () => {
    setComentariosDemanda(false);
  };

  const buscarUsuarios = (e) => {
    const { value } = e.target;
    const nombre = value;

    var data = {
      nombre: nombre,
    };

    let apiUrl = global.config.modulos.demanda + "buscar_usuario_gestion/";

    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };

    if (nombre.length >= 4) {
      axios(apiUrl, options).then((res) => {
        let datos = [];
        if (res.data.datos) {
          datos = res.data.datos;
        }
        setUsuarios(datos);
      });
    } else if (nombre == "") {
      setUsuarios([]);
    }
  };

  return (
    <Dialog open={open} onClose={props.ocultar} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="buscarUsuarioGestion">
      <DialogContent>
        <GridContainer className="agendar-container">
          <GridItem xs={12} sm={12} md={12} className="">
            <GridContainer className="agendar-container">
              <GridItem xs={12}>
                <h2>Buscar usuario</h2>
              </GridItem>
              <GridItem xs={12} style={{ marginTop: 30 }}>
                <p>Escriba el rut o el nombre del paciente a buscar</p>
                <p>
                  <input type="text" className="input-buscar-usuario" onChange={(e) => buscarUsuarios(e)} />
                </p>
                <TableContainer style={{ marginTop: 30, height: 500 }}>
                  <Table aria-label="simple table" className="tablaGeneralGestion bordeTabla">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Fecha de ingreso</TableCell>
                        <TableCell align="center">Ubicación</TableCell>
                        <TableCell align="center">Nombre</TableCell>
                        <TableCell align="center">Rut</TableCell>
                        <TableCell align="center">Cesfam</TableCell>
                        <TableCell align="center">Tomado por</TableCell>
                        <TableCell align="center">Observaciones</TableCell>
                        <TableCell align="center">Ir</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {usuarios.map((res, index) => (
                        <TableRow index={index}>
                          <TableCell align="center">{res.ged_fecha_registro.replace("T", " ")}</TableCell>
                          <TableCell align="center">{res.ubicacion}</TableCell>
                          <TableCell align="center">{res.nombre_usuario}</TableCell>
                          <TableCell align="center">{res.usu_rut}</TableCell>
                          <TableCell align="center">{res.cesfam}</TableCell>
                          <TableCell align="center">{res.usuario_panel}</TableCell>
                          <TableCell align="center">
                            <Tooltip title="Ver observaciones" aria-label="Ver observaciones">
                              <img className="smallMedIcon" style={{ marginTop: 0 }} src={respuestas} onClick={() => abrirComentariosDemanda(res.usu_id, res.ged_estado, res.ged_tomado)} />
                            </Tooltip>
                          </TableCell>
                          <TableCell align="center">
                            {res.ged_cesfam == cesfam ? (
                              <a onClick={() => redirigirGestion(res.ged_gestion_funcionario, res.usu_rut)}>
                                <img style={{ width: 30 }} src={icono_ir} />
                              </a>
                            ) : (
                              "--"
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </GridItem>

              <GridItem xs={12} style={{ marginTop: 20 }}>
                <Button id={"modalModificarUsuarioBtnCancelar"} onClick={props.ocultar} style={{ marginLeft: 20, float: "right", color: "#fff" }} color="primary" className="fondoRojo">
                  Cerrar
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>

          {comentariosDemanda ? <ComentariosDemanda ocultar={ocultarComentariosDemanda} usuario={usuId} estadoCaso={estadoCaso} demandaTomado={demandaTomado}></ComentariosDemanda> : null}
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
};

export default BuscarUsuarioGestion;
