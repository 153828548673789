import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import Button from "@material-ui/core/Button";
import axios from "axios";

import GridItem from "./../../components/grid/GridItem.js";
import GridContainer from "./../../components/grid/GridContainer.js";
import Grid from "@material-ui/core/Grid";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert"; // Import

import Imagenologia from "assets/css/imagenologia.module.css";

import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";

// estilos
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class CompletarFolio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hoy: moment().format("YYYY-MM-DD"),
      uspId: store.getState().usp,
      ageId: props.ageId,
      folio: "",
      nroFolio: "",
      fechaEmision: "",

      formErrors: {},
      open: true,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  validaciones() {
    const { folio, nroFolio, fechaEmision } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (folio == "" || folio == 0) {
      formIsValid = false;
      formErrors["folioErr"] = "Selecciona la opción.";
    }

    if (folio == 1) {
      if (nroFolio == "" || nroFolio == 0) {
        formIsValid = false;
        formErrors["nroFolioErr"] = "Escribe el número de folio.";
      } else {
        if (nroFolio.length < 7) {
          formIsValid = false;
          formErrors["nroFolioErr"] = "Revisa que el número de folio sea correcto";
        }
      }

      if (fechaEmision == "" || fechaEmision == 0) {
        formIsValid = false;
        formErrors["fechaErr"] = "Selecciona la fecha de emisión.";
      }
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  enviarFormulario = (e) => {
    if (this.validaciones()) {
      var data = {
        agenda: this.state.ageId,
        tipoFolio: this.state.folio,
        uspId: this.state.uspId,
      };

      if (this.state.folio == 1) {
        data["fechaEmision"] = this.state.fechaEmision;
        data["folio"] = this.state.nroFolio;
      }

      let apiUrl = global.config.modulos.agendamientos + "ingresar_folios/";
      const options = {
        method: "POST",
        data: data,
        headers: authHeader(),
      };
      axios(apiUrl, options).then((res) => {
        if (res.data.estado) {
          this.anunciarLlegada();
        }
      });
    }
  };

  anunciarLlegada = () => {
    var data = {
      agenda_id: this.state.ageId,
      usuario_panel: this.state.uspId,
    };
    let apiUrl = global.config.modulos.agendamientos + "confirmar_llegada/";
    const options = {
      method: "POST",
      data: data,
      headers: authHeader(),
    };
    axios(apiUrl, options).then((res) => {
      if (res.data.estado) {
        NotificationManager.success("Llegada confirmada con éxito");
        this.salir();
      }
    });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { folioErr, fechaErr, nroFolioErr } = this.state.formErrors;

    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="anularHora">
          <DialogContent className={Imagenologia.popup}>
            <GridContainer className="agendar-container">
              <GridItem xs={12}>
                <h2>Para confirmar la llegada necesitamos que seleccione lo siguiente</h2>
              </GridItem>
              <GridItem xs={12} style={{ marginTop: 30 }}>
                <Select id="folio" name="folio" style={{ width: "100%" }} value={this.state.folio} onChange={this.cambiarValores} displayEmpty>
                  <MenuItem value="" disabled>
                    <em>Selecciona</em>
                  </MenuItem>

                  <MenuItem value={1} key={0}>
                    {"Folio de Fonasa"}
                  </MenuItem>
                  <MenuItem value={2} key={1}>
                    {"Sin folio"}
                  </MenuItem>
                  <MenuItem value={3} key={2}>
                    {"Particular"}
                  </MenuItem>
                </Select>
                {folioErr && <div style={{ color: "red", paddingBottom: 10 }}>{folioErr}</div>}
              </GridItem>

              {this.state.folio == 1 && (
                <GridItem id="contenedorFolio" xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <InputLabel>N° Folio Fonasa*</InputLabel>
                      <TextField
                        id="nroFolio"
                        name="nroFolio"
                        type="text"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={this.state.nroFolio}
                        onChange={this.cambiarValores}
                        inputProps={{ maxLength: 10 }}
                      />

                      {nroFolioErr && <div style={{ color: "red", paddingBottom: 10 }}>{nroFolioErr}</div>}
                    </Grid>

                    <Grid item xs={6}>
                      <InputLabel>Fecha de emisión*</InputLabel>
                      <TextField
                        id="fechaEmision"
                        name="fechaEmision"
                        type="date"
                        value={this.state.fechaEmision}
                        InputProps={{
                          inputProps: {
                            min: "1900-01-01",
                            max: this.state.hoy,
                          },
                        }}
                        onChange={this.cambiarValores}
                      />
                      {fechaErr && <div style={{ color: "red", paddingBottom: 10 }}>{fechaErr}</div>}
                    </Grid>
                  </Grid>
                </GridItem>
              )}

              <br></br>
              <GridItem xs={12} style={{ marginTop: 30 }}>
                <Button id="modalEliminarBtnCancelar" className="botonDialog fondoRojo" style={{ marginLeft: 20 }} onClick={this.salir}>
                  Cancelar
                </Button>
                <Button className="botonDialog fondoVerde" onClick={this.enviarFormulario}>
                  Guardar
                </Button>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
