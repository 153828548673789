import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import chileanRut from "chilean-rut";
import axios from "axios";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import Buttons from "react-multi-date-picker/components/button";

import { Input } from "@material-ui/core";
import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert"; // Import
import { DialogContentText } from "@material-ui/core";

import "../../assets/css/usuarios.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class EditarFechaRegistro extends Component {
  constructor(props) {
    super(props);
    var today = new Date(),
      date = moment(today).format("YYYY-MM-DD");

    this.state = {
      registro: props.registro,
      tipo: props.tipo,

      tratamiento: props.dato,
      tratamientos: [],
      tratamiento_secundarios: [],

      dato: props.dato,
      fecha_registro: date,

      cesfam: store.getState().cesfam,
      usp_id: store.getState().usp,
      open: true,
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  obtenerTratamientos = () => {
    axios
      .get(global.config.modulos.listaEspera + "ver_tratamientos/", {
        headers: authHeader(),
        params: {
          cesfam_admin: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            tratamientos: res.data.datos,
          });
        }
      });
  };

  obtenerTratamientoSec = () => {
    axios
      .get(global.config.modulos.listaEspera + "obtener_tratamientos_secundarios/", {
        headers: authHeader(),
        params: {
          cesfam: this.state.cesfam,
        },
      })
      .then((res) => {
        if (res.data.estado) {
          this.setState({
            tratamiento_secundarios: res.data.datos,
          });
        }
      });
  };

  componentDidMount() {
    this.obtenerTratamientos();
    this.obtenerTratamientoSec();
  }

  modificarTratamiento = () => {
    if (this.state.tratamiento == "0" || this.state.tratamiento === null) {
      NotificationManager.error("Debe ingresar un registro", "Atencion", 3000);
      return;
    }

    var data = { loi_id: this.state.registro, usp_id: this.state.usp_id };

    if (this.state.tipo == 1) {
      data["loi_fecha_diagnostico"] = this.state.tratamiento;
    } else if (this.state.tipo == 2) {
      data["loi_tratamiento"] = this.state.tratamiento;
    } else {
      data["loi_tratamiento_secundario"] = this.state.tratamiento;
    }

    console.log(data);
    axios
      .patch(
        global.config.modulos.listaEspera + "modificar_lista/",
        { data: data },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Modificado con éxito ", "Atencion", 3000);
          this.salir();
        } else {
          NotificationManager.error(res.data.mensaje, "Atencion", 3000);
          return;
        }
      });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modalAgregarProfesional">
          <DialogContent>
            <div style={{ marginBottom: "20px" }}>{this.state.tipo == 1 ? <h2>Modificar Fecha de diagnóstico:</h2> : this.state.tipo == 2 ? <h2>Modificar diagnóstico </h2> : <h2>Modificar resultado exploración de oídos</h2>}</div>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {this.state.tipo == 1 ? (
                  <div style={{ marginBottom: "5px" }}>
                    <FormControl>
                      <Input type="date" name="tratamiento" value={this.state.tratamiento} onChange={this.cambiarValores} />
                    </FormControl>
                  </div>
                ) : this.state.tipo == 2 ? (
                  <div style={{ marginBottom: "5px" }}>
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">Seleccione Programa</InputLabel>
                      <Select name="tratamiento" value={this.state.tratamiento} onChange={this.cambiarValores}>
                        <MenuItem value={0}>Seleccione Tratamiento</MenuItem>
                        {this.state.tratamientos.map((tratamiento, i) => {
                          return (
                            <MenuItem key={i} value={tratamiento.lt_id}>
                              {tratamiento.lt_nombre}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                ) : (
                  <div style={{ marginBottom: "5px" }}>
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">Resultado examen</InputLabel>
                      <Select name="tratamiento" value={this.state.tratamiento} onChange={this.cambiarValores}>
                        <MenuItem value={0}>Seleccione resultado de examen</MenuItem>
                        {this.state.tratamiento_secundarios.map((tt_sec, i) => {
                          return (
                            <MenuItem key={i} value={tt_sec.lre_id}>
                              {tt_sec.lre_nombre}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "25px",
                  }}
                >
                  <Button style={{ marginRight: 10 }} onClick={() => this.modificarTratamiento()} className="fondoVerde">
                    Modificar
                  </Button>
                  <Button onClick={this.salir} style={{ color: "#fff" }} className="fondoNaranjo">
                    Cancelar
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
