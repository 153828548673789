import React, {Component} from 'react';
import { store } from "../../store/index";

class ListaEspecialidades extends Component {
    constructor(props){
        super(props);
        this.state = {
            especialidades : [],
            selectedEsp: props.selectedEsp,
            cesfam: store.getState().cesfam,
        }
        this.cambiarEsp = this.cambiarEsp.bind(this);
        this.handleClick = this.handleClick.bind(this);
        
    }

    componentDidMount() {
        const apiURL = 
            global.config.modulos.reportes + "listado_especialidades/" + this.state.cesfam;
        
        fetch(apiURL)
        .then((response) => {
            return response.json();
            
        })
        .then(data => {
            data = data.datos
            let listadoespecialidades = data.map((esp) => {
                return {value: esp.id, display: esp.nombre}
            });
            this.setState({
                especialidades: [{value: '', display: 'Seleccione especialidad'}].concat(listadoespecialidades)
            });
                 
        }).catch(error => {
            console.log(error)
        });
    }
    
    cambiarEsp = (event) => {
        this.setState({selectedEsp: event.target.value})
    }

    handleClick = () => {
        this.props.cambiarEspecialidad(this.state.selectedEsp);
    }

    render(){

        return(
            <div className='filtros-oferta-demanda'>
                <div className='lista-especialidades'>
                    <select className = {"select-especialidad"} id='seleccionarEsp' value={this.state.selectedEsp}
                        onChange={this.cambiarEsp}>
                        {this.state.especialidades.map((esp) => <option key={esp.value} value={esp.value}>{esp.display}</option>)}
                    </select>
                </div>
                <div>
                    <button className='boton-filtrar' onClick={this.handleClick}>
                        Filtrar
                    </button>
                </div>
            </div>
                
        );
    }

}

export default ListaEspecialidades;



































