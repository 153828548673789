import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";
import { store } from "./../../store/index";
import moment from "moment";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

//IMG
import lapiz from "../../assets/img/usuarios/lapiz.svg";

//CSS
import "react-confirm-alert/src/react-confirm-alert.css";
import InformacionUsuarioCSS from "./../../assets/css/usuarios.module.css";

//JS
import HorarioDiario from "./../../components/Profesionales/HorarioDiarioProfesional";

export default class BloqueoHoras extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      proId: this.props.profesional,
      horarios: [],
      especialidadesTodas: [],
      especialidades: [],
      especialidad: "",
      sectores: [],
      sector: "",
      tabla: "",
      fechaHorario: "",
      especialidadHorario: "",
      proNombre: "",
      proRut: "",
      proSexo: "",
      proCorreo: "",
      especialidadFiltro: "",
      semanaFiltro: moment().format("GGGG-[W]WW"),
      tablahorarioProfesional: false,
      horariodiario: false,
      tamanio: props.tamanio,
    };
  }

  componentDidMount() {
    this.traerDatosProfesional(this.props.profesional);
  }

  componentDidUpdate() {
    if (this.props.tamanio != this.state.tamanio) {
      this.setState(
        {
          tamanio: this.props.tamanio,
        },
        () => {
          this.cambiarTamanio(this.state.tamanio);
        }
      );
    }
  }

  cambiarTamanio = (tipo) => {
    this.removerClasses();
    const allElements = document.querySelectorAll("[name='labelTam']");
    const allElements_2 = document.querySelectorAll("[name='aCircle']");

    allElements.forEach((element) => {
      if (tipo == "1") {
        element.classList.add(InformacionUsuarioCSS.label_11);
      } else if (tipo == "2") {
        element.classList.add(InformacionUsuarioCSS.label_13);
      } else if (tipo == "3") {
        element.classList.add(InformacionUsuarioCSS.label_16);
      } else if (tipo == "4") {
        element.classList.add(InformacionUsuarioCSS.label_20);
      }
    });
    allElements_2.forEach((element) => {
      if (tipo == "1" || tipo == "2") {
        element.classList.add(InformacionUsuarioCSS.btn_peque);
      } else {
        element.classList.remove(InformacionUsuarioCSS.btn_peque);
      }
    });

    axios
      .patch(
        global.config.modulos.usuariospanel + "cambio_tamanio_letra/",
        {
          usuarioPanel: store.getState().usp,
          tipo_letra: tipo,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        this.setState({ tamanio: tipo });
      });
  };

  removerClasses = () => {
    const allElements = document.querySelectorAll("[name='labelTam']");

    allElements.forEach((element) => {
      element.classList.remove(InformacionUsuarioCSS.label_11, InformacionUsuarioCSS.label_13, InformacionUsuarioCSS.label_16, InformacionUsuarioCSS.label_20);
    });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    let semana = "";
    let especialidad = "";
    if (name == "semanaFiltro") {
      semana = value;
      especialidad = this.state.especialidadFiltro;
    } else {
      semana = this.state.semanaFiltro;
      especialidad = value;
    }

    this.datosTablaCrearHorario(especialidad, semana);
  };

  traerDatosProfesional(proId) {
    let apiUrl = global.config.modulos.profesionales + "por_profesional_cesfam/" + proId + "/" + this.state.cesfam;

    axios(apiUrl, {
      headers: authHeader(),
    }).then((res) => {
      var nombre = res.data["pro_nombre"] + " " + res.data["pro_apellido_p"];
      var rut = res.data["pro_rut"];

      if (res.data["pro_sexo"] == "M") {
        res.data["pro_sexo"] = "Masculino";
      } else {
        res.data["pro_sexo"] = "Femenino";
      }
      var sexo = res.data["pro_sexo"];

      if (res.data["pro_correo"] == null || res.data["pro_correo"] == "") {
        res.data["pro_correo"] = "no se indica correo electronico";
      }
      var correo = res.data["pro_correo"];

      this.setState({
        proNombre: nombre,
        proSexo: sexo,
        proRut: rut,
        proCorreo: correo,
      });

      this.traerEspecialidades(proId);
    });
  }

  traerEspecialidades(proId) {
    const apiUrl = global.config.modulos.agendamientos + "especialidades_profesional/?proId=" + proId + "&cesfam=" + this.state.cesfam;

    axios
      .get(apiUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.data.datos.length > 0) {
          this.setState({
            especialidades: res.data.datos,
            especialidad: res.data.datos[0]["esp_nombre"],
          });
        } else {
          let espResult = [];
          let esp = {
            esp_nombre: "No tiene",
          };
          espResult.push(esp);
          this.setState({
            especialidades: espResult,
            especialidad: espResult[0]["esp_nombre"],
          });
        }
      });
  }

  datosTablaCrearHorario = (esp, sem) => {
    var especialidad = esp;
    var semana = sem;

    if (semana == "" || especialidad == "") {
      return;
    }

    var arrSemana = sem.split("-W");
    var nSemana = arrSemana[1];
    var añoPorNSemana = arrSemana[0];
    var inicioSemana = moment().isoWeekYear(añoPorNSemana).isoWeek(nSemana).startOf("isoweek").format("YYYY-MM-DD");

    var data = {
      fechaInicioSemana: inicioSemana,
      cesfam: this.state.cesfam,
      profesional: this.state.proId,
      siglaEsp: especialidad,
    };

    let apiUrl = global.config.modulos.profesionales + "cantidad_horario_semanal_profesional/";

    const options = {
      method: "GET",
      params: data,
      headers: authHeader(),
    };

    axios(apiUrl, options).then((res) => {
      var data = res.data;
      var horarios = data.datos;

      var tabla = [];
      var rowsHead = [];

      var fecha = moment(inicioSemana);
      for (var i = 0; i <= 6; i++) {
        rowsHead.push(
          <TableCell className={"tablaRow "} key={i}>
            <p
              name="labelTam"
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                transition: "all 300ms linear",
              }}
            >
              {fecha.format("dddd")}
            </p>
            <p
              name="labelTam"
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                transition: "all 300ms linear",
              }}
            >
              {fecha.format("DD-MM-YYYY")}
            </p>
          </TableCell>
        );
        fecha = moment(fecha).add(1, "day");
      }

      tabla.push(
        <TableHead>
          <TableRow>{rowsHead}</TableRow>
        </TableHead>
      );

      var rows = [];
      var cell = [];
      for (var i = 0; i < horarios.length; i++) {
        var fecha_horario = horarios[i]["fecha_horario"];
        var total = horarios[i]["total"];
        if (total > 0) {
          cell.push(
            <TableCell className={"tablaRow "}>
              <p
                name="labelTam"
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  transition: "all 300ms linear",
                }}
              >
                <a name="aCircle" style={{ transition: "all 300ms linear" }} className={InformacionUsuarioCSS.btn}>
                  {total}
                </a>
              </p>
              <p
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  transition: "all 300ms linear",
                }}
              >
                <a
                  name="labelTam"
                  onClick={this.bloquearHoras.bind(this, fecha_horario, especialidad)}
                  style={{
                    cursor: "pointer",
                    transition: "all 300ms linear",
                  }}
                >
                  <img className={InformacionUsuarioCSS.lapizAgenda} name="lapizAge" src={lapiz} style={{ transition: "all 300ms linear" }} />
                </a>
              </p>
            </TableCell>
          );
        } else {
          cell.push(
            <TableCell className={"tablaRow "}>
              <p
                name="labelTam"
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  transition: "all 300ms linear",
                }}
              >
                <a name="aCircle" style={{ transition: "all 300ms linear" }} className={InformacionUsuarioCSS.btnDisabled}>
                  {0}
                </a>
              </p>
              <p
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  transition: "all 300ms linear",
                }}
              >
                <a name="labelTam" style={{ cursor: "pointer" }}>
                  <img name="lapizAge" style={{ transition: "all 300ms linear" }} className={InformacionUsuarioCSS.lapizAgenda} src={lapiz} />
                </a>
              </p>
            </TableCell>
          );
        }
      }
      rows.push(<TableRow className={"tablaRow"}>{cell}</TableRow>);

      tabla.push(<TableBody>{rows}</TableBody>);

      this.setState(
        {
          tabla: (
            <Table size="small" aria-label="a dense table" style={{ marginTop: 25 }} className={"bordeTabla"}>
              {tabla}
            </Table>
          ),
          tablahorarioProfesional: true,
        },
        this.props.asignarTam()
      );
    });
  };

  bloquearHoras(fechaHorario, especialidad) {
    this.setState({
      fechaHorario: fechaHorario,
      especialidadHorario: especialidad,
      horariodiario: true,
    });
  }

  ocultarHorario = () => {
    this.datosTablaCrearHorario(this.state.especialidadFiltro, this.state.semanaFiltro);
    this.setState({ horariodiario: false });
  };

  render() {
    const { especialidades, especialidadFiltro, semanaFiltro } = this.state;

    return (
      <div className="formDivProfesional" style={{ width: "100%" }}>
        <Grid container spacing={3}>
          <Grid id="vistaProfesionalFiltrosHorarios" item xs={12} style={{ padding: 0, marginTop: "1vw" }}>
            <FormControl
              variant="outlined"
              style={
                this.props.tamanio < 3
                  ? {
                      width: "15%",
                      transition: "width 300ms linear",
                    }
                  : {
                      width: "25%",
                      transition: "width 300ms linear",
                    }
              }
            >
              <InputLabel id="demo-simple-select-outlined-label">Especialidad</InputLabel>
              <Select
                style={{
                  marginTop: 8,
                }}
                labelId="demo-simple-select-outlined-label"
                id="especialidadFiltro"
                name="especialidadFiltro"
                value={especialidadFiltro}
                onChange={this.cambiarValores}
                label="Especialidad"
              >
                <MenuItem value={"0"} key={0}>
                  Todas
                </MenuItem>
                {especialidades &&
                  especialidades.map((esp, index) => (
                    <MenuItem value={esp.esp_sigla} key={index}>
                      {esp.esp_nombre}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <input
              style={{
                padding: 5,
                marginTop: 9,
                marginLeft: 20,
                width: this.props.tamanio < 3 ? "15%" : "25%",
                transition: "all 300ms linear",
              }}
              type="week"
              value={semanaFiltro}
              id="semanaFiltro"
              name="semanaFiltro"
              className={InformacionUsuarioCSS.SelectFecha}
              onChange={this.cambiarValores}
            ></input>
          </Grid>
        </Grid>

        {this.state.tablahorarioProfesional ? (
          <div id="tablahorarioProfesional" style={{ marginTop: "20px" }}>
            <TableContainer component={Paper}>{this.state.tabla}</TableContainer>
          </div>
        ) : null}
        {this.state.horariodiario ? <HorarioDiario profesional={this.state.proId} especialidad={this.state.especialidadHorario} fechaHorario={this.state.fechaHorario} ocultar={this.ocultarHorario}></HorarioDiario> : null}
      </div>
    );
  }
}
