import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

// core components
import GridItem from "../../components/grid/GridItem.js";
import GridContainer from "../../components/grid/GridContainer.js";
import axios from "axios";
import { CSVReader } from "react-papaparse";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";

import { NotificationManager } from "react-notifications";
import csvBeneficioTratamiento from "./../../assets/img/ejemplo_csv_beneficio_tratamiento.png";
import csvPrematuros from "../../assets/img/imgCargaMasiva/imgPrematuros.png";
import csvGeneral from "../../assets/img/imgCargaMasiva/2.png";
import csvCondicionEmbarazo from "./../../assets/img/imgCargaMasiva/cargaCondicionEmbarazo.png";
import csvCondicionDiscapacidad from "./../../assets/img/imgCargaMasiva/cargaCondicionDiscapacitado.png";
import csvCondicionCuidador from "./../../assets/img/imgCargaMasiva/cargaCondicionCuidador.png";
import { store } from "../../store/index";
import carga_ciclos from "../../assets/img/imgCargaMasiva/ciclos.svg";
import carga_beneficios from "../../assets/img/imgCargaMasiva/beneficios.svg";
import carga_prenaturo from "../../assets/img/imgCargaMasiva/prematuros.svg";
import carga_reservaExterba from "../../assets/img/imgCargaMasiva/reservas externas.svg";
import carga_fallecidos from "../../assets/img/imgCargaMasiva/fallecidos.svg";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";
import signo from "./../../assets/img/signo.svg";

// CSS
import "./../../assets/css/usuarios.css";
import moduleCM from "../../assets/css/cargamasiva.module.css";

export default class CargarCondiciones extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uspId: store.getState().usp,
      cesfam: store.getState().cesfam,
      condiciones: [],
      condicion: null,
      id_condicion: null,
      tipo_condicion: null,

      datos: [],
      tipo_carga: "eliminarCondiciones",
      herramientas: store.getState().herramientas,
    };

    this.cargaMasiva = this.cargaMasiva.bind(this);
  }

  componentDidMount() {
    this.traeCondiciones();

    this.titulo();
  }

  traeCondiciones = () => {
    axios.get(global.config.modulos.cargas_masivas + "condiciones/listar_condiciones/", { headers: authHeader() }).then((res) => {
      if (res.data.estado) {
        this.setState({ condiciones: res.data.datos });
      }
    });
  };

  titulo = () => {
    document.getElementsByClassName("content-titulo")[0].innerHTML = "Carga masiva <span title='Permite ingresar o actualizar a través de un archivo .csv información sobre los ciclos de los pacientes'><img style='width: 1vw' src=" + signo + "></img></span>";
  };

  cambioValor = (event) => {
    const { name, value } = event.target;
    let condicion = value.split(";");
    let id_condicion = condicion[0];
    let tipo_condicion = condicion[1];
    this.setState({
      [name]: value,
      id_condicion: id_condicion,
      tipo_condicion: tipo_condicion,
    });
  };

  handleOnDrop = (data) => {
    let registros = [];
    data.forEach((reg) => {
      registros.push(reg.data);
    });
    this.setState({ datos: registros });
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOnRemoveFile = (data) => {
    console.log(data);
    this.setState({ datos: [] });
  };

  cargaMasiva() {
    document.getElementById("cargando").style.display = "block";

    axios
      .post(
        global.config.modulos.cargas_masivas + "condiciones/carga_masiva_eliminar_condiciones/",
        {
          data: this.state.datos,
          uspId: this.state.uspId,
          tipo: this.state.id_condicion,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        document.getElementById("cargando").style.display = "none";
        if (res.data.estado) {
          NotificationManager.success("los registros se están ingresando, se le notificará cuando termine", "", 5 * 60 * 1000);
        } else {
          NotificationManager.warning(res.data.mensaje, "", 5 * 60 * 1000);
          if (res.data.errores.length > 0) {
            let mensaje = "";

            res.data.errores.forEach((reg) => {
              mensaje += "rut paciente: " + reg["rut_paciente"] + ", error: " + reg["mensaje"] + "\n";
            });

            const element = document.createElement("a");
            const file = new Blob([mensaje], {
              type: "text/plain;charset=utf-8",
            });
            element.href = URL.createObjectURL(file);
            element.download = "excepciones.txt";
            document.body.appendChild(element);
            element.click();
          }
        }
      });
  }

  cambiarVista = (e) => {
    const { name, value } = e.target;
    this.setState({ tipo_carga: value }, () => {
      this.props.history.push(this.state.tipo_carga);
    });
  };

  cargarCiclos = () => {
    this.props.history.push("/cargarCiclos");
  };

  cargarFallecidos = () => {
    this.props.history.push("/cargarFallecidos");
  };

  cargarCondiciones = () => {
    this.props.history.push("/cargarCondiciones");
  };

  cargarBeneficios = () => {
    this.props.history.push("/cargarBeneficios");
  };

  cargarReservasExternas = () => {
    this.props.history.push("/cargarReservasExternas");
  };

  render() {
    const { condiciones, condicion, datos, id_condicion } = this.state;

    return (
      <div className="middle-area">
        <div className="middle-content">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="cargarUsuarios">
                <div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <div className={moduleCM.divIcon}>
                        <img className={moduleCM.imgCMciclos1} src={carga_ciclos} onClick={this.cargarCiclos} />

                        <img className={moduleCM.imgCMbeneficio} src={carga_beneficios} onClick={this.cargarBeneficios} />

                        <img className={moduleCM.imgCMfallecido} src={carga_fallecidos} onClick={this.cargarFallecidos} />

                        {this.state.herramientas.includes("H44") ? <img className={moduleCM.imgCMprematuro1} src={carga_prenaturo} onClick={this.cargarCondiciones} /> : null}

                        {this.state.herramientas.includes("H47") ? <img className={moduleCM.imgCMreservaExterna} src={carga_reservaExterba} onClick={this.cargarReservasExternas} /> : null}
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <div className={moduleCM.divSelectIncribir}>
                        <label>Inscribir/Desinscribir</label>
                        <NativeSelect
                          className={moduleCM.selectCiclos}
                          value={this.state.tipo_carga}
                          onChange={this.cambiarVista}
                          inputProps={{
                            name: "tipo_carga",
                            id: "tipo_carga",
                          }}
                        >
                          <option value="cargarCondiciones">Inscribir condición</option>
                          <option selected value="eliminarCondiciones">
                            Desinscribir condición
                          </option>
                        </NativeSelect>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <div style={{ float: "left", marginLeft: "19px" }}>
                        <InputLabel className={moduleCM.labelCiclos} shrink htmlFor="age-native-label-placeholder" required>
                          Condición
                        </InputLabel>
                        <NativeSelect
                          className={moduleCM.selectCiclos}
                          value={this.state.condicion}
                          onChange={this.cambioValor}
                          inputProps={{
                            name: "condicion",
                            id: "condicion",
                          }}
                        >
                          <option value="">Seleccione condición</option>
                          {condiciones &&
                            condiciones.map((con, index) => (
                              <option value={con["con_id"] + ";" + con["con_nombre"]} key={index}>
                                {con["con_nombre"]}
                              </option>
                            ))}
                        </NativeSelect>
                      </div>
                    </Grid>

                    {id_condicion && (
                      <Grid item xs={12} sm={12}>
                        <span style={{ marginLeft: "19px" }}>Cargar CSV</span>
                        <div className={moduleCM.csvCargaMasiva}>
                          <CSVReader
                            onDrop={this.handleOnDrop}
                            onError={this.handleOnError}
                            addRemoveButton
                            removeButtonColor="#659cef"
                            onRemoveFile={this.handleOnRemoveFile}
                            config={{
                              header: true,
                              dynamicTyping: true,
                              skipEmptyLines: true,
                              transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
                            }}
                          >
                            <div
                              className={moduleCM.divSpanArchivo}
                              style={{
                                width: "21vw",
                              }}
                            >
                              <span className={moduleCM.spanArchivo}>Seleccione archivo</span>
                              <span style={{ fontSize: "0.9vw" }}> O arrastre archivo .CSV </span>
                            </div>
                          </CSVReader>
                          <LinearProgress id="cargando" style={{ display: "none" }} />
                        </div>
                      </Grid>
                    )}

                    <React.Fragment>
                      <Grid item xs={12} sm={12}>
                        {["2", "3", "4", "5", "6", "7", "8", "9", "10", "11"].includes(id_condicion) && <img className={moduleCM.imgEjemploCiclo} style={{ width: "50%", top: "25%" }} src={csvGeneral} alt="csv" />}
                      </Grid>
                    </React.Fragment>
                  </Grid>
                  {datos.length > 0 && (
                    <Grid item xs={12}>
                      <Button
                        onClick={this.cargaMasiva}
                        style={{
                          backgroundColor: "#3cbf71",
                          color: "white",
                          marginTop: 20,
                        }}
                      >
                        Cargar registros
                      </Button>
                    </Grid>
                  )}
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
