import React, { Component } from "react";
import authHeader from "components/Helpers/AuthHeader.js";

import axios from "axios";

import { store } from "./../../store/index";
import { NotificationManager } from "react-notifications";

// core components
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";

import "assets/css/usuarios.css";

export default class AgregarObservacion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usp_id: store.getState().usp,
      open: true,
      gdo_id: props.gdo_id,
      verOtro: true,
      observacion: 26,
      observacion_libre: "",
    };
  }

  salir = () => {
    this.setState({
      open: false,
    });
    this.props.ocultar();
  };

  componentDidMount() {}

  agregarObservacion = () => {
    if (this.state.observacion == null) {
      NotificationManager.error("Debe seleccionar una Observación");
      return;
    }

    var observacion_libre = null;
    if (this.state.observacion == 26) {
      if (this.state.observacion_libre == "") {
        NotificationManager.error("Debe escribir la observación");
        return;
      } else {
        observacion_libre = this.state.observacion_libre;
      }
    }

    axios
      .post(
        global.config.modulos.gestion + "observaciones_gestion_despacho/",
        {
          gdo_gestion: this.state.gdo_id,
          gdo_observacion: this.state.observacion,
          gdo_observacion_detalle: observacion_libre,
          gdo_usuario_panel: this.state.usp_id,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.estado) {
          NotificationManager.success("Se ha agregado la Observación al registro");
          this.salir();
        } else {
          NotificationManager.error("No se ha podido agregar la Observación al registro");
        }
      });
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.salir} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modificarUsuario" style={{ maxWidth: "100%", maxHeight: "100%" }}>
          <DialogContent
            style={{
              width: "500px",
              maxWidth: "100%",
              height: "100%",
              maxHeight: "100%",
            }}
          >
            <div style={{ marginBottom: "5px" }}>
              <h2>Observación</h2>
            </div>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {this.state.verOtro && (
                  <div style={{ marginTop: "8%" }}>
                    <InputLabel id="demo-simple-select-label">Ingresar observación:</InputLabel>
                    <FormControl style={{ width: "100%" }}>
                      <TextField name="observacion_libre" value={this.state.observacion_libre} onChange={this.cambiarValores} />
                    </FormControl>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "25px",
                  }}
                >
                  <Button style={{ marginRight: 10, backgroundColor: "#00ac77", color: "#fff" }} onClick={() => this.agregarObservacion()}>
                    Guardar
                  </Button>
                  <Button onClick={this.salir} style={{ color: "#fff" }} className="fondoNaranjo">
                    Cancelar
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
